
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class Contact {
    private _domain: string;
    private _subscription: string;
    prepped: boolean;
    private _status: string;
    private _show: string;
    private _name: string;
    private _state: string;
    private _type: string;
    private _full: string;
    full_jids: any;
    local: string;
    resource: string;
    bare: string;
    priority: string;
    full: string;
    jid: string;
    unescapedLocal: string;
    unescapedBare: string;
    unescapedFull: string;
    groups: any;
    _avatarData: string;
    constructor(
        private _bare: string
    ) {
        this.bare = _bare;
        this.jid = _bare;
        this._status = "offline";
        this._show = "offline";
        this._state = "offline";
        this._type = "unavailable";
        this.full_jids = [];
        this.local = _bare.split("@")[0];
        this._name = _bare.split("@")[0];
        this._domain = _bare.split("@")[1];
    }

    public get status(): string {
        return this._status;
    }

    public get avatarData(): string {
        return this._avatarData;
    }

    public setAvatar(value: string): void {
        this._avatarData = value;
    }

    public get domain(): string {
        return this._domain;
    }

    public get subscription(): string {
        return this._subscription;
    }

    public get show(): string {
        return this._show;
    }

    public get state(): string {
        return this._state;
    }

    public get name(): string {
        let name = this._name;
        if (!this._name && this.local) {
            name = this.local.split(".").map(function (name) {
                return name.charAt(0).toUpperCase() + name.substr(1, name.length);
            }).join(" ");
        }
        return name;
    }

    setStatus(status: string): void {
        this._status = status;
        this._show = status;
        this._state = status;
    }

    setName(name: string): void {
        this._name = name;
    }

    setSubscription(value: string): void {
        this._subscription = value;
    }

    setDomain(value: string): void {
        this._domain = value;
    }

    setFullJID(value: string): void {
        this._full = value;
    }
}