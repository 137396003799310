
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div style="position: relative;" class="participant-wrapper"
  (mouseover)="hover=true; $event.preventDefault();" (mouseleave)="hover=false; $event.preventDefault();">
  <div id="participant{{participantId}}" style="position: relative;" title="{{displayName | translate}}"
    class="participant {{ size }}" [ngClass]="{'has-audio': hasAudio,
                                                'has-video': hasVideo,
                                                'audio-only': audioOnly,
                                                'is-screen-sharing': isScreenSharingVideo,
                                                'show-fullscreen': isFullScreen}"
    (contextmenu)="onContextMenuParticipant($event)">
    <span *ngIf="size === 'small' && showHand" class="raise-hand-icon raise_hand_participantVideo{{participantId}}">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 18.33 20">
        <path id="prefix__hand-left"
          d="M3 6.58v12.09A3.342 3.342 0 0 0 6.33 22h6.09a3.289 3.289 0 0 0 2.37-1l6.54-6.64s-1.05-1.03-1.08-1.04a.985.985 0 0 0-.66-.25.966.966 0 0 0-.5.14c-.03.01-3.59 2.05-3.59 2.05V5.33a1.25 1.25 0 1 0-2.5 0v5.84h-.83V3.25a1.25 1.25 0 0 0-2.5 0v7.92h-.84V4.08a1.25 1.25 0 0 0-2.5 0v7.09H5.5V6.58a1.25 1.25 0 0 0-2.5 0z"
          transform="translate(-3 -2)" style="fill:#ffc800" />
      </svg>
    </span>
    <div class="row rounded-button-group" *ngIf="size === 'small'">
      <span class="rounded-button">
        <img #speakingIcon src="assets/volume-high.svg" class="material-icons is-speaking icon-speaking-info hide">
      </span>
      <span *ngIf="muteParticipantVideo" class="rounded-button-dark">
        <img src="assets/video-off.svg" class="material-icons">
      </span>
      <span *ngIf="muteParticipantAudio" class="rounded-button-dark">
        <img src="assets/microphone-off.svg" class="material-icons">
      </span>
    </div>

    <!-- connection quality icon -->
    <span
      *ngIf="size === 'small' && categoryColor !== '#000000'"
      class="bandwidth-container"
      [style.backgroundColor]="categoryColor"
      [ngStyle]="{visibility: hover || isOnMobile ? 'visible' : 'hidden' }" >
      <img src="assets/radio-tower.svg" class="bandwidth-material-icons">
    </span>

    <div class="thumbnail thumbnail-{{ size }}">
      <!-- <i #speakingIcon *ngIf="size === 'small'" class="is-speaking icon-speaking-info hide"></i> -->
      <div class="fullscreen" *ngIf="isFullScreen && size === 'small'">
        <i class="icon-fullscreen-info"></i>
      </div>
      <div *ngIf="size === 'small'" #participantAvatar id="participantAvatar{{participantId}}" class="video-object"
        [style.backgroundImage]="avatarData"
        [ngClass]="{'has-audio': hasAudio, 'is-fullscreen': isFullScreen, 'has-video': hasVideo, 'has-my-video': hasVideo}">
        <div class="participant__stream" contextmenu="mymenu">
          <video autoplay="1" playsinline id="participantVideo{{participantId}}" *ngIf="!audioOnly && hasVideo && !hideAllVideo"
            [ngClass]="{'my-video': participantId === myUserId, 'flipVideoX': isFlipped && !isScreenSharingVideo, 'is-screen-sharing': isScreenSharingVideo}"></video>
          <audio *ngIf="participantId !== myUserId && !videoOnly" autoplay="1" playsinline
            id="participantAudio{{participantId}}"></audio>
            <vp-avatar (loadedAvatar)="avatarData = null" *ngIf="!hasVideo || muteParticipantVideo || hideAllVideo" [jid]="displayName" size="preview-video"
            hidestatus="true"></vp-avatar>
        </div>
      </div>
      <div *ngIf="size === 'thumbnail'" #participantAvatar id="participantAvatar{{participantId}}" class="video-object"
        [style.backgroundImage]="avatarData"
        [ngClass]="{'has-audio': hasAudio, 'is-fullscreen': isFullScreen, 'has-video': hasVideo, 'has-my-video': hasVideo}">
        <div class="participant__stream" contextmenu="mymenu">
          <video autoplay="1" playsinline *ngIf="hasVideo && !hideAllVideo && !muteParticipantVideo" [style.display]="muteParticipantVideo ? 'none':'block'"
            id="participantVideo{{participant?.id}}" class="my-video"
            [ngClass]="{'is-screen-sharing': isScreenSharingVideo, 'flipVideoX': isFlipped && !isScreenSharingVideo}"></video>
            <vp-avatar *ngIf="!hasVideo || muteParticipantVideo || hideAllVideo" [jid]="displayName" size="preview-video"
            hidestatus="true"></vp-avatar>
        </div>
      </div>
      <div *ngIf="size === 'preview'" #participantAvatar id="participantAvatar{{participantId}}" class="video-object"
        [style.backgroundImage]="avatarData"
        [ngClass]="{'has-audio': hasAudio, 'is-fullscreen': isFullScreen, 'has-video': hasVideo, 'has-my-video': hasVideo}">
        <div class="participant__stream" contextmenu="mymenu">
          <video autoplay="1" disablePictureInPicture playsinline *ngIf="hasVideo && !hideAllVideo" [style.display]="muteParticipantVideo ? 'none':'block'"
            id="participantPreviewVideo{{participantId}}" class="my-video"
            [ngClass]="{'is-screen-sharing': isScreenSharingVideo, 'flipVideoX': isFlipped && !isScreenSharingVideo}"></video>
            <vp-avatar (loadedAvatar)="avatarData = null" *ngIf="!hasVideo || muteParticipantVideo || hideAllVideo" [jid]="displayName" size="preview-video"
            hidestatus="true"></vp-avatar>
        </div>
        <mat-icon fontIcon="mdi-launch" fontSet="mdi" class="return-call-icon"></mat-icon>
      </div>
      <div *ngIf="size === 'big'" #participantAvatar id="participantBigAvatar{{participantId}}" class="video-object"
        [style.backgroundImage]="avatarData"
        [ngClass]="{'has-audio': hasAudio, 'has-video': hasVideo, 'has-my-video': hasVideo, 'is-screen-sharing': isScreenSharingVideo}">
        <div class="participant__stream">
          <video *ngIf="!hideAllVideo" disablePictureInPicture autoplay="" playsinline id="largeVideo" style="transform: none;" [ngClass]="{'flipVideoX': isFlipped && !isScreenSharingVideo}"></video>
          <vp-avatar (loadedAvatar)="avatarData = null" *ngIf="!hasVideo || muteParticipantVideo || hideAllVideo" [jid]="displayName" size="preview-video"
          hidestatus="true"></vp-avatar>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" class="participant-context-menu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item *ngIf="allowToSendXMPP()" (click)="sendMessage()">
      <mat-icon fontSet="mdi" fontIcon="mdi-message-text"></mat-icon>
      <span>{{ 'SEND_A_MESSAGE' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="isOwner() && allowToSendXMPP()" (click)="changeRole()">
      <mat-icon fontIcon="mdi-account-multiple" fontSet="mdi"></mat-icon>
      <span>{{ 'CHANGE_ROLE'| translate }}</span>
    </button>
    <button mat-menu-item *ngIf="isOwner() && !isPinned" (click)="pinParticipant()">
      <mat-icon fontSet="mdi" fontIcon="mdi-pin"></mat-icon>
      <span>{{ 'PIN_PARTICIPANT' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="isOwner() && isPinned" (click)="unpinParticipant()">
      <mat-icon fontSet="mdi" fontIcon="mdi-pin-off"></mat-icon>
      <span>{{ 'UNPIN_PARTICIPANT' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="!muteForMe" (click)="muteAudioForMe()">
      <mat-icon fontIcon="mdi-volume-mute" fontSet="mdi"></mat-icon>
      {{ 'MUTE_FOR_ME'| translate }}
    </button>
    <button mat-menu-item *ngIf="muteForMe" (click)="unMuteAudioForMe()">
      <mat-icon fontIcon="mdi-volume-high" fontSet="mdi"></mat-icon>
      {{ 'UNMUTE_FOR_ME'| translate }}
    </button>
    <button mat-menu-item *ngIf="isOwner() && !muteParticipantAudio" (click)="muteRemoteMediaAudio()">
      <mat-icon fontIcon="mdi-volume-mute" fontSet="mdi"></mat-icon>
      {{ 'MUTE_FOR_EVERYONE'| translate }}
    </button>
    <button mat-menu-item *ngIf="isOwner() && muteParticipantAudio" (click)="unMuteRemoteMediaAudio()">
      <mat-icon fontIcon="mdi-volume-high" fontSet="mdi"></mat-icon>
      {{ 'UNMUTE_FOR_EVERYONE'| translate }}
    </button>
    <button mat-menu-item *ngIf="isOwner() && conferenceType === 'video' && !muteParticipantVideo"
      (click)="toggleRemoteMediaVideo()">
      <mat-icon fontSet="mdi" fontIcon="mdi-video-off"></mat-icon>
      <span>{{ 'DISABLE_CAMERA' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="isOwner() && conferenceType === 'video' && muteParticipantVideo"
      (click)="toggleRemoteMediaVideo()">
      <mat-icon fontSet="mdi" fontIcon="mdi-video-off"></mat-icon>
      <span>{{ 'ENABLE_CAMERA' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="isOwner()" (click)="kickOut()">
      <mat-icon fontIcon="mdi-exit-to-app" fontSet="mdi"></mat-icon>
      {{ 'REMOVE_FROM_CALL'| translate }}
    </button>
    <button mat-menu-item *ngIf="isOwner()" (click)="wakeUp()">
      <mat-icon fontIcon="mdi-sleep-off" fontSet="mdi"></mat-icon>
      {{ 'WAKE_UP'| translate }}
    </button>
    <button mat-menu-item *ngIf="isOwner() && !isAnonymous" (click)="removeFromMeeting()">
      <mat-icon fontIcon="mdi-account-remove" fontSet="mdi"></mat-icon>
      {{ 'REMOVE_FROM_GROUP'| translate }}
    </button>
  </ng-template>
</mat-menu>
