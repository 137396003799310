
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy } from "@angular/core";
import { Broadcaster } from "../../providers";
import { JID } from "../../../models/jid.model";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../../../reducers";
import { getIsAppOnline, getUserJID, getUserProfile } from "../../../../reducers";
import { ContactRepository } from "../../../repositories/contact.repository";
import { ToastService } from "../../../../shared/services/toast.service";
import { filter, Subject, take, takeUntil } from "rxjs";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import { ContactAddress, ContactInformation, EmailInformation, TelInformation } from "../../../models/vcard.model";
import { environment } from "app/environments/environment";
import { CommonUtil } from "../../../utils/common.util";
import { MatDialogRef } from "@angular/material/dialog";
import { getAllUserStatusTypes, getUserStatusType, UserStatus } from "app/shared/models";

@Component({
  selector: "vp-edit-profile",
  templateUrl: "./edit-profile.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VNCEditProfileComponent implements OnDestroy {
  loggedInUserJID: JID;

  status: UserStatus;

  avatarData: any;
  editable = false;
  showUploadForm = false;
  conversationTitle = "";
  localTime = "";
  data: any;
  m3: any;
  contact: ContactInformation;
  private isAlive$ = new Subject<boolean>();
  isAppOnline = false;
  getAllUserStatusTypes = getAllUserStatusTypes;
  getUserStatusType = getUserStatusType;
  disableForm = false;
  phoneOptions = [
    {
      label: "WORK",
      value: "WORK"
    },
    {
      label: "HOME",
      value: "HOME"
    }
  ];
  addressOptions = [
    {
      label: "WORK",
      value: "WORK"
    },
    {
      label: "HOME",
      value: "HOME"
    }
  ];
  emailOptions = [
    {
      label: "WORK",
      value: "WORK"
    },
    {
      label: "HOME",
      value: "HOME"
    }
  ];
  form: UntypedFormGroup;
  family = new UntypedFormControl("", []);
  given = new UntypedFormControl("", Validators.required);
  isHin = environment.theme === "hin";
  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private contactRepo: ContactRepository,
    private dialog: MatDialogRef<VNCEditProfileComponent>,
    private broadcaster: Broadcaster,
    private store: Store<TalkRootState>,
    private changeDetectionRef: ChangeDetectorRef) {
    this.form = this.fb.group({
      name: this.fb.group({
        family: this.family,
        given: this.given
      }),
      addresses: this.fb.array([
        this.initAddress(),
      ]),
      emails: this.fb.array([
        this.initEmail(),
      ]),
      phoneNumbers: this.fb.array([
        this.initPhone(),
      ])
    });
    this.setupStore();
    this.broadcaster.on<any>("hideEditProfile")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.dialog.close();
      });


  }

  onPhoneChanges(): void {
    this.form.get("phoneNumbers").valueChanges.subscribe(phoneNumbers => {
      const totalPhone = phoneNumbers.length;
      const lastPhone = phoneNumbers[totalPhone - 1];
      if (lastPhone.number !== "") {
        this.addPhone();
      }

    });
  }


  onEmailsChanges(): void {
    this.form.get("emails").valueChanges.subscribe(emails => {
      const totalEmails = emails.length;
      const lastEmail = emails[totalEmails - 1];
      if (lastEmail.email !== "") {
        this.addEmail();
      }

    });
  }

  onAddressChanges(): void {
    this.form.get("addresses").valueChanges.subscribe(addresses => {
      const totalAddresses = addresses.length;
      const lastAddress = addresses[totalAddresses - 1];
      if (lastAddress.city !== "" || lastAddress.country !== "" || lastAddress.postalCode !== "" || lastAddress.street !== "") {
        this.addAddress();
      }

    });
  }

  initAddress() {
    return this.fb.group({
      addressType: ["WORK"],
      street: [""],
      street2: [""],
      region: [""],
      city: [""],
      postalCode: [""],
      country: [""]
    });
  }

  initPhone() {
    return this.fb.group({
      phoneType: ["WORK"],
      number: [""]
    });
  }

  initEmail() {
    return this.fb.group({
      emailType: ["WORK"],
      email: [""]
    });
  }

  get isValidEmails(): boolean {
    let isValid: boolean = true;
    for (let form of this.form.get("emails").value) {
      if (!!form.email && !CommonUtil.isValidEmail(form.email)) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  addAddress() {
    // add address to the list
    const control = <UntypedFormArray>this.form.controls["addresses"];
    control.push(this.initAddress());
  }

  removeAddress(i: number) {
    // remove address from the list
    const control = <UntypedFormArray>this.form.controls["addresses"];
    control.removeAt(i);
  }

  addPhone() {
    // add phone to the list
    const control = <UntypedFormArray>this.form.controls["phoneNumbers"];
    control.push(this.initPhone());
  }

  removePhone(i: number) {
    // remove phone from the list
    const control = <UntypedFormArray>this.form.controls["phoneNumbers"];
    control.removeAt(i);
  }

  addEmail() {
    // add email to the list
    const control = <UntypedFormArray>this.form.controls["emails"];
    control.push(this.initEmail());
  }

  removeEmail(i: number) {
    // remove email from the list
    const control = <UntypedFormArray>this.form.controls["emails"];
    control.removeAt(i);
  }


  public onSubmit() {

    if (!this.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }

    let data = this.form.value;
    data.addresses.pop(); // remove last placeholder
    data.addresses = data.addresses.map(address => this.mapAddressToSave(address));
    data.emails.pop(); // remove last placeholder
    data.emails = data.emails.filter(f => f.email).map(email => this.mapEmailToSave(email));
    data.phoneNumbers.pop(); // remove last placeholder
    data.phoneNumbers = data.phoneNumbers.map(phone => this.mapPhoneToSave(phone));
    data.fullName = data.name.given + " " + data.name.family;
    const newData = {
      ...this.contact,
      ...data
    };
    this.contactRepo.publishVCards(newData).subscribe(() => {
      this.dialog.close();
    });
  }

  public onDisableForm(formDisabled: boolean) {
    if (formDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private setupStore() {
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
    this.store.select(getUserJID).pipe(takeUntil(this.isAlive$)).subscribe(jid => {
      this.loggedInUserJID = jid;
      this.store.select(getUserProfile).pipe(take(1)).subscribe(() => {
      });
      this.changeDetectionRef.markForCheck();
    });

    this.contactRepo.getContactVCard(this.loggedInUserJID.bare).pipe(filter(res => !!res), take(1)).subscribe(data => {
      this.contact = data;
      if (data.name) {
        this.form.get("name").setValue(data.name);
      }

      if (data.addresses && data.addresses.length > 0) {
        for (let i = 0; i < data.addresses.length - 1; i++) {
          this.addAddress();
        }
        const addresses = data.addresses.map(address => CommonUtil.mapAddress(address));
        this.form.get("addresses").setValue(addresses);
        this.addAddress();
      }

      if (data.emails && data.emails.length > 0) {
        for (let i = 0; i < data.emails.length - 1; i++) {
          this.addEmail();
        }
        const emails = data.emails.map(email => CommonUtil.mapEmail(email));
        this.form.get("emails").setValue(emails);
        this.addEmail();
      }

      if (data.phoneNumbers && data.phoneNumbers.length > 0) {
        for (let i = 0; i < data.phoneNumbers.length - 1; i++) {
          this.addPhone();
        }
        const phoneNumbers = data.phoneNumbers.map(phone => CommonUtil.mapPhone(phone));
        this.form.get("phoneNumbers").setValue(phoneNumbers);
        this.addPhone();
      }
      setTimeout(() => {
        if (document.querySelector("#firstName input") !== null) {
          (<HTMLElement>document.querySelector("#firstName input")).focus();
        }
      }, 500);
      this.onAddressChanges();
      this.onEmailsChanges();
      this.onPhoneChanges();
    });
  }

  private mapAddressToSave(address: ContactAddress) {
    switch (address.addressType) {
      case "WORK":
        address.work = true;
        break;
      case "HOME":
        address.home = true;
        break;
      case "POSTAL":
        address.postal = true;
        break;
      default:
        address.work = true;

    }
    delete address.addressType;
    return address;
  }

  private mapEmailToSave(email: EmailInformation) {
    switch (email.emailType) {
      case "WORK":
        email.work = true;
        break;
      case "HOME":
        email.home = true;
        break;
      case "INTERNET":
        email.internet = true;
        break;
      default:
        email.internet = true;

    }
    delete email.emailType;
    return email;
  }

  private mapPhoneToSave(phone: TelInformation) {
    switch (phone.phoneType) {
      case "WORK":
        phone.work = true;
        break;
      case "HOME":
        phone.home = true;
        break;
      case "FAX":
        phone.fax = true;
        break;
      default:
        phone.work = true;
    }
    delete phone.phoneType;
    return phone;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();

    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    window.scrollTo(0, 0);
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
  }

  close() {
    this.dialog.close();
  }

  getVCard() {
    this.contactRepo.getContactVCard(this.loggedInUserJID.bare).pipe(takeUntil(this.isAlive$)).subscribe();
  }

}
