
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { SearchMessageActionTypes } from "../actions/search-message";
import { SearchMessage } from "../models/search-message.model";
import { CommonUtil } from "../utils/common.util";

export interface SearchMessageState extends EntityState<SearchMessage> {
}

export const searchMessageAdapter: EntityAdapter<SearchMessage> = createEntityAdapter<SearchMessage>({
  selectId: (message: SearchMessage) => message.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(message1: SearchMessage, message2: SearchMessage): number {
  return message1.timestamp - message2.timestamp;
}

export const initialState: SearchMessageState = searchMessageAdapter.getInitialState({
  //
});

export function searchMessageReducer(state: SearchMessageState = initialState, action: Action): SearchMessageState {
  switch (action.type) {

    case SearchMessageActionTypes.RESET_MESSAGES: {
     return searchMessageAdapter.removeAll(state);
    }

    case SearchMessageActionTypes.MESSAGE_ADD: {
      const message = action.payload.message;
      message.cachedContent = CommonUtil.generateCachedBody(message.body);
      const newState = searchMessageAdapter.addOne(message, state);
      return searchMessageAdapter.updateOne({ id: message.id, changes: message }, newState);
    }

    case SearchMessageActionTypes.MESSAGE_BULK_LOAD:
    case SearchMessageActionTypes.MESSAGE_BULK_APPEND: {
      action.payload.messages.forEach(message => {
        message.cachedContent = CommonUtil.generateCachedBody(message.body);
      });

      return searchMessageAdapter.addMany(action.payload.messages, state);
    }

    case SearchMessageActionTypes.MESSAGE_BULK_UPDATE: {
      // TODO:
      // message.cachedContent = CommonUtil.generateCachedBody(message.body);
      return searchMessageAdapter.updateMany(action.payload.messages, state);
    }

    default: {
      return state;
    }
  }
}
