
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, Input, ElementRef, AfterViewInit, OnDestroy } from "@angular/core";

@Directive({ selector: "[vpDatepickerPos]" })
export class DatepickerPosDirective implements AfterViewInit, OnDestroy {

  @Input() container: Element;
  handler = this.onDatepickerShown.bind(this);
  input: Element;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.input = this.el.nativeElement.querySelector("input");
    this.input.addEventListener("click", this.handler);
  }

  onDatepickerShown() {
    let container = this.getContainer();
    let calendar = this.el.nativeElement.querySelector(".datepicker__calendar");

    if (!container || !calendar) return;

    let containerRect = container.getBoundingClientRect();
    let calendarRect = calendar.getBoundingClientRect();

    if (this.shouldMoveLeft(containerRect, calendarRect)) {
      calendar.classList.add("pos-left");
    }

    if (this.shouldMoveToTop(containerRect, calendarRect)) {
      calendar.classList.add("pos-top");
    }
  }

  shouldMoveLeft(containerRect, calendarRect) {
    let diffRight = calendarRect.right - containerRect.right;
    if (diffRight > 0) {
      let leftPos = Math.abs(calendarRect.left - calendarRect.width);
      let diffLeft = leftPos - containerRect.left;
      return diffLeft < diffRight; // we have more space towards left!!
    }
    return false;
  }

  shouldMoveToTop(containerRect, calendarRect) {
    let diffBottom = calendarRect.bottom - containerRect.bottom;
    if (diffBottom > 0) {
      let topPos = Math.abs(calendarRect.top - calendarRect.height);
      let diffTop = topPos - containerRect.top;
      return diffTop < diffBottom; // we have more space towards top!!
    }
    return false;
  }

  getContainer() {
    let container = this.container;
    if (this.container && typeof this.container === "string") {
      container = document.querySelector(this.container);
    }
    if (!container) {
      container = document.querySelector(".cast__dialog-body");
    }
    return container;
  }

  ngOnDestroy() {
    this.input.removeEventListener("click", this.handler);
  }

}
