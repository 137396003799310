
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Environment } from "./environment.interface";

export const environment: Environment = {
  production: true,
  xmppTimeout: 90,
  callRequestTimeout: 60,
  xmppReconnectInterval: 90,
  xmppForceReconnectInterval: 7,
  pollingTimeInterval: 5,
  conversationsCount: 20,
  messagesCount: 50,
  avatarFetchInterval: 5,
  avatarRefetchDelay: 600,
  isElectron: false,
  isCordova: false,
  title: "",
  logoPath: "",
  headerBranding: "",
  serviceDeskLink: "",
  manualLink: "",
  helpLink: "",
  FAQLink: "",
  theme: "",
  icon: "",
  defaultServerUrl: "",
  showGroupChatInfo: true,
  showAddParticipants: true,
  showImageNameInImagePreview: true,
  enableSentry: false,
  chatBackground: "",
  chatBackgroundColor: ""
};
