
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export const PAD__LOAD_PADS = "[PAD/API] Load pads";
export const PAD__LOAD_PADS_SUCCESS = "[PAD/API] Load pads success";
export const PAD__LOAD_PADS_FAILURE = "[PAD/API] Load pads failure";

export const PAD__LOAD_PAD_URL = "[PAD/API] Load pad url";
export const PAD__LOAD_PAD_URL_SUCCESS = "[PAD/API] Load pad url success";
export const PAD__LOAD_PAD_URL_FAILURE = "[PAD/API] Load pad url failure";

export const PAD__CREATE_NEW_PAD = "[PAD/API] Create new pad";
export const PAD__RENAME_PAD_REQUEST = "[PAD/API] Rename pad request";
export const PAD__RENAME_PAD = "[PAD/API] Rename pad";
export const PAD__DELETE_PAD = "[PAD/API] Delete pad";
export const PAD__SELECT_PAD = "[PAD/API] Select pad";
export const PAD__DELETE_PAD_REQUEST = "[PAD/API] Delete pad request";
export const PAD__CREATE_NEW_PAD_SUCCESS = "[PAD/API] Create new pad success";
export const PAD__CREATE_NEW_PAD_FAILURE = "[PAD/API] Create new pad failure";

export const PAD__SHOW_PAD_WINDOW = "[PAD] Show pad window";
export const PAD__HIDE_PAD_WINDOW = "[PAD] Hide pad window";

export const PAD__SAVE_CURRENT_TARGET = "[PAD] Save current target";
export const PAD__CREATE_CURRENT_TARGET = "[PAD] Create current target";
export const PAD__RESET = "[PAD] Reset";
export const PAD__IS_LOADING = "[PAD] Is Loading";
