
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { MessageActionTypes } from "../actions/message";
import { Message } from "../models/message.model";
import { Conversation } from "../models/conversation.model";
import { ConversationActionTypes } from "../actions/conversation";
import { AppActionTypes } from "../../actions/app";
import { CommonUtil } from "../utils/common.util";

export interface ConversationMessageState {
  ids: { [conversationTarget: string]: string[] };
  loading: { [conversationTarget: string]: boolean };
  loaded: { [conversationTarget: string]: boolean };

  isOnLastPage: { [conversationTarget: string]: boolean };
}

const initialState: ConversationMessageState = {
  ids: {} ,
  loading: {} ,
  loaded: {} ,
  isOnLastPage: {} ,
};

export function conversationMessageReducer(state = initialState , action: Action): ConversationMessageState {
  switch (action.type) {
    case MessageActionTypes.MESSAGE_ADD: {
      const payload = action.payload;
      const conversationTarget = payload.conversationTarget;
      const message = payload.message as Message;
      // If receiving new message on new conversation
      const oldMessages = state.ids[conversationTarget] ? state.ids[conversationTarget] : [];

      const messageIds = CommonUtil.uniq([...oldMessages , message.id]);

      return {
        ...state ,
        ids: {
          ...state.ids ,
          [conversationTarget]: messageIds
        }
      };
    }

    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD: {
      const data = action.payload;

      let localState = {...state};
      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const message = item.message as Message;

        // If receiving new message on new conversation
        const oldMessages = localState.ids[conversationTarget] ? localState.ids[conversationTarget] : [];

        const messageIds = CommonUtil.uniq([...oldMessages , message.id]);

        localState = {
          ...localState ,
          ids: {
            ...localState.ids ,
            [conversationTarget]: messageIds
          }
        };
      });

      return localState;
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND: {
      const payload = action.payload;
      const conversationTarget = payload.conversationTarget;
      const skipSetLoadingToFalse = payload.skipSetLoadingToFalse;

      const messages = payload.messages as Message[] || [];
      const oldMessageIds = state.ids[conversationTarget] ? state.ids[conversationTarget] : [];

      const messageIds = CommonUtil.uniq([...oldMessageIds, ...messages.map(m => m.id)]);

      const res = {
        ...state,
        ids: {
          ...state.ids,
          [conversationTarget]: messageIds
        }
      };

      if (!skipSetLoadingToFalse) {
        res.loading = {
          ...state.loading,
          [conversationTarget]: false
        };
        res.loaded = {
          ...state.loaded,
          [conversationTarget]: true
        };
      }

      return res;
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let localState = {...state};
      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const oldMessages = localState.ids[conversationTarget] ? localState.ids[conversationTarget] : [];

        const messages = item.messages || [];
        const newMessageIds =  messages.map(m => m.id);
        const messageIds = CommonUtil.uniq([
          ...oldMessages ,
          ...newMessageIds
        ]);

        localState = {
          ...localState ,
          ids: {
            ...localState.ids ,
            [conversationTarget]: messageIds
          } ,
          loading: {
            ...localState.loading ,
            [conversationTarget]: false
          }
        };
      });
      return localState;
    }

    case MessageActionTypes.MESSAGE_BULK_LOADING: {
      const conversationTarget = action.payload;

      return {
        ...state ,
        loading: {
          ...state.loading ,
          [conversationTarget]: true
        }
      };
    }

    case MessageActionTypes.MESSAGE_BULK_LOADED: {
      const conversationTarget = action.payload;

      return {
        ...state ,
        loading: {
          ...state.loading ,
          [conversationTarget]: false
        }
      };
    }

    case MessageActionTypes.MESSAGE_BULK_LOADING_FAILED: {
      const conversationTarget = action.payload;

      return {
        ...state ,
        loading: {
          ...state.loading ,
          [conversationTarget]: false
        }
      };
    }

    case MessageActionTypes.MESSAGE_LAST_PAGE_LOADED: {
      return {
        ...state ,
        isOnLastPage: {
          ...state.isOnLastPage ,
          [action.payload]: true
        } ,
        loading: {
          ...state.loading ,
          [action.payload]: false
        }
      };
    }

    case ConversationActionTypes.RESET_LAST_PAGE_LOADED: {
      return {
        ...state ,
        isOnLastPage: {
          ...state.isOnLastPage ,
          [action.payload]: false
        }
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.conversationMessageState;
      return savedState ? { ...state , ...savedState } : state;
    }

    case MessageActionTypes.MESSAGE_BULK_DELETE: {
      const persistentConversationIds = action.payload.persistentConversationIds || [];
      const nonPersistentConversationIds = action.payload.nonPersistentConversationIds || [];
      const messageIds = action.payload.messageIds;
      let ids = {...state.ids};

      nonPersistentConversationIds.forEach(conv => {
        ids[conv] = [];
      });

      persistentConversationIds.forEach(conv => {
        if (ids[conv] && ids[conv].length > 0) {
          ids[conv] = ids[conv].filter(id => !messageIds.includes(id));
        }
      });

      return {
        ...state ,
        ids: {...ids}
      };
    }

    default: {
      return state;
    }
  }
}

export const _getConversationMessageIds = (state: ConversationMessageState , conversation: any) => {
  return conversation && state.ids && state.ids[conversation.Target] ? state.ids[conversation.Target] : [];
};

export const _getIsConversationMessagesLoaded = (state: ConversationMessageState , conversation: Conversation) => {
  return conversation && state.loaded && state.loaded[conversation.Target];
};

export const _getIsConversationMessagesLoading = (state: ConversationMessageState , conversation: Conversation) => {
  return conversation && state.loading && state.loading[conversation.Target];
};

export const _getIsConversationMessagesOnLastPage = (state: ConversationMessageState , conversation: Conversation) => {
  if (!conversation) {
    // PRASHANT_COMMENT should not happen
    return true;
  }

  return state.isOnLastPage[conversation.Target] ? state.isOnLastPage[conversation.Target] : false;
};
