
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="custom-dialog-container"  [style.backgroundImage]="backgroundImage">
    <div class="top-margin"> </div>
    <div class=" close-button">
        <mat-icon (click)="onClose()">close</mat-icon>
    </div>
    <div class="container">
        <!-- <div class="container"> -->
        <div class="avatar">
            <vp-avatar *ngIf="item.override && item.type === 'active'" [jid]="item.override.conversationTarget"
                [hidestatus]="true" [size]="'big'" [type]="item.override.chatType" [showGroupAvatar]="false">
            </vp-avatar>
            <vp-avatar *ngIf="item.type !== 'active' && item.override && item.override.type === 'groupchat'"
                [jid]="item.override.room" [hidestatus]="true" [size]="'big'" [type]="item.override.chatType"
                [showGroupAvatar]="false">
            </vp-avatar>
            <vp-avatar *ngIf="item.type !== 'active' && item.override  && item.override.type !== 'groupchat'"
                [jid]="item.bare" [hidestatus]="true" [size]="'big'" [showGroupAvatar]="false"></vp-avatar>
            <vp-avatar *ngIf="item.type !== 'active' && !item.override" [jid]="item.bare" [hidestatus]="true"
                [size]="'notification'" [showGroupAvatar]="false"></vp-avatar>
        </div>
        <div class="caller-name"> {{ (data.length>22)? (data | slice:0:22)+'...':(data) }} </div>
        <div *ngIf="callType==='video'" class="call-type"> {{'INCOMING_VIDEO_CALL' | translate}} </div>
        <div *ngIf="callType==='audio'" class="call-type"> {{'INCOMING_AUDIO_CALL' | translate}} </div>
        <div *ngIf="callType==='screen'" class="call-type"> {{'SCREEN_SHARING' | translate}} </div>
        <!-- </div> -->
        <div class="button-group" mat-dialog-actions>
            <button *ngIf="callType === 'video'" id="joinVideoCallBtn" class="call-video-on" mat-mini-fab color="primary" (click)="$event.stopPropagation();startVideo()">
                <mat-icon>videocam</mat-icon>
            </button>
            <button [ngClass]="{'share-screen': isShareScreen}" id="declineIncCallBtn" class="decline-call" mat-mini-fab color="primary" (click)="$event.stopPropagation();decline()">
                <mat-icon>call_end</mat-icon>
            </button>
            <button id="joinAudioCallBtn" *ngIf="callType === 'audio' || callType === 'video'" class="call-audio-on" mat-mini-fab color="primary"
                (click)="$event.stopPropagation();startAudio()">
                <mat-icon>call</mat-icon>
            </button>
            <button id="joinAudioCallBtn" *ngIf="callType === 'screen'" class="call-audio-on" mat-mini-fab color="primary"
                (click)="$event.stopPropagation();shareScreen()">
                <mat-icon>call</mat-icon>
            </button>
        </div>
    </div>
</div>