

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "app/config.service";
import { environment } from "app/environments/environment";
import { MiddlewareService } from "app/talk/services/middleware.service";
import { CommonUtil } from "app/talk/utils/common.util";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import {SocialProfile} from "../../shared/models/social-profile.model";
import { Post } from "../models/post.model";

@Injectable()
export class SocialService {

    private isCordovaOrElectron = environment.isCordova || environment.isElectron;

    constructor(
        private middlewareService: MiddlewareService,
        private configService: ConfigService
    ) { }

    getPosts(offset?: number, limit?: number, query?: string): Observable<any> {
        let data: any = {};
        if (!!offset) {
            data["offset"] = offset;
        }
        if (!!limit) {
            data["limit"] = limit;
        }
        let reqUrl = `/api/posts`;
        if (!!query) {
            reqUrl += `?${query}`;
        }
        return this.middlewareService.get(reqUrl, true, data);
    }

    getUserPosts(userId: number, type: string, offset?: number, limit?: number, publishAt?: string): Observable<any> {
      let data: any = {};
      if (!!offset) {
        data["offset"] = offset;
      }
      if (!!limit) {
        data["limit"] = limit;
      }
      if(!!userId) {
        data["userId"] = userId;
      }
      if(!!type) {
        data["type"] = type;
      }
      if(!!publishAt) {
        data["publishAt"] = publishAt;
      }
      return this.middlewareService.get("/api/users_posts", true, data);
    }

    uploadFile(body: any, filename: any, content_type: any, showProgress = false): Observable<any> {
        filename = encodeURIComponent(filename);
        let vncDirectoryPublicUrl = this.configService.get("vncDirectoryPublicUrl");
        let uploadFileHeader = new HttpHeaders({
            "Content-Type": "application/octet-stream",
        });
        if (!!vncDirectoryPublicUrl) {
            if (!vncDirectoryPublicUrl.endsWith("/")) {
                vncDirectoryPublicUrl = vncDirectoryPublicUrl + "/";
            }
            const jwtToken = localStorage.getItem("token");
            const apiResponse = this.middlewareService.postWithoutBaseUrl(
                `${vncDirectoryPublicUrl}uploads.json?filename=${filename}&content_type=${content_type}&jwt=${jwtToken}`,
                false, body, uploadFileHeader, false);
            if (!!showProgress) {
                return apiResponse;
            } else {
                return apiResponse.pipe(
                    filter((v: any) => {
                        return !!CommonUtil.isHttpResponse(v);
                    }),
                    map((v: any) => v.body),
                );
            }
        } else {
            if (this.isCordovaOrElectron) {
                uploadFileHeader = uploadFileHeader.set("Authorization", localStorage.getItem("token"));
            }
            return this.middlewareService.post(`/api/uploads?filename=${filename}&content_type=${content_type}`, false, body, uploadFileHeader);
        }
    }

    uploadProfileHeaderImage(body: any) {
      return this.middlewareService.post("/api/my/update_header", true, body);
    }

    createPost(body: any) {
        return this.middlewareService.post(`/api/posts`, true, body);
    }

    deletePost(postId) {
        return this.middlewareService.delete(`/api/posts/${postId}`, true);
    }

    getAllPostReply(postId) {
        let data: any = {};
        data["postId"] = postId;
        return this.middlewareService.get(`/api/posts`, true, data);
    }

    bookMarkPost(postId): Observable<{topic: Post}> {
        return this.middlewareService.post(`/api/posts/${postId}/bookmark`, true, {});
    }

    unBookMarkPost(postId): Observable<{topic: Post}> {
        return this.middlewareService.post(`/api/posts/${postId}/unbookmark`, true, {});
    }

    undoRepost(postId): Observable<{topic: Post}> {
      return this.middlewareService.post(`/api/posts/${postId}/undo_repost`, true, {});
    }

    likePost(postId): Observable<{topic: Post}> {
        return this.middlewareService.post(`/api/posts/${postId}/like`, true, {});
    }

    unlikePost(postId): Observable<{topic: Post}> {
        return this.middlewareService.post(`/api/posts/${postId}/unlike`, true, {});
    }

    getFollowersList(jid, offset?: number, limit?: number): Observable<any> {
        let data: any = {};
        if (!!offset) {
            data["offset"] = offset;
        }
        if (!!limit) {
            data["limit"] = limit;
        }
        return this.middlewareService.get(`/api/followers/following/followers_list/${jid}`, true, data);
    }

    getFollowingList(jid, offset?: number, limit?: number): Observable<any> {
        let data: any = {};
        if (!!offset) {
            data["offset"] = offset;
        }
        if (!!limit) {
            data["limit"] = limit;
        }
        return this.middlewareService.get(`/api/followers/following/following-list/${jid}`, true, data);
    }

    getFollowerDetails(jid): Observable<{user: SocialProfile}> {
      return this.middlewareService.get(`/api/social/follower-details/${jid}`, true);
    }

    updateSocialProfile(userData: any): Observable<{user: SocialProfile}> {
      const data = {user: userData};
      return this.middlewareService.post(`/api/social/update-profile/`, true, data);
    }

    searchFollowUnfollow(keyword: string, offset?: number, limit?: number): Observable<any> {
        let data: any = {};
        if (!!offset) {
            data["offset"] = offset;
        }
        if (!!limit) {
            data["limit"] = limit;
        }
        return this.middlewareService.get(`/api/followers/following/search/${keyword}`, true, data);
    }

    followUser(jid): Observable<any> {
        return this.middlewareService.post(`/api/followers/following/follow/${jid}`, true, {});
    }

    unFollowUser(jid): Observable<any> {
        return this.middlewareService.post(`/api/followers/following/unfollow/${jid}`, true, {});
    }

    // removeFromfollowers(jid): Observable<any> {
    //     return this.middlewareService.post(`/api/followers/following/remove_follower/${jid}`, true, {});
    // }

    getSocialPostById(postId): Observable<any> {
        return this.middlewareService.get(`/api/posts/${postId}`, true);
    }

    updatePost(postId, body) {
        return this.middlewareService.put(`/api/posts/${postId}`, true, body);
    }

    getProfileAnalytics(startDate, endDate) {
      return this.middlewareService.get(`/api/my/analytics?start_date=${startDate}&end_date=${endDate}`, true);
    }

    muteUser(jid): Observable<any> {
        return this.middlewareService.post(`/api/followers/${jid}/mute`, true, {});
    }

    unmuteUser(jid): Observable<any> {
        return this.middlewareService.post(`/api/followers/${jid}/unmute`, true, {});
    }
}
