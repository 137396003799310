
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->


<vnc-dialog [classes]="['send-email-to-friends-container']" headerText="{{ (pad.action !== 'rename' ? 'NEW_DOCUMENT' : 'RENAME_PAD') | translate }}"
   [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>

<ng-template #bodyTemplate>
  <div class="email-section">
    <vnc-input [form]="padName" [showLabel]="true" class="mb-24" 
      placeholder="{{ (pad.action !== 'rename' ? 'NEW_DOCUMENT_NAME' : 'UPDATE_DOCUMENT_NAME') | translate}}">
    </vnc-input>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="footer-actions">
    <vnc-secondary-button (onClick)="closeDialog()" [size]="'s'" [label]="'CANCEL' | translate | uppercase">
    </vnc-secondary-button>
    <vnc-primary-button (onClick)="createOrUpdatePad()" [size]="'s'" [fixedSize]="96" [isFixedSize]="true" [label]="(pad.action !== 'rename' ? 'CREATE' : 'UPDATE') | translate | uppercase">
    </vnc-primary-button>
  </div>
</ng-template>

