
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { RFCService } from "../services/rfc.service";
import { Observable } from "rxjs";
import { ToastService } from "app/shared/services/toast.service";
import { Broadcaster } from "../shared/providers";
import { VNCTalkNotificationsService } from "../notifications";

@Injectable()
export class RFCRepository {

    constructor(
        private rfcService: RFCService,
        private toastService: ToastService,
        private broadcaster: Broadcaster,
        private notificationService: VNCTalkNotificationsService
        ) {

        this.broadcaster.on<any>("vncdirectoryrfcnotify").subscribe( (message) => {
            this.notificationService.rfcNotification("RFC_INVITATION", message.rfc.message, message.fromJid, { "token": message.rfc.token });
        });

        this.broadcaster.on<any>("acceptrfcinvitation").subscribe( (token) => {
            if (token) {
                this.acceptInvitation(token).subscribe( () => {
                   this.toastService.show("RFC_INVITATION_ACCEPT_SUCCESS");
                }, () => {
                   this.toastService.show("RFC_INVITATION_ACCEPT_ERROR");
                });
            }
        });

        this.broadcaster.on<any>("denyrfcinvitation").subscribe( (token) => {
            if (token) {
                this.declineInvitation(token).subscribe( () => {
                    this.toastService.show("RFC_INVITATION_DECLINE_SUCCESS");
                }, () => {
                    this.toastService.show("RFC_INVITATION_DECLINE_ERROR");
                });
            }
        });
    }

    public searchUsers(keyword: string): Observable<any> {
        return this.rfcService.searchUsers(keyword);
    }

    public sendInvitation(userId: number): Observable<any> {
        return this.rfcService.sendInvitation(userId);
    }

    public resendInvitation(token: string): Observable<any> {
        return this.rfcService.resendInvitation(token);
    }

    public sendInvitationMessage(email: string, message: string): Observable<any> {
        return this.rfcService.sendInvitationMessage(email, message);
    }

    public acceptInvitation(token: string): Observable<any> {
        return this.rfcService.acceptInvitation(token);
    }

    public declineInvitation(token: string): Observable<any> {
        return this.rfcService.declineInvitation(token);
    }
}
