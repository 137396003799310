
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HostListener, Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable, take, throwError } from "rxjs";
import { environment } from "./environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConstantsUtil } from "./talk/utils/constants.util";
import { BehaviorSubject, Subject } from "rxjs";
import { LoggerService } from "./shared/services/logger.service";
import { CommonUtil } from "app/talk/utils/common.util";

@Injectable()
export class ConfigService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  private loginIFrameHandled = false;

  private lang = ["en", "de", "fr"];

  private showLoginIframeKeyboardTimer;

  private config = {
    // Feature registry powered PORTAL_URL variable, default to plone-dev
    PORTAL_URL: "",
    knownIOMDomains: [],
    BASE_URI: "",
    DEMO_URL: "",
    GOOGLE_STATIC_MAP_API_KEY: "",

    CASTS_BATCH_SIZE: "",

    // Key used in local storage to store authentication token
    AUTH_TOKEN_KEY: "auth.jwt",

    PAGE_BRANDING: "",

    //
    // Cast Configuration
    //
    // CASTS_REFRESH_TIME: 60000,

    //
    // Stream configuration
    //

    // Get Icon from Entry type
    STREAM_ENTRY_ICON_MAP: {
      "folder": "folder",
      "collection": "folder_open",
      "image": "image",
      "file": "stay_current_portrait",
      "news": "settings_input_antenna",
      "event": "event_available",
      "link": "bookmark",
      "comment": "chat_bubble_outline",
      "default": "web_asset"
    },

    // Stream Filter
    STREAM_FILTER_KEY: "vnccollab.cloustrearm.filter",

    STREAM_FILTER_BUTTON_LABELS: [
      "Casts",
      "Events",
      "Files",
      "Forms",
      "Images",
      "Links",
      "News",
      "Pages"
    ],

    STREAM_FILTER_BUTTON_TYPES_MAP: {
      "Casts": ["Cast Update"],
      "Events": ["Event"],
      "Files": ["File"],
      "Forms": ["FormFolder"],
      "Images": ["Image"],
      "Links": ["Link"],
      "News": ["News Item"],
      "Pages": ["Document", "TablePage"]
    },
    "appsEnabled": [{
      "title": "VNCtalk",
      "route": "/talk",
      "active": true,
      "enabled": true
    }],
    "lang": "en"
  };

  appInBackground = false;
  API_URL = "";
  DEMO_URL = "https://talk.lab1.demo.vnc.de";
  OAUTH2_BACKEND_URL = "https://oauth2.hin.uxf.zimbra-vnc.de";
  OAUTH2_CLIENT_ID = "ch.hin.talk.mobile";
  OAUTH2_CLIENT_SECRET = "Cvdy}eTd3M7adE&8";
  // FIXME: remove static fields, use config keys
  // xmppSecret = "";
  boshURL = "";
  xmppWsUrl = "";
  avatarServiceUrl = !!localStorage.getItem("avatarServiceUrl") ? localStorage.getItem("avatarServiceUrl") : "https://avatar.vnc.biz";
  fileShareService = !!localStorage.getItem("fileShareService") ? localStorage.getItem("fileShareService") : "https://phpfile.uxf.zimbra-vnc.de";
  // FIXME: belongs into config on server
  GOOGLE_STATIC_MAP_API_KEY = "AIzaSyDuvK3ofJGLlmeOaQ1WPApAumWbVENDgd0";
  selectedServer = false;
  notificationStatus = false;
  data: any;
  backendConfig: any;

  LAST_ACTIVITY_BATCH_ITERVAL =  5 * 60 * 1000; // 5 mins
  // LAST_ACTIVITY_BATCH_ITERVAL =  10 * 1000; // 10 seconds (for tests)
  LAST_ACTIVITY_ONLINE_THRESHHOLD = 300; // 5 mins

  CONTACTS_REST_PER_PAGE = 100;

  PROFILE_AND_CONFIG_RETRIEVAL_RECOVERY_TIMES = 3;
  PROFILE_AND_CONFIG_RETRIEVAL_RECOVERY_INTERVAL = 3000;

  private config$ = new BehaviorSubject<any>(this.config);
  isProfileLoaded$ = new BehaviorSubject<boolean>(false);
  isAnonymous: boolean;
  REDMINE_URL = "https://redmine.vnc.biz";
  private _userJid = "";
  constructor(private http: HttpClient, private logger: LoggerService) {
    this.logger.info("[ConfigService][constructor]");
  }

  setUserJid(jid) {
    this._userJid = jid;
  }

  getUserJid() {
    return this._userJid;
  }

  loadConfig() {
    this.logger.info("[ConfigService][loadConfig]");
    this.logger.info("[ConfigService][loadConfig]", JSON.stringify(window.BuildInfo));

    if (!this.isCordovaOrElectron) {
      this.logger.info("[loadConfig] Not Cordova or Electron app");

      this.setAPIURL("");
      this.selectedServer = true;
    } else {
      if (window.BuildInfo && (window.BuildInfo.basePackageName === "biz.vnc.vnctalk.hinpp"
                            || window.BuildInfo.basePackageName === "biz.vnc.vnctalk.hinppch")) {
        this.OAUTH2_BACKEND_URL = "https://oauth2.talk.hintest.ch";
        localStorage.setItem("serverURL", this.OAUTH2_BACKEND_URL);
      }
      // else {
      //   this.OAUTH2_BACKEND_URL = "https://oauth2.talk.hin.ch";
      //   if (!localStorage.getItem("serverURL")) {
      //     localStorage.setItem("serverURL", environment.defaultServerUrl);
      //   }
      // }
      if (!!localStorage.getItem("serverURL")) {
        this.logger.info("[loadConfig] ", localStorage.getItem("serverURL"));

        this.selectedServer = true;
        this.setAPIURL(localStorage.getItem("serverURL").trim());
      } else if (environment.theme === "hin") {
        this.selectedServer = true;
        if (localStorage.getItem("usehinclient") !== "2") {
          this.setAPIURL(this.OAUTH2_BACKEND_URL);
        }
      }
    }
  }

  getLoadedConfig() {
    return this.config$.asObservable();
  }

  private configChangeTimerId = null;
  set(key: string, value: any): any {
    this.config[key] = value;

    // at start app we retrieve config from server and call .set for each of the params
    // which produces lots of 'getLoadedConfig()' calls. We want to minimize it to only one here.
    if (this.configChangeTimerId) {
      clearTimeout(this.configChangeTimerId);
    }
    this.configChangeTimerId = setTimeout(() => {
      this.configChangeTimerId = null;
      this.config$.next(this.config);
    }, 200);

    return value;
  }

  get(key: string): any {
    let result = this.config[key];
    return result;
  }

  getOrThrow(key: string): any {
    if (!this.config.hasOwnProperty(key)) {
      throw "Missing '" + key + "' in config";
    }

    return this.config[key];
  }

  setAPIURL(url: string): void {
    this.API_URL = url;

    localStorage.setItem(ConstantsUtil.KEY_SERVER_URL, url.trim());

    this.logger.info("[ConfigService][setAPIURL]", url);
  }

  storedAPIUrl() {
    return localStorage.getItem(ConstantsUtil.KEY_SERVER_URL);
  }

  showError(msg) {
    this.logger.info(`Invalid configuration: ${msg}`);
  }

  getSupportedLangs() {
    return this.lang.slice();
  }

  getDefaultLang() {
    return this.lang[0];
  }

  getConfiguredLang() {
    let lang = this.get("lang");
    if (this.lang.indexOf(lang) >= 0)
      return lang;
    return this.getDefaultLang();
  }

  isAppEnabled(appTitle: string) {
    let apps = this.config["appsEnabled"] || [];
    let app = apps.filter(app => app.title === appTitle)[0];
    return app && app.enabled;
  }

  setServerUrl(url: string) {
    this.config["PORTAL_URL"] = url;
    localStorage.setItem("portal_url", url);
  }

  setDefaultServerUrl() {
    const url = this.get("PORTAL_URL");
    this.setServerUrl(url);
    return url;
  }

  getServerUrl() {
    return localStorage.getItem("portal_url");
  }

  clearCache() {
    localStorage.removeItem("portal_url");
  }

  redirectToLoginScreen() {
    this.logger.info("[ConfigService][redirectToLoginScreen]", this.isCordovaOrElectron);

    if (!this.isCordovaOrElectron) {
      window.location.href = this.API_URL + "/api/login";
    } else {
      this.loginIframe();
    }
  }

  clearStorage(){
    this.logger.info("[ConfigService][clearStorage]");

    // clear storage but save a server url & user login
    const serverURL = localStorage.getItem(ConstantsUtil.KEY_SERVER_URL);
    const username = localStorage.getItem(ConstantsUtil.KEY_USERNAME);

    const electronIsSnap = localStorage.getItem("electronIsSnap");
    const electronSnapAudioConnected = localStorage.getItem("electronSnapAudioConnected");
    const electronSnapCamConnected = localStorage.getItem("electronSnapCamConnected");

    //
    localStorage.clear();
    //
    localStorage.setItem(ConstantsUtil.KEY_SERVER_URL, serverURL);
    localStorage.setItem(ConstantsUtil.KEY_USERNAME, username);

    localStorage.setItem("electronIsSnap", electronIsSnap);
    localStorage.setItem("electronSnapAudioConnected", electronSnapAudioConnected);
    localStorage.setItem("electronSnapCamConnected", electronSnapCamConnected);

  }

  loginIframe() {
    this.logger.info("[ConfigService][loginIframe] selectedServer: ", this.selectedServer);

    if (!this.selectedServer || this.isAnonymous) {
      this.logger.info("[ConfigService][loginIframe] return");
      return;
    }

    let iframe = document.createElement("iframe");
    if (iframe && !document.querySelector("#loginIframe")) {
      const initialHref = window.location.href.split("/talk")[0];
      const loginUrl = `${initialHref}/assets/login.html`;
      this.logger.info("[config.service] launching login iframe with url", loginUrl);

      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      // iframe.onload = () => {
      //   this.logger.info("[loginIframe] in onload");
      // };

      if (document.querySelector("vp-portal") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("vp-portal").appendChild(iframe);
      } else {
        if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
          document.querySelector("body").appendChild(iframe);
        }
      }
      iframe = null;
      // by some reason, a standard '.focus()' does not work in login.html for Android
      this.showKeyboard();

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
        commander = null;
      }
    } else {
      this.logger.info("[config.ts] no iframe");
    }
  }

  hideLoginIframe() {
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();

      this.hideKeyboard();
    }
  }

  tfaOtpIframe() {
    this.logger.info("[ConfigService][tfaOtpIframe] selectedServer: ", this.selectedServer);

    if (!this.selectedServer) {
      this.logger.info("[ConfigService][tfaOtpIframe] return");
      return;
    }

    let iframe = document.createElement("iframe");
    if (iframe) {
      const initialHref = window.location.href.split("/talk")[0];
      const loginUrl = `${initialHref}/assets/tfa-otp.html`;
      this.logger.info("[config.service] launching tfa otp iframe with url", loginUrl);

      iframe.id = "tfaOtpIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      // iframe.onload = () => {
      //   this.logger.info("[loginIframe] in onload");
      // };

      if (document.querySelector("vp-portal") !== null && document.querySelector("#tfaOtpIframe") === null) {
        document.querySelector("vp-portal").appendChild(iframe);
      } else {
        if (document.querySelector("body") !== null && document.querySelector("#tfaOtpIframe") === null) {
          document.querySelector("body").appendChild(iframe);
        }
      }

      // by some reason, a standard '.focus()' does not work in login.html for Android
      this.showKeyboard();

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
      }
    } else {
      this.logger.info("[config.ts] no iframe");
    }
  }

  hideTfaOtpIframe() {
    if (document.querySelector("#tfaOtpIframe") !== null) {
      document.querySelector("#tfaOtpIframe").remove();

      this.hideKeyboard();
    }
  }

  showKeyboard() {
    if (environment.isCordova && typeof Keyboard !== "undefined")  {
      this.showLoginIframeKeyboardTimer = setTimeout(() => {
        Keyboard.show();
        this.showLoginIframeKeyboardTimer = null;
      }, 100);
    }
  }

  hideKeyboard(){
    // by some reason, a standard '.focus()' does not work in login.html for Android,
    // so we show a keyboard in 'config.service.ts' using a Keyboard class.
    // So then we needd to hide it here once login is successful

    if  (!this.showLoginIframeKeyboardTimer) {
      clearTimeout(this.showLoginIframeKeyboardTimer);
      this.showLoginIframeKeyboardTimer = null;
    } else {
      if (environment.isCordova && typeof Keyboard !== "undefined")  {
        setTimeout(() => {
          Keyboard.hide();
        }, 100);
      }
    }
  }

  private buildHeaders(): HttpHeaders {
    let mutatedHeaders: HttpHeaders = new HttpHeaders();
    return mutatedHeaders.set("Content-Type", "application/json").set("Accept", "application/json").set("Authorization", localStorage.getItem("token"));
  }

  getConfigFromServer(): Observable<any> { // FIXME: this at least has a bad name. also we have two configs: this /api/config and config.json from loadConfig()
    const apiConfigURL = this.API_URL + "/api/config";

    if (this.isCordovaOrElectron) {
      return this.http.get(apiConfigURL, { headers: this.buildHeaders() });
    } else {
      return this.http.get(apiConfigURL);
    }
  }

  getConfig(refresh: boolean = false): Observable<any> { // FIXME: this at least has a bad name. also we have two configs: this /api/config and config.json from loadConfig()
    const response = new BehaviorSubject<any[]>([]);
    const cachedConfigString = localStorage.getItem("configFromServer");
    let cachedConfig;
    try {
      if (!!cachedConfigString && (cachedConfigString !== "")) {
        cachedConfig = JSON.parse(cachedConfigString);
      }
    } catch (e) {
      this.logger.error("[configService][getConfig] error restoring cachedConfig: ", e);
      this.logger.sentryErrorLog("[configService][getConfig] error restoring cachedConfig: ", e);
    }
    const hasCachedConfig = !!cachedConfig && (cachedConfigString !== "");

    if (!refresh && hasCachedConfig && (cachedConfigString.indexOf("jitsiAvailable") > -1)) {
      this.logger.info("[configService][getConfig] cachedConfig: ", cachedConfig);
      response.next(cachedConfig);
    } else {
      this.getConfigFromServer().subscribe(data => {
        this.logger.info("[configService][getConfig] dataFromServer: ", data);
        let jsonData;
        try {
          jsonData = JSON.stringify(data);
          localStorage.setItem("configFromServer", jsonData);
        } catch (e) {
          this.logger.error("error serializing config data: ", e);
          this.logger.sentryErrorLog("error serializing config data: ", e);
        }

/*        let configEqual = CommonUtil.compareObjects(data, cachedConfig);
        this.logger.info("[configService][getMyProducts] configEqual: ", configEqual);

        if (CommonUtil.compareObjects(data, cachedConfig)) {
          data["unchanged"] = true;
        } */
        response.next(data);
      });
    }
    // return response.asObservable().pipe(take(1));
    return response.asObservable();
  }

  getOMEMODeviceSubscribers(): Observable<any> {
    const apiConfigURL = this.API_URL + "/api/getOMEMODeviceSubscriptions";
    if (this.isCordovaOrElectron) {
      return this.http.get(apiConfigURL, { headers: this.buildHeaders() });
    } else {
      return this.http.get(apiConfigURL);
    }
  }

  getMyProductsFromServer(): Observable<any> {
    const apiConfigURL = this.API_URL + "/api/my-products";
    if (this.isCordovaOrElectron) {
      return this.http.get(apiConfigURL, { headers: this.buildHeaders() });
    } else {
      return this.http.get(apiConfigURL);
    }
  }


  getMyProducts(refresh:boolean = false): Observable<any> {
    const response = new BehaviorSubject<any[]>([]);
    const cachedConfigString = localStorage.getItem("myProductsFromServer");
    // this.logger.info("[configService][getMyProducts] cachedConfigString: ", refresh, cachedConfigString);
    let cachedConfig;
    try {
      if (!!cachedConfigString && (cachedConfigString !== "")) {
        cachedConfig = JSON.parse(cachedConfigString);
      }
    } catch (e) {
      this.logger.error("[configService][getMyProducts] error restoring cachedConfig: ", e);
      this.logger.sentryErrorLog("[configService][getMyProducts] error restoring cachedConfig: ", e);
    }

    const hasCachedConfig = !!cachedConfig;
    this.logger.info("[configService][getMyProducts] cachedConfigParse: ", hasCachedConfig, cachedConfigString, cachedConfig);

    if (!hasCachedConfig) {
      this.getMyProductsFromServer().subscribe(data => {
        this.logger.info("[configService][getMyProducts] dataFromServer: ", data);
        let jsonData;
        try {
          jsonData = JSON.stringify(data);
          localStorage.setItem("myProductsFromServer", jsonData);
        } catch (e) {
          this.logger.error("error serializing config data: ", e);
          this.logger.sentryErrorLog("error serializing config data: ", e);
        }
        response.next(data);
      });
    } else {
      // this.logger.info("[configService][getMyProducts] using cachedConfig: ", cachedConfig);
      response.next(cachedConfig);
    }

    return response.asObservable();
  }

  getJitsiConfig(jitsiUrl: string, jitsiRoomId: string): Observable<any> { // FIXME: this at least has a bad name. also we have two configs: this /api/config and config.json from loadConfig()
    // const configEndpoint = `${jitsiUrl}config.js`;
    // return this.http.get(configEndpoint);
    if (!jitsiUrl) {
      jitsiUrl = this.get("jitsiURL");
    }
    this.logger.info("[ConfigService][loadJitsiConfig]", jitsiUrl, jitsiRoomId);

    const response = new Subject<any>();

    if (!jitsiUrl) {
      response.next({});
      return;
    }

    if (document.querySelector("#jitsiConfig") !== null) {
      document.querySelector("#jitsiConfig").remove();
    }

    let script = document.createElement("script");
    script.id = "jitsiConfig";
    script.src = `${jitsiUrl}config.js`;
    if (!!jitsiRoomId) {
      this.logger.info("loadJitsiConfig with roomId: ", jitsiUrl, jitsiRoomId);
      script.src = `${jitsiUrl}config.js?room=` + jitsiRoomId;
    }
    script.onload = () => {

      const jitsiConfig = window.config;

      // we want to use WSS not BOSH
      if (!jitsiConfig.serviceUrl && jitsiConfig.websocket) {
        jitsiConfig.serviceUrl = jitsiConfig.websocket;
      }

      if (!jitsiConfig.applicationName) {
        jitsiConfig.applicationName = "VNCtalk";
      }

      if (!!jitsiRoomId) {
        jitsiConfig.serviceUrl += "?room=" + jitsiRoomId;
        jitsiConfig.websocket += "?room=" + jitsiRoomId;
      }

      // temporary disable Call Stats because of infinate lopp issue on iOS

      delete jitsiConfig.callStatsID;
      delete jitsiConfig.callStatsSecret;

      // jitsiConfig.enableIceRestart = true;

      this.logger.info("[ConfigService][loadJitsiConfig] onload", window.config);
      response.next(jitsiConfig);
    };
    script.onerror = (error: any) => {
      this.logger.error("[ConfigService][loadJitsiConfig] error", error);
      this.logger.sentryErrorLog("[ConfigService][loadJitsiConfig] error", error);
      response.error(error);
    };
    document.querySelector("head").appendChild(script);
    script = null;
    return response.asObservable().pipe(take(1));
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleErrorObservable(error: Response | any) {
    return throwError(error && error.message ? error.message : error);
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error && error.message ? error.message : error);
  }

  isWhiteboardAvailable() {
    return this.get("whiteBoardAvailable") && !environment.disableWhiteboard;
  }

  @HostListener("window:resize", ["$event"])
  handleLoginResize() {
    this.logger.info("[loginIframe] in window resize");
    // Added this because of the following reasons:
    // 1. When adding iFrame with position: fixed, scrolling doesn't work...
    // 1. When adding iFrame with position: absolute, white bar shows on startup and disappears on opening keyboard...

    if (!this.loginIFrameHandled) {
      this.loginIFrameHandled = true;
      document.getElementById("loginIframe").style.position = "absolute";
    }
  }
}
