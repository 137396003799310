<div *ngIf="!data?.isMobileView" class="smart-link-preview-container"
  [ngClass]="{'multiple' : ($singlePreviewVisible | async) === false}">
  <div class="action-header" *ngIf="!data.hasOwnProperty('previewMode') || !data.previewMode">
    <div class="action-header__title">
      <div class="back-btn" (click)="$singlePreviewVisible.next(false)"
        *ngIf="filterSmartLinkSearchResult(($filteredSmartSearchResults | async))?.length > 1 && ($singlePreviewVisible | async)">
        <ng-container [ngTemplateOutlet]="backIconTemplate"></ng-container>
      </div>
      Smartlink
    </div>
    <div class="action-header__actions" *ngIf="data?.isEditable">
      <mat-icon class="icon-18 blue-hover" *ngIf="data?.isAnalyticsTopic" [ngClass]="{'active': menuOpened}"
        svgIcon="add_icon" (click)="menuOpened = true" [matMenuTriggerFor]="smartObjectsMenu"></mat-icon>
      <mat-icon class="icon-18 blue-hover" *ngIf="!data?.isAnalyticsTopic" svgIcon="add_icon"
        (click)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'add' })"></mat-icon>
      <mat-icon class="icon-18 blue-hover" *ngIf="($singlePreviewVisible | async)" svgIcon="edit_icon"
        (click)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'single_edit' })"></mat-icon>
      <mat-icon class="icon-18 blue-hover" svgIcon="delete"
        (click)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'delete' })"></mat-icon>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="mainBodyTemplate"></ng-container>
</div>

<div *ngIf="data?.isMobileView" class="smart-link-preview-container-mobile">
  <vnc-dialog [classes]="['mobile-smart-link']" [headerTemplate]="mobileHeaderTemplate"
    classes="fixed-size with-border with-border-radius" [bodyTemplate]="mainBodyTemplate">
  </vnc-dialog>

  <ng-template #mobileHeaderTemplate>
    <vnc-mobile-header headerType="dark-back-more"
      darkBackMorePlaceHolder="{{ ($singlePreviewVisible | async) === false ? ('SMARTLINK' | translate) + ' ('+filterSmartLinkSearchResult(($filteredSmartSearchResults | async)).length+')' : ('SMARTLINK' | translate) }}"
      (darkSuffixButtonPress)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'update' })"
      [darkSuffixIcon]="($showFooterButtons | async) ? 'mdi-check' : ''"
      (darkBackPress)="handleMobileHeaderActionClicks($event)">
    </vnc-mobile-header>
  </ng-template>
</div>

<ng-template #mainBodyTemplate>
  <div class="main-body">
    <ng-container *ngIf="$singlePreviewVisible | async">
      <vnc-smart-link-preview [ngClass]="{'pt-0': !data.hasOwnProperty('previewMode') || !data.previewMode}"
        [previewType]="($selectedSmartLinkPreviewData | async)?.previewType"
        [previewData]="($selectedSmartLinkPreviewData | async)?.previewData" (click)="openSmartLinkEntity()"
        (onScroll)="onPreviewScroll()"></vnc-smart-link-preview>
    </ng-container>
    <ng-container *ngIf="($singlePreviewVisible | async) === false">
      <vnc-input label="Link texts" [type]="data?.isMobileView ? 'text' : 'questionmark'"
        class="questionmark-input flex" placeholder="Search by link text" [showLabel]="true"
        [form]="smartLinkControl"></vnc-input>

      <div class="smart-link-search-results vnc-scroll-bar--small--vertical">
        <ng-container
          *ngFor="let searchResult of filterSmartLinkSearchResult(($filteredSmartSearchResults | async)); let i = index;">
          <div class="smart-entity-wrapper">
            <div [ngClass]="{'mobile-search-result': data?.isMobileView}" class="smart-object-search-result-wrapper"
              id="{{searchResult?.id}}" (swipeleft)="handleSwipeForEntity(searchResult?.id, swipe.LEFT)"
              (swiperight)="handleSwipeForEntity(searchResult?.id, swipe.RIGHT)">
              <vnc-smart-link-search-result [avatarTemplate]="avatarTemplate" [resultData]="searchResult"
                [showActionIconsOnHover]="data?.isEditable && !data?.isMobileView"
                [resultType]="searchResult?.resultType" (actionClicked)="onHoverActionClick($event, searchResult)"
                (click)="changeSmartLinkPreviewMode(searchResult)" [mobileMode]="data?.isMobileView"
                [topicsLabel]="searchResult?.topics_count === 1 ? 'topic' : 'topics'">
              </vnc-smart-link-search-result>
              <ng-template #avatarTemplate>
                <vnc-avatar class="channel-avatar" *ngIf="searchResult?.resultType === 'CHANNEL'"
                  [imgSrc]="searchResult?.avatar_url" [defaultAvatarTemplate]="defaultChannelAvatarTemplate"
                  [rightIcon]="searchResult?.author?.avatar_url"
                  [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                  [totalUnread]="searchResult?.unread_topics_count"></vnc-avatar>
                <vnc-avatar class="topic-avatar" *ngIf="searchResult?.resultType === 'TOPIC'"
                  [imgSrc]="searchResult?.heroAttachmentThumbnailUrl"
                  [defaultAvatar]="searchResult?.default_cover_url || searchResult?.default_cover"
                  [rightIcon]="searchResult?.author?.avatar_url"
                  [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                  [totalUnread]="searchResult?.unread_comments_count"></vnc-avatar>
                <vnc-avatar *ngIf="searchResult?.resultType === 'COMMENT'"
                  [imgSrc]="searchResult?.author?.avatar_url"></vnc-avatar>
              </ng-template>
            </div>
            <div class="swipe-delete-button"
              (click)="onHoverActionClick({clickEvent: $event, actionType: 'delete'}, searchResult)">
              <ng-container [ngTemplateOutlet]="deleteIconTemplate"></ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="action-buttons-row"
        *ngIf="!data?.isMobileView && ($showFooterButtons | async) && data?.isEditable && (!data.hasOwnProperty('previewMode') || !data.previewMode)">
        <vnc-button [height]="'small'" [shape]="'rectangle'" [type]="'link'" [label]="'CANCEL' | translate"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'cancel' })"
          class="vnc-default"></vnc-button>
        <vnc-button [height]="'small'" [shape]="'rectangle'" [type]="'primary'" [label]="'SAVE' | translate"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'update' })"></vnc-button>
      </div>
    </ng-container>
  </div>

  <vnc-floating-button [iconTemplate]="showActions ? closeIcon : plusIcon"
    *ngIf="data?.isEditable && data?.isMobileView" class="new-object-button" id="newConversationMobile"
    (onClick)="$event.stopPropagation();showActions = !showActions" size="m"
    backgroundColor="blue600"></vnc-floating-button>

  <div *ngIf="data?.isEditable && showActions" class="mobile-action-backdrop" (click)="showActions = false">
    <ul class="smart-object-ul">
      <li (click)="$event.stopPropagation()" *ngIf="data?.isAnalyticsTopic">
        <span class="border-lable">{{ 'HIGHLIGHT' | translate }}</span>
        <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="highlightIconTemplate"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_HIGHLIGHT });showActions=false">
        </vnc-floating-button>
      </li>
      <li (click)="$event.stopPropagation()" *ngIf="data?.isAnalyticsTopic">
        <span class="border-lable">{{ 'STICKY_NOTE' | translate }}</span>
        <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="stickyNoteMobileIcon"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_STICKY_NOTE });showActions=false">
        </vnc-floating-button>
      </li>
      <li (click)="$event.stopPropagation()">
        <span class="border-lable">{{ 'SMARTLINK' | translate }}</span>
        <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="smartlinkMobileIcon"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'add' });showActions=false">
        </vnc-floating-button>
      </li>
      <li (click)="$event.stopPropagation()" *ngIf="data?.isAnalyticsTopic">
        <span class="border-lable">{{ 'REFERENCE' | translate }}</span>
        <vnc-floating-button class="mobile-floating-action-button" size="s" [iconTemplate]="referenceIconTemplate"
          (onClick)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_REFERENCE });showActions=false">
        </vnc-floating-button>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #defaultChannelAvatarTemplate>
  <img class="channel-default-avatar" [src]="defaultChannelAvatar" alt="">
</ng-template>

<ng-template #backIconTemplate>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
      <g fill="#000">
        <g>
          <g>
            <path d="M16 7L3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9z"
              transform="translate(-12 -12) translate(12 12) translate(4 4)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #smartLinkIconTemplate>
  <mat-icon svgIcon="smartlink"></mat-icon>
</ng-template>

<ng-template #stickyNoteIconTemplate>
  <mat-icon svgIcon="sticky-note"></mat-icon>
</ng-template>

<ng-template #highlightIconTemplate>
  <mat-icon svgIcon="highlight"></mat-icon>
</ng-template>

<ng-template #referenceIconTemplate>
  <mat-icon svgIcon="reference"></mat-icon>
</ng-template>

<mat-menu #smartObjectsMenu class="mat-menu-container" (close)="menuOpened = false">
  <ng-container [ngTemplateOutlet]="smartObjectsMenuOptionsTemplate"></ng-container>
</mat-menu>

<ng-template #smartObjectsMenuOptionsTemplate>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'" [prefixIconTemplate]="smartLinkIconTemplate"
      label="{{'ADD_SMARTLINK' | translate}}"
      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: 'add' })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'" [prefixIconTemplate]="stickyNoteIconTemplate"
      label="{{'ADD_STICKY_NOTE' | translate}}"
      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_STICKY_NOTE })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'" [prefixIconTemplate]="highlightIconTemplate"
      label="{{'ADD_HIGHLIGHT' | translate}}"
      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_HIGHLIGHT })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'" [prefixIconTemplate]="referenceIconTemplate"
      label="{{'ADD_REFERENCE' | translate}}"
      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: smartObjectActions.ADD_REFERENCE })">
    </vp-dropdown-item>
  </div>
</ng-template>

<ng-template #deleteIconTemplate>
  <mat-icon class="icon-18 blue-hover" svgIcon="delete"></mat-icon>
</ng-template>

<ng-template #plusIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"
          in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0"
          in="shadowBlurOuter2" result="shadowMatrixOuter2" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-16 -16)" fill="#FFF" fill-rule="evenodd">
      <path d="M35 29h-6v6h-2v-6h-6v-2h6v-6h2v6h6z" />
    </g>
  </svg>
</ng-template>

<ng-template #closeIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"
          in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0"
          in="shadowBlurOuter2" result="shadowMatrixOuter2" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g transform="rotate(90 28 12)" fill="#FFF" fill-rule="evenodd">
      <path
        d="M35 22.41 33.59 21 28 26.59 22.41 21 21 22.41 26.59 28 21 33.59 22.41 35 28 29.41 33.59 35 35 33.59 29.41 28z" />
    </g>
  </svg>
</ng-template>

<ng-template #smartlinkMobileIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-26%" y="-26%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"
          in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"
          in="shadowBlurOuter2" result="shadowMatrixOuter2" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-12 -12)" fill="#FFF" fill-rule="evenodd">
      <path
        d="m25.016 14-2.017 2.016V16L16 16v17h14v-5.188l2-2V33a2 2 0 0 1-2 2H16a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h9.016zm3.497 5.094c.266 0 .492.11.68.328 1 1 1.5 2.172 1.5 3.515 0 1.344-.5 2.516-1.5 3.516l-3.516 3.563c-1.031.968-2.21 1.453-3.539 1.453-1.328 0-2.508-.485-3.539-1.453-.969-1.032-1.453-2.211-1.453-3.54 0-1.328.484-2.507 1.453-3.538l1.5-1.5a6.427 6.427 0 0 0 .375 2.437l-.469.469c-.593.625-.89 1.336-.89 2.133 0 .796.304 1.5.914 2.109.61.61 1.312.914 2.11.914.796 0 1.507-.297 2.132-.89l3.516-3.563c.593-.594.89-1.29.89-2.086 0-.797-.297-1.508-.89-2.133a1.09 1.09 0 0 1-.282-.726c0-.266.094-.493.282-.68.218-.219.46-.328.726-.328zM30.623 13c1.328 0 2.507.484 3.539 1.453.968 1.031 1.453 2.211 1.453 3.54 0 1.327-.485 2.507-1.453 3.538l-1.5 1.5a6.427 6.427 0 0 0-.375-2.437l.468-.469c.594-.625.891-1.336.891-2.133 0-.797-.305-1.5-.914-2.11-.61-.609-1.313-.913-2.11-.913-.796 0-1.507.297-2.132.89l-3.516 3.563c-.594.594-.89 1.289-.89 2.086 0 .797.296 1.508.89 2.133.188.218.281.46.281.726 0 .266-.101.5-.304.703a.966.966 0 0 1-.703.305.865.865 0 0 1-.68-.328c-1-1-1.5-2.172-1.5-3.516 0-1.343.5-2.515 1.5-3.515l3.515-3.563c1.032-.969 2.211-1.453 3.54-1.453z" />
    </g>
  </svg>
</ng-template>

<ng-template #stickyNoteMobileIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-26%" y="-26%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"
          in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"
          in="shadowBlurOuter2" result="shadowMatrixOuter2" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-12 -12)" fill="#FFF" fill-rule="evenodd">
      <path
        d="M29 15a3 3 0 0 1 2.995 2.824L32 18v12a3 3 0 0 1-2.824 2.995L29 33h-6.362l-5.559-4.632a3 3 0 0 1-1.072-2.1L16 26.063V18a3 3 0 0 1 2.824-2.995L19 15h10zm-5.005 12.824L24 28v3h5a1 1 0 0 0 .974-.77l.02-.113L30 30V18a1 1 0 0 0-1-1H19a1 1 0 0 0-.993.883L18 18v7h3a3 3 0 0 1 2.995 2.824zM22 29.864V28a1 1 0 0 0-.883-.993L21 27h-2.438L22 29.864z" />
    </g>
  </svg>
</ng-template>