/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  _getChannelFiles,
  _getChannelMembers,
  _getChannelNoAccess,
  _getChannelNotFound,
  _getChannelSearchString,
  _getChannelUserJid,
  _getIsLoaded,
  _getIsLoading,
  _getChannelsInfo,
  _getPrivateChannelsInfo,
  _getPublicChannelsInfo,
  _getSubscribedChannelsInfo,
  _getSearchedChannelsInfo,
  _getSelectedChannelId,
  channelAdapter,
  channelReducer,
  ChannelState,
  _getChannelSideBarTab,
  _getFilteredTopicsInfo,
  _getAllAvailableTopicFiltersInfo,
  _getFavoriteChannelsInfo,
  _getMyChannelsInfo,
  _getArchiveChannelsSortBy,
  _getSubChannelsInfo,
  _getIOMChannelsInfo,
  _getIntranetChannelsInfo,
  _getTrashedChannelsInfo,
  _getArchivedChannelsInfo,
  _getExtranetChannelsInfo

} from "./channel";
import { File } from "../../models/file.model";
import { commentAdapter, commentReducer, CommentState, _getCommentReplies } from "./comment";
import {
  _getChannelIdsWithLoadedTopics,
  _getIsTopicLoaded,
  _getIsTopicLoading,
  _getSelectedTopicId,
  _getTopicFiles,
  _getTopicMembers,
  topicAdapter,
  topicReducer,
  TopicState,
  _getTopicsInfo, _getTopicSideBarTab, _getTopicComments,
  _getTopicDefaultCovers,
  _getAllTopicsInfo,
  _getMediaTopicsInfo, _getArchiveTopicsSortBy, _getArchivedTopicsInfo} from "./topic";
import { membersAdapter, membersReducer, MembersState } from "./members";
import { filesAdapter, filesReducer, FilesState } from "./files";
import { NotificationState, notificationReducer, notificationAdapter, _getNotificationIsLoaded, _getNotificationIsLoading, _getNotificationInfo, _getUnreadNotificationInfo, _getUnreadNotificationCount } from "./notification";
import { subscribeTopicAdapter, subscribeTopicReducer, SubscribeTopicState, _getAllSubscribeTopicsInfo, _getSubscribeTopicIsLoaded, _getSubscribeTopicIsLoading } from "./subscribe_topic";

export interface ChannelRootState {
  channels: State
}

export interface State {
  channel: ChannelState;
  topic: TopicState;
  comment: CommentState;
  members: MembersState;
  files: FilesState;
  notification: NotificationState;
  subscribeTopic: SubscribeTopicState
}

export const getChannelRootState = createFeatureSelector<State>("channels");

export const channelReducers = {
  channel: channelReducer,
  topic: topicReducer,
  comment: commentReducer,
  members: membersReducer,
  files: filesReducer,
  notification: notificationReducer,
  subscribeTopic: subscribeTopicReducer
};

export const getChannelState = createSelector(
  getChannelRootState,
  state => state.channel
);
export const {
  selectIds: getChannelIds,
  selectEntities: getChannelEntities,
  selectAll: getAllChannels,
  selectTotal: getTotalChannel,
} = channelAdapter.getSelectors(getChannelState);
//
export const getTopicState = createSelector(
  getChannelRootState,
  state => state.topic
);
export const getCommentState = createSelector(
  getChannelRootState,
  state => state.comment
);
export const getTrashedChannelsInfo = createSelector(getChannelState, _getTrashedChannelsInfo);
export const getArchivedChannelsInfo = createSelector(getChannelState, _getArchivedChannelsInfo);

export const getMemberState = createSelector(
  getChannelRootState,
  state => state.members
);

export const getFileState = createSelector(
  getChannelRootState,
  state => state.files
);

export const getNotificationState = createSelector(
  getChannelRootState,
  state => state.notification
);

export const getSubscribeTopicState = createSelector(
  getChannelRootState,
  state => state.subscribeTopic
);

export const {
  selectIds: getTopicIds,
  selectEntities: getTopicEntities,
  selectAll: getAllTopics,
  selectTotal: getTotalTopic,
} = topicAdapter.getSelectors(getTopicState);

export const {
  selectIds: getCommentIds,
  selectEntities: getCommentEntities,
  selectAll: getAllComments,
  selectTotal: getTotalComment,
} = commentAdapter.getSelectors(getCommentState);

export const {
  selectIds: getMemberIds,
  selectEntities: getMemberEntities,
  selectAll: getAllMembers
} = membersAdapter.getSelectors(getMemberState);

export const {
  selectIds: getFileIds,
  selectEntities: getFileEntities,
  selectAll: getAllFiles
} = filesAdapter.getSelectors(getFileState);

export const {
  selectIds: getNotificationIds,
  selectEntities: getNotificationEntities,
  selectAll: getAllNotifications
} = notificationAdapter.getSelectors(getNotificationState);

export const getIsChannelLoading = createSelector(getChannelState, _getIsLoading);
export const getIsChannelLoaded = createSelector(getChannelState, _getIsLoaded);
export const getIsTopicLoading = createSelector(getTopicState, _getIsTopicLoading);
export const getIsTopicLoaded = createSelector(getTopicState, _getIsTopicLoaded);
export const getChannelIdsWithLoadedTopics = createSelector(getTopicState, _getChannelIdsWithLoadedTopics);
export const getSelectedChannelId = createSelector(getChannelState, _getSelectedChannelId);
export const getArchiveChannelsSortBy = createSelector(getChannelState, _getArchiveChannelsSortBy);
export const getChannelMembers = createSelector(getChannelState, _getChannelMembers);
export const getChannelFiles = createSelector(getChannelState, _getChannelFiles);
export const getChannelSearchString = createSelector(getChannelState, _getChannelSearchString);
export const getChannelUserJid = createSelector(getChannelState, _getChannelUserJid);
export const getChannelsInfo = createSelector(getChannelState, _getChannelsInfo);
export const getSubChannelsInfo = createSelector(getChannelState, _getSubChannelsInfo);
export const getPrivateChannelsInfo = createSelector(getChannelState, _getPrivateChannelsInfo);
export const getPublicChannelsInfo = createSelector(getChannelState, _getPublicChannelsInfo);
export const getIOMChannelsInfo = createSelector(getChannelState, _getIOMChannelsInfo);
export const getSubscribedChannelsInfo = createSelector(getChannelState, _getSubscribedChannelsInfo);
export const getFavoriteChannelsInfo = createSelector(getChannelState, _getFavoriteChannelsInfo);
export const getMyChannelsInfo = createSelector(getChannelState, _getMyChannelsInfo);
export const getIntranetChannelsInfo = createSelector(getChannelState, _getIntranetChannelsInfo);
export const getExtranetChannelsInfo = createSelector(getChannelState, _getExtranetChannelsInfo);
export const getAllAvailableTopicFiltersInfo = createSelector(getChannelState, _getAllAvailableTopicFiltersInfo);
export const getSearchedChannelsInfo = createSelector(getChannelState, _getSearchedChannelsInfo);
export const getChannelNoAccess = createSelector(getChannelState, _getChannelNoAccess);
export const getChannelNotFound = createSelector(getChannelState, _getChannelNotFound);
export const getChannelSideBarTab = createSelector(getChannelState, _getChannelSideBarTab);
export const getFilteredTopicsInfo = createSelector(getChannelState, _getFilteredTopicsInfo);
export const getChannels = getAllChannels;
export const getTotalChannels = getTotalChannel;
export const getArchivedTopicsInfo = createSelector(getTopicState, _getArchivedTopicsInfo);
export const getTrashedChannels = (state: ChannelRootState) => {
  return getAllChannels(state)
  .filter(v => v?.deleted);
};



export const getSelectedTopicId = createSelector(getTopicState, _getSelectedTopicId);
export const getArchiveTopicsSortBy = createSelector(getTopicState, _getArchiveTopicsSortBy);
export const getTopicFiles = createSelector(getTopicState, _getTopicFiles);
export const getTopicDefaultCovers = createSelector(getTopicState, _getTopicDefaultCovers);
export const getTopicSideBarTab = createSelector(getTopicState, _getTopicSideBarTab);
export const getTopicMembers = createSelector(getTopicState, _getTopicMembers);
export const getTopicComments = createSelector(getTopicState, _getTopicComments);
export const getAllTopicsInfo = createSelector(getTopicState, _getAllTopicsInfo);


export const getChannelById = (state: ChannelRootState | any, id: string) => {
  return getChannelEntities(state)[id];
};
export const getAllSubchannels = (state: ChannelRootState | any, id: string) => {
  return getAllChannels(state).filter(c => c.parent);
};
export const getSubchannelById = (state: ChannelRootState | any, id: string) => {
  return getAllChannels(state).filter(c => c.id === id);
};
export const getSubchannelsByParentId = (state: ChannelRootState | any, id: string) => {
  return getAllChannels(state).filter(c => c.parent?.id === id);
};
export const getTopicById = (state: ChannelRootState, id: string) => {
  return getTopicEntities(state)[id];
};
export const getDefaultCoverByName = (state, name: string) => {
  return getTopicDefaultCovers(state).filter(c => c?.name.includes(name));
};

export const getAllChannelsList = (state: ChannelRootState) => {
  return getAllChannels(state).filter(c => !c.parent);
};

export const getSubscribedChannels = (state: ChannelRootState) => {
  return getAllChannels(state)
    .filter(v => v.subscribed && !v.parent);
};

export const getChannelsByIds = (state: ChannelRootState, ids: string[]) => {
  return ids.map(id => getChannelEntities(state)[id])
    .filter(v => !!v && !v.parent);
};

export const getTopicsByChannelId = (state: ChannelRootState, channelId: string) => {
  return getAllTopics(state)
    .filter(t => t.channel_id === channelId);
};

export const getCommentsByTopicId = (state: ChannelRootState, topicId: number) => {
    return getAllComments(state).filter(v => v.topic_id === parseInt(topicId.toString()) && !v.parent_comment_id);
};

export const getCommentById = (state: ChannelRootState, commentID: number) => {
  return getCommentEntities(state)[commentID];
};

export const getParentCommentInTopicById = (state: ChannelRootState, commentID: number, topicId: number) => {
  let comment = getCommentEntities(state)[commentID];
  if (comment) {
    return comment;
  }
  const comments = getAllComments(state)
    .filter(v => v.topic_id === parseInt(topicId.toString()) && !v.parent_comment_id && v.replies);
  comments.forEach(level1 => {
    if (level1.replies.find(v => v.id === commentID)) {
      comment = level1;
    }
    else {
      level1.replies.forEach(level2 => {
        if (level2.replies.find(v => v.id === commentID)) {
          comment = level1;
        }
      });
    }
  });
  return comment;
};

export const getCompleteMembers = (state: ChannelRootState) => {
  return getAllMembers(state);
};

export const getCompleteFiles = (state: ChannelRootState): File[] => getAllFiles(state);

export const getCompleteTopics = (state: TopicState) => {
  return getAllTopics(state);
};

export const getTopicsInfo = createSelector(getTopicState, _getTopicsInfo);
export const getTopicsInfoByChannelId = (state: ChannelRootState , channelId: string) => {
  if (getTopicsInfo(state)) {
    return getTopicsInfo(state)[channelId];
  } else {
    return null;
  }
};

export const getMediaTopicsInfo = createSelector(getTopicState, _getMediaTopicsInfo);
export const getMediaTopicsInfoByChannelId = (state: ChannelRootState , channelId: string) => {
  if (getMediaTopicsInfo(state)) {
    return getMediaTopicsInfo(state)[channelId];
  } else {
    return null;
  }
};

export const getCommentInfoByTopicId = (state: ChannelRootState , topicId: string) => {
  if (getTopicComments(state)) {
    return getTopicComments(state)[topicId];
  } else {
    return null;
  }
};

export const getIsNotificationLoading = createSelector(getNotificationState, _getNotificationIsLoading);
export const getIsNotificationLoaded = createSelector(getNotificationState, _getNotificationIsLoaded);
export const getNotificationInfo = createSelector(getNotificationState, _getNotificationInfo);
export const getUnreadNotificationInfo = createSelector(getNotificationState, _getUnreadNotificationInfo);
export const getUnreadNotificationCount = createSelector(getNotificationState, _getUnreadNotificationCount);

export const getAllNotificationList = (state: ChannelRootState) => {
  return getAllNotifications(state);
};

export const getUnreadNotifications = (state: ChannelRootState) => {
  return getAllNotifications(state)
    .filter(v => !v.read);
};

export const getAllNotificationsByGroupType = (state: ChannelRootState, period_type: string) => {
  return getAllNotifications(state)
    .filter(v => v.period_type === period_type);
};
export const getCommentReplies = createSelector(getCommentState, _getCommentReplies);

export const {
  selectIds: getSubscribeTopicIds,
  selectEntities: getSubscribeTopicEntities,
  selectAll: getAllSubscribeTopics,
  selectTotal: getTotalSubscribeTopic,
} = subscribeTopicAdapter.getSelectors(getSubscribeTopicState);

export const getIsSuscribeTopicLoading = createSelector(getSubscribeTopicState, _getSubscribeTopicIsLoading);
export const getIsSuscribeTopicLoaded = createSelector(getSubscribeTopicState, _getSubscribeTopicIsLoaded);
export const getAllSubscribeTopicsInfo = createSelector(getSubscribeTopicState, _getAllSubscribeTopicsInfo);

export const getAllSubscribeTopic = (state: ChannelRootState) => {
  return getAllSubscribeTopics(state);
};
