import {Component, Input, OnInit, Output} from "@angular/core";
import {Topic} from "../../models/topic.model";
import {Channel} from "../../models/channel.model";
import {Subject} from "rxjs";
import {EMPTY_STATE_TYPE} from "../../../shared/models/user-config.model";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: "vp-topic-list",
  templateUrl: "./topic-list.component.html",
  styleUrls: ["./topic-list.component.scss"]
})
export class TopicListComponent implements OnInit {

  @Input() isMobileScreen = false;
  @Input() topics: Topic[] = [];
  @Input() unpublishedTopics: Topic[] = [];
  @Input() filteredTopics: Topic[] = [];
  @Input() searchedTopics: Topic[] = [];
  @Input() topicsGroupedByAuthor = {};
  @Input() authorsGroup = [];
  @Input() currentView = "list";
  @Input() heroImageHeight = "244px";
  @Input() wrapperWidth = 0;
  @Input() isTopicsLoading = false;
  @Input() isFiltersApplied = false;
  @Input() filteredTopicsLoaded = false;
  @Input() filteredTopicsLoading = false;
  @Input() isRightBarExpanded = false;
  @Input() isLeftBarExpanded = false;
  @Input() isDisplayTopicSearch = true;
  @Input() noAccess = false;
  @Input() groupBy = false;
  @Input() selectedChannel: Channel;
  @Input() emptyCards = [];
  @Input() emptyStateBackground = "";
  @Input() emptyStateType = EMPTY_STATE_TYPE.SINGLE;
  @Input() topicSearchControl: UntypedFormControl;
  @Input() emptyStateTypeNoAccess = EMPTY_STATE_TYPE.BUTTON;
  @Input() sortBy = "";
  @Output() loadMoreTopics = new Subject();
  @Output() loadMoreFilteredTopics = new Subject();
  @Output() addFileToTopic = new Subject();
  @Output() requestAccess = new Subject();
  @Output() swipe = new Subject();

  constructor() { }

  ngOnInit(): void {
  }

}
