
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AdvanceSearchRequest } from "../models/advance-search-request.model";
import { TimeRangeType } from "../models";
import subHours from "date-fns/subHours";
import subWeeks from "date-fns/subWeeks";
import subMonths from "date-fns/subMonths";
import { AdvanceSearchContentType } from "../models/advance-search-content-type.model";

export class AdvanceSearchRequestUtil {

  public static isAllFilesSelected(request: AdvanceSearchContentType) {
    return request.media && request.urls && request.documents;
  }

  public static mapToServiceInput(request: AdvanceSearchRequest): {
    nb: string,
    na: string,
    tags: string[],
    users: string[]
  } {
    let fromTime: Date;
    let toTime: Date;

    if (request.time.type === TimeRangeType.CUSTOM) {
      // TODO:
      fromTime = new Date(request.time.from.date + " " + request.time.from.time);
      toTime = new Date(request.time.to.date + " " + request.time.to.time);
    } else {
      toTime = new Date();

      switch (request.time.type) {
        case TimeRangeType.LAST_HOUR: {
          fromTime = subHours(new Date(), 1);
          break;
        }

        case TimeRangeType.LAST_24_HOURS: {
          fromTime = subHours(new Date(), 24);
          break;
        }

        case TimeRangeType.LAST_WEEK: {
          fromTime = subWeeks(new Date(), 1);
          break;
        }

        case TimeRangeType.LAST_MONTH: {
          fromTime = subMonths(new Date(), 1);
          break;
        }
      }
    }

    let newTags = [];
    let tags: string[] = request.tags || [];
    if (request.starred_messages) {
      newTags = [
        ...tags,
        "*"
      ];
    }

    return {
      nb: fromTime.toISOString(),
      na: toTime.toISOString(),
      tags: newTags,
      users: request.users.map(c => c.bare)
    };
  }
}
