/* eslint no-console: 0 */

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Injectable } from "@angular/core";
import { TalkRootState, getFullScreenParticipantId,
  getConferenceParticipants, getJitsiConferenceParticipants, getJitsiRoom,
  getIsConferenceAudioMuted, getSelectedParticipantId,
  getFrontCameraId, getHasWebcam, getParticipantEmail, getSpeakingParticipant,
  getConversationOwner, getJitsiConfig, getConversationAdmins, getActiveConference,
  getCurrentView, getSelectedWhiteboardId, getAvailableMediaDevices,
  getScreenSharingRequest, getConferenceType, getConversationAudiences, isMutedEveryone,
  getVirtualBackground,
  getConversationById} from "../reducers/index";
import { Store } from "@ngrx/store";
import { JitsiParticipant } from "../models/jitsi-participant.model";
import { ConferenceAddParticipant, JitsiConferenceAddParticipant, ConferenceLeaveSuccess,
  ConferenceRemoveParticipant, JitsiConferenceRemoveParticipant, ConferenceSetFullScreenParticipant,
  ConferenceUnMuteAudio, SetStreamId, ConferenceSelectParticipant, SetAudioStatus, SetVideoStatus,
  ConferenceUnMuteVideo, SetSpeakingParticipant, ConferenceMuteAudio, SetConversationTarget,
  SetScreenSharingRequest, SetScreenSharingStarted, ResetScreenSharingData, SetScreenSharingRequestStatus,
  ConferenceShareScreen, ConferenceSetActiveWhiteboard, SetConferenceType, SetParticipantE2EEStatus, SetParticipantE2EEIndexKey, ToggleE2EE, UpdateLobbyState,
  ParticipantIsKnockingOrUpdated, KnockingParticipantLeft, BackgroundEffectEnabled, SetVirtualBackground,
  SetUploadedBackground,
  ConferenceMuteVideo,
  ToggleMuteEveryone,
  ConferenceUnShareScreen,
  UpdateJitsiConfig,
  ToggleMuteCamera,
  SetNoiseSuppressionEnabled} from "../actions/conference";
import { BehaviorSubject, map, Observable, skip, take, interval, bufferTime, debounceTime, distinctUntilChanged, filter, Subject, takeUntil } from "rxjs";
import { ConstantsUtil, BroadcastKeys, ScreenViews } from "../utils/constants.util";
import { Broadcaster } from "../shared/providers";
import { CommonUtil } from "../utils/common.util";
import { ConversationUtil } from "../utils/conversation.util";
import { getUserJID, getNetworkInformation, getContactById, getAppSettings, getUserProfile, getUserConfig } from "../../reducers";
import { JID } from "../models/jid.model";
import { TranslateService } from "@ngx-translate/core";
import { AudioOutputService } from "./audio-output.service";
import { ConfigService } from "app/config.service";
import { VNCTalkNotificationsService } from "../notifications";
import { Contact } from "../models/contact.model";
import { AppSettings } from "../models/app-settings.model";
import { environment } from "app/environments/environment";
import { CallParticipantStats } from "../models/call-participant-stats.model";
import { ConversationUpdateAdmins, ConversationUpdateOwner, ConversationCreate } from "../actions/conversation";
import { GroupChatsService } from "./groupchat.service";
import { ChangeLayout, XmppSession } from "app/actions/app";
import { SetSelectedWhiteboardId } from "../actions/whiteboard";
import JitsiStreamPresenterEffect from "../utils/stream-presenter";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { combineLatest, timer } from "rxjs";
import { createVirtualBackgroundEffect } from "./virtual-background";
import { resizeImage, toDataURL } from "./virtual-background/function";
import { createTaskQueue } from "../utils/helpers";
import { NotificationService } from "./notification.service";
import * as dayjs from "dayjs";
import { LoggerService } from "app/shared/services/logger.service";
import { Conversation } from "../models/conversation.model";

export enum VideoQuality {
  LOW = 180,      // 180p: 320x180
  MEDIUM = 240,   // 240p: 640x240
  STANDARD = 360, // 360p: 640x360
  GOOD = 480,     // 480p: 640x480
  BETTER = 540,   // 540p: 960x540
  HIGH = 720,     // 720p: 1280x720 (HD)
  HIGHEST = 2160
}
export const HIDDEN_EMAILS = [ "inbound-sip-jibri@jitsi.net", "outbound-sip-jibri@jitsi.net" ];

const TOTAL_UNMUTED_PARTICIPANTS = 5;
// The limit of virtual background uploads is 24. When the number
// of uploads is 25 we trigger the deleteStoredImage function to delete
// the first/oldest uploaded background.
const backgroundsLimit = 25;

const _replaceLocalVideoTrackQueue = createTaskQueue();
const _replaceLocalAudioTrackQueue = createTaskQueue();
const _stopScreenSharingQueue = createTaskQueue();
const JITSI_MEET: any = {};
@Injectable()
export class JitsiService {
  private fullScreenParticipantId: string;

  cameraId: any;
  // micId: any;

  private _localTracks$ = new BehaviorSubject<any[]>([]);
  private _remoteTracks$ = new BehaviorSubject<{ [participantId: string]: any[] }>({});
  private _mixerEffect = null;
  public _room = new Subject<boolean>();
  enabledVideo: boolean;
  needToEnableVideoOnSwitchToForeground = null;
  recordInterval: any;
  mutedEveryone: boolean;
  localAudioMuted: boolean;
  streamId: any;
  private connection: any;
  private hangedUp = false;
  private room = null;
  private roomName: string;
  displayName: string;
  private localAudio = null;
  private localDesktopAudio = null;
  localVideo = null;
  private uConnOptions: any = {};
  private _callDuration = new BehaviorSubject<string>("00:00:00");
  private _recordDuration = new BehaviorSubject<string>("00:00:00");
  public _muteAudioPopup = new Subject<boolean>();
  public _muteVideoPopup = new Subject<boolean>();
  private openPopupList: string[] = [];
  public recordSessionId: string = "";
  private startRecordingTime: number;
  private isAlive$ = new Subject<boolean>();
  public isRecording: boolean = false;
  public inConference: boolean = false;
  private zeroUploadCount = 0;
  private zeroRemoteTracks = 0;
  private recoverAttempts = 0;
  private restartWorkArounds = 0;
  public recordingStatus: string = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
  public lastDominantSpeaker$ = new BehaviorSubject<any>("");
  public speakingParticipant$ = new BehaviorSubject<any>({});
  public lastTimeJoined$ = new BehaviorSubject<any>({});
  public lastTimeSpeaking$ = new BehaviorSubject<any>({});
  public fullParticipantByModerator$ = new BehaviorSubject<any>(null);
  public pinnedParticipant$ = new BehaviorSubject<any>(null);
  public raisedHandList$ = new BehaviorSubject<any>({});
  public mutedForMe$ = new BehaviorSubject<any>([]);
  public participantsInformation$ = new BehaviorSubject<any>([]);
  onWhiteboardOpen$ = new BehaviorSubject<boolean>(false);
  isCamOn = new BehaviorSubject<boolean>(false);
  whiteboardMode$ = new BehaviorSubject<string>("expanded");
  realScreenShareParticipantId$ = new BehaviorSubject<string>("");
  private _onAudioLevelChange$ = new Subject<any>();
  private _onAudioLevelChangeSubscription$: any;

  // TODO: get rid of these timers
  private waitForAudio = {};
  private waitForVideo = {};

  private callDurationTimer$: any;
  private networkSubscription$: any;
  private callRestoreTimer$: any;
  static CALL_RESTORE_INTERVAL = 30000;
  private networkOnline = false;

  private jitsiActiveConfig;
  private jitsiCurrentEnvConfig;

  participantsList = {};
  private participantsStats = {};
  private participantsConnStatus = {};
  private participantsConnStatusTimer: any;

  userJID: JID;
  leftList = new BehaviorSubject<string[]>([]);
  totalLeftList = new BehaviorSubject<string[]>([]);
  joinedList = new BehaviorSubject<string[]>([]);
  participantEmail: string;
  conferencePassword = {};
  static VIDEO_TRACK_SCREEN_SHARE_TYPES = ["desktop", "screen"];
  checkFullScreen: any;
  private lastSpeakingParticipant$ = new BehaviorSubject<string>(null);
  waitForFullVideo: any;
  whiteboardData$ = new BehaviorSubject<any>({});
  whiteBoardAvailable: boolean;
  raisedNotifications$ = new BehaviorSubject<any>([]);
  conferenceTarget: any;
  private isFlipped$ = new BehaviorSubject<boolean>(true);
  recordingName: string;
  liveStreamTarget: string;
  canManageMCBs: any;
  whiteboardId: any;
  audiences: any;
  joinedWithPassword: any;
  localPresenterVideo: any;
  displayedVincent: any;
  startedRecording: boolean;
  nextAction: string;
  private blurBackground$ = new BehaviorSubject<boolean>(false);
  private currentView: string;
  callFromMCB: boolean;
  private conferenceParticipantsCount = 0;
  private currentLocalTrackConstraints;
  someoneshouldStartScreenshare: boolean;
  onConferenceJoin$ = new BehaviorSubject<boolean>(false);
  remoteHasScreenShared$ = new BehaviorSubject<boolean>(false);
  localHasScreenShared$ = new BehaviorSubject<boolean>(false);
  screenSharePresenterParticipant$ = new BehaviorSubject<string>(null);
  // for recovery from frozen video
  videoQuality$ = new BehaviorSubject<number>(VideoQuality.HIGH);
  unmuteVideoWhenGoToForegroundTrigger = new BehaviorSubject<any>(null);
  muteVideoWhenGoToBackgroundTrigger  = new BehaviorSubject<any>(null);
  videoResetRequired: boolean = false;
  lobbyRoom: any;
  lobbyDialog: MatDialogRef<any, any>;
  setPassword$: any;
  activeTarget: string;
  lastActiveTarget: string;
  localImages: any = !!localStorage.getItem("virtualBackgrounds") ? JSON.parse(localStorage.getItem("virtualBackgrounds")) : [];
  shouldStartScreen: any;
  someoneStartedScreenshare: boolean;
  isSwitchCameraInProgress: any;
  isRejoining: boolean = false;
  resetState: any;
  mutedAllCamera: boolean;
  private _updateInterval: any;
  speakerStats = new BehaviorSubject<any>({});
  setBackground: any;
  lang: any;
  isVP9: boolean;
  minRemoteQuality: any;
  switchedQuality: number;
  participantInfo = new BehaviorSubject<any>({});
  private _startAudioAfterScreenShareTimerId = null;
  attachedVideo: boolean;
  externalData: any;
  localSettingPreviewStreams: any[];
  conversationAdmins: string[];
  currentParticipants: string[];
  usedExternalPass: boolean = false;
  vp8restartCount = 0;
  CONFERENCE_ERRORS: any = {};

  CONNECTION_ERRORS: any = {};
  loadedLib$ = new BehaviorSubject<boolean>(false);
  roomSubject$ = new BehaviorSubject<string>("");
  callView: string;
  fakeParticipant: JitsiParticipant;
  public currentMicLabel: string = "";
  public currentCamLabel: string = "";
  public currentAudioOutputLabel: string = "";


  constructor(
    private store: Store<TalkRootState>,
    private broadcaster: Broadcaster,
    private dialog: MatDialog,
    private translate: TranslateService,
    private configService: ConfigService,
    private groupChatsService: GroupChatsService,
    private notificationService: NotificationService,
    private notificationsService: VNCTalkNotificationsService,
    private logger: LoggerService,
    private audioOutputService: AudioOutputService) {

    this.conversationAdmins = [];

    this.fakeParticipant = {
      id: "fake123",
      name: "fake-participant"
    };
    document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    this.localSettingPreviewStreams = [];
    this.store.dispatch(new SetUploadedBackground(this.localImages));
    this.videoQuality$.pipe(skip(1)).subscribe(v => {
      this.logger.info("[JitsiService][switchDownUpVideoQuality][this.videoQuality$]", v);
      this.handleVideoQuality(v);
    });
    this.localHasScreenShared$.pipe(distinctUntilChanged()).subscribe(v => {
      setTimeout(() => {
        this.logger.info("[JitsiService][Rscreenshare] localHasScreenShared$.value: ", v, this.localHasScreenShared$.value);
        this.setSenderVideoConstraint();
        this.setSelectedUsersVideoQuality();
      }, 1000);
    });
    this.remoteHasScreenShared$.pipe(distinctUntilChanged()).subscribe(v => {
      const isFirefox = /firefox/i.test(navigator.userAgent.toLowerCase());
      if (!this.remoteHasScreenShared$.value && isFirefox) {
        this.switchDownVideoQuality();
      }
      setTimeout(() => {
        this.logger.info("[JitsiService][Rscreenshare] remoteHasScreenShared$.value: ", v, this.remoteHasScreenShared$.value);
        this.setSenderVideoConstraint();
        this.setSelectedUsersVideoQuality();
      }, 1500);
    });
    this.screenSharePresenterParticipant$.pipe(distinctUntilChanged()).subscribe(v => {
      this.logger.info("[JitsiService][Rscreenshare] screenSharePresenterParticipant$.value: ", v, this.screenSharePresenterParticipant$.value);
      setTimeout(() => {
        if (v && (v !== "")) {
          this.translate.get("SCREENSHARE").pipe(take(1)).subscribe(content => {
            this.fakeParticipant = {
              id: "fake123",
              name: this.getDisplayName(v) + " (" + content + ")"
            };
          });
          this.broadcaster.broadcast("fakeParticipantActive");
          this.broadcaster.broadcast("onScreenStarted");
          this.store.dispatch(new ConferenceAddParticipant(this.fakeParticipant));
          this.store.dispatch(new JitsiConferenceAddParticipant(this.fakeParticipant));


          // this.selectHiResUsers([v]);
          setTimeout(() => {
            this.selectHiResUsers(["fake123"]);
            this.setFullScreenParticipantId("fake123");
          }, 150);
        } else {
          this.logger.info("[JitsiService][Rscreenshare] :=> fakeParticipantInactive ", v, this.screenSharePresenterParticipant$.value);
          this.broadcaster.broadcast("fakeParticipantInactive");
          setTimeout(() => {
            this.store.dispatch(new ConferenceRemoveParticipant("fake123"));
            this.store.dispatch(new JitsiConferenceRemoveParticipant("fake123"));
          }, 150);
          this.store.select(getConferenceParticipants).pipe(take(1)).subscribe((participants: any) => {
            if (participants && participants.length > 1) {
                   this.selectHiResUsers(participants.map(p => p.id));
            }
          });
        }
      }, 1500);
    });
    this.unmuteVideoWhenGoToForegroundTrigger.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe((ts) => {
      this._unmuteVideoWhenGoToForeground();
    });

    this.muteVideoWhenGoToBackgroundTrigger.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe((ts) => {
      this._muteVideoWhenGoToBackground();
    });

    this.store.select(isMutedEveryone).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(v => {
      this.logger.info("[JitsiService] isMutedEveryone", v);
      this.mutedEveryone = v;
    });


    this.store.select(getSelectedWhiteboardId).subscribe(id => {
      this.whiteboardId = id || this.roomName;
    });
    this.onWhiteboardOpen$.asObservable().subscribe(v => {
      if (!!v) {
        document.querySelector("body").classList.add("white-board-on");
      } else {
        document.querySelector("body").classList.remove("white-board-on");
      }
    });
    this.store.select(getActiveConference).subscribe(v => {
      this.activeTarget = v;
      if (!!v) {
        this.lastActiveTarget = v;
      }
    });
    this.store.select(getConferenceParticipants).pipe(debounceTime(1000)).subscribe(participants => {
      const newParticipants = participants.map(p => this.getDisplayName(p.id));
      // this.logger.info("[JitsiService][getConferenceParticipants]", participants && participants.length, newParticipants);
      this.currentParticipants = newParticipants;
      if (participants && participants.length > 1) {
        this.setSelectedUsersVideoQuality();

        if (this.currentView === "tile") {
          this.selectHiResUsers(participants.map(p => p.id));
        }
      }

      this.conferenceParticipantsCount = participants.length;
    });

    this.store.select(getCurrentView).subscribe(v => {
      this.currentView = v;
      // this.logger.info("[JitsiService][getCurrentView]", v);

      this.setSelectedUsersVideoQuality();
    });

    this.store.select(getUserConfig).subscribe( userConfig => {
      this.logger.info("[getUserConfig]", userConfig);
      if (userConfig) {
        this.canManageMCBs = userConfig.can_manage_mcbs;
      }
    });

    this.store.select(getJitsiConfig).subscribe(res => {
      if (res) {
        this.jitsiCurrentEnvConfig = res;
        this.logger.info("[JitsiService] jitsiCurrentEnvConfig", this.jitsiCurrentEnvConfig);
        let jitURL = new URL(this.jitsiCurrentEnvConfig?.serviceUrl);
        localStorage.setItem("currentJitsiHost", jitURL.hostname);
      }
    });

    this.switchedQuality = 0;
    this.minRemoteQuality = 720;

    this.store.select(getUserJID)
      .pipe(filter(jid => !!jid)
      , distinctUntilChanged())
      .subscribe(jid => {
        this.userJID = jid;
      });

    this.lastDominantSpeaker$.pipe(debounceTime(2500)).subscribe(userId => {
      // this.logger.info("lastDominantSpeaker$", userId);
      // - By default always display video of speaking person.
      // - If I am speaking person in that case display video of last speaker
      //
      // display a user who is speaking currently as full screen
      this.store.select(getSelectedParticipantId).pipe(take(1)).subscribe(res => {
        if (!res) { // only display if no one is selected manually before
          this.store.select(getFullScreenParticipantId).pipe(take(1))
          .subscribe(participantId => {
            if (userId && userId !== participantId && userId !== this.myUserId()){
              // this.logger.info("[JitsiService][DOMINANT_SPEAKER_CHANGED] set as full screen", userId);
              this.setFullScreenParticipantId(userId);
            }
          });
        } else {
          // this.logger.info("[JitsiService][DOMINANT_SPEAKER_CHANGED] ignore, user manualy set full screen participant");
        }
      });
    });

    // this.broadcaster.on<any>("switchMicrophone")
    //   .subscribe(micDeviceId => {
    //     this.changeMediaDevices(this.getPreferableCameraId(), micDeviceId, this.getPreferableAudioOutputId(), true);
    //   });

  }

  initJitsi() {
    this.loadedLib$.next(true);
    const jitsiLogLevel = localStorage.getItem("jitsiLogLevel");
    if (!!jitsiLogLevel && jitsiLogLevel === "debug") {
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG);
    } else {
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG);
      // JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);
    }

    JITSI_MEET.JitsiConferenceEvents = JitsiMeetJS.events.conference;
    JITSI_MEET.JitsiDetectionEvents = JitsiMeetJS.events.detection;
    JITSI_MEET.JitsiE2ePingEvents = JitsiMeetJS.events.e2eping;
    JITSI_MEET.JitsiConferenceErrors = JitsiMeetJS.errors.conference;
    JITSI_MEET.JitsiConnectionErrors = JitsiMeetJS.errors.connection;
    JITSI_MEET.JitsiParticipantConnectionStatus = JitsiMeetJS.constants.participantConnectionStatus;

    this.CONFERENCE_ERRORS = {
      CONNECTION_ERROR: JitsiMeetJS.errors.conference.CONNECTION_ERROR,
      SETUP_FAILED: JitsiMeetJS.errors.conference.SETUP_FAILED,
      AUTHENTICATION_REQUIRED: JitsiMeetJS.errors.conference.AUTHENTICATION_REQUIRED,
      PASSWORD_REQUIRED: JitsiMeetJS.errors.conference.PASSWORD_REQUIRED,
      PASSWORD_NOT_SUPPORTED: JitsiMeetJS.errors.conference.PASSWORD_NOT_SUPPORTED,
      VIDEOBRIDGE_NOT_AVAILABLE: JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE,
      RESERVATION_ERROR: JitsiMeetJS.errors.conference.RESERVATION_ERROR,
      CONFERENCE_FAILED: JitsiMeetJS.errors.conference.CONFERENCE_FAILED,
      CONFERENCE_DESTROYED: JitsiMeetJS.errors.conference.CONFERENCE_DESTROYED,
      FOCUS_DISCONNECTED: JitsiMeetJS.errors.conference.FOCUS_DISCONNECTED,
      CONFERENCE_MAX_USERS: JitsiMeetJS.errors.conference.CONFERENCE_MAX_USERS,
      ICE_FAILED: JitsiMeetJS.errors.conference.ICE_FAILED,
      MEMBERS_ONLY_ERROR: JitsiMeetJS.errors.conference.MEMBERS_ONLY_ERROR,
      CONFERENCE_ACCESS_DENIED: JitsiMeetJS.errors.conference.CONFERENCE_ACCESS_DENIED
    };

    this.CONNECTION_ERRORS = {
      CONNECTION_DROPPED_ERROR: JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR,
      SERVER_ERROR: JitsiMeetJS.errors.connection.SERVER_ERROR,
      OTHER_ERROR: JitsiMeetJS.errors.connection.OTHER_ERROR,
    };
    if (environment.isElectron) {
      this.currentAudioOutputLabel = this.getPreferableAudioOutputLabel();
      this.currentCamLabel = this.getPreferableCameraLabel();
      this.currentMicLabel = this.getPreferableMicLabel();
    }
  }
  deviceReady() {
    this.logger.info("[JitsiService][deviceReady]");

    if (CommonUtil.isOnIOS()) {
      this.iOSRTCPluginRegisterGlobals();
    }

  }

  handleVideoQuality(v: number) {
    if (this.room && this._localTracks$.value.filter(v => v.type === "video").length > 0) {
      this.logger.info("[JitsiService][handleVideoQuality]", v);

      if (v === 0) {
        this.store.dispatch(new ConferenceMuteVideo());
        this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
        this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
          for (let track of tracks.filter(t => t.type === "video")) {
            track.mute();
            this.logger.info("[JitsiService][handleVideoQuality] track.mute", track);
          }
        });
        this.enabledVideo = false;
      } else {
        this.store.dispatch(new ConferenceUnMuteVideo());
        this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
        this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
          for (let track of tracks.filter(t => t.type === "video")) {
            this.logger.info("[JitsiService][handleVideoQuality] track.unmute", track.sourceType);
            if (!!track.sourceType && (track.sourceType !== "desktop")) {
              track.unmute();
              this.logger.info("[JitsiService][handleVideoQuality] track.unmute", track);
            }
          }
        });
        this.enabledVideo = true;
        this.setSenderVideoConstraint();
        this.setSelectedUsersVideoQuality();
      }
    }
  }

  // only for desktop
  getPreferableCameraLabel(): string {
    let appSettings: AppSettings;
    this.store.select(getAppSettings).subscribe(options => {
      appSettings = options;
    });

    this.logger.info("[JitsiService][getPreferableCameraLabel]", appSettings.preferableMediaDevices, CommonUtil.getDeviceId());
    let preferableCameraLabel;
    try {
      let storedCamLabel = localStorage.getItem("preferableCameraLabel");

      if ((!!storedCamLabel) && (storedCamLabel !== "")) {
        preferableCameraLabel = storedCamLabel;
      }
    } catch (e) {
      this.logger.info("error restoring: ", e);
    }
    if (!preferableCameraLabel) {
      preferableCameraLabel = appSettings.preferableMediaDevices && appSettings.preferableMediaDevices[CommonUtil.getDeviceId()]?.preferableCameraLabel;
    }

    // this.logger.info("[JitsiService][getPreferableCameraLabel] preferableCameraLabel", preferableCameraLabel);

    // if not defined or not available on current machine then use 1st available
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(devices => {
      if (!preferableCameraLabel || !this.getVideoInputDeviceIdByLabel(devices, preferableCameraLabel)) {
        if (devices.videoInput && (devices.videoInput.length > 0)) {
          preferableCameraLabel = devices.videoInput[0].deviceLabel;
          this.logger.info("[JitsiService][getPreferableCameraLabel] use 1st available", preferableCameraLabel);
        } else {
          console.warn("[JitsiService][getPreferableCameraLabel] no available cam");
        }
      }
    });

    return preferableCameraLabel;
  }

  isPreferableCameraLabelAvailable(): boolean {
    let appSettings: AppSettings;
    this.store.select(getAppSettings).subscribe(options => {
      appSettings = options;
    });

    let preferableCameraLabel;
    try {
      let storedCamLabel = localStorage.getItem("preferableCameraLabel");

      if ((!!storedCamLabel) && (storedCamLabel !== "")) {
        preferableCameraLabel = storedCamLabel;
      }
    } catch (e) {
      this.logger.info("error restoring: ", e);
    }
    if (!preferableCameraLabel) {
      preferableCameraLabel = appSettings.preferableMediaDevices && appSettings.preferableMediaDevices[CommonUtil.getDeviceId()]?.preferableCameraLabel;
    }

    return !!preferableCameraLabel;
  }

  getPreferableCameraId(): string {
    let preferableCameraId: string;
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(devices => {
      preferableCameraId = this.getVideoInputDeviceIdByLabel(devices, this.getPreferableCameraLabel());
    });

    return preferableCameraId;
  }

  // only for desktop
  getPreferableMicLabel(): string {
    let appSettings: AppSettings;
    this.store.select(getAppSettings).subscribe(options => {
      appSettings = options;
    });
    let preferableMicLabel;
    try {
      let storedMicLabel = localStorage.getItem("preferableMicLabel");
      this.logger.info("[JitsiService][getPreferableMicLabel][getAvailableMediaDevices] localStorage preferableMicLabel", storedMicLabel);
      if ((!!storedMicLabel) && (storedMicLabel !== "")) {
        preferableMicLabel = storedMicLabel;
      }
    } catch (e) {
      this.logger.info("error restoring: ", e);
    }
    if (!preferableMicLabel) {
      preferableMicLabel = appSettings.preferableMediaDevices && appSettings.preferableMediaDevices[CommonUtil.getDeviceId()]?.preferableMicLabel;
    }

    // if not defined or not available on current machine then use 1st available
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(async devices => {
      this.logger.info("[JitsiService][getPreferableMicLabel][getAvailableMediaDevices]", devices);

      this.logger.info("[JitsiService][getPreferableMicLabel][getAvailableMediaDevices] preferableMicLabel", preferableMicLabel, this.getAudioInputDeviceIdByLabel(devices, preferableMicLabel));

      if (!preferableMicLabel || !this.getAudioInputDeviceIdByLabel(devices, preferableMicLabel)) {
        let activeDevice = await JitsiMeetJS.getActiveAudioDevice();

        this.logger.info("[JitsiService][getPreferableMicLabel] getactive", activeDevice);
        const firstavail = (!!devices && !!devices.audioInput && (devices.audioInput.length > 0)) ? devices.audioInput[0].deviceLabel : "";
        preferableMicLabel = !!activeDevice.deviceLabel ? activeDevice.deviceLabel : firstavail;
        localStorage.setItem("preferableMicLabel", preferableMicLabel);
        this.logger.info("[JitsiService][getPreferableMicLabel] use 1st available", preferableMicLabel, devices.audioInput, firstavail);
      }
    });

    return preferableMicLabel;
  }
  getPreferableMicId(): string {
    let preferableMicId: string;
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(devices => {
      preferableMicId = this.getAudioInputDeviceIdByLabel(devices, this.getPreferableMicLabel());
    });

    return preferableMicId;
  }

  // only for desktop
  getPreferableAudioOutputLabel(): string {
    let appSettings: AppSettings;
    this.store.select(getAppSettings).subscribe(options => {
      appSettings = options;
    });

    let preferableAudioOutputLabel;
    try {
      let storedOutpuLabel = localStorage.getItem("preferableAudioOutputLabel");
      this.logger.info("[JitsiService][getPreferableMicLabel][getAvailableMediaDevices] localStorage preferableAudioOutputLabel", storedOutpuLabel);
      if ((!!storedOutpuLabel) && (storedOutpuLabel !== "")) {
        preferableAudioOutputLabel = storedOutpuLabel;
      }
    } catch (e) {
      this.logger.info("error restoring: ", e);
    }
    if (!preferableAudioOutputLabel) {
      preferableAudioOutputLabel = appSettings.preferableMediaDevices && appSettings.preferableMediaDevices[CommonUtil.getDeviceId()]?.preferableAudioOutputLabel;
    }

    // if not defined or not available on current machine then use 1st available
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(devices => {
      if (!preferableAudioOutputLabel || !this.getAudioOutputDeviceIdByLabel(devices, preferableAudioOutputLabel)) {
        if (devices.audioOutput?.length > 0) {
          preferableAudioOutputLabel = devices.audioOutput[0].deviceLabel;
        }
        this.logger.info("[JitsiService][getPreferableAudioOutputLabel] use 1st available", preferableAudioOutputLabel);
      }
    });

    return preferableAudioOutputLabel;
  }
  getPreferableAudioOutputId(): string {
    let preferableAudioOutputId: string;
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(devices => {
      this.logger.info("[JitsiService][getPreferableAudioOutputId] audioOutputDevices ", devices);
      preferableAudioOutputId = this.getAudioOutputDeviceIdByLabel(devices, this.getPreferableAudioOutputLabel());
    });

    return preferableAudioOutputId;
  }

  public getAudioInputDeviceIdByLabel(availableMediaDevices: any, deviceLabel: string) {
    if (!availableMediaDevices?.audioInput) {
      return "";
    }
    const device = availableMediaDevices.audioInput.find(d => d.deviceLabel === deviceLabel || d.deviceId === deviceLabel);
    return device?.deviceId;
  }

  public getVideoInputDeviceIdByLabel(availableMediaDevices: any, deviceLabel: string) {
    if (!availableMediaDevices?.videoInput) {
      return "";
    }
    const device = availableMediaDevices.videoInput?.find(d => d.deviceLabel === deviceLabel || d.deviceId === deviceLabel);
    return device?.deviceId;
  }

  public getAudioOutputDeviceIdByLabel(availableMediaDevices: any, deviceLabel: string) {
    if (!availableMediaDevices?.audioOutput) {
      return "";
    }
    const device = availableMediaDevices.audioOutput.find(d => d.deviceLabel === deviceLabel || d.deviceId === deviceLabel);
    const defaultLabeledDevice = availableMediaDevices.audioOutput.find(d => d.deviceLabel === "default" || d.deviceId === "default");
    const firstDevice = (!!availableMediaDevices.audioOutput && (availableMediaDevices.audioOutput.length > 0)) ? availableMediaDevices.audioOutput[0] : null;
    if (!!device && !!device.deviceId) {
      return device.deviceId;
    }
    if (!!defaultLabeledDevice && !!defaultLabeledDevice.deviceId) {
      return defaultLabeledDevice.deviceId;
    }
    return firstDevice?.deviceId;
  }

  // call this function only with non mutated tracks
  private setRemoteTracks(tracks: any) {
    this.logger.info("[JitsiService][setRemoteTracks]", tracks);
    this._remoteTracks$.next(tracks);
  }

  // call this function only with non mutated tracks
  private setLocalTracks(tracks: any) {
    this.logger.info("[JitsiService][setLocalTracks]", tracks);
    for (let track of tracks) {
      if (track.type === "video") {
        this.localVideo = track;
      } else if (track.type === "audio") {
        this.localAudio = track;
      }
    }
    this.updateLocalTracks(tracks);
  }

  getLocalAudio() {
    return this.localAudio;
  }

  resetParticipants() {
    this.leftList.next([]);
    this.totalLeftList.next([]);
    this.joinedList.next([]);
  }

  ///

  setCameraId(cameraId: string) {
    this.logger.info("[JitsiService][setCameraId]", cameraId);
    this.cameraId = cameraId;
  }

  getCameraId() {
    return this.cameraId;
  }

  updateCurrentDeviceLabels(): void {
    const localAudioTrack = this._localTracks$.value?.find(track => track.type === "audio");
    const localVideoTrack = this._localTracks$.value?.find(track => track.type === "video" && track.videoType === "camera");
    // this.logger.info("[JitsiService] updateDevicesListInRunningCall localtracks: ", localAudioTrack, localVideoTrack);
    const currentMicId = localAudioTrack?.deviceId;
    const currentCamId = localVideoTrack?.deviceId;
    const currentOutId = this.loadedLib$.value ? JitsiMeetJS.mediaDevices.getAudioOutputDevice() : null;
    this.store.select(getAvailableMediaDevices).pipe(take(1)).subscribe(devices => {

      const cam = (!!currentCamId) ? devices.videoInput?.find(d => d.deviceId === currentCamId) : null;
      const mic = (!!currentMicId) ? devices.audioInput?.find(d => d.deviceId === currentMicId) : null;
      const out = (!!currentOutId) ? devices.audioOutput?.find(d => d.deviceId === currentOutId) : null;
      if (!!cam) {
        this.currentCamLabel = cam?.deviceLabel;
      }
      if (!!mic) {
        this.currentMicLabel = mic?.deviceLabel;
      }
      if (!!out) {
        this.currentAudioOutputLabel = out?.deviceLabel;
      }
      // this.logger.info("[JitsiService] updateDevicesListInRunningCall record: ", this.currentMicLabel, this.currentCamLabel, this.currentAudioOutputLabel);
    });
  }
  ///

  roomAvailable(): Observable<boolean> {
    return this._room;
  }

  getTracksForParticipant(participantId: string, screenshare: boolean = false): Observable<any[]> {
    // define empty array here instead of inline so that distinctUntilChanged doesn't fire again.
    // this.logger.info("jitsiservice tracksubscription0 getTracksForParticipant ", participantId, this._remoteTracks$.value, this.getLocalTracks());
    const emptyTracks = [];
    if (this.isLocalId(participantId)) {
      return this.getLocalTracks();
    }
    return this._remoteTracks$.pipe(map(tracks => {
      if (participantId === "fake123") {
        this.logger.info("jitsiService remoteTracks jitsistreamCheckUpdateTracks: ", tracks, screenshare, this.realScreenShareParticipantId$.value);
      }
      if (tracks !== null && tracks[participantId]) {
        let currentTracks = tracks[participantId];
        if (screenshare && tracks["fake123"]) {
          this.logger.info("jitsiService remoteTracks jitsistreamCheckUpdateTracksReturn: ", tracks, screenshare, this.realScreenShareParticipantId$.value, currentTracks);
          if (tracks["fake123"].length > 0) {
            currentTracks = tracks["fake123"];
          }
        }

        return currentTracks;
      }
      return emptyTracks;
    }));
  }

  getLocalTracks(): Observable<any[]> {
    return this._localTracks$;
  }

  updateLocalTracks(tracks: any[]) {
    const localCamTrack = tracks.filter(t => t.type === "video" && t.videoType === "camera");
    // this.logger.info("[JitsiService][updateLocalTracks isCamOn]", tracks, localCamTrack);
    if (!!localCamTrack) {
      if (localCamTrack.length > 0 && !localCamTrack[0].isMuted()) {
        this.logger.info("[JitsiService][updateLocalTracks isCamOn] => true ", tracks, localCamTrack, !!localCamTrack, localCamTrack.length);
        this.isCamOn.next(true);
      } else {
        this.isCamOn.next(false);
      }
    }

    this._localTracks$.next(tracks);
  }

  private join() {
    this.logger.info("[JitsiService][join]");
    this.switchedQuality = 0;
    this.minRemoteQuality = 720;
    let isOnFirefox = /firefox/i.test(navigator.userAgent.toLowerCase());
    if (isOnFirefox) {
      this.switchDownVideoQuality(true);
    }

    this.store.dispatch(new ResetScreenSharingData());
    document.querySelector("body").classList.remove("white-board-on");
    this.onWhiteboardOpen$.next(false);

    this.store.select(getJitsiRoom).pipe(filter(v => !!v), take(1)).subscribe(option => {
      let jitsiRoomId = option?.value;
      let jitsiUrl = option?.jitsiurl;
      if (!!jitsiUrl) {
        const lastIndexOf = jitsiUrl.lastIndexOf("/");
        if (lastIndexOf > 0) {
          jitsiUrl = jitsiUrl.slice(0, lastIndexOf + 1);
        }
      }

      // refresh jitsi config
      const t0 = performance.now();
      this.logger.info("[JitsiService][join][loadJitsiConfig]", jitsiUrl, jitsiRoomId);
      this.configService.getJitsiConfig(jitsiUrl, jitsiRoomId).subscribe(jitsiConfig => {
        const t1 = performance.now();
        this.logger.info("[JitsiService][join][loadJitsiConfigPerformance took] ", t1 - t0);
        const timediff = t1 - t0;
        if (timediff > 150) {
          this.switchDownVideoQuality();
        }
        if (timediff > 250) {
          this.switchDownVideoQuality();
        }
        this.logger.info("[JitsiService][join][loadJitsiConfig] res", jitsiConfig);
        this.init(jitsiConfig);
        this.store.dispatch(new UpdateJitsiConfig(jitsiConfig));

        this.connect();
      }, err => {
        this.logger.error("[JitsiService][join][loadJitsiConfig] err", err);
        this.connect();
      });
    });
  }

  joinWithPassword(password) {
    this.room.join(password);
    this.joinedWithPassword = password;
  }

  setWhiteboardStatus(val) {
    this.onWhiteboardOpen$.next(val);
  }

  init(jitsiConfig: any) {
    this.jitsiActiveConfig = CommonUtil.isOnIOS() ? { ...jitsiConfig, disableAudioLevels: true } : jitsiConfig;

    let isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
    let isOnFirefox = /firefox/i.test(navigator.userAgent.toLowerCase());
    this.jitsiActiveConfig["videoQuality"] = {
      preferredCodec: "VP9",
      maxBitratesVideo: {
          H264: {
              low: 200000,
              standard: 500000,
              high: 1500000
          },
          VP8: {
              low: 400000,
              standard: 1000000,
              high: 3000000
          },
          VP9: {
            low: 200000,
            standard: 1000000,
            high: 3000000
          }
      },
    };

    if (isOnFirefox || isiOSDevice) {
      this.jitsiActiveConfig.videoQuality.preferredCodec = "VP8";
    }

    this.logger.info("[JitsiService][init] config", this.jitsiActiveConfig);

    JitsiMeetJS.init(this.jitsiActiveConfig);
  }

  getJitsiActiveConfig() {
    return this.jitsiActiveConfig || this.jitsiCurrentEnvConfig;
  }

  getRoomJID(roomJIDLocalPart: string) {
      return roomJIDLocalPart + "@" + this.getJitsiActiveConfig().hosts.muc;
  }

  private connect(): void {
    // this.logger.info("[JitsiService][connect]");

    if (this.configService.get("requireJitsiAuth")) {
      const storedJitsiAuth = localStorage.getItem("jitsiauth");
      const configJitsiAuth = this.configService.get("jitsiauth");
      const useJitsiAuth = !!configJitsiAuth ? configJitsiAuth : (!!storedJitsiAuth ? storedJitsiAuth : "none");
      this.connection = new JitsiMeetJS.JitsiConnection(this.configService.get("jitsiAppId"), useJitsiAuth, this.getJitsiActiveConfig());
      this.logger.info("[JitsiService][connect] connect with jitsiauth", useJitsiAuth,  this.configService.get("jitsiAppId"));
    } else {
      let storedJitsiAuth = localStorage.getItem("jitsiauth");
      if (!!storedJitsiAuth) {
        this.connection = new JitsiMeetJS.JitsiConnection("AIH5DDgeaDDhWWoIELRlAPuQXua14ZiP3Tk", storedJitsiAuth, this.getJitsiActiveConfig());
      } else {
        this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.getJitsiActiveConfig());
      }
    }

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess.bind(this)
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed.bind(this)
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.onDisconnect.bind(this)
    );

    /*
    * #30003052-3849
    * https://github.com/jitsi/lib-jitsi-meet/blob/master/doc/API.md#jitsiconnection
    * https://redmine.vnc.biz/resolutions/56
    * set options so that first user authenticates
    * limitations: works only if user is in base domain for jitsi
    */
    this.uConnOptions = {};
    //
    const currentJitsiURL = this.configService.get("jitsiURL");
    const requireJitsiAuth = this.configService.get("requireJitsiAuth");
    const foreignJitsi = !currentJitsiURL.includes(this.getJitsiActiveConfig().hosts.domain);
    //
    this.logger.info("[JitsiService][connect]", {currentJitsiURL, requireJitsiAuth, foreignJitsi, uConnOptions: this.uConnOptions});

    this.connection.connect(this.uConnOptions);
  }

  private  disconnect() {
    this.logger.info("[JitsiService][disconnect]");
    if (!this.connection) {
      console.warn("[JitsiService][disconnect] skip, already disconnected");
      return;
    }

    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess.bind(this));
    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed.bind(this));
    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.onDisconnect.bind(this));

    this.connection.disconnect();

    this.connection = null;
  }

  myUserId() {
    return this.room && this.room.myUserId();
  }

  private onConferenceJoined() {
    this.logger.info("[JitsiService][onConferenceJoined]", this.room, this.activeTarget);
    if (environment.isCordova && CommonUtil.isOnAndroid()) {
      // if (device && (device.platform === "Android") && ((device.version === "12") || (device.version === "13"))) {
      if (device && (device.platform === "Android")) {
        this.logger.info("[JitsiService][onConferenceJoined] hideIncomingCallNotification", this.room, this.activeTarget);
        this.notificationsService.hideIncomingCallNotification(this.activeTarget, false);
        /* ToDo: evaluate if it is a good idea to set volume on call join.
        if (!!window.androidVolume) {
          window.androidVolume.set(85, () => {
            this.logger.info("[JitsiService][onConferenceJoined] androidVolume set to 85");
          }, err => {
            this.logger.info("[JitsiService][onConferenceJoined] androidVolume error: ", err);
          });
        }
        */
      }
    }

    this.inConference = true;
    this.zeroUploadCount = 0;
    this.zeroRemoteTracks = 0;
    this.recoverAttempts = 0;
    this.hideLobbyScreen();
    localStorage.setItem("white-board-open", "false");
    localStorage.setItem("raiseMyHand", "false");
    if (!!this.conferencePassword[this.activeTarget] && this.conferencePassword[this.activeTarget].trim() !== "") {
      this.setPassword(this.conferencePassword[this.activeTarget]);
    }
    this.store.select(state => getConversationById(state, this.activeTarget)).pipe(take(1)).subscribe((conv: Conversation) => {
      if (conv && conv.conference_start && conv.conference_start * 1000 + 5 * 60 * 1000 > new Date().getTime()) {
        this.enableLobby().then(() => {
          this.logger.info("[enableLobby] ok");
          this.store.dispatch(new UpdateLobbyState(true));
        }).catch(err => {
            this.logger.error("[enableLobby] err", err);
        });
      }
    });
    this.sendFollowMeCommand();
    this.sendInitiatorCommand();
    this.setUpCallDurationTimer();
    this.startListeningTrackAudioLevelChanges();
    this.setupNetworkChangesListener();
    this.sendParticipantInformation();
    this.onConferenceJoin$.next(true);
    const participantId = this.myUserId();
    const lastTimeJoined = this.lastTimeJoined$.value;
    lastTimeJoined[participantId] = new Date().getTime();
    this.lastTimeJoined$.next(lastTimeJoined);
    const participant = this.getParticipantById(participantId);
    let newParticipant: JitsiParticipant = { id: participantId, name: (participant && participant._displayName) || "ME" };

    this.store.dispatch(new ConferenceAddParticipant(newParticipant));
    this.store.dispatch(new JitsiConferenceAddParticipant(newParticipant));
    this.participantsList[participantId] = newParticipant;

    this.joinedWithPassword = null;
    // window.room = this.room;
    if (environment.isCordova && CommonUtil.isOnAndroid()) { // must set output when we have the first sound
      setTimeout(() => {
        if (this.enabledVideo) {
          this.audioOutputService.enableDefaultSpeakerOutput();
          this.audioOutputService.enableDefaultSpeakerOutput();
        } else {
          this.audioOutputService.enableDefaultOutput();
          this.audioOutputService.enableDefaultOutput();
        }
      }, 2000);
    }

    this.participantsStats = {};
    this.participantsConnStatus = {};

    this.processLocalTracks();

    console.log("[jitsiservice] broadcasting onConferenceJoined");
    this.broadcaster.broadcast("onConferenceJoined", this.activeTarget);
}

setPassword(password) {
  this.logger.info("call [set password]", this.onConferenceJoin$.value);
  if (this.setPassword$ && this.setPassword$.unsubscribe) {
    this.setPassword$.unsubscribe();
  }
  this.setPassword$ = this.onConferenceJoin$.asObservable().pipe(filter(v => !!v), take(1)).subscribe(() => {
    if (this.room) {
      this.logger.info("call [set password]", this.room.isModerator());
      setTimeout(() => {

        if (this.room.isModerator()) {
          this.room.lock(password).then(() => {
            this.translate.get("PASSWORD_IS_ADDED").pipe(take(1)).subscribe(text => {
              this.notificationsService.html("", text, "custom", { bgColor: "#fe5019", timeOut: 6000 });
            });
            this.logger.info("[set password]", password);
          }).catch(err => {
            this.logger.info("[set password] err", err);
          });
        } else {
          timer(0, 500).pipe(take(5))
          .subscribe(() =>
          {
            if (this.room.isModerator()) {
              this.logger.info("call [set password]", password, this.room.isModerator());
              this.room.lock(password).then(() => {
                this.translate.get("PASSWORD_IS_ADDED").pipe(take(1)).subscribe(text => {
                  this.notificationsService.html("", text, "custom", { bgColor: "#fe5019", timeOut: 6000 });
                });
                this.logger.info("[set password]", password);
              }).catch(err => {
                this.logger.info("[set password] err", err);
              });
            }
          });
          if (!this.configService.isAnonymous && this.isModeratorOrOwner()) {
            // You are not jitsi moderator so need jitsi moderator to set password
            this.sendDataOnce(ConstantsUtil.SET_PASSWORD_COMMAND, password);
          }

        }
      }, 500);
    }
  });

}

setConferencePassword(target, password) {
  this.conferencePassword[target] = password;
  this.logger.info("[setConferencePassword]", target, password);
}

private getContactById(bare: string): Observable<Contact> {
  return this.store.select(state => getContactById(state, bare));
}

loadIframe(iframeName, url) {
  this.logger.info("[loadIframe]", iframeName, url);
    let iframe = document.getElementById(iframeName);
    if (!!iframe) {
      iframe.setAttribute("src", url);
      iframe.style.display = "block";
      iframe = null;
    }
}

  private onMessageReceived(id, text, ts) {
    let messageObj = JSON.parse(text);
    const participant = this.room.getParticipantById(messageObj.userID);
    let displayName = messageObj.userID;
    let fullName = messageObj.userID;
    this.logger.info("[onMessageReceived]", id, ts, messageObj);
    if (this.configService.isWhiteboardAvailable() && messageObj.EventType === 1001 && this.configService.get("whiteBoardAvailable") && !environment.isCordova) {
      const whiteboardURL = this.configService.get("whiteboardURLNew");
      const whiteboardId = messageObj.whiteboardId;
      if (!!whiteboardId && !!whiteboardURL) {
        this.store.dispatch(new ChangeLayout(ScreenViews.FILMSTRIP));
        this.whiteboardData$.next({whiteboardId: whiteboardId, participantId: messageObj.FromParticipantID});
        const username = participant ? participant._displayName : displayName;
        const url = `${whiteboardURL}?whiteboardid=${whiteboardId}&username=${username}`;
        window.sessionStorage.setItem("white-board", whiteboardId);
        localStorage.setItem("white-board-open", "true");
        this.broadcaster.broadcast("setFullPreviewWhiteboard", this.participantsList[messageObj.FromParticipantID]);
        setTimeout(() => {
          this.loadIframe("whiteboardFrame", url);
          this.whiteboardMode$.next("");
          this.onWhiteboardOpen$.next(true);
        }, 1000);
      }
    } else if (this.configService.isWhiteboardAvailable() && messageObj.EventType === 1002 && !environment.isCordova) {
      this.whiteboardMode$.next("");
      this.whiteboardData$.next({});
      this.setWhiteboardStatus(false);
      localStorage.setItem("white-board-open", "false");
      // (<HTMLElement>document.querySelector("#whiteboardFrame")).style.display = "none";
      this.translate.get("WHITEBOARD_SESSION_IS_ENDED").pipe(take(1)).subscribe(text => {
        this.notificationsService.html("", text, "info", { bgColor: "#fe5019", timeOut: 6000 });
      });
    } else if (messageObj.EventType === 1003) {
      if (this.myUserId() !== messageObj.userID) {

        if (!!participant) {
          displayName = participant._displayName;
          this.getContactById(displayName).pipe(take(1)).subscribe(contact => {
            if (contact) {
              fullName = contact.name || contact.local;
            }
            fullName = displayName.split("@")[0];
          });
        }
        this.setRaiseHandStatus(messageObj.userID, true);
        let raisedNotifications = this.raisedNotifications$.value;
        if (!raisedNotifications.find(v => v.participantId === messageObj.userID)) {
          raisedNotifications.unshift({participantId: messageObj.userID, email: displayName, fullName: fullName, status: "requested"});
          this.raisedNotifications$.next(raisedNotifications);
        }
        let isModerator = this.isModeratorOrOwner();
        if (isModerator) {
          // this.translate.get("USER_WANTS_TO_SPEAK", {fullName: fullName}).pipe(take(1)).subscribe(text => {
          //   this.notificationsService.html("", text, "custom", { bgColor: "#fe5019", timeOut: 6000, bare: displayName,  action: "RAISE_HAND", uID: messageObj.userID });
          // });
          setTimeout(() => {
            this.raisedNotifications$.next(this.raisedNotifications$.value.map(v => {
              if (messageObj.userID === v.participantId) {
                v.isCollapsed = true;
              }
              return v;
            }));
          }, 10000);
        } else {
          setTimeout(() => {
            this.closeRaising(messageObj.userID);
          }, 5000);
        }
      }
    }
    else if (messageObj.EventType === 1004) {
      this.setRaiseHandStatus(messageObj.userID, false);
      if (this.myUserId() == messageObj.userID) {
        let text = "";
        this.translate.get(messageObj.Message).pipe(take(1)).subscribe(v => text = v);
        if (messageObj.allow) {
          this.unmuteAudio(messageObj.userID);
          this.notificationsService.html("", text, "allowed", { bgColor: "#fe5019", timeOut: 5000 });
        } else {
          this.notificationsService.html("", text, "rejected", { bgColor: "#fe5019", timeOut: 5000 });
          const data = {
            participantId: this.myUserId(),
            on: false
          };
          this.setRaiseHandStatus(this.myUserId(), false);
          this.sendData("status", JSON.stringify(data));
        }
      } else {
        let raisedNotifications = this.raisedNotifications$.value.filter(v => v.participantId !== messageObj.userID);
        const participant = this.room.getParticipantById(messageObj.userID);
        let displayName = messageObj.userID;
        let fullName = messageObj.userID;
        if (!!participant) {
          displayName = participant._displayName;
          this.getContactById(displayName).pipe(take(1)).subscribe(contact => {
            if (contact) {
              fullName = contact.name || contact.local;
            }
            fullName = displayName.split("@")[0];
          });
        }
        if (messageObj.allow) {
          raisedNotifications.unshift({participantId: messageObj.userID, email: displayName, fullName: fullName, status: "allowed"});
        } else {
          raisedNotifications.unshift({participantId: messageObj.userID, email: displayName, fullName: fullName, status: "rejected"});
        }
        this.raisedNotifications$.next(raisedNotifications);
        setTimeout(() => {
          this.closeRaising(messageObj.userID);
        }, 5000);
      }
    } else if (messageObj.action === "TOGGLE_PIN") {
      this.logger.info("TOGGLE_PIN", messageObj);
      if (messageObj.isPinned) {
        this.pinnedParticipant$.next(messageObj.participantId);
      } else {
        this.pinnedParticipant$.next(null);
      }
    }
  }

  private setRaiseHandStatus(participantId, value) {
    let raisedHandList = this.raisedHandList$.value;
    raisedHandList[participantId] = value;
    this.raisedHandList$.next(raisedHandList);
  }

  private onConferenceFailed(err, ...params) {
    const key = CommonUtil.findKey(this.CONFERENCE_ERRORS, value => value === err);
    this.logger.error("[JitsiService][onConferenceFailed]", err, params, key, this.joinedWithPassword);
    if (key) {
      this.logger.error("[JitsiService][onConferenceFailed] err: ", err);
      this.logger.error("[JitsiService][onConferenceFailed] key: ", key);
      this.logger.error("[JitsiService][onConferenceFailed] params: ", params);
      if (err === JitsiMeetJS.errors.conference.AUTHENTICATION_REQUIRED) {
        // proper handling for authentication
        // internal users always send their auth credentials along
        // when jitsi was determined to use remote jitsi, wait for user of this jitsi to join
        // attempt rejoin every 2 seconds
        //
        // the case with added password uses JitsiMeetJS.errors.conference.PASSWORD_REQUIRED
        setTimeout(() => {
          this.logger.info("[jitsiService] AuthWaitReJoin attempt");
          if (!this.isJoined) {
            this.room.join();
          }

        }, 2000);
      } else if (err === JitsiMeetJS.errors.conference.ICE_FAILED) {
        this.logger.info("[JitsiService][onConferenceFailed] ICE_FAILED");

        this.callRestoreNotPossible(err);

      } else if (!this.checkOpenPopup(key) && key === "PASSWORD_REQUIRED") {
        this.logger.info("[JitsiService][PASSWORD_REQUIRED] :", this.externalData);
        let messageKey = "password_required";
        if (this.joinedWithPassword) {
          messageKey = "access_denied";
        }
        if (!!this.externalData?.info?.password && !this.usedExternalPass) {
          this.logger.info("[JitsiService][PASSWORD_REQUIRED] - should use :", this.externalData.info.password);
          this.usedExternalPass = true;
          setTimeout(() => {
            this.room.join(this.externalData.info.password);
          }, 200);
        } else {
          this.openPopupList.push(key);
          this.showPasswordDialog({ action: messageKey});
        }
      } else if (err === JITSI_MEET.JitsiConferenceErrors.MEMBERS_ONLY_ERROR) {
        this.logger.error("[MEMBERS_ONLY_ERROR] Handle lobby", this.room);
        this.lobbyRoom = params[0];
        // Join the lobby chat room and send request to moderator
        // If the moderator allowed then participant will be joined the conference automatically
        // Participant will leave the lobby room
        this.openLobbyScreen();
      } else if (err === JITSI_MEET.JitsiConferenceErrors.CONFERENCE_ACCESS_DENIED) {
        // When join request is denied by moderator
        // Moderator Kick participant with jid
        this.logger.error("[CONFERENCE_ACCESS_DENIED] Your join request was rejected by a moderator.");
        this.translate.get("REQUEST_WAS_REJECTED").pipe(take(1)).subscribe(content => {
          this.notificationsService.html("", content, "custom", { bgColor: "#fe5019", timeOut: 5000});
        });
        this.broadcaster.broadcast("CONFERENCE_ACCESS_DENIED");
      } else if (err === JITSI_MEET.JitsiConferenceErrors.RESERVATION_ERROR) {
        // meeting was created by other user - waiting for owner to start
        this.logger.error("[CONFERENCE_ACCESS_DENIED] Your join request was rejected by a moderator.");
        this.translate.get("REQUEST_WAS_REJECTED").pipe(take(1)).subscribe(content => {
          this.notificationsService.html("", content, "custom", { bgColor: "#fe5019", timeOut: 8000});
        });
        this.broadcaster.broadcast(BroadcastKeys.CALL_REQUEST_TIMED_OUT);
      }
    }
  }

  private onConferenceLeft() {
    this.logger.info("[JitsiService][onConferenceLeft]");

    this.inConference = false;

    if (this.recordInterval) {
      this.recordInterval.unsubscribe();
    }
    this.recordSessionId = "";
    this.isRecording = false;
    this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
    this.broadcaster.broadcast("onConferenceLeft");
    this.store.dispatch(new ConferenceLeaveSuccess());
  }

  private onDominantSpeakerChanged(userID) {
    this.logger.info("[JitsiService][DOMINANT_SPEAKER_CHANGED]", userID, this.getDisplayName(userID));

    // - By default always display video of speaking person.
    // - If I am speaking person in that case display video of last speaker
    //
  // display a user who is speaking currently as full screen
    if (userID !== this.myUserId()) {
      this.store.dispatch(new SetSpeakingParticipant(userID));
      this.lastDominantSpeaker$.next(userID);
      this.setLastTimeSpeaking(userID);
    }
  }

  private setLastTimeSpeaking(userID) {
    const lastTimeSpeaking = this.lastTimeSpeaking$.value;
    lastTimeSpeaking[userID] = new Date().getTime();
    this.lastTimeSpeaking$.next(lastTimeSpeaking);
    this.lastSpeakingParticipant$.next(userID);
    // this.logger.info("[setLastTimeSpeaking]", userID, lastTimeSpeaking);
  }

  private onTrackAudioLevelChanged(userID, audioLevel) {
    // this.logger.info("[JitsiService][onTrackAudioLevelChanged]", {userID, audioLevel});

    this._onAudioLevelChange$.next({userID, audioLevel});
  }
  private startListeningTrackAudioLevelChanges() {
    this.stopListeningTrackAudioLevelChanges();

    this._onAudioLevelChangeSubscription$ = this._onAudioLevelChange$.pipe(bufferTime(500), filter(res => res.length > 0)).subscribe(changes => {
      // collect last change per user
      let audioLevelsMap = {};
      changes.forEach(c => {
        audioLevelsMap[c.userID] = c.audioLevel;
      });

      Object.keys(audioLevelsMap).forEach(userID => {
        const audioLevel = audioLevelsMap[userID];
        const speakingParticipant = this.speakingParticipant$.value;
        if (audioLevel >= 0.02) {
          this.lastSpeakingParticipant$.next(userID);
          const lastTimeSpeaking = this.lastTimeSpeaking$.value;
          lastTimeSpeaking[userID] = new Date().getTime();
          this.lastTimeSpeaking$.next(lastTimeSpeaking);
          this.setLastTimeSpeaking(userID);
          speakingParticipant[userID] = audioLevel;
        } else {
          speakingParticipant[userID] = 0;
        }

        this.speakingParticipant$.next(speakingParticipant);
        if (!document.getElementById("participant" + userID)) {
          return;
        }

        let speakingIcon = document.querySelector("#small_videos #participant" + userID + " .is-speaking");
        let currentTracks;
        this.getTracksForParticipant(userID).pipe(take(1)).subscribe((tracks) => {
          currentTracks = tracks;
        });
        let isMuted = false;
        if (currentTracks) {
          const filteredTracks = currentTracks.filter(t => t.getType() !== "video");
          isMuted = filteredTracks[0] && filteredTracks[0].isMuted();
        }
        if (audioLevel > 0.03 && !isMuted) {
          if (speakingIcon && speakingIcon?.className.indexOf("hide") !== -1) {
            speakingIcon.classList.remove("hide");
          }
        } else {
          if (speakingIcon && speakingIcon?.className.indexOf("hide") === -1) {
            speakingIcon.classList.add("hide");
          }
        }
        speakingIcon = null;
      });
    });
  }

  private stopListeningTrackAudioLevelChanges() {
    if (this._onAudioLevelChangeSubscription$) {
      this._onAudioLevelChangeSubscription$.unsubscribe();
      this._onAudioLevelChangeSubscription$ = null;
    }
  }

  private processLocalTracks() {
    this.logger.info("[JitsiService][processLocalTracks]", this._localTracks$.value);
    this.store.select(getConferenceType).pipe(take(1)).subscribe(conferenceType => {
      if (!!conferenceType && conferenceType === "screen") {
        this.updateLocalTracks(this._localTracks$.value.filter(v => v.videoType !== "camera"));
      }
    });
  }

  attachAllTracks(videoOnly?: boolean) {
    this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
      this.logger.info("[JitsiService][attachAllTracks] participants: ", participants);

      for (let participant of participants) {
        const participantId = participant.id;
        this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
          for (let track of tracks) {
            // this.logger.info("[JitsiService][attachAllTracks] processing track: ", participantId, track);
            if (!videoOnly || videoOnly && track.type === "video") {
              // this.logger.info("[JitsiService][attachAllTracks] attaching track: ", participantId, track);
              this.attachTrack(track, participantId);
            }
          }
        });
      }
    });
  }

  detachAllTracks(videoOnly?: boolean) {
    this.logger.info("[JitsiService][detachAllTracks]", this.tempLocalTracks);

    this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
      for (let participant of participants) {
        const participantId = participant.id;
        this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
          for (let track of tracks) {
            for (let container of track.containers) {
              if (!videoOnly || videoOnly && track.type === "video") {
                if (container.id.indexOf("participantPreviewVideo") === -1) {
                  track.detach && track.detach(container);
                }
              }
            }
          }
        });
      }
    });

    if (this.tempLocalTracks) {
      this.disposeAndRemoveTracks(this.tempLocalTracks);
      this.tempLocalTracks = null;
    }
  }

  detachAllVideoTracks() {
    this.logger.info("[JitsiService][detachAllVideoTracks]");

    this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
      for (let participant of participants) {
        const participantId = participant.id;
        this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
          this.logger.info("[JitsiService][detachAllVideoTracks] tracks", tracks);
          for (let track of tracks) {
            if (track.type === "video") {
              for (let container of track.containers) {
                track.detach && track.detach(container);
              }
            }
          }
        });
      }
    });
  }

  private setSenderVideoConstraint() {
    this.logger.info("[JitsiService][setSenderVideoConstraint]", this.videoQuality$.value, this.localHasScreenShared$.value, this.remoteHasScreenShared$.value);
    // toDo debug / adjust to updated lib
    // return;

    if (this.room) {
      if (this.localHasScreenShared$.value || this.remoteHasScreenShared$.value) {
        if (this.remoteHasScreenShared$.value) {
          // select Hires presenter
          this.logger.info("[JitsiService][setSenderVideoConstraintVal]", VideoQuality.LOW, this.remoteHasScreenShared$.value);
          this.room.setSenderVideoConstraint(VideoQuality.LOW)
          .catch(err => {
            this.logger.error(`Changing sender resolution to ${this.videoQuality$.value} failed - ${err} `);
          });
        } else {
          this.logger.info("[JitsiService][setSenderVideoConstraintVal]", VideoQuality.HIGHEST, this.remoteHasScreenShared$.value);
          this.room.setSenderVideoConstraint(VideoQuality.HIGHEST)
          .catch(err => {
            this.logger.error(`Changing sender resolution to ${this.videoQuality$.value} failed - ${err} `);
          });
        }
      } else {
        this.logger.info("[JitsiService][setSenderVideoConstraintVal]", this.videoQuality$.value);
        // this.room.setSenderVideoConstraint(this.videoQuality$.value);
        this.room.setSenderVideoConstraint(this.videoQuality$.value)
        .catch(err => {
          this.logger.error(`Changing sender resolution to ${this.videoQuality$.value} failed - ${err} `);
        });
      }
    }
  }

  private setSelectedUsersVideoQuality() {
    if (!this.room) {
      console.warn("[JitsiService][setSelectedUsersVideoQuality] ignore, no room");
      return;
    }


    const availableHeight = 200;
    const isReducedUI = false;

    const resolution = this.getVideoQualityLevel(((this.currentView === "tile") && (!this.remoteHasScreenShared$.value)), availableHeight, isReducedUI);

    this.logger.info("[JitsiService][setSelectedUsersVideoQuality]", resolution, this.currentView, availableHeight, this.screenSharePresenterParticipant$.value, this.fullScreenParticipantId);

    // set the desired resolution to get from JVB (180, 360, 720, 1080, etc). You should use that method if you are using simulcast.
    this.room.setReceiverVideoConstraint(resolution);
  }

  private getVideoQualityLevel(isTilesViewMode: boolean, availableHeight: number, isReducedUI?: boolean) {

    if (isReducedUI) {
      // 'reducedUI' means e.g. we switched to chat while having an active call,
      // so no need to request high res
      return VideoQuality.LOW;
    }

    // tiles view
    if (isTilesViewMode) {
      // TODO: need to calculate the desired resolution based on screen settings
      //
      // const qualityLevels = [
      //     VideoQuality.HIGH,
      //     VideoQuality.STANDARD,
      //     VideoQuality.LOW
      // ];
      //
      // let selectedLevel = qualityLevels[0];
      //
      // for (let i = 1; i < qualityLevels.length; i++) {
      //     const previousValue = qualityLevels[i - 1];
      //     const currentValue = qualityLevels[i];
      //     const diffWithCurrent = Math.abs(availableHeight - currentValue);
      //     const diffWithPrevious = Math.abs(availableHeight - previousValue);
      //
      //     if (diffWithCurrent < diffWithPrevious) {
      //         selectedLevel = currentValue;
      //     }
      // }
      //
      // return selectedLevel;

      // for now we make it simple
      let participants;
      this.store.select(getConferenceParticipants).subscribe(p => {
        participants = p;
      });
      this.logger.info("[jitsi.service][getVideoQualityLevel] this.videoQuality.value$ ", this.videoQuality$.value);
      let maxFrameHeight;
      if (this.videoQuality$.value >= VideoQuality.STANDARD) {
        this.logger.info("[jitsi.service][getVideoQualityLevel] better quality for receive ");
        if (participants) {
          if (participants.length <= 4) {
            maxFrameHeight = VideoQuality.HIGH;
          } else if (participants.length > 4 && participants.length < 8) {
            maxFrameHeight = VideoQuality.STANDARD;
          } else {
            maxFrameHeight = VideoQuality.LOW;
          }
        } else {
          maxFrameHeight = VideoQuality.LOW;
        }
      } else {
        this.logger.info("[jitsi.service][getVideoQualityLevel] lower quality for receive ");
        if (participants) {
          if (participants.length <= 4) {
            maxFrameHeight = VideoQuality.STANDARD;
          } else {
            maxFrameHeight = VideoQuality.LOW;
          }
        } else {
          maxFrameHeight = VideoQuality.LOW;
        }
      }

      return maxFrameHeight;

    // speaker view
    } else {
      // For speaker view - a highest quality should be used for the selected user as new jitsi flow
      if ((this.videoQuality$.value >= VideoQuality.STANDARD) || this.remoteHasScreenShared$.value) {
        if (this.screenSharePresenterParticipant$.value && (this.screenSharePresenterParticipant$.value !== "")) {
          this.selectHiResUsers([this.screenSharePresenterParticipant$.value]);
        }
        return (CommonUtil.isOnAndroid()) ? VideoQuality.HIGH : VideoQuality.HIGHEST;
      } else {
        if (this.screenSharePresenterParticipant$.value && (this.screenSharePresenterParticipant$.value !== "")) {
          this.selectHiResUsers([this.screenSharePresenterParticipant$.value]);
          return VideoQuality.HIGH;
        } else {
          return VideoQuality.STANDARD;
        }
      }
    }
  }

  private selectHiResUsers(participantIds){
    // By default, JVB broadcasts the lowest video quality level (180).
    // So we need to select users we want a better quality
    //
    // For 'speaker' view it will be only one user with better quality
    // For 'tile' view all the users will have better quality (720 or 360 depending on users amount)

    if (!this.room) {
      console.warn("[JitsiService][selectHiResUsers] ignore, no room");
      return;
    }
    // debug screen share
    this.logger.info("[JitsiService][selectHiResUsers]", participantIds);


    if (participantIds) {
      this.logger.info("[JitsiService][selectHiResUsers]", participantIds.map(pid => this.getDisplayName(pid)));
      const remoteParticipants = participantIds.filter(p => p !== this.myUserId());
      // this.logger.info("[JitsiService][selectHiResUsers1]", remoteParticipants, this.myUserId(), this._remoteTracks$.value, this.screenSharePresenterParticipant$.value, this.callView);
      let receiverConstraints = {
        lastN: 20, // Number of videos requested from the bridge.
        selectedSources: [],
        onStageSources: [],
        defaultConstraints: { maxHeight: 180 },
        constraints: []
      };
      if (remoteParticipants.includes("fake123")) {
        // screenshare
        this.logger.info("[JitsiService][selectHiResUsers1] screenshare: ", this._remoteTracks$.value, this.callView, this.currentView);
        if (!!this._remoteTracks$.value.fake123 && !!this._remoteTracks$.value.fake123[0] && !!this._remoteTracks$.value.fake123[0]._sourceName) {
          receiverConstraints.onStageSources.push(this._remoteTracks$.value.fake123[0]._sourceName);
          receiverConstraints.constraints[this._remoteTracks$.value.fake123[0]._sourceName] = { maxHeight: VideoQuality.HIGHEST };
          // this.logger.info("[JitsiService][selectHiResUsers1] setConstraints: ", receiverConstraints);
          try {
            this.room.setReceiverConstraints({ videoConstraints: receiverConstraints });
          } catch (err) {
            this.logger.error("[JitsiService][selectHiResUsers1]", err);
          }
        }

      } else {
        if (remoteParticipants.length === 1) {
          // presenter in floating
          const remoteHiresParticipantId = remoteParticipants[0];
          const remoteHiresTracks = this._remoteTracks$.value[remoteHiresParticipantId];
          // this.logger.info("[JitsiService][selectHiResUsers1] floating: ", this._remoteTracks$.value, this.callView, this.currentView, remoteHiresTracks);
          this.getTracksForParticipant(remoteHiresParticipantId).pipe(take(1)).subscribe((tracks) => {
            const remoteHiresVideo = tracks.filter(t => ((t.type === "video") && (t.videoType === "camera")));
            if (!!remoteHiresVideo && !!remoteHiresVideo[0] && !!remoteHiresVideo[0]._sourceName) {
              receiverConstraints.onStageSources.push(remoteHiresVideo[0]._sourceName);
              receiverConstraints.constraints[remoteHiresVideo[0]._sourceName] = { maxHeight: this.videoQuality$.value };
              // this.logger.info("[JitsiService][selectHiResUsers1] setConstraints: ", receiverConstraints);
              try {
                this.room.setReceiverConstraints({ videoConstraints: receiverConstraints });
              } catch (err) {
                this.logger.error("[JitsiService][selectHiResUsers1]", err);
              }
            }

          });

        } else {
          // tiles?
          const availableHeight = 200;
          const isReducedUI = false;

          const resolution = this.getVideoQualityLevel(((this.currentView === "tile") && (!this.remoteHasScreenShared$.value)), availableHeight, isReducedUI);

          // this.logger.info("[JitsiService][selectHiResUsers1] tiles: ", this._remoteTracks$.value, this.callView, this.currentView, resolution);
          receiverConstraints.defaultConstraints.maxHeight = resolution;
          this.logger.info("[JitsiService][selectHiResUsers1] setConstraints: ",receiverConstraints);
        }
        try {
          this.room.setReceiverConstraints({ videoConstraints: receiverConstraints });
        } catch (err) {
          this.logger.error("[JitsiService][selectHiResUsers1]", err);
        }
      }
    }
  }

  sendInitiatorCommand() {
    if (this.room && this.room.isModerator()) {
      this.logger.info("[sendInitiatorCommand]");
      this.store.select(getActiveConference).pipe(take(1)).subscribe(target => {
        if (!!target) {
          this.store.select(state => getConversationById(state, target)).pipe(take(1)).subscribe((conv: Conversation) => {
            if (!!conv && this.room && this.room.isModerator()) {
              this.room.setSubject(conv.groupChatTitle);
              this.logger.info("setSubject", conv?.groupChatTitle);
            }
          });
        }

        this.sendDataOnce(ConstantsUtil.SET_INITIATOR_COMMAND, JSON.stringify({
          jid: this.displayName,
          target: target
        }));
        localStorage.setItem(`callInitiator_${target}`, this.displayName);
      });
    }
  }

  hasScreenShare() {
    let hasScreenShared = false;
    if (!!this._localTracks$.value.find(track => track.type === "video"
    && (!track.disposed && (track.videoType === "desktop" || track.videoType === "screen")))) {
      hasScreenShared = true;
      this.localHasScreenShared$.next(true);
    } else {
      this.localHasScreenShared$.next(false);
    }
    for (let id of Object.keys(this._remoteTracks$.value)) {
      this.logger.info("[resetTileMode] check remote", this._remoteTracks$.value, [id]);
      if (!!this._remoteTracks$.value[id] && !!this._remoteTracks$.value[id].find(track => track.type === "video"
      && (!track.disposed && (track.videoType === "desktop" || track.videoType === "screen")))) {
        hasScreenShared = true;
      }
    }
    if (!this.localHasScreenShared$.value) {
      if (hasScreenShared) {
        this.remoteHasScreenShared$.next(true);
      } else {
        this.remoteHasScreenShared$.next(false);
      }
    }
    return hasScreenShared;
  }

  sendFollowMeCommand(isFullScreen?: boolean) {
    if (this.room && this.room.isModerator()) {
      this.logger.info("[sendFollowMeCommand]", isFullScreen);
      if (!!this._localTracks$.value.find(track => track.type === "video"
      && (track.videoType === "desktop" || track.videoType === "screen"))) {
        isFullScreen = true;
      }
      for (let id of Object.keys(this._remoteTracks$.value)) {
        if (!!this._remoteTracks$.value[id] && !!this._remoteTracks$.value[id].find(track => track.type === "video"
        && (track.videoType === "desktop" || track.videoType === "screen"))) {
          isFullScreen = true;
        }
      }
      this.room.removeCommand(ConstantsUtil.FOLLOW_ME_COMMAND);
      this.room.sendCommandOnce(ConstantsUtil.FOLLOW_ME_COMMAND, {
          attributes: {
            filmstripVisible: !isFullScreen,
            tileViewEnabled: !isFullScreen}
      });
    }
  }

  sendScreenSharingCommand(presenterJid, presenterId) {
    this.sendDataOnce(ConstantsUtil.SCREEN_SHARING_COMMAND, JSON.stringify({
      presenterJid,
      presenterId
    }));
    this.setScreenSharingRequest(presenterJid, presenterId);
  }

  sendReJoinedCommand(fullName, jid) {
    this.sendDataOnce(ConstantsUtil.COMMANDS.rejoin, JSON.stringify({
      fullName,
      jid: jid,
      participantId: this.myUserId()
    }));
    setTimeout(() => {
      this.rejoinCall();
    }, 500);
  }

  sendScreenSharingRequest(fromJid, toId) {
    this.sendDataOnce(ConstantsUtil.SCREEN_SHARING_REQUEST, JSON.stringify({
      fromJid,
      toId
    }));
    this.store.dispatch(new SetScreenSharingRequestStatus(true));
  }

  allowScreenShare(participantId: any) {
    this.unshareScreen();
    this.logger.info("[jitsiService] allowScreenShare broadcast: screenshareStopped");
    this.broadcaster.broadcast("screenshareStopped");

    this.sendDataOnce(ConstantsUtil.SCREEN_SHARING_REQUEST_ALLOW, JSON.stringify({
      toId: participantId
    }));
  }

  denyScreenShare(participantId: any) {
    this.sendDataOnce(ConstantsUtil.SCREEN_SHARING_REQUEST_DENY, JSON.stringify({
      toId: participantId
    }));
  }

  setScreenSharingRequest(presenterJid, presenterId) {
    this.store.dispatch(new SetScreenSharingRequest({isStartingScreenshare: true, presenterJid, presenterId}));
  }

  private onUserJoined(participantId) {
    const myLocalRole = this.room.getRole();
    const participant = this.getParticipantById(participantId);
    this.logger.info("[jitsiService] participantsConnStatusArray onUserJoined: ", this.room, participant);
    if (!!participant && (participant._statsID?.indexOf("jibri") > -1) && participant._hidden ) {
      return;
    }

    // detect and ignore ghost users
    if(this.detectGhostParticipant(participant)) {
      return;
    }
    this.detectReverseGhost(participant);

    if (localStorage.getItem("white-board-open") === "true") {
       document.querySelector("body").classList.add("white-board-on");
    }

    this.sendFollowMeCommand();
    this.sendInitiatorCommand();

    const lastTimeJoined = this.lastTimeJoined$.value;
    lastTimeJoined[participantId] = new Date().getTime();
    this.lastTimeJoined$.next(lastTimeJoined);

    let cl = Object.keys(this.participantsList).length;
/*    if ((cl < 3) && CommonUtil.isOnAndroid() && (this.restartWorkArounds < 2)) {
      ++this.restartWorkArounds;
      setTimeout(() => {
        this.logger.info("[jitsiService] onUserJoined - workaround  _restartMediaSessions ?", cl, this.restartWorkArounds);
        // this.room._restartMediaSessions();
      }, 1500);
    } */
    this.logger.info("[JitsiService][onUserJoined2]", participantId, participant, this.getDisplayName(participant), this.isModerator(participantId), cl);

    this.sendRolesData();
    this.sendParticipantInformation();
    this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
      if (type && type.startsWith("screen") && this.room && this.room.isModerator()) {
        this.startScreenSharingCommand();
        this.setScreenSharingSession();
      } else if (this._localTracks$.value.find(v => v.videoType === "desktop")) {
        this.startScreenSharingCommand();
      }
    });

    // leave a conf if same user from other device joined
    if (this.isJoined && participant._displayName && participant._displayName === this.displayName
      && !this.configService.isAnonymous && participant._displayName.indexOf("@") !== -1) {
      console.warn("[JitsiService][onUserJoined] leave conf, same user from other device joined");

      // a small delay, for not to get a 'CALL_LAST_REMOTE_USER_LEFT' case
      setTimeout(() => {
        console.warn("[JitsiService][onUserJoined] leave conf");
        this.broadcaster.broadcast(BroadcastKeys.CALL_SECOND_DEVICE_JOINED);
      }, 3000);

      return;
    }

    if (participantId && !participantId.startsWith("recorder")) {
      const newParticipant: JitsiParticipant = { id: participantId, name: (participant && participant._displayName) || participantId };
      this.store.dispatch(new ConferenceAddParticipant(newParticipant));
      this.store.dispatch(new JitsiConferenceAddParticipant(newParticipant));
    }

    if (participant && participant._displayName) {
      this.participantsList[participantId] = { id: participantId, name: participant._displayName };
      let displayName = participant._displayName || "";
      if (displayName && this.joinedList.value.indexOf(displayName) === -1) {
        this.joinedList.next([...this.joinedList.value, displayName]);
      }
      this.broadcaster.broadcast("onUserJoined", displayName);
      this.logger.info("[onUserJoined]", this.joinedList.value);
    }

    // select 1st joined user as full screen
    this.store.select(getFullScreenParticipantId).pipe(take(1)).subscribe(id => {
      if (!id) {
        this.setFullScreenParticipantId(participantId);
      }
    });

    this.broadcaster.broadcast(BroadcastKeys.CALL_ON_USER_JOINED);

    this.store.select(getActiveConference).pipe(take(1)).subscribe((conferenceTarget: string) => {
      if (!!conferenceTarget) {
        this.activeTarget = conferenceTarget;
        let owner$ = this.store.select(state => getConversationOwner(state, conferenceTarget)).pipe(take(1));
        let admin$ = this.store.select(state => getConversationAdmins(state, conferenceTarget)).pipe(take(1));
            combineLatest([owner$, admin$]).pipe(take(1)).subscribe(results => {
              let admins = results[1] || [];
              admins.push(results[0]);
              this.conversationAdmins = admins;
              // this.logger.info("[onUserJoined3 getConversationAdmins] ", conferenceTarget, this.activeTarget , admins);
              if (!this.configService.isAnonymous && (myLocalRole === "moderator")) {
              this.logger.info("[onUserJoined4] ", conferenceTarget, this.getDisplayName(participantId), admins);
              if (admins.indexOf(this.getDisplayName(participantId)) > -1) {
                this.room.grantOwner(participantId);
              }
            }
          });
      }
    });
    setTimeout(() => {
      this.enableLocalTracksIOS();
    }, 300);


    this.logger.info("[JitsiService][onUserJoined3]", participantId, participant, this.getDisplayName(participant), this.isModerator(participantId));
  }

  private detectReverseGhost(participant: any ) {
    this.logger.info("[JitsiService][detectReverseGhost] participantId: ", participant._id);

    const conferenceParticipants = this.room.getParticipants();
    this.logger.info("[JitsiService][detectReverseGhost] conferenceParticipants: ", conferenceParticipants);
    if (conferenceParticipants.length > 0) {
      const maybeGhosts = conferenceParticipants.filter(conferenceParticipant => conferenceParticipant._displayName === participant._displayName);
      this.logger.info("[JitsiService][detectReverseGhost] maybeGhosts: ", maybeGhosts);
      if (maybeGhosts.length > 1) {
        maybeGhosts.forEach(mg => {
          if ((mg._connectionStatus === "interrupted") || (mg._connectionStatus === "restoring")) {
            setTimeout(() => {
              if ((this.getConnectionStatus(mg._id) === "interrupted") || (mg._connectionStatus === "restoring")) {
                console.warn("[JitsiService][detectReverseGhost] removing ghost: ", mg);
                this.onUserLeft(mg._id, null, true);
              }
            }, 4000);
          }
        });
      }

    }
  }

  private detectGhostParticipant(newParticipant: any): boolean {
    const newParticipantName = newParticipant._displayName;
    const newParticipantConnectionStatus = newParticipant._connectionStatus;
    this.logger.info("[JitsiService][detectGhostParticipant]", newParticipantName, newParticipantConnectionStatus, {...newParticipant}, this.userJID?.bare);

    // 1. ignore own user on own side
    if (newParticipantName === this.userJID?.bare) {
      if (newParticipantConnectionStatus === "interrupted") {
        console.warn("[JitsiService][detectGhostParticipant] ignore own user on own side");
        return true;
      } else {
        // wait 5 sec until the connection becoomes interrupted
        setTimeout(() => {
          this.logger.info("[JitsiService][detectGhostParticipant]2",  newParticipant._connectionStatus, {...newParticipant}, newParticipant);
          if (newParticipant._connectionStatus === "interrupted") {
            // remove ghost user
            console.warn("[JitsiService][detectGhostParticipant] removing ghost user[1]", newParticipant);
            this.onUserLeft(newParticipant._id);
          }
        }, 5000);

        return false;
      }
    }

    // 1. ignore own user on other side
    const existingSameParticipant: any = Object.values(this.participantsList).filter((p: any) => p.name === newParticipantName)[0];
    if (existingSameParticipant) {
      const existingSameParticipantConnectionStatus = this.getConnectionStatus(existingSameParticipant.id);
      this.logger.info("[JitsiService][detectGhostParticipant] existingSameParticipant", existingSameParticipant, existingSameParticipantConnectionStatus, this.participantsList);
      if (newParticipantConnectionStatus === "interrupted" && existingSameParticipantConnectionStatus === "active") {
        console.warn("[JitsiService][detectGhostParticipant] ignore own user on other side");
        return true;
      }
      if (newParticipantConnectionStatus === "active") {
        // remove ghost user
        console.warn("[JitsiService][detectGhostParticipant] removing ghost user[2]", existingSameParticipant);
        setTimeout(() => {
          this.onUserLeft(existingSameParticipant.id, null, true);
        }, 3000);
        return false;
      }
    }

    return false;
  }

  sendRolesData() {
    this.store.select(getActiveConference).pipe(take(1)).subscribe(conferenceTarget => {
      this.logger.info("[sendRolesData]");
      if (!!conferenceTarget && this.isModeratorOrOwner(conferenceTarget)) {
        let owner$ = this.store.select(state => getConversationOwner(state, conferenceTarget)).pipe(take(1));
        let admin$ = this.store.select(state => getConversationAdmins(state, conferenceTarget)).pipe(take(1));
        let audience$ = this.store.select(state => getConversationAudiences(state, conferenceTarget)).pipe(take(1));
            combineLatest([owner$, admin$, audience$]).pipe(take(1)).subscribe(results => {
            this.logger.info("[sendRolesData]getLatestRoles", conferenceTarget, results);
            let owner = results[0];
            let admins = results[1];
            let audiences = results[2];
            if (!this.configService.isAnonymous) {
              this.sendRoles(conferenceTarget, admins, owner, audiences);
            }
          });
      }
    });
  }

  getConversationOwner(target) {
    return this.store.select(state => getConversationOwner(state, target));
  }

  getConversationAdmins(target) {
    return this.store.select(state => getConversationAdmins(state, target));
  }

  isOwner(target, bare?: string) {
    // let isModerator = this.room && this.room.isModerator(this.myUserId());
    let isOwner = false;
    this.getConversationOwner(target).pipe(take(1)).subscribe(owner => {
      if (!bare) {
        isOwner =  owner && this.userJID && owner === this.userJID?.bare;
      } else {
        isOwner =  owner && owner === bare;
      }
    });
    return isOwner;
  }

  private onKicked() {
    this.logger.info("[JitsiService][onKicked]");
    this.broadcaster.broadcast("onCallHangup");
  }

  private onUserLeft(userId, leftParticipant?, keepParticipant?: boolean) {
    let isRecorderLeft = false;
    if (!!leftParticipant && Object.keys(leftParticipant).includes("_features")) {
      if (leftParticipant._features.has("http://jitsi.org/protocol/jibri")) {
        isRecorderLeft = true;
      }
    }
    this.logger.info("[JitsiService][onUserLeft0]", userId, keepParticipant, isRecorderLeft, this.screenSharePresenterParticipant$.value);

    delete this.participantsStats[userId];
    delete this.participantsConnStatus[userId];
    this.broadcaster.broadcast("onUserLeft", userId);
    this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
      if (type === "screen-receive") {
        this.store.select(getScreenSharingRequest).pipe(take(1)).subscribe(v => {
          this.logger.info("[JitsiService][onUserLeft] screen-receive ", userId, v);
          if (userId === v.presenterId) {
            this.logger.info("[JitsiService][onUserLeft] screen-receive presenter has left - ending call");
            this.broadcaster.broadcast(BroadcastKeys.CALL_LAST_REMOTE_USER_LEFT);
          }
        });
      }
    });

    if (userId === this.screenSharePresenterParticipant$.value) {
      // this.logger.info("[JitsiService][onUserLeft0] fakeParticipantInactive", userId, this.screenSharePresenterParticipant$.value);
      this.broadcaster.broadcast("fakeParticipantInactive");
      this.store.dispatch(new ConferenceRemoveParticipant("fake123"));
      this.store.dispatch(new JitsiConferenceRemoveParticipant("fake123"));
      this.setFullScreenParticipantId(null);

    }
    if (!userId.startsWith("recorder")) {
      const participant = Object.assign({}, this.participantsList[userId]);
      if (participant) {
        this.logger.info("[JitsiService][onUserLeft]", participant._displayName, userId);
        const leftList = this.leftList.value;
        const totalLeftList = this.totalLeftList.value;
        let displayName = participant.name;
        if (totalLeftList.indexOf(displayName) === -1) {
          this.totalLeftList.next([...leftList, displayName]);
        }
        if (!keepParticipant) {
          if (leftList.indexOf(displayName) === -1) {
            this.leftList.next([...leftList, displayName]);
          }
          this.joinedList.next(this.joinedList.value.filter(v => v !== displayName));
          this.broadcaster.broadcast("onParticipantLeft", displayName);
        }

      } else {
        this.logger.info("[JitsiService][onUserLeft]", userId);
      }

      this.store.dispatch(new ConferenceRemoveParticipant(userId));
      this.store.dispatch(new JitsiConferenceRemoveParticipant(userId));
      this.store.select(getSpeakingParticipant).pipe(take(1)).subscribe(res => {
        if (res === userId) {
          this.pinnedParticipant$.next(null);
          this.store.dispatch(new SetSpeakingParticipant(null));
        }
      });
    } else {
      this.logger.info("[JitsiService][onUserLeft]", userId);
    }
    this.logger.info("[JitsiService][onUserLeft]", userId, this.joinedList.value, this.leftList.value);
    this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
      this.logger.info("[JitsiService][onUserLeft] getConferenceParticipants", participants);
      const participantsWithoutFake = participants.filter(p => p.id !== "fake123");

      if (participantsWithoutFake.length < 2 && !isRecorderLeft) {
        this.logger.info("[JitsiService][onUserLeft] getConferenceParticipants CALL_LAST_REMOTE_USER_LEFT", this.displayName);
        this.broadcaster.broadcast(BroadcastKeys.CALL_LAST_REMOTE_USER_LEFT);
      }
    });
    // select new user as full screen if current left
    this.store.select(getFullScreenParticipantId).pipe(take(1)).subscribe(fullScreenParticipantId => {
      if (fullScreenParticipantId === userId) {
        const participants = this.getConferenceParticipants();
        const filteredParticipants = participants.filter(v => v.id !== userId && v.id !== this.myUserId());
        this.setFullScreenParticipantId(filteredParticipants.length > 0 ? filteredParticipants[0].id : null);
      }
    });

    // unselect user if selected
    this.store.select(getSelectedParticipantId).pipe(take(1)).subscribe(selectedParticipantId => {
      if (selectedParticipantId === userId) {
        this.selectParticipant(null);
      }
    });

    const currentRemoteTracks = this._remoteTracks$.value;
    this.logger.info("[JitsiService][onUserLeft] currentRemoteTracks: ", currentRemoteTracks);
    if (!!currentRemoteTracks.fake123 && !!currentRemoteTracks.fake123[0]
      && !!currentRemoteTracks.fake123[0].track && !!currentRemoteTracks.fake123[0].track.readyState
      && currentRemoteTracks.fake123[0].track.readyState !== "live") {
        this.logger.info("[JitsiService][onUserLeft] screenshareEnded");
        this.broadcaster.broadcast("fakeParticipantInactive");
    }
    if (currentRemoteTracks[userId]) {
      let updatedCurrentRemoteTracks = [];
      for (let id of Object.keys(currentRemoteTracks)) {
        if (id !== userId) {
          updatedCurrentRemoteTracks[id] = currentRemoteTracks[id];
        }
      }
      this.logger.info("[JitsiService][onUserLeft] updatedCurrentRemoteTracks: ", updatedCurrentRemoteTracks);
      // if no remote participants left - end the cacll
      if (Object.keys(updatedCurrentRemoteTracks).length === 0) {
        if (this.recordSessionId !== "") {
          // this.logger.info("[JitsiService][onUserLeft] stopRecordingConference: ", this.recordSessionId);
          this.stopRecordingConference(this.recordSessionId);
        }
        this.store.select(getConferenceType).pipe(take(1)).subscribe(conferenceType => {
          if (!!conferenceType && (conferenceType.startsWith("screen"))) {
            this.logger.info("[JitsiService][noRemoteTracks] skip CALL_LAST_REMOTE_USER_LEFT");
          } else {
            this.logger.info("[JitsiService][onUserLeft] CALL_LAST_REMOTE_USER_LEFT");

            this.broadcaster.broadcast(BroadcastKeys.CALL_LAST_REMOTE_USER_LEFT);
          }
        });
      } else {
        // this case will happen in case of screen share only session where for moderators remote tracks don't exist
        this.store.select(getJitsiConferenceParticipants).pipe(take(1)).subscribe(res => {
          if (res.length === 1 && this.isJoined) {
            // ME is the only remaining participant
            this.logger.info("screenShareLastRemoteUserLeft");
            this.broadcaster.broadcast("screenShareLastRemoteUserLeft");
          }
        });
      }
      this.setRemoteTracks(updatedCurrentRemoteTracks);
      this.broadcaster.broadcast(BroadcastKeys.CALL_ON_USER_LEFT, userId);
    }
  }

  private onUserRoleChanged(userId, role) {
    this.logger.info("[JitsiService][onUserRoleChanged]", userId, role);
    this.broadcaster.broadcast("onUserRoleChanged", { id: userId, role: role });
  }

  private onLastNChanged(leavingEndpointIds, enteringEndpointIds) {
    this.logger.info("[JitsiService][onLastNChanged] leaving ", leavingEndpointIds);
    this.logger.info("[JitsiService][onLastNChanged] entering ", enteringEndpointIds);
  }

  public isLocalId(id): boolean {
    return id === this.myUserId();
  }

  private onConnectionSuccess(res) {
    this.logger.info("[JitsiService][Connection][onConnectionSuccess]", res, this.connection);
    this.onConnectionSuccessCallback(res);
  }

  private onConnectionSuccessCallback(res) {
    this.logger.info("[JitsiService][Connection][onConnectionSuccessCallback]", res);

    if (!this.connection || this.connection === null) {
      this.connect();
      return;
    }

    this.store.select(getJitsiRoom).pipe(filter(v => !!v), take(1)).subscribe(option => {
      const jitsiRoomId = option.value;
      this.createAndSetupJitsiRoom(jitsiRoomId);
    });
  }

  private createAndSetupJitsiRoom(jitsiRoomId: string) {
    this.logger.info("[JitsiService][createAndSetupJitsiRoom]", jitsiRoomId, this.activeTarget, this.externalData);

    this.roomName = jitsiRoomId;
    if (!this.configService.isAnonymous) {
      this.displayName = this.userJID?.bare;
    } else {
      this.store.select(getParticipantEmail).pipe(take(1)).subscribe(email => {
        this.displayName = email;
        this.participantEmail = email;
      });
    }
    if (this.externalData && this.externalData.info) {
      this.displayName = this.externalData.info.name;
      if (this.externalData.info.password) {
        this.activeTarget = jitsiRoomId;
        this.conferencePassword[this.activeTarget] = this.externalData.info.password;
      }
    }
    if (this.externalData && this.externalData.selectedBackground) {
      this.store.dispatch(new BackgroundEffectEnabled(this.externalData.selectedBackground.enabled));
    this.store.dispatch(new SetVirtualBackground(this.externalData.selectedBackground));
    }
    this.room = this.connection.initJitsiConference(jitsiRoomId, this.getJitsiActiveConfig());
    this.room.setDisplayName(this.displayName);
    let canJoinWithoutPassword = false;
    this.store.select(state => getConversationById(state, this.activeTarget)).pipe(take(1)).subscribe(conv => {
      if (conv && conv.conference_start && conv.conference_start * 1000 + 5 * 60 * 1000 > new Date().getTime()) {
        canJoinWithoutPassword = true;
      }
    });
    if ((canJoinWithoutPassword || this.isModeratorOrOwner()) && this.conferencePassword[this.activeTarget]) {
      this.room.join(this.conferencePassword[this.activeTarget]);
    } else {
      this.room.join();
    }

    this.room.on(JITSI_MEET.JitsiConferenceEvents.MEMBERS_ONLY_CHANGED, (enabled) => {
      this.logger.info("[JitsiConferenceEvents][MEMBERS_ONLY_CHANGED]", enabled);
      this.store.dispatch(new UpdateLobbyState(enabled));
    });

    this.room.on(JITSI_MEET.JitsiConferenceEvents.LOBBY_USER_JOINED, (id, name) => {
      this.logger.info("[JitsiConferenceEvents][LOBBY_USER_JOINED]", id, name);
      this.store.dispatch(new ParticipantIsKnockingOrUpdated({id, name}));
    });

    this.room.on(JITSI_MEET.JitsiConferenceEvents.SUBJECT_CHANGED, (data) => {
      this.logger.info("[JitsiConferenceEvents][SUBJECT_CHANGED]", data);
      if (!!data) {
        this.roomSubject$.next(data);
      } else {
        this.translate.get("VNCTALK_VIDEO_CONFERENCE").pipe(take(1)).subscribe(text => {
          this.roomSubject$.next(text);
        });
      }
    });

    this.room.on(JITSI_MEET.JitsiConferenceEvents.LOBBY_USER_UPDATED, (id, participant) => {
      this.logger.info("[JitsiConferenceEvents][LOBBY_USER_UPDATED]", id, participant);
      this.store.dispatch(new ParticipantIsKnockingOrUpdated({...participant, id}));
    });

    this.room.on(JITSI_MEET.JitsiConferenceEvents.LOBBY_USER_LEFT, (id) => {
      this.logger.info("[JitsiConferenceEvents][LOBBY_USER_LEFT]", id);
      this.store.dispatch(new KnockingParticipantLeft(id));
    });

    this.room.on(JITSI_MEET.JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, this.receiverEndpointMessageListener.bind(this));

    this.room.on(JITSI_MEET.JitsiConferenceEvents.PARTICIPANT_PROPERTY_CHANGED, this.handleParticipantPropertyChanged.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.TRACK_ADDED, this.handleTrackAdded.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.TRACK_REMOVED, this.handleTrackRemoved.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.CONFERENCE_JOINED, this.onConferenceJoined.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.CONFERENCE_LEFT, this.onConferenceLeft.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.USER_JOINED, this.onUserJoined.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.KICKED, this.onKicked.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.USER_LEFT, this.onUserLeft.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.USER_ROLE_CHANGED, this.onUserRoleChanged.bind(this));

    this.room.on(JITSI_MEET.JitsiConferenceEvents.LAST_N_ENDPOINTS_CHANGED, this.onLastNChanged.bind(this));

    this.room.on(JITSI_MEET.JitsiConferenceEvents.CONNECTION_ESTABLISHED, this.onConnectionEstablished.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.CONNECTION_INTERRUPTED, this.onConnectionInterrupted.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.CONNECTION_RESTORED, this.onConnectionRestored.bind(this));
    this.room.on(JITSI_MEET.JitsiConferenceEvents.MESSAGE_RECEIVED, this.onMessageReceived.bind(this));

    this.room.on(JITSI_MEET.JitsiConferenceEvents.PARTICIPANT_CONN_STATUS_CHANGED, this.onParticipantConnStatuschanged.bind(this));

    //  JitsiTrack was muted or unmuted.
    this.room.on(JITSI_MEET.JitsiConferenceEvents.TRACK_MUTE_CHANGED, track => {
      this.logger.info(`[JitsiService][TRACK_MUTE_CHANGED] ${track.getType()}. muted: ${track.isMuted()}. participans: ${this.getDisplayName(track.getParticipantId())}`, track, track.videoType);

      if (track.getParticipantId() !== this.myUserId()) {
        if ((track.type === "audio") || ((track.type === "video") && (track.videoType === "camera"))) {
          // this.logger.info(`[JitsiService][TRACK_MUTE_CHANGEDSetMediaStatus] ${track.getType()}. muted: ${track.isMuted()}. participans: ${this.getDisplayName(track.getParticipantId())}`, track.videoType, track.muted, track);
          this.setMediaStatus(track.getParticipantId(), track.getType(), track.isMuted() ? ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted : ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
        }
        if ((track.type === "video") && (track.videoType === "desktop")) {
          this.setMediaStatus("fake123", track.getType(), track.isMuted() ? ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted : ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
          setTimeout(() => {
            this.logger.info(`[JitsiService][TRACK_MUTE_CHANGED_onVideoSourceChange] ${track.getType()}. muted: ${track.isMuted()}. participant: ${this.getDisplayName(track.getParticipantId())}`, track);
            this.broadcaster.broadcast("onVideoSourceChange", track.getParticipantId());
          }, 300);
          setTimeout(() => {
            this.logger.info(`[JitsiService][TRACK_MUTE_CHANGED_onVideoSourceChange2] ${track.getType()}. muted: ${track.isMuted()}. participant: ${this.getDisplayName(track.getParticipantId())}`, track);
            this.broadcaster.broadcast("onVideoSourceChange", track.getParticipantId());
          }, 2000);

        }
      }
      this.trackMuteUnmute(track);
    });
    this.room.on(
      JITSI_MEET.JitsiConferenceEvents.DISPLAY_NAME_CHANGED,
      (userID, displayName) => this.logger.info(`[JitsiService][DISPLAY_NAME_CHANGED] ${userID} - ${displayName}`));

    this.room.on(
      JITSI_MEET.JitsiConferenceEvents.DOMINANT_SPEAKER_CHANGED, this.onDominantSpeakerChanged.bind(this));

    this.room.on(
      JITSI_MEET.JitsiConferenceEvents.CONFERENCE_FAILED, this.onConferenceFailed.bind(this));

    this.room.on(
      JITSI_MEET.JitsiConferenceEvents.TRACK_AUDIO_LEVEL_CHANGED, this.onTrackAudioLevelChanged.bind(this));

    this.room.addCommandListener(ConstantsUtil.TOGGLE_MUTE_EVERYONE_COMMAND, (res, participantId) => {
      this.logger.info("TOGGLE_MUTE_EVERYONE_COMMAND", res, participantId);
      const data = JSON.parse(res.value);
      this.store.dispatch(new ToggleMuteEveryone(data.isMuted));
      let mediaMuteStatus = this.getMediaMuteStatus(this.myUserId());
      if (participantId !== this.myUserId()) {
        this.logger.info("TOGGLE_MUTE_EVERYONE_COMMAND", data, mediaMuteStatus);
        if (data.isMuted) {
          // this.dialog.open(MutedAudioPopupComponent, {
          //   disableClose: true
          // });
          this.muteAudio(this.myUserId());
          this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
        } else {
          if (mediaMuteStatus.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator) {
            this.broadcaster.broadcast("onUnmuteByModerator", { type: "audio" });
            this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
          }
        }
      }
    });

    this.room.addCommandListener(ConstantsUtil.USER_INFO, (res, participantId) => {
      this.logger.info("USER_INFO", res, participantId);
      const data = JSON.parse(res.value);
      const participantInfo = this.participantInfo.value;
      participantInfo[data.participantId] = data;
      this.participantInfo.next(participantInfo);
      this.broadcaster.broadcast("updateIncallParticipantsList");

      this.logger.info("[USER_INFO] participantInfo", participantInfo);
    });

    this.room.addCommandListener(ConstantsUtil.TOGGLE_MUTE_CAMERA_COMMAND, (res, participantId) => {
      this.logger.info("TOGGLE_MUTE_CAMERA_COMMAND", res, participantId);
      const data = JSON.parse(res.value);
      if (participantId !== this.myUserId()) {
        if (data.isMuted) {
          this.turnOffVideo();
          this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
        } else {
          this.broadcaster.broadcast("onUnmuteByModerator", { type: "video" });
          this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
        }
      }
    });

    this.room.addCommandListener(ConstantsUtil.SCREEN_SHARING_COMMAND, (res, participantId) => {
      this.logger.info("SCREEN_SHARING_COMMAND", res, participantId);
      const data = JSON.parse(res.value);
      this.realScreenShareParticipantId$.next(participantId);
      if (participantId !== this.myUserId()) {
        this.setScreenSharingRequest(data.presenterJid, participantId);
      }
      if (this.configService.isWhiteboardAvailable()) {
        this.stopWhiteboard();
      }
    });

    this.room.addCommandListener(ConstantsUtil.SET_PASSWORD_COMMAND, (res, participantId) => {
      this.logger.info("SET_PASSWORD_COMMAND", res, participantId);
      if (this.room.isModerator()) {
        this.setPassword(res.value);
        this.logger.info("SET_PASSWORD_COMMAND SET", res.value);
      }
    });

    this.room.addCommandListener("setKnockingParticipantApproval", (res, participantId) => {
      this.logger.info("setKnockingParticipantApproval", res, participantId);
      const data = JSON.parse(res.value);
      if (this.room.isModerator()) {
        this.setKnockingParticipantApproval(data.participantId, data.approved);
      }
    });

    this.room.addCommandListener("enableLobby", () => {
      if (this.room.isModerator()) {
        this.enableLobby();
      }
    });

    this.room.addCommandListener("disableLobby", () => {
      if (this.room.isModerator()) {
        this.disableLobby();
      }
    });

    this.room.addCommandListener("startRecording", (data, participantId) => {
      this.logger.info("[startRecording] command", data, participantId);
      if (this.room.isModerator()) {
        const options = JSON.parse(data.value);
        this.room.startRecording(options);
        this.logger.info("[startRecording] from other side");
      }
    });

    this.room.addCommandListener("stopRecording", (data, participantId) => {
      this.logger.info("[stopRecording] command", data, participantId);
      if (!!this.room && this.room.isModerator()) {
        this.room.stopRecording(data.value);
        this.logger.info("[stopRecording] from other side");
      }
    });

    this.room.addCommandListener(ConstantsUtil.SCREEN_SHARING_REQUEST, (res, participantId) => {
      this.logger.info("SCREEN_SHARING_REQUEST", res, participantId);
      const data = JSON.parse(res.value);
      if (data.toId === this.myUserId() && data.fromJid !== this.myUserId()) {
        // show screen sharing request to presenter
        this.broadcaster.broadcast("shareScreenRequest", {participantId, fromJid: data.fromJid});
      }
      setTimeout(() => {
        this.store.dispatch(new SetScreenSharingRequestStatus(false));
        this.store.dispatch(new SetScreenSharingStarted());
      }, 30000);
    });

    this.room.addCommandListener(ConstantsUtil.SCREEN_SHARING_REQUEST_ALLOW, async (res, participantId) => {
      this.logger.info("SCREEN_SHARING_REQUEST_ALLOW", res, participantId);
      const data = JSON.parse(res.value);
      if (data.toId === this.myUserId()) {
        if (!environment.isCordova) {
          window.focus();
        }

        if (!environment.isElectron && !CommonUtil.isOnFirefox()) {
          this.startSharing();
        } else {
          this.broadcaster.broadcast("setupScreenSharingPicker");
          // SCREEN SHARING FLOW ELECTRON
          let style: any = {
            width: "440px",
            minHeight: "250px",
          };
          const { ConferenceDialogComponent } = await import(
            "../shared/components/dialogs/conference-confirmation/conference-confirmation.component");
          this.dialog.open(ConferenceDialogComponent, Object.assign({
              backdropClass: "vnctalk-form-backdrop",
              panelClass: "vnctalk-form-panel",
              disableClose: true,
              data: {action: "start_screenshare"},
              autoFocus: true
             }, style)).afterClosed().pipe(take(1)).subscribe(data => {
              if (data && data.sharescreen) {
                this.startSharing();
              } else {
                this.store.dispatch(new SetScreenSharingRequestStatus(false));
              }
             });
        }

      }
    });

    this.room.addCommandListener(ConstantsUtil.SCREEN_SHARING_REQUEST_DENY, (res, participantId) => {
      this.logger.info("SCREEN_SHARING_REQUEST_ALLOW", res, participantId);
      const data = JSON.parse(res.value);
      if (data.toId === this.myUserId()) {
        this.store.dispatch(new SetScreenSharingRequestStatus(false));

      }
    });

    this.room.addCommandListener(ConstantsUtil.COMMANDS.setShareScreenType, (res, participantId) => {
      this.logger.info("SetConferenceType", res, participantId);
      this.store.select(getConferenceType).pipe(take(1)).subscribe(oldConferenceType => {
        this.logger.info("[JitsiService][setShareScreenType] old conferenceType: ", oldConferenceType);
        if (oldConferenceType !== "screen") {
          this.store.dispatch(new SetConferenceType("screen-receive"));
        } else {
          this.store.dispatch(new SetConferenceType("screen"));
        }
        if (res.value !== this.myUserId()) {
          this.turnOffVideo();
        }
        this.logger.info("[JitsiService][setShareScreenType] calling muteAudio");
        this.muteAudio();
      });
    });

    this.room.addCommandListener(ConstantsUtil.COMMANDS.removeShareScreenType, (res, participantId) => {
      this.logger.info("SetConferenceType", res, participantId);
      this.store.dispatch(new SetConferenceType("video"));
    });

    this.room.addCommandListener(ConstantsUtil.COMMANDS.toggleShareScreen, (data, participantId) => {
        this.logger.info("[JitsiService][toggleShareScreen]", data, participantId);
        if (data.value === ConstantsUtil.SCREEN_SHARE_STARTED_COMMAND_DATA) {
          // do something on screen share start by other user, if required
          this.someoneshouldStartScreenshare = true;
          this.sendFollowMeCommand(true);
          this.screenSharePresenterParticipant$.next(participantId);
          this.broadcaster.broadcast("onScreenStarted", participantId);
          if (this.configService.isWhiteboardAvailable()) {
            this.stopWhiteboard();
          }
          this.store.select(getScreenSharingRequest).pipe(take(1)).subscribe(v => {
            if (!v.presenterId && this.participantsList[participantId]) {
              const presenterJid = this.participantsList[participantId].name;
              this.store.dispatch(new SetScreenSharingRequest({isStartingScreenshare: false, presenterJid, presenterId: participantId}));
            }
            this.store.dispatch(new SetScreenSharingStarted());
            this.logger.info("SetScreenSharingStarted 1");

          });
        } else if (data.value === ConstantsUtil.SCREEN_SHARE_STOPPED_COMMAND_DATA) {
          this.screenSharePresenterParticipant$.next("");
          this.handleScreenShareStoppedParticipant(participantId);
        }
        this.resetTileMode();
    });

    this.room.addCommandListener(
      "invitedParticipants",
      (data, participantId) => {
        this.logger.info("[JitsiService][invitedParticipants]", data, participantId);
        this.broadcaster.broadcast("setInvitedParticipants", JSON.parse(data.value));
    });

    this.room.addCommandListener(
      ConstantsUtil.SET_INITIATOR_COMMAND,
      (data, participantId) => {
        this.logger.info("[JitsiService][invitedParticipants] SET_INITIATOR_COMMAND", data, participantId);
        const params = JSON.parse(data.value);
        localStorage.setItem(`callInitiator_${params.target}`, params.jid);
    });

    this.room.addCommandListener(ConstantsUtil.COMMANDS.toggleShareScreen, (data, participantId) => {
        this.logger.info("[JitsiService][toggleShareScreen]", data, participantId);
        if (data.value === ConstantsUtil.SCREEN_SHARE_STARTED_COMMAND_DATA) {
          // do something on screen share start by other user, if required
          this.someoneStartedScreenshare = true;
          this.sendFollowMeCommand(true);
          this.broadcaster.broadcast("onScreenStarted", participantId);
          if (this.configService.isWhiteboardAvailable()) {
            this.stopWhiteboard();
          }
          this.store.select(getScreenSharingRequest).pipe(take(1)).subscribe(v => {
            if (!v.presenterId && this.participantsList[participantId]) {
              const presenterJid = this.participantsList[participantId].name;
              this.store.dispatch(new SetScreenSharingRequest({isStartingScreenshare: false, presenterJid, presenterId: participantId}));
            }
            this.store.dispatch(new SetScreenSharingStarted());
            this.logger.info("SetScreenSharingStarted 2");
          });
        } else if (data.value === ConstantsUtil.SCREEN_SHARE_STOPPED_COMMAND_DATA) {
          this.broadcaster.broadcast("onScreenStopped", participantId);

          if (participantId === this.myUserId()) {
            this.setLocalTracks(this._localTracks$.value.filter(t => t.videoType !== "desktop" && t.videoType !== "screen"));
          } else {
            const currentTracks = this._remoteTracks$.value || {};
            let filteredTracks = [];
            if (currentTracks[participantId]) {
              filteredTracks = currentTracks[participantId].filter(t => t.videoType !== "desktop" && t.videoType !== "screen");
            }
            let remoteTracksWithoutFake = this._remoteTracks$.value;
            remoteTracksWithoutFake["fake123"] = [];

            this.logger.info("setRemoteTracksWithoutScreenshare2 ", this._remoteTracks$.value, remoteTracksWithoutFake);

            this.setRemoteTracks(remoteTracksWithoutFake);
            this.logger.info("[SCREEN_SHARE_STOPPED_COMMAND_DATA]", participantId, filteredTracks);
          }

          this.store.select(getScreenSharingRequest).pipe(take(1)).subscribe(v => {
            if (participantId === v.presenterId) {
              this.store.dispatch(new ResetScreenSharingData());
            }
          });
          // set video to muted, cause after screen share off we switch to audio only
          // this.setMediaStatus(participantId, "video", ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
          // this.setMediaStatus("fake123", "video", ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
        }
        this.resetTileMode();
    });

    this.room.on(
      JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
      (stats) => {
        const localUserId = this.myUserId();
        this.logger.info("[JitsiService][LOCAL_STATS_UPDATED]", stats, localUserId);
        this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
          this.logger.info("[JitsiService] handleZeroUpload this.localZracks: ", tracks);
          this.store.select(getConferenceType).pipe(take(1)).subscribe(conferenceType => {
            if (!!conferenceType && (!conferenceType.startsWith("screen"))) {
              if (tracks.length === 0) {
                this.logger.info("[JitsiService] handleZeroUpload RECONNECTING_IN_CALL1 no local zracks - trying to recover");
                this.notificationService.openSnackBarWithTranslation("RECONNECTING_IN_CALL", {}, 5000);
                if (this.enabledVideo) {
                  this.startVideo();
                } else {
                  this.startAudio();
                }
              } else {
                // if (!CommonUtil.isOnIOS()) {
                  // we have local tracks, but upload rate is 0 => something is wrong
                  if ((stats.bitrate?.upload === 0) || ((stats.bitrate?.video.upload === 0) && (tracks.length > 1) && (tracks[1]?.videoType !== "desktop") )) {
                    this.logger.info("[JitsiService] localupload 0", "enabledVideo:", this.enabledVideo, stats, tracks, stats.bitrate?.upload);
                    if (this.room && this.enabledVideo) {
                      this.logger.info("[JitsiService] skip localupload debug");
                      // to check if this really should be enabled
                     //  this.handleZeroUpload(0);
                    }
                  } else if ((stats.bitrate?.audio.download === 0) && (stats.bitrate?.video.download === 0)) {
                    let allMuted = true;
                    this.logger.info("[JitsiService] local-download 0 ", this.participantsStats);
                    if (this.room) {
                      const roomParticipants = this.room.getParticipants();
                      roomParticipants.forEach(p => {
                        this.logger.error("[JitsiService] local-download 0 participant", p, p._tracks[0]?.muted);
                        if (!p._tracks[0]?.muted) {
                          allMuted = false;
                        }
                      });

                      if (!allMuted) {
                        this.handleZeroUpload(0);
                      }
                    }
                  } else {
                    this.handleZeroUpload(stats.bitrate?.upload);
                  }
                  // }
                }
            } else {
              if (conferenceType === "screen-receive") {
                this.logger.info("[JitsiService] screen-receive stats: ", stats);
                if ((stats.bitrate?.audio.download === 0) && (stats.bitrate?.video.download === 0)) {
                  this.logger.info("[JitsiService] screen-receive local-download 0");
                }
              }
            }
          });
        });


        const allUserFramerates = stats.framerate || {};
        const allUserResolutions = stats.resolution || {};

        // FIXME resolution and framerate are maps keyed off of user ids with
        // stat values. Receivers of stats expect resolution and framerate to
        // be primitives, not maps, so here we override the 'lib-jitsi-meet'
        // stats objects.
        const modifiedLocalStats = Object.assign({}, stats, {
          framerate: allUserFramerates[localUserId],
          resolution: allUserResolutions[localUserId]
        });

        this._emitStatsUpdate(localUserId, modifiedLocalStats);

        // Get all the unique user ids from the framerate and resolution stats
        // and update remote user stats as needed.
        const framerateUserIds = Object.keys(allUserFramerates);
        const resolutionUserIds = Object.keys(allUserResolutions);
        [...new Set([...framerateUserIds, ...resolutionUserIds])]
            .filter(id => id !== localUserId)
            .forEach(id => {
                const remoteUserStats: any = {};

                const framerate = allUserFramerates[id];

                if (framerate) {
                    remoteUserStats.framerate = framerate;
                }

                const resolution = allUserResolutions[id];

                if (resolution) {
                    remoteUserStats.resolution = resolution;
                }

                this._emitStatsUpdate(id, remoteUserStats);
            });
      }
    );

    this.room.on(
      JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
      (id, stats) => {
        this.logger.info("[JitsiService][REMOTE_STATS_UPDATED]", stats, id);
        this._emitStatsUpdate(id, stats);
      }
    );

    this.room.addCommandListener(
      ConstantsUtil.COMMANDS.toggleAudio,
      (data, participantId) => {
        this.logger.info(ConstantsUtil.COMMANDS.toggleAudio, data, participantId);
        data = JSON.parse(data.value);
        if (data.participantId !== this.myUserId()) {
          return;
        }
        let participant = this.getConferenceParticipants().find(p => p.id === this.myUserId());
        this.logger.info("[ConstantsUtil.COMMANDS.toggleAudio]", participant, data);
        if (!participant) {
          if (data.shouldMute) {
            this.muteAudio(data.participantId);
          }
          return;
        }
        if (!data.shouldMute || !participant.audioStatus || participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted || participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator) {
          if (!data.shouldMute) {
            this.broadcaster.broadcast("onUnmuteByModerator", { type: "audio" });
            this.logger.info("[ConstantsUtil.COMMANDS.toggleAudio] unmuteAudio");
            this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
          }
        } else {
          this.logger.info("[ConstantsUtil.COMMANDS.toggleAudio] muteAudio");
          this.setMediaStatus(data.participantId, ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
          this.muteAudio(data.participantId);
        }
    });

    this.room.addCommandListener(
    "status",
    (data, participantId) => {
      const jsonData = JSON.parse(data.value);
      this.setRaiseHandStatus(participantId, jsonData.on);
    });
    this.room.addCommandListener(
      "broadcast",
      (data, participantId) => {
        const jsonData = JSON.parse(data.value);
        this.handleBroadcastMessage(participantId, jsonData.email, jsonData.content);
    });
    this.room.addCommandListener(
      ConstantsUtil.COMMANDS.wakeup,
      (data) => {
        const jsonData = JSON.parse(data.value);
        this.handleWakeupMessage(jsonData);
    });
    this.room.addCommandListener(
      ConstantsUtil.COMMANDS.rejoin,
      (data) => {
        const jsonData = JSON.parse(data.value);
        this.handleReJoinedMessage(jsonData);
    });
    this.room.addCommandListener(
      "information",
      (data, participantId) => {
        const jsonData = JSON.parse(data.value);
        const information = this.participantsInformation$.value.filter(v => v.id !== participantId);
        this.participantsInformation$.next([...information, jsonData]);
    });
    this.room.addCommandListener(
      "recordingInfo",
      (data) => {
        this.logger.info("[recordingInfo]", data);
        const jsonData = JSON.parse(data.value);
        this.startRecordingTime = jsonData.startRecordingTime;
        this.startRecordingTimer();
    });
    this.room.addCommandListener(
      "kickParticipant",
      (data) => {
        if (this.isRoomModerator(this.myUserId())) {
          const jsonData = JSON.parse(data.value);
          this.kickParticipant(jsonData.participantId);
        }

    });
    this.room.addCommandListener(
      "roles",
      (data, participantId) => {
        let localRole = this.room.getRole();
        this.logger.info("receive roles", data, participantId, localRole);
        const jsonData = JSON.parse(data.value);
        if (participantId !== this.myUserId()) {
          this.logger.info("update roles", data, participantId);
          this.store.dispatch(new SetConversationTarget(jsonData.target));
          this.conferenceTarget = jsonData.target;

          const conv = ConversationUtil.createLocalConversation(jsonData.target, "", "groupchat");

          this.audiences = jsonData.audiences;
          this.groupChatsService.audienceList$.next(jsonData.audiences);
          this.store.dispatch(new ConversationCreate(conv));
          setTimeout(() => {
            this.store.dispatch(new ConversationUpdateAdmins({
              conversationTarget: jsonData.target,
              admins: jsonData.admins
            }));
            this.store.dispatch(new ConversationUpdateOwner({
              conversationTarget: jsonData.target,
              owner: jsonData.owner
            }));
          }, 500);
          let ownerAndAdmins = jsonData.admins;
          ownerAndAdmins.push(jsonData.owner);

          Object.keys(this.participantsList).forEach(pe => {
            // console.log("[receiveRolesData2] ",  this.participantsList[pe], ownerAndAdmins.indexOf(this.participantsList[pe].name));
            if (ownerAndAdmins.indexOf(this.participantsList[pe].name) > -1) {
              if (localRole === "moderator") {
                this.room.grantOwner(this.participantsList[pe].id);
              }
            }
          });

          // console.log("[receiveRolesData] ", ownerAndAdmins, localRole, this.participantsList);
        }


    });
    this.room.addCommandListener(
      ConstantsUtil.COMMANDS.toggleVideo,
      (data, participantId) => {
        data = JSON.parse(data.value);
        this.logger.info("JitsiService][toggleRemoteMedia] VIDEO", data, participantId);
        if (data.participantId !== this.myUserId()) {
          return;
        }

        let participant = this.getConferenceParticipants().find(p => p.id === this.myUserId());
        if (!participant) {
          if (data.shouldMute) {
            this.turnOffVideo(data.participantId);
          }
          return;
        }
        this.logger.info("JitsiService][toggleRemoteMedia] VIDEO current status", participant);
        if (!participant.videoStatus || participant.videoStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted || (participant.videoStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator)) {
          if (!data.shouldMute) {
            this.logger.info("JitsiService][toggleRemoteMedia] onUnmuteByModerator VIDEO current status", participant);
            this.broadcaster.broadcast("onUnmuteByModerator", { type: "video" });
            if (this.room) {
              this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
            }
          }
        } else {
          this.turnOffVideo(data.participantId);
        }
    });

    /**Event listerner for recorder update */
    this.room.on(JitsiMeetJS.events.conference.RECORDER_STATE_CHANGED, this.onRecordingUpdatesReceived.bind(this));
  }


  handleScreenShareStoppedParticipant(participantId) {
    this.broadcaster.broadcast("onScreenStopped", participantId);

    if (participantId === this.myUserId()) {
      this.setLocalTracks(this._localTracks$.value.filter(t => t.videoType !== "desktop" && t.videoType !== "screen"));
    } else {
      const currentTracks = this._remoteTracks$.value || {};
      if (currentTracks[participantId]) {
        const filteredTracks = currentTracks[participantId].filter(t => t.videoType !== "desktop" && t.videoType !== "screen");

        this.logger.info("[jitsi.service this.setRemoteTracks w/o screenshare track");
        // this.setRemoteTracks(tracks);
        this.logger.info("[SCREEN_SHARE_STOPPED_COMMAND_DATA]", participantId, filteredTracks);
      }
    }

    this.store.select(getScreenSharingRequest).pipe(take(1)).subscribe(v => {
      if (participantId === v.presenterId) {
        this.store.dispatch(new ResetScreenSharingData());
      }
    });

    // set video to muted, cause after screen share off we switch to audio only
    // this.setMediaStatus(participantId, "video", ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
  }

  startSharing() {
    // skip - as setting a streamId leads to wrong state re screenshare and
    // cam buttons - especially when cancelling from select source dialog
    // if really required to set this then only do on success - but this
    // was called too  often causing wrong states of buttons displayed

    // this.store.dispatch(new SetStreamId(streamId));

    // do not dispatch this yet for electron - causes wrong
    // state of cam and screenshare buttons  when source picker
    // dialog is canceled
    if (!environment.isElectron) {
      this.store.dispatch(new ConferenceShareScreen());
    }
    this.store.dispatch(new SetScreenSharingRequestStatus(false));
    this.shareScreen();
  }

  handleWakeupMessage(jsonData: any) {
    if (jsonData.participantId === this.myUserId()) {
      this.translate.get("YOU_HAVE_BEEN_CALLED_TO_WAKE_UP", {fullName: jsonData.fullName}).pipe(take(1)).subscribe(text => {
        this.notificationsService.html("", text, "wakeupme", { bgColor: "#fe5019", timeOut: 5000 });
      });
    }
  }

  handleReJoinedMessage(jsonData: any) {
    if (jsonData.participantId !== this.myUserId()) {
      this.translate.get("RE_JOINED", {fullName: jsonData.fullName}).pipe(take(1)).subscribe(text => {
        this.notificationsService.html("", text, "custom", { bgColor: "#fe5019", timeOut: 5000, bare: jsonData.jid || this.displayName });
      });
    }
  }

  handleBroadcastMessage(participantId: string, email: string, content: string) {
    this.logger.info("handleBroadcastMessage", participantId, content);
    if (participantId !== this.myUserId()) {
      this.notificationsService.html("", content, "custom", { bgColor: "#fe5019", timeOut: 5000, bare: email, participantId});
    }
  }

  /** Handling jitsi connection errors */
  private onConnectionFailed(err) {
    this.logger.error("[JitsiService][Connection][onConnectionFailed]", err, !!this.networkSubscription$, this.networkOnline);

    // if it failed even before success join
    if (!this.networkSubscription$) {
      setTimeout(() => {
        this.callRestoreNotPossible(err);
      }, 300);
      return;
    }

    if (this.networkOnline) {
      setTimeout(() => {
        this.join();
      }, 1000);
    } else {
      // wait couple seconds for connection restoring
      setTimeout(() => {
        if (this.networkOnline) {
          this.join();
        } else {
          this.callRestoreNotPossible(err);
        }
      }, 3500);
    }
  }

  private onConnectionEstablished() {
    this.logger.info("[JitsiService][Connection][onConnectionEstablished]");
    this.invalidateCallRestoreTimer();
  }

  private onConnectionInterrupted() {
    console.warn("[JitsiService][Connection][onConnectionInterrupted]");
    if (this.room) {
      if (!this.networkOnline) {
        this.broadcaster.broadcast(BroadcastKeys.LOST_CONNECTION_WHILE_ACTIVE_CALL, {});

        this.runCallRestoreTimer(JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR);
      }
    }
  }

  private onConnectionRestored() {
    this.logger.info("[JitsiService][Connection][onConnectionRestored] ");

    this.invalidateCallRestoreTimer();
  }

  private onDisconnect(err) {
    this.logger.info("[JitsiService][Connection][onDisconnect]", err);
    this.broadcaster.broadcast("onStopShareScreen");
  }


  private handlePresenterInterrupted(presenterId) {
    // check twice if the status remains the same

    setTimeout(() => {
      if (presenterId === this.screenSharePresenterParticipant$.value) {
        this.logger.info("re-checking after 3 seconds for presenterStatus: ", this.participantsConnStatus[presenterId]);
        if ((this.participantsConnStatus[presenterId] === "interrupted") && (presenterId === this.screenSharePresenterParticipant$.value)) {
          this.handleScreenShareStoppedParticipant(presenterId);
          this.screenSharePresenterParticipant$.next("");
          setTimeout(() => {
            this.resetTileMode();
          }, 200);
        }
      }
    }, 3000);
    setTimeout(() => {
      if (presenterId === this.screenSharePresenterParticipant$.value) {
        this.logger.info("re-checking after 6 seconds for presenterStatus: ", this.participantsConnStatus[presenterId]);
        if ((this.participantsConnStatus[presenterId] === "interrupted") && (presenterId === this.screenSharePresenterParticipant$.value)) {
          this.handleScreenShareStoppedParticipant(presenterId);
          this.screenSharePresenterParticipant$.next("");
          setTimeout(() => {
            this.resetTileMode();
          }, 200);
        }
      }
    }, 6000);


  }

  private connection2PeopleRestoreTimer: any;

  private onParticipantConnStatuschanged(participantId, newStatus) {
    this.participantsConnStatus[participantId] = newStatus;
    this.logger.info("[JitsiService][onParticipantConnStatuschanged] participantsConnStatusArray: ", this.participantsConnStatus);

    this._emitStatsUpdate(participantId, {connStatus: newStatus});

    // total participants on a call (including me)
    let totalParticipansCount;
    let callParticipants;
    this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
      callParticipants = participants;
      totalParticipansCount = participants ? participants.length : 0;
    });

    // total participans with conn status either 'inactive' or 'interrupted'
    let totalParticipansInactiveOrInterrupted = Object.values(this.participantsConnStatus).filter(s => s === "inactive" || s === "interrupted").length;

    this.logger.info("[JitsiService][onParticipantConnStatuschanged]", `${this.getDisplayName(participantId)}(${participantId})`, newStatus, this.participantsConnStatus);
    this.logger.info("[JitsiService][onParticipantConnStatuschanged]", `Total/InactiveOrInterrupted: ${totalParticipansCount} / ${totalParticipansInactiveOrInterrupted}`, callParticipants);

    if (newStatus === "interrupted") {
      // if (this.screenSharePresenterParticipant$.value === participantId) {
        console.warn("[JitsiService][onParticipantConnStatuschanged] presenter interrupted? ", this.screenSharePresenterParticipant$.value, participantId);
        if (participantId === this.screenSharePresenterParticipant$.value) {
          this.handlePresenterInterrupted(participantId);
        }
      // }
    }

    // // handle for 1:1 call
    if (totalParticipansCount === 2) {
      if (totalParticipansInactiveOrInterrupted === 1) {
        this.connection2PeopleRestoreTimer = setTimeout(() => {
          if (this.room) {
            this.notificationService.openSnackBarWithTranslation("RECONNECTING_IN_CALL", {}, 5000);
            console.warn("[JitsiService] RECONNECTING_IN_CALL2 HandleInactive restartMediaSessions, isRecording: ", this.isRecording);
            this.room._restartMediaSessions();
          }
        }, 5000);
      } else if (totalParticipansInactiveOrInterrupted === 0){
        clearTimeout(this.connection2PeopleRestoreTimer);
      }
    }

    if (totalParticipansCount <= 3) {
      // skip
      // do not use call restoration logic in samll groups
      // @Ihor: but should we try?
      return;
    }



    if (totalParticipansInactiveOrInterrupted >= (totalParticipansCount - 1)) { // -1 means we should not count ourself
      // seems we lost connection
      console.warn("[JitsiService][onParticipantConnStatuschanged]", "Videos are lost, let's try to automatically restore during 10s");

      this.participantsConnStatusTimer = setTimeout(() => {
        console.warn("[JitsiService][onParticipantConnStatuschanged]", "Videos are lost, let's try to re-join");

        this.broadcaster.broadcast(BroadcastKeys.LOST_CONNECTION_WHILE_ACTIVE_CALL, {});

        this.rejoinCall();

        this.participantsConnStatusTimer = null;
      }, 60000);

    } else {
      console.warn("[JitsiService][onParticipantConnStatuschanged]", "restored");
      this.invalidateParticipantsConnStatusTimer();
    }
  }

  private invalidateParticipantsConnStatusTimer() {
    if (this.participantsConnStatusTimer) {
      clearTimeout(this.participantsConnStatusTimer);
      this.participantsConnStatusTimer = null;
    }
  }

  private setupNetworkChangesListener() {
    if (this.networkSubscription$) {
      return;
    }

    this.logger.info("[JitsiService][setupNetworkChangesListener]");

    this.networkSubscription$ = this.store.select(getNetworkInformation).pipe(distinctUntilChanged()).subscribe(information => {
      this.logger.info("[JitsiService][getNetworkInformation]", information.onlineState, information);
      this.networkOnline = information.onlineState;

      // if we got active network AND have a restoring timer
      // then try to re-join the call
      if (this.networkOnline && this.callRestoreTimer$) {
        this.invalidateCallRestoreTimer();
      }
    });
  }

  private invalidateNetworkChangesListener() {
    if (this.networkSubscription$) {
      this.logger.info("[JitsiService][invalidateNetworkChangesListener]");
      this.networkSubscription$.unsubscribe();
      this.networkSubscription$ = null;
    }
  }

  private runCallRestoreTimer(obtainedError) {
    if (this.callRestoreTimer$) {
      console.warn("[JitsiService][runCallRestoreTimer] return, timer already defined");
      return;
    }

    this.logger.info("[JitsiService][runCallRestoreTimer]");

    this.callRestoreTimer$ = timer(JitsiService.CALL_RESTORE_INTERVAL).subscribe(() => {
      this.callRestoreNotPossible(obtainedError);
    });
  }

  private callRestoreNotPossible(obtainedError) {
    this.logger.info("[JitsiService][callRestoreNotPossible] timeout, call restore is not possible");

    // this.leave();

    this.invalidateCallRestoreTimer();

    // restore is not possibloe, so simply hangup it all
    this.logger.info("[JitsiService][callRestoreNotPossible] broadcasting CALL_CONNECTION_FAILED");
    // this will perform a call hangup
    this.broadcaster.broadcast(BroadcastKeys.CALL_CONNECTION_FAILED);

    // show alert
    let key = CommonUtil.findKey(this.CONNECTION_ERRORS, value => value === obtainedError);
    if (!key) {
      key = CommonUtil.findKey(this.CONFERENCE_ERRORS, value => value === obtainedError);
    }
    this.logger.info("[JitsiService][callRestoreNotPossible] errorkey: ", key);
    if (key && !this.checkOpenPopup(key) && !this.isRejoining) {
      this.openPopupList.push(key);
      this.showMessageDialog({ messageKey: key });
    }
  }

  private invalidateCallRestoreTimer() {
    if (this.callRestoreTimer$) {
      this.logger.info("[JitsiService][invalidateCallRestoreTimer]");
      this.callRestoreTimer$.unsubscribe();
      this.callRestoreTimer$ = null;
    }
  }

  private rejoinCall() {
    this.logger.info("[JitsiService][onParticipantConnStatuschanged][rejoinCall]");
    this.broadcaster.broadcast(BroadcastKeys.CALL_REJOIN);
  }

  private _emitStatsUpdate(participantId: string, stats: any) {
    const parsedStats = this.parseAndPrepareStats(participantId, stats);

    // uncomment if you want to watch known tracks on stats emit
    // this.getTracksForParticipant(participantId).take(1).subscribe((tracks) => {
    //  this.logger.info("getTracksForParticipant ", this.getDisplayName(participantId), participantId, tracks);
    // });

    // merge
    const mergedStats = {...this.participantsStats[participantId] || this.getDefaultStatsForParticipant(participantId), ...parsedStats};
    this.participantsStats[participantId] = mergedStats;

    // this.logger.info(`[JitsiService][_emitStatsUpdate] ${this.getDisplayName(participantId)}(${participantId}). resolution: ${mergedStats.resolution}, framerate: ${mergedStats.framerate}, bitrate: ${mergedStats.bitrate}, packetLoss: ${mergedStats.packetLoss}, connectionQuality: ${mergedStats.connectionQuality}`);

    this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
      // this.logger.info("[JitsiService][_emitStatsUpdate] xframerate for " + this.getDisplayName(participantId) + " is ", mergedStats.framerate, tracks.length);
      if ((mergedStats.framerate === "N/A") && (tracks.length === 0)) {
        // if (!CommonUtil.isOnIOS()) {
        this.store.select(getConferenceType).pipe(take(1)).subscribe(conferenceType => {
          if (!!conferenceType && (!conferenceType.startsWith("screen"))) {
            this.logger.info("[JitsiService][_emitStatsUpdate] handleZeroUpload for " + this.getDisplayName(participantId) + " is ", mergedStats.framerate, tracks.length, tracks);
            this.handleZeroRemoteTracks(0);
          }
        });
        // }
      }
      // this.logger.info("[JitsiService] xmergedStats for " + this.getDisplayName(participantId) + " is ", mergedStats);
      if (this.getDisplayName(participantId) === "me") {
        this.handleOwnStats(mergedStats);
      } else {
        this.handleRemoteStats(mergedStats);
      }
      this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
        // this.logger.info("[JitsiService] xmergedStats for conferenceType: ", type);
        if ((type && type.startsWith("screen")) || (!type)) {
          if (!this.connection) {
            this.logger.info("[JitsiService] stale cale?");
          //   this.processLeaveConference();
          }
        }
      });
    });

    this.broadcaster.broadcast(`onStatsUpdate${participantId}`, { id: participantId, stats: mergedStats });
  }

  getStatsForParticipant(participantId): CallParticipantStats {
    return this.participantsStats[participantId] || this.getDefaultStatsForParticipant(participantId);
  }

  private getDefaultStatsForParticipant(participantId): CallParticipantStats {
    const isLocal = participantId === this.myUserId();

    return {
      isLocal,
      resolution: "N/A",
      framerate: "N/A",
      bitrate: "↓N/A ↑N/A",
      packetLoss: "N/A",
      connectionQuality: CommonUtil.getDefaultConnectionQuality(),
      connStatus: "active",
      avgAudioLevels: 0
    };
  }

  private parseAndPrepareStats(participantId: string, stats: any): CallParticipantStats {
    const isLocal = participantId === this.myUserId();

    const res: CallParticipantStats = {
      isLocal
    };

    if (stats.resolution) {
      try {
        const resObj: any = Object.values(stats.resolution)[0];
        res.resolution = `${resObj.width}x${resObj.height}`;
      } catch (error) {
        this.logger.error("[JitsiService][parseAndPrepareStats] 'resolution' parse error", error);
        res.resolution = "N/A";
      }
    }

    if (stats.framerate) {
      try {
        res.framerate = Object.values(stats.framerate)[0] as number;
      } catch (error) {
        this.logger.error("[JitsiService][parseAndPrepareStats] 'framerate' parse error", error);
        res.framerate = "N/A";
      }
    }

    if (stats.bitrate) {
      res.bitrate = `↓${stats.bitrate.download}Kbps ↑${stats.bitrate.upload}Kbps`;
    }

    if (stats.packetLoss) {
      res.packetLoss = `↓${stats.packetLoss.download}% ↑${stats.packetLoss.upload}%`;
    }

    if (isLocal) {
      if (stats.localAvgAudioLevels) {
        res.avgAudioLevels = stats.localAvgAudioLevels;
      }
    } else {
      if (stats.avgAudioLevels) {
        res.avgAudioLevels = stats.avgAudioLevels;
      }
    }

    // TODO:
    // from iOS user we receive the following:
    // bitrate: {download: 0, upload: 0, audio: {…}, video: {…}}
    // connectionQuality: 0
    //
    // so probably need to workaround it, e.g to take 'connectionQuality' from 'framerate' as below

    // if (!stats.connectionQuality && (typeof res.framerate === "number")) {
    //   stats.connectionQuality = (100 * res.framerate) / 30;
    // }
    if (stats.connectionQuality) {
      res.connectionQuality = CommonUtil.getConnectionQuality(stats.connectionQuality);
    }

    // external property
    if (stats.connStatus) {
      res.connStatus = stats.connStatus;
    }

    return res;
  }

  private handleParticipantPropertyChanged(participant, propertyName, oldValue, newValue) {
    this.logger.info("[handleParticipantPropertyChanged]", participant, propertyName, oldValue, newValue);
    this.logger.info("[handleParticipantPropertyChanged] propertyName ", propertyName);
    this.logger.info("[handleParticipantPropertyChanged] newValue ", newValue);
    if (this.room) {
      if (propertyName === "e2ee.enabled") {
        this.store.dispatch(new SetParticipantE2EEStatus({id: participant, status: newValue}));
      } else if (propertyName === "e2ee.idKey") {
        this.store.dispatch(new SetParticipantE2EEIndexKey({id: participant, idKey: newValue}));
      }
      if (propertyName === "codecType") {
        if (newValue === "vp8") {
          if (this.isVP9) {
            this.isVP9 = false;
            this.switchDownVideoQuality(true);
          }
        }
        if (newValue === "vp9") {
          if (!this.isVP9) {
            this.switchUpVideoQuality();
          }
          this.isVP9 = true;
        }
      }
      this.room.getParticipants().forEach(p => {
        this.logger.info("[handleParticipantPropertyChanged][getParticipants]", p);
      });
    }
  }

  // JitsiMeetJS.events.conference.TRACK_ADDED
  private handleTrackAdded(track: any) {
    this.logger.info("[JitsiService][handleTrackAdded]");
    this.logger.info("[JitsiService][handleTrackAdded] isLocal: " + track.isLocal());
    this.logger.info("[JitsiService][handleTrackAdded] TYPE: " + track.type);

    if (this.localAudio && this.localAudio.isMuted() && track.isLocal() && track.type === "audio") {
      if (track.mute) {
        try {
          track.mute();
        } catch (e) {
          console.warn("[JitsiService][handleTrackAdded] track.mute error", e);
        }
      } else {
        // WTF? why a track does not have a 'mute' method?
        console.warn("[JitsiService][handleTrackAdded] no track.mute function available", track);
      }
      if ((track.type === "audio") || (track.type === "video" && track.videoType === "camera")) {
        this.setMediaStatus(track.getParticipantId(), track.type, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
      }
    } else {
      if ((track.type === "audio") || (track.type === "video" && track.videoType === "camera")) {
        // this.logger.info("[JitsiService][handleTrackAdded] setMediastatus: ", track.getParticipantId(), track.type, this.getMediaStatusByTrack(track), track.muted, track);
        this.setMediaStatus(track.getParticipantId(), track.type, this.getMediaStatusByTrack(track));
      }
    }

    let confType = this.getConferenceTypeForParticipant(track.getParticipantId());
    this.broadcaster.broadcast("onConferenceTypeChangeForParticipant", { id: track.getParticipantId(), type: confType });

    if (track.isLocal()) {
      this.logger.info("[JitsiService][handleTrackAdded](LOCAL)", track.type, track.getTrackId(), track.getParticipantId(), this.getDisplayName(track.getParticipantId()));
      this._room.next(true);
      return;
    } else {
      if (track.type === "video") {
        this.logger.info("[JitsiService][handleTrackAdded](REMOTE)", track.videoType, track.getTrackId(), track.getParticipantId(), this.getDisplayName(track.getParticipantId()), this.myUserId());
      } else {
        this.logger.info("[JitsiService][handleTrackAdded](REMOTE)", track.type, track.getTrackId(), track.getParticipantId(), this.getDisplayName(track.getParticipantId()), this.myUserId());
      }
    }

    this.handleRemoteTrackAdded(track);
  }

  private handleRemoteTrackAdded(track: any) {
    // this.logger.info("[JitsiService][handleRemoteTrackAdded1 track: ", track, track.isMuted(), track.getSourceName(), track.getParticipantId());
    // this.logger.info("[JitsiService][handleRemoteTrackAdded]2 getParticipantId", track.type, track.getTrackId(), track.getParticipantId(), this.getDisplayName(track.getParticipantId()), this.myUserId());

    this.updateRemoteTracksWithNewTrack(track);

    // const newSourceName = track.getSourceName();
    const participantId = (track.videoType === "desktop") ? "fake123" : track.getParticipantId();
    if (participantId === "fake123") {
      this.logger.info("[JitsiService][handleTrackAdded](REMOTE_SCREENSHARE_ADDED", track.videoType, track.getTrackId(), track.getParticipantId(), this.getDisplayName(track.getParticipantId()), this.myUserId());
      this.translate.get("SCREENSHARE").pipe(take(1)).subscribe(content => {
        this.fakeParticipant = {
          id: "fake123",
          name: this.getDisplayName(track.getParticipantId()) + " (" + content + ")"
        };
      });
      if (!track.muted) {
        this.broadcaster.broadcast("fakeParticipantActive");
        this.broadcaster.broadcast("onScreenStarted");
        this.store.dispatch(new ConferenceAddParticipant(this.fakeParticipant));
        this.store.dispatch(new JitsiConferenceAddParticipant(this.fakeParticipant));
        // this.logger.info("jitsiservice tracksubscription1 realScreenShareParticipant: ", track.getParticipantId(), track);
        this.realScreenShareParticipantId$.next(track.getParticipantId());
        this.setFullScreenParticipantId("fake123");
      }
    }
    this.attachTrack(track, participantId);
    if ((this.restartWorkArounds === 0) && CommonUtil.isOnAndroid() && (track.type === "video")) {
      ++this.restartWorkArounds;
      setTimeout(() => {
        this.logger.info("[JitsiService][handleRemoteTrackAdded] restartMediaSessions");
        this.room._restartMediaSessions();
      }, 300);
    }
    if ((this.restartWorkArounds === 0) && (CommonUtil.isOnIOS() || CommonUtil.isOnIpad())) {
      ++this.restartWorkArounds;
      setTimeout(() => {
        this.enableLocalTracksIOS();
      }, 300);
    }
  }

  private updateRemoteTracksWithNewTrack(newTrack: any) {
    // eslint-disable-next-line no-console
    console.log("[JitsiService][updateRemoteTracksWithNewTrack] track: ", newTrack);
    if (!!newTrack) {
      // const newSourceName = newTrack.getSourceName();
      const participantId = (newTrack.videoType === "desktop") ? "fake123" : newTrack.getParticipantId();

      let currentTracks = this._remoteTracks$.value || {};
      let participantCurrentTracks: any = currentTracks[participantId] || [];
      if (newTrack.type === "audio") {
        currentTracks[participantId] = [newTrack, ...participantCurrentTracks.filter(v => v.type !== "audio")];
      } else if (newTrack.type === "video") {
        currentTracks[participantId] = [...participantCurrentTracks, newTrack];
      }

      this.setRemoteTracks(currentTracks);

      this.logger.info("[JitsiService][updateRemoteTracksWithNewTrack]",
        {newTrack, participantCurrentTracks, displayName: this.getDisplayName(newTrack.getParticipantId())});

      setTimeout(() => {
        this.broadcaster.broadcast("onVideoSourceChange", participantId);
      }, 200);
    }
  }

  public attachTrack(track, participantId, fullPreviewOnly?: boolean) {
    this.logger.info("[JitsiService][attachTrack]", participantId, track.type, this.getDisplayName(participantId), this.myUserId());

    // this.logger.info("[JitsiService][attachTrack]2 track.type", track.type);

    if (!track) {
      return;
    }
    this.logger.info("[JitsiService][attachTrack1]", track, track.type, track.videoType, track.isMuted(), this.getDisplayName(participantId), fullPreviewOnly);
    if (track.type === "video" && track.videoType === "desktop") {
      this.logger.info("[JitsiService][attachTrack1screenshare]", track, track.type, track.videoType, track.isMuted(), this.getDisplayName(participantId), fullPreviewOnly);
    }
    if (track.type === "video" && track.videoType === "desktop" && track.isMuted()) {
      this.logger.info("[JitsiService][attachTrack1] bailout old screenshare track", track);
      return;
    }

    // TODO !!!
    // TODO: get rid of below 'setInterval'

    // Video track
    if (track.type === "video") {

      let triedVideo = 0;
      if (this.waitForVideo[participantId]) {
        clearInterval(this.waitForVideo[participantId]);
      }
      this.waitForVideo[participantId] = setInterval(() => {
        let videoElement = document.getElementById(`participantVideo${participantId}`);
        let videoPreviewElement = document.getElementById(`participantPreviewVideo${participantId}`);
        let participantFullVideo = document.getElementById(`participantFullVideo${participantId}`);
        let videoElementScreen = document.getElementById(`participantVideoScreen${participantId}`);
        const fakeParticipantId = "fake123";
        if (track.videoType === "desktop") {
          if (participantId !== fakeParticipantId) {
            // this.logger.info("jitsi.service tracksubscription1 setting realScreenShareParticipantId: ", participantId);
            this.realScreenShareParticipantId$.next(participantId);
          }
          videoElement = document.getElementById(`participantVideo${fakeParticipantId}`);
          videoPreviewElement = document.getElementById(`participantPreviewVideo${fakeParticipantId}`);
          participantFullVideo = document.getElementById(`participantFullVideo${fakeParticipantId}`);
          videoElementScreen = document.getElementById(`participantVideoScreen${fakeParticipantId}`);
        }

        if ((videoElement || videoPreviewElement || participantFullVideo)) {
          clearInterval(this.waitForVideo[participantId]);
          videoElement = document.getElementById(`participantVideo${participantId}`);
          videoPreviewElement = document.getElementById(`participantPreviewVideo${participantId}`);
          participantFullVideo = document.getElementById(`participantFullVideo${participantId}`);
          videoElementScreen = document.getElementById(`participantVideoScreen${participantId}`);
          if (track.videoType === "desktop") {
            videoElement = document.getElementById(`participantVideo${fakeParticipantId}`);
            videoPreviewElement = document.getElementById(`participantPreviewVideo${fakeParticipantId}`);
            participantFullVideo = document.getElementById(`participantFullVideo${fakeParticipantId}`);
            videoElementScreen = document.getElementById(`participantVideoScreen${fakeParticipantId}`);
            // this.logger.info("handleRemoteTrackAdded attachTrack1screenshare - using fake ", fakeParticipantId, videoElement, participantFullVideo);
          }
          const containerToDetach = [];
          for (let container of track.containers) {
            if ((container.id.indexOf(participantId) === -1 && container.id.indexOf("participantFullVideo") === -1) || !fullPreviewOnly && container.id.indexOf("participantVideoScreen") !== -1 || fullPreviewOnly && container.id.indexOf("participantFullVideo") !== -1) {
                containerToDetach.push(container);
                track.detach(container);
                this.logger.info("[JitsiService][attachTrack] containerToDetach ", containerToDetach, participantId, this.getDisplayName(participantId));
            }
          }
          this.logger.info("[JitsiService][attachTrack] ====", this.getDisplayName(participantId), videoElement, participantFullVideo);

          if (videoElement) {
            this.logger.info("[JitsiService][attachTrack] attaching video (videoElement)", track, this.getDisplayName(participantId), videoElement);
            track.attach && track.attach(videoElement);
            this.logger.info("[JitsiService][attachTrack] attached video (videoElement)", track.isMuted(), this.getDisplayName(participantId));
            /*
            if (track.videoType === "desktop") {
              this.logger.info("handleRemoteTrackAdded - using fake for track: ", track, videoElement);
            } else {
              this.logger.info("attachVideoElementParticipant", this.getDisplayName(participantId), track, videoElement);
            }
            */
          }
          if (videoPreviewElement) {
            track.attach && track.attach(videoPreviewElement);
            // this.logger.info("[JitsiService][attachTrack] attachTrack1screenshare attached video (videoPreviewElement)", track.isMuted(), this.getDisplayName(participantId));
          }
          if (videoElementScreen) {
            track.attach && track.attach(videoElementScreen);
            // this.logger.info("[JitsiService][attachTrack] attached video (videoElementScreen)", track.isMuted(), this.getDisplayName(participantId));
          }
          if (participantFullVideo) {
            track.attach && track.attach(participantFullVideo);
            // this.logger.info("[JitsiService][attachTrack1] attached video (participantFullVideo)", track, track.isMuted(), this.getDisplayName(participantId));
          }
          if (!track.isMuted()) {
            if (videoElement) {
              videoElement.removeAttribute("style");
            }
            if (videoPreviewElement) {
              videoPreviewElement.removeAttribute("style");
            }
            if (participantFullVideo) {
              participantFullVideo.removeAttribute("style");
            }
            if (videoElementScreen) {
              videoElementScreen.removeAttribute("style");
            }
          } else {
            if (videoElement) {
              videoElement.style.display = "none";
            }
            if (videoPreviewElement) {
              videoPreviewElement.style.display = "none";
            }
            if (participantFullVideo) {
              participantFullVideo.style.display = "none";
            }
          }

          // correct audio output
          if (!CommonUtil.isOnIOS()) {
            this.audioOutputService.correctAudioOutput();
          }
        } else if (videoElementScreen !== null) {
          track.attach && track.attach(videoElementScreen);
          this.logger.info("[JitsiService][attachTrack1] attached screen share", this.getDisplayName(participantId));

          if (!track.isMuted()) {
            videoElementScreen.removeAttribute("style");
          } else {
            videoElementScreen.style.display = "none";
          }
          videoElementScreen = null;

          clearInterval(this.waitForVideo[participantId]);

          // correct audio output
          if (!CommonUtil.isOnIOS()) {
            this.audioOutputService.correctAudioOutput();
          }
        } else if (triedVideo > 20) {
          clearInterval(this.waitForVideo[participantId]);
        }
        videoElement = null;
        videoPreviewElement = null;
        participantFullVideo = null;
        triedVideo++;
      }, 500);

      // attach large video if required
      this.store.select(getFullScreenParticipantId).pipe(take(1)).subscribe(res => {
        this.logger.info("[JitsiService][attachTrack] getFullScreenParticipantId", participantId, this.getDisplayName(participantId), res);
        if (res === participantId) {
          this._attachLargeVideoIfNotAttached(participantId);
        }
        if (!res && (participantId === "fake123")) {
          setTimeout(() => {
            this.setFullScreenParticipantId("fake123");
            this.pinnedParticipant$.next("fake123");
          }, 300);
        }
      });
    // Audio track
    } else if (!this.isLocalId(participantId)) {

      let triedAudio = 0;
      clearInterval(this.waitForAudio[participantId]);
      this.waitForAudio[participantId] = setInterval(() => {
        let audioElement = document.getElementById(`participantAudio${participantId}`);
        if (audioElement) {
          this.logger.info("[JitsiService][attachTrack] attached audio", this.getDisplayName(participantId));

          track.attach && track.attach(audioElement);

          clearInterval(this.waitForAudio[participantId]);
          if (!CommonUtil.isOnIOS()) {
            this.audioOutputService.correctAudioOutput();
          }
          audioElement = null;
          this.broadcaster.broadcast("onAttachTrack", participantId);
        } else if (triedAudio > 10) {
          clearInterval(this.waitForAudio[participantId]);
        }
        triedAudio++;
      }, 500);
    }

  }

  public attachTrackToElement(track, element, isFullScreen?: boolean) {
    if (!track) {
      return;
    }
    let containersToDetach = [];
    if (!isFullScreen) {
      containersToDetach = [...track.containers];
    }

    containersToDetach.forEach(container => {
      // track.detach(container);
      this.logger.info("[JitsiService] attachTrackToElement detach", container);
    });

    this.logger.info("[JitsiService] attachTrackToElement", element, isFullScreen);
    track.attach && track.attach(element);
  }

  // Jitsi event
  private handleTrackRemoved(track: any) {
    this.logger.info("[JitsiService][handleTrackRemoved]", track.getTrackId(), track.type, this.getDisplayName(track.getParticipantId()), track );

    if (track.isLocal()) {
      return;
    }

    // const oldSourceName = track.getSourceName();
    let participantId = track.getParticipantId();
    if (track.videoType === "desktop") {
      console.log("fakeParticipant needs to be removed, removed track: ", track);
      this.broadcaster.broadcast("fakeParticipantInactive");
      participantId = "fake123";
      setTimeout(() => {
        this.store.dispatch(new ConferenceRemoveParticipant("fake123"));
        this.store.dispatch(new JitsiConferenceRemoveParticipant("fake123"));
      }, 150);
    }

    this.updateRemoteTracksWithRemovedTrack(track);

    this.detachTrack(track, track.getParticipantId());
  }

  private updateRemoteTracksWithRemovedTrack(removedTrack: any) {
    const participantId = removedTrack.getParticipantId();

    const currentTracks = this._remoteTracks$.value || {};
    if (currentTracks[participantId]) {
      const filteredTracks = currentTracks[participantId].filter(t => t.track.id !== removedTrack.track.id);
      const updatedCurrentTracks = {
        ...currentTracks,
        [participantId]: filteredTracks
      };

      this.logger.info("[JitsiService][updateRemoteTracksWithRemovedTrack]", removedTrack.track.id, removedTrack.type, removedTrack.getParticipantId(), removedTrack, updatedCurrentTracks);

      this.setRemoteTracks(updatedCurrentTracks);
    } else {
      this.logger.info("[JitsiService][updateRemoteTracksWithRemovedTrack] skip, nothing to remove");
    }
  }

  private detachTrack(track, participantId) {
    this.logger.info("[JitsiService][detachTrack]", track.track.id, track.getType(), this.getDisplayName(participantId));

    // Video
    if (track.getType() === "video") {
      this.hideTrackAllContainers(track);

      // detach
      const videoElement = document.getElementById(`participantVideo${participantId}`);
      if (videoElement) {
        track.detach(videoElement);
        videoElement.style.display = "none";
        this.logger.info("[JitsiService][detachTrack] detached", track.track.id, track.getType(), this.getDisplayName(participantId));
      } else {
        this.logger.info("[JitsiService][detachTrack] skip, nothing to detached", track.track.id, track.getType(), this.getDisplayName(participantId));
      }

      // get off a full screen for the detached user
      this.store.select(getFullScreenParticipantId).pipe(take(1)).subscribe(res => {
        if (res && res === participantId) {
          let largeVideoElement = document.getElementById("largeVideo");
          if (largeVideoElement) {
            this.logger.info("[JitsiService][detachTrack] detached", largeVideoElement);
            track.detach(largeVideoElement);
            largeVideoElement.style.display = "none";
            largeVideoElement = null;
          }
          this.selectParticipant(null);
        }
      });

      // Audio
    } else {
      let audioElement = document.getElementById(`participantAudio${participantId}`);
      if (audioElement) {
        track.detach(audioElement);
        audioElement = null;
      }
    }

    this.broadcaster.broadcast("onDetachTrack", participantId);
  }

  public detachVideoTrack(track, participantId) {
    this.logger.info("[JitsiService][detachTrack]", track.track.id, track.getType(), this.getDisplayName(participantId));
    // Video
    if (track.getType() === "video") {
      this.hideTrackAllContainers(track);
      // detach
      const videoElement = document.getElementById(`participantVideo${participantId}`);
      if (videoElement) {
        track.detach(videoElement);
        videoElement.style.display = "none";
        this.logger.info("[JitsiService][detachTrack] detached");
      } else {
        this.logger.info("[JitsiService][detachTrack] skip, nothing to detached");
      }
    }
  }

  public detachFullPreviewVideoTrack(track, participantId) {
    this.logger.info("[JitsiService][detachFullPreviewVideoTrack]", track.track.id, track.getType(), this.getDisplayName(participantId));
    // Video
    if (track.getType() === "video") {
      this.hideTrackAllContainers(track);
      // detach
      const videoElement = document.getElementById(`participantFullVideo${participantId}`);
      if (videoElement) {
        track.detach(videoElement);
        videoElement.style.display = "none";
        this.logger.info("[JitsiService][detachFullPreviewVideoTrack] detached");
      } else {
        this.logger.info("[JitsiService][detachFullPreviewVideoTrack] skip, nothing to detached");
      }
    }
  }

  public getRoom() {
    return this.room;
  }

  public getConnection() {
    return this.connection;
  }
  // JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED
  private trackMuteUnmute(track) {
    this.logger.info("[JitsiService][onTrackMuteUnmute] track: ", track);
    if (track.isMuted()) {
      this.hideTrackAllContainers(track);
      if (track.isLocal() && track.type === "video" && track.videoType === "camera") {
        // this.logger.info("[JitsiService][onTrackMuteUnmute] isCamOn track: ", track);
        this.isCamOn.next(false);
      }
      if (track.videoType === "desktop") {
        this.broadcaster.broadcast("fakeParticipantInactive");
        setTimeout(() => {
          this.store.dispatch(new ConferenceRemoveParticipant("fake123"));
          this.store.dispatch(new JitsiConferenceRemoveParticipant("fake123"));
        }, 150);
      }
    } else {
      this.logger.info("[JitsiService][onTrackMuteUnmute] isCamOn track: ", track, track.isLocal());
      if (track.isLocal() && !track.isMuted() && track.type === "video" && track.videoType === "camera") {
        // this.logger.info("[JitsiService][onTrackMuteUnmute] isCamOn => true - track: ", track);
        this.isCamOn.next(true);
      }

      if (track.videoType === "desktop") {
        // console.log("trackMute screenshare started, add fakeParticipant ", track);
        // const newSourceName = track.getSourceName();
        const participantId = (track.videoType === "desktop") ? "fake123" : track.getParticipantId();
        if (participantId === "fake123") {
          this.translate.get("SCREENSHARE").pipe(take(1)).subscribe(content => {
            this.fakeParticipant = {
              id: "fake123",
              name: this.getDisplayName(track.getParticipantId()) + " (" + content + ")"
            };
          });
          const currentRemoteTracks = this._remoteTracks$.value;
          if ((!currentRemoteTracks[this.fakeParticipant.id]) || (currentRemoteTracks[this.fakeParticipant.id].length === 0)) {
            this.handleRemoteTrackAdded(track);
          }
          this.broadcaster.broadcast("fakeParticipantActive");
          this.broadcaster.broadcast("onScreenStarted");
          this.store.dispatch(new ConferenceAddParticipant(this.fakeParticipant));
          this.store.dispatch(new JitsiConferenceAddParticipant(this.fakeParticipant));
        }
      }
      this.showTrackAllContainers(track);
    }

    this.broadcaster.broadcast("onTrackMuteUnmute", track);
  }

  private hideTrackAllContainers(track, detach = false) {
    this.logger.info("[JitsiService][hideTrackAllContainers] detach", detach);

    for (let container of track.containers) {
      if (track.getType() === "video") {
        container.style.display = "none";
      }
      if (detach) {
        track.detach(container);
      }
    }
  }

  private showTrackAllContainers(track) {
    for (let container of track.containers) {
      if (track.getType() === "video") {
        // this.logger.info("[showAllTrackContainers] ", track, track.videoType, this.getDisplayName(track.getParticipantId()));
        container.style.display = "inline-block";
      }
    }
  }

  tempLocalTracks = null;
  onLocalTracks(tracks, muteAudio?: boolean, shouldStartScreen?: boolean, isFromScreenshare?: boolean) {
    this.logger.info("[JitsiService][onLocalTracks]", tracks, this.isJoined, !!this.room, !!this.connection, this.hangedUp, shouldStartScreen);

    if (this.hangedUp && !shouldStartScreen) {
      this.disposeAndRemoveTracks(tracks);
      return;
    }

    // JOIN
    if (!this.room && !this.connection && !this.hangedUp) {
      this.join();
    }

    if (tracks && tracks.find(v => v.type === "video" && v.videoType === "desktop")) {
      this.notificationService.openSnackBarWithTranslation("YOU_ARE_SHARING_YOUR_SCREEN_NOW", {}, 5000);
      setTimeout(async () => {
        let hasWebcam = false;
        this.store.select(getHasWebcam).pipe(take(1)).subscribe(res => {
          hasWebcam = res;
        });
        const isFirefox = /firefox/i.test(navigator.userAgent.toLowerCase());
        const isLinux = /linux/i.test(navigator.userAgent.toLowerCase());
        // always skip presenter since we have multi stream
        const shouldSkipPresenter = true; // isFirefox || CommonUtil.isOnSafari() || (environment.isElectron && isLinux);
        if (hasWebcam && this.enabledVideo && !shouldSkipPresenter) {
          try {
            this.logger.info("enable presenter mode - camId: ", this.cameraId);
            let _presenterEffect = await this._createPresenterStreamEffect(null, this.cameraId);
            if (!!_presenterEffect) {
              await this.localVideo.setEffect(_presenterEffect);
              this.localVideo.unmute();
            } else {
              console.warn("skipped presenter effect");
            }
          } catch (err) {
            this.logger.error("Failed to apply the presenter effect", err);
          }
        }
      }, 2000);

    }

    if (this.isJoined) {
      if (this.hangedUp) {
        this.disposeAndRemoveTracks(tracks);
        return;
      }
      this.processNewLocalTracks(tracks, muteAudio, shouldStartScreen, isFromScreenshare);
    } else {
      this.tempLocalTracks = tracks;
      this.onConferenceJoin$.pipe(filter(v => !!v), take(1)).subscribe(joined => {
        this.logger.info("[JitsiService][onLocalTracks] onConferenceJoin$", {joined, hangedUp: this.hangedUp});

        if (this.hangedUp) {
          this.disposeAndRemoveTracks(tracks);
          return;
        }
        this.tempLocalTracks = null;
        this.processNewLocalTracks(tracks, muteAudio, shouldStartScreen, isFromScreenshare);
      });
    }
  }

  restartMediaSessionsWithIOS() {
    let hasVP8 = false;
    this.room.getParticipants().forEach(p => {
      this.logger.info("[restartMediaSessionsWithIOS]", p._properties.codecType);
      if (p._properties.codecType === "vp8") {
        hasVP8 = true;
      }
    });
    if (hasVP8 && (this.vp8restartCount === 0)) {
      this.vp8restartCount++;
      setTimeout(() => {
        this.room._restartMediaSessions();
      }, 500);

    }
  }

  processNewLocalTracks(tracks, muteAudio?: boolean, shouldStartScreen?: boolean, isFromScreenshare?:boolean) {
    this.logger.info("[JitsiService][processNewLocalTracks] setStreamId", {tracks, muteAudio, isJoined: this.isJoined, shouldStartScreen});
    /*
    const tracksHaveScreenshare = tracks.filter(t => (t?.videoType === "desktop" || t?.videoType === "screen"));
    if (!!tracksHaveScreenshare && (tracksHaveScreenshare.length > 0)) {
      this.logger.info("[JitsiService][processNewLocalTracks] tracksHaveScreenshare ", tracksHaveScreenshare);
    } else {
      this.store.dispatch(new SetStreamId(""));
    }
    */
    for (let track of tracks) {
      if (track.type === "video") {
        this.useVideoStream(track);
        this.setMediaStatus(this.myUserId(), "video", ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
        this.store.dispatch(new ConferenceUnMuteVideo());
        this.restartMediaSessionsWithIOS();
      } else {
        this.useAudioStream(track, isFromScreenshare);
      }
    }
    if (this.nextAction === "sendScreenInvitation") {
      this.broadcaster.broadcast("sendScreenInvitation");
      this.nextAction = "";
    }
    if (CommonUtil.isOnIOS()) {
      this.broadcaster.broadcast("disableVideoButton", false);
    }

    if (muteAudio) {
      this.logger.info("[JitsiService][processNewLocalTracks] calling muteAudio");
      this.muteAudio();
    } else {
      this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
        if (type && type.startsWith("screen")) {
          this.logger.info("[JitsiService][processNewLocalTracks] skip unmuteAudio - we are in screensession!");
        } else {
          this.logger.info("[JitsiService][processNewLocalTracks] calling unmuteAudio");
          this.unmuteAudio();
        }
      });
    }

    if (shouldStartScreen) {
      this.shouldStartScreen = true;
      setTimeout(() => {
        this.shareScreen();
      }, 2000);
    }

    this.enableLocalTracksIOS();
  }

  muteVideoWhenGoToBackground() {
    const nts = Date.now();
    this.muteVideoWhenGoToBackgroundTrigger.next(nts);
  }

  _muteVideoWhenGoToBackground() {
    this.logger.info("[JitsiService][muteVideoWhenGoToBackground] enabledVideo?", this.enabledVideo);

    if (this.enabledVideo && this.isVideoTrackAvailable() && window.appInBackground) {
      this.needToEnableVideoOnSwitchToForeground = true;
      this.turnOffVideo();
    }
  }

  unmuteVideoWhenGoToForeground() {
    setTimeout(() => {
      const nts = Date.now();
      this.unmuteVideoWhenGoToForegroundTrigger.next(nts);
    }, 200);
  }

  _unmuteVideoWhenGoToForeground() {
    this.logger.info("[JitsiService][unmuteVideoWhenGoToForeground] needToEnableVideoOnSwitchToForeground?", this.needToEnableVideoOnSwitchToForeground, this.enabledVideo);

    if (this.needToEnableVideoOnSwitchToForeground) {
    // if (this.needToEnableVideoOnSwitchToForeground ||
    //   (CommonUtil.isOnIOS() && this.needToEnableVideoOnSwitchToForeground === null && this.enabledVideo)) {
      this.needToEnableVideoOnSwitchToForeground = false;
      this.turnOnVideo();

      // sometimes after switch to foreground there is a black stream instead of a video
      // so we fix it by off/on video
      if (CommonUtil.isOnIOS() && this.enabledVideo) {
        this.logger.info("[JitsiService][unmuteVideoWhenGoToForeground] fix iOS black stream1");

        setTimeout(() => {
          this.logger.info("[JitsiService][unmuteVideoWhenGoToForeground] fix iOS black stream2");
          this.turnOffVideo();
          setTimeout(() => {
            this.logger.info("[JitsiService][unmuteVideoWhenGoToForeground] fix iOS black stream3");
            this.turnOnVideo();
            // this.enableLocalTracksIOS();
          }, 1000);
        }, 1000);
      }
      if (CommonUtil.isOnAndroid()) {
        this.room._restartMediaSessions();
      }
    }
  }



  public setAudioOutputDevice(deviceId) {
    if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output")) {
      this.logger.info("[JitsiService][setAudioOutputDevice] setJitsiDevices", deviceId);
      JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId);
    } else {
      console.warn("[JitsiService][setAudioOutputDevice] output device change is not available");
    }
  }

  startAudioRetryCount = 0;

  startAudio(isRetry = false, muteAudio?: boolean) {
    this.hangedUp = false;
    this.logger.info("[JitsiService][leave] startAudio ", this.hangedUp);
    this.onConferenceJoin$.next(false);

    if (isRetry) {
      ++this.startAudioRetryCount;
    } else {
      this.startAudioRetryCount = 0;
    }

    console.log("[JitsiService][startAudio] isRetry", isRetry, this.startAudioRetryCount, this.room);

    this.enabledVideo = false;

    // correct audio output
    //
    if (CommonUtil.isOnNativeMobileDevice()) {
      if (CommonUtil.isOnAndroid()) {
        this.audioOutputService.correctAudioOutput();
      } else {

        // BLUETOOTH
        // iosDebugDisable
        /*
        this.audioOutputService.isBluetoothHeadsetOrHeadphoneConnected().subscribe(available => {
          this.logger.info("[JitsiService][startAudio][isBluetoothHeadsetOrHeadphoneConnected]", available);
          if (available) {
            this.audioOutputService.setInitialAudioOutput(AudioToggle.BLUETOOTH);
          } else {
            this.audioOutputService.setInitialAudioOutput(AudioToggle.EARPIECE);
          }
        });
        */
      }
    // only for desktop
    } else if (!CommonUtil.isOnMobileDevice()){
      const preferableAudioOutputId = this.getPreferableAudioOutputId();
      if (preferableAudioOutputId) {
        this.setAudioOutputDevice(preferableAudioOutputId);
      }
    }

    let devices = ["audio"];

    let hasWebcam = false;
    this.store.select(getHasWebcam).pipe(take(1)).subscribe(res => {
      hasWebcam = res;
    });
    if (!hasWebcam) {
    // if (!hasWebcam) {
      devices = ["audio"];
    }
    this.store.select(getConferenceType).pipe(take(1)).subscribe(conferenceType => {
      if (!!conferenceType && conferenceType.startsWith("screen")) {
        devices = [];
      }
    });
    const micDeviceId = CommonUtil.isOnMobileDevice() ? null : this.getPreferableMicId();

    this.logger.info("[JitsiService][startAudio] setJitsiDevices", micDeviceId, CommonUtil.isOnMobileDevice());

    if (CommonUtil.isOnIOS()) {
      this.iOSRTCPluginRegisterGlobals();
    }

    const mediaConstraints = this.buildLocalTrackConstraints(devices, null, micDeviceId);
    this.logger.info("[JitsiService][startAudio] mediaConstraints", mediaConstraints);

    try {

      JitsiMeetJS.createLocalTracks(mediaConstraints)
        .then(tracks => {
          this.onLocalTracks(tracks, muteAudio);
          if (CommonUtil.isOnIOS() && (this.startAudioRetryCount < 1)) {
            this.logger.info("[JitsiService][startAudio] retry count ", this.startAudioRetryCount);
            setTimeout(() => {
              this.startAudio(true);
            }, 1000);
          }
        })
        .catch(error => {
          this.logger.error("[JitsiService][startAudio] createLocalTracks", error);
          const key = CommonUtil.findKey(ConstantsUtil.CONFERENCE_GUM_ERRORS, value => value === error.name);
          if (key) {
            this.handleGumErrors(key, error);
          }
        });
    } catch (e) {
      this.logger.error("[JitsiService][startAudio] error", e, JSON.stringify(e));

      if (this.startAudioRetryCount < 1) {
        this.logger.info("[JitsiService][startAudio] error, will retry");
        setTimeout(() => {
          this.startAudio(true);
        }, 1000);
      }
    }
  }

  private handleGumErrors(key, error) {
    this.logger.error("[JitsiService][handleGumErrors] key: error:", key, error);

    if (key === "CHROME_EXTENSION_USER_CANCELED") {
      this.logger.info("[JitsiService][handleGumErrors] CHROME_EXTENSION_USER_CANCELED", this._localTracks$.value.length, this._localTracks$.value);
      this.store.dispatch(new SetScreenSharingRequestStatus(false));
      this.store.dispatch(new SetScreenSharingStarted());
      this.store.select(getScreenSharingRequest).pipe(take(1)).subscribe(v => {
          if (this.myUserId() === v.presenterId) {
            this.store.dispatch(new ResetScreenSharingData());
          }
      });
      this.sendData(ConstantsUtil.COMMANDS.toggleShareScreen, ConstantsUtil.SCREEN_SHARE_STOPPED_COMMAND_DATA);
      this.logger.info("[jitsiService] handleGumErrors broadcast: onStopShareScreen screenshareStopped");
      this.broadcaster.broadcast("onStopShareScreen");
      this.broadcaster.broadcast("screenshareStopped");
      this.setUnshareScreen();
      if (this._localTracks$.value.length === 0) {
        this.broadcaster.broadcast(BroadcastKeys.CALL_USER_CANCELED_SHARE_SCREEN_CALL, error);
      } else {
        this.broadcaster.broadcast(BroadcastKeys.CALL_USER_CANCELED_SHARE_SCREEN_SWITCH, error);
      }

      // mac OS permissions error
      if (error.message === "Permission denied by system") {
        if (CommonUtil.isOnMacOS()) {
          this.broadcaster.broadcast(BroadcastKeys.SHARE_SCREEN_MACOS_PERMISSIONS_ERROR, error);
        } else {
          // other OS: TBD
        }
      }

    } else if (key === "GENERAL_MEDIA_ERROR") {
      if (error.message === "Could not start audio source") {
        // electron
        if (environment.isElectron) {
          if (CommonUtil.isOnMacOS()) {
            this.broadcaster.broadcast(BroadcastKeys.MIC_MACOS_PERMISSIONS_ERROR, error);
          } else {
            // other OS: TBD
          }
        // web
        } else {
          if (this.checkOpenPopup("GENERAL_AUDIO_ERROR") === false) {
            this.openPopupList.push("GENERAL_AUDIO_ERROR");
            this.showMessageDialog({ messageKey: "GENERAL_AUDIO_ERROR" });
          }
        }
      } else if (error.message === "Could not start video source") {
        // electron
        if (environment.isElectron) {
          if (CommonUtil.isOnMacOS()) {
            this.broadcaster.broadcast(BroadcastKeys.CAM_MACOS_PERMISSIONS_ERROR, error);
          } else {
            // other OS: TBD
            if (this.checkOpenPopup("GENERAL_VIDEO_ERROR") === false) {
              this.openPopupList.push("GENERAL_VIDEO_ERROR");
              this.showMessageDialog({ messageKey: "GENERAL_VIDEO_ERROR" });
            }
          }
        // web
        } else {
          if (this.checkOpenPopup("GENERAL_VIDEO_ERROR") === false) {
            this.openPopupList.push("GENERAL_VIDEO_ERROR");
            this.showMessageDialog({ messageKey: "GENERAL_VIDEO_ERROR" });
          }
        }
      }
    } else if (this.checkOpenPopup(key) === false) {
      this.openPopupList.push(key);
      this.showMessageDialog({ messageKey: key });
    }
    this.broadcaster.broadcast("stopPlayCalling");
    this.resetTileMode();
  }

  private buildLocalTrackConstraints(devices: string[], cameraId?: string, micId?: string, facingMode?: string) {
    // this.logger.info("[jitsiService][buildLocalTrackConstraints] devices: ", devices);
    let params: any = {
      devices: devices,
      firefox_fake_device: false
    };
    let resolution = this.configService.get("preferredResolution");
    this.logger.info("[jitsiService][buildLocalTrackConstraints] resolution: ", resolution);
    if (!!cameraId) {
      params.cameraDeviceId = cameraId;
      if (environment.isCordova && device && (device.manufacturer === "Google") && (device.model.startsWith("Pixel 3"))) {
        params.constraints = {
          video: {
            height: {
              ideal: 640,
              max: 640,
              min: 640
            },
            width: {
              ideal: 368,
              max: 368,
              min: 368
            }
          }
        };
      } else {
        params.resolution = resolution;  // see https://github.com/jitsi/lib-jitsi-meet/blob/master/service/RTC/Resolutions.js
        params.constraints = {
          video: {
            height: {
              ideal: 720,
              max: 720,
              min: 180
            },
            width: {
              ideal: 1280,
              max: 1280,
              min: 320
            }
          }
        };
      }
    } else {
      devices = devices.filter(v => v !== "video");
    }

    if (micId && devices.length > 0) {
      params.micDeviceId = micId;
    }
    this.logger.info("[JitsiService][buildLocalTrackConstraints]", JSON.stringify(params));

    this.currentLocalTrackConstraints = params;

    return params;
  }

  correctAudioOutput() {
    // correct audio output
    //
    if (CommonUtil.isOnNativeMobileDevice()) {
      if (!CommonUtil.isOnIOS()) {
        this.audioOutputService.correctAudioOutput();
      } else {
        // BLUETOOTH
        // iosDebug
        /*
        this.audioOutputService.isBluetoothHeadsetOrHeadphoneConnected().subscribe(available => {
          if (available) {
            this.audioOutputService.setInitialAudioOutput(AudioToggle.BLUETOOTH);
          } else {
            // WIRED
            this.audioOutputService.isWiredHeadsetOrHeadphoneConnected().subscribe(available => {
              if (available) {
                this.audioOutputService.setInitialAudioOutput(AudioToggle.EARPIECE);
              } else {
                this.audioOutputService.setInitialAudioOutput(AudioToggle.SPEAKER);
              }
            });
          }
        });
        */
      }
    // only for desktop
    } else if (!CommonUtil.isOnMobileDevice()) {
      const preferableAudioOutputId = this.getPreferableAudioOutputId();
      if (preferableAudioOutputId) {
        this.setAudioOutputDevice(preferableAudioOutputId);
      }
    }
  }

  startVideo(usePrevSelectedCamId = false, muteAudio?: boolean) {
    this.hangedUp = false;
    this.logger.info("[JitsiService][startVideo] this.hangedUp ", this.hangedUp, usePrevSelectedCamId);
    this.onConferenceJoin$.next(false);

    this.enabledVideo = true;

    this.correctAudioOutput();

    let devices = ["audio", "video"];
    let audioMutedStatus = false;
    if (this._localTracks$.value.length > 0) {
      // devices = ["video"];
      this._localTracks$.value.filter(v => v.type === "audio").forEach(track => {
        if (track.isMuted()) {
          audioMutedStatus = true;
        }
      });
    }

    let camDeviceId = (CommonUtil.isOnMobileDevice() || usePrevSelectedCamId) ? this.cameraId : this.getPreferableCameraId();
    let micDeviceId = CommonUtil.isOnMobileDevice() ? null : this.getPreferableMicId();

    this.logger.info("[JitsiService][startVideo]", this.localVideo, {usePrevSelectedCamId, _localTracks: this._localTracks$.value, devices, camDeviceId, micDeviceId});

    if (CommonUtil.isOnIOS()) {
      this.iOSRTCPluginRegisterGlobals();
    }
    /*
    (async() => {
      try {
        if (this.localVideo && !this.localVideo.disposed) {
          this.localVideo.setEffect(undefined);
          this.localVideo.stopStream();
        }
      } catch(ex) {
        this.logger.info("stopEffect ex: ", ex);
      }
    })();
    */
    this.store.select(getHasWebcam).pipe(take(1)).subscribe(hasWebcam => {
      if (!hasWebcam) {
        devices = ["audio"];
      }
    });


    JitsiMeetJS.createLocalTracks(this.buildLocalTrackConstraints(devices, camDeviceId, micDeviceId))
      .then(tracks => {
        this.onLocalTracks(tracks, audioMutedStatus || muteAudio);
        if (!CommonUtil.isOnIOS()) {
          this.audioOutputService.correctAudioOutput();
        }
      })
      .catch(error => {
        this.logger.error("[JitsiService][startVideo] error: ", error);

        const key = CommonUtil.findKey(ConstantsUtil.CONFERENCE_GUM_ERRORS, value => value === error.name);
        if (key) {
          this.logger.error("[JitsiService][startVideo] error key: ", key);
          this.handleGumErrors(key, error);
        } else {
          try {
            if (error.toString().indexOf("Could not start video source") > -1) {
              let mediaMuteStatus = this.getMediaMuteStatus(this.myUserId());
              this.logger.info("[JitsiService][startVideo] error - attemptingaudioonly, mediaMuteStatus ", mediaMuteStatus);

              this.handleGumErrors("GENERAL_MEDIA_ERROR", {message: "Could not start video source"});
              if ((!!mediaMuteStatus && (mediaMuteStatus.audioStatus !== "muted")) || (!mediaMuteStatus)) {
                this.startAudio();
              }
              this.broadcaster.broadcast("localVideoFailed");
            } else if (error.toString().indexOf("Permission denied") > -1) {
              this.logger.error("[JitsiService][startVideo] error Permission denied -> startAudio: ");
              this.startAudio();
              this.handleGumErrors("GENERAL_MEDIA_ERROR", { message: "Could not start video source" });
            }

          } catch (er) {
            this.logger.error("[JitsiService][startVideo] error handling er: ", er);
          }
        }
      });

    // TypeError: undefined is not an object (evaluating 'i.getSettings')
  }

  switchCameraInScreenshare(): Observable<any> {
    const response = new Subject();

    this.logger.info("[JitsiService][switchCameraInScreenshare] old presenter disposed");
    setTimeout(async () => {
      try {
        this.logger.info("enable presenter mode - camId: ", this.cameraId);
        this.switchCamera();
      } catch (err) {
        this.logger.error("Failed to apply the presenter effect", err);
      }
      response.next(true);
    }, 100);
    return response.asObservable().pipe(take(1));
  }

  switchCamera() {
    if (CommonUtil.isOnIOS()) {
      this.iOSRTCPluginRegisterGlobals();
    }

    this.logger.info("[JitsiService][switchCamera]", this.cameraId, this.localVideo);

    let audioMutedStatus = false;
    // block very fast switch
    if (this.isSwitchCameraInProgress) {
      console.warn("[JitsiService][switchCamera] cannot switch now, switch already in progress");
      return;
    }
    if (this.resetState) {
      clearTimeout(this.resetState);
    }
    this.isSwitchCameraInProgress = true;
    this.resetState = setTimeout(() => {
      this.isSwitchCameraInProgress = false;
    }, 2000);
    //

    if (this._localTracks$.value.length > 0) {
      // this.logger.info("[JitsiService][switchCamera] this._localTracks: ", this._localTracks$.value);
      this._localTracks$.value.filter(v => v.type === "audio").forEach(track => {
        if (track.isMuted()) {
          audioMutedStatus = true;
        }
      });
    }
    // switch camera flow for Google Pixel devices
    if (environment.isCordova) {
      this.localVideo.dispose().then(() => {
        let newConstraints = this.buildLocalTrackConstraints(["video"]);
        newConstraints.cameraDeviceId = this.cameraId;
        this.logger.info("[JitsiService][switchCamera] newConstraints: ", newConstraints);

        JitsiMeetJS.createLocalTracks(newConstraints, this.cameraId)
        .then((tracks) => {
          this.onLocalTracks(tracks, audioMutedStatus);
        })
        .catch(error => {
          this.broadcaster.broadcast("disableVideoButton", false);
          this.logger.error("[JitsiService][switchCamera] JitsiMeetJS.createLocalTracks", error);
          const key = CommonUtil.findKey(ConstantsUtil.CONFERENCE_GUM_ERRORS, value => value === error.name);
          if (key) {
            this.handleGumErrors(key, error);
          }
          this.isSwitchCameraInProgress = false;
        });
      });
    } else {
      // keep original flow for other devices
      JitsiMeetJS.createLocalTracks(this.buildLocalTrackConstraints(["video"], this.cameraId))
      .then((tracks) => {
        this.onLocalTracks(tracks, audioMutedStatus);
      })
      .catch(error => {
        this.broadcaster.broadcast("disableVideoButton", false);
        this.logger.error("[JitsiService][switchCamera] JitsiMeetJS.createLocalTracks", error);
        const key = CommonUtil.findKey(ConstantsUtil.CONFERENCE_GUM_ERRORS, value => value === error.name);
        if (key) {
          this.handleGumErrors(key, error);
        }
        this.isSwitchCameraInProgress = false;
      });
    }
  }

  changeMediaDevices(cameraId: string, micId: string, outputDeviceId?: string): Observable<any> {
    const response = new Subject();
    this.logger.info("[JitsiService][changeMediaDevices]", cameraId, micId);

    let hasWebcam = false;
    this.store.select(getHasWebcam).pipe(take(1)).subscribe(v => {
      hasWebcam = v;
    });
    const isScreenSharingActive = this.currentLocalTrackConstraints?.devices.includes("desktop");
    const isAudioActive = this.currentLocalTrackConstraints?.devices.includes("audio");
    if (hasWebcam && this.enabledVideo && !isScreenSharingActive) {
      this.startVideo();
    } else {
      this.startAudio();
    }
    if (isAudioActive && outputDeviceId) {
      this.setAudioOutputDevice(outputDeviceId);
    }

    return response.asObservable().pipe(take(1));
  }

  removeEffect() {
    // console.warn("[jitsiService][removeEffect]: ", this.localVideo);
    if (this.localVideo && !this.localVideo._setEffectInProgress) {
      try {
        (async () => {
          await this.localVideo.setEffect(undefined);
        })();
      } catch (error) {
        this.logger.info("removeEffect error: ", error);
      }
    }
  }

  stopVideoStream() {
    if (this.localVideo && !this.localVideo._stopStreamInProgress) {
      try {
        (async () => {
          this.logger.info("jitsi-service-sharescreen3 stopVideoStream ", this.localVideo);
          await this.localVideo.stopStream();
        })();
      } catch (error) {
        console.warn("[jitsiService][stopVideoStream] error: ", error);
      }
    }
  }

  private disposeAndRemoveExistingVideoTrack() {
    this.logger.info("[JitsiService][disposeAndRemoveExistingVideoTrack]");
    this._localTracks$.value.forEach(track => {
      if (track.getType() === "video") {
        this.disposeAndRemoveExistingTrack(track);
      }
    });
  }

  private disposeAndRemoveExistingTrack(track) {
    this.logger.info("[JitsiService][disposeAndRemoveExistingTrack]", track);
    this.disposeAndRemoveTracks([track]).subscribe(() => {
      this.setLocalTracks(this._localTracks$.value.filter(t => t.id !== track.id));
    });
  }

  disposeAndRemoveAllExistingTracks() {
    if (this.room) {
      this.logger.info("[JitsiService][disposeAndRemoveAllExistingTracks]", this._localTracks$.value);
      this.disposeAndRemoveTracks([...this._localTracks$.value, this.localVideo]).subscribe(() => {
          this.setLocalTracks([]);
      });
    }

  }

  shareScreen(screenOnly?: boolean) {
    this.logger.info("jitsiService shareScreen this.room: ", this.room, screenOnly);
    this.hangedUp = false;
    this.logger.info("[JitsiService][shareScreen] this.hangedUp ", this.hangedUp);
    this.onConferenceJoin$.next(false);

    this.logger.info("[JitsiService][shareScreen]");

    let devices = ["desktop"];
    let conferenceType = "audio";
    this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
      conferenceType = type;
      if (type?.startsWith("screen")) {
        devices = ["desktop"];
      } else {
        this.shouldStartScreen = true;
      }
    });
    if (screenOnly) {
      devices = ["desktop"];
    }

    let audioMutedStatus = false;
    if (this._localTracks$.value.length > 0) {
      this._localTracks$.value.filter(v => v.type === "audio").forEach(track => {
        if (track.isMuted()) {
          audioMutedStatus = true;
        }
      });
    }
    if (conferenceType === "screen" && !this.shouldStartScreen) {
      devices = ["audio"];
      audioMutedStatus = true;
    }

    if (!audioMutedStatus) {
      this.store.dispatch(new ConferenceUnMuteAudio());
    }
    // do not mute video as the here we may not have a new source selected - so keep current video
    // this.store.dispatch(new ConferenceMuteVideo());
    if (CommonUtil.isOnIOS()) {
      this.iOSRTCPluginRegisterGlobals();
    }

    const micDeviceId = CommonUtil.isOnMobileDevice() ? null : this.getPreferableMicId();

    this.logger.info("[JitsiService][shareScreen3]", devices, screenOnly, micDeviceId, this.room, this.activeTarget, this.hangedUp);
    const newJitsiConstraints = this.buildLocalTrackConstraints(devices, null, micDeviceId);
      JitsiMeetJS.createLocalTracks(newJitsiConstraints)
      .then(async tracks => {

        this.logger.info("[JitsiService][shareScreen3tracks] created tracks - hangedUp: ", tracks, this.hangedUp, this.room, screenOnly);

          let tracks2Process = tracks.filter(t => t.type === "video");
          const desktopAudio = tracks.filter(t => t.type === "audio");
          if (!!desktopAudio && (desktopAudio.length > 0)) {
            this.logger.info("[JitsiService][shareScreen3tracks] hasAudio: ", desktopAudio);
            this.localDesktopAudio = desktopAudio[0];
            if (!!this.localAudio) {
              this._mixerEffect = new AudioMixerEffect(this.localDesktopAudio);
              await this.localAudio.setEffect(this._mixerEffect);
            }

          }
          this.sendScreenSharingCommand(this.displayName, this.myUserId());
            this.onLocalTracks(tracks2Process, audioMutedStatus, !this.shouldStartScreen);

          if (this.shouldStartScreen) {
            this.startScreenSharingCommand();
          }
          // now we actually have a new source stream - so now we set the id
          const xStreamId = /chrome/i.test(navigator.userAgent.toLowerCase()) ? "chrome" : "firefox";
          this.store.dispatch(new SetStreamId(xStreamId));

      }).catch(async (error) => {
        const key = CommonUtil.findKey(ConstantsUtil.CONFERENCE_GUM_ERRORS, value => value === error.name);
        // handle error from start screenshare
        this.logger.error("[JitsiService][shareScreen] error", error);
        if (error && error.name && error.message && error.message === "gum.screensharing_user_canceled" && error.name === "gum.electron_desktop_picker_error") {
          let hkey = "CHROME_EXTENSION_USER_CANCELED";
          let herror = {
            name: "gum.screensharing_user_canceled",
            message: "Permission denied"
          };

          this.handleGumErrors(hkey, herror);

        } else if (error && error.name && error.message && error.name === "gum.screensharing_user_canceled" && error.message === "getDisplayMedia requires transient activation from a user gesture.") {
          // SCREEN SHARING FLOW FIREFOX
          let style: any = {
            width: "440px",
            minHeight: "250px",
          };
          const { ConferenceDialogComponent } = await import(
            "../shared/components/dialogs/conference-confirmation/conference-confirmation.component");
          this.dialog.open(ConferenceDialogComponent, Object.assign({
              backdropClass: "vnctalk-form-backdrop",
              panelClass: "vnctalk-form-panel",
              disableClose: true,
              data: {action: "start_screenshare"},
              autoFocus: true
             }, style)).afterClosed().pipe(take(1)).subscribe(data => {
              if (data && data.sharescreen) {
                this.shareScreen(true);
              } else {
                this.store.dispatch(new SetScreenSharingRequestStatus(false));
              }
             });

        } else {
          if (key) {
            this.handleGumErrors(key, error);
          }
        }
      });




  }



  private iOSRTCPluginRegisterGlobals() {
    // iOS 14.3+ fix https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/618
    if (environment.isCordova && cordova && cordova.plugins && cordova.plugins.iosrtc
      && typeof device !== "undefined" && device.platform && (device.platform.toLowerCase() === "ios") && (parseInt(device.version) < 16 )) {
      this.logger.info("[JitsiService][startVideo] registerGlobals");

      cordova.plugins.iosrtc.registerGlobals();

      // Prevent WebRTC-adapter to overide navigator.mediaDevices after shim is applied since ios 14.3
      // TODO: remove after upgraded to 6.0.17 https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/618#issuecomment-760170969
      Object.freeze(navigator.mediaDevices);
      // cordova.plugins.iosrtc.debug.enable('*', true);
    }
  }

  unshareScreen() {
    this.logger.info("[JitsiService][unshareScreen] - desktopAudio: ", this.localDesktopAudio);
    this.setUnshareScreen();
    this.stopScreenSharingCommand();
    this.stopScreenSharing();
  }

  unshareScreenWithoutStarting() {
    this.logger.info("[JitsiService][unshareScreenWithoutStarting]");
    this.setUnshareScreen();
    this.stopScreenSharingCommand();
  }

  private startScreenSharingCommand() {
    this.sendData(ConstantsUtil.COMMANDS.toggleShareScreen, ConstantsUtil.SCREEN_SHARE_STARTED_COMMAND_DATA);
  }

  private stopScreenSharingCommand() {
    this.sendData(ConstantsUtil.COMMANDS.toggleShareScreen, ConstantsUtil.SCREEN_SHARE_STOPPED_COMMAND_DATA);
  }

  private setScreenSharingSession() {
    this.sendData(ConstantsUtil.COMMANDS.setShareScreenType, this.myUserId());
  }

  private removeScreenSharingSession() {
    this.sendData(ConstantsUtil.COMMANDS.removeShareScreenType, this.myUserId());
  }

  private stopScreenSharing(hangup?: boolean) {
    this.logger.info("[JitsiService][stopScreenSharingX1]", hangup, this.localVideo, this.room);
    this.broadcaster.broadcast("stopScreenSharingX1");
    if (this.localVideo) {

      this.notificationService.openSnackBarWithTranslation("SCREEN_SHARE_IS_DISABLED", {}, 5000);
      _stopScreenSharingQueue.enqueue(onFinish => {
        this.sendData(ConstantsUtil.COMMANDS.toggleShareScreen, ConstantsUtil.SCREEN_SHARE_STOPPED_COMMAND_DATA);

        this._startAudioAfterScreenShareTimerId = setTimeout(() => {
          this.logger.info("[JitsiService][stopScreenSharingX2] start audio/video", this.enabledVideo, this.hangedUp, this.room);
          this.logger.info("[JitsiService][stopScreenSharingX2] this._localTracks$.value ", this._localTracks$.value);
          if (!!this.room) {
            // it can be a case whe quickly stop scree share and stop call,
            // so need to ignore this
            this._startAudioAfterScreenShareTimerId = null;
            this.store.dispatch(new ConferenceMuteVideo());
            if ((this.enabledVideo && (this._localTracks$.value.length === 2)) || (!this.enabledVideo && (this._localTracks$.value.length === 1))) {
              console.warn("[JitsiService][stopScreenSharingX2] start audio/video - skip - tracks exist; this.localVideo: ", this.localVideo);
            } else {
              // this.store.dispatch(new ConferenceMuteVideo());
              if (!this.enabledVideo) {
                this.store.select(getJitsiConferenceParticipants).pipe(take(1)).subscribe(participants => {
                  let participant = participants ? participants.find(p => p.id === this.myUserId()) : null;
                  if (participant) {
                    const enableAudio = !(participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted || participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
                    if (enableAudio) {
                      this.startAudio();
                    }
                  }
                });

              } else {
                this.startVideo(true);
              }
            }
          }
          onFinish();
        }, 1100);
      });

    }
    // this.setMediaStatus(this.myUserId(), "video", ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);


  }

  getParticipantById(id: string) {
    if (id === "fake123") {
      return this.fakeParticipant;
    }
    return this.room && this.room.getParticipantById(id);
  }

  getDisplayName(participantId: string) {
    if (participantId === this.myUserId()) {
      let displaName = "me";
      this.translate.get("ME").pipe(take(1)).subscribe(content => {
        displaName = content;
      });
      return displaName;
    }
    const participant = this.getParticipantById(participantId);
    if (!participant) {
      return null;
    }
    return participant._displayName;
  }

  getConnectionStatus(participantId: string) {
    const participant = this.getParticipantById(participantId);
    this.logger.info("[JitsiService][getConnectionStatus]", participantId, participant);
    if (!participant) {
      return null;
    }
    return participant._connectionStatus;
  }

  setFullScreenParticipantId(participantId: string) {
    if (this.fullScreenParticipantId === participantId) {
      return;
    }

    this.logger.info("[JitsiService][setFullScreenParticipantId][largeVideo]", this.getDisplayName(participantId), participantId);

    // detach old participant video in full screen
    this.detachLargeVideo(this.fullScreenParticipantId);

    if (participantId && this.room) {

      // request hi res stream for full screen video
      if (this.currentView !== "tile") {
        if (participantId !== this.myUserId()) {
          this.selectHiResUsers([participantId]);
        }
      }

      // this._attachLargeVideoIfNotAttached(participantId);

      this.store.dispatch(new ConferenceSetFullScreenParticipant(participantId));
    }

    this.fullScreenParticipantId = participantId;
  }

  public detachLargeVideo(participantId) {
    if (!participantId) {
      return;
    }
    if (!!document.getElementById("largeVideo")) {
      this.logger.info("[JitsiService][detachLargeVideo]", this.myUserId(), participantId);
      let largeVideoElement = document.getElementById("largeVideo");
      if (this.myUserId() === participantId) {
        this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
          this.logger.info("[JitsiService][detachLargeVideo]", participantId, largeVideoElement, tracks);
          this._detachLargeVideo(largeVideoElement, tracks);
        });
      } else {
        this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
          this._detachLargeVideo(largeVideoElement, tracks);
          this.logger.info("[JitsiService][detachLargeVideo]", participantId, largeVideoElement, tracks);
        });
      }
      largeVideoElement = null;
    }

  }

  public detachFullVideo(participantId) {
    if (!participantId) {
      return;
    }
    if (!!document.getElementById(`participantFullVideo${participantId}`)) {
      this.logger.info("[JitsiService][detachLargeVideo]", this.myUserId(), participantId);
      let largeVideoElement = document.getElementById(`participantFullVideo${participantId}`);
      if (this.myUserId() === participantId) {
        this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
          this.logger.info("[JitsiService][detachLargeVideo]", participantId, largeVideoElement, tracks);
          this._detachLargeVideo(largeVideoElement, tracks);
        });
      } else {
        this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
          this._detachLargeVideo(largeVideoElement, tracks);
          this.logger.info("[JitsiService][detachLargeVideo]", participantId, largeVideoElement, tracks);
        });
      }
      largeVideoElement = null;
    }

  }

  private _detachLargeVideo(largeVideoElement, tracks) {
    for (let track of tracks) {
      if (track.getType() === "video") {
        this.logger.info("[_detachLargeVideo] before detach", track);
        track.detach(largeVideoElement);
        this.logger.info("[_detachLargeVideo] after detach", track);
      }
    }
  }

  private _attachLargeVideoIfNotAttached(participantId) {
    if (!participantId) {
      return;
    }
    this.attachedVideo = false;
    setTimeout(() => {
      this.logger.info("[JitsiService][_attachLargeVideoIfNotAttached][largeVideo]", this.getDisplayName(participantId), participantId, document.getElementById("largeVideo"));
      if (!!document.getElementById("largeVideo")) {
        this.logger.info("[JitsiService][_attachLargeVideoIfNotAttached][largeVideo]", this.getDisplayName(participantId), participantId);
        this.attachedVideo = true;
        let largeVideoElement = document.getElementById("largeVideo");
        if (this.myUserId() === participantId) {
          this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
            this._attachLargeVideo(participantId, largeVideoElement, tracks);
            largeVideoElement = null;
          });
        } else {
          this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
            this._attachLargeVideo(participantId, largeVideoElement, tracks);
            largeVideoElement = null;
          });
        }
      }
    }, 1000);
  }

  private _attachLargeVideo(participantId, largeVideoElement, tracks) {
    for (let track of tracks) {
      if (track.getType() === "video") {
        track.attach(largeVideoElement);

        this.logger.info("[JitsiService][attachLargeVideo] attached", participantId, "track muted: ", track.isMuted(), this.restartWorkArounds);

        if (!track.isMuted()) {
          largeVideoElement.removeAttribute("style");
        } else {
          largeVideoElement.style.display = "none";
        }
        if (CommonUtil.isOnAndroid() && (this.restartWorkArounds === 0)) {
          this.logger.info("[jitsiservice][attachLargeVideo] _restartMediaSessions ", this.restartWorkArounds);
          this.maybeRestartWorkAround();
        }
      }
    }
  }

  private _isTrackAttachedToLargeVideo(participantId) {
    let alreadyAttached = false;
    let participantsTracks;

    if (this.myUserId() === participantId) {
      this.getLocalTracks().pipe(take(1)).subscribe(tracks => {
        participantsTracks = tracks;
      });
    } else {
      this.getTracksForParticipant(participantId).pipe(take(1)).subscribe(tracks => {
        participantsTracks = tracks;
      });
    }

    for (let track of participantsTracks) {
      if (track.getType() === "video") {
        if (track.containers.length > 0) {
          track.containers.forEach(c => {
            alreadyAttached = c.id === "largeVideo";
          });
        }
      }
    }

    this.logger.info("[JitsiService][_isTrackAttachedToLargeVideo]", alreadyAttached, participantId);
    return alreadyAttached;
  }

  public attachLargeVideo(participantId) {
    let largeVideoElement = document.getElementById("largeVideo");
      if (largeVideoElement) {
        clearInterval(this.checkFullScreen);

        if (this.myUserId() === participantId) {
          this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
            this._attachLargeVideo(participantId, largeVideoElement, tracks);
            largeVideoElement = null;
          });
        } else {
          this.getTracksForParticipant(participantId).pipe(take(1)).subscribe((tracks) => {
            this._attachLargeVideo(participantId, largeVideoElement, tracks);
            largeVideoElement = null;
          });
        }
      }
  }

  muteAudio(remoteParticipantId?: string) {
    this.logger.info("[JitsiService][muteAudio]", remoteParticipantId, ", ", this.myUserId());

    if (remoteParticipantId) {
      this.setMediaStatus(remoteParticipantId, ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
    } else {
      this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
    }
    if (this.localAudio) {
      this.localAudioMuted = true;
      this.store.dispatch(new ConferenceMuteAudio());
      this.localAudio.mute();
      this.logger.info("[JitsiService][muteAudio] localAudio", this.localAudio);

      this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
        this.logger.info("[JitsiService][muteAudio] getLocalTracks", tracks);
        for (let track of tracks) {
          if (track.type === "audio") {
            track.mute();
            this.logger.info("[JitsiService][muteAudio] MUTED track", track);
            // break;
          }
        }
      });
    }
  }

  unmuteAudio(remoteParticipantId?: string, notifyOnly?: boolean): boolean {
    this.logger.info("[JitsiService][unmuteAudio]", notifyOnly, remoteParticipantId, ", ", this.myUserId());

    let participant = this.getConferenceParticipants().find(p => p.id === (remoteParticipantId ? remoteParticipantId : this.myUserId()));
    if (!this.isModeratorOrOwner() && !remoteParticipantId && this.room && participant && participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator) {
      this.logger.info("[JitsiService][unmuteAudio] cannot unmute because you are muted by moderator");
      return false;
    }

    if (this.room) {
      this.setMediaStatus(remoteParticipantId ? remoteParticipantId : this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
    }
    if (!remoteParticipantId && this.localAudio) {
      this.localAudio.unmute();
      this.localAudioMuted = false;
      this.logger.info("[JitsiService][unmuteAudio] UNMUTED localAudio track");

      this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
        for (let track of tracks) {
          if (track.type === "audio") {
            this.logger.info("[JitsiService][unmuteAudio] UNMUTED track", track);
            track.unmute();
            // break;
          }
        }
      });
    }
    return true;
  }

  private isVideoTrackAvailable() {
    let available = false;
    if (this._localTracks$.value.length > 0) {
      for (let track of this._localTracks$.value) {
        if (track.type === "video") {
          available = true;
        }
      }
    }

    this.logger.info("[JitsiService][isVideoTrackAvailable]", available);

    return available;
  }

  turnOffVideo(remoteParticipantId?: string) {
    if (CommonUtil.isOnIOS()) {
      this.iOSRTCPluginRegisterGlobals();
    }

    this.logger.info("[JitsiService-turnOffVideo]",
      {remoteParticipantId, localTracks: this._localTracks$.value});

    if (remoteParticipantId) {
      this.setMediaStatus(remoteParticipantId, ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
    } else {
      this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted);
    }

    this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
      for (let track of tracks.filter(t => t.type === "video" && t.videoType === "camera")) {
        track.mute();
        this.store.dispatch(new ConferenceMuteVideo());
      }
    });

    this.enabledVideo = false;
  }

  getMyData() {
    return this.getConferenceParticipants().find(p => p.id === this.myUserId());
  }

  enableLocalTracksIOS() {
    if (!this.room) {
      return;
    }
    this.logger.info("[JitsiService][enableLocalTracksIOS] called: ", this.myUserId());
    this.checkVideoElements();
    if ((CommonUtil.isOnIOS() || CommonUtil.isOnIpad())) {
      this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
        const hasVideo = tracks.length > 1;
        if (!!tracks[0]) {
          this.logger.info("[JitsiService][enableLocalTracksIOS] first track: ", tracks[0].type, tracks[0].track.enabled, tracks[0].track.muted, tracks);
        }

        tracks.forEach(track => {
          // this.logger.info("[JitsiService][enableLocalTracksIOS] track: ", track.type, track.track.enabled, track.track.muted, track);
          if (track.track && track.track.enabled && track.track.muted) {
            track.mute().then(() => {
              track.unmute().then(() => {
                this.checkLocalTracks(track.type);
                this.checkVideoElements();
              }).catch(err => {
                this.logger.info("[JitsiService][enableLocalTracksIOS] trackUnMute error: ", err, track);
              });
            }).catch(err => {
              this.logger.info("[JitsiService][enableLocalTracksIOS] trackMute error: ", err, track);
              // this.checkLocalTracks(track.type);
              this.localAudio.dispose().then(() => {
                setTimeout(() => {
                  this.startAudio();
                }, 300);
              }).catch(err => {
                this.logger.info("[JitsiService][enableLocalTracksIOS] trackMute dispose error: ", err);
              });
            });
          }
        });
      });
    }
  }

  checkLocalTracks(trackType) {
    this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
      tracks.forEach(track => {
        if (track.type === trackType) {
          this.logger.info("[JitsiService][enableLocalTracksIOS] checkLocalTrack: ", track.type, track.track.readyState, track.track.enabled, track.track.muted, track);
          if (track.track.enabled && track.track.muted) {
            this.logger.info("[JitsiService][enableLocalTracksIOS] checkLocalTrack localAudio: ", this.localAudio);
            this.localAudio.dispose().then(() => {
              let newConstraints = this.buildLocalTrackConstraints(["audio"]);
              this.logger.info("[JitsiService][checkLocalTrack] newConstraints: ", newConstraints);
              JitsiMeetJS.createLocalTracks(newConstraints)
                .then((tracks) => {
                  this.onLocalTracks(tracks, false);
                })
                .catch(error => {
                  this.logger.error("[JitsiService][enableLocalTracksIOS] JitsiMeetJS.createLocalTracks", error);
                  const key = CommonUtil.findKey(ConstantsUtil.CONFERENCE_GUM_ERRORS, value => value === error.name);
                  if (key) {
                    this.handleGumErrors(key, error);
                  }
                });
            });

          }
        }
      });
    });
  }

  checkVideoElements() {
    const videoPreviewElement = document.getElementById(`participantVideo${this.myUserId()}`) as HTMLMediaElement | null;
    const participantPreviewVideo = document.getElementById("participantPreviewVideo") as HTMLMediaElement | null;
    if (!videoPreviewElement) {
      return;
    }
    if (!participantPreviewVideo) {
      return;
    }
    this.logger.info("[JitsiService][enableLocalTracksIOS] checkLocalTrack previewVideo: ", videoPreviewElement, videoPreviewElement.paused, participantPreviewVideo, participantPreviewVideo.paused);
    try {
      if (!!videoPreviewElement) {
        if (!!videoPreviewElement.error) {
          this.logger.error("[JitsiService][enableLocalTracksIOS] previewVideoPlay error1: ", videoPreviewElement.error);
        }
        videoPreviewElement.play();
      }
      if (!!participantPreviewVideo) {
        if (!!participantPreviewVideo.error) {
          this.logger.error("[JitsiService][enableLocalTracksIOS] previewVideoPlay error2: ", participantPreviewVideo.error);
        }
        participantPreviewVideo.play();
      }
    } catch (error) {
      this.logger.error("[JitsiService][enableLocalTracksIOS] previewVideoPlay error: ", error);
    }

  }


  turnOnVideo(remoteParticipantId?: string, notifyOnly?: boolean): boolean {
    this.logger.info("[JitsiService-turnOnVideo] remoteParticipantId:", notifyOnly, remoteParticipantId, this._localTracks$.value);

    let participant = this.getConferenceParticipants().find(p => p.id === (remoteParticipantId ? remoteParticipantId : this.myUserId()));
    if (!remoteParticipantId && this.room && participant && participant.videoStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator) {
      return false;
    }

    if (this.room) {
      this.setMediaStatus(remoteParticipantId ? remoteParticipantId : this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
    }

    this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
      if (tracks.filter(t => t.type === "video" && t.videoType === "camera").length === 0) {
        this.logger.info("[JitsiService][turnOnVideo] startVideo");
        this.startVideo();
      } else {
        for (let track of tracks.filter(t => t.type === "video" && t.videoType === "camera")) {
          if (this.isVideoTrackOfScreenShare(track)) {
            this.logger.info("[JitsiService][turnOnVideo] startVideo2");
            this.startVideo();
          } else {
            this.logger.info("[JitsiService][turnOnVideo] unmute");
            if (!track.disposed) {
              track.unmute();
            } else {
              try {
                this.removeEffect();
                this.stopVideoStream();
              } catch (error) {

              }

              this.startVideo();
            }

          }
          this.store.dispatch(new ConferenceUnMuteVideo());
        }
      }
    });

    this.enabledVideo = true;

    return true;
  }

  setUnshareScreen() {
    this.logger.info("[JitsiService][setUnshareScreen] ", this.localPresenterVideo, this.localVideo);
    this.store.dispatch(new SetScreenSharingRequestStatus(false));
    this.store.dispatch(new SetStreamId(""));
    this.store.dispatch(new ConferenceUnMuteAudio());
    this.store.dispatch(new ConferenceUnShareScreen());

    this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
      for (let track of tracks.filter(t => t.type === "video" && t.videoType === "desktop")) {
        track.mute();
        // this.store.dispatch(new ConferenceMuteVideo());
      }
    });


    if (!!this.localDesktopAudio && !!this.localAudio) {
      try {
        this.localAudio.setEffect(undefined);
        this.localDesktopAudio._stopStreamEffect();
        this.localDesktopAudio.dispose().then(() => {
          this.localDesktopAudio = null;
        });
      } catch (ex) {
        console.warn("localDesktopAudio stop ex: ", ex);
      }
    }
    // mute the presenter track if it exists.
    if (this.localPresenterVideo) {
      try {
        this.localVideo._stopStreamEffect();
        this.localVideo.setEffect(undefined);
        this.logger.info("jitsi-service-sharescreen3 setUnshareScreen stopStream", this.localPresenterVideo, this.localVideo);
        this.localVideo.stopStream();

        this.localPresenterVideo.stopStream();
      } catch(ex) {
        console.warn("localPresenterVideo stop ex: ", ex);
      }
      this.localPresenterVideo.dispose().then(() => {
        this.localPresenterVideo = null;
      });
    }
    if (document.getElementById("presenterVideo")) {
      (<HTMLVideoElement> document.getElementById("presenterVideo")).srcObject = null;
    }
    if (!!document.getElementById("presenterScreenFull")) {
      document.getElementById("presenterScreenFull").style.visibility = "hidden";
    }
    if (!!document.getElementById("presenterVideoFull")) {
      document.getElementById("presenterVideoFull").style.visibility = "hidden";
    }
  }

  turnONwhiteboard(remoteParticipantId?: string) {
    this.logger.info("[JitsiService][turnONwhiteboard]", remoteParticipantId);
    if (!!this.myUserId()) {
      let controlMessage;
      this.whiteboardMode$.next("");
      if (!!this.onWhiteboardOpen$.value) {
        controlMessage = {
          EventType: 1002,
          userID: this.myUserId(),
          Message: "Close Whiteboard!!",
          whiteboardId: this.whiteboardId,
          FromParticipantID: this.myUserId()
        };
        this.whiteboardData$.next({});
        this.onWhiteboardOpen$.next(false);
      } else {
        controlMessage = {
          EventType: 1001,
          userID: this.myUserId(),
          Message: "Open Whiteboard!!",
          whiteboardId: this.whiteboardId,
          FromParticipantID: this.myUserId()
        };
        this.whiteboardData$.next({whiteboardId: this.whiteboardId, participantId: this.myUserId()});
        this.onWhiteboardOpen$.next(true);

      }
      let message = JSON.stringify(controlMessage);
      this.room.sendTextMessage(message);
    }
  }

  closeWhiteboard(remoteParticipantId?: string) {
    this.logger.info("[JitsiService][closeWhiteboard]", remoteParticipantId);
    if (!!this.myUserId()) {
      let controlMessage;
      this.whiteboardMode$.next("");
      controlMessage = {
        EventType: 1002,
        userID: this.myUserId(),
        Message: "Close Whiteboard!!",
        whiteboardId: this.whiteboardId,
        FromParticipantID: this.myUserId()
      };
      this.whiteboardData$.next({});
      this.onWhiteboardOpen$.next(false);
      let message = JSON.stringify(controlMessage);
      this.room.sendTextMessage(message);
    }
  }

  sendHandRequest() {
    if (!!this.myUserId()) {
      const participantId = this.myUserId();
      const message = {
        EventType: 1003,
        userID: this.myUserId(),
        Message: "USER_WANTS_TO_SPEAK",
        roomName: this.roomName,
        FromParticipantID: participantId
      };
      this.room.sendTextMessage(JSON.stringify(message));
    }
  }


  allowDenyHand(action, id) {
    this.closeRaising(id);
    let message = {
      EventType: 1004,
      userID: id,
      allow: true,
      Message: "ALLOWED_TEMPORARY_SPEAKER",
      roomName: this.roomName,
      FromParticipantID: id

    };
    if (action !== "allow") {
      message.Message = "REJECTED_TEMPORARY_SPEAKER";
      message.allow = false;
    }
    this.room.sendTextMessage( JSON.stringify(message));
  }

  closeRaising(id) {
    this.logger.info("[closeRaising]");
    this.raisedNotifications$.next(this.raisedNotifications$.value.filter(v => v.participantId !== id));
  }

  togglePinParticipant(isPinned: boolean, participantId: string, fromView = "thumbnail") {
    let message = {
      action: "TOGGLE_PIN",
      participantId: participantId,
      isPinned: isPinned,
      fromView: fromView
    };
    this.pinUnpinParticipant(isPinned ? participantId : null);
    this.room.sendTextMessage( JSON.stringify(message));
  }

  pinUnpinParticipant(participantId) {
    this.pinnedParticipant$.next(participantId);
  }

  private isVideoTrackOfScreenShare(track) {
    return JitsiService.VIDEO_TRACK_SCREEN_SHARE_TYPES.includes(track.videoType);
  }

  selectParticipant(participantId: string) {
    this.logger.info("[JitsiService][selectParticipant]", participantId);

    this.store.dispatch(new ConferenceSelectParticipant(participantId));
  }

  getCallDuration(): Observable<string> {
    return this._callDuration.asObservable();
  }

  getRecordDuration(): Observable<string> {
    return this._recordDuration.asObservable();
  }

  private setUpCallDurationTimer() {
    const start = new Date().getTime();

    this.invalidateCallDurationTimer();

    this.logger.info("[JitsiService][setUpCallDurationTimer]");

    this.callDurationTimer$ = interval(1000).subscribe(() => {
      if (!this.room) {
        this.invalidateCallDurationTimer();
        return;
      }
      let now = new Date().getTime();
      const duration = dayjs(now - start).format("HH:mm:ss");
      const isoDuration = dayjs(now - start).toISOString().split("T")[1].split(".")[0];
      this.logger.info("[_callDuration]", now, start, duration, isoDuration);
      localStorage.setItem("duration", isoDuration);
      this._callDuration.next(isoDuration);
      if (!!navigator.proximity) {
        navigator.proximity.getProximityState(proximityState => {
          // iosDebug
          if (!CommonUtil.isOnIOS()) {
            this.audioOutputService.setProximityState(proximityState);
          }
        });
      }
    });
  }

  private invalidateCallDurationTimer() {
    if (this.callDurationTimer$) {
      this.logger.info("[JitsiService][invalidateCallDurationTimer]");
      this.callDurationTimer$.unsubscribe();
      this.callDurationTimer$ = null;
    }
  }

  toggleFlipVideo() {
    this.isFlipped$.next(!this.isFlipped$.value);
  }

  getFlipStatus() {
    return this.isFlipped$.asObservable();
  }

  processLeaveConference() {
    this.logger.info("[JitsiService][processLeaveConference]", this._localTracks$.value, ", ", this.localVideo);
    localStorage.setItem("startConferenceTarget", "");

    this.detachAllVideoTracks();
    this.isCamOn.next(false);
    this.screenSharePresenterParticipant$.next(null);

    this.cancelGUMProcesses([...this._localTracks$.value, this.localVideo]).finally(() => {
      this.logger.info("[JitsiService][cancelGUMProcesses]");
      this.disposeAndRemoveAllExistingTracks();

      this.room = null;
      this._room.next(false);
    });

    if (this.room && this.room.isJoined()) {
      try {
        this.room.off(JITSI_MEET.JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, this.receiverEndpointMessageListener.bind(this));
        this.room.leave().then(() => {
          this.logger.info("[JitsiService][leave] room success");
          this.disconnect();
        }).catch(error => {
          // The timeout for the confirmation about leaving the room expired
          this.logger.error("[JitsiService][processLeaveConference] leave room promise error", error);
          this.disconnect();
        });
      } catch (ex) {
        this.logger.sentryErrorLog("[JitsiService][processLeaveConference] leave room catch error", JSON.stringify(ex));
        this.logger.error("[JitsiService][processLeaveConference] leave room catch error", JSON.stringify(ex));
        this.disconnect();
      }
    } else {
      this.disconnect();
    }

    this.store.dispatch(new SetStreamId(""));
    this.store.dispatch(new ConferenceUnMuteAudio());
    this.store.dispatch(new ToggleE2EE(false));
    this.store.dispatch(new SetSpeakingParticipant(null));

    this.broadcaster.broadcast("closePasswordDialog");
    this.hideLobbyScreen();
    this.blurBackground$.next(false);
    this.nextAction = "";
    this.joinedWithPassword = "";
    this.recordingName = "";
    this.liveStreamTarget = "";
    this.isRecording = false;
    this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
    this.displayedVincent = false;
    this.startedRecording = false;
    this.participantsList = [];
    this.usedExternalPass = false;
    this.restartWorkArounds = 0;
    this.vp8restartCount = 0;

    if (this.recordSessionId) {
      // this.logger.info("[JitsiService][processleave] stopRecording");
      if (!!this.room && this.room.isModerator()) {
        this.stopRecordingConference(this.recordSessionId);
      }
      this.recordSessionId = null;
    }

    this.isFlipped$.next(true);
    this.participantsInformation$.next([]);
    this.lastSpeakingParticipant$.next(null);
    this.fullParticipantByModerator$.next(null);
    this.pinnedParticipant$.next(null);

    this.speakingParticipant$.next({});
    this.raisedNotifications$.next([]);

    this.broadcaster.broadcast("resetFullPreviewParticipant");
    this.mutedForMe$.next([]);

    Object.keys(this.waitForAudio).forEach(i => {
      clearInterval(this.waitForAudio[i]);
    });
    this.waitForAudio = {};
    Object.keys(this.waitForVideo).forEach(i => {
      clearInterval(this.waitForVideo[i]);
    });
    this.waitForVideo = {};

    this.participantsStats = {};
    this.participantsConnStatus = {};

    this.store.select(getFrontCameraId).pipe(take(1)).subscribe(res => { // Reset to front camera
      if (res) {
        this.logger.info("[JitsiService][processLeaveConference] reset to front camera");
        this.setCameraId(res);
      }
    });

    this.invalidateCallDurationTimer();
    this.stopListeningTrackAudioLevelChanges();
    this.invalidateNetworkChangesListener();
    this.invalidateParticipantsConnStatusTimer();
    this.setFullScreenParticipantId(null);
    this.screenSharePresenterParticipant$.next(null);
    this.selectParticipant(null);
    this.setRemoteTracks([]);
    this.setLocalTracks([]);
    this.enabledVideo = false;
    setTimeout(() => {
      this.logger.info("[JitsiService][processLeaveConference] end2", this.localPresenterVideo, this.room);
      if (!!this.localPresenterVideo && !this.room) {
        console.warn("[JitsiService][processLeaveConference] sharescreen3 stopStream catch camrelease!");
        try {
        this.localPresenterVideo.stopStream();
        } catch(ex) {
          console.warn("localPresenterVideo stop ex: ", ex);
        }
        this.localPresenterVideo.dispose().then(() => {
          this.localPresenterVideo = null;
        });
      }
    }, 3000);
    // reset quality to configured value after call end
    this.store.select(getAppSettings).pipe(take(1)).subscribe(options => {
      if (typeof options.videoQuality === "undefined") {
        this.videoQuality$.next(VideoQuality.HIGH);
      } else {
        try {
          this.logger.info("got app option getAppSettingsVideo ", options.videoQuality, typeof options.videoQuality);
          const value = parseInt(options.videoQuality);
          switch (value) {
            case 180:
              this.videoQuality$.next(VideoQuality.LOW);
              break;
            case 240:
              this.videoQuality$.next(VideoQuality.MEDIUM);
              break;
            case 360:
              this.videoQuality$.next(VideoQuality.STANDARD);
              break;
            case 480:
              this.videoQuality$.next(VideoQuality.GOOD);
              break;
            case 540:
              this.videoQuality$.next(VideoQuality.BETTER);
              break;
            case 720:
              this.videoQuality$.next(VideoQuality.HIGH);
              break;
            default:
              this.videoQuality$.next(VideoQuality.HIGH);
              break;
          }
        } catch (error) {
          this.videoQuality$.next(VideoQuality.HIGH);
        }
      }
    });
    this.logger.info("[JitsiService][processLeaveConference] end", this.localPresenterVideo, this.localVideo, this._localTracks$.value);
  }

  leave(isBeforeUnloadEvent?: boolean) {

    this.logger.info("[JitsiService][leave]", this.localAudio, this.localVideo);
    localStorage.setItem("lsStartConferenceTarget", "");
    this.isRecording = false;
    this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
    this.store.dispatch(new SetNoiseSuppressionEnabled(false));
    this.getLocalTracks().pipe(take(1)).subscribe((tracks) => {
      const oldVideoTracks = tracks.filter(t => t.type === "video" && t.videoType === "camera");
      this.logger.info("effect? : oldVideoTracks ", oldVideoTracks);
      if (oldVideoTracks.length > 0) {
        oldVideoTracks.forEach(async oldVideoTrack => {
            try {
              // this.logger.info("effect? this.localVideo: ", this.localVideo, this._localTracks$.value);
              oldVideoTrack._stopStreamEffect();
              oldVideoTrack.setEffect(undefined);
              this.logger.info("jitsi-service-sharescreen3 leave stopStream oldVideoTrack", oldVideoTrack);
              oldVideoTrack.stopStream();
            } catch (ex) {
              this.logger.info("stopEffect ex: ", ex);
            }
          });
      }
    });
    (async() => {
      try {
        if (this.localVideo && !this.localVideo.disposed) {
          // this.logger.info("effect? this.localVideo: ", this.localVideo, this._localTracks$.value);
          this.localVideo._stopStreamEffect();
          this.localVideo.setEffect(undefined);
          this.logger.info("jitsi-service-sharescreen3 leave stopStream localVideo", this.localVideo);
          this.localVideo.stopStream();
        }
      } catch(ex) {
        this.logger.info("stopEffect ex: ", ex);
      }
      try {
        if (this.localAudio && !this.localAudio.disposed) {
          this.logger.info("effect? this.localAudio: ", this.localAudio);
          this.localAudio._stopStreamEffect();
          this.localAudio.setEffect(undefined);
          this.logger.info("jitsi-service-sharescreen3 leave stopStream localAudio", this.localAudio);
          this.localAudio.stopStream();
        }
      } catch(ex) {
        this.logger.info("stopEffect ex: ", ex);
      }
      try {
        if (this.localDesktopAudio && !this.localDesktopAudio.disposed) {
          this.localDesktopAudio.stopStream();
          await this.localDesktopAudio.dispose();
        }
      } catch (error) {
        this.logger.info("jitsi-service-sharescreen4 leave stopStream localDesktopAudio", this.localDesktopAudio, error);
      }
    })();

    this.hangedUp = true;
    this.logger.info("[JitsiService][leave] this.hangedUp ", this.hangedUp);
    this.onConferenceJoin$.next(false);
    this.translate.get("VNCTALK_VIDEO_CONFERENCE").pipe(take(1)).subscribe(text => {
      this.roomSubject$.next(text);
    });

    if (this.localPresenterVideo) {
      this.localPresenterVideo.stopStream();
      this.localPresenterVideo = null;
    }

    // stop running audio after screen share
    if (this._startAudioAfterScreenShareTimerId) {
      clearTimeout(this._startAudioAfterScreenShareTimerId);
      this._startAudioAfterScreenShareTimerId = null;
      this.logger.info("[JitsiService][leave] clearTimeout _startAudioAfterScreenShareTimerId");
    }

    const localTracks = this.room?.getLocalTracks();
    localTracks?.forEach(track => {
      this.logger.info("[JitsiService][leave] LocalTracks stopStream", track);
      track.stopStream();
    });
    // sometimes when start call and then stop in 5 sec, the cam is not released yet, do not know why...
    // so we do this workaround
    setTimeout(() => {
      this.logger.info("[JitsiService][leave]stopStream");
      localTracks?.forEach(async track => {
        this.logger.info("[JitsiService][leave] LocalTracks2 stopStream", track);
        track.stopStream();
        await track.dispose();
        this.logger.info("[JitsiService][leave] LocalTracks2 stopStream disposed ", track);
      });
    }, 2500);
    //
    if (this.tempLocalTracks) {
      this.tempLocalTracks.forEach(async track => {
        this.logger.info("[JitsiService][leave] tempLocalTracks stopStream", track);
        track.stopStream();
        await track.dispose();
        this.logger.info("[JitsiService][leave] tempLocalTracks disposed", track);
      });
      this.tempLocalTracks = null;
    }

    this.localAudio = null;
    this.localVideo = null;

    if (this.recordSessionId !== "") {
      // this.logger.info("[JitsiService][leave] stopRecording2 ", this.recordSessionId, this.activeTarget, this.currentParticipants, this.conversationAdmins);
      if ((this.lastActiveTarget.indexOf("@conference") === -1) && !!this.room && this.room.isModerator()) { // 1:1 case
        // this.logger.info("[JitsiService][leave] stopRecording 2 ", this.recordSessionId);
        this.stopRecordingConference(this.recordSessionId);
      }
      if (this.lastActiveTarget.indexOf("@conference") > -1) { // leave group call
        if (this.conversationAdmins.length === 0) {
          this.stopRecordingConference(this.recordSessionId);
        } else {
          let remainingModerator = false;
          for (let i = 0; i < this.conversationAdmins.length; i++) {
            if (this.currentParticipants.indexOf(this.conversationAdmins[i]) > -1) {
              remainingModerator = true;
            }
          }
          if (!remainingModerator) {
            this.stopRecordingConference(this.recordSessionId);
          }
        }
      }
    }
    this.conversationAdmins = [];
    this.isSwitchCameraInProgress = false;
    this.broadcaster.broadcast("endingCall", isBeforeUnloadEvent);
    if (environment.isCordova && !!AudioToggle) {
      AudioToggle.setAudioMode("speaker");
    }
    _replaceLocalAudioTrackQueue._onTaskComplete();
    _replaceLocalVideoTrackQueue._onTaskComplete();
    _stopScreenSharingQueue._onTaskComplete();

    this.processLeaveConference();
  }

  public getFullScreenDisplayName() {
    this.getDisplayName(this.fullScreenParticipantId) || "";
  }

  /**
   * Share data to other users.
   * @param command the command
   * @param {any} value new value
   */
  private sendData(command: string, value: string): void {
    this.logger.info("[JitsiService][sendData]", command);

    if (!this.room) {
      return;
    }

    this.room.sendCommandOnce(command, { value });
  }

  /**
   * Share data to other users.
   * @param command the command
   * @param {any} value new value
   */
  public sendDataOnce(command: string, value: string): void {
    if (!this.room) {
      return;
    }

    this.room.removeCommand(command);
    this.room.sendCommandOnce(command, { value });
  }

  /**
   * Replaces one track with another for one renegotiation instead of invoking
   * two renegotiations with a separate removeTrack and addTrack. Disposes the
   * removed track as well.
   *
   * @param {JitsiLocalTrack|null} oldTrack - The track to dispose.
   * @param {JitsiLocalTrack|null} newTrack - The track to use instead.
   */
  private _replaceLocalTrackTimerIDs = {};
  replaceOrAddLocalTrack(oldTrack: any, newTrack: any, isFromScreenshare?:boolean): void {
    const oldLocalTracks = this.room?.getLocalTracks();
    this.logger.info("[JitsiService][replaceOrAddLocalTrack-" + newTrack?.type, oldLocalTracks, { oldTrack, newTrack, localVideo: this.localVideo, localAudio: this.localAudio });

    if (newTrack.type === "video") {
      const possibleRealOldTrack = oldLocalTracks.filter(t => t.type === "video" && t.videoType === newTrack.videoType);
      if (!!possibleRealOldTrack && !!possibleRealOldTrack[0]) {
        oldTrack = possibleRealOldTrack[0];
      }
    }

    if (!this.room) {
      this.logger.error("[JitsiService][replaceOrAddLocalTrack]", "return, room is empty");
      return;
    }
    if (!oldTrack) {
      this.logger.info("[JitsiService][replaceOrAddLocalTrack]", "skip, nothing to replace");
      if (newTrack.type === "video" && !!newTrack.videoType && newTrack.videoType === "camera") {
        this.logger.info("[JitsiService][replaceOrAddLocalTrack]", "cam-is-on", newTrack);
        setTimeout(() => {
          this.broadcaster.broadcast("CAM_IS_ON");
        }, 150);
      }
      this.processNewTrackAndDisposeOldIfNeeded(oldTrack, newTrack);
      return;
    }

    // this.logger.info("[JitsiService][replaceOrAddLocalTrack] ", oldTrack.videoType, newTrack.videoType );
    if ((oldTrack.videoType === newTrack.videoType) && (oldTrack.type === newTrack.type) && !isFromScreenshare) {
      // this.logger.info("[JitsiService][replaceOrAddLocalTrack1] - same videoType");
      this.room.replaceTrack(oldTrack, newTrack).then(() => {
        this.updateLocalTracks(this.room.getLocalTracks());

        this.clearRepalceLocalTrackTimer(newTrack.track.id);

        // this.logger.info("[JitsiService][replaceOrAddLocalTrack][replaceStoredTracks] success", oldTrack, newTrack);
      }).catch(err => {
        this.clearRepalceLocalTrackTimer(newTrack.track.id);

        this.logger.error("[JitsiService][replaceOrAddLocalTrack][replaceStoredTracks] err", err);
      }).finally(() => {
        this.clearRepalceLocalTrackTimer(newTrack?.track.id);

        // this.logger.info("[JitsiService][replaceOrAddLocalTrack] finally", this.room, !!newTrack, newTrack);

        this.processNewTrackAndDisposeOldIfNeeded(oldTrack, newTrack);
      });
      // it can be a case when during 'replaceOrAddLocalTrack' a user stops the conv,
      // so 'replaceOrAddLocalTrack' promise will never resolve, and the track will be active.
      // here we handle it
      this._replaceLocalTrackTimerIDs[newTrack.track.id] = setTimeout(() => {
        // console.warn("[JitsiService][replaceOrAddLocalTrack] timeout", newTrack.track.id);
        delete this._replaceLocalTrackTimerIDs[newTrack.track.id];
        this.logger.info("jitsi-service-sharescreen3 _replaceLocalTrackTimerIDs1 stopStream ", newTrack);
        // newTrack.stopStream();
        // newTrack = null;
      }, 1000);
    } else {
      this.room.addTrack(newTrack).then(() => {
        // this.logger.info("[JitsiService][replaceOrAddLocalTrack2] - different Type");
        this.updateLocalTracks(this.room.getLocalTracks());

      }).catch(err => {
        this.clearRepalceLocalTrackTimer(newTrack.track.id);

        this.logger.error("[JitsiService][replaceOrAddLocalTrack][replaceStoredTracks] err", err);
      }).finally(() => {
        this.clearRepalceLocalTrackTimer(newTrack?.track.id);

        // this.logger.info("[JitsiService][replaceOrAddLocalTrack] finally", this.room, !!newTrack, newTrack);

        this.addTrackIfNotAdded(newTrack);
      });

      // it can be a case when during 'replaceOrAddLocalTrack' a user stops the conv,
      // so 'replaceOrAddLocalTrack' promise will never resolve, and the track will be active.
      // here we handle it
      this._replaceLocalTrackTimerIDs[newTrack.track.id] = setTimeout(() => {
        // console.warn("[JitsiService][replaceOrAddLocalTrack] timeout", newTrack.track.id);
        delete this._replaceLocalTrackTimerIDs[newTrack.track.id];
        this.logger.info("jitsi-service-sharescreen3 _replaceLocalTrackTimerIDs2 stopStream ", newTrack);
        // newTrack.stopStream();
        // newTrack = null;
      }, 1000);
    }
  }

  private clearRepalceLocalTrackTimer(trackId: string) {
    // this.logger.info("[JitsiService][replaceLocalTrack][clearRepalceLocalTrackTimer]", trackId);

    if (this._replaceLocalTrackTimerIDs[trackId]) {
      clearTimeout(this._replaceLocalTrackTimerIDs[trackId]);
      delete this._replaceLocalTrackTimerIDs[trackId];
    }
  }

  private cancelGUMProcesses(tracks) {
    this.logger.info("[JitsiService][cancelGUMProcesses]", tracks);

    return Promise.all(
      tracks.filter(v => !!v).map(({ gumProcess }) =>
                gumProcess && gumProcess.cancel().catch(err => this.logger.error("cancelGUMProcesses", err))));
  }

  private disposeAndRemoveTracks(tracks): Observable<any> {
    this.logger.info("[JitsiService][disposeAndRemoveTracks]", tracks);
    tracks.filter(v => !!v).forEach(t => {
      this.hideTrackAllContainers(t, true);
    });
    const response = new Subject<any>();
    Promise.all(
      tracks.filter(v => !!v && !v.disposed).map(t => {
        try {
          return t.dispose()
            .catch(err => {
              this.logger.error("[JitsiService][Track might be already disposed][1]", err);
              // this.displayTrackAlreadyDisposedErrorAlert();
              return Promise.resolve(err);
            });
        } catch (ex) {
          this.logger.error("[JitsiService][Track might be already disposed][2]", ex);
          // this.displayTrackAlreadyDisposedErrorAlert();
          return Promise.resolve(ex);
        }
      }

      ))
      .then(() => {
        this.logger.info("[JitsiService][disposed track]", tracks);
        tracks.filter(v => !!v).forEach(t => {
          this.trackRemoved(t);
          this.logger.info("jitsi-service-sharescreen3 disposeAndRemoveTracks stopStream ", t);
          t.stopStream();
        });
        response.next(tracks);
      }).catch(err => {
        response.error(err);
      });
    return response.asObservable().pipe(take(1));
  }

  private trackRemoved(track) {
    this.logger.info("[JitsiService][trackRemoved]", track);
    track.removeAllListeners(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED);
    track.removeAllListeners(JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED);
    track.removeAllListeners(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED);
    track.removeAllListeners(JitsiMeetJS.events.track.NO_DATA_FROM_SOURCE);
    track.removeAllListeners(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED);
    this.resetTileMode();
    if (this.room) {
        this.logger.info("[JitsiService][trackRemoved] room", this.room);
        this.room.removeTrack(track).then(() => {
          this.updateLocalTracks(this._localTracks$.value.filter(v => v !== track));
        })
        .catch(err => {
          // Local track might be already disposed by direct
          // JitsiTrack#dispose() call. So we should ig e this error
          // here.
          if (err.name !== JitsiMeetJS.errors.track.TRACK_IS_DISPOSED) {
            this.logger.info(
              "Failed to remove local track from conference",
              err);
          }
        });
    }
  }

  private async addTrackIfNotAdded(track: any) {

    if (!track) {
      return;
    }

    const participantId = this.myUserId();
    let is2ndAudio = false;
    /*
    if (this.localVideo && this.localVideo !== track) {
      try {
        await this.localVideo.setEffect(undefined);
      } catch (error) {
        // this.restartMediaSessionIfRequired(error);
      }
    }
    */

    if (!!track && track.isVideoTrack()) {
      this.localVideo = track;
    } else {
      this.localAudio = track;
    }

    this.logger.info("[JitsiService][addTrackIfNotAdded]",
      {track, participantId, displayName:  this.getDisplayName(participantId), room: !!this.room, roomLocalTracks: this.room.getLocalTracks()});

    let trackToAdd;
    if (track.type === "video") {
      trackToAdd = track;
      this.attachTrack(track, participantId);
      if (track.videoType !== "desktop") {
        this.correctAudioOutput();
        this.setMediaStatus(this.myUserId(), ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted);
        track.unmute();
        this.store.dispatch(new ConferenceUnMuteVideo());
        this.enabledVideo = true;
      }
    } else {
      this.store.select(getIsConferenceAudioMuted).pipe(take(1)).subscribe(isMuted => {
        // this.logger.info("[JitsiService][addTrackIfNotAdded] getIsConferenceAudioMuted", isMuted);
        trackToAdd = track;
        if (isMuted) {
          if (!!this.localAudio) {
            trackToAdd = this.localAudio;
            this.localAudio.mute();
          } else {
            track.mute();
          }
        } else {
          if (!!this.localAudio) {
            trackToAdd = this.localAudio;
            this.localAudio.unmute();
          } else {
            track.unmute();
          }
        }
      });
    }

    if (this.externalData && this.externalData.muteAudio) {
        this.muteAudio();
    }
    if (this.room) {
      const conferenceLocalTracks = this.room.getLocalTracks() || [];
      this.logger.info("[JitsiService][addTrackIfNotAdded] conferenceLocalTracks, track2add", conferenceLocalTracks, trackToAdd);
      // if not added already -> add
      if ((conferenceLocalTracks.indexOf(trackToAdd) === -1)) {
        this.logger.info("[JitsiService][addTrackIfNotAdded] conferenceLocalTracks", conferenceLocalTracks, this.room.getLocalVideoTrack(), this.room.rtc.getLocalTracks("video"));

        try {
          if (trackToAdd.type === "video" && (trackToAdd.videoType === "screen" || trackToAdd.videoType === "desktop") && !!this.room.getLocalVideoTrack()) {
            this.room.onLocalTrackRemoved(this.room.getLocalVideoTrack());
          }
        } catch (error) {
          this.logger.error("[JitsiService][addTrackIfNotAdded] onLocalTrackRemoved", error);
        }
        this.logger.info("[JitsiService][addTrackIfNotAdded] conferenceLocalTracks after", conferenceLocalTracks, this.room.getLocalVideoTrack(), this.room.rtc.getLocalTracks("video"));

        this.room.addTrack(trackToAdd)
          .then(() => {
            this.updateLocalTracks(this.room.getLocalTracks());

            this.logger.info("[JitsiService][addTrackIfNotAdded] added",
              { trackToAdd, roomLocalTracks: this.room.getLocalTracks(), _localTracks: this._localTracks$.value});

            if (trackToAdd.type === "video") {
              this.logger.info("[JitsiService][addTrackIfNotAdded] added broadcast onVideoSourceChange");
              this.broadcaster.broadcast("onVideoSourceChange", participantId);
              // setTimeout(() => {
              //  track.unmute();
              // }, 2000);
            }
          }).catch(err => {
            this.logger.error("[JitsiService][addTrackIfNotAdded] room.addTrack error", err, this.room.getLocalVideoTrack());
            this.logger.info("[JitsiService][addTrackIfNotAdded] error", track, this.room.getLocalVideoTrack(), this.room.rtc.getLocalTracks("video"));

            // this.disposeAndRemoveTracks([track]);
            // this.restartMediaSessionIfRequired(err);
          });
      }
      if (track.type === "video" && this.localVideo && this.localVideo.videoType !== "desktop" && !this.localVideo._streamEffect) {
        if (!CommonUtil.isOnSafari()) {
          if (this.setBackground) {
            clearTimeout(this.setBackground);
          }
          this.setBackground = setTimeout(() => {
            this.store.select(getVirtualBackground).pipe(take(1)).subscribe(v => {
              this.logger.info("[trackAdded][getVirtualBackground]", v);
              if (!!v && v.backgroundType !== "none" && v.selectedThumbnail !== "none") {
                this.logger.info("[trackAdded][toggleBackgroundEffect]");
                this.toggleBackgroundEffect({...v, enabled: true});
              }
            });
          }, 1000);
        }
      }
    }

    // BIND EVENTS
    track.addEventListener(
      JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {
        this.logger.info("[JitsiService][LOCAL_TRACK_STOPPED]", track);

        if ( track.deviceId && (track.deviceId.startsWith("screen")
        || track.videoType === "desktop" || track.deviceId.startsWith("window")) || track.videoType === "desktop") {
          this.logger.info("[jitsiService] LOCAL_TRACK_STOPPED broadcast: screenshareStopped");
          this.broadcaster.broadcast("screenshareStopped");
          this.notificationService.openSnackBarWithTranslation("SCREEN_SHARE_IS_DISABLED", {}, 5000);
          // this.unshareScreen(); // TODO: this will be called anyway. e.g from conv repo unshareScreenAndStartNewStream
        }
        this.hideTrackAllContainers(track, true);
        this.resetTileMode();
      }
    );
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_IS_DISPOSED, () => {
        this.logger.info("[JitsiService][TRACK_IS_DISPOSED]", track);
      }
    );
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_NO_STREAM_FOUND, () => {
        this.logger.info("[JitsiService]trackEventCheck[TRACK_NO_STREAM_FOUND]", track);
      }
    );
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_STREAMING_STATUS_CHANGED, () => {
        this.logger.info("[JitsiService]trackEventCheck[TRACK_STREAMING_STATUS_CHANGED]", track);
      }
    );
    //TRACK_VIDEOTYPE_CHANGED
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED, () => {
        this.logger.info("[JitsiService]trackEventCheck[TRACK_VIDEOTYPE_CHANGED]", track);
      }
    );

  }

  isFatalJitsiConnectionError(error: any) {
    if (typeof error !== "string") {
        error = error.name; // eslint-disable-line no-param-reassign
    }
    return (
        error === JITSI_MEET.JitsiConnectionErrors.CONNECTION_DROPPED_ERROR
            || error === JITSI_MEET.JitsiConnectionErrors.OTHER_ERROR
            || error === JITSI_MEET.JitsiConnectionErrors.SERVER_ERROR);
  }


  get isJoined(): boolean {
    return this.room && this.room.isJoined();
  }

  async showMessageDialog(options) {
    this.joinedWithPassword = "";
    let dialogStyles: any = {
      "width": "430px",
      "min-height": "280px"
    };
    if (CommonUtil.isOnMobileDevice()) {
      dialogStyles = {
        "width": "70%",
        "min-width": "70%",
        "max-width": "70%",
        "height": "auto"
      };
    }
    const { ConferenceMessageDialogDialogComponent } = await import(
      "../shared/components/dialogs/conference-message-dialog/conference-message-dialog.component");
    const headerKey = (options.messageKey === "SCREENSHARING_USER_CANCELED") ? "SCREENSHARING_USER_CANCELED" : "VNCTALK.CHECK_YOUR_CAMERA_MICROPHONE";
    this.dialog.open(ConferenceMessageDialogDialogComponent, Object.assign({
      data: { ...options, headerTranslationKey: headerKey},
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      autoFocus: true
    }, dialogStyles)).afterClosed().subscribe((data) => {
        this.logger.info("[JitsiService][showMessageDialog]1", data, options.messageKey, this.openPopupList);
        this.openPopupList = this.openPopupList.filter(item => item !== options.messageKey);
        this.store.dispatch(new ConferenceUnShareScreen());
      });
  }

  async showPasswordDialog(data) {
    this.joinedWithPassword = "";
    let style: any = {
      width: "440px",
      minHeight: "250px",
    };
    if (CommonUtil.isMobileSize()) {
      style = {
        maxWidth: "100vw",
        width: "100vw",
        height: "100vh"
      };
    }
    this.openPopupList.push("PASSWORD_REQUIRED");
    const { ConferenceDialogComponent } = await import(
      "../shared/components/dialogs/conference-confirmation/conference-confirmation.component");
    const dialog = this.dialog.open(ConferenceDialogComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        data: data,
        autoFocus: true
       }, style));
       dialog.afterOpened().pipe(take(1)).subscribe(() => {
        this.broadcaster.broadcast("toggleHideVideoIOS", true);
       });
       dialog.afterClosed().pipe(take(1)).subscribe(data => {
        this.broadcaster.broadcast("toggleHideVideoIOS", false);
        this.openPopupList = this.openPopupList.filter(item => item !== "PASSWORD_REQUIRED");
        if (data && data.password) {
          this.room.join(data.password);
          this.joinedWithPassword = data.password;
        } else if (data && data.goToChat) {
          this.broadcaster.broadcast("hangupCall");
        }
       });
  }

  public isModerator(participantId: string) {
    if (this.isLocalId(participantId)) {
      return this.room && this.room.isModerator() || this.isModeratorOrOwner();
    }
    return this.room && this.room.getParticipantById(participantId)._role === ConstantsUtil.CONFERENCE_USER_ROLE.moderator || this.isModeratorOrOwner();
  }

  public isRoomModerator(participantId: string) {
    if (this.isLocalId(participantId)) {
      return this.room && this.room.isModerator();
    }
    return this.room && this.room.getParticipantById(participantId)._role === ConstantsUtil.CONFERENCE_USER_ROLE.moderator || this.isModeratorOrOwner();
  }

  public isModeratorOrOwner(conferenceTarget?: string, bare?: string) {
    let isOwner = false;
    let isMod = false;
    let email = bare || this.displayName;
    if (email === "ME") {
      email = this.displayName;
    }
    let target = conferenceTarget || this.conferenceTarget;
    if (!target || !CommonUtil.isGroupTarged(target)) {
      return false;
    }
    this.store.select(getActiveConference).pipe(take(1)).subscribe(v => {
      if (!!v) {
        target = v;
      }
    });

    // this.logger.info("[JitsiService][isModeratorOrOwner]", bare, email, target);

    if (target) {
      this.getConversationOwner(target).pipe(take(1)).subscribe(owner => {
        // this.logger.info("[JitsiService][isModeratorOrOwner] getConversationOwner", owner);
        if (owner && owner === email) {
          isOwner = true;
        }
      });
      this.getConversationAdmins(target).pipe(take(1)).subscribe(admins => {
        // this.logger.info("[JitsiService][isModeratorOrOwner] getConversationAdmins", admins);
        isMod = !!admins && admins.indexOf(email) !== -1;
      });
    }

    // this.logger.info("[isModeratorOrOwner]", target, email, isOwner, isMod);
    return isOwner || isMod;
  }

  private setMediaStatus(participantId: string, mediaType: string, mediaStatus: string) {
    this.logger.info("[JitsiService][setMediaStatus] ", participantId, mediaType, mediaStatus);

    let participant: JitsiParticipant;
    switch (mediaType) {
      case ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio:
        participant = { id: participantId, audioStatus: mediaStatus };
        this.store.dispatch(new SetAudioStatus(participant));
        break;
      case ConstantsUtil.CONFERENCE_MEDIA_TYPE.video:
        participant = { id: participantId, videoStatus: mediaStatus };
        this.store.dispatch(new SetVideoStatus(participant));
        break;
    }
  }

  public getMediaStatus(participantId?: string): any {
    let mediaStatus = { audioStatus: true, videoStatus: true };
    let participant = this.getConferenceParticipants().find(p => p.id === (participantId === undefined ? this.myUserId() : participantId));
    if (!participant || participant === undefined) {
      return null;
    }
    mediaStatus.audioStatus = ((participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted) || (participant.audioStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator));

    // 'true' means muted

    mediaStatus.videoStatus = ((participant.videoStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted) || (participant.videoStatus === ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator));

    // this.logger.info("[JitsiService][getMediaStatus]", participantId, mediaStatus);

    return mediaStatus;
  }

  public getMediaMuteStatus(participantId?: string): any {
    let mediaStatus = { audioStatus: "unmuted", videoStatus: "unmuted" };
    let participant = this.getConferenceParticipants().find(p => p.id === (participantId === undefined ? this.myUserId() : participantId));
    if (!participant || participant === undefined) {
      return null;
    }
    // this.logger.info("[jitsi-service] getMediaMuteStatus ", participant.id, participant);

    mediaStatus.audioStatus = (participantId !== "fake123") ? participant.audioStatus : ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted;
    mediaStatus.videoStatus = (participantId !== "fake123") ? participant.videoStatus : ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted;
    return mediaStatus;
  }

  private getMediaStatusByTrack(track: any) {
    return track.isMuted() ? ConstantsUtil.CONFERENCE_MEDIA_STATUS.muted : ConstantsUtil.CONFERENCE_MEDIA_STATUS.unmuted;
  }

  public getConferenceParticipants() { // hmm
    let participants = [];
    this.store.select(getJitsiConferenceParticipants).pipe(take(1)).subscribe(res => {
      participants = res || [];
    });
    return participants;
  }

  public getAllParticipants() { // hmm
    let participants = [];
    this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(res => {
      participants = res || [];
    });
    return participants;
  }

  public getConferenceTypeForParticipant(participantId: string): string {
    // this.logger.info("[JitsiService][getConferenceType] participantId:", participantId);

    if (this.room === null) {
      return null;
    }
    let participant = this.room.getParticipantById(participantId);
    if (participant === null || participant === undefined) {
      return null;
    }
    if (participant._tracks.length === 1) {
      return ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio;
    } else if (participant._tracks.length === 2) {
      return ConstantsUtil.CONFERENCE_MEDIA_TYPE.video;
    }
  }

  public getRaiseMyhand(participantId: string): string {
    // this.logger.info("[JitsiService][getRaiseMyhand] participantId:", participantId);
    if (!this.room) {
      return;
    }
    let participant = this.room.getParticipantById(participantId);
    if (!participant) {
      return;
    }
    // this.broadcaster.broadcast("onRaiseHAnd", participant._status);
  }

  toggleRemoteMedia(mediaType: string, participantId: string, shouldMute?: boolean) {
    this.logger.info("[JitsiService][toggleRemoteMedia] remoteId: myId:", participantId, this.myUserId(), this.canManageMCBs,  this.isModerator(this.myUserId()));
    if (!this.isModerator(this.myUserId()) && !this.canManageMCBs) {
      return;
    }
    const data = {
      participantId: participantId,
      shouldMute: shouldMute
    };
    this.logger.info("[JitsiService][toggleRemoteMedia]", participantId, mediaType, data);
    switch (mediaType) {
      case ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio:
        this.setMediaStatus(participantId, ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio, ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
        this.sendDataOnce(ConstantsUtil.COMMANDS.toggleAudio, JSON.stringify(data));
        break;
      case ConstantsUtil.CONFERENCE_MEDIA_TYPE.video:
        this.setMediaStatus(participantId, ConstantsUtil.CONFERENCE_MEDIA_TYPE.video, ConstantsUtil.CONFERENCE_MEDIA_STATUS.mutedByModerator);
        this.sendDataOnce(ConstantsUtil.COMMANDS.toggleVideo, JSON.stringify(data));
        break;
    }
  }

  wakeUp(participantId) {
    const participant = this.room.getParticipantById(participantId);
    let myFullName = this.displayName.split("@")[0];
     this.store.select(getUserProfile).pipe(take(1)).subscribe(profile => {
      if (profile && profile.user) {
        myFullName = profile.user.fullName;
      } else {
        this.getContactById(this.displayName).pipe(take(1)).subscribe(contact => {
          if (contact) {
            myFullName = contact.name || contact.local;
          }
          myFullName = myFullName.split("@")[0];
        });
      }
    });

    let participantName = participantId;

    if (!!participant) {
      participantName = participant._displayName;
      this.getContactById(participant._displayName).pipe(take(1)).subscribe(contact => {
        if (contact) {
          participantName = contact.name || contact.local;
        }
        participantName = participantName.split("@")[0];
      });
    }
    const data = {
      participantId: participantId,
      fullName: myFullName
    };
    this.translate.get("YOU_CALLED_TO_WAKE_UP", {fullName: participantName}).pipe(take(1)).subscribe(text => {
      this.notificationsService.html("", text, "wakeup", { bgColor: "#fe5019", timeOut: 6000, participantId: participantId });
    });
    this.sendDataOnce(ConstantsUtil.COMMANDS.wakeup, JSON.stringify(data));
  }

  toggleRaiseMyhand() {
    const value = !this.raisedHandList$.value[this.myUserId()];
    const data = {
      participantId: this.myUserId(),
      on: value
    };
    this.setRaiseHandStatus(this.myUserId(), value);
    this.logger.info("[JitsiService][toggleRaiseMyhand] remoteId: myId:", data, this.myUserId());
    this.sendData("status", JSON.stringify(data));
    if (data.on) {
      this.sendHandRequest();
    }
  }

  sendBroadcast(content) {
    const data = {
      participantId: this.myUserId(),
      email: this.displayName,
      content: content
    };
    this.sendData("broadcast", JSON.stringify(data));
  }

  sendParticipantInformation() {
    const data = {
      participantId: this.myUserId(),
      onMobile: CommonUtil.isOnMobileDevice(),
      oniPad: CommonUtil.isOnIpad(),
      isPortrait: window.innerHeight > window.innerWidth
    };
    this.sendData("information", JSON.stringify(data));
    this.logger.info("[sendParticipantInformation][recordingInfo]");
    if (this.startRecordingTime && this.isModerator(this.myUserId())) {
      this.logger.info("[sendParticipantInformation][recordingInfo]", this.startRecordingTime);
      this.sendData("recordingInfo", JSON.stringify({
        participantId: this.myUserId(),
        recordSessionId: this.recordSessionId,
        startRecordingTime: this.startRecordingTime
      }));
    }
    if (this.externalData &&  this.externalData.info) {
      const info = {participantId: this.myUserId(), name: this.externalData.info.name, company: this.externalData.info.company, position: this.externalData.info.position, email: this.externalData.info.email};
      this.sendUserInfo(info);
    }
    this.store.select(getActiveConference).pipe(take(1)).subscribe(conferenceTarget => {
      if (CommonUtil.isGroupTarged(conferenceTarget)) {
        if (!!conferenceTarget && !this.startedRecording && (this.isModeratorOrOwner(conferenceTarget)) || this.canManageMCBs) {
          this.groupChatsService.updateGroupInfo(conferenceTarget, {status: "active", stream_url: this.userJID?.bare + "##" + this.roomName}).subscribe((res: any) => {
            if (res.group_chat && res.group_chat.meta_conference_boards && res.group_chat.meta_conference_boards.length > 0) {
              this.startLiveStream(conferenceTarget);
            }
          });
        }
      }
    });
  }

  startLiveStream(target) {
    this.logger.info("[startLiveStream]", this.recordingStatus, this.recordSessionId);
    if (this.recordingStatus !== ConstantsUtil.JitsiRecordingStatus.NOT_STARTED || this.recordSessionId) {
      return;
    }
    const streamId = CommonUtil.md5(target);
    this.recordingName = streamId;
    this.liveStreamTarget = target;
    const options = {
      streamId: streamId,
      mode: "stream"
    };
    this.startRecordingConference(options);
  }

  kickParticipantByCommand(participantId) {
    const data = {
      participantId: participantId
    };
    this.sendData("kickParticipant", JSON.stringify(data));
  }

  sendRoles(target, admins, owner, audiences?: string[]) {
    const data = {
      target: target,
      owner: owner,
      admins: admins,
      audiences: audiences
    };
    this.logger.info("[sendRoles]", data);
    this.sendDataOnce("roles", JSON.stringify(data));
  }

  public showMuteAudioStatus(): Subject<boolean> {
    return this._muteAudioPopup;
  }

  public showMuteVideoStatus(): Subject<boolean> {
    return this._muteVideoPopup;
  }

  /**
   * Calls jitsi kick() API. Allowed only for moderator
   * @param participantId participant that will be kicked from the call
   */
  public kickParticipant(participantId) {
    this.logger.info("[JitsiService][kickParticipant]", participantId, this.myUserId());
    const participant = Object.assign({}, this.participantsList[participantId]);
    if (participant) {
      const leftList = this.leftList.value;
      let displayName = participant.name;
      if (leftList.indexOf(displayName) === -1) {
        this.leftList.next([...leftList, displayName]);
      }
      this.joinedList.next(this.joinedList.value.filter(v => v !== displayName));
    }
    // return if not the moderator
    if (!this.isRoomModerator(this.myUserId())) {
      this.logger.info("[JitsiService][kickParticipant] byCommand ", participantId);
      this.kickParticipantByCommand(participantId);
      return;
    }
    if (participantId === this.myUserId()) {
      this.logger.info("[JitsiService][kickParticipant] myself ", participantId, this.myUserId());
      this.broadcaster.broadcast("onCallHangup");
    }
    this.store.dispatch(new ConferenceRemoveParticipant(participantId));
    this.store.dispatch(new JitsiConferenceRemoveParticipant(participantId));
    this.room.kickParticipant(participantId);
  }

  public getUserRole(participantId) {
    if (this.isLocalId(participantId)) {
      return this.room.getRole();
    }
    return this.room.getParticipantById(participantId)._role;
  }

  getCurrentMediaDevices() {
    this.logger.info("[JitsiService][getCurrentMediaDevices]");

    const tracks = this._localTracks$.value;
    const currentMediaDevices = {
      audioDeviceId: "",
      videoDeviceId: "",
    };
    tracks.forEach(t => {
      if (t.type === ConstantsUtil.CONFERENCE_MEDIA_TYPE.video) {
        // currentMediaDevices.video = t.track.label;
        currentMediaDevices.videoDeviceId = t.deviceId;
      } else if (t.type === ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio) {
        currentMediaDevices.audioDeviceId = t.deviceId;
      }
    });

    return currentMediaDevices;
  }

  private checkOpenPopup(msg: string): boolean {
    this.logger.info("[JitsiService][checkOpenPopup]", msg, this.openPopupList);
    return !!this.openPopupList.find(x => x === msg);
  }

  /**Callback function for handling recording status updates */
  private onRecordingUpdatesReceived(recorderSession: any) {
    if (recorderSession) {
      if (recorderSession.getError()) {
        this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
        this.broadcaster.broadcast("onRecordingError");
        this.logger.error("Recording session update status " + recorderSession.getError());
      } else {
        this.logger.info("Recording session update status ", recorderSession.getStatus());
        if (recorderSession.getStatus() === ConstantsUtil.JitsiRecordingStatus.ON && !this.recordSessionId) {
          this.logger.info("RecordingStatus: ON");
          this.recordSessionId = recorderSession.getID();
          if (this.startedRecording) {
            this.startRecordingTime = new Date().getTime();
            this.sendData("recordingInfo", JSON.stringify({
              participantId: this.myUserId(),
              recordSessionId: this.recordSessionId,
              startRecordingTime: this.startRecordingTime
            }));
            this.startRecordingTimer();
          }

          this.isRecording = true;
          this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.ON;
          this.translate.get("RECORDING_STARTED").pipe(take(1)).subscribe(text => {
            this.notificationsService.html("", text, "startedRecording", { bgColor: "#fe5019", timeOut: 3000 });
          });
        } else if (recorderSession.getStatus() === ConstantsUtil.JitsiRecordingStatus.OFF) {
          this.logger.info("RecordingStatus: OFF");
          this.isRecording = false;
          this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
          this.recordSessionId = "";
          if (this.recordInterval) {
            this.recordInterval.unsubscribe();
          }
        } else if (recorderSession.getStatus() === ConstantsUtil.JitsiRecordingStatus.PENDING) {
          this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.PENDING;
          this.logger.info("RecordingStatus: PENDING");
        }
        const preferableAudioOutputId = this.getPreferableAudioOutputId();
        if (preferableAudioOutputId) {
          this.setAudioOutputDevice(preferableAudioOutputId);
        }
        this.broadcaster.broadcast("onRecordingStatusChanged", { status: recorderSession.getStatus(), sessionId: recorderSession.getID() });
      }
    } else {
      return;
    }
  }

  /**Public interface to trigger start recording */
  public startRecordingConference(options: any) {
    if (!this.configService.get("disableAVRecording")) {
      this.logger.info("Recording starting");
      if (this.room && this.room.isModerator()) {
        this.logger.info("[startRecording] start directly");
        this.room.startRecording(options);
      } else {
        this.logger.info("[startRecording] send command");
        this.sendDataOnce("startRecording", JSON.stringify(options));
      }
      this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.START_IN_PROGRESS;
      this.startedRecording = true;
      this.sendAnalytics(this.createRecordingEvent("start", "file"));
      this.logger.info("Live stream url", this.recordingName);
    }
  }

  /**Public interface to trigger stop recording */
  public stopRecordingConference(sessionId: string) {
    this.logger.info("stopRecordingConference Recording stoppped ");
    this.startedRecording = false;
    if (this.room && this.room.isModerator()) {
      this.logger.info("[stopRecording] call directly");
      this.room.stopRecording(sessionId);
    } else {
      this.logger.info("[stopRecording] send command");
      this.sendDataOnce("stopRecording", sessionId);
    }

    this.sendAnalytics(this.createRecordingEvent("stop", "file"));
    if (this.recordInterval) {
      this.recordInterval.unsubscribe();
    }

    this.recordSessionId = "";
    this.isRecording = false;
    this.recordingStatus = ConstantsUtil.JitsiRecordingStatus.NOT_STARTED;
  }

  public startRecordingTimer() {
    this.logger.info("Recording timer started");
    if (!!this.recordInterval && this.recordInterval.unsubscribe) {
      this.recordInterval.unsubscribe();
    }
    this.recordInterval = interval(1000).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      let now = new Date().getTime();
      const isoDuration = dayjs(now - this.startRecordingTime).toISOString().split("T")[1].split(".")[0];
      localStorage.setItem("recordDuration", isoDuration);
      this._recordDuration.next(isoDuration);
    });
  }

  public muteForMe(participantId) {
    this.mutedForMe$.next([...this.mutedForMe$.value, participantId]);
  }

  public unMuteForMe(participantId) {
    this.mutedForMe$.next(this.mutedForMe$.value.filter(v => v !== participantId));
  }

  public getLastSpeakingParticipant() {
    return this.lastSpeakingParticipant$.asObservable();
  }

  stopWhiteboard() {
    this.store.dispatch(new SetSelectedWhiteboardId(null));
    this.setWhiteboardStatus(false);
    this.store.dispatch(new ConferenceSetActiveWhiteboard(null));
  }

  async createLocalPresenterTrack(options) {
    this.logger.info("createLocalPresenterTrack options: ", options);
    const { cameraDeviceId } = options;

    // compute the constraints of the camera track based on the resolution
    // of the desktop screen that is being shared.
    const constraints = {
        video: {
            aspectRatio: 4 / 3,
            height: {
                ideal: 180
            }
        }
    };

    const [ videoTrack ] = await JitsiMeetJS.createLocalTracks(
        {
            cameraDeviceId,
            constraints,
            devices: [ "video" ]
        });

    videoTrack.type = "presenter";

    return videoTrack;
  }

  createPresenterEffect(stream: MediaStream, desktopStream: MediaStream) {
    if (!MediaStreamTrack.prototype.getSettings
        && !MediaStreamTrack.prototype.getConstraints) {
        return Promise.reject(new Error("JitsiStreamPresenterEffect not supported!"));
    }
    return Promise.resolve(new JitsiStreamPresenterEffect(stream, desktopStream));
  }

  // set cameraid to last used cameraid from call
  async _createPresenterStreamEffect(height = null, cameraDeviceId = this.cameraId) {
    this.logger.info("[_createPresenterStreamEffect] cameraDeviceId ", cameraDeviceId);
    try {
      this.localPresenterVideo && this.localPresenterVideo.dispose();
      this.localPresenterVideo = null;
      this.localPresenterVideo = await this.createLocalPresenterTrack({ cameraDeviceId });
    } catch (err) {
        this.logger.error("[_createPresenterStreamEffect]Failed to create a camera track for presenter", height, err);
        return null;
    }
    try {
        this.logger.error("[_createPresenterStreamVideo] Create the presenter localPresenterVideo: ", this.localPresenterVideo);
        if (!!this.localPresenterVideo && !!this.localPresenterVideo.stream && !!this.localVideo && !!this.localVideo.stream) {
          const effect = await this.createPresenterEffect(this.localPresenterVideo.stream, this.localVideo.stream);
          this.logger.error("[_createPresenterStreamEffect]Create the presenter effect", effect);

          return effect;
        } else {
          this.logger.error("[_createPresenterStreamEffect]Create the presenter effect2");
          return null;
        }
    } catch (err) {
        this.logger.error("[_createPresenterStreamEffect]Failed to create the presenter effect", err);
        return null;
    }
  }

  _removeLocalTracksFromConference(tracks) {
    if (!!this.room) {
      this.logger.info("_removeLocalTracksFromConference this.room: ", this.room);
      return Promise.all(tracks.map(track =>
        this.room.removeTrack(track)
          .catch(() => {
            // Local track might be already disposed by direct
            // JitsiTrack#dispose() call. So we should ignore this error
            // here.
            this.logger.info("Local track might be already disposed");
          })
      ));
    }
    return Promise.resolve();
  }

  /**
   * Creates an event which indicates that an action related to recording has
   * occured.
   *
   * @param {string} action - The action (e.g. 'start' or 'stop').
   * @param {string} type - The recording type (e.g. 'file' or 'live').
   * @param {number} value - The duration of the recording in seconds (for stop
   * action).
   * @returns {Object} The event in a format suitable for sending via
   * sendAnalytics.
   */
  createRecordingEvent(action, type, value?: any) {
    return {
        action,
        actionSubject: `recording.${type}`,
        attributes: {
            value
        }
    };
  }

/**
 * Creates an event indicating that an action related to E2EE occurred.
 *
 * @param {string} action - The action which occurred.
 * @returns {Object} The event in a format suitable for sending via
 * sendAnalytics.
 */
  createE2EEEvent(action) {
    return {
        action,
        actionSubject: "e2ee"
    };
  }

  sendAnalytics(event) {
    try {
      JitsiMeetJS.analytics.sendEvent(event);
  } catch (e) {
      console.warn(`Error sending analytics event: ${e}`);
    }
  }

  createVideoBlurEvent(action) {
    return {
        action,
        actionSubject: "video.blur"
    };
  }

  async toggleBlur() {
    const isBlurEnabled = !this.blurBackground$.value;
    if (isBlurEnabled) {
      let style: any = {
        width: "440px",
        minHeight: "250px",
      };
      this.openPopupList.push("PASSWORD_REQUIRED");
      const { ConferenceDialogComponent } = await import(
        "../shared/components/dialogs/conference-confirmation/conference-confirmation.component");
      this.dialog.open(ConferenceDialogComponent, Object.assign({
          backdropClass: "vnctalk-form-backdrop",
          panelClass: "vnctalk-form-panel",
          disableClose: true,
          data: {action: "enable_blur"},
          autoFocus: true
         }, style)).afterClosed().pipe(take(1)).subscribe(data => {
          if (data && data.enableBlur) {
            this.sendAnalytics(this.createVideoBlurEvent("started"));
            this.toggleBlurEffect(true);
          }
         });
    } else {
      this.sendAnalytics(this.createVideoBlurEvent(isBlurEnabled ? "started" : "stopped"));
      this.toggleBlurEffect(isBlurEnabled);
    }

    this.logger.info("[toggleBlur]", isBlurEnabled);
  }

  isBlurEnabled(): Observable<boolean> {
    return this.blurBackground$.asObservable();
  }

  toggleBlurEffect(enabled: boolean) {
    if (this.localVideo) {
      return this.getBlurEffect()
          .then(blurEffectInstance =>
            this.localVideo.setEffect(enabled ? blurEffectInstance : undefined)
                  .then(() => {
                    this.blurBackground$.next(enabled);
                    this.logger.info("[toggleBlurEffect]", this.localVideo, enabled);
                  })
                  .catch(error => {
                    this.localVideo.setEffect(undefined);
                    this.logger.info("[toggleBlurEffect] error", error);
                  })
          )
          .catch(error => {
            this.localVideo.setEffect(undefined);
            this.logger.info("[toggleBlurEffect] error", error);
          });
    } else {
      return Promise.resolve();
    }
  }

  getBlurEffect() {
    const ns = this.getJitsiMeetGlobalNS();

    if (ns.effects && ns.effects.createBlurEffect) {
        return ns.effects.createBlurEffect();
    }

    return this.loadScript("assets/js/video-blur-effect.min.js").then(() => ns.effects.createBlurEffect());
  }

  getJitsiMeetGlobalNS() {
    if (!window.JitsiMeetJS) {
        window.JitsiMeetJS = {};
    }

    if (!window.JitsiMeetJS.app) {
        window.JitsiMeetJS.app = {};
    }

    return window.JitsiMeetJS.app;
  }

  loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) =>
        JitsiMeetJS.util.ScriptUtil.loadScript(
            url,
            /* async */ true,
            /* prepend */ false,
            /* relativeURL */ false,
            /* loadCallback */ resolve,
            /* errorCallback */ reject));
  }

  resetTileMode(delayed = false) {

    setTimeout(() => {
      let hasScreenShared = false;
      if (this.someoneshouldStartScreenshare) {
        if (!!this._localTracks$.value.find(track => track.type === "video"
        && (!track.disposed && (track.videoType === "desktop" || track.videoType === "screen")))) {
          hasScreenShared = true;
          this.logger.info("[resetTileMode] hasScreenShared local");
          this.localHasScreenShared$.next(true);
        } else {
          this.localHasScreenShared$.next(false);
        }
        // this.logger.info("[resetTileMode] hasScreenSharedRemoteTracks: ", this._remoteTracks$.value);
        for (let id of Object.keys(this._remoteTracks$.value)) {
          this.logger.info("[resetTileMode] hasScreenShared check remote", this._remoteTracks$.value, [id]);
          if (!!this._remoteTracks$.value[id] && !!this._remoteTracks$.value[id].find(track => track.type === "video"
            && (!track.disposed && (track.videoType === "desktop" || track.videoType === "screen")))) {
            hasScreenShared = true;
            this.logger.info("[resetTileMode] hasScreenShared remote", this._remoteTracks$.value[id]);
            setTimeout(() => {
              this.broadcaster.broadcast("streamCheckUpdate");
            }, 300);
            if (!delayed) {
              setTimeout(() => {
                this.logger.info("[resetTileMode] hasScreenShared check remote delayed");
                this.resetTileMode(true);
              }, 4000);
            } else {
              if (hasScreenShared && this.screenSharePresenterParticipant$.value === "") {
                hasScreenShared = false;
              }
              this.broadcaster.broadcast("checkAndFixCamButton");
            }
          }
        }
        this.logger.info("[resetTileMode] hasScreenShared", hasScreenShared);
        if (!this.localHasScreenShared$.value) {
          this.remoteHasScreenShared$.next(hasScreenShared);
          // this.logger.info("[resetTileMode] hasScreenShared resetTilemode -> fakeParticipantInactive");
        }
        if (!hasScreenShared) {
          this.someoneshouldStartScreenshare = false;
          this.broadcaster.broadcast("RESET_TILE_MODE");
        }
      }

    }, 1000);
  }

  toggleE2EE(enabled?: boolean) {
    if (this.room) {
      this.room.toggleE2EE(enabled);
    }
    this.sendAnalytics(this.createE2EEEvent(`enabled.${String(enabled)}`));
    // Broadcast that we enabled / disabled E2EE.
    // const participant = getLocalParticipant(getState);
    // dispatch(participantUpdated({
    //   e2eeEnabled: action.enabled,
    //     id: participant.id,
    //     local: true
    // }));
    // this.store.dispatch(new ParticipantUpdated({}))
  }

  // LOBBY FLOW


  hideLobbyScreen() {
    // Close lobby dialog
    if (this.lobbyDialog && this.lobbyDialog.close) {
      this.lobbyDialog.close();
    }
  }

  async openLobbyScreen() {
    // Open lobby dialog
    const { VNCLobbyScreenComponent } = await import(
    "../shared/components/dialogs/lobby-screen");
    this.lobbyDialog = this.dialog.open(VNCLobbyScreenComponent, {
      backdropClass: "lobby-backdrop",
      panelClass: "lobby-panel",
      disableClose: true,
      autoFocus: true,
      width: "100vw",
      maxHeight: "100vh",
      maxWidth: "100vw",
      height: "100vh",
      data: {roomName: this.roomName}
    });
    this.lobbyDialog.afterClosed().pipe(take(1)).subscribe(() => {
      this.lobbyDialog = null;
    });
  }

  startKnocking() {

  }

  enableLobby() {
    if (this.room && this.room.isModerator()) {
      return this.room.enableLobby();
    } else {
      this.sendDataOnce("enableLobby", "");
      return new Promise((resolve) => {
        resolve("ok");
      });
    }
  }

  isLobbySupported() {
    return this.room && this.room.isLobbySupported && this.room.isLobbySupported();
  }

  disableLobby() {
    if (this.room && this.room.isModerator()) {
      this.room && this.room.disableLobby();
    } else {
      this.sendDataOnce("disableLobby", "");
    }
  }

  joinLobby(displayName, email) {
    this.displayName = displayName;
    this.room.setDisplayName(this.displayName);
    this.store.dispatch(new XmppSession({bare: email}));
    return this.room.joinLobby(displayName, email);
  }

  maybeSendLobbyNotification(participant, message) {
    // this.logger.info("[maybeSendLobbyNotification]", participant, message);
    if (!participant?._id || message?.type !== "lobby-notify") {
      return;
    }
    const notificationProps: any = {
      descriptionArguments: {
        originParticipantName: this.getDisplayName(participant?._id),
        targetParticipantName: message.name
      },
      titleKey: "lobby.notificationTitle"
    };

    switch (message.event) {
      case "LOBBY-ENABLED":
        notificationProps.descriptionKey = `lobby.notificationLobby${message.value ? "En" : "Dis"}abled`;
        break;
      case "LOBBY-ACCESS-GRANTED":
        notificationProps.descriptionKey = "lobby.notificationLobbyAccessGranted";
        break;
      case "LOBBY-ACCESS-DENIED":
        notificationProps.descriptionKey = "lobby.notificationLobbyAccessDenied";
        break;
    }

  }

  setKnockingParticipantApproval(participantId: string, approved?: boolean) {
    if (this.room && this.room.isModerator()) {
      if (approved) {
        this.room.lobbyApproveAccess(participantId);
      } else {
        this.room.lobbyDenyAccess(participantId);
      }
    } else {
      const value = JSON.stringify({participantId, approved});
      this.sendDataOnce("setKnockingParticipantApproval", value);
    }
  }

  toggleBackgroundEffect(options: any, skipAutoSet?: boolean) {
    this.logger.info("[JitsiService][toggleBackgroundEffect]", options, this.localVideo, this.isJoined, this.room);
    if (environment.isCordova || CommonUtil.isOnSafari()) {
      return;
    }
    if (!options.virtualSource?.startsWith("data:image") && !!options.enabled && !(options.backgroundType === "blur")) {
      this.logger.info("[JitsiService][toggleBackgroundEffect] invalid background - bailing out");
      return;
    }
    this.store.dispatch(new BackgroundEffectEnabled(options.enabled));
    this.store.dispatch(new SetVirtualBackground(options));
    if (typeof createTFLiteModule !== "undefined" && typeof createTFLiteSIMDModule !== "undefined") {
      (async () => {
        if (this.localVideo && !skipAutoSet && this.isJoined) {
          try {
            if (options.enabled) {
              this.changeBackgroundNotification();
              await this.localVideo.setEffect(await createVirtualBackgroundEffect(options));
              this.logger.info("[toggleBackgroundEffect] started", this.localVideo);
            } else {
              await this.localVideo.setEffect(undefined);
            }
            this.logger.info("[toggleBackgroundEffect] DONE");
          } catch (error) {
            this.store.dispatch(new BackgroundEffectEnabled(false));
            // await this.localVideo.setEffect(undefined);
            this.logger.info("[toggleBackgroundEffect]Error on apply background effect:", error, this.room.getLocalTracks());
            this.attachTrack(this.localVideo, this.myUserId());
            this.restartMediaSessionIfRequired(error);
          }
        }
      })();
    }
  }

  restartMediaSessionIfRequired(error) {
    this.logger.info("[restartMediaSessionIfRequired]", error);
    const secondTrackError = "Cannot add second video track to the conference";
    if (error.message && error.message.indexOf(secondTrackError) !== -1
    || typeof error === "string" && error?.indexOf(secondTrackError) !== -1) {
      console.warn("[restartMediaSessionIfRequired]  RECONNECTING_IN_CALL3 Cannot add second video track to the conference - isRecording: ", this.isRecording);
      this.notificationService.openSnackBarWithTranslation("RECONNECTING_IN_CALL", {}, 5000);
      this.room._restartMediaSessions();
    }
  }

  addStoredImage(image) {
    this.localImages.push(image);
    try {
      localStorage.setItem("virtualBackgrounds", JSON.stringify(this.localImages));
    } catch (err) {
        // Preventing localStorage QUOTA_EXCEEDED_ERR
        err && this.deleteStoredImage(this.localImages[0]);
    }
    if (this.localImages.length === backgroundsLimit) {
        this.deleteStoredImage(this.localImages[0]);
    }
    this.store.dispatch(new SetUploadedBackground(this.localImages));
  }

  deleteStoredImage(image) {
    this.localImages = this.localImages.filter(item => item !== image);
    try {
      localStorage.setItem("virtualBackgrounds", JSON.stringify(this.localImages));
    } catch (err) {
        // Preventing localStorage QUOTA_EXCEEDED_ERR
        err && this.deleteStoredImage(this.localImages[0]);
    }
    if (this.localImages.length === backgroundsLimit) {
        this.deleteStoredImage(this.localImages[0]);
    }
    this.store.dispatch(new SetUploadedBackground(this.localImages));
  }

  enableBlur(blurValue, selection, skipApply?: boolean) {
    this.toggleBackgroundEffect({
      backgroundType: "blur",
      enabled: true,
      blurValue,
      selectedThumbnail: selection
    }, skipApply);
  }

  setUploadedImageBackground(image) {
    this.toggleBackgroundEffect({
      backgroundType: "image",
      enabled: true,
      virtualSource: image.src,
      selectedThumbnail: image.id
    });
  }

  setImageBackground(image, skipApply?: boolean) {
    this.logger.info("[setImageBackground] start");
    toDataURL(image.src).then(async (url: any) => {
      this.logger.info("[setImageBackground] loaded url");
      await this.toggleBackgroundEffect({
        backgroundType: "image",
        enabled: true,
        virtualSource: url,
        selectedThumbnail: image.id
      }, skipApply);
      this.logger.info("[setImageBackground] done");
    }).catch(err => {
      this.logger.error("[setImageBackground] err", err);
    });
  }

  uploadImage(imageFile, height: number = 1080, width: number = 1920) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = async () => {
        const url = await resizeImage(reader.result,width, height);
        const uuId = CommonUtil.md5(CommonUtil.randomId(50, true));
        this.addStoredImage({
          id: uuId,
          src: url
        });
        resolve({
          id: uuId,
          src: url
        });
      };
      reader.onerror = () => {
        reject("none");
        this.logger.error("Failed to upload virtual image!");
      };
    });

  }

  removeBackground() {
    this.toggleBackgroundEffect({
      enabled: false,
      selectedThumbnail: "none"
    });
  }

  receiverEndpointMessageListener(origin, sender){
    // this.logger.info("[JitsiConferenceEvents][ENDPOINT_MESSAGE_RECEIVED]", origin, sender);
    this.maybeSendLobbyNotification(origin, sender);
  }

  destroyLocalTracks() {
    this.cancelGUMProcesses(this._localTracks$.value).finally(() => {
      this.logger.info("[processLeaveConference][cancelGUMProcesses]disposeAndRemoveAllExistingTracks");
      this.disposeAndRemoveAllExistingTracks();
    });
  }

  toggleMuteEveryOne() {
    this.mutedEveryone = !this.mutedEveryone;
    this.store.dispatch(new ToggleMuteEveryone(this.mutedEveryone));
    this.sendDataOnce(ConstantsUtil.TOGGLE_MUTE_EVERYONE_COMMAND, JSON.stringify({isMuted: this.mutedEveryone}));
  }

  sendUserInfo(data) {
    this.sendDataOnce(ConstantsUtil.USER_INFO, JSON.stringify(data));
  }

  toggleMuteCamera() {
    this.mutedAllCamera = !this.mutedAllCamera;
    this.store.dispatch(new ToggleMuteCamera(this.mutedAllCamera));
    this.sendDataOnce(ConstantsUtil.TOGGLE_MUTE_CAMERA_COMMAND, JSON.stringify({isMuted: this.mutedAllCamera}));
  }

  /**
   * Start using provided video stream.
   * Stops previous video stream.
   * @param {JitsiLocalTrack} newTrack - new track to use or null
   * @returns {Promise}
   */
   useVideoStream(newTrack: any) {
    this.logger.info("[Jitsiservice][useVideoStream]", newTrack);

    this.isSwitchCameraInProgress = false;

    return new Promise((resolve) => {
        _replaceLocalVideoTrackQueue.enqueue(onFinish => {
          this.replaceOrAddLocalTrack(this.localVideo, newTrack);
          onFinish();
          resolve(newTrack);
        });
    });
  }

  /**
   * Start using provided audio stream.
   * Stops previous audio stream.
   * @param {JitsiLocalTrack} newTrack - new track to use or null
   * @returns {Promise}
   */
  useAudioStream(newTrack: any, isFromScreenshare?: boolean) {
    this.logger.info(`[JitsiService] useAudioStream`, newTrack);
    this.checkLocalAudio(newTrack.stream);

    window.backupTrack = newTrack;
    return new Promise((resolve) => {
      _replaceLocalAudioTrackQueue.enqueue(onFinish => {
          this.replaceOrAddLocalTrack(this.localAudio, newTrack, isFromScreenshare);
          onFinish();
          resolve(newTrack);
        });
    });
  }

  private processNewTrackAndDisposeOldIfNeeded(oldTrack, newTrack) {
    this.logger.info(`[JitsiService][processNewTrackAndDisposeOldIfNeeded]`, {oldTrack, newTrack});

    // We call dispose after doing the replace because dispose will
    // try and do a new o/a after the track removes itself. Doing it
    // after means the JitsiLocalTrack.conference is already
    // cleared, so it won't try and do the o/a.
    const disposePromise
          = oldTrack
              ? this._disposeAndRemoveTracks([ oldTrack ])
              : Promise.resolve();

    return disposePromise
        .finally(() => {
            if (newTrack) {
              // The mute state of the new track should be
              // reflected in the app's mute state. For example,
              // if the app is currently muted and changing to a
              // new track that is not muted, the app's mute
              // state should be falsey. As such, emit a mute
              // event here to set up the app to reflect the
              // track's mute state. If this is not done, the
              // current mute state of the app will be reflected
              // on the track, not vice-versa.
              const isMuted = newTrack.isMuted();

              this.sendAnalytics(this.createTrackMutedEvent(
                  newTrack.getType(),
                  "track.replaced",
                  isMuted));

              this.logger.info(`[JitsiService][replaceStoredTracks] replace '${newTrack.getType()}' track (${
                  isMuted ? "muted" : "unmuted"})`);

              if (newTrack.isVideoTrack()) {
                if (isMuted) {
                  this.store.dispatch(new ConferenceMuteVideo());
                } else {
                  this.store.dispatch(new ConferenceUnMuteVideo());
                }
              } else {
                if (isMuted) {
                  this.store.dispatch(new ConferenceMuteAudio());
                } else {
                  this.store.dispatch(new ConferenceUnMuteAudio());
                }
              }

              this.addTrackIfNotAdded(newTrack);
            }
        });
  }

  createTrackMutedEvent(mediaType, reason, muted = true) {
    return {
        action: "track.muted",
        attributes: {
            "media_type": mediaType,
            muted,
            reason
        }
    };
  }

  private _disposeAndRemoveTracks(tracks) {
    return this._disposeTracks(tracks)
            .then(() =>
                Promise.all(tracks.map(t => this.trackRemoved(t))));
  }

  private _disposeTracks(tracks) {
    return Promise.all(
      tracks.map(t =>
        t.dispose()
          .catch(err => {
            // Track might be already disposed so ignore such an error.
            // Of course, re-throw any other error(s).
            if (err.name !== JitsiMeetJS.errors.track.TRACK_IS_DISPOSED) {
              this.logger.error("[JitsiService][_disposeTracks] track is already disposed", tracks);
            }
          })));
  }

  private handleZeroUpload(uploadrate: number) {
    console.warn("[JitsiService][handleZeroUpload]", uploadrate);
    // debugIos
    return;
    if (uploadrate > 0) {
      this.zeroUploadCount = 0;
      this.handleRecoverAttempts(true);
      return;
    } else {
      ++this.zeroUploadCount;
      if (this.zeroUploadCount > 1) {
        this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
          if (type && type.startsWith("screen") && this.room || this.hasScreenShare() ) {
            this.logger.info("[JitsiService] skip detected stale connection1 as we are in screenshare only");
          } else {
            this.zeroUploadCount = 0;
            this.logger.error("[JitsiService] RECONNECTING_IN_CALL4 detected stale connection1, restartMediaSessions to try and fix it, isRecording: ", this.isRecording);

            this.room._restartMediaSessions();
            this.notificationService.openSnackBarWithTranslation("RECONNECTING_IN_CALL", {}, 5000);
            this.handleRecoverAttempts(false);
          }
        });
      }
    }
  }

  private handleZeroRemoteTracks(uploadrate) {
    // debugIos
    return;

    if (uploadrate > 0) {
      this.zeroRemoteTracks = 0;
      this.handleRecoverAttempts(true);
      return;
    } else {
      ++this.zeroRemoteTracks;
      if (this.zeroRemoteTracks > 1) {
        this.zeroRemoteTracks = 0;
        if (this.room) {
          this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
            if ((type && type.startsWith("screen")) || (this.isRecording)) {
              this.logger.info("[JitsiService] skip detected stale connection2 as we are in screenshare or recording: ", this.isRecording);
            } else {
              this.handleRecoverAttempts(false);
              console.warn("[JitsiService] RECONNECTING_IN_CALL5 detected stale connection2, restartMediaSessions to try and fix it, isRecording: ", this.isRecording);
              this.room._restartMediaSessions();
              this.notificationService.openSnackBarWithTranslation("RECONNECTING_IN_CALL", {}, 5000);
            }
          });
        } else {
          // show rejoin popup
          console.warn("[JitsiService] detected stale connection2, CALL_DROP_TRIGGER 1");
          // this.broadcaster.broadcast("CALL_DROP_TRIGGER");
        }
      }
    }
  }

  private handleRecoverAttempts(reset: boolean) {
    if (reset) {
      this.recoverAttempts = 0;
    } else {
      ++this.recoverAttempts;
      // recover attempts did not help - let user rejoin
      if (this.recoverAttempts > 3) {
        console.warn("[JitsiService] handleRecoverAttempts, CALL_DROP_TRIGGER 2");
        this.broadcaster.broadcast("CALL_DROP_TRIGGER", "handleRecoverAttempts");
      }
    }
  }

  private handleOwnStats(stats: any) {
    const localRoomTracks = this.room.getLocalTracks();
    /* this.logger.info("[jitsiservice][handleLowStats] stats ", stats);
    this.logger.info("[jitsiservice][handleLowStats] tracks ", localRoomTracks);
    this.logger.info("[jitsiservice][handleLowStats] this.videoQuality ", this.videoQuality$.value); */
    if (!!stats.framerate && (stats.framerate !== "N/A") && (stats.framerate < 8)) {
      // need to decrese quality 1 step
      this.switchDownVideoQuality();
    }
    if (!!stats.framerate && (stats.framerate !== "N/A") && (stats.framerate > 24)) {
      // need to decrese quality 1 step
      // this.switchDownVideoQuality();
      if (!!stats.bitrate && (stats.bitrate !== "↓N/A ↑N/A") && stats.packetLoss && (stats.packetLoss !== "N/A")) {
        try {
          let up = parseInt(stats.bitrate.split("↑")[1].split("K")[0]);
          let plUp = parseInt(stats.packetLoss.split("↑")[1].split("%")[0]);
          let plDown = parseInt(stats.packetLoss.split("↓")[1].split("%")[0]);
          if ((up < 2500) && (plUp < 5) && (plDown < 5))  {
            this.switchUpVideoQuality();
          }
        } catch (e) {
          this.logger.info("[jitsiservice][handleRemoteStats] error ", e);
        }
      }
      this.logger.info("[jitsiservice][handleOwnStats] this.localVideo ", this.localVideo);
    }


    let isOnFirefox = /firefox/i.test(navigator.userAgent.toLowerCase());
    if (isOnFirefox && (stats.connectionQuality === "poor")) {
      this.switchDownVideoQuality();
    }
    if (isOnFirefox && (stats.connectionQuality === "good")) {
      this.switchUpVideoQuality();
    }

  }

  private handleRemoteStats(stats:any) {
    return;
    this.logger.info("[jitsiservice][handleRemoteStats] stats ", stats);
    if (!!stats.bitrate && (stats.bitrate !== "↓N/A ↑N/A")) {
      try {
        let up = parseInt(stats.bitrate.split("↑")[1].split("K")[0]);
        let down = parseInt(stats.bitrate.split("↓")[1].split("K")[0]);
        if ((down > (up * 2.5)) && (up > 10)) {
          this.logger.info("[jitsiservice][handleRemoteStats] detected slow remote - throttling quality ", stats.bitrate);
          this.switchDownVideoQuality();
        }
      } catch (e) {
        this.logger.info("[jitsiservice][handleRemoteStats] error ", e);
      }
    }
    if (!!stats.resolution && (stats.resolution !== "N/A")) {

      try {
        if (stats.resolution < this.minRemoteQuality) {
          this.minRemoteQuality = stats.resolution.split("x")[0];
          if (this.minRemoteQuality < 240) {
            this.switchDownVideoQuality();
          }
        }
      } catch (e) {
        this.logger.info("[jitsiservice][handleRemoteStats] error ", e);
      }

    }
  }

  private switchDownVideoQuality(force?: boolean) {
    let n = Date.now();
    this.logger.info("[jitsiservice][switchDownUpVideoQuality] down from ", this.videoQuality$.value, n);
    if (!this.remoteHasScreenShared$.value) {

      if (((n - this.switchedQuality) > 3000) || force) {
        this.switchedQuality = n;
        switch(this.videoQuality$.value) {
          case VideoQuality.MEDIUM:
          this.videoQuality$.next(VideoQuality.LOW);
          break;

          case VideoQuality.MEDIUM:
          this.videoQuality$.next(VideoQuality.LOW);
          break;
          case VideoQuality.STANDARD:
          this.videoQuality$.next(VideoQuality.MEDIUM);
          break;
          case VideoQuality.GOOD:
          this.videoQuality$.next(VideoQuality.STANDARD);
          break;
          case VideoQuality.BETTER:
          this.videoQuality$.next(VideoQuality.GOOD);
          break;
          case VideoQuality.HIGH:
          this.videoQuality$.next(VideoQuality.BETTER);
          break;
        }
      }
    }
  }

  private switchUpVideoQuality() {
    let n = Date.now();
    this.logger.info("[jitsiservice][switchDownUpQuality] up from", this.videoQuality$.value, n);
    if ((n - this.switchedQuality) > 6000) {
      this.switchedQuality = n;
      switch(this.videoQuality$.value) {
        case VideoQuality.LOW:
        this.videoQuality$.next(VideoQuality.MEDIUM);
        break;

        case VideoQuality.MEDIUM:
        this.videoQuality$.next(VideoQuality.STANDARD);
        break;
        case VideoQuality.STANDARD:
        this.videoQuality$.next(VideoQuality.HIGH);
        break;
      }
    }
  }

  checkLocalAudio(stream) {
    this.logger.info("[JitsiService][checkLocalAudioLevel]");
    // return;
    const clonedStream = stream.clone();
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(clonedStream);
    const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = async (audioProcessEvent) => {
      const tracks = stream.getAudioTracks();
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;

      const length = array.length;
      for (let index = 0; index < length; index++) {
        values += (array[index]);
      }
      const average = values / length;
      // this.volumeLevel = Math.round((Math.round(average)) / 10) * 4;
      if (this.localAudioMuted) {
        const level = Math.round((Math.round(average)) / 10) * 4;
        this.logger.info("[JitsiService][checkLocalAudioLevel]", level);
        if (level > 5) {
          this.broadcaster.broadcast("talkWhileMuted");
        }
      }

      if (!!tracks[0] && !!tracks[0].readyState && (tracks[0].readyState === "ended")) {
        this.logger.info("[JitsiService][checkLocalAudioLevel] ended, audioContext: ", audioContext);
        try {
          await audioContext.close();
        } catch (error) {
          this.logger.info("[JitsiService][checkLocalAudioLevel] ended, audioContext already closed: ", error);
        }
        javascriptNode.disconnect();
        analyser.disconnect();
        microphone.disconnect();

        this.logger.info("[JitsiService][checkLocalAudioLevel] ended, cleanup tracks: ", tracks);
        const clonedTracks = clonedStream.getTracks();
        clonedTracks.forEach(t => {
          t.stop();
          clonedStream.removeTrack(t);
        });
        this.logger.info("[JitsiService][checkLocalAudioLevel] ended, cleanup streamtracks: ", clonedStream, clonedStream.getTracks());
        /* clonedStream.removeTrack(tracks[0]);
        tracks[0].stop();
        tracks[0] = null; */
        javascriptNode.disconnect();
        analyser.disconnect();
        microphone.disconnect();
      }
    };
  }

  handleRejoinCall() {
    this.rejoinCall();
  }

  changeBackgroundNotification() {
    this.notificationService.openSnackBarWithTranslation("ADD_BACKGROUND_MSG", {}, 5000);
  }

  //polling for speaker stats updates
  startSpeakerStatsUpdates() {
    this.stopSpeakerStatsUpdates();
    this._updateInterval = setInterval(this._updateStats.bind(this), 1000);
  }

  // Stop polling for speaker stats updates.
  stopSpeakerStatsUpdates() {
    if (this._updateInterval) {
      clearInterval(this._updateInterval);
    }
  }

  private _updateStats() {
    if (this.room) {
      this.speakerStats.next(this.room.getSpeakerStats());
    }
  }

  getPreviewMediaStream(constraints: any): Observable<any> {
    const response = new Subject();
    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      this.localSettingPreviewStreams.push(stream);
      response.next(stream);
    }).catch(err => {
        this.logger.error("[VNCSettingsComponent][changeInput] getUserMedia error", err, ",", err.message);
        this.logger.info("[VNCSettingsComponent][changeInput] getUserMedia error",
            environment.isElectron, CommonUtil.isOnMacOS(),
            err.message === "Could not start audio source",
            err.message === "Could not start video source"
        );

        if (environment.isElectron) {
            if (CommonUtil.isOnMacOS()) {
                if (err.message === "Could not start audio source") {
                    this.broadcaster.broadcast(BroadcastKeys.MIC_MACOS_PERMISSIONS_ERROR, err);
                } else if (err.message === "Could not start video source") {
                    this.broadcaster.broadcast(BroadcastKeys.CAM_MACOS_PERMISSIONS_ERROR, err);
                }
            } else {
                // other OS: TBD
            }
        }
      response.error(err.message);
    });
    return response.asObservable().pipe(take(1));
  }

  stopAllPreviewMediaStreams() {
    // wait and stop all streams after delay
    setTimeout(() => {
      if (this.localSettingPreviewStreams.length > 0) {
        this.localSettingPreviewStreams.forEach(stream => {
          stream.getTracks().forEach(track => {
            this.logger.info("[jitsiservice][shareScreen3] stopAllPreviewMediaStreams will stop ", track);
              track.stop();
          });
        });
      }
    }, 3000);
  }

  maybeRestartWorkAround() {
    ++this.restartWorkArounds;
    this.logger.info("[jitsiservice][maybeRestartWorkAround] will _restartMediaSessions");
      setTimeout(() => {
        this.logger.info("[jitsiservice][maybeRestartWorkAround] _restartMediaSessions");
        this.room._restartMediaSessions();
      }, 1000);
  }

  getLocalHasScreenShared() {
    return this.localHasScreenShared$.value;
  }

  phoneDial(phone) {
    if (!!this.room) {
      this.room.dial(phone);
    }
  }

  isJitsiAvailable() {
    return this.configService.get("jitsiAvailable");
  }

  getJitsiParticipants() {
    const jitsiParticipants = (!!this.room) ? this.room.getParticipants() : [];
    // this.logger.info("[jitsiService]startedcall1 room: ", this.room, jitsiParticipants);
    return jitsiParticipants;
  }

  requestMediaDeviceAccess(type?: string): Observable<any> {
    const response = new Subject();
    this.logger.info("[JitsiService][onCallAccepted][requestMediaDevicesAccess]", type);

    JitsiMeetJS.mediaDevices.enumerateDevices(type).then((granted) => {
      this.logger.info("[JitsiService][onCallAccepted][requestMediaDevicesAccess] grant", granted);
      response.next(granted);
    });
    return response.asObservable().pipe(take(1));
  }

  getRealScreenShareParticipantId() {
    return this.realScreenShareParticipantId$.value;
  }

  setCallViewValue(val) {
    this.callView = val;
  }

  getJitsiParticipantInfo() {
    return this.participantInfo.value;
  }

  getJitsiParticipantInfoById(id) {
    return !!this.participantInfo.value && !!this.participantInfo.value[id] ? this.participantInfo.value[id] : {};
  }

}

// source: https://github.com/jitsi/jitsi-meet/blob/master/react/features/stream-effects/audio-mixer/AudioMixerEffect.ts

/**
 * Class Implementing the effect interface expected by a JitsiLocalTrack.
 * The AudioMixerEffect, as the name implies, mixes two JitsiLocalTracks containing a audio track. First track is
 * provided at the moment of creation, second is provided through the effect interface.
 */
export class AudioMixerEffect {
  /**
   * JitsiLocalTrack that is going to be mixed into the track that uses this effect.
   */
  _mixAudio: any;

  /**
   * MediaStream resulted from mixing.
   */
  _mixedMediaStream: any;

  /**
   * MediaStreamTrack obtained from mixed stream.
   */
  _mixedMediaTrack: Object;

  /**
   * Original MediaStream from the JitsiLocalTrack that uses this effect.
   */
  _originalStream: Object;

  /**
   * MediaStreamTrack obtained from the original MediaStream.
   */
  _originalTrack: any;

  /**
   * Lib-jitsi-meet AudioMixer.
   */
  _audioMixer: any;

  /**
   * Creates AudioMixerEffect.
   *
   * @param {JitsiLocalTrack} mixAudio - JitsiLocalTrack which will be mixed with the original track.
   */
  constructor(mixAudio: any) {
    if (mixAudio.getType() !== MEDIA_TYPE.AUDIO) {
      throw new Error("AudioMixerEffect only supports audio JitsiLocalTracks; effect will not work!");
    }

    this._mixAudio = mixAudio;
  }

  /**
   * Checks if the JitsiLocalTrack supports this effect.
   *
   * @param {JitsiLocalTrack} sourceLocalTrack - Track to which the effect will be applied.
   * @returns {boolean} - Returns true if this effect can run on the specified track, false otherwise.
   */
  isEnabled(sourceLocalTrack: any) {
    // Both JitsiLocalTracks need to be audio i.e. contain an audio MediaStreamTrack
    return sourceLocalTrack.isAudioTrack() && this._mixAudio.isAudioTrack();
  }

  /**
   * Effect interface called by source JitsiLocalTrack, At this point a WebAudio ChannelMergerNode is created
   * and and the two associated MediaStreams are connected to it; the resulting mixed MediaStream is returned.
   *
   * @param {MediaStream} audioStream - Audio stream which will be mixed with _mixAudio.
   * @returns {MediaStream} - MediaStream containing both audio tracks mixed together.
   */
  startEffect(audioStream: MediaStream) {
    this._originalStream = audioStream;
    this._originalTrack = audioStream.getTracks()[0];

    this._audioMixer = JitsiMeetJS.createAudioMixer();
    this._audioMixer.addMediaStream(this._mixAudio.getOriginalStream());
    this._audioMixer.addMediaStream(this._originalStream);

    this._mixedMediaStream = this._audioMixer.start();
    this._mixedMediaTrack = this._mixedMediaStream.getTracks()[0];

    return this._mixedMediaStream;
  }

  /**
   * Reset the AudioMixer stopping it in the process.
   *
   * @returns {void}
   */
  stopEffect() {
    this._audioMixer.reset();
  }

  /**
   * Change the muted state of the effect.
   *
   * @param {boolean} muted - Should effect be muted or not.
   * @returns {void}
   */
  setMuted(muted: boolean) {
    this._originalTrack.enabled = !muted;
  }

  /**
   * Check whether or not this effect is muted.
   *
   * @returns {boolean}
   */
  isMuted() {
    return !this._originalTrack.enabled;
  }
}

export type MediaType = "audio" | "video" | "screenshare";

export const MEDIA_TYPE: {
  AUDIO: MediaType;
  SCREENSHARE: MediaType;
  VIDEO: MediaType;
} = {
  AUDIO: "audio",
  SCREENSHARE: "screenshare",
  VIDEO: "video"
};
