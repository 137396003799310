import { environment } from "app/environments/environment";
import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {ChannelService} from "../channel.service";
import {Observable, Subject} from "rxjs";
import {Attachment} from "../models/attachment.model";
import {Channel} from "../models/channel.model";
import {Topic} from "../models/topic.model";
import {SmartLinkSearchResultType} from "../smart-link-popup/smart-link-popup.component";
import {CommonUtil} from "../../talk/utils/common.util";
import {map, take, takeUntil} from "rxjs/operators";
import {UntypedFormControl} from "@angular/forms";
import {DEFAULT_AVATAR} from "../../common";
import {Router} from "@angular/router";
import {ChannelRepository} from "../repository/channel.repository";
import {combineLatest} from "rxjs";
import {TopicCommentScroll} from "../channel-detail/channel-detail.component";
import {
  DEFAULT_CHANNEL_THUMBNAIL_URL,
  SmartLinkActions,
  SmartLinkData,
  SmartLinkPreviewType,
  SmartLinkService
} from "../smart-link.service";
import {ChannelSnackbarService} from "../channel-snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {SmartObjectActions} from "../smart-objects/smart-objects.service";
import {StickyNoteActions, StickyNoteService} from "../sticky-note.service";
import {RemarkService} from "../remark.service";
import {CloseSmartObjectDialog} from "../../root.component";
import {Broadcaster} from "../../talk/shared/providers";

@Component({
  selector: "vp-smart-link-preview",
  templateUrl: "./smart-link-preview.component.html",
  styleUrls: ["./smart-link-preview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SmartLinkPreviewComponent implements OnInit,OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { smartLinkData: SmartLinkData[], smartLinkName: string, isEditable: boolean, shouldNavigate: boolean, previewMode?: boolean, isAnalyticsTopic?: boolean, isMobileView: boolean, openedFromSmartObject?: boolean },
              private dialog: MatDialog,
              private channelRepository: ChannelRepository,
              private dialogRef: MatDialogRef<SmartLinkPreviewComponent>,
              private _smartLinkService: SmartLinkService,
              private channelService: ChannelService,
              private channelSnackBarService: ChannelSnackbarService,
              private translate: TranslateService,
              private router: Router,
              private _stickyNoteService: StickyNoteService,
              private _remarkService: RemarkService,
              private _broadcaster: Broadcaster) {
    this._broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_SMART_LINK_PREVIEW_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  $selectedSmartLinkPreviewData = new BehaviorSubject<{ previewType: SmartLinkData["previewType"] | any, previewData: any, resultData: any }>(null);
  $filteredSmartSearchResults = new BehaviorSubject<SmartLinkData["resultData"][]>([]);
  $singlePreviewVisible = new BehaviorSubject<boolean>(false);
  $showFooterButtons = new BehaviorSubject<boolean>(false);
  $updateSmartlinkData = new Subject<any>();
  smartLinkControl = new UntypedFormControl();

  filterSmartLinkSearchResult = (searchResults: SmartLinkData["resultData"][]) => searchResults.filter(item => item?.hasOwnProperty("id"));

  defaultAvatar = DEFAULT_AVATAR;
  defaultChannelAvatar = DEFAULT_CHANNEL_THUMBNAIL_URL;
  allSmartLinkData: SmartLinkData[] = [];

  private isAlive$ = new Subject();
  private _channelPreviewMetadata = { offset: 0, limit: 25, total_count: 0 };

  menuOpened = false;
  showActions = false;
  swipe = {
    LEFT: "left",
    RIGHT: "right"
  };
  swipeLeftActive = "";
  swipeRightActive = "";

  smartObjectActions = SmartObjectActions;
  ngOnInit() {
    this.allSmartLinkData = [...this.data.smartLinkData];
    this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
    if (this.allSmartLinkData.length === 1) {
      this.changeSmartLinkPreviewMode(this.allSmartLinkData[0].resultData);
    }
    this.listenSearchControlChanges();
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

  handleSwipeForEntity(uid: string, direction: string) {
    const smartEntity = document.getElementById(uid);
    switch (direction) {
      case (this.swipe.LEFT):
        this.removeLeftSwipes();
        smartEntity.classList.add("wrapper-swiped-left");
        this.swipeLeftActive = uid;
        break;
      case (this.swipe.RIGHT):
        this.removeLeftSwipes();
        smartEntity.classList.remove("wrapper-swiped-left");
        this.swipeLeftActive = "";
        break;
    }
  }

  private removeLeftSwipes() {
    const alreadySwipedEntities = document.getElementsByClassName("wrapper-swiped-left");
    Array.from(alreadySwipedEntities).forEach(el => el.classList.remove("wrapper-swiped-left"));
  }


  changeSmartLinkPreviewMode(searchResult: SmartLinkData["resultData"]) {
    const smartLinkData = this.allSmartLinkData.find(item => item.resultData.resultType === searchResult.resultType && item.resultData.id === searchResult.id);
    this.$singlePreviewVisible.next(true);
    this.getMappedSmartLinkData(smartLinkData).pipe(takeUntil(this.isAlive$)).subscribe(mappedData => {
      this.$selectedSmartLinkPreviewData.next(mappedData);
    });
  }

  onHoverActionClick(event: { clickEvent: MouseEvent, actionType: "edit" | "delete" }, searchResult: SmartLinkData["resultData"]) {
    event?.clickEvent?.preventDefault();
    event?.clickEvent?.stopPropagation();
    switch (event.actionType) {
      case "edit": {
        const smartLinkData = this.allSmartLinkData.find(item => item.resultData.resultType === searchResult?.resultType && item.resultData.id === searchResult?.id);
        this.getSmartLinkPopupData(this.data.smartLinkName, event.clickEvent.clientX, event.clickEvent.clientY, true, searchResult, {title: smartLinkData?.linkText, description: smartLinkData?.description}, null, this.data.isMobileView).pipe(takeUntil(this.isAlive$)).subscribe((smartLinkData: SmartLinkData) => {
          if (!!smartLinkData) {
            this.$showFooterButtons.next(true);
            this.allSmartLinkData = this.allSmartLinkData
              .map(item => (item.resultData["object_type"] === searchResult["object_type"] && item.resultData.id === searchResult.id) ? smartLinkData : item);
            this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(item => item.resultData));
          }
        });
        break;
      }
      case "delete": {
        this.allSmartLinkData = this.allSmartLinkData
          .filter(item => !(item.resultData["object_type"] === searchResult["object_type"] && item.resultData.id === searchResult.id));
        this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
        this.$showFooterButtons.next(true);
        break;
      }
    }
  }

  handleMobileHeaderActionClicks(event) {
    if (this.$singlePreviewVisible.getValue() && this.allSmartLinkData.length > 1) {
       return this.clearSinglePreview();
    }
    this.onHeaderAndFooterActionClick({ clickEvent: event, actionType: "cancel" });
  }

  clearSinglePreview() {
    this.$singlePreviewVisible.next(false);
    this.$selectedSmartLinkPreviewData.next(null);
  }

  onHeaderAndFooterActionClick(event: { clickEvent: MouseEvent, actionType: "add" | "single_edit" | "delete" | "update" | "cancel" | SmartObjectActions }) {
    switch (event.actionType) {
      case "add": {
        const smartLinkName = this.data.smartLinkName;
        let alreadyLinkedObjects = {};
        this.allSmartLinkData.forEach(s => {
          alreadyLinkedObjects[s?.resultData?.id] = s?.resultType;
        });
        this.getSmartLinkPopupData(smartLinkName, event.clickEvent.clientX, event.clickEvent.clientY, false, null, null, alreadyLinkedObjects, this.data.isMobileView).pipe(takeUntil(this.isAlive$)).subscribe((smartLinkData) => {
          if (!!smartLinkData) {
            this.$showFooterButtons.next(true);
            this.data.isMobileView ? this.allSmartLinkData = [...this.allSmartLinkData, ...smartLinkData] : this.allSmartLinkData.push(smartLinkData);
            this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
            this.$singlePreviewVisible.next(false);
            this.$selectedSmartLinkPreviewData.next(null);
          }
        });
        break;
      }
      case "single_edit": {
        const selectedSmartlinkPreviewData = this.$selectedSmartLinkPreviewData.value;
        const smartLinkData = this.allSmartLinkData.find(item => item.resultData.resultType === selectedSmartlinkPreviewData?.resultData?.resultType && item.resultData.id === selectedSmartlinkPreviewData?.resultData?.id);
        this.getSmartLinkPopupData(this.data.smartLinkName, event.clickEvent.clientX, event.clickEvent.clientY, true, selectedSmartlinkPreviewData?.resultData, {title: smartLinkData?.linkText, description: smartLinkData?.description}, null, this.data.isMobileView).pipe(takeUntil(this.isAlive$)).subscribe((value) => {
          if (!!value) {
            smartLinkData.linkText = value?.data?.title;
            smartLinkData.description = value?.data?.description;
            const oldPreviewData = this.$selectedSmartLinkPreviewData.getValue();
            this.allSmartLinkData = this.allSmartLinkData.map(item => (item.previewType === oldPreviewData.previewType && item.resultData.id === oldPreviewData.resultData.id) ? smartLinkData : item);
            this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
            this.changeSmartLinkPreviewMode(smartLinkData.resultData);
            this.$updateSmartlinkData.next({data: this.getEncodedSmartLinkData(this.allSmartLinkData)});
          }
        });
        break;
      }
      case "delete": return this.dialogRef.close({ action: SmartLinkActions.DELETE });
      case "update": return this.dialogRef.close( { data: this.getEncodedSmartLinkData(this.allSmartLinkData), action: SmartLinkActions.UPDATE } );
      case "cancel": return this.dialogRef.close();

      case SmartObjectActions.ADD_HIGHLIGHT: return this.onAddHighlight();
      case SmartObjectActions.ADD_STICKY_NOTE: return this.onAddStickyNote(event?.clickEvent);
      case SmartObjectActions.ADD_REFERENCE: return this.onAddReference(event?.clickEvent);
    }
  }

  openSmartLinkEntity() {
    let path = "";
    const resultType = this.$selectedSmartLinkPreviewData.value.previewType;
    const previewData = this.$selectedSmartLinkPreviewData.value.previewData;
    const $channelIdOb = this.channelRepository.getSelectedChannelId();
    const $topicIdOb = this.channelRepository.getSelectedTopicId();
    combineLatest([$channelIdOb, $topicIdOb])
      .pipe(takeUntil(this.isAlive$)
      , take(1))
      .subscribe(res => {
        const channelId = res[0];
        const topicId = res[1];
        if (resultType.toLowerCase().includes("channel")) {
          const { channelFullData } = previewData;
          if (!this.data.shouldNavigate) {
            path += `/talk/channels/${channelFullData.id}`;
            this.openPreviewInNewTab(path);
            return;
          }
          this.router.navigate([`talk/channels/${channelFullData.id}`], {
            queryParams: {
              parentChannelId: channelId,
              parentTopicId: topicId
            },
            queryParamsHandling: "merge"
          }).then(() => this.dialogRef.close({closePopup: true}));
        }
        if (resultType.toLowerCase().includes("topic")) {
          const { topicData } = previewData;
          if (!this.data.shouldNavigate) {
            path += `/talk/channels/${topicData?.channel_id}/topics/${topicData.id}`;
            this.openPreviewInNewTab(path);
            return;
          }
          this.router.navigate([`talk/channels/${topicData?.channel_id}/topics/${topicData.id}`], {
            queryParams: {
              parentChannelId: channelId,
              parentTopicId: topicId
            },
            queryParamsHandling: "merge"
          }).then(() => this.dialogRef.close({closePopup: true}));
        }
        if (resultType.toLowerCase().includes("comment")) {
          const { commentFullData } = previewData;
          if (!this.data.shouldNavigate) {
            path += `/talk/channels/${commentFullData?.channel_id}/topics/${commentFullData.topic_id}?comment=${TopicCommentScroll.COMMENT_ITEMS}`;
            this.openPreviewInNewTab(path);
            return;
          }
          this.router.navigate([`talk/channels/${commentFullData?.channel_id}/topics/${commentFullData.topic_id}`], {
            queryParams: {
              comment: TopicCommentScroll.COMMENT_ITEMS,
              parentChannelId: channelId,
              parentTopicId: topicId
            },
            queryParamsHandling: "merge"
          }).then(() => this.dialogRef.close({closePopup: true}));
        }
      });
  }

  private openPreviewInNewTab(path) {
    if (environment.isElectron) {
      const shell = window.electron.shell;
      const origin = localStorage.getItem("serverURL");
      shell.openExternal(`${origin}${path}`).then();
      return;
    }
    if (environment.isCordova) {
      const origin = localStorage.getItem("serverURL");
      if (device.platform === "iOS") {
        window.open(`${origin}${path}`, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(`${origin}${path}`, {
          openExternal: true
        });
      }
      return;
    }
    const newWindow = window.open(`${window.location.origin}${path}`, "_blank");
    newWindow.focus();
  }

  onPreviewScroll() {
    const selectedPreview = this.$selectedSmartLinkPreviewData.getValue();
    const isChannelPreview = selectedPreview.previewType === SmartLinkPreviewType.CHANNEL_MAX || selectedPreview.previewType === SmartLinkPreviewType.CHANNEL_MIN;
    if (isChannelPreview && (this._channelPreviewMetadata.offset < this._channelPreviewMetadata.total_count)) {
      const filterAndConcat = (oldTopics: Topic[], newTopics: Topic[]) => {
        const resultantTopics = [...oldTopics];
        newTopics.forEach(newTopic => {
          const index = oldTopics.findIndex(oldTopic => oldTopic.id === newTopic.id);
          if (index === -1) {
            resultantTopics.push(newTopic);
          }
        });
        return resultantTopics;
      };

      const channelId = selectedPreview.previewData.channelFullData.id;
      this.channelService.getTopics(channelId, this._channelPreviewMetadata.offset, this._channelPreviewMetadata.limit)
        .pipe(take(1))
        .subscribe(res => {
          const selectedPreview = this.$selectedSmartLinkPreviewData.getValue();
          const isChannelPreview = selectedPreview.previewType === SmartLinkPreviewType.CHANNEL_MAX || selectedPreview.previewType === SmartLinkPreviewType.CHANNEL_MIN;
          if (isChannelPreview) {
            this.updateChannelMetadata(res);
            res.topics = res.topics.map((t) => {
              return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
            });
            const oldTopics = selectedPreview.previewData.channelFullData.topics;
            const newTopics = res.topics;
            selectedPreview.previewData.channelFullData.topics = filterAndConcat(oldTopics, newTopics);
            this.$selectedSmartLinkPreviewData.next(selectedPreview);
          }
        });
    }
  }

  private listenSearchControlChanges() {
    this.smartLinkControl.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(value => {
      const filteredData = this.allSmartLinkData.filter(item => item.linkText.toLowerCase().includes((value || "").toLowerCase()));
      this.$filteredSmartSearchResults.next(filteredData.map(smartLinkData => smartLinkData.resultData));
    });
  }

  private getMappedSmartLinkData(smartLinkData: SmartLinkData) {
    const data = new BehaviorSubject<{ previewType: SmartLinkData["previewType"] | any, previewData: any, resultData: any }>({
      previewType: smartLinkData.previewType,
      previewData: this._smartLinkService.getMappedSmartLinkPreviewData(smartLinkData.resultData),
      resultData: smartLinkData.resultData
    });

    if (smartLinkData?.resultType === SmartLinkSearchResultType.CHANNEL) {
      const { channelFullData } = this._smartLinkService.getMappedSmartLinkPreviewData(smartLinkData.resultData);
      this.getChannelTopicAndMembers(smartLinkData.resultData.id).pipe(takeUntil(this.isAlive$)).subscribe(channelMembersAndTopics => {
        data.next({
          previewType: smartLinkData.previewType,
          previewData: { channelFullData: { ...channelFullData, ...channelMembersAndTopics } },
          resultData: smartLinkData.resultData
        });
      });
    }
    return data;
  }

  private getChannelTopicAndMembers(channelId: Channel["id"]) {
    const updateAttachmentsURLs = (attachments: Attachment[]): Attachment[] => {
      return attachments.map(attachment => {
        let content_url = CommonUtil.getAttachmentLocalAPIURL(attachment.content_url);
        let thumbnail_url = CommonUtil.getAttachmentLocalAPIURL(attachment.thumbnail_url);
        return {...attachment, content_url, thumbnail_url};
      });
    };

    const $channelMembersOb = this.channelRepository.getMembersOfChannel(channelId);
    const $channelTopicsOb = this.channelService.getTopics(channelId, this._channelPreviewMetadata.offset, this._channelPreviewMetadata.limit);
    return combineLatest([$channelMembersOb, $channelTopicsOb])
      .pipe(takeUntil(this.isAlive$)
      , map((res: any) => {
        const members = res[0];
        const topics = res[1]?.topics.map((t) => {
          return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
        });
        this.updateChannelMetadata(res[1]);
        return {
          members,
          topics: topics.map(topic => {
            let headerAttachments = topic?.attachments?.filter(attachment => attachment.is_header === true);
            const heroAttachments = updateAttachmentsURLs(headerAttachments);
            return {...topic, heroAttachments, thumbnailURL: heroAttachments?.[0]?.thumbnail_url};
          })
        };
      }));
  }

  private updateChannelMetadata(res: { topics: any[], offset: number, limit: number, total_count: number }) {
    let newOffset = (res?.offset || 0) + this._channelPreviewMetadata.limit;
    const total_count = res?.total_count || 0;
    newOffset = newOffset > total_count ? total_count : newOffset;
    this._channelPreviewMetadata = { offset: newOffset, total_count, limit: 25 };
  }

  private getSmartLinkPopupData(selectedText: string, clientX, clientY, skipFirstStep = false, searchResult = null, selectedSmartLinkData = null, alreadyLinkedObjects = null, isMobileView = false): Observable<any> {
    const smartLinkPopupRef = this._smartLinkService.getSmartLinkPopupRef(selectedText, clientX, clientY, true, skipFirstStep, searchResult, selectedSmartLinkData, alreadyLinkedObjects, isMobileView);
    return smartLinkPopupRef.afterClosed().pipe(map(value => isMobileView ? value?.data?.previewData : !!skipFirstStep ? value : value?.data?.previewData[0]), take(1));
  }

  private getEncodedSmartLinkData(smartLinkData: SmartLinkData[]) {
    return smartLinkData.map(item => {
      const encodedResultData = { entityId: item?.resultData?.id };

      if (item.previewType === SmartLinkPreviewType.TOPIC) {
        encodedResultData["metadata"] = { channelId: item?.resultData?.channel_id };
      }
      if (item.previewType === SmartLinkPreviewType.COMMENT) {
        encodedResultData["metadata"] = { topicId: item?.resultData?.topic_id };
        if (item?.resultData?.parent_comment_id) {
          encodedResultData["metadata"]["parentCommentId"] = item.resultData.parent_comment_id;
        }
      }

      return {
        ...item,
        resultData: encodedResultData
      };
    });
  }

  private getSmartLinkData() {
    return this.$filteredSmartSearchResults.getValue().map(obj => {

      const currentSL = this.allSmartLinkData.find(sl => sl?.resultData?.id === obj.id);

      const resultData = {entityId: obj?.id, entityType: obj?.resultType};

      const data = {
        description: currentSL?.description,
        linkText: currentSL?.linkText,
        previewType: currentSL?.previewType,
        resultType: currentSL?.resultType,
        searchQuery: currentSL?.searchQuery
      };

      if (obj?.resultType === SmartLinkPreviewType.TOPIC) {
        resultData["channelId"] = obj?.channel_id;
      }

      if (obj?.resultType === SmartLinkPreviewType.COMMENT) {
        resultData["topicId"] = obj?.topic_id;
        resultData["channelId"] = obj?.channel_id;
      }

      return {
        ...data,
        resultData
      };
    });
  }

  onAddHighlight() {
    const smartLinkData = this.getSmartLinkData();
    this.dialogRef.close({data: {smartLinkData}, action: SmartLinkActions.ADD_HIGHLIGHT});
  }

  onAddStickyNote(event) {
    const stickyNotePopupRef = this._stickyNoteService.openStickyNotePopup(event, "", false, false, this.data.isMobileView);
    stickyNotePopupRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data?.action === StickyNoteActions.SAVE) {
          const smartLinkData = this.getSmartLinkData();
          const { noteData } = data;
          const stickyNotesData = this._stickyNoteService.getMappedStickyNotesForReqPayload([noteData], true);
          this.dialogRef.close({data: {smartLinkData, stickyNotesData}, action: SmartLinkActions.ADD_STICKY_NOTE});
        }
      });
  }

  onAddReference(event) {
    const remarkPopupRef = this._remarkService.openRemarkPopup(event, this.data.isMobileView);
    remarkPopupRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data?.action === "save") {
          const smartLinkData = this.getSmartLinkData();
          const referencesData = this._remarkService.getMappedReferencesForReqPayload([data], true);
          this.dialogRef.close({data: {smartLinkData, referencesData}, action: SmartLinkActions.ADD_REFERENCE});
        }
      });
  }
}
