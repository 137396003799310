

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { VncLibraryService } from "vnc-library";
import { Observable } from "rxjs";
import { SnackbarPositionType } from "app/channels/models/snackbar.model";


@Injectable()
export class SocialSnackbarService {
  constructor(private vncLibaryService: VncLibraryService) {

  }

  openSnackBar(msg: string,
    snackbarType?: string,
    actionLabel?: string,
    link?: string,
    timeout?: number,
    snackbarVerticalPosition = SnackbarPositionType.BOTTOM,
    snackbarHorizontalPosition = SnackbarPositionType.LEFT
  ): Observable<any> {
    return this.vncLibaryService.openSnackBar(msg, snackbarType, actionLabel, link, timeout, snackbarVerticalPosition, snackbarHorizontalPosition);
  }
}
