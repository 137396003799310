/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {Attachment} from "../../talk/models/attachment.model";

export interface SocialProfile {
  header_image: Attachment;
  id: number;
  jid: string;
  avatar_url?: string;
  header_image_url?: string;
  firstname?: string;
  lastname?: string;
  following: boolean;
  followed_by: boolean;
  followers_count: number;
  following_count: number;
  bio?: string;
  role: string;
  company: string;
  email: string;
  languages: any;
  country: string;
  location: string;
  public_role: string;
  public_company: string;
  active?: boolean;
  name?: string;
}

export enum USER_POST_TYPE {
  ALL = "all",
  REPLIES = "replies",
  MEDIA = "media",
  LIKE = "like"
}
