
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createSelector } from "@ngrx/store";
import { PadState } from "./pad.reducer";
import { getTalkRootState } from "../../../reducers";
import { PadsCounter } from "../interfaces/pads-counter.interface";
import { ResponseCreatedPad } from "../interfaces/response-created-pad.interface";

export const getPadStateSelector = createSelector(
  getTalkRootState,
  state => state.padReducer
);

export const padsSelector = createSelector(
  getPadStateSelector,
  ({ pads }: PadState) => pads
);

export const isShowPadWindowSelector = createSelector(
  getPadStateSelector,
  ({ isShowPadWindow }: PadState) => {
    return { isShowPadWindow };
  }
);

export const getPadsCountSelector = createSelector(
  getPadStateSelector,
  ({ pads }: PadState) => new PadsCounter(pads.length)
);

export const getPadUrlForIframeSelector = createSelector(
  getPadStateSelector,
  ({ responseCreatedPad }: PadState): ResponseCreatedPad => responseCreatedPad
);

export const getPadsTarget = createSelector(
  getPadStateSelector,
  ({ currentTarget }: PadState) => currentTarget
);

export const getCurrentPadId = createSelector(
  getPadStateSelector,
  ({ currentPadId }: PadState) => currentPadId
);

export const getIsPadLoading = createSelector(
  getPadStateSelector,
  ({ isLoading }: PadState) => isLoading
);

export const getPadsTargetAndName = createSelector(
  getPadStateSelector,
  ({ currentTarget, currentPadName }: PadState) => {
    return {currentTarget, currentPadName};
  }
);

export const getCurrentPadInfo = createSelector(
  getPadStateSelector,
  ({ currentPadName, currentPadId }: PadState) => {
    return {currentPadName, currentPadId};
  }
);
