/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SocialProfile, USER_POST_TYPE } from "app/shared/models/social-profile.model";
import {Action} from "../../actions";
import { Post } from "../models/post.model";

export class ProfileActionTypes {
  static PROFILE_LOAD_REQUEST       = "[PROFILE] Load Request";
  static PROFILE_LOAD_SUCCESS       = "[PROFILE] Load Success";
  static SELECTED_PROFILE           = "[PROFILE] Selected PROFILE";
  static PROFILE_UPDATE             = "[PROFILE] Update PROFILE";
  static PROFILE_POSTS_LOAD_REQUEST = "[PROFILE] PROFILE Posts Load Request";
  static PROFILE_POSTS_LOAD_SUCCESS = "[PROFILE] PROFILE Posts Load Success";
  static PROFILE_POST_ADD          = "[PROFILE] PROFILE Posts Add";
  static PROFILE_POST_REMOVE       = "[PROFILE] PROFILE Posts Remove";

  static FILTERED_POSTS_LOAD_REQUEST = "[PROFILE] FILTERED Posts Load Request";
  static FILTERED_POSTS_LOAD_SUCCESS = "[PROFILE] FILTERED Posts Load Success";


  static PROFILE_FOLLOWERS_LOAD_REQUEST = "[PROFILE] Follower Load Request";
  static PROFILE_FOLLOWERS_LOAD_SUCCESS = "[PROFILE] Follower Load Success";
  static PROFILE_FOLLOWERS_ADD_ONE = "[PROFILE] Follower Add One";
  static PROFILE_FOLLOWERS_REMOVE_ONE = "[PROFILE] Follower Remove One";

  static PROFILE_FOLLOWING_LOAD_REQUEST = "[PROFILE] Following Load Request";
  static PROFILE_FOLLOWING_LOAD_SUCCESS = "[PROFILE] Following Load Success";
  static PROFILE_FOLLOWING_ADD_ONE = "[PROFILE] Following Add One";
  static PROFILE_FOLLOWING_REMOVE_ONE = "[PROFILE] Following Remove One";
}

export class ProfileLoadRequest implements Action {
  readonly type = ProfileActionTypes.PROFILE_LOAD_REQUEST;
}

export class ProfileLoadSuccess implements Action {
  readonly type = ProfileActionTypes.PROFILE_LOAD_SUCCESS;

  constructor(public payload: SocialProfile) {
  }
}

export class ProfileUpdate implements Action {
  readonly type = ProfileActionTypes.PROFILE_UPDATE;

  constructor(public payload: SocialProfile | Partial<SocialProfile>) {
  }
}

export class SetSelectedProfileId implements Action {
  readonly type = ProfileActionTypes.SELECTED_PROFILE;

  constructor(public payload: SocialProfile["jid"]) {
  }
}

export class ProfilePostsLoadRequest implements Action {
  readonly type = ProfileActionTypes.PROFILE_POSTS_LOAD_REQUEST;

  constructor(public payload: { userJID: SocialProfile["jid"], type: USER_POST_TYPE }) {
  }
}

export class FilteredPostsLoadRequest implements Action {
  readonly type = ProfileActionTypes.FILTERED_POSTS_LOAD_REQUEST;

  constructor(public payload: { type: USER_POST_TYPE, filter: string }) {
  }
}

export class ProfilePostsLoadSuccess implements Action {
  readonly type = ProfileActionTypes.PROFILE_POSTS_LOAD_SUCCESS;

  constructor(public payload: { userJID: SocialProfile["jid"], posts: Post[], type: USER_POST_TYPE, offset: number, total_count: number }) {
  }
}

export class FilteredPostsLoadSuccess implements Action {
  readonly type = ProfileActionTypes.FILTERED_POSTS_LOAD_SUCCESS;

  constructor(public payload: {filter: string, posts: Post[], type: USER_POST_TYPE, offset: number, total_count: number }) {
  }
}

export class ProfilePostAdd implements Action {
  readonly type = ProfileActionTypes.PROFILE_POST_ADD;

  constructor(public payload: { userJID: SocialProfile["jid"], postId: Post["id"], type: USER_POST_TYPE }) {
  }
}

export class ProfilePostRemove implements Action {
  readonly type = ProfileActionTypes.PROFILE_POST_REMOVE;

  constructor(public payload: { userJID: SocialProfile["jid"], postId: Post["id"], type: USER_POST_TYPE }) {
  }
}

export class ProfileFollowersLoadRequest implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWERS_LOAD_REQUEST;
  constructor(public payload: string) { }
}

export class ProfileFollowersLoadSuccess implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWERS_LOAD_SUCCESS;
  constructor(public payload: { jid: string, users: any[], total_count?: number, offset?: number }) {
  }
}

export class ProfileFollowersAddOne implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWERS_ADD_ONE;
  constructor(public payload: { jid: string, id: number }) { }
}

export class ProfileFollowersRemoveOne implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWERS_REMOVE_ONE;
  constructor(public payload: { jid: string, id: number }) { }
}

export class ProfileFollowingLoadRequest implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWING_LOAD_REQUEST;
  constructor(public payload: string) { }
}

export class ProfileFollowingLoadSuccess implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWING_LOAD_SUCCESS;
  constructor(public payload: { jid: string, users: any[], total_count?: number, offset?: number }) {
  }
}

export class ProfileFollowingAddOne implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWING_ADD_ONE;
  constructor(public payload: { jid: string, id: number }) { }
}

export class ProfileFollowingRemoveOne implements Action {
  readonly type = ProfileActionTypes.PROFILE_FOLLOWING_REMOVE_ONE;
  constructor(public payload: { jid: string, id: number }) { }
}
