/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Channel } from "app/channels/models/channel.model";
import { Action } from ".";
import { Member } from "../../models/member.model";
import { File } from "../../models/file.model";



export class ChannelActionTypes {
  static CHANNEL_LOAD_REQUEST = "[Channel] Load Request";
  static CHANNEL_LOAD_SUCCESS = "[Channel] Load Success";

  static CHANNEL_ADD = "[Channel] Add";
  static CHANNEL_DELETE = "[Channel] Delete";
  static CHANNEL_UPDATE = "[Channel] Update";
  static CHANNEL_BULK_ADD = "[Channel] Bulk Add";
  static CHANNEL_SEARCH_STRING_UPDATE = "[Channel] Search String Update";
  static CHANNEL_USER_JID_UPDATE = "[Channel] User Jid Update";
  static CHANNEL_INFO_UPDATE = "[Channel] All Channel Info Update";

  static SUBCHANNEL_ADD = "[Channel] Subchannel Add";
  static SUBCHANNEL_BULK_ADD = "[Channel] Subchannel Bulk Add";
  static SUBCHANNEL_DELETE = "[Channel] Subchannel Delete";
  static SUBCHANNEL_UPDATE = "[Channel] Subchannel Update";
  static SUBCHANNEL_INFO_UPDATE = "[Channel] Subchannel Info Update";

  static PRIVATE_CHANNEL_INFO_UPDATE = "[Channel] Private Channel Info Update";
  static PUBLIC_CHANNEL_INFO_UPDATE = "[Channel] Public Channel Info Update";
  static IOM_CHANNEL_INFO_UPDATE = "[Channel] IOM Channel Info Update";
  static SUBSCRIBED_CHANNEL_INFO_UPDATE = "[Channel] Subscribed Channel Info Update";
  static FAVORITE_CHANNEL_INFO_UPDATE = "[Channel] Favorite Channel Info Update";
  static MY_CHANNEL_INFO_UPDATE = "[Channel] My Channel Info Update";
  static INTRANET_CHANNEL_INFO_UPDATE = "[Channel] Intranet Info Update";
  static EXTRANET_CHANNEL_INFO_UPDATE = "[Channel] EXtranet Info Update";
  static FILTERED_TOPICS_INFO_UPDATE = "[Channel] Filtered Topics Info Update";
  static TOPIC_FILTERS_INFO_UPDATE = "[Channel] Topic Filters Info Update";
  static SEARCHED_CHANNEL_INFO_UPDATE = "[Channel] Searched Channel Info Update";
  static SELECTED_CHANNEL = "[Channel] Selected Channel";
  static ARCHIVE_CHANNELS_SORT_BY = "[Channel] Archive Channels Sort By";
  static CHANNEL_MEMBERS_LOAD_REQUEST = "[Channel] Channel Members Load Request";
  static CHANNEL_MEMBERS_LOAD_SUCCESS = "[Channel] Channel Members Load Success";
  static CHANNEL_MEMBERS_OFFSET_UPDATE = "[Channel] Channel Members Offset Update";
  static CHANNEL_MEMBERS_ADD = "[Channel] Channel Members Add";
  static CHANNEL_MEMBERS_UPDATE = "[Channel] Channel Members UPDATE";
  static CHANNEL_MEMBERS_REMOVE = "[Channel] Channel Members Remove";
  static CHANNEL_FILES_LOAD_REQUEST = "[Channel] Channel Files Load Request";
  static CHANNEL_FILES_LOAD_SUCCESS = "[Channel] Channel Files Load Success";
  static CHANNEL_FILES_OFFSET_UPDATE = "[Channel] Channel Files Offset Update";
  static CHANNEL_FILES_DELETE = "[Channel] Channel Files Delete";
  static CHANNEL_NO_ACCESS = "[Channel] Channel No Access";
  static CHANNEL_NOT_FOUND = "[Channel] Channel Not Found";
  static CHANNEL_SIDEBAR_TAB_CHANGE = "[Channel] Channel Sidebar Tab Change";
  static CHANNEL_AVATAR_FAILED_TO_LOAD = "[Channel] Channel Avatar Failed To Load";
  static CHANNEL_AVATAR_FAILED_TO_LOAD_REMOVE = "[Channel] Channel Avatar Failed To Load Remove";
  static TRASHED_CHANNEL_INFO_UPDATE = "[Channel] Trashed Channel Info Update";
  static ARCHIVED_CHANNEL_INFO_UPDATE = "[Channel] Archived Channel Info Update";
}



export class ChannelLoadRequest implements Action {
  readonly type = ChannelActionTypes.CHANNEL_LOAD_REQUEST;
}



export class ChannelLoadSuccess implements Action {
  readonly type = ChannelActionTypes.CHANNEL_LOAD_SUCCESS;
  constructor(public payload: any[]) {
  }
}



export class ChannelAdd implements Action {
  readonly type = ChannelActionTypes.CHANNEL_ADD;


  constructor(public payload: Channel) {
  }
}


export class TrashedChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.TRASHED_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class ArchivedChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.ARCHIVED_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}



export class ChannelUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_UPDATE;
  constructor(public payload: any) {
  }
}



export class ChannelDelete implements Action {
  readonly type = ChannelActionTypes.CHANNEL_DELETE;
  constructor(public payload: string) {
  }
}



export class ChannelBulkAdd implements Action {
  readonly type = ChannelActionTypes.CHANNEL_BULK_ADD;
  constructor(public payload: Channel[]) {
  }
}

export class SubChannelAdd implements Action {
  readonly type = ChannelActionTypes.SUBCHANNEL_ADD;
  constructor(public payload: Channel) {
  }
}

export class SubChannelDelete implements Action {
  readonly type = ChannelActionTypes.SUBCHANNEL_DELETE;
  constructor(public payload: string) {
  }
}

export class SubChannelUpdate implements Action {
  readonly type = ChannelActionTypes.SUBCHANNEL_UPDATE;
  constructor(public payload: any) {
  }
}

export class SubChannelBulkAdd implements Action {
  readonly type = ChannelActionTypes.SUBCHANNEL_BULK_ADD;
  constructor(public payload: Channel[]) {
  }
}

export class ChannelSearchStringUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_SEARCH_STRING_UPDATE;
  constructor(public payload: string) {
  }
}

export class ChannelUserJidUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_USER_JID_UPDATE;
  constructor(public payload: string) {
  }
}
// FOR ALL CHANNELS
export class ChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}
export class SubChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.SUBCHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, parent: { [key: string]: { subchannels: string[], allLoaded: boolean } | null }, isLoading: boolean, isLoaded: boolean}) {
  }
}
export class PrivateChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.PRIVATE_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class PublicChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.PUBLIC_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class IOMChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.IOM_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], subscribed: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class SubscribedChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.SUBSCRIBED_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class FavoriteChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.FAVORITE_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class IntranetChannelsInfoUpdate implements Action {
  readonly type = ChannelActionTypes.INTRANET_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class ExtranetChannelsInfoUpdate implements Action {
  readonly type = ChannelActionTypes.EXTRANET_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class MyChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.MY_CHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class FilteredTopicsInfoUpdate implements Action {
  readonly type = ChannelActionTypes.FILTERED_TOPICS_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean, channelId: string, filterApplied: boolean}) {
  }
}

export class TopicFiltersInfoUpdate implements Action {
  readonly type = ChannelActionTypes.TOPIC_FILTERS_INFO_UPDATE;
  constructor(public payload: {filters: any[], total_count: number}) {
  }
}


export class SearchedChannelInfoUpdate implements Action {
  readonly type = ChannelActionTypes.SEARCHED_CHANNEL_INFO_UPDATE;
  constructor(public payload: {keyword: string, ids: string[], totalCount: number, offset: number}) {
  }
}


export class SetSelectedChannelId implements Action {
  readonly type = ChannelActionTypes.SELECTED_CHANNEL;


  constructor(public payload: string) {
  }
}

export class SetArchiveChannelsSortBy implements Action {
  readonly type = ChannelActionTypes.ARCHIVE_CHANNELS_SORT_BY;


  constructor(public payload: string) {
  }
}



export class ChannelMembersLoadRequest implements Action {
  readonly type = ChannelActionTypes.CHANNEL_MEMBERS_LOAD_REQUEST;
  constructor(public payload: string) {
  }
}



export class ChannelMembersLoadSuccess implements Action {
  readonly type = ChannelActionTypes.CHANNEL_MEMBERS_LOAD_SUCCESS;
  constructor(public payload: { channelId: string, members: Member[], total_count: number }) {
  }
}

export class ChannelMemberOffsetUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_MEMBERS_OFFSET_UPDATE;
  constructor(public payload: { channelId: string, offset: number }) {
  }
}

export class ChannelMembersAdd implements Action {
  readonly type = ChannelActionTypes.CHANNEL_MEMBERS_ADD;
  constructor(public payload: { channelId: string, members: { jid: string, role: string }[] }) {
  }
}

export class ChannelMembersUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_MEMBERS_UPDATE;
  constructor(public payload: { channelId: string, members: { jid: string, role: string }[] }) {
  }
}

export class ChannelMembersRemove implements Action {
  readonly type = ChannelActionTypes.CHANNEL_MEMBERS_REMOVE;
  constructor(public payload: { channelId: string, memberIds: string[] }) {
  }
}

export class ChannelFilesLoadRequest implements Action {
  readonly type = ChannelActionTypes.CHANNEL_FILES_LOAD_REQUEST;


  constructor(public payload: Channel["id"]) {
  }
}

export class ChannelFilesLoadSuccess implements Action {
  readonly type = ChannelActionTypes.CHANNEL_FILES_LOAD_SUCCESS;
  constructor(public payload: { channelId: Channel["id"], files: File[], total_count: number }) {
  }
}

export class ChannelFilesOffsetUpdate implements Action {
  readonly type = ChannelActionTypes.CHANNEL_FILES_OFFSET_UPDATE;
  constructor(public payload: { channelId: string, offset: number }) {
  }
}

export class ChannelFilesRemove implements Action {
  readonly type = ChannelActionTypes.CHANNEL_FILES_DELETE;
  constructor(public payload: { fileId: number, channelId: string}) {
  }
}

export class ChannelNoAccess implements Action {
  readonly type = ChannelActionTypes.CHANNEL_NO_ACCESS;
  constructor(public payload: boolean) {
  }
}

export class ChannelNotFound implements Action {
  readonly type = ChannelActionTypes.CHANNEL_NOT_FOUND;
  constructor(public payload: boolean) {
  }
}

export class ChannelSideBarTabChange implements Action {
  readonly type = ChannelActionTypes.CHANNEL_SIDEBAR_TAB_CHANGE;
  constructor(public payload: string) {
  }
}

export class ChannelAvatarFailedToLoad implements Action {
  readonly type = ChannelActionTypes.CHANNEL_AVATAR_FAILED_TO_LOAD;
  constructor(public payload: string) {
  }
}

export class ChannelAvatarFailedToLoadRemove implements Action {
  readonly type = ChannelActionTypes.CHANNEL_AVATAR_FAILED_TO_LOAD_REMOVE;
  constructor(public payload: string) {
  }
}
