
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { map } from "rxjs/operators";

@Injectable()
export class RFCService {

  constructor(private middlewareService: MiddlewareService) {
  }

  searchUsers(keyword: string) {
    return this.middlewareService.get(`/api/rfc/search_users?keyword=` + keyword, true).pipe(map( (res: any) => res.users ));
  }

  sendInvitation(userId: number) {
    let data = {"rfc": {"to_user_id": userId }};
    return this.middlewareService.post(`/api/rfc/send`, true, data);
  }

  resendInvitation(token: string) {
    let data = {};
    return this.middlewareService.post(`/api/rfc/resend?token=` + token, true, data);
  }

  sendInvitationMessage(email: string, message: string) {
    let data = {"rfc": { "email": email, "message": message }};
    return this.middlewareService.post(`/api/rfc/send`, true, data);
  }

  acceptInvitation(token: string) {
    return this.middlewareService.get(`/api/rfc/accept?token=` + token, true);
  }

  declineInvitation(token: string) {
    return this.middlewareService.get(`/api/rfc/decline?token=` + token, true);
  }
}






