
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, AfterViewInit, Inject, InjectionToken } from "@angular/core";
import { User } from "../../models/user.model";
import { Subject, take, takeUntil } from "rxjs";
import { UntypedFormControl, Validators } from "@angular/forms";
import { TalkRootState } from "../../../talk/reducers";
import { Store } from "../../../../../node_modules/@ngrx/store";
import { JID } from "../../../talk/models/jid.model";
import { getIsAppOnline } from "../../../reducers";
import { ContactInformation } from "../../../talk/models/vcard.model";
import { ToastService } from "../../services/toast.service";
import { Broadcaster } from "app/talk/shared/providers";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export const PAD_DATA = new InjectionToken<any>("pad");

@Component({
  selector: "vp-create-pad",
  templateUrl: "./create-pad.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePadComponent implements OnDestroy, AfterViewInit {
  loggedInUserJID: JID;
  currentUser: User;
  isAlive$ = new Subject<boolean>();
  padName: UntypedFormControl;
  isAppOnline = false;
  contact: ContactInformation;
  setFocus: any;
  constructor(private matDialogRef: MatDialogRef<CreatePadComponent>,
    private store: Store<TalkRootState>,
    private toastService: ToastService,
    private broadcaster: Broadcaster,
    @Inject(MAT_DIALOG_DATA) public pad: any,
    private changeDetectorRef: ChangeDetectorRef)  {

    this.padName = new UntypedFormControl("", [Validators.required, Validators.maxLength(100)]);
    if (this.pad.action === "rename") {
      this.padName.setValue(this.pad.padname);
    }
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
    this.broadcaster.on("closeCreatePadForm").pipe(take(1)).subscribe(() => {
      this.closeDialog();
    });
    this.changeDetectorRef.markForCheck();
  }
  ngAfterViewInit(): void {
    if (!!document.getElementById("padNameInput")) {
      document.getElementById("padNameInput").focus();
    }
    this.setFocus = setInterval(() => {
      if (!!document.getElementById("padNameInput")) {
        document.getElementById("padNameInput").focus();
      }
    }, 500);
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  createOrUpdatePad() {
    if (!this.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }
    this.matDialogRef.close(this.padName.value);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (this.setFocus) {
      clearInterval(this.setFocus);
    }

  }

  onKey(event: KeyboardEvent): void {
    if (this.padName.valid && this.padName.value.trim().length > 0 && (event.code === "ENTER" || event.key === "ENTER" || event.keyCode === 13)) {
      this.createOrUpdatePad();
    }
  }
}
