
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Whiteboard } from "../models/whiteboard.model";
import { WhiteboardActionTypes } from "../actions/whiteboard";

export interface WhiteboardState extends EntityState<Whiteboard> {
  selectedWhiteboard: Whiteboard;
  selectedWhiteboardId: string;
}

export const whiteboardAdapter: EntityAdapter<Whiteboard> = createEntityAdapter<Whiteboard>({
  selectId: (whiteboard: Whiteboard) => whiteboard.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(Whiteboard1: Whiteboard, Whiteboard2: Whiteboard): number {
  return Whiteboard1.id - Whiteboard2.id;
}

export const initialState: WhiteboardState = whiteboardAdapter.getInitialState({
  selectedWhiteboard: null,
  selectedWhiteboardId: null
});

export function whiteboardReducer(state: WhiteboardState = initialState, action: Action): WhiteboardState {
  switch (action.type) {

    case WhiteboardActionTypes.WHITEBOARD_ADD: {
      const whiteboard = action.payload.whiteboard;
      const newState = whiteboardAdapter.addOne(whiteboard, state);
      return whiteboardAdapter.updateOne({ id: whiteboard.id, changes: whiteboard }, newState);
    }

    case WhiteboardActionTypes.MULTI_CONVERSATION_WHITEBOARD_ADD: {
      const data = action.payload;
      let localState = { ...state };
      data.forEach(item => {
        let whiteboard = item.whiteboard;
        localState = whiteboardAdapter.addOne(whiteboard, localState);
        localState = whiteboardAdapter.updateOne({ id: whiteboard.id, changes: whiteboard }, localState);
      });

      return localState;
    }

    case WhiteboardActionTypes.WHITEBOARD_BULK_APPEND: {
      const whiteboards = action.payload.whiteboards;
      return whiteboardAdapter.addMany(whiteboards, state);
    }

    case WhiteboardActionTypes.WHITEBOARD_BULK_APPEND_MULTI_CONVERSATION: {
      const whiteboards = action.payload;
      return whiteboardAdapter.addMany(whiteboards, state);

    }

    case WhiteboardActionTypes.WHITEBOARD_DELETE_ACTION: {
      return whiteboardAdapter.removeOne(action.payload, state);
    }
    case WhiteboardActionTypes.WHITEBOARD_UPDATE: {
      return whiteboardAdapter.updateOne({ id: action.payload.id, changes: action.payload.changes }, state);
    }

    case WhiteboardActionTypes.WHITEBOARD_BULK_DELETE: {
      return whiteboardAdapter.removeMany(action.payload.messageIds, state);
    }

    case WhiteboardActionTypes.WHITEBOARDS_BULK_UPDATE: {
      const changes = action.payload.map(wd => {
        return { id: wd.id, changes: wd };
      });
      return whiteboardAdapter.updateMany(changes, state);
    }

    case WhiteboardActionTypes.SET_SELECTED_WHITEBOARD_ID: {
      return {
        ...state,
        selectedWhiteboardId: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedWhiteboardId = (state: WhiteboardState) => state.selectedWhiteboardId;
