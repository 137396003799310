<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<div class="vnc-settings-component">
  <vnc-dialog *ngIf="!mobileSettings" [classes]="['vnc-settings']" headerText="{{ 'SETTINGS' | translate }}"
    classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
  </vnc-dialog>
  <vnc-dialog *ngIf="mobileSettings" [classes]="['vnc-settings-mobile']" headerText="{{ 'SETTINGS' | translate }}"
    classes="fixed-size with-border with-border-radius" [headerTemplate]="headerTemplate" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
  </vnc-dialog>
  <ng-template #headerTemplate>
    <vnc-mobile-header *ngIf="selectedTab === 'ALL'" style="width: 100%;" (backButtonClick)="cancel()" headerType="back-more" mobileHeading="{{ 'SETTINGS' | translate }}"></vnc-mobile-header>
    <vnc-mobile-header *ngIf="selectedTab !== 'ALL'" style="width: 100%;" (rightButtonClick)="save()" rightButtonIcon="mdi-check" customClass="white-back-more" (backButtonClick)="backToSettings()" headerType="back-more" mobileHeading="{{ settingLabel | translate }}"></vnc-mobile-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <form [formGroup]="settingsForm" [class.hide-tabs]="selectedTab !== 'ALL' && mobileSettings" class="smooth-scroll" (submit)="$event.preventDefault();">
      <vnc-vertical-tabs [activeFirstTab]="!mobileSettings" fixedWidth="280px" (selected)="onTabChange($event)">
        <vnc-vertical-tab tabTitle="{{ 'GLOBAL_SETTINGS' | translate }}" iconName="setting" type="icon-left" id="globalSettings">
          <div class="setting-option">
            <div class="setting-heading">
              {{ 'GENERAL' | translate }}
            </div>
          </div>
          <div class="setting-option">
            <mat-form-field class="setting-component-form-field">
              <mat-label class="option-label">{{ 'LANGUAGE'| translate}}</mat-label>
              <mat-select id="configLanguage" formControlName="lang">
                <mat-select-trigger *ngFor="let option of supportedLanguages">
                  <div class="mat-trigger-block" *ngIf="option.value === lang.value">
                    <div class="mat-trigger-text-style">{{ option.display|translate }}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option [attr.id]="option.elementId" *ngFor="let option of supportedLanguages"
                    [value]="option.value">{{ option.display|translate }}
                    <mat-icon *ngIf="option.value == lang.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="setting-option"  *ngIf="showDateTimeFormat">
            <mat-form-field class="setting-component-form-field">
              <mat-label class="option-label">{{ 'DATE_FORMAT'| translate}}</mat-label>
              <mat-select id="configLanguage" formControlName="dateFormat">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style" *ngIf="'dd.MM.yyyy' == dateFormat.value">{{this.lang.value === 'en' ? 'DD.MM.YYYY' : 'TT.MM.JJJJ'}}</div>
                    <div class="mat-trigger-text-style" *ngIf="'MM/dd/yyyy' == dateFormat.value">{{this.lang.value === 'en' ? 'MM/DD/YYYY' : 'MM/TT/JJJJ'}}</div>
                    <div class="mat-trigger-text-style" *ngIf="'dd/MM/yyyy' == dateFormat.value">{{this.lang.value === 'en' ? 'DD/MM/YYYY' : 'TT/MM/JJJJ'}}</div>
                    <div class="mat-trigger-text-style" *ngIf="'yyyy-MM-dd' == dateFormat.value">{{this.lang.value === 'en' ? 'YYYY-MM-DD' : 'JJJJ-MM-TT'}}</div>

                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option value="dd.MM.yyyy">{{this.lang.value === 'en' ? 'DD.MM.YYYY' : 'TT.MM.JJJJ'}}
                  <mat-icon *ngIf="'dd.MM.yyyy' == dateFormat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
                <mat-option value="MM/dd/yyyy">{{this.lang.value === 'en' ? 'MM/DD/YYYY' : 'MM/TT/JJJJ'}}
                  <mat-icon *ngIf="'MM/dd/yyyy' == dateFormat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
                <mat-option value="dd/MM/yyyy">{{this.lang.value === 'en' ? 'DD/MM/YYYY' : 'TT/MM/JJJJ'}}
                  <mat-icon *ngIf="'dd/MM/yyyy' == dateFormat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
                <mat-option value="yyyy-MM-dd">{{this.lang.value === 'en' ? 'YYYY-MM-DD' : 'JJJJ-MM-TT'}}
                  <mat-icon *ngIf="'yyyy-MM-dd' == dateFormat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="setting-option" *ngIf="changeLanguageSave">
            <mat-form-field class="setting-component-form-field">
              <mat-label class="option-label">{{ 'TIME_FORMAT'| translate}}</mat-label>
              <mat-select id="configLanguage" formControlName="timeFormat">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style" *ngIf="'HH:mm' == timeFormat.value">{{ 'FORMAT_24_HOURS'| translate}}</div>
                    <div class="mat-trigger-text-style" *ngIf="'hh:mm a' == timeFormat.value">{{ 'FORMAT_12_HOURS'| translate}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option value="HH:mm">{{ 'FORMAT_24_HOURS'| translate}}
                  <mat-icon *ngIf="'HH:mm' == timeFormat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
                <mat-option value="hh:mm a" *ngIf="this.lang.value !== 'de'">{{ 'FORMAT_12_HOURS'| translate}}
                  <mat-icon *ngIf="'hh:mm a' == timeFormat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="setting-option">
            <mat-form-field class="setting-component-form-field">
              <mat-label class="option-label">{{ 'TIMEZONE'| translate}}</mat-label>
              <mat-select id="configLanguage" formControlName="timezone">
                <mat-select-trigger *ngFor="let timeZone of timezones">
                  <div class="mat-trigger-block" *ngIf="timeZone.key == timezone.value">
                    <div class="mat-trigger-text-style">{{ timeZone.value }}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option *ngFor="let timeZone of timezones"
                    [value]="timeZone.key">{{ timeZone.value }}
                    <mat-icon *ngIf="timeZone.key == timezone.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="setting-option" *ngIf="!disableServerUrl && isCordova">
            <vnc-input id="serverUrl" [form]="serverURL" [showLabel]="true" placeholder="
            {{ 'SERVER_URL' | translate }}"></vnc-input>
          </div>
          <div class="setting-option">
            <div class="setting-heading-small">
              {{ 'OPTIONS' | translate }}
            </div>
          </div>
          <div class="setting-row">
            <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.sendMessageWithEnter" [size]="'s'"
              label="{{ 'SEND_MESSAGE_WITH_ENTER' | translate }}" (changeEvent)="toggleChange('sendMessageWithEnter', $event)"
              tooltipLabel="{{ (notificationSettings.sendMessageWithEnter ? 'DISABLE' : 'ENABLE') | translate }}">
            </vnc-toggle-switch>
          </div>
          <div class="setting-row">
            <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.addTimestamp" [size]="'s'"
              label="{{ 'ADD_DATESTAMP_WITH_TIMESTAMP' | translate }}"
              (changeEvent)="toggleChange('addTimestamp', $event)" tooltipLabel="{{ (notificationSettings.addTimestamp ? 'DISABLE' : 'ENABLE') | translate }}">
            </vnc-toggle-switch>
          </div>
        </vnc-vertical-tab>
        <vnc-vertical-tab  tabTitle="{{'NOTIFICATIONS_SOUND' | translate}}" iconName="notification" type="icon-left"
          id="notificationSettings">
          <ng-container *ngIf="!showExtraSettings">
            <div class="setting-option">
              <div class="setting-heading">
                {{ 'GET_NOTIFIED' | translate }}
                <vnc-action-link *ngIf="mobileSettings" (click)="showExtraSettings=true" [trailingIconTemplate]="trailIconTemplate" [type]="'icon-right'" [linkColor]="'blue'" label="{{ 'CHANGE' | translate}}"></vnc-action-link>
              </div>
              <div class="notification-options">
                <div class="notification-options-left">
                  <div class="notification-option">
                    <vnc-header [itemsLength]="itemsLength" title="{{ 'CHATS' | translate }}" iconName="chat"></vnc-header>
                    <div class="current-settings">{{ chatSettings }}</div>
                  </div>
                  <div class="notification-option" *ngIf="userConfigRepo.isChannelsEnabled() && enableChannels.value">
                    <vnc-header [itemsLength]="itemsLength" title="{{ 'CHANNELS' | translate }}" iconName="channel"></vnc-header>
                    <div class="current-settings">{{ channelSettings }}</div>
                  </div>
                  <div class="notification-option" *ngIf="userConfigRepo.isBroadcastEnabled()">
                    <vnc-header [itemsLength]="itemsLength" title="{{ 'MENTIONS' | translate }}" iconName="mention"></vnc-header>
                    <div class="current-settings">{{ mentionSettings }} </div>
                  </div>
                  <div class="notification-option">
                    <vnc-header [itemsLength]="itemsLength" title="{{ 'BROADCAST' | translate }}" iconName="broadcast"></vnc-header>
                    <div class="current-settings">{{ broadcastSettings }}</div>
                  </div>
                </div>
                <div class="notification-options-right">
                  <button (click)="showExtraSettings=true" class="vnc-btn vnc-default">
                    <span>{{ 'CHANGE' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="setting-option" *ngIf="changeLanguageSave">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'BADGE_COLOR'| translate}}</mat-label>
                <mat-select id="badgeColor" formControlName="badgeColor">
                  <mat-select-trigger>
                    <div class="mat-trigger-block" *ngIf="'red' == badgeColor.value">
                      <div class="mat-trigger-text-style">{{ 'RED'| translate}}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option value="red">
                    <span>{{ 'RED'| translate}}</span>
                    <mat-icon *ngIf="'red' == badgeColor.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="setting-option" *ngIf="isCordova">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'STICKY_NOTIFICATIONS'| translate}}</mat-label>
                <mat-select id="notification" formControlName="notification">
                  <mat-select-trigger *ngFor="let option of notifyOptions">
                    <div class="mat-trigger-block" *ngIf="option.value == notification.value">
                      <div class="mat-trigger-text-style">{{ option.display|translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option [attr.id]="option.elementId" *ngFor="let option of notifyOptions"
                    [value]="option.value">{{ option.display|translate }}
                    <mat-icon *ngIf="option.value == notification.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="setting-option" *ngIf="!isCordova">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'STICKY_NOTIFICATIONS'| translate}}</mat-label>
                <mat-select id="desktopNotification" formControlName="desktopNotification">
                  <mat-select-trigger *ngFor="let option of desktopNotifyOptions">
                    <div class="mat-trigger-block" *ngIf="option.value == desktopNotification.value">
                      <div class="mat-trigger-text-style">{{ option.display|translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option [attr.id]="option.elementId" *ngFor="let option of desktopNotifyOptions"
                    [value]="option.value">{{ option.display|translate }}
                    <mat-icon *ngIf="option.value == desktopNotification.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="setting-option">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'EMAIL_NOTIFICATIONS_PERIOD'|translate}}</mat-label>
                <mat-select id="emailNotification" formControlName="emailNotification">
                  <mat-select-trigger *ngFor="let option of emailNotificationOptions">
                    <div class="mat-trigger-block" *ngIf="option.value == emailNotification.value">
                      <div class="mat-trigger-text-style">{{ option.display|translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option [attr.id]="option.elementId" *ngFor="let option of emailNotificationOptions"
                    [value]="option.value">{{ option.display|translate }}
                    <mat-icon *ngIf="option.value == emailNotification.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="setting-option">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'EMAIL_NOTIFICATION_REPEATS'|translate}}</mat-label>
                <mat-select id="emailNotificationRepeat" formControlName="emailNotificationRepeat">
                  <mat-select-trigger *ngFor="let option of emailNotificationRepeatOptions">
                    <div class="mat-trigger-block" *ngIf="option.value == emailNotificationRepeat.value">
                      <div class="mat-trigger-text-style">{{ option.display|translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option [attr.id]="option.elementId" *ngFor="let option of emailNotificationRepeatOptions"
                    [value]="option.value">{{ option.display|translate }}
                    <mat-icon *ngIf="option.value == emailNotificationRepeat.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="setting-option">
              <div class="setting-heading">
                {{ 'SOUND' | translate }}
              </div>
            </div>
            <div class="setting-row">
              <vnc-toggle-switch labelPosition="before" [checked]="enabledSound.value" [size]="'s'"
                label="{{ 'ENABLE_SOUNDS' | translate }}" (changeEvent)="enabledSound.patchValue($event.checked)" tooltipLabel="{{ (enabledSound.value ? 'DISABLE' : 'ENABLE') | translate }}">
              </vnc-toggle-switch>
            </div>
            <div class="setting-option" *ngIf="enabledSound.value">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'SELECT_RINGTONE'|translate}}</mat-label>

                <mat-select id="callRingtone" formControlName="callRingtone">
                  <mat-select-trigger *ngFor="let option of ringtoneOptions">
                    <div class="mat-trigger-block" *ngIf="option.value == callRingtone.value">
                      <div class="mat-trigger-text-style">{{ option.display|translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option class="notification-options" *ngFor="let option of ringtoneOptions"
                  [value]="option.value">
                    <span class="notification-option-icon">
                      <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/product/volume-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(2.000000, 3.847752)" fill="#8B96A0">
                                <path d="M12.7952267,0.498182461 L7.678,4.15224796 L3.00591905,4.15224796 C1.34770262,4.15224796 -1.82254212e-12,5.49631872 -1.82254212e-12,7.15016109 L-1.82254212e-12,9.15433484 L0.00507491956,9.33088208 C0.0960098586,10.9079027 1.39781515,12.152248 3.00591905,12.152248 L7.678,12.151248 L12.7952267,15.8063135 C14.3532812,16.9192096 16,16.0716815 16,14.1581877 L16,2.14630824 C16,0.230571852 14.3570007,-0.617370454 12.7952267,0.498182461 Z M13.999,2.09524796 L13.999,14.208248 L8.32046505,10.152248 L3.00591905,10.152248 C2.44281524,10.152248 2,9.70944261 2,9.15433484 L2,7.15016109 C2,6.60209818 2.45106577,6.15224796 3.00591905,6.15224796 L8.32046505,6.15224796 L13.999,2.09524796 Z M19.5,8.15224796 C19.5,6.38224796 18.48,4.86224796 17,4.12224796 L17,12.172248 C18.48,11.442248 19.5,9.92224796 19.5,8.15224796 L19.5,8.15224796 Z" id="Combined-Shape"></path>
                            </g>
                        </g>
                      </svg>
                    </span>
                    <span class="notification-option-text">
                      {{ option.display|translate }}
                    </span>
                    <mat-icon *ngIf="option.value == callRingtone.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                <!-- <mat-select id="callRingtone" formControlName="callRingtone">
                  <mat-option [attr.id]="option.elementId" *ngFor="let option of ringtoneOptions"
                    [value]="option.value">
                    <mat-icon >volume_up</mat-icon>
                    {{ option.display|translate }}
                  </mat-option>-->
                </mat-select>
              </mat-form-field>
              <div class="play-button" *ngIf="!playingRingtone" (click)="playRingtone()">
                <ng-container [ngTemplateOutlet]="playBtn">
                </ng-container>
              </div>
              <div class="play-button" *ngIf="playingRingtone" (click)="stopRingtone()">
                <ng-container [ngTemplateOutlet]="pauseBtn">
                </ng-container>
              </div>
            </div>
            <div class="setting-option" *ngIf="enabledSound.value">
              <mat-form-field class="setting-component-form-field">
                <mat-label class="option-label">{{ 'NOTIFICATION_SOUND'|translate}}</mat-label>
                <mat-select id="notificationSound" formControlName="notificationSound">
                  <mat-select-trigger *ngFor="let option of soundOptions">
                    <div class="mat-trigger-block" *ngIf="option.value == notificationSound.value">
                      <div class="mat-trigger-text-style">{{ option.display | translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option [attr.id]="option.elementId" *ngFor="let option of soundOptions" [value]="option.value">
                    {{ option.display | translate }}
                    <mat-icon *ngIf="option.value == notificationSound.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="play-button" *ngIf="!playingNotification" (click)="playNotification()">
              <ng-container [ngTemplateOutlet]="playBtn">
              </ng-container>
            </div>
            <div class="play-button" *ngIf="playingNotification" (click)="stopNotification()">
              <ng-container [ngTemplateOutlet]="pauseBtn">
              </ng-container>
            </div>
          </ng-container>


          <div *ngIf="showExtraSettings" class="setting-option extra-settings">
            <vnc-header [itemsLength]="itemsLength" (onLeftIconClick)="showExtraSettings=false" (click)="showExtraSettings=false" title="{{ 'GO_BACK' | translate }}" iconName="mdi-arrow-left">
            </vnc-header>
            <ng-container [ngTemplateOutlet]="chatNotification">
            </ng-container>
            <ng-container *ngIf="userConfigRepo.isChannelsEnabled() && enableChannels.value" [ngTemplateOutlet]="channelNotification">
            </ng-container>
            <ng-container [ngTemplateOutlet]="mentionNotification">
            </ng-container>
            <ng-container [ngTemplateOutlet]="userConfigRepo.isBroadcastEnabled() &&broadcastNotification">
            </ng-container>
          </div>
        </vnc-vertical-tab>
        <vnc-vertical-tab  *ngIf="!isMobileNotIpad()" tabTitle="{{'AUDIO_VIDEO'|translate}}" iconName="video" type="icon-left" id="videoSettings">
          <div class="setting-option">
            <div class="setting-heading">
              {{ 'AUDIO_HEADING' | translate }}
            </div>
          </div>
          <div class="setting-option">
            <mat-form-field *ngIf="isCordova" class="setting-component-form-field audio-input-mat-form">
              <mat-label class="option-label">{{ 'INPUT'| translate}}</mat-label>
              <mat-select [attr.disabled]="!micDeviceAvailable" formControlName="currentAudioInputDeviceLabel" (selectionChange)="changeInput()">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{currentAudioInputDeviceLabel?.value | translate}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let device of availableMediaDevices?.audioInput"
                  [value]="device?.deviceId">
                  {{device?.deviceId | translate}}
                  <mat-icon *ngIf="device?.deviceId == currentAudioInputDeviceLabel?.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!isCordova" class="setting-component-form-field audio-input-mat-form">
              <mat-label class="option-label">{{ 'INPUT'| translate}}</mat-label>
              <mat-select [attr.disabled]="!micDeviceAvailable" formControlName="currentAudioInputDeviceLabel" (selectionChange)="changeInput()">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{currentAudioInputDeviceLabel?.value | translate}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let device of availableMediaDevices?.audioInput"
                  [value]="device?.deviceLabel">
                  {{device?.deviceLabel | translate}}
                  <mat-icon *ngIf="device?.deviceLabel == currentAudioInputDeviceLabel?.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="setting-row audio-device-toggle">
            <vnc-toggle-switch labelPosition="before" [checked]="autoDeviceChange.value" [size]="'s'"
              label="{{ 'AUTO_DEVICE_CHANGE' | translate }}"
              (changeEvent)="autoDeviceChange.setValue($event.checked)" tooltipLabel="{{ (autoDeviceChange.value ? 'DISABLE' : 'ENABLE') | translate }}">
            </vnc-toggle-switch>
          </div>
          <div class="setting-row screen-share-toggle">
            <vnc-toggle-switch labelPosition="before" [checked]="allowScreensharePreviewNew.value" [size]="'s'"
              (changeEvent)="allowScreensharePreviewNew.setValue($event.checked)" tooltipLabel="{{ (allowScreensharePreviewNew.value ? 'DISABLE' : 'ENABLE') | translate }}">
              {{ 'ALLOW_SCREEN_SHARE_PREVIEW_FOR_THE_PRESENTER' | translate }} <br>
              <div class="option-sub-desc">
                {{ 'SCREENSHARE_PRESENTER_WILL_ALSO_SEE_WHAT_IS_SHARED' | translate }}
              </div>
            </vnc-toggle-switch>
          </div>
          <div class="level-block">
            <div class="setting-option preview-level">
              <div class="label-preview">{{ 'LEVEL'| translate}}</div>
              <div class="level">
                <div [ngClass]="{'block': true, 'detectLevel': (index <= (volumeLevel - 1)) }"
                  *ngFor="let level of levels; let index = index">
                </div>
              </div>
            </div>
          </div>
          <div class="setting-option">
            <mat-form-field class="setting-component-form-field">
              <mat-label class="option-label">{{ 'OUTPUT'| translate}}</mat-label>
              <mat-select *ngIf="isCordova" [attr.disabled]="!audioOutputDeviceAvailable"  formControlName="currentAudioOutputDeviceLabel">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{currentAudioOutputDeviceLabel.value}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let device of availableMediaDevices?.audioOutput"
                  [value]="device?.deviceId">
                  {{device?.deviceId}}
                  <mat-icon *ngIf="device?.deviceId == currentAudioOutputDeviceLabel.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
              <mat-select *ngIf="!isCordova" [attr.disabled]="!audioOutputDeviceAvailable"  formControlName="currentAudioOutputDeviceLabel">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{currentAudioOutputDeviceLabel?.value | translate}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let device of availableMediaDevices?.audioOutput"
                  [value]="device?.deviceLabel">
                  {{device?.deviceLabel | translate}}
                  <mat-icon *ngIf="device?.deviceLabel == currentAudioOutputDeviceLabel?.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="setting-option">
            <div class="setting-heading">
              {{ 'VIDEO_HEADING' | translate }}
            </div>
          </div>
          <div class="setting-option">
            <mat-form-field class="setting-component-form-field">
              <mat-label class="option-label">{{ 'INPUT'| translate}}</mat-label>
              <mat-select *ngIf="isCordova" [attr.disabled] = "!camDeviceAvailable" panelClass="video-input-select" (openedChange)="openedChange()" (selectionChange)="changeInput()"  formControlName="currentVideoInputDeviceLabel">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{currentVideoInputDeviceLabel.value | translate}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let device of availableMediaDevices?.videoInput"
                  [value]="device?.deviceId">
                  {{device?.deviceId}}
                </mat-option>
                <mat-icon *ngIf="device?.deviceId == currentVideoInputDeviceLabel.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
              </mat-select>
              <mat-select *ngIf="!isCordova" [attr.disabled] = "!camDeviceAvailable" panelClass="video-input-select" (openedChange)="openedChange()" (selectionChange)="changeInput()"  formControlName="currentVideoInputDeviceLabel">
                <mat-select-trigger>
                  <div class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{currentVideoInputDeviceLabel?.value | translate}}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let device of availableMediaDevices?.videoInput"
                  [value]="device?.deviceLabel">
                  {{device?.deviceLabel | translate}}
                  <mat-icon *ngIf="device?.deviceLabel == currentVideoInputDeviceLabel?.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="setting-option">
            <div class="preview">
              <video *ngIf="!hideVideoPreview"
                class="video-preview-style"
                autoplay
                playsinline
                width="100%"
                height="100%"
                #videoElem>
              </video>
            </div>
          </div>
        </vnc-vertical-tab>
        <vnc-vertical-tab  *ngIf="!isHin"  tabTitle="{{'SECURITY_PRIVACY'|translate}}" iconName="security" type="icon-left" id="securitySettings">
          <ng-container [ngTemplateOutlet]="securityTab"></ng-container>
        </vnc-vertical-tab>
        <vnc-vertical-tab  tabTitle="{{'FEATURES'|translate}}" type="icon-left" iconName="feature" id="featuresSettings">
          <ng-container [ngTemplateOutlet]="featureTab"></ng-container>
        </vnc-vertical-tab>
        <vnc-vertical-tab  *ngIf="!isHin" tabTitle="{{'APPEARANCE'|translate}}" type="icon-left" iconName="appearance" id="appearanceSettings">
          <ng-container *ngIf="userConfigRepo.isThemesEnabled()">
            <div class="setting-row no-border">
              <div class="setting-heading">
                {{ 'SELECT_THEME' | translate }}
              </div>
            </div>
            <div  *ngIf="mobileSettings" class="setting-row theme-settings">
              <img *ngIf="selectedTheme.value === 'vnctalk'" [src]="vnctalkIconMobile" alt="VNCtalk">
              <img *ngIf="selectedTheme.value === 'ekbo'" [src]="ekboIconMobile" alt="ekbo">
              <img *ngIf="selectedTheme.value === 'airbus'" [src]="airbusIconMobile" alt="airbus">
              <img *ngIf="selectedTheme.value === 'hin'" [src]="hinIconMobile" alt="hin">
            </div>
            <div class="setting-row theme-settings">
              <mat-radio-group formControlName="selectedTheme" >
                <div class="theme-group" matTooltip="{{'APPLY_THIS_THEME' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                  <div  *ngIf="!mobileSettings"  class="theme-icon" (click)="selectedTheme.setValue('vnctalk')" [class.selected-theme]="selectedTheme.value === 'vnctalk'">
                    <img [src]="vnctalkIcon" alt="VNCtalk">
                  </div>
                  <mat-radio-button value="vnctalk">VNCtalk</mat-radio-button>
                </div>
                <div class="theme-group" matTooltip="{{'APPLY_THIS_THEME' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                  <div  *ngIf="!mobileSettings"  class="theme-icon" (click)="selectedTheme.setValue('ekbo')" [class.selected-theme]="selectedTheme.value === 'ekbo'">
                    <img [src]="ekboIcon" alt="{{ 'PURPLE' | translate }}">
                  </div>
                  <mat-radio-button value="ekbo">{{ 'PURPLE' | translate }}</mat-radio-button>
                </div>
                <div class="theme-group" matTooltip="{{'APPLY_THIS_THEME' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                  <div  *ngIf="!mobileSettings"  class="theme-icon" (click)="selectedTheme.setValue('hin')" [class.selected-theme]="selectedTheme.value === 'hin'">
                    <img [src]="hinIcon" alt="{{ 'ORANGE' | translate }}">
                  </div>
                  <mat-radio-button value="hin">{{ 'ORANGE' | translate }}</mat-radio-button>
                </div>
                <div class="theme-group" matTooltip="{{'APPLY_THIS_THEME' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                  <div  *ngIf="!mobileSettings"  class="theme-icon" (click)="selectedTheme.setValue('airbus')" [class.selected-theme]="selectedTheme.value === 'airbus'"><img [src]="airbusIcon" alt="{{ 'PURPLE' | translate }}"></div>
                  <mat-radio-button value="airbus">{{ 'DARK_BLUE' | translate }}</mat-radio-button>
                </div>

              </mat-radio-group>
            </div>
          </ng-container>

          <div class="setting-row no-border" >
            <div class="setting-heading">
              {{ 'FONT_SIZE' | translate }}
            </div>
          </div>
          <div class="setting-row no-border slider-padding">
            <mat-slider color="primary" formControlName="selectedFontSize"
              [value]="selectedFontSize.value" style="width: 100%;" min="14" step="1" max="20"
              (input)="onInputChange($event, selectedFontSize)"></mat-slider>
            <div class="font-size">{{ selectedFontSize.value || '14' }}</div>
          </div>
          <div class="setting-row no-border">
            <ul class="messages-list">
              <li class="message-item">
                <div class="message-avatar">
                  <img [src]="messageAvatar">
                </div>
                <div class="message-wrapper">
                    <div style="font-size: {{ selectedFontSize.value || '14' }}px" class="message-content" [innerHTML]="'MESSAGE_LEFT' | translate"></div>
                    <div class="message-meta-data">Adam Forrest  ·  15:26  12.01.2021 <mat-icon fontIcon="mdi-star" fontSet="mdi"></mat-icon> <mat-icon fontIcon="mdi-at" fontSet="mdi"></mat-icon></div>
                </div>
              </li>
              <li class="message-item align-right">
                <div class="message-wrapper">
                    <div class="message-content" style="font-size: {{ selectedFontSize.value || '14' }}px" [innerHTML]="'MESSAGE_RIGHT' | translate"></div>
                    <div class="message-meta-data">15:26  12.01.2021 <mat-icon fontIcon="mdi-star-outline" fontSet="mdi"></mat-icon></div>
                </div>
              </li>
            </ul>
          </div>
          <ng-container>
            <div class="setting-row">
              <div class="setting-heading">
                {{ 'CHAT_BACKGROUND' | translate }}
              </div>
            </div>

            <div class="setting-row no-border chat-background-settings">
              <mat-radio-group formControlName="selectedChatBackground" >
                <div  *ngFor="let item of defaultChatBackgrounds" class="theme-group" matTooltip="{{'APPLY_THIS_BACKGROUND' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                  <div class="theme-icon" (click)="selectedChatBackground.setValue(item.id)" [class.selected-theme]="selectedChatBackground.value === item.id">
                    <img [src]="sanitizeSrc(item.src)" alt="{{item.id}}">
                  </div>
                  <mat-radio-button [value]="item.id"></mat-radio-button>
                </div>

                <div class="theme-group">
                  <div class="theme-icon" (click)="selectedChatBackground.setValue('background_color')" [class.selected-theme]="selectedChatBackground.value === 'background_color'" matTooltip="{{'APPLY_THIS_BACKGROUND' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                    <div style="background-color: {{chatBackgroundColor}}" class="background-color"></div>
                  </div>
                  <vnc-action-panel [class.is-mobile]="isOnMobile" type = "action" [iconTemplate] = "editIconChat"
                         [isPending] = "false" [actionColor] = "'dark'"  matTooltip="{{ 'EDIT' | translate }}"
                          (actionClicked)="openColorDialog()"></vnc-action-panel>
                  <mat-radio-button value="background_color"></mat-radio-button>
                </div>

                <div  *ngFor="let item of customBackgroundImages" class="theme-group">
                  <div class="theme-icon" (click)="selectedChatBackground.setValue(item.id)" [class.selected-theme]="selectedChatBackground.value === item.id" matTooltip="{{'APPLY_THIS_BACKGROUND' | translate}}" [matTooltipPosition]="themeTooltipPosition">
                    <img [src]="item.src" alt="{{item.id}}">
                  </div>
                  <vnc-action-panel [class.is-mobile]="isOnMobile" *ngIf="appliedTheme !== item.id" type = "action" [iconTemplate] = "deleteIconChat"
                          [isPending] = "false" [actionColor] = "'dark'" matTooltip="{{ 'DELETE' | translate }}"
                          (actionClicked)="deleteChatBackgroundImage(item.id)"></vnc-action-panel>
                  <mat-radio-button [value]="item.id"></mat-radio-button>
                </div>

                <div class="add-menu-button" (click)="openActionMenu()"  matTooltip="{{ 'ADD' | translate }}" [matTooltipPosition]="themeTooltipPosition">
                  <div role="button" class="action-menu-trigger" [matMenuTriggerFor]="addActionMenu" mat-button #addActionMenuTrigger="matMenuTrigger"></div>
                  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M25.333 17.333h-8v8h-2.666v-8h-8v-2.666h8v-8h2.666v8h8z" fill="#388EDA" fill-rule="evenodd"/>
                  </svg>
                  <mat-menu class="add-menu-action" #addActionMenu="matMenu" yPosition="above">
                    <ng-template matMenuContent>
                      <button mat-menu-item (click)="menuItemClicked('background')">
                        <span>{{ 'BACKGROUND_IMAGE' | translate }}</span>
                      </button>

                      <button mat-menu-item (click)="menuItemClicked('avatar')">
                        <span>{{ 'BACKGROUND_PATTERN' | translate }}</span>
                      </button>
                  </ng-template>
                  </mat-menu>
                </div>
                <input *ngIf="!isOnIOS" id="attachImage" style="visibility: hidden;" class="attachFile" title=" " (change)="attachImage($event)" type="file" accept=".png, .jpg, .jpeg, .bmp, .svg"/>
                <input *ngIf="isOnIOS" id="attachImage" style="visibility: hidden;" class="attachFile" title=" " (change)="attachImage($event)" multiple="true" type="file" accept="image/*"/>


              </mat-radio-group>
            </div>
          </ng-container>

        </vnc-vertical-tab>
      </vnc-vertical-tabs>
    </form>

  </ng-template>
  <ng-template #footerTemplate>
    <div class="footer-actions">
         <vnc-link-button
            [size]="'m'"
            label="{{ 'CANCEL' | translate }}"
            (onClick)="cancel()"
            [isFixedSize]="true"
            [fixedSize]="96"
        ></vnc-link-button>
        <vnc-primary-button
            [size]="'m'"
            (onClick)="save()"
            [isFixedSize]="true"
            [fixedSize]="104"
        ><span>{{ 'SAVE' | translate }}</span>
        </vnc-primary-button>
    </div>
  </ng-template>

</div>




<ng-template #broadcastNotification>
  <vnc-header [itemsLength]="itemsLength" title="{{ 'BROADCASTS' | translate }}" iconName="broadcast"></vnc-header>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.broadcastEnableNotification" [size]="'s'"
      label="{{ 'ENABLE_NOTIFICATIONS' | translate }}"
      (changeEvent)="toggleChange('broadcastEnableNotification', $event)" tooltipLabel="{{ (notificationSettings.broadcastEnableNotification ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.broadcastShowBadge" [size]="'s'"
      label="{{ 'SHOW_BADGE' | translate }}" (changeEvent)="toggleChange('broadcastShowBadge', $event)" tooltipLabel="{{ (notificationSettings.broadcastShowBadge ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
</ng-template>
<ng-template #mentionNotification>
  <vnc-header [itemsLength]="itemsLength" title="{{ 'MENTIONS' | translate }}" iconName="mention"></vnc-header>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.mentionEnableNotification" [size]="'s'"
      label="{{ 'ENABLE_NOTIFICATIONS' | translate }}"
      (changeEvent)="toggleChange('mentionEnableNotification', $event)" tooltipLabel="{{ (notificationSettings.mentionEnableNotification ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.mentionShowBadge" [size]="'s'"
      label="{{ 'SHOW_BADGE' | translate }}" (changeEvent)="toggleChange('mentionShowBadge', $event)" tooltipLabel="{{ (notificationSettings.mentionShowBadge ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
</ng-template>
<ng-template #channelNotification>
  <vnc-header [itemsLength]="itemsLength" title="{{ 'CHANNELS' | translate }}" iconName="channel"></vnc-header>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.channelNewTopic" [size]="'s'"
      label="{{ 'NEW_TOPIC_IN_CHANNEL_YOU_SUBSCRIBED_ON' | translate }}"
      (changeEvent)="toggleChange('channelNewTopic', $event)" tooltipLabel="{{ (notificationSettings.channelNewTopic ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.channelInvite" [size]="'s'"
      label="{{ 'INVITE_TO_A_CHANNEL' | translate }}" (changeEvent)="toggleChange('channelInvite', $event)" tooltipLabel="{{ (notificationSettings.channelInvite ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.channelNewComment" [size]="'s'"
      label="{{ 'NEW_COMMENT_IN_TOPIC_YOU_SUBSCRIBED_ON' | translate }}"
      (changeEvent)="toggleChange('channelNewComment', $event)" tooltipLabel="{{ (notificationSettings.channelNewComment ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.channelReplyComment" [size]="'s'"
      label="{{ 'REPLYS_TO_YOUR_COMMENTS' | translate }}" (changeEvent)="toggleChange('channelReplyComment', $event)" tooltipLabel="{{ (notificationSettings.channelReplyComment ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
</ng-template>
<ng-template #chatNotification>
  <vnc-header [itemsLength]="itemsLength" title="{{ 'CHATS' | translate }}" iconName="chat"></vnc-header>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.chatMention" [size]="'s'"
      label="{{ 'MENTIONS' | translate }}" (changeEvent)="toggleChange('chatMention', $event)" tooltipLabel="{{ (notificationSettings.chatMention ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.chatConferenceReminder" [size]="'s'"
      label="{{ 'CONFERENCE_VIDEO_CALLS_REMINDERS' | translate }}"
      (changeEvent)="toggleChange('chatConferenceReminder', $event)" tooltipLabel="{{ (notificationSettings.chatConferenceReminder ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="notificationSettings.chatNotifyMissedCall" [size]="'s'"
      label="{{ 'NOTIFY_ABOUT_MISSED_CALLS' | translate }}"
      (changeEvent)="toggleChange('chatNotifyMissedCall', $event)" tooltipLabel="{{ (notificationSettings.chatNotifyMissedCall ? 'DISABLE' : 'ENABLE') | translate }}">
    </vnc-toggle-switch>
  </div>
</ng-template>

<ng-template #playBtn>
  <button class="play-btn">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fill-rule="evenodd">
        <g fill="#388EDA">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path d="M0 0L0 9.333 7.333 4.667z"
                        transform="translate(-577 -642) translate(253 180) translate(304 -198) translate(0 500) translate(16 156) translate(4 4) translate(5.333 3.333)" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  </button>
  <span>{{ 'PLAY_LBL' | translate }}</span>
</ng-template>
<ng-template #pauseBtn>
  <button class="play-btn">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fill-rule="evenodd">
        <g fill="#388EDA">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path d="M0 9.333h2.667V0H0v9.333zM5.333 0v9.333H8V0H5.333z"
                        transform="translate(-577 -758) translate(253 180) translate(304 -198) translate(0 500) translate(16 272) translate(4 4) translate(4 3.333)" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
  <span> {{ 'STOP' | translate }}</span>
</ng-template>

<ng-template #featureTab>
  <ng-container *ngIf="mobileSettings">
    <div class="setting-row">
      <div class="setting-heading">
        {{ 'CHAT_SWIPE_ACTIONS' | translate }}
      </div>
    </div>
    <div class="setting-row">
      <vnc-toggle-switch labelPosition="before" [checked]="leftSwipe.value" [size]="'s'"
        (changeEvent)="leftSwipe.setValue($event.checked)" tooltipLabel="{{ (leftSwipe.value ? 'DISABLE' : 'ENABLE') | translate }}">
        {{ 'LEFT_SWIPE' | translate }} <br>
        <div class="option-sub-desc">
          {{ leftAction | translate }}
        </div>
      </vnc-toggle-switch>

    </div>
    <div class="setting-row" *ngIf="leftSwipe.value">
      <div class="swipe-action">
        <div #leftMenuTrigger="matMenuTrigger"  [matMenuTriggerFor]="actionLeftMenu" class="swipe-icon voice-action">
          <ng-container *ngIf="leftAction === 'DELETE'" [ngTemplateOutlet]="iconDelete"></ng-container>
          <ng-container *ngIf="leftAction === 'VOICE_CALL'" [ngTemplateOutlet]="iconVoice"></ng-container>
          <ng-container *ngIf="leftAction === 'VIDEO_CALL'" [ngTemplateOutlet]="iconVideo"></ng-container>
          <ng-container *ngIf="leftAction === 'ARCHIVE'" [ngTemplateOutlet]="iconArchive"></ng-container>
          <ng-container *ngIf="leftAction === 'PIN_CHAT'" [ngTemplateOutlet]="iconPin"></ng-container>
      </div>
      <mat-menu backdropClass="action-menu-backdrop" class="action-menu" #actionLeftMenu="matMenu" [overlapTrigger]="false">
        <ng-template matMenuContent [ngTemplateOutlet]="menuMobileTemplate"
        [ngTemplateOutletContext]="{'action': 'swipeLeft'}">
        </ng-template>
      </mat-menu>
        <div class="swipe-placeholder">
          <vnc-gs-dropdown-item
                [type]="'chat-skeleton'"
                [size]="'xl'">
            </vnc-gs-dropdown-item>
        </div>
      </div>
    </div>
    <div class="setting-row">
      <vnc-toggle-switch labelPosition="before" [checked]="rightSwipe.value" [size]="'s'"
        (changeEvent)="rightSwipe.setValue($event.checked)" tooltipLabel="{{ (rightSwipe.value ? 'DISABLE' : 'ENABLE') | translate }}">
        {{ 'RIGHT_SWIPE' | translate }} <br>
        <div class="option-sub-desc">
          {{ rightAction | translate }}
        </div>
      </vnc-toggle-switch>
    </div>
    <div class="setting-row" *ngIf="rightSwipe.value">
      <div class="swipe-action">
        <div class="swipe-placeholder delete-placeholder">
          <vnc-gs-dropdown-item
                [type]="'chat-skeleton'"
                [size]="'xl'">
            </vnc-gs-dropdown-item>
        </div>
        <div #rightMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="actionMenu" class="swipe-icon delete-action">
          <ng-container *ngIf="rightAction === 'DELETE'" [ngTemplateOutlet]="iconDelete"></ng-container>
          <ng-container *ngIf="rightAction === 'VOICE_CALL'" [ngTemplateOutlet]="iconVoice"></ng-container>
          <ng-container *ngIf="rightAction === 'VIDEO_CALL'" [ngTemplateOutlet]="iconVideo"></ng-container>
          <ng-container *ngIf="rightAction === 'ARCHIVE'" [ngTemplateOutlet]="iconArchive"></ng-container>
          <ng-container *ngIf="rightAction === 'PIN_CHAT'" [ngTemplateOutlet]="iconPin"></ng-container>
        </div>
        <mat-menu backdropClass="action-menu-backdrop" class="action-menu" #actionMenu="matMenu" [overlapTrigger]="false">
          <ng-template matMenuContent [ngTemplateOutlet]="menuMobileTemplate"
          [ngTemplateOutletContext]="{'action': 'swipeRight'}">
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </ng-container>




  <div class="setting-row">
    <div class="setting-heading">
      {{ 'CHAT_ADDONS' | translate }}
    </div>
  </div>
  <div class="setting-row" *ngIf="userConfigRepo.isRTFEnabled()">
    <vnc-toggle-switch labelPosition="before" [checked]="enableRTF.value" [size]="'s'"
      (changeEvent)="enableRTF.setValue($event.checked)" tooltipLabel="{{ (enableRTF.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_RTF' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ENABLE_RTF_SUBTEXT' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enableAutofocus.value" [size]="'s'"
      (changeEvent)="enableAutofocus.setValue($event.checked)" tooltipLabel="{{ (enableAutofocus.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_KEYBOARD' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ENABLE_KEYBOARD_MESSAGE' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <div *ngIf="!isHin" class="setting-row" style="margin-top: 24px;">
    <div class="setting-heading">
      {{ 'SELECT_FEATURES' | translate }}
    </div>
  </div>
  <div *ngIf="!isHin && userConfigRepo.isChannelsEnabled()"   class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enableChannels.value" [size]="'s'"
      (changeEvent)="enableChannels.setValue($event.checked)" tooltipLabel="{{ (enableChannels.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_CHANNELS' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ALLOW_USE_OF_CHANNELS' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <div *ngIf="!isHin && userConfigRepo.isChannelsEnabled()"   class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enableSocials.value" [size]="'s'"
      (changeEvent)="enableSocials.setValue($event.checked)" tooltipLabel="{{ (enableSocials.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_SOCIALS' | translate }} <br>
      <div class="option-sub-desc">
      </div>
    </vnc-toggle-switch>
  </div>
  <div *ngIf="!isHin && userConfigRepo.isBroadcastEnabled()"   class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enableBroadcasts.value" [size]="'s'"
      (changeEvent)="enableBroadcasts.setValue($event.checked)" tooltipLabel="{{ (enableBroadcasts.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_BROADCASTS' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ALLOW_USE_OF_BROADCASTS' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <div *ngIf="!isHin && userConfigRepo.isMCBEnabled()"   class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enableWhiteboard.value" [size]="'s'"
      (changeEvent)="enableWhiteboard.setValue($event.checked)" tooltipLabel="{{ (enableWhiteboard.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_WHITEBOARD' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ALLOW_USE_OF_WHITEBOARD' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <div *ngIf="!isHin && canManageMCBs && userConfigRepo.isMCBEnabled()"   class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enableMCB.value" [size]="'s'"
      (changeEvent)="enableMCB.setValue($event.checked)" tooltipLabel="{{ (enableMCB.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_MCB' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ALLOW_USE_OF_MULTI_CONFERENCE_BOARD' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <div *ngIf="!isHin && userConfigRepo.isPadEnabled()"   class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="enablePad.value" [size]="'s'"
      (changeEvent)="enablePad.setValue($event.checked)" tooltipLabel="{{ (enablePad.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'ENABLE_PAD' | translate }} <br>
    </vnc-toggle-switch>
  </div>
</ng-template>

<ng-template #securityTab>
  <div class="setting-row">
    <div class="setting-heading">
      {{ 'SECURITY' | translate }}
    </div>
  </div>
  <ng-container *ngIf="!isCordova && userConfigRepo.is2FAEnabled()">
    <div class="setting-row">
      <vnc-toggle-switch labelPosition="before" [checked]="use2FA.value" [size]="'s'"
        (changeEvent)="use2FA.setValue($event.checked)" tooltipLabel="{{ (use2FA.value ? 'DISABLE' : 'ENABLE') | translate }}">
        {{ 'ENABLE_TWO_FACTOR_AUTHENTICATION' | translate }}
      </vnc-toggle-switch>
    </div>
    <div class="setting-row" *ngIf="use2FA.value && !is2FAEnabled">
      <vnc-primary-button
          [size]="'m'"
          (onClick)="setAuthenticationApp()"
      ><span>{{ 'START_AUTHENTICATOR_APP' | translate }}</span>
      </vnc-primary-button>
    </div>
  </ng-container>


  <!-- omemo -->
  <ng-container *ngIf="userConfigRepo.isOMEMOEnabled()">
    <div class="setting-row no-border">
      <div class="setting-heading">
        {{ 'OMEMO_DEVICES' | translate }}
      </div>
    </div>
    <p *ngIf="isOmemoDeviceRetrievalInProgress" [ngStyle]="{'height': '10px'}">
      <vnc-loader-circular  color="blue" size="xs"></vnc-loader-circular>
    </p>
    <div class="setting-row omemo-devices">
      <div *ngFor="let device of omemoDevicesList" class="setting-option pd-right-0" style="width: 100%;">
        <mat-checkbox id="omemo-device-{{ device.id }}" class="omemo-device-checkbox" color="primary" value="{{ device.id }}" checked=true (change)="onOMEMODDeviceSelect($event)"></mat-checkbox>
        <h6 class="option-label">{{ device.id }} {{ device.label }}
          <ng-container *ngIf="device.id == omemoCurrentDeviceId">
            <small style="margin-left: 3px;">{{ 'CURRENT_DEVICE'| translate }}</small>
          </ng-container>
        </h6>

        <div class="delete-omemo-device" (click)="deleteOmemoDevice(device.id)">
          <svg width="24" [matTooltip]="'DELETE' | translate" [matTooltipPosition]="'above'" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 7v11.286C18 19.775 16.809 21 15.333 21H8.667C7.19 21 6 19.775 6 18.286V7h12zm-2 2H8v9.286c0 .363.26.66.578.708l.089.006h6.666c.358 0 .667-.318.667-.714V9zm-3-6a2 2 0 0 1 1.733 1H18a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2h3.268A2 2 0 0 1 11 3h2z" fill="#8B96A0" fill-rule="evenodd"/>
          </svg>
        </div>
      </div>
    </div>

    <div *ngIf="isOmemoMaxDeviceReached" class="omemo-notice-text">
      {{ 'OMEMO_HIT_MAX_DEVICES_NOTE' | translate }}
    </div>

    <div class="setting-row no-border">
      <div class="setting-heading">
        {{ 'OMEMO_TRANSFER_CHATS' | translate }}
        <button mat-button class="vnc-button" (click)="omemoTransferChats()" [ngStyle]="{'margin-left': '16px'}">{{ 'SELECT_CHATS' | translate }}</button>
      </div>
    </div>
  </ng-container>

  <div class="setting-row" style="margin-top: 24px;">
    <div class="setting-heading">
      {{ 'PRIVACY' | translate }}
    </div>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="receipts.value" [size]="'s'"
      (changeEvent)="receipts.setValue($event.checked)" tooltipLabel="{{ (receipts.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'RECEIPTS' | translate }}
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="informTyping.value" [size]="'s'"
      (changeEvent)="informTyping.setValue($event.checked)" tooltipLabel="{{ (informTyping.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'INFORM_ABOUT_TYPING' | translate }} <br>
      <div class="option-sub-desc" *ngIf="!isHin">
        {{ 'INFORM_ABOUT_TYPING_SUBTEXT' | translate }}
      </div>
      <div class="option-sub-desc" *ngIf="isHin">
        {{ 'INFORM_ABOUT_TYPING_SUB_HIN' | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  <!-- <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="publishProfile.value" [size]="'s'"
      (changeEvent)="publishProfile.setValue($event.checked)">
      {{ 'PUBLISH_PROFILE_USER_DATA' | translate }} <br>
      <div class="option-sub-desc">
        {{ 'ALLOW_OTHER_USERS_TO_FIND_YOU' | translate }}
      </div>
    </vnc-toggle-switch>
  </div> -->
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="publicLastActivity.value" [size]="'s'"
      (changeEvent)="publicLastActivity.setValue($event.checked)" tooltipLabel="{{ (publicLastActivity.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'PUBLISH_LAST_ACTIVITY' | translate }}
    </vnc-toggle-switch>
  </div>
  <div class="setting-row">
    <vnc-toggle-switch labelPosition="before" [checked]="hideInsecureContent.value" [size]="'s'"
      (changeEvent)="hideInsecureContent.setValue($event.checked)" tooltipLabel="{{ (hideInsecureContent.value ? 'DISABLE' : 'ENABLE') | translate }}">
      {{ 'DO_NOT_LOAD_INSECURE_CONTENT' | translate }}
    </vnc-toggle-switch>
  </div>
</ng-template>

<ng-template #trailIconTemplate>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>D1EBE853-A3D9-44ED-8F2C-117CC73548EE</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Actionlink/@blue/icon-right/default" transform="translate(-125.000000, -3.000000)" fill="#337CBD">
            <g id="Container/18px" transform="translate(122.000000, 0.000000)">
                <g id="Icon/product/arrow_forward" transform="translate(3.000000, 3.000000)">
                    <g id="Icon-24px" transform="translate(3.000000, 3.000000)">
                        <polygon id="Shape" points="6 0 4.9425 1.0575 9.1275 5.25 0 5.25 0 6.75 9.1275 6.75 4.9425 10.9425 6 12 12 6"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</ng-template>


<ng-template #iconArchive>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.667 3c1.267 0 2.251 1.067 2.328 2.36l.005.164v12.843c0 1.358-.917 2.535-2.174 2.627l-.16.006H5.334c-1.285 0-2.253-1.123-2.328-2.464L3 18.367V5.524c0-1.312.933-2.431 2.176-2.519L5.333 3h13.334zM19 9.571H5v8.796c0 .303.12.518.237.598l.05.026.046.009h13.334c.12 0 .29-.193.326-.509l.007-.124V9.571zM14.25 11c.414 0 .75.346.75.773a.762.762 0 0 1-.75.772h-4.5a.762.762 0 0 1-.75-.772c0-.427.336-.773.75-.773h4.5zm4.417-6H5.333c-.135 0-.293.164-.327.422L5 5.524V7.57h14V5.524c0-.247-.11-.427-.23-.494l-.052-.022L18.667 5z" fill="#ffffff" fill-rule="evenodd"/>
</svg>
</ng-template>
<ng-template #iconDelete>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 7v11.286C18 19.775 16.809 21 15.333 21H8.667C7.19 21 6 19.775 6 18.286V7h12zm-2 2H8v9.286c0 .363.26.66.578.708l.089.006h6.666c.358 0 .667-.318.667-.714V9zm-3-6a2 2 0 0 1 1.733 1H18a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2h3.268A2 2 0 0 1 11 3h2z" fill="#FFF" fill-rule="evenodd"/>
</svg>
</ng-template>
<ng-template #iconPin>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/unpin-outline-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M9.34314575,3.34314575 L20.6568542,14.6568542 C21.0473785,15.0473785 21.0473785,15.6805435 20.6568542,16.0710678 C20.26633,16.4615921 19.633165,16.4615921 19.2426407,16.0710678 L16.32,13.149 L15.2539427,14.2261817 L15.2539427,16.1733371 C15.2539427,17.2267126 14.6759396,17.5069884 13.9444781,16.8371725 L13.8482347,16.7444026 L11.15,14.014 L4.70356686,20.4610287 C4.31385238,20.8507431 3.68200035,20.8507431 3.29228587,20.4610287 C2.90257138,20.0713142 2.90257138,19.4394621 3.29228587,19.0497477 L9.747,12.594 L6.9923534,9.80776254 C6.25308581,9.05978676 6.46312688,8.47295825 7.45529024,8.46524136 L7.58912459,8.46755334 L9.68771481,8.55354913 L10.7,7.529 L7.92893219,4.75735931 C7.5384079,4.36683502 7.5384079,3.73367004 7.92893219,3.34314575 C8.31945648,2.95262146 8.95262146,2.95262146 9.34314575,3.34314575 Z M12.126,8.955 L11.2379121,9.840735 C10.8474471,10.2293576 10.8448345,10.8605841 11.2320693,11.2524255 L12.5943939,12.6309565 C12.9527401,12.9935657 13.5197973,13.0246486 13.9138852,12.7219486 L14.0169211,12.6309805 L14.0169211,12.6309805 L14.903,11.732 L12.126,8.955 Z M18.283139,2.47510726 L18.4099233,2.59296139 L21.1769585,5.39259084 C21.9156349,6.13996846 21.9551764,7.32276295 21.2973142,8.11294974 L21.1812134,8.24087031 L19.135,10.306 L17.716,8.887 L19.0694273,7.51871294 C19.4537628,7.12983149 19.4545191,6.50437033 19.0711253,6.11456047 L17.7536283,4.77501544 C17.3961479,4.4115527 16.8291665,4.37911742 16.4343578,4.6808767 L16.3352459,4.7674535 L14.962,6.133 L13.516,4.687 L15.5992833,2.58412483 C16.3312261,1.84356012 17.5001461,1.80711787 18.283139,2.47510726 Z" id="Shape" fill="#8B96A0"></path>
    </g>
</svg>
</ng-template>
<ng-template #iconVoice>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 2H4.889A2.891 2.891 0 0 0 2 4.889C2 14.34 9.66 22 19.111 22A2.891 2.891 0 0 0 22 19.111V16a2.892 2.892 0 0 0-2.72-2.884l-.169-.005c-.88 0-1.736-.138-2.546-.406l-.195-.054a2.91 2.91 0 0 0-2.727.727l-.887.886-.073-.047a11.42 11.42 0 0 1-2.91-2.906l-.048-.071.866-.869c.769-.741 1.037-1.877.713-2.908a8.147 8.147 0 0 1-.407-2.198l-.008-.376A2.891 2.891 0 0 0 8 2zm0 2c.489 0 .889.4.889.889 0 1.111.178 2.178.507 3.173a.877.877 0 0 1-.138.803l-.085.095-1.955 1.964a13.397 13.397 0 0 0 5.517 5.67l.34.18 1.956-1.956a.907.907 0 0 1 .907-.214c.995.33 2.062.507 3.173.507.451 0 .827.34.882.778L20 16v3.111c0 .489-.4.889-.889.889C10.764 20 4 13.236 4 4.889c0-.451.34-.827.778-.882L4.888 4H8z" fill="#FFF" fill-rule="evenodd"/>
  </svg>
</ng-template>
<ng-template #iconVideo>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5a4 4 0 0 1 3.995 3.8L16 9v6a4 4 0 0 1-3.8 3.995L12 19H5a4 4 0 0 1-3.995-3.8L1 15V9a4 4 0 0 1 3.8-3.995L5 5h7zm0 2H5a2 2 0 0 0-1.995 1.85L3 9v6a2 2 0 0 0 1.85 1.995L5 17h7a2 2 0 0 0 1.995-1.85L14 15V9a2 2 0 0 0-1.85-1.995L12 7zm8.543.702a1.9 1.9 0 0 1 2.636.521c.133.2.228.423.28.686l.033.206.008.166v5.438a1.9 1.9 0 0 1-1.9 1.9 1.87 1.87 0 0 1-.726-.148l-.19-.088-.141-.086-2.7-1.808a1.902 1.902 0 0 1-.805-1.226l-.03-.18L17 12.91v-1.82c0-.497.194-.971.571-1.352l.132-.123.14-.104 2.7-1.809zm.957 6.832.001-5.068L19 11.14l-.002 1.716 2.502 1.678z" fill="#ffffff" fill-rule="evenodd"/>
  </svg>
</ng-template>


<ng-template #editIconChat>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 12.938v2.812h2.813l8.294-8.295-2.812-2.813-8.295 8.296zM15.533 5.28a.747.747 0 0 0 0-1.058l-1.755-1.755a.747.747 0 0 0-1.058 0L11.347 3.84l2.813 2.813 1.373-1.373z" fill="#FFF" fill-rule="evenodd"/>
  </svg>
</ng-template>
<ng-template #deleteIconChat>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 5.25h-9v8.464c0 1.117.893 2.036 2 2.036h5c1.107 0 2-.919 2-2.036V5.25zM6 6.75h6v6.964c0 .298-.232.536-.5.536h-5l-.066-.005a.53.53 0 0 1-.434-.53V6.75zm3.75-4.5a1.5 1.5 0 0 1 1.3.75h2.45a.75.75 0 1 1 0 1.5h-9a.75.75 0 0 1 0-1.5h2.45a1.5 1.5 0 0 1 1.3-.75h1.5z" fill="#FFF" fill-rule="evenodd"/>
  </svg>
</ng-template>

<ng-template #menuMobileTemplate let-action="action">
  <h2 class="swipe-heading" *ngIf="action === 'swipeLeft'">{{ 'LEFT_SWIPE_ACTION' | translate }}</h2>
  <h2 class="swipe-heading" *ngIf="action !== 'swipeLeft'">{{ 'RIGHT_SWIPE_ACTION' | translate }}</h2>
  <vp-dropdown-item [isActive]="isActive(action, 'ARCHIVE')" itemType="menu-item" (onClickEvent)="swipeAction(action, 'ARCHIVE')" [prefixIconTemplate]="iconArchive"
    label="{{ 'ARCHIVE' | translate }}"></vp-dropdown-item>
  <vp-dropdown-item [isActive]="isActive(action, 'DELETE')" itemType="menu-item" (onClickEvent)="swipeAction(action, 'DELETE')" [prefixIconTemplate]="iconDelete"
    label="{{ 'DELETE_FROM_RECENT_LIST' | translate }}"></vp-dropdown-item>
  <vp-dropdown-item [isActive]="isActive(action, 'PIN_CHAT')" itemType="menu-item" (onClickEvent)="swipeAction(action, 'PIN_CHAT')" [prefixIconTemplate]="iconPin"
    label="{{ 'PIN_CHAT' | translate }}"></vp-dropdown-item>
  <vp-dropdown-item [isActive]="isActive(action, 'VOICE_CALL')" itemType="menu-item" (onClickEvent)="swipeAction(action, 'VOICE_CALL')" [prefixIconTemplate]="iconVoice"
    label="{{ 'VOICE_CALL' | translate }}"></vp-dropdown-item>
  <vp-dropdown-item [isActive]="isActive(action, 'VIDEO_CALL')" itemType="menu-item" (onClickEvent)="swipeAction(action, 'VIDEO_CALL')" [prefixIconTemplate]="iconVideo"
    label="{{ 'VIDEO_CALL' | translate }}"></vp-dropdown-item>
</ng-template>


<ng-template #checkMarkIcon>
  <svg width="132" height="132" viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <filter x="-13.8%" y="-29.7%" width="127.6%" height="159.5%" filterUnits="objectBoundingBox" id="7134vtj1ia">
            <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
            <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
            <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
            <feColorMatrix values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"/>
                <feMergeNode in="shadowMatrixOuter2"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
    </defs>
    <g filter="url(#7134vtj1ia)" transform="translate(-410 -104)" fill="#337CBD" fill-rule="evenodd">
        <path d="M473.75 173.127 470.623 170l-1.065 1.058 4.192 4.192 9-9-1.058-1.058z"/>
    </g>
</svg>
</ng-template>

<ng-template #matSelectTrigger let-action="value">
  <div class="mat-trigger-block">
    <div class="mat-trigger-text-style">{{action}}</div>
    <span class="mat-trigger-icon-style">
      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
      </ng-template>
    </span>
  </div>
</ng-template>

<ng-template #matSelectTriggerIcon>
<svg width="24px" height="24px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>Icon/product/keyboard-arrow-down-new</title>
  <g id="Icon/product/keyboard-arrow-down-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M7.25735931,9.79289322 C7.6478836,9.40236893 8.28104858,9.40236893 8.67157288,9.79289322 L12.5,13.6213203 L16.3284271,9.79289322 C16.7189514,9.40236893 17.3521164,9.40236893 17.7426407,9.79289322 C18.133165,10.1834175 18.133165,10.8165825 17.7426407,11.2071068 L12.5,16.4497475 L7.25735931,11.2071068 C6.86683502,10.8165825 6.86683502,10.1834175 7.25735931,9.79289322 Z" id="Path" fill="#8B96A0"></path>
  </g>
</svg>
</ng-template>
