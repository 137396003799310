
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { Notification } from "../../notifications/notifications.model";
import { CommonUtil } from "app/talk/utils/common.util";
import { Broadcaster } from "app/talk/shared/providers";
import { BroadcastKeys } from "app/talk/utils/constants.util";
import { Subject, takeWhile } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-call-invitation",
  templateUrl: "./call-invitation.component.html",
})
export class CallInvitationComponent implements OnInit, OnDestroy {

  public item: Notification;
  public callType: string;
  public showVideo: boolean = true;
  public data: string;
  public isShareScreen: boolean = false;
  isAlive = true;
  private isAlive$ = new Subject<boolean>();

  backgroundImage = "url(" + CommonUtil.getFullUrl("/assets/img/backgroundO.jpg") + " )";
  constructor(public dialogRef: MatDialogRef<CallInvitationComponent>,
    private conversationRepo: ConversationRepository,
    private broadcaster: Broadcaster) {

    this.item = this.conversationRepo.item;
    this.callType = this.conversationRepo.callInvitationType;
    this.data = this.conversationRepo.callInvitationName;
    if (this.callType === "screen") {
      this.isShareScreen = true;
    }

    this.broadcaster.on<string>(BroadcastKeys.HIDE_INVITATION).pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      this.onHide();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  onHide() {
    this.dialogRef.close({ type: "close" });
  }

  onClose() {
    this.dialogRef.close({ type: "" });
  }

  startVideo() {
    this.dialogRef.close({ type: "video" });
  }

  startAudio() {
    this.dialogRef.close({ type: "audio" });
  }

  shareScreen() {
    this.dialogRef.close({ type: "screen" });
  }

  decline() {
    this.dialogRef.close({ type: "" });
  }
}
