
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SearchActionTypes } from "../actions/search";
import { Action } from "../actions";
import { AppType, TimeRangeType } from "../shared/models";
import { AdvanceSearchRequest } from "../shared/models/advance-search-request.model";
import { Contact } from "../talk/models/contact.model";
import { AdvanceSearchTime } from "../shared/models/advance-search-time.model";
import { AdvanceSearchContentType } from "../shared/models/advance-search-content-type.model";
import { SearchMessage } from "../talk/models/search-message.model";


export interface SearchState {
  request: AdvanceSearchRequest;

  keyword: string;
  offset: number;
  total: number;

  selectedMessageId: string | null;

  searchMessages: SearchMessage[];
  searchResultTimeRange: AdvanceSearchTime;
}

export const initialState: SearchState = {
  request: {
    app_types: [
      AppType.VNCTALK
    ],

    time: {
      type: TimeRangeType.LAST_MONTH,
      from: null,
      to: null
    },

    content_type: {
      media: true,
      urls: true,
      documents: true
    },

    users: [],
    tags: [],

    attachments: false,
    starred_messages: false,
    boolean_operators: false,
  },
  searchResultTimeRange: {
    type: TimeRangeType.LAST_MONTH,
    from: null,
    to: null
  },

  keyword: "",
  offset: 0,
  total: 0,

  selectedMessageId: null,

  searchMessages: []
};

export function searchReducer(state: SearchState = initialState, action: Action): SearchState {
  switch (action.type) {

    case SearchActionTypes.SEARCH_RESPONSE_LOAD: {
      return {
        ...state,
        searchMessages: action.payload,
        searchResultTimeRange: state.request.time
      };
    }

    case SearchActionTypes.SEARCH_RESPONSE_NEXT_PAGE: {
      return {
        ...state,
        searchMessages: [
          ...state.searchMessages,
          ...action.payload
        ],
        searchResultTimeRange: state.request.time
      };
    }

    case SearchActionTypes.SEARCH_SELECTED_MESSAGE_ID: {
      return {
        ...state,
        selectedMessageId: action.payload
      };
    }

    case SearchActionTypes.SEARCH_TOTAL_UPDATE: {
      return {
        ...state,
        total: action.payload as number
      };
    }

    case SearchActionTypes.SEARCH_KEYWORD_UPDATE: {
      return {
        ...state,
        keyword: action.payload as string
      };
    }

    case SearchActionTypes.SEARCH_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload as number
      };
    }

    case SearchActionTypes.SEARCH_CLEAR_ALL: {
      return {
        ...state,

        keyword: "",
        offset: 0,
        total: 1,

        searchMessages: []
      };
    }

    case SearchActionTypes.SEARCH_APP_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          app_types: action.payload as AppType[]
        }
      };
    }

    case SearchActionTypes.SEARCH_TIME_RANGE_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          time: action.payload as AdvanceSearchTime
        }
      };
    }

    case SearchActionTypes.SEARCH_CONTENT_TYPE_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          content_type: action.payload as AdvanceSearchContentType
        }
      };
    }

    case SearchActionTypes.SEARCH_USERS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          users: action.payload as Contact[]
        }
      };
    }

    case SearchActionTypes.SEARCH_TAGS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          tags: action.payload as string[]
        }
      };
    }

    case SearchActionTypes.SEARCH_ATTACHMENTS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          attachments: action.payload as boolean
        }
      };
    }

    case SearchActionTypes.SEARCH_STARRED_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          starred_messages: action.payload as boolean
        }
      };
    }

    case SearchActionTypes.SEARCH_BOOLEAN_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          boolean_operators: action.payload as boolean
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const _getAdvanceSearchRequest = (state: SearchState) => state.request;

export const _getSearchKeyword = (state: SearchState) => state.keyword;
export const _getSearchOffset = (state: SearchState) => state.offset;
export const _getSearchTotal = (state: SearchState) => state.total;

export const _getSearchSelectedMessageId = (state: SearchState) => state.selectedMessageId;

export const _getSearchResults = (state: SearchState) => state.searchMessages;
export const _getSearchResultTimeRange = (state: SearchState) => state.searchResultTimeRange;
