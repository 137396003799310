
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { delay, retryWhen, take } from "rxjs";
import { ContactRepository } from "../../../repositories/contact.repository";
import { CommonUtil } from "../../../utils/common.util";

@Component({
    selector: "vp-last-activity",
    template: `<div *ngIf="lastActivityTime">{{ lastActivityTime?.key|translate:{time:lastActivityTime?.value} }}</div>`
  })
  export class LastActivityComponent implements OnInit, OnDestroy {
    lastActivityTime: { key: string, value: number | string };
    activitySubscription$;
    @Input() target: string;

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private contactsRepo: ContactRepository) {}

    ngOnInit() {
        this.getLastActivity(this.target);
    }

    ngOnDestroy() {
      if (this.activitySubscription$) {
        this.activitySubscription$.unsubscribe();
      }
    }

    getLastActivity(target: string) {
      this.activitySubscription$ = this.contactsRepo.getLastActivity(target).pipe(retryWhen(err => {
        return err.pipe(delay(1000), take(2));
      }), take(1)).subscribe(seconds => {
        this.lastActivityTime = CommonUtil.lastActivity(parseInt(seconds));
        this.changeDetectionRef.markForCheck();
      });
    }
  }
