
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { CommentItem } from "vnc-library/lib/components/comments/models/comment.model";
import { Action } from ".";


export class CommentActionTypes {
  static COMMENT_LOAD_REQUEST = "[Comment] Load Request";
  static COMMENT_LOAD_SUCCESS = "[Comment] Load Success";

  static COMMENT_ADD = "[Comment] Add";
  static COMMENT_DELETE = "[Comment] Delete";
  static COMMENT_UPDATE = "[Comment] Update";
  static COMMENT_BULK_ADD = "[Comment] Bulk Add";
  static COMMENT_REPLIES_LOAD_REQUEST = "[Topic] Comment Replies Load Request";
  static COMMENT_REPLIES_LOAD_SUCCESS = "[Topic] Comment Replies Load Success";
}

export class CommentLoadRequest implements Action {
  readonly type = CommentActionTypes.COMMENT_LOAD_REQUEST;
}

export class CommentLoadSuccess implements Action {
  readonly type = CommentActionTypes.COMMENT_LOAD_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class CommentAdd implements Action {
  readonly type = CommentActionTypes.COMMENT_ADD;

  constructor(public payload: CommentItem) {
  }
}

export class CommentUpdate implements Action {
  readonly type = CommentActionTypes.COMMENT_UPDATE;

  constructor(public payload: any) {
  }
}

export class CommentDelete implements Action {
  readonly type = CommentActionTypes.COMMENT_DELETE;

  constructor(public payload: number) {
  }
}

export class CommentBulkAdd implements Action {
  readonly type = CommentActionTypes.COMMENT_BULK_ADD;

  constructor(public payload: CommentItem[]) {
  }
}

export class CommentRepliesLoadRequest implements Action {
  readonly type = CommentActionTypes.COMMENT_REPLIES_LOAD_REQUEST;

  constructor(public payload: number) {
  }
}

export class CommentRepliesLoadSuccess implements Action {
  readonly type = CommentActionTypes.COMMENT_REPLIES_LOAD_SUCCESS;

  constructor(public payload: { commentId: number, offset: number, total_count: number }) {
  }
}
