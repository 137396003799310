
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { SideRoasterTabType } from "../utils/constants.util";

export class LayoutActionTypes {
  static EXPAND_CONVERSATION_LIST = "[LAYOUT] Expand Conversation List";
  static EXPAND_SIDE_ROSTER_LIST = "[LAYOUT] Expand Side Chat List";

  static SELECT_SIDE_ROSTER_TAB = "[LAYOUT] Select Side Chat Tab";

  static SHOW_GLOBAL_SEARCH = "[LAYOUT] Show Global Search";
  static HIDE_GLOBAL_SEARCH = "[LAYOUT] Hide Global Search";

  static GLOBAL_SEARCH_RESULT_SELECTED = "[LAYOUT] Global Search Result Selected";
  static GLOBAL_SEARCH_RESULT_DESELECTED = "[LAYOUT] Global Search Result DeSelected";

  static RIGHT_SIDEBAR_EXPANDED = "[LAYOUT] Right Sidebar Expanded";
  static CHAT_RIGHT_SIDEBAR_EXPANDED = "[LAYOUT] Chat Right Sidebar Expanded";
  static CHAT_RIGHT_FILES_SIDEBAR_EXPANDED = "[LAYOUT] Chat Right Files Sidebar Expanded";
  static CHAT_RIGHT_FILES_SIDEBAR_EXPANDED_WITH_FILE_IDS = "[LAYOUT] Chat Right Files Sidebar Expanded With Ids";
  static CHAT_RIGHT_INFO_SIDEBAR_EXPANDED = "[LAYOUT] Chat Right Info Sidebar Expanded";
  static CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE = "[LAYOUT] Chat Right Sidebar Expand wth Profile";
  static CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE_TAB = "[LAYOUT] Chat Right Sidebar Expand wth Profile for tab";
  static CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE_TAB_FILE_IDS = "[LAYOUT] Chat Right Sidebar Expand wth Profile for tab File Ids";
  static CHAT_RIGHT_SIDEBAR_CHAT_INFO  = "[LAYOUT] Chat Right Sidebar Expand for chat info";
  static CHAT_RIGHT_FILE_TAB_SIDEBAR = "[LAYOUT] Chat Right Sidebar file tab";
  static RESET_DOWNLOAD_FILE_IDS = "[LAYOUT] Reset Downlaod File Ids";
}

export class ExpandConversationList implements Action {
  readonly type = LayoutActionTypes.EXPAND_CONVERSATION_LIST;

  constructor(public payload: boolean) {
  }
}

export class ExpandRightSidebar implements Action {
  readonly type = LayoutActionTypes.RIGHT_SIDEBAR_EXPANDED;

  constructor(public payload: boolean) {
  }
}

export class ExpandChatRightSidebar implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPANDED;

  constructor(public payload: boolean) {
  }
}

export class ExpandChatFilesSidebar implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_FILES_SIDEBAR_EXPANDED;

  constructor(public payload: boolean) {
  }
}

export class ExpandChatFilesSidebarWithFileIds implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_FILES_SIDEBAR_EXPANDED_WITH_FILE_IDS;

  constructor(public payload: {status: boolean, ids: any}) {
  }
}

// open sidebar with chat info tab
export class ExpandChatInfoSidebar implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_INFO_SIDEBAR_EXPANDED;

  constructor(public payload: boolean) {
  }
}

export class SetChatFileTabSidebar implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_FILE_TAB_SIDEBAR;

  constructor(public payload: string) {
  }
}

export class ExpandChatRightSidebarWithProfile implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE;

  constructor(public payload: string) {
  }
}

export class ExpandChatRightSidebarWithProfileTab implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE_TAB;

  constructor(public payload: string) {
  }
}

export class ExpandChatRightSidebarWithProfileTabFileIds implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE_TAB_FILE_IDS;

  constructor(public payload: {tab: string, ids: any}) {
  }
}

export class ExpandChatRightSidebarChatInfo implements Action {
  readonly type = LayoutActionTypes.CHAT_RIGHT_SIDEBAR_CHAT_INFO;

  constructor(public payload: string) {
  }
}

export class ExpandSideRostersList implements Action {
  readonly type = LayoutActionTypes.EXPAND_SIDE_ROSTER_LIST;

  constructor(public payload: boolean) {
  }
}

export class SelectSideRostersTab implements Action {
  readonly type = LayoutActionTypes.SELECT_SIDE_ROSTER_TAB;

  constructor(public payload: SideRoasterTabType) {
  }
}

export class ShowGlobalSearch implements Action {
  readonly type = LayoutActionTypes.SHOW_GLOBAL_SEARCH;

}

export class HideGlobalSearch implements Action {
  readonly type = LayoutActionTypes.HIDE_GLOBAL_SEARCH;

}

export class GlobalSearchResultSelected implements Action {
  readonly type = LayoutActionTypes.GLOBAL_SEARCH_RESULT_SELECTED;

}

export class GlobalSearchResultDeSelected implements Action {
  readonly type = LayoutActionTypes.GLOBAL_SEARCH_RESULT_DESELECTED;

}

export class ResetDownloadFileIds implements Action {
  readonly type = LayoutActionTypes.RESET_DOWNLOAD_FILE_IDS;
}
