
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { BehaviorSubject, tap } from "rxjs";

@Injectable()
export class GroupChatsService {
  public audienceList$ = new BehaviorSubject<string[]>([]);
  constructor(private middlewareService: MiddlewareService) {
  }

  updateGroupAvatar(target: string, photo: any) {
    let data = { "avatar_base64" : "data:image/png;base64," + photo.data };
    return this.middlewareService.post(`/api/group_chats/avatar/` + target, true, data);
  }

  searchMUC(searchstring: string) {
    return this.middlewareService.post(`/api/searchmuc`, true, {searchstring});
  }

  removeGroupAvatar(target: string) {
    let data = { "delete" : "true" };
    return this.middlewareService.post(`/api/group_chats/avatar/` + target, true, data);
  }

  setSubject(target: string, newTitle: string) {
    let data = { "group_chat": { "subject" : newTitle }};
    return this.middlewareService.post(`/api/group_chats/subject/` + target, true, data);
  }

  updateGroupInfo(target: string, params: any) {
    // console.trace("[updateGroupInfo]", target, params);

    let data = { "group_chat": params};
    return this.middlewareService.post(`/api/group_chats/${target}`, true, data);
  }

  getGroupInfo(target: string) {
    // console.trace("[getGroupInfo]", target);

    return this.middlewareService.get(`/api/group_chats/${target}`, true).pipe(tap((res: any) => {
      if (res && res.group_chat && res.group_chat.affiliations_audience) {
        this.audienceList$.next( res.group_chat.affiliations_audience.map(v => v.jid));
      } else {
        this.audienceList$.next([]);
      }
    }));
  }

  setRoomAffiliation(target: string, userJid: string[], role: string) {
    let uniqJids = [];
    for (let i = 0; i < userJid.length; i++) {
      if (uniqJids.indexOf(userJid[i]) === -1) {
        uniqJids.push(userJid[i]);
      }
    }
    let aRole;
    // console.log("[setRoomAffiliation] arr ", target, userJid, role);
    if ( role === null || role === "" || role === "member" || !role) {
        aRole = "member";
    } else if ( role === "moderator" || role === "admin") {
        aRole = "admin";
    } else if (role === "audience") {
        aRole = "audience";
    } else if ( role === "none" ) {
      aRole = "none";
    } else {
      aRole = role;
    }
    let affiliationArr = {};
    for (let i = 0; i < uniqJids.length; i++){
        affiliationArr[i.toString()] = { "user_email": uniqJids[i], "affiliation": aRole };
        // console.log("[setRoomAffiliation] arr ", i, affiliationArr[i.toString()]);
    }
    let data = { "affiliation_attributes": affiliationArr };
    return this.middlewareService.post(`/api/group_chats/affiliation/` + target, true, data);
  }

  leaveConversation(userJid: string, target: string) {
    let data = {};
    data["0"] = { "user_email": userJid, "affiliation": "none" };
    let body = { "affiliation_attributes": data };
    return this.middlewareService.post("/api/leavegroup/" + target, true, body);
  }


  // toDo: affiliation_attributes, group_chat as data object
  setRoomAffiliationAndData(target: string, affiliations: any, roomData: any) {
    // console.trace("[setRoomAffiliation]", target);

    let data = { "affiliation_attributes": affiliations, "group_chat": roomData };
    return this.middlewareService.post(`/api/group_chats/` + target, true, data);
  }

  joinPublicRoom(target: string) {
    let body = { target: target };
    return this.middlewareService.post("/api/joingroup/" + target, true, body);
  }

  public checkRunningCallViaRest(target: string) {
    return this.middlewareService.get<any>(`/api/checkRunningCall//${target}`, true);
  }
}
