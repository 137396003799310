
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, ElementRef, AfterViewInit } from "@angular/core";

@Directive({ selector: "[vpPopoverPos]" })
export class PopoverPosDirective implements AfterViewInit {

  classes = ["pos-top", "pos-bottom", "pos-left", "pos-right"];

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    let elem = this.el.nativeElement;
    elem.style.visibility = "hidden";
    this.classes.forEach(_class => {
      elem.classList.remove(_class);
    });
    let rect = elem.getBoundingClientRect();
    if (rect.bottom > window.innerHeight) {
      elem.classList.add("pos-top");
    } else {
      elem.classList.add("pos-bottom");
    }
    if (rect.right > window.innerWidth) {
      elem.classList.add("pos-left");
    } else {
      elem.classList.add("pos-right");
    }
    elem.style.visibility = "visible";
  }

}
