
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PadService } from "app/talk/chat-window/pad/services/pad.service";
import { ToastService } from "app/shared/services/toast.service";
import { Broadcaster } from "app/talk/shared/providers";
import { take } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-select-app-dialog",
  templateUrl: "./select-app-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAppDialogComponent {

  constructor(private matDialogRef: MatDialogRef<SelectAppDialogComponent>, private broadcast: Broadcaster, private padService: PadService, private toastService: ToastService) {
    this.broadcast.on("closeSelectAppDialog").pipe(take(1)).subscribe(() => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  showTextPad() {
    this.padService.showPadWindow();
    this.broadcast.broadcast("hideMessageSelection");
    this.matDialogRef.close("pad");
  }

  showSpreadsheet() {
    this.toastService.show("UNDER_DEVELOPMENT");
    this.matDialogRef.close();
  }
}
