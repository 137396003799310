
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy ,
  ChangeDetectorRef ,
  Component ,
  HostListener ,
  Inject ,
  InjectionToken ,
  OnDestroy ,
} from "@angular/core";
import { Broadcaster } from "../../providers";
import { CommonService } from "../../../../shared/providers/common.service";
import { JID } from "../../../models/jid.model";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../../../reducers";
import {
  getContactById ,
  getIsAppOnline ,
  getUserJID ,
  getUserProfile
} from "../../../../reducers";
import { XmppService } from "../../../services/xmpp.service";
import { ConversationRepository } from "../../../repositories/conversation.repository";
import { ContactRepository } from "../../../repositories/contact.repository";
import { Contact } from "../../../models/contact.model";
import { ConferenceRepository } from "../../../repositories/conference.repository";
import { ToastService } from "../../../../shared/services/toast.service";
import { filter, Subject, take, takeUntil } from "rxjs";
import { NavigationEnd, Router } from "../../../../../../node_modules/@angular/router";
import { Conversation } from "../../../models/conversation.model";
import { JitsiService } from "../../../services/jitsi.service";
import { CommonUtil } from "../../../utils/common.util";
import { VNCEditProfileComponent } from "./edit-profile.component";
import { ContactInformation } from "../../../models/vcard.model";
import { Photo } from "../../../models/photo.model";
import { SetActiveTab } from "app/actions/app";
import { AvatarRepository } from "app/talk/repositories/avatar.repository";
import { environment } from "app/environments/environment";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export const PROFILE_JID = new InjectionToken<string>("profile_jid");

@Component({
  selector: "vp-profile" ,
  templateUrl: "./profile.html" ,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VNCProfileComponent implements OnDestroy {
  loggedInUserJID: JID;

  editable = false;
  showUploadForm = false;
  conversationTitle = "";
  localTime = "";
  data: any;
  m3: any;
  contact: Contact;
  lang = "en";
  private isAlive$ = new Subject<boolean>();
  isAppOnline = false;
  vCard: ContactInformation;
  originalData: ContactInformation;
  fullName = "";
  isOnSafari = CommonUtil.isOnSafari() && !CommonUtil.isOnNativeMobileDevice();
  enableEditProfile = environment.enableEditProfile;
  oldAvatarUrl: string;
  showDefaultAvatar: boolean;
  constructor(public xmppService: XmppService ,
              private toastService: ToastService ,
              private contactRepo: ContactRepository ,
              private dialogService: MatDialog ,
              private dialog: MatDialogRef<VNCProfileComponent> ,
              private broadcaster: Broadcaster ,
              private commonService: CommonService ,
              @Inject(MAT_DIALOG_DATA) public jidBare: string ,
              private store: Store<TalkRootState> ,
              private conversationRepo: ConversationRepository ,
              private conferenceRepo: ConferenceRepository ,
              private configService: ConfigService,
              private electronService: ElectronService,
              private jitsiService: JitsiService,
              private router: Router ,
              private avatarRepo: AvatarRepository,
              private changeDetectionRef: ChangeDetectorRef) {
    this.setupStore();
    this.conferenceRepo.toggleHideVideoIOS(true);
    this.data = {};
    this.oldAvatarUrl = CommonUtil.translateHINFileURL(this.configService.avatarServiceUrl + "/" + this.buildTargetHash(this.jidBare) + ".jpg?v=" + new Date().getTime());
    this.showDefaultAvatar = true;
    this.lang = CommonUtil.getDefaultLang();
    this.commonService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
      this.lang = lang;
      this.changeDetectionRef.markForCheck();
    });
    this.broadcaster.on<any>("closeDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.dialog.close();
        this.changeDetectionRef.markForCheck();
      });
    this.store.select(state => getContactById(state , this.jidBare)).pipe(takeUntil(this.isAlive$)).subscribe(c => {
      this.contact = c;
      this.changeDetectionRef.markForCheck();
    });

    this.getVCard();
  }

  private buildTargetHash(target) {
    if (this.electronService.isElectron) {
      return this.electronService.md5(target);
    }
    return md5(target);
  }

  private setupStore() {
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
    this.store.select(getUserJID).pipe(takeUntil(this.isAlive$)).subscribe(jid => {
      this.loggedInUserJID = jid;

      if (this.loggedInUserJID && this.loggedInUserJID.bare === this.jidBare) {
        this.store.select(getUserProfile).pipe(take(1)).subscribe(res => {
          this.fullName = res.user.firstName + " " + res.user.lastName;
        });
      } else {
        this.fullName = this.contactRepo.getFullName(this.jidBare);
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  @HostListener("window:resize" , ["$event"])
  onResize() {
    window.scrollTo(0 , 0);
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
  }

  close() {
    this.dialog.close();
  }

  getVCard() {
    this.contactRepo.getContactVCard(this.jidBare).pipe(takeUntil(this.isAlive$)).subscribe(data => {
      let vCard = data || {};
      if (vCard.addresses && vCard.addresses.length > 0) {
        vCard.addresses = vCard.addresses.map(address => CommonUtil.mapAddress(address));
      }

      if (vCard.emails && vCard.emails.length > 0) {
        vCard.emails = vCard.emails.map(email => CommonUtil.mapEmail(email));
      }

      if (vCard.phoneNumbers && vCard.phoneNumbers.length > 0) {
        vCard.phoneNumbers = vCard.phoneNumbers.map(phone => CommonUtil.mapPhone(phone));
      }
      this.vCard = vCard;
      if (vCard.fullName) {
        this.fullName = vCard.fullName;
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  changeAvatar() {
    this.showUploadForm = true;
    this.changeDetectionRef.markForCheck();
  }

  changedAvatar(data) {
    this.showUploadForm = false;
    this.changeDetectionRef.markForCheck();
    if (data && data.photo) {
      this.data.image = data.photo.data;
      this.updateAvatar();
    }
  }

  updateAvatar() {
    if (!this.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }
    if (!this.data.image) {
      this.showUploadForm = false;
      this.data = {};
      this.changeDetectionRef.markForCheck();
      return;
    }

    let b64Data = this.data.image.split(",")[1];
    let photo: Photo = {
      type: "image/png" ,
      data: b64Data
    };
    let originalData: ContactInformation = {};
    this.contactRepo.getContactVCard(this.jidBare).pipe(takeUntil(this.isAlive$)).subscribe(data => {
      originalData = data;
    });
    const newData = {
      ...originalData,
      ...{photo: photo}
    };
    this.contactRepo.publishVCards(newData).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.toastService.showMatSnackBar("PROFILE_PHOTO_UPDATED");
      this.showUploadForm = false;
      this.data = {};
      setTimeout(() => {
        this.avatarRepo.upgradeAvatar(this.jidBare);
        this.oldAvatarUrl = CommonUtil.translateHINFileURL(this.configService.avatarServiceUrl + "/" + this.buildTargetHash(this.jidBare) + ".jpg?v=" + new Date().getTime());
        // broadcast avatar change to all contacts
        this.contactRepo.notifyOnAvatarUpdate().pipe(take(1)).subscribe();
      }, 1000);
      this.showDefaultAvatar = true;
      this.changeDetectionRef.markForCheck();
    });
  }

  sendMessage() {
    this.store.dispatch(new SetActiveTab("chat"));
    this.conversationRepo.navigateToConversation(this.jidBare);
    this.broadcaster.broadcast("closeDialog");
    this.close();
  }

  startCall(callType: string) {
    if (!this.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }
    this.store.dispatch(new SetActiveTab("chat"));
    if (this.jitsiService.isJoined) {
      this.conferenceRepo.hideActiveCall();
      this.conferenceRepo.leaveConference();
      this.jitsiService.leave();
    }
    let conversation: Conversation;
    this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
      conversation = conv;
    });
    if (conversation && conversation.Target === this.jidBare) {
      this.conferenceRepo.startConference(this.jidBare, callType);
    } else {
      this.conversationRepo.navigateToConversation(this.jidBare);
      this.router.events
        .pipe(filter(e => e instanceof NavigationEnd), take(1))
        .subscribe(() => {
            this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
              if (conv && conv.Target === this.jidBare) {
                this.conferenceRepo.startConference(this.jidBare , callType);
              }
            });
        });
    }

    this.close();
    this.broadcaster.broadcast("closeDialog");
  }

  mailTo(event) {
    event.preventDefault();
  }

  ngOnDestroy() {
    this.conferenceRepo.toggleHideVideoIOS(false);
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  editProfile() {
    if (!this.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }

    let dialogStyles = {
      "width": "490px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "100%",
        "visibility": "visible"
      };
    }
    this.dialogService.open(VNCEditProfileComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      autoFocus: true
    }, dialogStyles));
  }

  removeAvatar(): void {
    this.contactRepo.deleteAvatar(this.jidBare).subscribe(() => {
      this.showDefaultAvatar = false;
      this.data = {};
      this.changeDetectionRef.markForCheck();
      setTimeout(() => {
        this.avatarRepo.removeAvatar(this.jidBare);

        // broadcast avatar change to all contacts
        this.contactRepo.notifyOnAvatarUpdate().pipe(take(1)).subscribe();
      }, 1000);
    });
  }

  imgLoadOnError() {
    this.showDefaultAvatar = false;
    this.changeDetectionRef.markForCheck();
  }

  isOnMobile() {
    return CommonUtil.isOnMobileDevice();
  }
}
