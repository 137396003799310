<div *ngIf="!isMobileScreen" class="trash-window-container">
  <div class="header">
    <div class="header-left">
      <div class="close-btn">
        <vnc-icon-container (click)="exitTrashWindow()">
          <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </vnc-icon-container>
      </div>
      <div class="title">{{ 'TRASH' | translate }}</div>
    </div>
    <div class="header-right" id="setting-link">
      <vnc-action-link [type]="'icon-left'"
                       [linkColor]="'blue'"
                       [label]="'SETTINGS' | translate"
                       [leadingIconTemplate]="settingsIconTemplate"
                       (actionClicked)="openTrashSettings(isMobileScreen)"></vnc-action-link>
    </div>
  </div>
  <div class="tabs">
    <mat-tab-group class="sidebar-container-channels-tab-group" animationDuration="0ms" [selectedIndex]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'CHANNELS' | translate }}
          <span class="channel-count">{{channelsCount}}</span>
        </ng-template>
        <vp-trash-window-channels (totalChannels)="channelsCount = $event"></vp-trash-window-channels>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'TOPICS' | translate }}
          <span class="topic-count">{{topicsCount}}</span>
        </ng-template>
        <vp-trash-window-topics (totalTopics)="topicsCount = $event"></vp-trash-window-topics>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div *ngIf="isMobileScreen">
  <vnc-dialog [classes]="['trash-window-wrapper-mobile']" [headerTemplate]="mobileHeaderTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="mobileBodyTemplate">
  </vnc-dialog>

  <ng-template #mobileHeaderTemplate>
    <div class="header-right" id="setting-link">
      <vnc-mobile-header headerType="dark-back-more"
                        darkBackMorePlaceHolder="{{'TRASH' | translate}}"
                        [sufixIconTemplate1]="settingsIconMobileTemplate"
                        (onSufixIcon1Click)="openTrashSettings(true)"
                        (darkBackPress)="exitTrashWindow()">
      </vnc-mobile-header>
    </div>
  </ng-template>

  <ng-template #mobileBodyTemplate>
    <vp-trash-window-channels-mobile [isMobileScreen]="true" [hidden]="_trashWindowService.currentActiveTab === 'topics'"></vp-trash-window-channels-mobile>
    <vp-trash-window-topics-mobile [isMobileScreen]="true" [hidden]="_trashWindowService.currentActiveTab === 'channels'"></vp-trash-window-topics-mobile>
  </ng-template>
  <ng-template #footerTemplate>
    <span></span>
  </ng-template>
</div>



<ng-template #settingsIconTemplate>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="#337CBD">
        <g>
          <g>
            <g>
              <path d="M7.516 1.5c2.932 0 4.696 1.999 4.775 3.986l.002.187-.017.73c-.005.47.021.763.105 1.078.129.488.388.885.846 1.222l2.493 1.848 1.229.916c.332.248.4.718.152 1.05-.23.306-.647.388-.971.204l-.118-.081-2.763-2.056-.911-.673c-.741-.546-1.193-1.236-1.407-2.046-.124-.465-.16-.86-.156-1.437l.018-.803C10.793 4.372 9.628 3 7.516 3c-.39 0-.739.01-1.051.027l-.256.017.786.396.566.297c1.522.816 1.448 2.285.61 3.787C7.37 8.964 6.285 9.718 4.75 9.08l-.723-.307-.692-.302.057.141c.06.14.128.28.202.416l.116.202c.24.394.531.743.882 1.038.626.528 1.585.744 3.234.744.853 0 1.7.266 2.514.703.212.114.403.229.565.335l.222.15.175.132.07.056 3.717 2.76c.332.248.401.718.154 1.05-.228.307-.645.39-.97.206l-.079-.05-3.75-2.788-.098-.076-.11-.079c-.18-.127-.384-.255-.606-.374-.614-.33-1.234-.525-1.804-.525-1.972 0-3.238-.285-4.2-1.097-.48-.404-.877-.878-1.198-1.405-.635-1.045-.885-2.08-.927-2.807-.032-.565.549-.961 1.063-.724l.63.286c.317.144.64.287.956.426l.706.306.47.198c.623.259 1.058-.044 1.535-.9.482-.864.511-1.456-.009-1.735L6.5 4.873l-.637-.325c-.302-.152-.618-.308-.94-.464l-1.132-.541c-.37-.173-.533-.609-.368-.982.129-.293.447-.54.976-.716.662-.22 1.671-.345 3.117-.345z" transform="translate(-1798.000000, -81.000000) translate(360.000000, 64.000000) translate(1435.000000, 14.000000) translate(3.000000, 3.000000)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
<ng-template #settingsIconMobileTemplate>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.022 2c3.908 0 6.26 2.665 6.366 5.315l.003.248-.023.974c-.007.626.028 1.017.14 1.438.172.65.518 1.18 1.128 1.629l3.324 2.464 1.639 1.222a1 1 0 0 1-1.092 1.67l-.157-.107-3.685-2.741-1.214-.898c-.989-.727-1.591-1.647-1.877-2.727-.164-.62-.214-1.146-.207-1.917l.024-1.07c0-1.67-1.553-3.5-4.37-3.5-.519 0-.985.013-1.401.036l-.341.023 1.048.528.754.395c2.03 1.088 1.93 3.047.814 5.05-1.07 1.919-2.515 2.925-4.562 2.073l-.964-.409-.922-.403.076.189c.08.187.17.372.268.554l.155.27c.32.525.71.99 1.177 1.384.835.704 2.113.992 4.312.992 1.137 0 2.266.354 3.351.937.284.152.538.305.754.446l.296.2.234.176.092.074 4.956 3.682a1 1 0 0 1-1.087 1.674l-.106-.068-5-3.717-.13-.1-.146-.106c-.24-.17-.513-.34-.809-.5-.819-.439-1.645-.698-2.405-.698-2.63 0-4.317-.38-5.601-1.463a7.587 7.587 0 0 1-1.596-1.874c-.847-1.393-1.18-2.773-1.236-3.742a1 1 0 0 1 1.417-.966l.839.382c.424.191.853.382 1.276.568l.941.407.627.265c.83.345 1.41-.06 2.046-1.2.642-1.152.682-1.942-.012-2.314l-.469-.247-.85-.434c-.402-.202-.824-.41-1.253-.619l-1.51-.721a1 1 0 0 1-.49-1.31c.172-.39.595-.718 1.301-.954.883-.294 2.228-.46 4.157-.46z" fill="#FFF" fill-rule="evenodd"/>
  </svg>
</ng-template>

