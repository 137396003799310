
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Message, MessageStatus } from "../models/message.model";
import { Action } from "../../actions";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { MessageActionTypes } from "../actions/message";
import { ConversationActionTypes } from "../actions/conversation";
import { AppActionTypes } from "../../actions/app";
import { CommonUtil } from "../utils/common.util";

export interface MessageState extends EntityState<Message> {
}

export const messageAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  selectId: (message: Message) => message.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(message1: Message, message2: Message): number {
  return message1.timestamp - message2.timestamp;
}

export const initialState: MessageState = messageAdapter.getInitialState({
  //
});

export function messageReducer(state: MessageState = initialState, action: Action): MessageState {
  switch (action.type) {

    case MessageActionTypes.MESSAGE_ADD: {
      const message = action.payload.message;

      if (message.html && message.html.body) {
        message.htmlBody = message.html.body.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
      }
      let cachedContent = CommonUtil.generateCachedBody(message.body);
      if (message.htmlBody) {
        cachedContent = CommonUtil.generateCachedBody(message.htmlBody);
      }
      if (typeof message.from === "string" || typeof message.to === "string") {
        return state;
      }
      // const conversationTarget = payload.conversationTarget;

      message.from = { ...message.from };
      message.to = { ...message.to };
      // eslint-disable-next-line no-console
      console.log("MESSAGE_ADD", message, cachedContent);
      return messageAdapter.upsertOne({...message, cachedContent}, state);
    }

    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD: {
      const data = action.payload;
      let localState = {...state};
      // eslint-disable-next-line no-console
      console.log("MULTI_CONVERSATION_MESSAGE_ADD", data);
      const messages = data.map(item => {
        let message = item.message;
        message.cachedContent = CommonUtil.generateCachedBody(message.body);
        if (message.htmlBody) {
          message.cachedContent = CommonUtil.generateCachedBody(message.htmlBody);
        } else if (message.html && message.html.body) {
          message.htmlBody = message.html.body.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
          message.cachedContent = CommonUtil.generateCachedBody(message.htmlBody);
        }
        if (typeof message.from === "string" || typeof message.to === "string") {
          return state;
        }
        message.from = { ...message.from };
        message.to = { ...message.to };
        return message;
      });
      return messageAdapter.upsertMany(messages, localState);
   }

    case MessageActionTypes.MESSAGE_BULK_APPEND: {
      // TODO: move to Web Worker
      //
      let messages = action.payload.messages || [];
      messages = messages
        .filter(message => (!message.replace || message.replace && message.body?.trim() === "")
        && !(typeof message.from === "string" || typeof message.to === "string"))
        .map(message => {
          // copy
          const newMsg = Object.assign({}, message);
          newMsg.from = { ...newMsg.from };
          newMsg.to = { ...newMsg.to };

          if (newMsg.htmlBody) {
            newMsg.cachedContent = CommonUtil.generateCachedBody(message.htmlBody);
          } else if (message.html && message.html.body) {
            newMsg.htmlBody = message.html.body.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
            newMsg.cachedContent = CommonUtil.generateCachedBody(message.htmlBody);
          } else {
            newMsg.cachedContent = CommonUtil.generateCachedBody(message.body);
          }
          return newMsg;
      });
      // eslint-disable-next-line no-console
      console.log("MESSAGE_BULK_APPEND", messages);
      return messageAdapter.upsertMany(messages, state);
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let combinedMessages = [];
      data.forEach(item => {
        const messages = item.messages
          .filter(message => !(typeof message.from === "string" || typeof message.to === "string"))
          .map(message => {
            message.cachedContent = CommonUtil.generateCachedBody(message.body);
            if (message.htmlBody) {
              message.cachedContent = CommonUtil.generateCachedBody(message.htmlBody);
            }
            return {
              ...message,
              from: { ...message.from },
              to: { ...message.to }
            };
        });
        combinedMessages.push(...messages);
      });
      // eslint-disable-next-line no-console
      console.log("MESSAGE_BULK_APPEND_MULTI_CONVERSATION", combinedMessages);
      return messageAdapter.upsertMany(combinedMessages, state);

    }

    case MessageActionTypes.MESSAGE_DELETE_ACTION: {
      return messageAdapter.removeOne(action.payload, state);
    }

    case MessageActionTypes.MESSAGE_STATUS_UPDATE: {
      return messageAdapter.updateOne({ id: action.payload.id, changes: { status: action.payload.status } }, state);
    }

    case MessageActionTypes.MESSAGE_UPDATE: {
      // eslint-disable-next-line no-console
      console.log("MESSAGE_UPDATE", action.payload.changes);
      // TODO
      // message.cachedContent = CommonUtil.generateCachedBody(message.body);
      return messageAdapter.updateOne({ id: action.payload.id, changes: action.payload.changes }, state);
    }

    case MessageActionTypes.MESSAGE_STATUS_UPDATE_DELIVERED: {
      const ids = action.payload;
      let localState = { ...state };
      ids.forEach(id => {
        localState = messageAdapter.updateOne({
          id: id,
          changes: { status: MessageStatus.DELIVERED }
        }, localState);
      });
      return localState;
    }

    case MessageActionTypes.MESSAGE_DELETED_STATUS_UPDATE: {
      return messageAdapter.updateOne({
        id: action.payload.id,
        changes: { isDeleted: action.payload.isDeleted, body: null }
      }, state);
    }

    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_DELETED_STATUS_UPDATE: {

      const data = action.payload;
      let localState = {...state};

      data.forEach(item => {
        localState = messageAdapter.updateOne({
          id: item.id,
          changes: { isDeleted: item.isDeleted, body: null }
        }, localState);
      });
      return localState;
    }

    case MessageActionTypes.MESSAGE_FAVORITE_UPDATE: {
      return messageAdapter.updateOne({
        id: action.payload.id,
        changes: { isStarred: action.payload.isStarred }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_DELETE: {
      const ids = messageAdapter.getSelectors().selectAll(state)
        .filter(m => m.status === MessageStatus.PENDING && m.pendingIn === action.payload)
        .map(m => m.id);
      return messageAdapter.removeMany(ids, state);
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.messageState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case MessageActionTypes.MESSAGE_BULK_DELETE: {
      return messageAdapter.removeMany(action.payload.messageIds, state);
    }

    case MessageActionTypes.MESSAGES_BULK_UPDATE: {
      const changes = action.payload.map(msg => {
        return { id: msg.id, changes: msg };
      });
      return messageAdapter.updateMany(changes, state);
    }

    default: {
      return state;
    }
  }
}
