
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { CLEAR_INTERVAL, INTERVAL_TIMEOUT, SET_INTERVAL, timerWorkerScript } from "./timer.worker";

/**
 * Represents a modified MediaStream that adds video as pip on a desktop stream.
 * <tt>JitsiStreamPresenterEffect</tt> does the processing of the original
 * desktop stream.
 */
export default class JitsiStreamPresenterEffect {
    _canvas: any;
    _ctx: CanvasRenderingContext2D;
    _desktopElement: HTMLVideoElement;
    _desktopStream: MediaStream;
    _frameRate: number;
    _onVideoFrameTimerWorker: Function;
    _videoFrameTimerWorker: any;
    _videoElement: HTMLVideoElement;

    constructor(videoStream: MediaStream, desktopStream: MediaStream) {
        if (document.getElementById("presenterVideoHidden")) {
            document.getElementById("presenterVideoHidden").remove();
        }
        const videoDiv = document.createElement("div");
        videoDiv.id = "presenterVideoHidden";
        const firstVideoTrack = videoStream.getVideoTracks()[0];
        const { height, width, frameRate } = firstVideoTrack.getSettings() ? firstVideoTrack.getSettings() : firstVideoTrack.getConstraints();

        this._canvas = document.createElement("canvas");
        this._ctx = this._canvas.getContext("2d");

        this._desktopElement = document.createElement("video");
        this._desktopElement.id = "desktopElement";
        this._videoElement = document.createElement("video");
        videoDiv.appendChild(this._videoElement);
        videoDiv.appendChild(this._desktopElement);
        if (document.body !== null) {
            document.body.appendChild(videoDiv);
        }

        // Set the video element properties
        this._frameRate = parseInt(frameRate.toString(), 10);
        this._videoElement.width = parseInt(width.toString(), 10);
        this._videoElement.height = parseInt(height.toString(), 10);
        this._videoElement.autoplay = true;
        this._videoElement.srcObject = videoStream;

        setTimeout(() => {
            if (document.getElementById("presenterVideo")) {
                (<HTMLVideoElement> document.getElementById("presenterVideo")).srcObject = videoStream;
            }
        }, 200);

        // set the style attribute of the div to make it invisible
        videoDiv.style.visibility = "hidden";
        videoDiv.style.position = "fixed";
        videoDiv.style.width = "100%";
        videoDiv.style.height = "100%";
        this._desktopStream = desktopStream;
        // Bind event handler so it is only bound once for every instance.
        this._onVideoFrameTimer = this._onVideoFrameTimer.bind(this);
    }

    /**
     * EventHandler onmessage for the videoFrameTimerWorker WebWorker.
     *
     * @private
     * @param {EventHandler} response - The onmessage EventHandler parameter.
     * @returns {void}
     */
    _onVideoFrameTimer(response) {
        if (response.data.id === INTERVAL_TIMEOUT) {
            this._renderVideo();
        }
    }

    /**
     * Loop function to render the video frame input and draw presenter effect.
     *
     * @private
     * @returns {void}
     */
    _renderVideo() {
        // adjust the canvas width/height on every frame incase the window has been resized.
        const [track] = this._desktopStream.getVideoTracks();
        let { height, width } = track.getSettings() ? track.getSettings() : track.getConstraints();
        if (/firefox/.test(navigator.userAgent.toLowerCase()) && document.getElementById("desktopElement")) {
            const newWidth = this.calculateVideoSize(width, height).width;
            const newHeight = this.calculateVideoSize(width, height).height;
            width = newWidth;
            height = newHeight;
            this._desktopElement.height = parseInt(height.toString(), 10);
        }
        // revert - the window of the talk application does not matter here. you may not only share full screen but a window,
        // and even then different aspect ratios are not uncommon, e.g. 4:3 (1600x1200), 5/4 (1280x1024), 16/9 (1920x1080) etc ...
        this._canvas.width = parseInt(width.toString(), 10);
        this._canvas.height = parseInt(height.toString(), 10);
        this._ctx.drawImage(this._desktopElement, 0, 0, parseInt(width.toString(), 10), parseInt(height.toString(), 10));
        this._ctx.drawImage(this._videoElement, this._canvas.width - this._videoElement.width, this._canvas.height
            - this._videoElement.height, this._videoElement.width, this._videoElement.height);

        // draw a border around the video element.
        this._ctx.beginPath();
        this._ctx.lineWidth = 2;
        this._ctx.strokeStyle = "#A9A9A9"; // dark grey
        this._ctx.rect(this._canvas.width - this._videoElement.width, this._canvas.height - this._videoElement.height,
            this._videoElement.width, this._videoElement.height);
        this._ctx.stroke();
        setTimeout(() => {
            if (document.getElementById("presenterVideo") && !(<HTMLVideoElement> document.getElementById("presenterVideo")).srcObject) {
                (<HTMLVideoElement> document.getElementById("presenterVideo")).srcObject = this._videoElement.srcObject;
            }
        }, 200);

    }

    /**
     * Checks if the local track supports this effect.
     *
     * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
     * @returns {boolean} - Returns true if this effect can run on the
     * specified track, false otherwise.
     */
    isEnabled(jitsiLocalTrack: any) {
        return jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === "desktop";
    }

    /**
     * Starts loop to capture video frame and render presenter effect.
     *
     * @param {MediaStream} desktopStream - Stream to be used for processing.
     * @returns {MediaStream} - The stream with the applied effect.
     */
    startEffect(desktopStream: MediaStream) {

        const firstVideoTrack = this._desktopStream.getVideoTracks()[0];
        let { height, width } = firstVideoTrack.getSettings() ? firstVideoTrack.getSettings() : firstVideoTrack.getConstraints();
        // set the desktop element properties.
        this._desktopStream = desktopStream;
        // revert here as well - reason see above.
        // in addition - we disabled presenter fro FF as long as FF does not use VP9 encoding
        if (/firefox/.test(navigator.userAgent.toLowerCase())) {
             const newWidth = this.calculateVideoSize(width, height).width;
             const newHeight = this.calculateVideoSize(width, height).height;
             width = newWidth;
             height = newHeight;
             this._desktopStream.getVideoTracks()[0].applyConstraints({width, height}).then(() => {
             });
             this._desktopElement.height = parseInt(height.toString(), 10);
         } else {
             this._desktopElement.width = parseInt(width.toString(), 10);
             this._desktopElement.height = parseInt(height.toString(), 10);
         }

        this._desktopElement.autoplay = true;
        this._desktopElement.srcObject = desktopStream;

        // autoplay is not enough to start the video on Safari, it's fine to call play() on other platforms as well
        this._desktopElement.play();

        this._canvas.width = parseInt(width.toString(), 10);
        this._canvas.height = parseInt(height.toString(), 10);
        this._videoFrameTimerWorker = new Worker(timerWorkerScript, { name: "Presenter effect worker" });
        this._videoFrameTimerWorker.onmessage = this._onVideoFrameTimer;
        this._videoFrameTimerWorker.postMessage({
            id: SET_INTERVAL,
            timeMs: 1000 / this._frameRate
        });

        return this._canvas.captureStream(this._frameRate);
    }

    calculateVideoSize(width, height) {
        if (this._desktopElement) {
            width = this._desktopElement.clientWidth;
            height = this._desktopElement.clientHeight;
            const ratio = width / height;
            if (!!document.querySelector(".presenter-screen")) {
                const presenterHeight = document.querySelector(".presenter-screen").clientHeight;
                if (height < presenterHeight) {
                    height = presenterHeight;
                    width = height * ratio;
                }
            }
        }
        return {width, height};
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    stopEffect() {
        if (this._videoFrameTimerWorker) {
            this._videoFrameTimerWorker.postMessage({
                id: CLEAR_INTERVAL
            });
            this._videoFrameTimerWorker.terminate();
            if (document.getElementById("presenterVideo")) {
                (<HTMLVideoElement> document.getElementById("presenterVideo")).srcObject = null;
            }
        }

        this._videoElement.srcObject = null;

    }

}
