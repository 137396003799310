import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {FilterType} from "../../smart-link.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MobileSmartLinkFilterService} from "./mobile-smart-link-filter.service";
import {ToastService} from "../../../shared/services/toast.service";
import {MobileScheduleDialogComponent} from "../../mobile-schedule-dialog/mobile-schedule-dialog.component";
import {take, takeUntil} from "rxjs/operators";
import {UntypedFormControl} from "@angular/forms";
import {CloseSmartObjectDialog} from "../../../root.component";
import {Broadcaster} from "../../../talk/shared/providers";
import {Subject} from "rxjs";
import { environment } from "app/environments/environment";

@Component({
  selector: "vp-smart-link-filter",
  templateUrl: "./smart-link-filter.component.html",
  styleUrls: ["./smart-link-filter.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SmartLinkFilterComponent implements OnInit, OnDestroy {

  isAlive$ = new Subject();

  constructor(private translate: TranslateService,
              private matDialog: MatDialog,
              private bottomSheet: MatBottomSheet,
              private dialogRef: MatDialogRef<SmartLinkFilterComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public mobileFilterService: MobileSmartLinkFilterService,
              private toastService: ToastService,
              private broadcaster: Broadcaster) {
    this.broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_SMART_LINK_FILTER_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  headerLabel = "Filter";
  filterType = FilterType;
  categoryFilters = CategoryFilters;
  filterCategories = {
    CHATS: false,
    CHANNELS: true,
    BROADCASTS: false,
    TOPIC: false,
    FILES: false,
    COMMENTS: false
  };
  isSchedule: boolean = false;
  public scheduleDateControl = new UntypedFormControl(new Date());
  public expireDateControl = new UntypedFormControl();
  minDate: Date = new Date();

  ngOnInit(): void {
    if (this.data) {
      this.filterCategories = this.data;
    }
    this.translate.get("FILTER").pipe(take(1)).subscribe(text => {
      this.headerLabel = "";
      this.headerLabel = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
    });
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  changeFilterCategory(currentCategory: string) {
    Object.keys(this.filterCategories).forEach((key) => {
      this.filterCategories[key] = false;
    });
    this.filterCategories[currentCategory] = true;
  }

  applyFilters() {
    this.mobileFilterService.setFilters();
    this.dialogRef.close();
  }

  closeFilter(){
    this.dialogRef.close();
  }

  underDevelopment() {
    this.toastService.show("UNDER_DEVELOPMENT");
  }

  openScheduleDialog(): void {
    const options = {
      maxWidth: "100vw",
      maxHeight: "100vh",
      width: "100vw",
      height: "100vh",
    };
    const dialog = this.matDialog.open(
      MobileScheduleDialogComponent,
      Object.assign(
        {
          backdropClass: "vnctalk-form-backdrop",
          panelClass: "vnctalk-form-panel",
          disableClose: true,
          data: {
            datePickerMode: true,
            isScheduled: true,
            publishDate: this.scheduleDateControl,
            expiryDate: this.expireDateControl
          },
          autoFocus: false,
        }, options));
    dialog.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.isSchedule = res.isSchedule;
        this.scheduleDateControl = res.scheduleDateControl;
        this.expireDateControl = res.expireDateControl;
        this.setDateFilterForMobile();
      }
    });
  }

  setDateFilterForMobile() {
    this.mobileFilterService.globalDateFilterApplied = true;
    this.mobileFilterService.globalDateFilter = {
      startDate: new Date(this.scheduleDateControl.value),
      endDate: new Date(this.expireDateControl.value)
    };
    const dateRange = this.mobileFilterService.globalDateFilter.startDate.format("dd/MM/yyyy") + " - " + this.mobileFilterService.globalDateFilter.endDate.format("dd/MM/yyyy");
    this.mobileFilterService.dateRangeToDisplay = dateRange;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

}


export enum CategoryFilters {
  CHATS = "CHATS",
  CHANNELS = "CHANNELS",
  TOPIC = "TOPIC",
  BROADCASTS = "BROADCASTS",
  FILES = "FILES",
  COMMENTS = "COMMENTS"
}
