/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "app/config.service";
import { getIsAppOnline, getUserJID } from "app/reducers";
import { TalkRootState } from "app/talk/reducers";
import { ConferenceRepository } from "app/talk/repositories/conference.repository";
import { ContactRepository } from "app/talk/repositories/contact.repository";
import { filter, Subject, take, takeUntil } from "rxjs";
import { JitsiService } from "../../../talk/services/jitsi.service";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-change-user-info",
  templateUrl: "./change-user-info.component.html",
  styleUrls: ["./change-user-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ChangeUserInfoComponent implements OnInit, OnDestroy {
  companyControl: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  nameControl: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  positionControl: UntypedFormControl = new UntypedFormControl("", []);
  currentUser: any;
  private isAlive$ = new Subject<boolean>();
  loggedInUserJID: any;
  contact: any;
  isAppOnline: boolean;
  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private contactRepo: ContactRepository,
    private store: Store<TalkRootState>,
    public configService: ConfigService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private conferenceRepo: ConferenceRepository,
    private jitsiService: JitsiService,
    private matDialogRef: MatDialogRef<ChangeUserInfoComponent>,
    private vncLibaryService: VncLibraryService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data) {
      this.changeDetectionRef.markForCheck();
    }

  }

  ngOnInit(): void {
    this.conferenceRepo.toggleHideVideoIOS(true);
    if (!this.configService.isAnonymous) {
      this.contactRepo.getUserProfile().pipe(take(1)).subscribe(profile => {
        this.currentUser = profile.user;
        this.changeDetectionRef.markForCheck();
      });
      this.store.select(getUserJID).pipe(take(1)).subscribe(jid => {
        this.loggedInUserJID = jid;
        if (this.loggedInUserJID) {
          this.contactRepo.getContactVCard(this.loggedInUserJID.bare).pipe(filter(res => !!res)).pipe(take(1)).subscribe(data => {
            if (data) {
              this.contact = data || { nicknames: [this.getFullName()] };
            } else {
              this.contact = {
                fullName: this.getFullName(),
                name: {
                  family: this.currentUser.firstName,
                  given: this.currentUser.lastName
                },
                nicknames: [this.getFullName()]
              };
            }
            if (this.contact.nicknames) {
              this.nameControl.setValue(this.contact.nicknames[0]);
            } else {
              this.nameControl.setValue(this.getFullName());
            }
            this.companyControl.setValue(data?.organization?.name);
            this.positionControl.setValue(data?.role);
          });
        }
      });
    } else {
      this.nameControl.setValue(this.conferenceRepo?.localUserInfo.name);
      this.companyControl.setValue(this.conferenceRepo?.localUserInfo.company);
      this.positionControl.setValue(this.conferenceRepo?.localUserInfo.position);
    }

    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
  }

  update() {
    if (!this.isAppOnline) {
      this.translate.get("OFFLINE_MODE").pipe(take(1)).subscribe(v => {
        this.snackBar.open(v, null, {duration: 5000});
      });
      return;
    }
    if (!this.configService.isAnonymous) {
      const newData = {
        ...this.contact,
        role: this.positionControl.value,
        organization: {
          name: this.companyControl.value
        },
        nicknames: [this.nameControl.value]
      };
      this.contactRepo.publishVCards(newData).pipe(take(1)).subscribe(() => {
        this.contactRepo.publishNick(this.nameControl.value).pipe(take(1)).subscribe(() => {
        });
        this.translate.get("USER_INFO_UPDATED").pipe(take(1)).subscribe(v => {
          this.vncLibaryService.openSnackBar(v, "checkmark",
            "", "", 5000, "bottom", "left").subscribe(res => {
            });
        });
        this.contactRepo.updateContactFromVCard(this.loggedInUserJID);
      });
    }

    this.conferenceRepo.localUserInfo = {position: this.positionControl.value, company: this.companyControl.value, name: this.nameControl.value};
    this.matDialogRef.close({position: this.positionControl.value, company: this.companyControl.value, name: this.nameControl.value});
    this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(v => {
      if (!!v) {
        this.jitsiService.sendUserInfo({position: this.positionControl.value, company: this.companyControl.value,
          name: this.nameControl.value, participantId: this.jitsiService.myUserId()});
      }
    });
  }

  @HostListener("document:keydown.esc")
  close(): void {
    this.matDialogRef.close();
  }

  getFullName() {
    return this.currentUser ? this.currentUser.fullName : "";
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.conferenceRepo.toggleHideVideoIOS(false);
  }
}

