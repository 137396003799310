
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { ProfileResponse } from "../responses/profile.response";
import { UserStatus } from "../shared/models";
import { ConversationState } from "../talk/reducers/conversation";
import { ConversationMessageState } from "../talk/reducers/conversation-message";
import { MessageState } from "../talk/reducers/message";
import { ContactState } from "../reducers/contact";
import { AppState } from "../reducers/app";
import { AppSettings } from "../talk/models/app-settings.model";
import { TOPIC_VIEW, UserConfig } from "app/shared/models/user-config.model";
import { InvitationConfig } from "app/shared/models/invitation-config";

export class AppActionTypes {
  static SET_LANDING_PAGE_URL = "[App] set landing page url";
  static SET_AVAILABLE_APPS = "[App] set available apps";
  static SET_ACTIVE_TAB = "[App] Set Active Tab";
  static SET_BASE_API_URL = "[App] set base api url";
  static SET_FIREBASE_TOKEN = "[App] set firebase token";
  static ENABLE_GLOBAL_MUTE = "[App] enable global mute";
  static DISABLE_GLOBAL_MUTE = "[App] disable global mute";
  static SET_LOCAL_SEARCH_LAYOUT = "[App] Set Local Search Layout";



  static APP_BOOTSTRAPPED = "[App] Bootstrap success";

  static LOGIN_REQUEST = "[App] Login Request";
  static LOGIN_SUCCESS = "[App] Login Success";
  static LOGOUT_SUCCESS = "[App] Logout Success";

  static XMPP_SESSION = "[App] Xmpp Session";
  static XMPP_OMEMO_INIT = "[App] Xmpp Omemo Init";
  static XMPP_OMEMO_MAX_DEVICES_REACHED = "[App] Xmpp Omemo Max Devices Reached";
  static SET_BARE = "[App] Set bare";

  static STATUS_CHANGE = "[APP] Status Change";
  static NOTIFY_OPTION_CHANGE = "[APP] Notify Option Change";

  static FETCH_ME_SUCCESS = "[App] Fetch Me Success";
  static LOGOUT = "[App] logout";


  static XMPP_CONNECTED = "[App] XMPP Connected";
  static APP_ONLINE = "[App] App Online";
  static RESTORE_SAVED_STATE = "[App] Restore Saved State";
  static WINDOW_FOCUS_STATE = "[App] Window Focus State";
  static SET_APP_SETTINGS = "[App] App Settings";
  static SET_FONT_SIZE = "[App] Font Size";
  static UPDATE_CONNECTION_INFORMATION = "[App] Update Network Information";


  static RESTORE_PROFILE_JID = "[App] Restore Profile and Jid";
  static DATABASE_READY = "[App] Database Ready";
  static SET_DOMAIN = "[App] Set Domain";
  static UPDATE_LOGOUT_OPTION = "[App] Update logout option";
  static UPDATE_USER_CONFIG = "[App] Update user config";
  static UPDATE_TOPIC_VIEW = "[App] Update topic view";
  static UPDATE_TRASH_CONFIG = "[App] Update trash config";
  static SET_INVITATION_CONFIG = "[App] Invitation config";
  static SET_MCB_LIST = "[App] Set MCBs List";
  static CHANGE_LAYOUT = "[App] Change Layout";
  static UPDATE_SELECTED_LAYOUT = "[App] Update Layout";
  static SELECT_PROFILE = "[App] Select Profile";
  static SET_ACTIVE_FILTER = "[App] Set Active Filter";
  static SET_CHAT_BACKGROUND_IMAGES = "[App] Chat Background Images";
  static SET_SELECTED_CHAT_BACKGROUND_PREVIEW = "[App] Selected Chat Background Preview";
  static DELETE_CHAT_BACKGROUND_IMAGE = "[App] Delete Chat Background Image";
  static SET_CHAT_BACKGROUND_COLOR_PREVIEW = "[App] Chat Background Color Preview";

}

export class SetLandingPageUrl implements Action {
  readonly type = AppActionTypes.SET_LANDING_PAGE_URL;

  constructor(public payload: string) {
  }
}

export class SetActiveTab implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_TAB;

  constructor(public payload: string) {
  }
}

export class SetActiveFilter implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_FILTER;

  constructor(public payload: string) {
  }
}

export class SelectProfile implements Action {
  readonly type = AppActionTypes.SELECT_PROFILE;

  constructor(public payload: string) {
  }
}

export class SetDomain implements Action {
  readonly type = AppActionTypes.SET_DOMAIN;

  constructor(public payload: string) {
  }
}

export class UpdateLogoutOption implements Action {
  readonly type = AppActionTypes.UPDATE_LOGOUT_OPTION;

  constructor(public payload: boolean) {
  }
}

export class SetBaseApiUrl implements Action {
  readonly type = AppActionTypes.SET_BASE_API_URL;

  constructor(public payload: string) {
  }
}

export class SetAvailableApps implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_APPS;

  constructor(public payload: any[]) {
  }
}

export class SetFirebaseToken implements Action {
  readonly type = AppActionTypes.SET_FIREBASE_TOKEN;

  constructor(public payload: string) {
  }
}

export class AppBootstrapped implements Action {
  readonly type = AppActionTypes.APP_BOOTSTRAPPED;
}


export class LoginRequest implements Action {
  readonly type = AppActionTypes.LOGIN_REQUEST;
}


export class LoginSuccess implements Action {
  readonly type = AppActionTypes.LOGIN_SUCCESS;

  constructor() {
  }
}

export class LogoutSuccess implements Action {
  readonly type = AppActionTypes.LOGOUT_SUCCESS;

  constructor() {
  }
}


export class XmppSession implements Action {
  readonly type = AppActionTypes.XMPP_SESSION;

  constructor(public payload: any) {
  }
}

export class XmppOMEMOInit implements Action {
  readonly type = AppActionTypes.XMPP_OMEMO_INIT;

  constructor() {

  }
}

export class XmppOMEMOMaxDevicesReached implements Action {
  readonly type = AppActionTypes.XMPP_OMEMO_MAX_DEVICES_REACHED;

  constructor() {
  }
}

export class SetXmppConnection implements Action {
  readonly type = AppActionTypes.XMPP_CONNECTED;

  constructor(public payload: boolean) {
  }
}

export class UpdateNetworkInformation implements Action {
  readonly type = AppActionTypes.UPDATE_CONNECTION_INFORMATION;

  constructor(public payload: any) {
  }
}


export class StatusChange implements Action {
  readonly type = AppActionTypes.STATUS_CHANGE;

  constructor(public payload: UserStatus) {
  }
}

export class NotifyOptionChange implements Action {
  readonly type = AppActionTypes.NOTIFY_OPTION_CHANGE;

  constructor(public payload: string) {
  }
}

export class FetchMeSuccess implements Action {
  readonly type = AppActionTypes.FETCH_ME_SUCCESS;

  constructor(public payload: ProfileResponse) {
  }
}

export class Logout implements Action {
  readonly type = AppActionTypes.LOGOUT;
}

export class OnlineStatus implements Action {
  readonly type = AppActionTypes.APP_ONLINE;

  constructor(public payload: boolean) {
  }
}

export class RestoreSavedState implements Action {
  readonly type = AppActionTypes.RESTORE_SAVED_STATE;

  constructor(public payload: {
    conversationState: ConversationState,
    conversationMessageState: ConversationMessageState,
    messageState: MessageState,
    contactState: ContactState,
    appState: AppState
  }) {
  }
}
export class WindowFocusStatus implements Action {
  readonly type = AppActionTypes.WINDOW_FOCUS_STATE;
  constructor(public payload: boolean) {}
}

export class SetAppSettings implements Action {
  readonly type = AppActionTypes.SET_APP_SETTINGS;
  constructor(public payload: AppSettings) {}
}

export class SetFontSize implements Action {
  readonly type = AppActionTypes.SET_FONT_SIZE;
  constructor(public payload: number) {}
}

export class RestoreProfileJid implements Action {
  readonly type = AppActionTypes.RESTORE_PROFILE_JID;
  constructor(public payload) {}
}

export class SetBare implements Action {
  readonly type = AppActionTypes.SET_BARE;
  constructor(public payload: string) {}
}

export class DatabaseReady implements Action {
  readonly type = AppActionTypes.DATABASE_READY;
  constructor() {}
}

export class EnableGlobalMute implements Action {
  readonly type = AppActionTypes.ENABLE_GLOBAL_MUTE;
  constructor() {}
}

export class DisableGlobalMute implements Action {
  readonly type = AppActionTypes.DISABLE_GLOBAL_MUTE;
  constructor() {}
}

export class SetUserConfig implements Action {
  readonly type = AppActionTypes.UPDATE_USER_CONFIG;
  constructor(public payload: UserConfig) {}
}

export class SetInvitationConfig implements Action {
  readonly type = AppActionTypes.SET_INVITATION_CONFIG;
  constructor(public payload: InvitationConfig) {}
}


export class SetMCBsList implements Action {
  readonly type = AppActionTypes.SET_MCB_LIST;
  constructor(public payload: any) {}
}

export class ChangeLayout implements Action {
  readonly type = AppActionTypes.CHANGE_LAYOUT;
  constructor(public payload: string) {}
}

export class UpdateSelectedLayout implements Action {
  readonly type = AppActionTypes.UPDATE_SELECTED_LAYOUT;
  constructor(public payload: string) {}
}

export class UpdateTopicView implements Action {
  readonly type = AppActionTypes.UPDATE_TOPIC_VIEW;
  constructor(public payload: TOPIC_VIEW) {}
}

export class UpdateTrashConfig implements Action {
  readonly type = AppActionTypes.UPDATE_TRASH_CONFIG;
  constructor(public payload: {auto_clean_trash: boolean, auto_clean_trash_period: number}) {}
}

export class SetLocalSearchLayout implements Action {
  readonly type = AppActionTypes.SET_LOCAL_SEARCH_LAYOUT;

  constructor(public payload: string) {
  }
}

export class SetChatBackgroundImages implements Action {
  readonly type = AppActionTypes.SET_CHAT_BACKGROUND_IMAGES;
  constructor(public payload: any) {}
}

export class SetSelectedChatBackgroundPreview implements Action {
  readonly type = AppActionTypes.SET_SELECTED_CHAT_BACKGROUND_PREVIEW;
  constructor(public payload: string) {}
}

export class DeleteChatBackgroundImage implements Action {
  readonly type = AppActionTypes.DELETE_CHAT_BACKGROUND_IMAGE;
  constructor(public payload: string) {}
}

export class SetChatBackgroundColorPreview implements Action {
  readonly type = AppActionTypes.SET_CHAT_BACKGROUND_COLOR_PREVIEW;
  constructor(public payload: string) {}
}
