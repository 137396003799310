<div *ngIf="data?.event === filterType.CHANNEL_TYPE" class="type-menu-bottom-sheet">
  <div class="type-label">Channel Type</div>
  <div *ngFor="let option of channelTypeOptions" class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      (onClickEvent)="selectItem({event: filterType.CHANNEL_TYPE, selected: option.value})"
                      label="{{option.label | translate }}">
    </vp-dropdown-item>
    <ng-container *ngIf="selectedValue === option.value" [ngTemplateOutlet]="checkIcon"></ng-container>
  </div>
  <div class="hr-div">
    <hr />
  </div>
  <div class="save-button" (click)="operation()">
    {{'SAVE' | translate }}
  </div>
</div>

<div *ngIf="data?.event === filterType.TOPIC_TYPE" class="type-menu-bottom-sheet">
  <div class="type-label">Topic Type</div>
  <div *ngFor="let option of topicTypeOptions" class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      (onClickEvent)="selectItem({event: filterType.TOPIC_TYPE, selected: option.value})"
                      label="{{option.label | translate }}">
    </vp-dropdown-item>
    <ng-container *ngIf="selectedValue === option.value" [ngTemplateOutlet]="checkIcon"></ng-container>
  </div>
  <div class="hr-div">
    <hr />
  </div>
  <div class="save-button" (click)="operation()">
    {{'SAVE' | translate }}
  </div>
</div>

<ng-template #checkIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <defs>
      <filter id="a95ig6mtaa" width="138.3%" height="147.3%" x="-19.2%" y="-23.6%" filterUnits="objectBoundingBox">
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"/>
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g fill="#337CBD">
        <g transform="translate(-323.000000, -513.000000) translate(0.000000, 448.000000)">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path d="M4.192 7.935L1.065 4.808 0 5.865 4.192 10.057 13.193 1.058 12.135 0z" transform="translate(0.000000, 48.000000) translate(0.000000, -0.000000) translate(320.000000, 14.000000) translate(3.000000, 3.000000) translate(2.557500, 4.192500)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
