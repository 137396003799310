
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, ElementRef, Output, EventEmitter, ViewChild, Input,
  TemplateRef, OnInit, HostBinding
} from "@angular/core";

import { isInputOrTextAreaElement, getContentEditableCaretCoords } from "./mention-utils";
import { getCaretCoordinates } from "./caret-coords";

@Component({
  selector: "vp-mention-list",
  template: `
    <ng-template #defaultItemTemplate let-item="item">
      <div class="mention-full-name">{{ item.label }}</div>
      <span class="mention-bare">{{ item.value }}</span>
    </ng-template>
    <ul #list [hidden]="hidden" class="dropdown-menu vnc-scrollbar-verical-show-on-hover scrollable-menu">
        <li *ngFor="let item of items; let i = index" [class.active]="activeIndex==i">
            <a class="dropdown-item" (click)="activeIndex=i;itemClick.emit();$event.preventDefault();$event.stopPropagation();" (mousedown)="activeIndex=i;itemClick.emit();$event.preventDefault();$event.stopPropagation();">
              <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{'item':item}"></ng-template>
            </a>
        </li>
    </ul>
    `
})
export class MentionListComponent implements OnInit {
  @Input() labelKey: string = "label";
  @Input() itemTemplate: TemplateRef<any>;
  @Output() itemClick = new EventEmitter();
  @ViewChild("list", {static: true}) list: ElementRef;
  @ViewChild("defaultItemTemplate", {static: true}) defaultItemTemplate: TemplateRef<any>;
  items = [];
  activeIndex: number = 0;
  hidden: boolean = false;
  @HostBinding("class") className = "";
  constructor(private element: ElementRef) { }

  ngOnInit() {
    if (!this.itemTemplate) {
      this.itemTemplate = this.defaultItemTemplate;
    }
  }

  destroy(): void {
    this.element.nativeElement.remove();
  }

  // lots of confusion here between relative coordinates and containers
  position(nativeParentElement: HTMLInputElement, iframe: HTMLIFrameElement = null, dropUp: boolean) {
    let coords = { top: 0, left: 0 };
    if (isInputOrTextAreaElement(nativeParentElement)) {
      // parent elements need to have postition:relative for this to work correctly?
      coords = getCaretCoordinates(nativeParentElement, nativeParentElement.selectionStart);
      coords.top = nativeParentElement.offsetTop + coords.top + 16;
      coords.left = nativeParentElement.offsetLeft + coords.left;
    }
    else if (iframe) {
      let context: { iframe: HTMLIFrameElement, parent: Element } = { iframe: iframe, parent: iframe.offsetParent };
      coords = getContentEditableCaretCoords(context);
    }
    else {
      let doc = document.documentElement;
      let scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      let scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      // bounding rectangles are relative to view, offsets are relative to container?
      let caretRelativeToView = getContentEditableCaretCoords({ iframe: iframe });
      let parentRelativeToContainer: ClientRect = nativeParentElement.getBoundingClientRect();

      coords.top = caretRelativeToView.top - parentRelativeToContainer.top + nativeParentElement.offsetTop - scrollTop;
      coords.left = caretRelativeToView.left - parentRelativeToContainer.left + nativeParentElement.offsetLeft - scrollLeft;
      doc = null;
    }
    let el: HTMLElement = this.element.nativeElement;
    if (this.list) {
      this.list.nativeElement.style.marginBottom = dropUp ? "24px" : null;
    }
    el.className = dropUp ? "dropup" : "mentions-list-wrapper";
    el.style.position = "absolute";

    if (isInputOrTextAreaElement(nativeParentElement)) {
      el.style.left = coords.left + "px";
      el.style.top = "-150px";
      this.MentionsListWrapperForInput(nativeParentElement , coords);
    };
    if (nativeParentElement.classList.contains("ql-editor")) {
      el.style.top = "-150px";
      this.MentionsListWrapperRTF(nativeParentElement , coords);
    };
    if (!nativeParentElement.classList.contains("ql-editor") && !isInputOrTextAreaElement(nativeParentElement)){
      el.style.left = coords.left + "px";
      el.style.top = "-150px";
    };
    if (window.outerWidth < 767) {
      el.style.left = "0px";
      el.style.top = "-175px";
    }
    if (window.outerWidth > 767 && window.outerWidth < 1336 && coords.left > 200) {
      el.style.left = "auto";
      el.style.right = "0";
      el.style.top = "-175px";
    }
  }

  MentionsListWrapperForInput(input , el) {
    const { spaceAbove, spaceBelow } = this.getSpaceAboveInput(input, el);
    const inputWith = input.offsetWidth;
    const mentionsListWrapper = document.querySelector(".mentions-list-wrapper");
    const popup = mentionsListWrapper.querySelector(".dropdown-menu") as HTMLElement;
    const popupHeight = popup?.offsetHeight;
    if (popupHeight == 0) popup.style.visibility = "hidden";
    else popup.style.visibility = "";
    if ((inputWith - el.left) < 300) {
      mentionsListWrapper["style"].left = (inputWith - 310) + "px";
    };
    const isFullScreen = this.isFullScreenEditor();
    if (isFullScreen) {
      mentionsListWrapper["style"].height = `${popupHeight}px`;
      if (spaceAbove < 462) mentionsListWrapper["style"].top = `${spaceAbove - 100}px`;
      if (spaceAbove > 462 && spaceAbove < 900) mentionsListWrapper["style"].top = `${(spaceAbove - 100) - popupHeight}px`;
      if (spaceAbove > 900) mentionsListWrapper["style"].top = `${(spaceAbove - 200) - popupHeight}px`;
    }
    else {
      if (el.top === 48) mentionsListWrapper["style"].top = "-135px";
      if (el.top === 72) mentionsListWrapper["style"].top = "-110px";
      if (el.top === 95) mentionsListWrapper["style"].top = "-90px";
      if (el.top === 118) mentionsListWrapper["style"].top = "-65px";
      if (el.top === 142) mentionsListWrapper["style"].top = "-40px";
      if (el.top === 165) mentionsListWrapper["style"].top = "-15px";
      if (el.top > 165) mentionsListWrapper["style"].top = "-40px";
    }
  }

  MentionsListWrapperRTF(input , el) {
    const inputWith = input.offsetWidth;
    const mentionsListWrapper = document.querySelector(".mentions-list-wrapper");
    const popup = mentionsListWrapper.querySelector(".dropdown-menu") as HTMLElement;
    const isFullScreen = this.isFullScreenEditor();
    const popupHeight = popup.offsetHeight;
    if (popupHeight == 0) popup.style.visibility = "hidden";
    else popup.style.visibility = "";

    if ((inputWith - el.left) < 300) {
      mentionsListWrapper["style"].left = (inputWith - 310) + "px";
    }
    if (el.top >= 32 && el.top <= 93) {
      const baseTop = -100;
      const multiplier = 20;
      if ( popupHeight > 150 && isFullScreen) mentionsListWrapper["style"].top = `${(baseTop + ((el.top - 32) / 20) * multiplier) + (popupHeight + 20)}px`;
      else mentionsListWrapper["style"].top = `${baseTop + ((el.top - 32) / 20) * multiplier}px`;
    } else if (el.top >= 112) {
      const difference = el.top - 112;
      if ( popupHeight > 150 && el.top < 333 && isFullScreen) mentionsListWrapper["style"].top = `${(-40 + difference) + (popupHeight + 20)}px`;
      else mentionsListWrapper["style"].top = `${-40 + difference}px`;
    }
  }

  isFullScreenEditor() {
    const chatWindow = document.querySelector(".chat-window");
    if (chatWindow) {
      const fullScreen = chatWindow.querySelector(".message-wrapper");
      if (fullScreen) {
        const isFullscreen = fullScreen.classList.contains("show-full-editor");
        return isFullscreen;
      }
    }
  }

  getSpaceAboveInput(textarea,cord) {
    const lineHeight = 24;
    const textareaRect = textarea.getBoundingClientRect();
    const caretIndex = textarea.selectionStart;
    const lines = textarea.value.substring(0, caretIndex).split("\n");
    const estimatedTopWithinTextarea = lines.length * lineHeight - textarea.scrollTop;
    const estimatedAbsoluteTop = textareaRect.top + window.scrollY + estimatedTopWithinTextarea;
    const spaceAbove = estimatedAbsoluteTop - window.scrollY;
    const spaceBelow = window.innerHeight - (estimatedAbsoluteTop - window.scrollY);
    return { spaceAbove, spaceBelow };
  }

  get activeItem() {
    return this.items[this.activeIndex];
  }

  activateNextItem() {
    // adjust scrollable-menu offset if the next item is out of view
    if (this.list) {
      let listEl: HTMLElement = this.list.nativeElement;
      let activeEl = listEl.getElementsByClassName("active").item(0);
      if (activeEl) {
        let nextLiEl: HTMLElement = <HTMLElement>activeEl.nextSibling;
        if (nextLiEl && nextLiEl.nodeName === "LI") {
          let nextLiRect: ClientRect = nextLiEl.getBoundingClientRect();
          if (nextLiRect.bottom > listEl.getBoundingClientRect().bottom) {
            listEl.scrollTop = nextLiEl.offsetTop + nextLiRect.height - listEl.clientHeight;
          }
        }
      }
      // select the next item
      this.activeIndex = Math.max(Math.min(this.activeIndex + 1, this.items.length - 1), 0);
    }
  }

  activatePreviousItem() {
    // adjust the scrollable-menu offset if the previous item is out of view
    if (this.list) {
      let listEl: HTMLElement = this.list.nativeElement;
      let activeEl = listEl.getElementsByClassName("active").item(0);
      if (activeEl) {
        let prevLiEl: HTMLElement = <HTMLElement>activeEl.previousSibling;
        if (prevLiEl && prevLiEl.nodeName === "LI") {
          let prevLiRect: ClientRect = prevLiEl.getBoundingClientRect();
          if (prevLiRect.top < listEl.getBoundingClientRect().top) {
            listEl.scrollTop = prevLiEl.offsetTop;
          }
        }
      }
      // select the previous item
      this.activeIndex = Math.max(Math.min(this.activeIndex - 1, this.items.length - 1), 0);
    }
  }

  resetScroll() {
    if (this.list) {
      this.list.nativeElement.scrollTop = 0;
    }
  }
}
