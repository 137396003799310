<div class="file-menu-bottom-sheet">
        <div class="item-drop-down">
            <vp-dropdown-item (onClickEvent)="operation('whatsapp')"
                [itemType]="'icon-drop-default'" label="{{'QUICK_REPLY_CANT_TALK_WHATS_UP' | translate }}">
            </vp-dropdown-item>
        </div>
        <div class="item-drop-down">
            <vp-dropdown-item (onClickEvent)="operation('call-you-right-back')"
                [itemType]="'icon-drop-default'" label="{{'QUICK_REPLY_CALL_YOU_RIGHT_BACK' | translate }}">
            </vp-dropdown-item>
        </div>
        <div class="item-drop-down">
            <vp-dropdown-item (onClickEvent)="operation('call-you-later')"
                [itemType]="'icon-drop-default'" label="{{'QUICK_REPLY_CALL_YOU_LATER' | translate }}">
            </vp-dropdown-item>
        </div>
        <div class="item-drop-down">
            <vp-dropdown-item (onClickEvent)="operation('call-me-later')"
                [itemType]="'icon-drop-default'" label="{{'QUICK_REPLY_CANT_TALK_CALL_ME_LATER' | translate }}">
            </vp-dropdown-item>
        </div>

        <div class="item-drop-down custom no-shadow">
            <vp-dropdown-item (onClickEvent)="operation('cancel')"
            [prefixIconTemplate]="closeIcon" [itemType]="'icon-drop-default'" label="{{'CANCEL' | translate }}">
            </vp-dropdown-item>
        </div>

</div>

<ng-template #addIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(5.000000, 5.000000)" fill="#8B96A0">
                <polygon id="Shape" points="14 8 8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6"></polygon>
            </g>
        </g>
    </svg>
</ng-template>


<ng-template #closeIcon>
    <svg class="default-color" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Icon/product/close-new</title>
        <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z"
                id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>


<ng-template #iconEmoji>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/insert-emoticon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
          <path d="M9.99,0 C4.47,0 0,4.48 0,10 C0,15.52 4.47,20 9.99,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 9.99,0 L9.99,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 L10,18 Z M13.5,9 C14.33,9 15,8.33 15,7.5 C15,6.67 14.33,6 13.5,6 C12.67,6 12,6.67 12,7.5 C12,8.33 12.67,9 13.5,9 L13.5,9 Z M6.5,9 C7.33,9 8,8.33 8,7.5 C8,6.67 7.33,6 6.5,6 C5.67,6 5,6.67 5,7.5 C5,8.33 5.67,9 6.5,9 L6.5,9 Z M10,15.5 C12.33,15.5 14.31,14.04 15.11,12 L4.89,12 C5.69,14.04 7.67,15.5 10,15.5 L10,15.5 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  </ng-template>