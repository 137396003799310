import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "app/talk/shared/providers";
import { BroadcastKeys } from "app/talk/utils/constants.util";
import { AddRemarkPopupComponent } from "./add-remark-popup/add-remark-popup.component";
import { PopupPositionService } from "./popup-position.service";
import {Utils} from "../common";
import {SmartObject, SmartObjectsService} from "./smart-objects/smart-objects.service";
import format from "date-fns/format";

@Injectable()
export class RemarkService {
  constructor(
    private _dialog: MatDialog,
    private _popup: PopupPositionService,
    private _broadcaster: Broadcaster,
    private _smartObjectsService: SmartObjectsService
  ) {}

  openRemarkPopup(popupConfig, isMobileView = false) {
    const data = {
      preContent: popupConfig.preContent,
      selectedText: popupConfig.selectedText,
      postContent: popupConfig.postContent,
    };
    return this.getRemarkPopupRef(data, popupConfig.pageX, popupConfig.pageY, isMobileView);
  }

  getRemarkPopupRef(
    data,
    clientX,
    clientY,
    isMobileView = false
  ): MatDialogRef<AddRemarkPopupComponent> {
    let config = {
      width: isMobileView ? "100%" : "400px",
      height: isMobileView ? "100%" : "fit-content",
      backdropClass: "transparent-backdrop",
      panelClass: isMobileView ? "remark-popup-container-mobile" : "remark-popup-container",
      data: { selectedData: data, isMobileView: isMobileView },
      position: isMobileView ? {
        left: "0px",
        top: "0px"
      } : this._popup.getPopupPosition(
        { width: 400, height: 474 },
        clientX,
        clientY
      ),
    };
    return this._dialog.open(AddRemarkPopupComponent, config);
  }

  onRemarkClicked(event) {
    // @ts-ignore
    const type = event.target.getAttribute("data-type");
    if (type === "remark-index") {
      this._broadcaster.broadcast(BroadcastKeys.REMARK_CLICKED, {
        uid: event.target.getAttribute("data-remark-id"),
        clientX: event.clientX,
        clientY: event.clientY,
      });
    }
  }

  constructRemarkData(innerText, uid) {
    const data = {
      type: "remark",
      uid,
    };
    const element = document.createElement("s");
    Object.entries(data).forEach((entry) => {
      element.dataset[entry[0]] = entry[1];
    });

    const index = document.createElement("s"); // for the index number
    index.classList.add("remark-index");
    index.dataset["remarkId"] = uid;
    index.dataset["type"] = "remark-index";
    index.contentEditable = "false";
    element.textContent = innerText;
    element.classList.add("remark-text");

    element.prepend(index);
    return element;
  }

  editRemark(allRemarks: any[], uid: string, newContent: string, addUpdatedFlag = false): any[] {
    return [
      ...allRemarks.map((k) => {
        if (k.uid === uid) {
          return addUpdatedFlag ? { ...k, notes: newContent.trim() || "Empty Remark.", updated: true } : { ...k, notes: newContent.trim() || "Empty Remark." };
        }
        return k;
      }),
    ];
  }

  getRemarkText(allRemarks: any[], uid: string): string {
    return allRemarks.filter((k) => k.uid === uid)?.[0]?.notes;
  }

  getRemarkIndexElement(refId: string) {
    const element = document.createElement("s");
    element.className = "remark-index";
    element.setAttribute("data-type","remark-index");
    element.setAttribute("data-remark-id", refId);
    element.contentEditable = "false";
    return element;
  }

  insertAfter(newNode, existingNode: Element) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

  addFreshIndicesToTopicDetailPage(selector = "topic_detail_body") {
    const getAttribute = (element, attributeName) => element?.getAttribute(attributeName);
    const description = document.getElementById(selector);
    const smartObjects = description?.querySelectorAll("s[data-type=\"SMART_OBJECTS\"]");
    smartObjects?.forEach((obj: Element) => {
      const previewData = JSON.parse(decodeURIComponent(getAttribute(obj, "data-preview-data") || ""));
      if (previewData.hasOwnProperty("referencesData") && previewData?.referencesData.length > 0) {
        previewData?.referencesData.reverse().forEach(refId => {
          const remarkIndexElement = this.getRemarkIndexElement(refId);
          this.insertAfter(remarkIndexElement, obj);
        });
      }
    });
  }

  removeIndicesFromTopicDetailPage(selector = "topic_detail_body") {
    const getAttribute = (element, attributeName) => element?.getAttribute(attributeName);
    const description = document.getElementById(selector);
    const smartObjectsWrap = description?.querySelectorAll("s[data-type=\"SMART_OBJECTS\"]");
    smartObjectsWrap?.forEach((obj: Element) => {
      const previewData = JSON.parse(decodeURIComponent(getAttribute(obj, "data-preview-data") || ""));
      if (previewData.hasOwnProperty("referencesData") && previewData?.referencesData.length > 0) {
        const references = previewData?.referencesData;
        references.forEach(() => {
          obj?.nextElementSibling?.remove();
        });
      }
    });
  }

  removeRedundantIndicesFromTopicEdit(editor: any) {
    if (!!editor) {
      let editorContent = editor?.root?.innerHTML || "";
      const parser = new DOMParser();
      const editorContentHTML = parser.parseFromString(editorContent, "text/html")?.body;
      const remarkIndices = editorContentHTML?.querySelectorAll("s[data-type=\"remark-index\"]");
      remarkIndices.forEach(index => {
        if (!index.parentElement.querySelector("s[data-type=\"SMART_OBJECTS\"]") && index.parentElement.getAttribute("data-type") !== "remark") {
          editorContent = editorContent.replace(index.outerHTML, "");
        }
      });
      return editorContent;
    }
    return editor?.root?.innerHTML;
  }

  removeOldIndices(node: Element) {
    node?.parentElement?.querySelectorAll("s[data-type=\"remark-index\"]")?.forEach(index => index?.remove());
    return true;
  }

  hasOldIndices(node: Element) {
    return node?.parentElement?.tagName === "S" && node?.parentElement?.childNodes?.length > 1 && node?.parentElement?.style?.position === "relative";
  }

  removeOldIndicesFromAllSmartObjects(editor: any) {
    let editorContent = editor?.root?.innerHTML || "";
    const parser = new DOMParser();
    const editorContentHTML = parser.parseFromString(editorContent, "text/html")?.body;
    const smartObjects = editorContentHTML.querySelectorAll("s[data-type=\"SMART_OBJECTS\"]");
    smartObjects.forEach((obj: Element) => {
      if (this.hasOldIndices(obj)) {
        editorContent = editorContent.replace(obj.parentElement.outerHTML, obj.parentElement.children?.[0].outerHTML);
      }
    });
    return editorContent;
  }

  getQuillContentWithIndicesInSmartObjects(editor: any) {
    const getAttribute = (element, attributeName) => element.getAttribute(attributeName);
    if (!!editor) {
      let editorContent = editor?.root?.innerHTML || "";
      const parser = new DOMParser();
      const editorContentHTML = parser.parseFromString(editorContent, "text/html")?.body;
      const smartObjects = editorContentHTML.querySelectorAll("s[data-type=\"SMART_OBJECTS\"]");
      smartObjects.forEach((obj: Element) => {

        if (this.hasOldIndices(obj)) {
          editorContent = editorContent.replace(obj.parentElement.outerHTML, obj.parentElement.children?.[0].outerHTML);
        }
        const previewData = JSON.parse(decodeURIComponent(getAttribute(obj, "data-preview-data")));
        if (previewData?.hasOwnProperty("referencesData") && previewData?.referencesData.length > 0) {
          const smartObjectWrap = this._smartObjectsService.getSmartObjectsWrap();
          smartObjectWrap.append(obj);
          previewData?.referencesData.forEach(refId => {
            const remarkIndexElement = this.getRemarkIndexElement(refId);
            smartObjectWrap.append(remarkIndexElement);
            smartObjectWrap.append(" ");
          });
          editorContent = editorContent.replace(obj.outerHTML, smartObjectWrap.outerHTML);
        }
      });
      return editorContent;
    }
    return editor?.root?.innerHTML;
  }

  updateQuillRemarkIndexes(allRemarks, selector = "editor_body") {
    let rankArray = [];
    let bottomRemarksArray = [];

    setTimeout(() => {
      const editor = document.getElementById(selector);
      const remarkElements = editor?.querySelectorAll("s[data-type=\"remark-index\"]");

      if (remarkElements) {
        remarkElements.forEach((elem) => {
          const id = elem.getAttribute("data-remark-id");
          const rank = allRemarks.findIndex((val) => val.uid === id);
          const note = allRemarks.filter((val) => val.uid === id)[0].notes;

          if (!isNaN(rankArray.findIndex((k) => k.id === id))) {
            // remove duplicates
            rankArray.push({ id, rank, note });
          }
          elem.innerHTML = `[${rankArray.length}]`; // Update to use rankArray length instead of index
        });

        rankArray = rankArray.sort((a, b) => a.rank - b.rank);

        let isPreviousElementStrikethrough = false;

        remarkElements.forEach((elem) => {
          const uid = elem.getAttribute("data-remark-id");
          const ind = rankArray.findIndex((val) => val.id === uid);
          const note = rankArray[ind]?.note;
          bottomRemarksArray[ind] = ({ uid, notes: note, index: ind + 1 });

          if (isPreviousElementStrikethrough) {
            // Remove strikethrough formatting from subsequent text
            const nextNode = elem.nextSibling as HTMLElement;
            if (nextNode) {
              nextNode.style.textDecoration = "none";
            }
          }

          elem.innerHTML = `[${ind + 1}]`;

          // Check if the current element is strikethrough
          isPreviousElementStrikethrough = (elem as HTMLElement).style.textDecoration === "line-through";
        });

        this._broadcaster.broadcast("update_bottom_remarks", bottomRemarksArray);

        // Move the cursor after the indexes
        const quillEditor = editor.querySelector(".ql-editor");
        if (quillEditor) {
          const range = document.createRange();
          range.selectNodeContents(quillEditor);
          range.collapse(false);
          const sel = window.getSelection();
          sel?.removeAllRanges();
          sel?.addRange(range);
        }
      }
    }, 1000);
  }



  getMappedReferencesForReqPayload(references, mapFromRawData = false) {
    return references.map(ref => {
      return mapFromRawData ? {
        uid: Utils.makeid(15),
        notes: ref?.remarkText
      } : {
        uid: ref?.uid,
        notes: ref?.description
      };
    });
  }

  addReferenceToExistingSmartString(element, data: {smartLinkData?: any[], referencesData?: any[], stickyNotesData?: any[]}) {
    const linkText = element.textContent;

    const newElement = document.createElement("s");
    newElement.dataset["type"] = SmartObject.SMART_OBJECTS;
    newElement.setAttribute("data-remark", "true");

    if (Object.keys(data)?.includes("smartLinkData")) {
      newElement.dataset[SmartObject.SMART_LINK.toLowerCase()] = "true";
    }

    if (Object.keys(data)?.includes("stickyNotesData")) {
      newElement.setAttribute("data-sticky-note", "true");
    }

    const newData = {
      ...data,
      referencesData: [
        ...(data?.referencesData.map(note => note?.uid))
      ]
    };
    newElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(newData)));
    newElement.contentEditable = "false";
    newElement.textContent = linkText;

    return newElement;
  }

  getMappedReferenceDropdownData(references, mapFromRawData = false, referenceIndexes = {}) {
    return references.map(ref => {
      return mapFromRawData === true ? {
        uid: Utils.makeid(15),
        reference_index: "*",
        description: ref?.notes ? ref?.notes : ref?.remarkText,
        date: format(new Date(), "dd.MM.yyyy")
      } : {
        uid: ref?.data?.selectedData?.uid,
        reference_index: referenceIndexes?.[ref?.data?.selectedData?.uid],
        description: ref?.notes ? ref?.notes : ref?.remarkText,
        date: format(new Date(), "dd.MM.yyyy")
      };
    });
  }
}

// When topic view -> click ->  then scroll down to the remark
// When edit -> click -> then open the popup -> edit the remark API
// delete remark API
// show the index numbers in remarks

// Create wali API for sticky notes
// Save id and bg-color of sticky notes in s tag
// click to edit or delete -> API
