<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<vnc-dialog [classes]="['confirmation-dialog']" [headerTemplate]="headerTemplate"
  classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>

<ng-template #bodyTemplate>
  <div class="content-wrapper">
    <p *ngIf="messageKey" class="dialog-message" [innerHTML]="messageKey|translate: params"></p>
    <mat-form-field *ngIf="data.action === 'password_required'">
      <input style="border: none;" required matInput type="password" placeholder="{{ 'PASSWORD' | translate }}"
        [(ngModel)]="password">
    </mat-form-field>
    <mat-form-field style="width: 100%" *ngIf="isCancelMeeting" class="textarea-full-width" appearance="fill">
      <mat-label>{{ 'MESSAGE' | translate }}</mat-label>
      <textarea id="cancelMessage" style="border: none;" matInput type="text"
        [(ngModel)]="message"></textarea>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #headerTemplate>
  <ng-container [ngSwitch]="data?.action">
  <p *ngSwitchCase="true">
  
  </p>
  
  <vnc-header *ngSwitchCase="'password_required'" style="width:100%;"
    title="{{ 'PASSWORD_REQUIRED_TITLE' | translate }}" type="title-close" size="l" (onCloseClick)="cancel()"
    [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchCase="'cancel_meeting'" style="width:100%;"
    title="{{ 'CANCEL_SCHEDULED_MEETING' | translate }}" type="title-close" size="l" (onCloseClick)="cancel()"
    [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchCase="'access_denied'" style="width:100%;" title="{{ 'ACCESS_DENIED' | translate }}"
    type="title-close" size="l" (onCloseClick)="cancel()" [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchCase="'close_whiteboard'" style="width:100%;"
    title="{{ 'DELETE_WHITEBOARD' | translate }}" type="title-close" size="l" (onCloseClick)="cancel()"
    [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchCase="'remove_from_meeting'" style="width:100%;"
    title="{{ 'REMOVE_FROM_CHAT' | translate }}" type="title-close" size="l" (onCloseClick)="cancel()"
    [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchCase="'start_screenshare'" style="width:100%;"
    title="{{ 'SCREENSHARE_ALLOWED' | translate }}" type="title-close" size="l" (onCloseClick)="cancel()"
    [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchCase="'request_share_screen'" style="width:100%;"
    title="{{ 'SCREENSHARE_ALLOWED' | translate }}" type="title-close" size="l" (onCloseClick)="cancel()"
    [showCloseIcon]="true">
  </vnc-header>
  <vnc-header *ngSwitchDefault style="width:100%;"
    type="title-close" size="l" (onCloseClick)="cancel()"
  [showCloseIcon]="true">
  </vnc-header>
  </ng-container>
</ng-template>
<ng-template #footerTemplate>
  <ng-container *ngIf="data.action !== 'cancel_meeting'">
    <ng-container *ngIf="data.action === 'access_denied'">
      <vnc-footer secondaryLabel="{{ 'GO_TO_CHATS' | translate }}" primaryLabel="{{ 'TRY_AGAIN' | translate }}"
        (onPrimaryClick)="tryAgain()" (onSecondaryClick)="goToChats()" [type]="'secondary-primary'" [color]="'white'">
      </vnc-footer>
    </ng-container>
    <ng-container *ngIf="data.action !== 'access_denied'">
      <vnc-footer *ngIf="data.action !== 'request_share_screen'" secondaryLabel="{{ 'CANCEL'|translate
    }}" primaryLabel="{{ activeKey|translate }}" (onPrimaryClick)="action()" (onSecondaryClick)="cancel()"
        [type]="'secondary-primary'" [color]="'white'">
      </vnc-footer>
      <vnc-footer *ngIf="data.action === 'request_share_screen'" secondaryLabel="{{ 'DENY'|translate
    }}" primaryLabel="{{ activeKey|translate }}" (onPrimaryClick)="action()" (onSecondaryClick)="cancel()"
        [type]="'secondary-primary'" [color]="'white'">
      </vnc-footer>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data.action === 'cancel_meeting'">
      <vnc-footer *ngIf="!isCancelMeeting" secondaryLabel="{{ 'NO' | translate }}" primaryLabel="{{ 'YES' | translate }}"
        (onPrimaryClick)="cancelMeeting()" (onSecondaryClick)="cancel()" [type]="'secondary-primary'" [color]="'white'">
      </vnc-footer>
      <vnc-footer *ngIf="isCancelMeeting" secondaryLabel="{{ 'CANCEL' | translate }}" primaryLabel="{{ 'SEND' | translate }}"
        (onPrimaryClick)="send()" (onSecondaryClick)="cancel()" [type]="'secondary-primary'" [color]="'white'">
      </vnc-footer>
  </ng-container>
  

</ng-template>