
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { LayoutActionTypes } from "../actions/layout";
import { SideRoasterTabType } from "../utils/constants.util";

export interface LayoutState {
  chatRightSideBarExpanded: any;
  selectedSideRostersTab: SideRoasterTabType;

  conversationListExpanded: boolean;
  sideRostersListExpanded: boolean;
  showGlobalSearchResults: boolean;
  showGlobalSearchResultSelected: boolean;
  rightSideBarExpanded: boolean;
  expandFilesSidebar: boolean;
  expandInfoSidebar: boolean;
  profileJid?: string;
  profileTab: string;
  chatInfo?: string;
  fileTab?: string;
  downloadFileIds?: number[];
}

const initialState: LayoutState = {
  selectedSideRostersTab: SideRoasterTabType.DEFAULT,

  conversationListExpanded: true,
  sideRostersListExpanded: false,
  showGlobalSearchResults: false,
  showGlobalSearchResultSelected: false,
  rightSideBarExpanded: false,
  chatRightSideBarExpanded: false,
  expandInfoSidebar: false,
  expandFilesSidebar: false,
  fileTab: "all",
  profileTab: "profileTab",
  downloadFileIds: [],
};

export function layoutReducer(state = initialState, action: Action): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.EXPAND_CONVERSATION_LIST: {
      return {
        ...state,
        conversationListExpanded: action.payload
      };
    }
    case LayoutActionTypes.EXPAND_SIDE_ROSTER_LIST: {
      return {
        ...state,
        sideRostersListExpanded: action.payload
      };
    }
    case LayoutActionTypes.SELECT_SIDE_ROSTER_TAB: {
      return {
        ...state,
        selectedSideRostersTab: action.payload
      };
    }

    case LayoutActionTypes.HIDE_GLOBAL_SEARCH: {
      return {
        ...state,
        showGlobalSearchResults: false
      };
    }
    case LayoutActionTypes.SHOW_GLOBAL_SEARCH: {
      return {
        ...state,
        showGlobalSearchResults: true
      };
    }

    case LayoutActionTypes.GLOBAL_SEARCH_RESULT_SELECTED: {
      return {
        ...state,
        showGlobalSearchResultSelected: true
      };
    }
    case LayoutActionTypes.GLOBAL_SEARCH_RESULT_DESELECTED: {
      return {
        ...state,
        showGlobalSearchResultSelected: false
      };
    }

    case LayoutActionTypes.RIGHT_SIDEBAR_EXPANDED: {
      return {
        ...state,
        rightSideBarExpanded: action.payload
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPANDED: {
      return {
        ...state,
        chatRightSideBarExpanded: action.payload,
        expandFilesSidebar: false,
        expandInfoSidebar: false,
        profileJid: null,
        chatInfo: null,
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_FILES_SIDEBAR_EXPANDED: {
      return {
        ...state,
        chatRightSideBarExpanded: action.payload,
        expandFilesSidebar: action.payload,
        expandInfoSidebar: false,
        profileJid: null,
        chatInfo: null
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_FILES_SIDEBAR_EXPANDED_WITH_FILE_IDS: {
      return {
        ...state,
        chatRightSideBarExpanded: action.payload.status,
        expandFilesSidebar: action.payload.status,
        downloadFileIds: action.payload.ids,
        expandInfoSidebar: false,
        profileJid: null,
        chatInfo: null
      };
    }

    case LayoutActionTypes.RESET_DOWNLOAD_FILE_IDS: {
      return {
        ...state,
        downloadFileIds: [],
      };
    }

    // Chat info tab
    case LayoutActionTypes.CHAT_RIGHT_INFO_SIDEBAR_EXPANDED: {
      return {
        ...state,
        chatRightSideBarExpanded: action.payload,
        expandFilesSidebar: false,
        expandInfoSidebar: action.payload,
        profileJid: null,
        profileTab: null,
        chatInfo: null
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE: {
      return {
        ...state,
        chatRightSideBarExpanded: true,
        profileJid: action.payload,
        profileTab: null,
        chatInfo: null
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE_TAB: {
      return {
        ...state,
        profileTab: action.payload
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_SIDEBAR_EXPAND_PROFILE_TAB_FILE_IDS: {
      return {
        ...state,
        profileTab: action.payload.tab,
        downloadFileIds: action.payload.ids,
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_SIDEBAR_CHAT_INFO: {
      return {
        ...state,
        chatRightSideBarExpanded: true,
        expandFilesSidebar: false,
        expandInfoSidebar: false,
        profileJid: null,
        chatInfo: action.payload,
      };
    }

    case LayoutActionTypes.CHAT_RIGHT_FILE_TAB_SIDEBAR: {
      return {
        ...state,
        fileTab: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedSideRostersTab = (state: LayoutState) => state.selectedSideRostersTab;

export const _getIsConversationListExpanded = (state: LayoutState) => state.conversationListExpanded;
export const _getIsRightSideBarExpanded = (state: LayoutState) => state.rightSideBarExpanded;
export const _getFileTabSidebar = (state: LayoutState) => state.fileTab;
export const _getIsChatRightSideBarExpanded = (state: LayoutState) => state.chatRightSideBarExpanded;
export const _getIsChatFilesSideBarExpanded = (state: LayoutState) => state.expandFilesSidebar;
export const _getIsChatInfoSideBarExpanded = (state: LayoutState) => state.expandInfoSidebar;
export const _getProfileJid = (state: LayoutState) => state.profileJid;
export const _getProfileTab = (state: LayoutState) => state.profileTab;
export const _getSidebarInfo = (state: LayoutState) => state.chatInfo;
export const _getIsSideRostersExpanded = (state: LayoutState) => state.sideRostersListExpanded;
export const _getShowGlobalSearchResults = (state: LayoutState) => state.showGlobalSearchResults;
export const _getShowGlobalSearchResultSelected = (state: LayoutState) => state.showGlobalSearchResultSelected;
export const _getDownloadFileIds = (state: LayoutState) => state.downloadFileIds;
