<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<vnc-dialog [classes]="classes" [headerTemplate]="mobileHeaderTemplate" [bodyTemplate]="mobileBodyTemplate"
  [footerTemplate]="footerTemplate">
</vnc-dialog>

<ng-template #mobileHeaderTemplate>
  <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{ headerText | translate}}"
    (darkBackPress)="close()" [darkSuffixIcon]="'mdi-check'" (darkSuffixButtonPress)="submit()">
  </vnc-mobile-header>
</ng-template>
<ng-template #mobileBodyTemplate>
  <vp-dropdown-item *ngIf="!datePickerMode" [isSelectOption]="true" [isActive]="isSchedule"
    itemType="filter-check-unchecked"
    label="{{ (isScheduledMeeting ? 'SCHEDULE': isScheduledPost ? 'SCHEDULE_POST' : 'SCHEDULE_TOPIC') | translate }}"
    (onClickEvent)="changeSchedule()" (checkEvent)="changeSchedule()"></vp-dropdown-item>
  <div *ngIf="isSchedule">
    <div [ngClass]="{'date-picker-mode': datePickerMode}" class="picker-item-schedule"
      [style.margin-top]="datePickerMode ? '24px' : 'unset'">
      <vnc-datepicker label="{{ 'PUBLISH_DATE' | translate }}" class="start-date"
        placeholder="{{ (isScheduledMeeting || datePickerMode ? 'START_DATE':'PUBLISH_DATE') | translate }}"
        [showCancel]="true" [showDropdowns]="true" [drops]="'down'" [showTimePicker]="true" [linkedCalendars]="true"
        [singleSelected]="scheduleDateControl?.value" [singleDatePicker]="true"
        (datesUpdatedEvent)="dateChange($event, true)">
      </vnc-datepicker>
      <!-- <vnc-single-datepicker
                locale="{{lang === 'de' ? 'de_DE' : 'en_US'}}"
                placeholder="{{ (isScheduledMeeting || datePickerMode ? 'START_DATE':'PUBLISH_DATE') | translate }}"
                [dateControl]="scheduleDateControl"
                [showSeconds]="!isScheduledMeeting"
                [minDate]="minDate"
                [hideTime]="datePickerMode"
                [locale]="lang"
                [maxDate]="maxDate"
                [color]="'accent'"
            ></vnc-single-datepicker> -->
    </div>
    <div *ngIf="!isScheduledMeeting" [ngClass]="{'date-picker-mode': datePickerMode}" class="picker-item-schedule">
      <vnc-datepicker label="{{ 'EXPIRY_DATE' | translate }}" class="expiry-date"
        placeholder="{{ (isScheduledMeeting || datePickerMode ? 'END_DATE' : 'EXPIRY_DATE') | translate }}"
        [showCancel]="true" [showDropdowns]="true" [drops]="'down'" [showTimePicker]="true" [linkedCalendars]="true"
        [singleSelected]="expireDateControl?.value" [singleDatePicker]="true"
        (datesUpdatedEvent)="dateChange($event, false)">
      </vnc-datepicker>
      <!-- <vnc-single-datepicker
                placeholder="{{ (isScheduledMeeting || datePickerMode ? 'END_DATE' : 'EXPIRY_DATE') | translate }}"
                [dateControl]="expireDateControl"
                [showSeconds]="!isScheduledMeeting"
                [minDate]="minDate"
                [hideTime]="datePickerMode"
                [locale]="lang"
                [color]="'accent'"
            ></vnc-single-datepicker> -->
    </div>
    <div *ngIf="isScheduledMeeting" class="scheduled-time call-duration">
      <mat-form-field>
        <mat-label class="option-label">{{ 'DURATION'| translate}}</mat-label>
        <mat-select [(ngModel)]="duration">
          <mat-option *ngFor="let option of durations" [value]="option.id">{{ option.label | translate : {'duration':
            option.value} }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="action-link-item" *ngIf="!datePickerMode && !isScheduledMeeting">
      <vnc-action-link [type]="'icon-left'" [linkColor]="'blue'" [leadingIconTemplate]="deleteIcon"
        label="{{'REMOVE_LBL' | translate}}" (actionClicked)="changeSchedule()"></vnc-action-link>
    </div>
  </div>
</ng-template>
<ng-template #deleteIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="#337CBD">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M13.5 5.25v8.464c0 1.117-.893 2.036-2 2.036h-5c-1.107 0-2-.919-2-2.036V5.25h9zM12 6.75H6v6.964c0 .273.195.496.434.531l.066.005h5c.268 0 .5-.238.5-.536V6.75zm-2.25-4.5c.555 0 1.04.302 1.3.75h2.45c.414 0 .75.336.75.75s-.336.75-.75.75h-9c-.414 0-.75-.336-.75-.75S4.086 3 4.5 3h2.45c.26-.449.745-.75 1.3-.75h1.5z"
                  transform="translate(-268.000000, -284.000000) translate(0.000000, 70.000000) translate(16.000000, 143.000000) translate(249.000000, 68.000000) translate(3.000000, 3.000000)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
<ng-template #footerTemplate></ng-template>
