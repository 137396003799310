import {Injectable} from "@angular/core";
import {Topic} from "../models/topic.model";
import {TopicBulkAdd} from "../store/actions/topic";
import {ChannelService} from "../channel.service";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {ChannelRootState, getAllTopics, getFilteredTopicsInfo} from "../store/reducers";
import {FilteredTopicsInfoUpdate} from "../store/actions/channel";
import {combineLatest} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()

export class ChannelTopicListingService {
  constructor(private _channelService: ChannelService,
              private _channelRootStore: Store<ChannelRootState>) {
  }

  resetFilteredTopics(channelId: string) {
    const defaultState = { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false, channelId: channelId, filterApplied: false};
    this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(defaultState));
  }

  loadFilteredTopics(channelId: string, query: string) {
    const data = this.buildDataObject(0, 0, [], true, false, channelId, true);
    this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(data));
    this._channelService.getTopics(channelId, 0, null, query)
      .subscribe(res => {
        if (res && !res.error) {
          (res.topics as Topic[]).filter((t)=>t.channel_id == channelId);
          const topics: Topic[] = (res.topics as Topic[]).filter((t) => t.channel_id == channelId)
            .map((t) => ({
              ...t,
              id: t.is_iom ? "iom-" + t.id : t.id,
            }));

          const data = {
            totalCount: res.total_count,
            offset: res.topics.length,
            ids: this.extractIdsFromTopics(topics),
            isLoading: false,
            isLoaded: true,
            channelId,
            filterApplied: true
          };
          this._channelRootStore.dispatch(new TopicBulkAdd(topics));
          this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(data));
        }
        else {
          const data = this.buildDataObject(0, 0, [], false, true, channelId, true);
          this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(data));
        }
      });
  }
  getLoadingLoadedStatus(channelId): Observable<{loading: boolean, loaded: boolean}> {
    return this._channelRootStore.select(getFilteredTopicsInfo).pipe(map(info => {
      return {
        loading: info?.[channelId]?.isLoading || false,
        loaded: info?.[channelId]?.isLoaded || false
      };
    }));
  }

  loadMoreFilteredTopics(channelId: string, query: string, offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this._channelRootStore.select(getFilteredTopicsInfo)
      .subscribe(res => {
        const info = res?.[channelId];
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false, channelId, true);
      this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(data));
      this._channelService.getTopics(channelId, offset, limit, query)
        .subscribe(res => {
          if (res && !res.error) {
            (res.topics as Topic[]).filter((t)=>t.channel_id == channelId);
            const topics: Topic[] = (res.topics as Topic[]).filter((t) => t.channel_id == channelId)
              .map((t) => ({
                ...t,
                id: t.is_iom ? "iom-" + t.id : t.id,
              }));
            const data = {
              totalCount: res.total_count,
              offset: offset + res.topics.length,
              ids: [...previousIds, ...this.extractIdsFromTopics(topics, previousIds)],
              isLoading: false,
              isLoaded: true,
              channelId,
              filterApplied: true
            };
            this._channelRootStore.dispatch(new TopicBulkAdd(topics));
            this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(data));
          }
          else {
            const data = this.buildDataObject(totalCount, previousOffset, previousIds, false, true, channelId, true);
            this._channelRootStore.dispatch(new FilteredTopicsInfoUpdate(data));
          }
        });
    }
  }

  selectFilteredTopics(channelId: string): Observable<any> {
    let filteredTopics = [];
    const filteredTopicsOb$ = this._channelRootStore.select(getFilteredTopicsInfo);
    const allTopicsOb$ = this._channelRootStore.select(getAllTopics);
    return combineLatest([filteredTopicsOb$, allTopicsOb$])
      .pipe(map(([allFilteredTopics, allTopics]) => {
        const allFilteredTopicsOfThisChannel = allFilteredTopics?.[channelId];
        const ids = allFilteredTopicsOfThisChannel?.ids || [];
        // @ts-ignore
        const topicsById = {};
        allTopics.forEach(t => {
          if (!!t?.id) {
            topicsById[t?.id] = t;
          }
        });
        filteredTopics = ids.map(id => topicsById?.[id]).filter(v => !!v);
        return filteredTopics;
      }));
  }

  async isFilterApplied(channelId: string) {
    const filteredTopicsInfo = await this._channelRootStore.select(getFilteredTopicsInfo).toPromise();
    return filteredTopicsInfo?.[channelId]?.filterApplied || false;
  }

  buildDataObject(totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean, channelId: string, filterApplied) {
    return {
      totalCount,
      offset,
      ids,
      isLoading,
      isLoaded,
      channelId,
      filterApplied
    };
  }

  extractIdsFromTopics(topics: Topic[], previousIds?: string[]) {
    let ids = [];
    if (previousIds && previousIds.length > 0) {
      topics.forEach(topic => !previousIds.includes(topic.id) && ids.push(topic.id));
    } else {
      topics.forEach(topic => ids.push(topic.id));
    }
    return ids;
  }

  operation:string = "";
  topicId:string = "";
  dragged(ev,opt:string,topic:any){
    this.operation = opt;
    this.topicId = topic?.id;
    localStorage.setItem("topicDragged",this.topicId);
    localStorage.setItem("operation",opt);
  }
}
