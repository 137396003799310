
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AppType } from "../shared/models";
import { Contact } from "../talk/models/contact.model";
import { Action } from "./index";
import { AdvanceSearchTime } from "../shared/models/advance-search-time.model";
import { AdvanceSearchContentType } from "../shared/models/advance-search-content-type.model";
import { SearchMessage } from "../talk/models/search-message.model";

export class SearchActionTypes {
  static SEARCH_KEYWORD_UPDATE = "[SEARCH] Update Keyword";
  static SEARCH_OFFSET_UPDATE = "[SEARCH] Update Offset";
  static SEARCH_TOTAL_UPDATE = "[SEARCH] Update Total";
  static SEARCH_CLEAR_ALL = "[SEARCH] Clear All";
  static SEARCH_RESPONSE_LOAD = "[SEARCH] Search Response Load";
  static SEARCH_RESPONSE_NEXT_PAGE = "[SEARCH] Search Response Next Page";

  static SEARCH_SELECTED_MESSAGE_ID = "[SEARCH] Search Selected Message Id";

  static SEARCH_APP_UPDATE = "[SEARCH] Update Apps";
  static SEARCH_TIME_RANGE_UPDATE = "[SEARCH] Update Time Range";
  static SEARCH_CONTENT_TYPE_UPDATE = "[SEARCH] Update Content Type";
  static SEARCH_USERS_UPDATE = "[SEARCH] Update Users";
  static SEARCH_TAGS_UPDATE = "[SEARCH] Update Tags";
  static SEARCH_ATTACHMENTS_UPDATE = "[SEARCH] Update Attachments";
  static SEARCH_STARRED_UPDATE = "[SEARCH] Update Starred";
  static SEARCH_BOOLEAN_UPDATE = "[SEARCH] Update Boolean";
}

export class SearchResponseLoad implements Action {
  readonly type = SearchActionTypes.SEARCH_RESPONSE_LOAD;

  constructor(public payload: SearchMessage[]) {
  }
}

export class SearchResponseNextPage implements Action {
  readonly type = SearchActionTypes.SEARCH_RESPONSE_NEXT_PAGE;

  constructor(public payload: SearchMessage[]) {
  }
}

export class SetSearchSelectedMessageId implements Action {
  readonly type = SearchActionTypes.SEARCH_SELECTED_MESSAGE_ID;

  constructor(public payload: string | null) {
  }
}

export class SearchTotalUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_TOTAL_UPDATE;

  constructor(public payload: number) {
  }
}

export class SearchKeywordUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_KEYWORD_UPDATE;

  constructor(public payload: string) {
  }
}

export class SearchOffsetUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_OFFSET_UPDATE;

  constructor(public payload: number) {
  }
}

export class SearchClearAll implements Action {
  readonly type = SearchActionTypes.SEARCH_CLEAR_ALL;
}

export class SearchAppUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_APP_UPDATE;

  constructor(public payload: AppType[]) {
  }
}

export class SearchTimeRangeUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_TIME_RANGE_UPDATE;

  constructor(public payload: AdvanceSearchTime) {
  }
}

export class SearchContentTypeUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_CONTENT_TYPE_UPDATE;

  constructor(public payload: AdvanceSearchContentType) {
  }
}

export class SearchUsersUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_USERS_UPDATE;

  constructor(public payload: Contact[]) {
  }
}

export class SearchTagsUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_TAGS_UPDATE;

  constructor(public payload: string[]) {
  }
}

export class SearchAttachmentUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_ATTACHMENTS_UPDATE;

  constructor(public payload: boolean) {
  }
}


export class SearchStarredTypUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_STARRED_UPDATE;

  constructor(public payload: boolean) {
  }
}


export class SearchBooleanUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_BOOLEAN_UPDATE;

  constructor(public payload: boolean) {
  }
}
