import { Component, ElementRef, Inject } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { environment } from "app/environments/environment";

@Component({
    selector: "vp-mobile-bottom-sheet-mobile-call-menu",
    templateUrl: "./bottom-sheet-mobile-call.component.html",
    styleUrls: ["./bottom-sheet-mobile-call.component.scss"],
})
export class VNCBottomSheetMobileCallMenu {
    menuType: string = "file";
    file: any;
    isCordovaApp: boolean = environment.isCordova;
    isChannelArchived: boolean = false;
    constructor(
        private _bottomSheetRef: MatBottomSheetRef<VNCBottomSheetMobileCallMenu>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        if (this.data) {
            this.file = this.data.file;
            this.isChannelArchived = this.data.isChannelArchived ?? false;
        }
    }

    operation(operationItem: string): void {
        this._bottomSheetRef.dismiss({ operation: operationItem });
    }

}
