
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  InjectionToken,
  OnDestroy
} from "@angular/core";
import { Store } from "@ngrx/store";
import { JID, LDAPData } from "app/talk/models/jid.model";
import { Contact } from "app/talk/models/contact.model";
import { Subject, take, takeUntil } from "rxjs";
import { ContactInformation } from "app/talk/models/vcard.model";
import { XmppService } from "app/talk/services/xmpp.service";
import { ContactRepository } from "app/talk/repositories/contact.repository";
import { Broadcaster } from "app/talk/shared/providers";
import { TalkRootState } from "app/talk/reducers";
import { getContactById, getIsAppOnline } from "app/reducers";
import { CommonUtil } from "app/talk/utils/common.util";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export const USER_JID = new InjectionToken<string>("bare");

@Component({
  selector: "vp-user-info",
  templateUrl: "./user-info.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoDialogComponent implements OnDestroy {
  loggedInUserJID: JID;
  contact: Contact;
  isAppOnline = false;
  vCard: ContactInformation;
  fullName = "";
  avatarBackground: string;
  avatarText: string;
  ldapData: LDAPData;
  jidBare = "";
  isExternalUser: boolean;
  companyName = "";
  private isAlive$ = new Subject<boolean>();
  isGroupChat: boolean;

  constructor(public xmppService: XmppService,
    private contactRepo: ContactRepository,
    private dialog: MatDialogRef<UserInfoDialogComponent>,
    private broadcaster: Broadcaster,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<TalkRootState>,
    private changeDetectionRef: ChangeDetectorRef) {
      this.jidBare = this.data.bare;
    this.setupStore();
    if (this.data.name) {
      this.fullName = this.data.name;
    } else {
      this.fullName = this.jidBare.split("@")[0];
    }
    this.isExternalUser = this.contactRepo.isExternalUser(this.jidBare);
    this.isGroupChat = CommonUtil.isGroupTarged(this.jidBare);
    if (!this.isGroupChat) {
      if (this.data.ldapData) {
        this.ldapData = this.data.ldapData;
        if (!!this.ldapData.company) {
          this.companyName = this.ldapData.company[0];
        }
      } else {
        this.getVCard();
      }
    }


    this.broadcaster.on<any>("closeUserInfoDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.dialog.close();
        this.changeDetectionRef.markForCheck();
      });

    this.store.select(state => getContactById(state, this.jidBare)).pipe(takeUntil(this.isAlive$)).subscribe(c => {
      this.contact = c;
      if (!!c) {
        this.fullName = c.name;
      }
      this.changeDetectionRef.markForCheck();
    });

    this.avatarText = CommonUtil.getAvatarText(this.jidBare);
    this.avatarBackground = CommonUtil.getAvatarBackground(this.avatarText);
  }

  private setupStore() {
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
  }

  @HostListener("document:keydown.esc")
  close(): void {
    this.dialog.close();
  }

  getVCard() {
    this.contactRepo.getContactVCard(this.jidBare).pipe(takeUntil(this.isAlive$)).subscribe(vCard => {
      if (!!vCard) {
        if (vCard.addresses && vCard.addresses.length > 0) {
          vCard.addresses = vCard.addresses.map(address => CommonUtil.mapAddress(address));
        }

        if (vCard.emails && vCard.emails.length > 0) {
          vCard.emails = vCard.emails.map(email => CommonUtil.mapEmail(email));
        }

        if (vCard.phoneNumbers && vCard.phoneNumbers.length > 0) {
          vCard.phoneNumbers = vCard.phoneNumbers.map(phone => CommonUtil.mapPhone(phone));
        }
        this.vCard = vCard;
        if (vCard.fullName) {
          this.fullName = vCard.fullName;
        }
        if (vCard.organization) {
          this.companyName = vCard.organization.name;
        }
        this.changeDetectionRef.markForCheck();
      } else {
        this.contactRepo.getContactById(this.jidBare).pipe(take(1)).subscribe(contact => {
          if (!!contact) {
            this.fullName = contact.name;
            this.companyName = contact.companyName;
          }
          this.vCard = {
            emails: [{email: this.jidBare}]
          };
          this.changeDetectionRef.markForCheck();
        });
      }
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}
