``;/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "app/actions";
import { PostActionTypes } from "../actions/post";
import { Post } from "../models/post.model";

export interface PostState extends EntityState<Post> {
  isLoading: boolean;
  isLoaded: boolean;
  selectedPostId: Post["id"];
  postTimer: any;
  scrolltoItem: {[route: string]: {postId: number}};
  comments: { [postId: string]: { offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  postSideBarTab: string;
  remarks: any[];
  allPostsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  bookmarkedPostsInfo: {totalCount: number, offset: number, ids: number[], isLoading: boolean, isLoaded: boolean};
  mediaPostsInfo: { totalCount: number, offset: number, ids: number[], isLoading: boolean, isLoaded: boolean };
  postUserJid: string;
  reposting: number[];
  replys: { [postId: string]: { children: { id: string}[],  isLoading: boolean, isLoaded: boolean, totalCount: number, offset: number }}
}

export const postAdapter: EntityAdapter<Post> = createEntityAdapter<Post>({
  selectId: (post: Post) => post.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(t1: Post, t2: Post): number {
  return new Date(t2.publish_at).getTime() - new Date(t1.publish_at).getTime();
}

export const initialState: PostState = postAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedPostId: null,
  scrolltoItem: null,
  postTimer: null,
  comments: null,
  postSideBarTab: null,
  mediaPostsInfo: null,
  allPostsInfo: null,
  bookmarkedPostsInfo: {totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  remarks: [],
  reposting: [],
  postUserJid: "",
  replys: null
});

export function postReducer(state: PostState = initialState, action: Action): PostState {
  switch (action.type) {

    case PostActionTypes.POST_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case PostActionTypes.POST_LOAD_SUCCESS: {
      const posts = action.payload.map(t => {
        let post = state.entities[t.id];
        if (post) {
          t = {...t, ...post};
        }
        return t;
      });

      return postAdapter.setAll(posts, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case PostActionTypes.POST_ADD: {
      const newState = postAdapter.addOne(action.payload, state);
      return postAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }

    case PostActionTypes.POST_UPDATE: {
      const updatedState = postAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case PostActionTypes.POST_BULK_ADD: {
      const newState = postAdapter.addMany(action.payload, state);

      const changes = action.payload.map(post => {
        return { id: post.id, changes: post };
      });

      return postAdapter.updateMany(changes, newState);
    }
    case PostActionTypes.POST_BULK_UPDATE: {
      const changes = action.payload.map(post => {
        return { id: post.id, changes: post };
      });
      return postAdapter.updateMany(changes, state);
    }

    case PostActionTypes.POST_DELETE: {
      return postAdapter.removeOne(action.payload, state);
    }

    case PostActionTypes.POST_BULK_DELETE: {
      return postAdapter.removeMany(action.payload, state);
    }

    case PostActionTypes.SELECTED_POST: {
      return {
        ...state,
        selectedPostId: action.payload
      };
    }

    case PostActionTypes.POST_TIMER: {
      return {
        ...state,
        postTimer: action.payload
      };
    }

    case PostActionTypes.POST_SIDEBAR_TAB_CHANGE: {
      return {
        ...state,
        postSideBarTab: action.payload
      };
    }

    case PostActionTypes.POST_COMMENTS_LOAD_REQUEST: {
      return {
        ...state,
        comments: { ...state.comments, [action.payload]: {...(state?.comments?.[action.payload] || {}), isLoading: true}}
      };
    }

    case PostActionTypes.POST_COMMENTS_LOAD_SUCCESS: {
      const postId = action.payload.postId;
      return {
        ...state,
        comments: {
          ...state.comments,
          [postId]: {
            ...(state?.comments?.[postId] || {}),
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count,
            offset: action.payload.offset
          }
        }
      };
    }

    case PostActionTypes.POST_SCROLL_TO_ITEM: {
      const route = action.payload.route;
      const postId = action.payload.postId;
      return {
        ...state,
        scrolltoItem: {
          ...state.scrolltoItem,
          [route]: {
            ...(state?.scrolltoItem?.[route] || {}),
            postId: postId
          }
        }
      };
    }
    case PostActionTypes.POST_REMOVE_SCROLL_TO_ITEM: {
      const route = action.payload.route;
      const scrolltoItem = state.scrolltoItem;
      delete scrolltoItem[route];
      return {
        ...state,
        scrolltoItem
      };
    }
    case PostActionTypes.POST_REMOVE_ALL_SCROLL_TO_ITEM: {
      return {
        ...state,
        scrolltoItem: {}
      };
    }

    case PostActionTypes.ALL_POSTS_INFO_UPDATE: {
      return {
        ...state,
        allPostsInfo: action.payload
      };
    }

    case PostActionTypes.MEDIA_POST_INFO_UPDATE: {
      return {
        ...state,
        mediaPostsInfo: action.payload
      };
    }

    case PostActionTypes.BOOKMARKED_POSTS_INFO_UPDATE: {
      return {
        ...state ,
        bookmarkedPostsInfo: action.payload
      };
    }

    case PostActionTypes.POST_USER_JID_UPDATE: {
      return {
        ...state,
        postUserJid: action.payload
      };
    }
    case PostActionTypes.POST_REPOST_REQUEST: {
      return {
        ...state,
        reposting: [...state.reposting, action.payload]
      };
    }
    case PostActionTypes.POST_REPOST_SUCCESS: {
      return {
        ...state,
        reposting: [...state.reposting].filter(v => v !== action.payload)
      };
    }

    case PostActionTypes.POST_REPLY_LOAD_REQUEST: {
      return {
        ...state,
        replys: { ...state.replys, [action.payload]: { ids: [], isLoading: true, isLoaded: false, offset: 0, totalCount: 0 }}
      };
    }

    case PostActionTypes.POST_REPLY_LOAD_SUCCESS: {
      const postId = action.payload.postId;
      const oldReplys = [ ...(state?.replys?.[postId]?.children || [])];
      const oldReplyIds = oldReplys.map(item => item.id);
      const newReplys = (action.payload.children || [])
        .filter(k => !oldReplyIds.includes(k?.id))
        .map(k => ({id: k?.id}));
      return {
        ...state,
        replys: {
          ...state.replys,
          [postId]: {
            ...(state?.replys?.[postId] || {}),
            children: [ ...oldReplys, ...newReplys ],
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count
          }
        }
      };
    }

    case PostActionTypes.POST_REPLY_ADD: {
      const postId = action.payload.postId;
      const oldMemberIds: any[] = state?.replys?.[postId]?.children?.map(x => x.id) || [];
      const newReplys: any[] = (action.payload.children?.map(k => ({ id: parseInt(k.id)})) || [])
        .filter(m => !oldMemberIds.includes(m.id));
      const oldReplys = [...(state?.replys?.[action.payload.postId]?.children || [])];
      return {
        ...state,
        replys: {
          ...state.replys,
          [postId]: {
            ...(state?.replys?.[postId] || {}),
            children: [...oldReplys, ...newReplys],
            isLoading: false,
            isLoaded: true
          }
        }
      };
    }

    case PostActionTypes.POST_REPLY_REMOVE: {
      const postId = action.payload.postId;
      const newReplys: any[] = [...(state?.replys?.[postId]?.children || [])]
        .filter(x => !action.payload.ids.includes(x.id));
      return {
        ...state,
        replys: {
          ...state.replys,
          [postId]: {
            ...(state?.replys?.[postId] || {}),
            children: newReplys
          }
        }
      };
    }

    case PostActionTypes.POST_REPLY_OFFSET_UPDATE: {
      const postId = action.payload.postId;
      const offset = action.payload.offset;
      return {
        ...state,
        replys: {
          ...state.replys,
          [postId]: {
            ...state.replys[postId],
            offset
          }
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsPostLoading = (state: PostState) => state.isLoading;
export const _getIsPostLoaded = (state: PostState) => state.isLoaded;
export const _getSelectedPostId = (state: PostState) => state.selectedPostId;
export const _getPostTimer = (state: PostState) => state.postTimer;
export const _getPostComments = (state: PostState) => state.comments;
export const _getScrollToItem = (state: PostState) => state.scrolltoItem;
export const _getPostSideBarTab = (state: PostState): string => state.postSideBarTab;
export const _getAllPostsInfo = (state: PostState) => state.allPostsInfo;
export const _getBookmarkedPostsInfo = (state: PostState) => state.bookmarkedPostsInfo;
export const _getMediaPostsInfo = (state: PostState) => state.mediaPostsInfo;
export const _getPostUserJid = (state: PostState) => state.postUserJid;
export const _getPostReplys = (state: PostState) => state.replys;
export const _getPostReposting = (state: PostState) => state.reposting;
