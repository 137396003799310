<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->



<vnc-dialog [classes]="['about-dialog-component']" headerText="{{ 'APP_INFO' | translate }}" closeText="{{ 'CLOSE' | translate }}"
  [headerTemplate]="isMobileScreen ? mobileHeader : null" classes="fixed-size {{ !isMobileScreen ? 'with-border with-border-radius' : '' }}"
  [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<ng-template #mobileHeader>
  <vnc-mobile-header style="width: 100%;" customClass="white-back-more" (backButtonClick)="onEsc()"
    headerType="back-more" mobileHeading="{{ 'APP_INFO' | translate }}"></vnc-mobile-header>
</ng-template>

<ng-template #bodyTemplate>
  <div class="mat-dialog" [ngStyle]="productInfoStyle">
    <div class="mat-dialog__body">
      <div class="container">
        <img [src]="iconUrl" [style.visibility]="currentTheme === 'airbus' ? 'hidden': 'visible'">
        <div class="app-version-name" [style.visibility]="currentTheme === 'airbus' ? 'hidden': 'visible'">
          <h6 [innerHTML]="headerBranding"></h6>
        </div>

        <span [style.marginTop]="currentTheme === 'airbus' ? '80px': '8px'" (click)="copyToClipboard()" matTooltip="{{'COPY_TO_CLIPBOARD' | translate }}" class="app-version">V {{ app_version }} </span>


        <vnc-toggle-switch *ngIf=isAvailableAppUpgradeCheck labelPosition="before"
          [checked]="isAndroidAppUpgradeEnabled" [size]="'s'" label="{{ 'CHECK_FOR_UPDATES' | translate }}"
          (changeEvent)="toggleAppUpdateChange($event)">
        </vnc-toggle-switch>

      </div>
      <h3 *ngIf="!hideChangelogMenu" class="changelog-menu">
        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 7.875c-.623 0-1.125.503-1.125 1.125S2.377 10.125 3 10.125 4.125 9.622 4.125 9 3.623 7.875 3 7.875zm0-4.5c-.623 0-1.125.502-1.125 1.125S2.377 5.625 3 5.625 4.125 5.123 4.125 4.5 3.623 3.375 3 3.375zm0 9.127a.997.997 0 1 0 0 1.995.997.997 0 0 0 0-1.995zm2.25 1.748h10.5v-1.5H5.25v1.5zm0-4.5h10.5v-1.5H5.25v1.5zm0-6v1.5h10.5v-1.5H5.25z"
            fill="#FFF" fill-rule="evenodd" />
        </svg> <a target="_blank" class="open-new-window"
          href="{{ 'CHANGELOG_URL'|translate }}">{{ 'CHANGELOG'|translate }}</a>
      </h3>
    </div>
  </div>
</ng-template>
