
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { LoggerService } from "app/shared/services/logger.service";
import { Observable, Subject, take } from "rxjs";
import { Message } from "../models/message.model";
import { CommonUtil } from "../utils/common.util";
import { normalizeCommonJSImport } from "../utils/normalize-common-js-import";

export class XmppServiceOmemo {
  private client: any;
  private isStarted: boolean;
  private localDeviceId: number = 0;
  private isOmemoConnected: boolean;
  private OmemoUtils: any;
  private databaseService: any;

  constructor(xmpp: any, databaseService: any, private logger: LoggerService) {
      this.logger.info("[XmppServiceOmemo][constructor]");
      this.client = xmpp;
      this.databaseService = databaseService;
  }

  async init() {
    if (this.isOmemoConnected) {
      return;
    }


    const XMPP = await normalizeCommonJSImport(import("stanza.io"));
    const Hints = XMPP.Hints;
    this.OmemoUtils = XMPP.Omemo.OmemoUtils;
    this.logger.info("[XmppServiceOmemo][init]", XMPP.Omemo);
    this.client.use(XMPP.Omemo.default);
    this.client.use(Hints);
    this.client.createOmemo(new OmemoDatabaseStorage(this.databaseService, this.logger));
    this.databaseService.getLocalDevice().pipe(take(1)).subscribe(localDevice => {
      this.logger.info("[XmppServiceOmemo][initlocalDevice]", localDevice);
      if (!!localDevice && !!localDevice.id) {
        this.localDeviceId = localDevice.id;
      }
    });

    this.isOmemoConnected = true;
  }

  async start() {
    if (this.isStarted) {
      return;
    }

    const platform = CommonUtil.platformDescription();

    this.logger.info("[XmppServiceOmemo][start] start OMEMO", platform, this.client);

    await this.client.omemo.start(platform);
    this.isStarted = true;

    this.logger.info("[XmppServiceOmemo] OMEMO started");
  }

  updateLocalDeviceId(localDevice: any) {
    if (!!localDevice && !!localDevice.id) {
      this.logger.info("[XmppServiceOmemo][initlocalDevice]", localDevice);
      this.localDeviceId = localDevice.id;
    }
  }

  getLocalDeviceId() {
    return this.localDeviceId;
  }

  getAnnouncedDevices(jid: string) {
    this.logger.info("[XmppServiceOmemo][getAnnouncedDevices]", jid, !!this.client.omemo);
    return this.client.omemo && this.client.omemo.getAnnouncedDevices(jid);
  }

  announceDevices(devices: any[]) {
    this.logger.info("[XmppServiceOmemo][announceDevices]");
    return this.client.omemo.announceDevices(devices);
  }

  clearAllAnnouncedDeviceIds() {
    this.logger.info("[XmppServiceOmemo][clearAllAnnouncedDeviceIds]");
    return this.client.omemo.announceDevices([]);
  }

  async clearAllAnnouncedDeviceIdsExceptCurrent() {
    const localDeviceId = await this.client.omemo.store.getLocalRegistrationId();
    this.logger.info("[XmppServiceOmemo][clearAllAnnouncedDeviceIdsExceptCurrent]", localDeviceId);
    const platform = CommonUtil.platformDescription();
    return this.client.omemo.announceDevices([{id: localDeviceId, label: platform}]);
  }

  sendMessage(msg: any, members = [msg.to, msg.from], isSignal = false) {
    const content = {
      to: msg.to,
      from: msg.from,
      body: msg.body,
      html: msg.html,
      htmlBody: msg.htmlBody,
      attachment: msg.attachment,
      location: msg.location,
      signal: msg.signal
    };

    let binaryBody = JSON.stringify(content);
    binaryBody = this.utf8_to_b64(binaryBody);
    binaryBody = this.toBinary(binaryBody);

    const toEncryptMsg = {
      ...msg,
      body: binaryBody
    };

    delete toEncryptMsg.html;
    delete toEncryptMsg.htmlBody;
    delete toEncryptMsg.attachment;
    delete toEncryptMsg.location;
    delete toEncryptMsg.signal;
    delete toEncryptMsg.$body;
    this.logger.info("[XmppServiceOmemo][sendMessage]", toEncryptMsg, members);
    const hint = isSignal ? "" : "Encrypted message";

    this.client.omemo.sendMessage(toEncryptMsg, members, hint);
  }

  decryptMessage(msg: any): Observable<Message> {
    const response = new Subject<Message>();

    this.logger.info("[XmppServiceOmemo][decryptMessage]", msg.id, msg, msg.encrypted.header);

    this.client.omemo.decryptMessage(msg).then(decryptedContent => {
      this.logger.info("[XmppServiceOmemo][decryptMessage] decryptedContent", msg.id, msg, decryptedContent);

      // if a message does not contain current device
      if (!decryptedContent) {
        this.logger.info(`[XmppServiceOmemo][decryptMessage] message ${msg.id} does not contain current device`);
        response.error({message: "message does not contain current device"});
        return;
      }
      let content: any;
      try {
        const contentBase64: string = this.OmemoUtils.arrayBufferToBase64String(decryptedContent);
        content = this.fromBinary(atob(contentBase64));
        content = this.b64_to_utf8(content);
        content = JSON.parse(content);
        this.logger.info("[XmppServiceOmemo][decryptMessage] content", decryptedContent, contentBase64);
        if (!content) {
          this.logger.error("[XmppServiceOmemo][decryptMessage] parse content error", decryptedContent, contentBase64);
          response.error({message: "parse content error"});
          return;
        }
      } catch (error) {
        this.logger.error("[XmppServiceOmemo][decryptMessage] exception", error);
        response.error(error);
        return;
      }

      msg.body = content.body;
      delete msg.$body;
      // if (!msg.$body) {
      //   msg.$body = {};
      // }
      // msg.$body.en = msg.body; // TODO VT get lang from msg.lang

      msg.html = content.html;
      msg.htmlBody = content.htmlBody;
      msg.attachment = content.attachment;
      msg.location = content.location;
      msg.signal = content.signal;

      this.logger.info("[XmppServiceOmemo][decryptMessage] res", msg.id, msg);

      // remove omemo materials
      msg.encryption = null;
      msg.encrypted = null;
      msg.cannotDecrypt = false;

      const loggedInuserBare = this.client.jid.bare;
      // this.logger.info("[XmppServiceOmemo][decryptMessage] loggedInuserBare", loggedInuserBare);

      const target = (msg.from.bare === loggedInuserBare) ? msg.to.bare : msg.from.bare;

      this.databaseService.setLastOmemoActiveTS(target).subscribe(() => {
        this.databaseService.createOrUpdateDecryptedMessage(msg, target).pipe(take(1)).subscribe(stored => {
          this.logger.info("[XmppServiceOmemo][decryptMessage] decryptDone stored", msg.id, msg, stored);
          response.next(msg);
        });
      });

    }).catch(err => {
      if (!err.toString().startsWith("Error: Tried to decrypt on a sending chain")) {
        this.logger.error("[XmppServiceOmemo][decryptMessage] error: ", msg.id, err, msg);
      } else {
        this.logger.info("[XmppServiceOmemo][decryptMessageError] error", msg.id, err, msg);
      }
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  // convert a Unicode string to a string in which
  // each 16-bit unit occupies only one byte
  toBinary(text: string): string {
    const codeUnits = new Uint16Array(text.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = text.charCodeAt(i);
    }
    const codeUnitsBuffer = new Uint8Array(codeUnits.buffer);
    return String.fromCharCode(...Array.from(codeUnitsBuffer));
  }

  fromBinary(binary: string): string {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    const codeUnitsBuffer = new Uint16Array(bytes.buffer);
    return String.fromCharCode(...Array.from(codeUnitsBuffer));
  }

  private utf8_to_b64(str: string) {
    const res = window.btoa(unescape(encodeURIComponent(str)));
    return res;
  }

  private b64_to_utf8(str: string) {
    const res = decodeURIComponent(escape(window.atob(str)));
    return res;
  }
}

class OmemoStorage {
  Direction = {
    SENDING: 1,
    RECEIVING: 2,
  };
}

export class OmemoDatabaseStorage extends OmemoStorage {
  databaseService: any;

  constructor(databaseService: any, private logger: LoggerService) {
    super();
    this.databaseService = databaseService;
  }

  async getDevices(jid: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getDevices(jid).subscribe((devices: any[]) => {
        resolve(devices);
      }, err => {
        reject(err);
      });
    });
  }

  async hasDevices(jid: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.hasDevices(jid).subscribe((has: boolean) => {
        resolve(has);
      }, err => {
        reject(err);
      });
    });
  }

  async storeDevices(jid: string, devices: any[]) {
    this.logger.info("[XmppServiceOmemo][storeDevices]", devices);

    return new Promise((resolve, reject) => {
      this.databaseService.storeDevices(jid, devices).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async getLocalRegistrationId() {
    return new Promise((resolve, reject) => {
      let localDevice;
      try {
        localDevice = JSON.parse(localStorage.getItem("localOmemoDevice"));
      } catch (error) {
        this.logger.error("error parsing json from localStorage, fetch from indexeddb: ", error);
      }
      if (!!localDevice && !!localDevice.id && !!localDevice.label) {
        resolve(localDevice.id);
      } else {
        this.databaseService.getLocalDevice().subscribe(device => {
          resolve(device?.id);
        }, err => {
          reject(err);
        });
      }
    });
  }

  async storeLocalRegistration(device: any) {
    return new Promise((resolve, reject) => {
      const localDevice = JSON.stringify({ id: device.id, label: device.label });
      localStorage.setItem("localOmemoDevice", localDevice);
      this.databaseService.storeLocalDevice(device).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async storeWhisper(address: string, id: string, whisper: any) {
    return new Promise((resolve, reject) => {
      this.databaseService.storeWhisper(address, id, whisper).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async getWhisper(address: string, id: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getWhisper(address, id).subscribe((whisper) => {
        resolve(whisper);
      }, err => {
        reject(err);
      });
    });
  }

  async isTrustedIdentity() {
    return true;
  }

  async loadIdentityKey(identity: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getIdentityKey(identity).subscribe((key) => {
        resolve(key);
      }, err => {
        reject(err);
      });
    });
  }

  async saveIdentity(identity: string, identityKey: any) {
    return new Promise((resolve, reject) => {
      this.databaseService.storeIdentityKey(identity, identityKey).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async getIdentityKeyPair() {
    return new Promise((resolve, reject) => {
      this.databaseService.getIdentityKeyPair().subscribe((keyPair) => {
        resolve(keyPair);
      }, err => {
        reject(err);
      });
    });
  }

  async storeIdentityKeyPair(keyPair: any) {
    return new Promise((resolve, reject) => {
      this.databaseService.storeIdentityKeyPair(keyPair).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async loadPreKey(keyId: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getPreKey(keyId).subscribe((preKey: any) => {
        resolve(preKey);
      }, err => {
        reject(err);
      });
    });
  }

  async storePreKey(keyId: string, preKeyPair: any) {
    return new Promise((resolve, reject) => {
      this.databaseService.storePreKey(keyId, preKeyPair).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async removePreKey() {
    // Keep it in case of race condition
  }

  async loadSignedPreKey(keyId: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getSignedPreKey(keyId).subscribe((preKey: any) => {
        resolve(preKey);
      }, err => {
        reject(err);
      });
    });
  }

  async storeSignedPreKey(keyId: string, signedPreKeyPair: any) {
    return new Promise((resolve, reject) => {
      this.databaseService.storeSignedPreKey(keyId, signedPreKeyPair).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async removeSignedPreKey(keyId: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.removeSignedPreKey(keyId).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  async loadSession(identifier: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getSession(identifier).subscribe((session) => {
        resolve(session);
      }, err => {
        reject(err);
      });
    });
  }

  async storeSession(identifier: string, session: any) {
    return new Promise((resolve, reject) => {
      this.databaseService.storeSession(identifier, session).subscribe(() => {
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }
  //
  // async removeSession(identifier: string) {
  //
  // }
  //
  // async removeAllSessions(prefix: string) {
  //
  // }

  wrapFunction(name: string, func: any) {
    const orig = this[name];
    this[name] = (...args) => func(orig, ...args);
  }
}
