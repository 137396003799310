
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Inject, HostListener, OnDestroy, Optional, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject, take } from "rxjs";
import { environment } from "app/environments/environment";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { Broadcaster } from "../../providers";

@Component({
  selector: "vp-update-avatar",
  templateUrl: "./update-avatar.component.html"
})
export class UpdateAvatarComponent implements OnInit, OnDestroy, AfterViewInit {
  oldAvatarUrl: string;
  showDefaultAvatar: boolean;
  isAppOnline: boolean;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  transform: ImageTransform = {};
  canvasRotation = 0;
  rotation = 0;
  containWithinAspectRatio = true;
  private isAlive$ = new Subject<boolean>();
  @Input() hideHeader: boolean;
  @Input() userProfile: any;
  @Output() changedAvatar = new EventEmitter();
  constructor(
    private electronService: ElectronService,
    private config: ConfigService,
    private broadcast: Broadcaster,
    @Optional() private matDialogRef: MatDialogRef<UpdateAvatarComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      if (data && data.userProfile) {
        this.userProfile = data.userProfile;
        this.oldAvatarUrl = this.getUserAvatar(data.userProfile);
        this.showDefaultAvatar = true;
      }
      this.broadcast.on("closeUploadAvatarDialog").pipe(take(1)).subscribe(() => {
        if (this.matDialogRef) {
          this.matDialogRef.close();
        }
      });
  }

  selectPhoto() {
    document.getElementById("custom-input").click();
  }

  ngAfterViewInit() {
    if (!!document.querySelector("vp-new-conversation-mobile")) {
      document.querySelector("vp-new-conversation-mobile").parentElement.style.zIndex = "1";
    }
  }

  getUserAvatar(userProfile) {
    let avatarId = "";
    let avatarURL = "";
    if (!!userProfile) {
      if (environment.isElectron) {
        avatarId = this.electronService.md5(userProfile.email);
      } else {
        avatarId = md5(userProfile.email);
      }
      const currentTime = new Date().getTime();
      avatarURL = `${this.config.get("avatarURL")}/${avatarId}-420.jpg?v=${currentTime}`;
    }
    return avatarURL;
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded() {
      // show cropper
  }

  cropperReady() {
      // cropper ready
  }

  loadImageFailed() {
      // show message
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
    if (!!document.querySelector("vp-new-conversation-mobile")) {
      document.querySelector("vp-new-conversation-mobile").parentElement.style.zIndex = "999999";
    }
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    if (this.matDialogRef && this.matDialogRef.close) {
      this.matDialogRef.close();
    }
  }

  imgLoadOnError() {
    this.showDefaultAvatar = false;
  }

  updateAvatar() {
    if (this.croppedImage) {
      const b64Data = this.croppedImage;
      const photo: any = {
        type: "image/png",
        data: b64Data
      };
      if (this.matDialogRef && this.matDialogRef.close) {
        this.matDialogRef.close({ photo: photo });
      } else {
        this.changedAvatar.emit({ photo: photo });
      }
    } else {
      if (this.matDialogRef && this.matDialogRef.close) {
        this.matDialogRef.close({ photo: {} });
      } else {
        this.changedAvatar.emit({ photo: {} });
      }
    }
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
  }

}
