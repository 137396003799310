<div class="related-topics" [ngClass]="{'list-dropdown-related-topics': view === viewType.LIST_DROPDOWN}">
  <div class="related-topics-header">
      <div class="left-part">
        <span class="heading" *ngIf="view !== viewType.LIST_DROPDOWN">
          {{ (!isMobileScreen ? 'RELATED_TOPICS' : 'RELATED_OBJECTS') | translate}}
        </span>
        <span class="heading" *ngIf="view === viewType.LIST_DROPDOWN">
          {{(!isMobileScreen ? 'RELATED_TOPICS' : 'RELATED_OBJECTS')  | translate | uppercase}}
        </span>
        <span class="count">
          ({{relatedTopics?.length || 0}})
        </span>
        <span class="svgIcon" [ngClass]="{'svgInverted':open,'svgIcon':!open}" (click)="this.open = !this.open">
          <ng-container [ngTemplateOutlet]="openIcon" ></ng-container>
      </span>
      </div>
      <div class="right-part" *ngIf="view !== viewType.LIST_DROPDOWN">
        <vnc-action-panel *ngIf="view === viewType.LIST && editable"
          [iconTemplate]="plusIconTemplate"
          [actionColor]="'white'"
          (click)="openRelatedTopicsDialog($event)"
          matTooltip="{{ 'ADD_RELATED_TOPIC' | translate }}" matTooltipPosition="above" matTooltipClass="customise-tooltip">
        </vnc-action-panel>
        <vnc-action-panel *ngIf="(!editable && relatedTopics?.length > 0) || editable"
          [iconTemplate]="kebabMenuIconTemplate"
          [actionColor]="'white'"
          [matMenuTriggerFor]="menu"
          matTooltip="{{ 'MORE_TEXT' | translate }}" matTooltipPosition="above" matTooltipClass="customise-tooltip">
        </vnc-action-panel>
      </div>
  </div>
  <div *ngIf="open" [ngClass]="{'related-topics-list': view === viewType.LIST, 'related-topics-grid': view === viewType.GRID, 'scrollable-list': view !== viewType.LIST_DROPDOWN}">
    <div class="plus-icon-grid" *ngIf="view === viewType.GRID && editable">
      <vnc-tile size="s"
                tileType="add-default"
                (addClickEvent)="openRelatedTopicsDialog($event)"
                (tileClickEvent)="openRelatedTopicsDialog($event)">
      </vnc-tile>
    </div>
    <ng-container *ngFor="let topic of relatedTopics">
      <vnc-related-topic-item class="related-topics-list-item"
                              [ngClass]="{'inactive-vnc-avatar': !topic?.author?.active}"
                              [avatarTemplate]="avatarTemplate"
                              *ngIf="!topic?.deleted"
                              [topicItem]="topic"
                              [showTopicIcon]="view !== viewType.GRID"
                              [menuTemplate]="relatedTopicMenuTemplate"
                              [showKebabIconOnHover]="view === viewType.GRID"
                              [showStaticKebabIcon]="view !== viewType.GRID"
                              (click)="openRelatedTopicPreview($event, topic)">
      </vnc-related-topic-item>
      <ng-template #avatarTemplate>
        <vnc-avatar
          customSize="48px"
          [imgSrc]="topic?.thumbnail_url"
          [defaultAvatar]="topic?.default_cover_url"
          [rightIconDefaultAvatarTemplate]="authorDefaultAvatar"
          [leftIcon]="topic?.is_private ? 'private' : topic?.subscribed ? 'subscribe' : '' ? 'private' : ''"
          [rightIcon]="topic?.author?.avatar_url">
        </vnc-avatar>
      </ng-template>
      <ng-template #relatedTopicMenuTemplate>
        <div class="item-drop-down">
          <vp-dropdown-item [itemType]="'icon-drop-default'"
                            [prefixIconTemplate]="copyLinkIcon"
                            label="{{'COPY_LINK' | translate}}"
                            (onClickEvent)="onCopyLink(topic)">
          </vp-dropdown-item>
        </div>
        <div class="item-drop-down">
          <vp-dropdown-item [itemType]="'icon-drop-default'"
                            [prefixIconTemplate]="sendToIconTemplate"
                            label="{{'SEND_TO' | translate}}"
                            (onClickEvent)="onSendTopic(topic)">
          </vp-dropdown-item>
        </div>
        <div class="item-drop-down" *ngIf="editable">
          <vp-dropdown-item [itemType]="'icon-drop-default'"
                            [prefixIconTemplate]="deleteIconTemplate"
                            label="{{'REMOVE' | translate}}"
                            (onClickEvent)="onRemoveTopic(topic)">
          </vp-dropdown-item>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<mat-menu #menu class="mat-menu-container">
  <ng-container *ngIf="menuTemplate" [ngTemplateOutlet]="menuTemplate"></ng-container>
</mat-menu>

<ng-template #menuTemplate>
  <div *ngIf="editable" class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="plusIconTemplate"
                      label="{{'ADD_RELATED_TOPIC' | translate}}"
                      (onClickEvent)="openRelatedTopicsDialog($event)">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down"
       *ngIf="relatedTopics?.length > 0">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="sendToIconTemplate"
                      label="{{'SEND_ALL_TO_DOT' | translate}}"
                      (onClickEvent)="onSendAllTopics()">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down" *ngIf="editable && relatedTopics?.length > 0">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="deleteIconTemplate"
                      label="{{'REMOVE_ALL' | translate}}"
                      (onClickEvent)="onRemoveAll()">
    </vp-dropdown-item>
  </div>
</ng-template>

<ng-template #openIcon>
  <svg width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m7 9 5 5 5-5z" fill="#000" fill-rule="evenodd"/>
</svg>
</ng-template>
<ng-template #kebabMenuIconTemplate>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="#8B96A0">
        <g>
          <g>
            <g>
              <g>
                <path d="M1.5 3C2.325 3 3 2.325 3 1.5S2.325 0 1.5 0 0 .675 0 1.5.675 3 1.5 3zm0 1.5C.675 4.5 0 5.175 0 6s.675 1.5 1.5 1.5S3 6.825 3 6s-.675-1.5-1.5-1.5zm0 4.5C.675 9 0 9.675 0 10.5S.675 12 1.5 12 3 11.325 3 10.5 2.325 9 1.5 9z" transform="translate(-1553 -2476) translate(1227 2453) translate(324 20) translate(3 3) translate(7.5 3)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #plusIconTemplate>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
      <g fill="#8B96A0">
        <g>
          <g>
            <path d="M14 8L8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6z" transform="translate(-16 -16) translate(16 16) translate(5 5)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #sendToIconTemplate>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/arrow-forward-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M15.7740438,5.37517659 L15.9113815,5.49364219 L21.7890048,10.9899785 C22.6078577,11.7557116 22.6618753,13.0040308 21.9439084,13.8336032 L21.7900198,13.9936673 L15.9123965,19.5047392 C14.8741816,20.4782069 13.5431012,19.9408363 13.4339803,18.5537843 L13.4268015,18.3681002 L13.4253624,15.266 L3.58349643,14.2545694 C2.68392381,14.162122 2,13.4043105 2,12.5 C2,11.5956895 2.68392381,10.837878 3.58349643,10.7454306 L13.4253624,9.734 L13.4268015,6.62461436 C13.4268015,5.179067 14.7105935,4.5321556 15.7740438,5.37517659 Z M20.422967,12.4507799 L15.426,7.776 L15.4245272,11.539083 L6.07,12.5 L15.4245252,13.4609168 L15.425,17.219 L20.384,12.57 L20.435,12.517 L20.4445699,12.5009252 C20.4463657,12.4928421 20.4455008,12.4846253 20.4420441,12.4762695 L20.422967,12.4507799 Z" id="Path" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>

<ng-template #deleteIconTemplate>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M18,7 L18,18.2857143 C18,19.7746499 16.8086874,21 15.3333333,21 L15.3333333,21 L8.66666667,21 C7.19131256,21 6,19.7746499 6,18.2857143 L6,18.2857143 L6,7 L18,7 Z M16,9 L8,9 L8,18.2857143 C8,18.649428 8.25938319,18.9465317 8.57834889,18.9935231 L8.57834889,18.9935231 L8.66666667,19 L15.3333333,19 C15.6913126,19 16,18.6824929 16,18.2857143 L16,18.2857143 L16,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>

<ng-template #authorDefaultAvatar>
  <svg width="20px" height="20px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <circle id="path-1" cx="18" cy="18" r="18"></circle>
    </defs>
    <g id="Avatar/empty-user" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-1"></use>
      </mask>
      <use id="Oval" fill="#F3F5F6" xlink:href="#path-1"></use>
      <path d="M36,37 L0,37 L0,30.1780432 C3.2398217,28.5963644 5.99816262,27.805525 8.27502276,27.805525 C11.690313,27.805525 13.0748922,27.1374703 13.2178223,24.5298771 C13.3607523,21.9222839 12.822374,21.5330938 12.5966535,20.8356024 C12.4461732,20.370608 12.2937219,19.7697192 12.1392996,19.0329357 C11.4805779,19.1711696 11.2062395,18.7283463 10.7091802,18.0197875 C10.2121209,17.3112288 9.98978676,15.331644 10.0591259,14.7563247 C10.105352,14.3727785 10.3220367,14.2431842 10.7091802,14.3675419 L11.0992128,14.4971361 C10.4058215,12.8556087 10.3624846,11.3906596 10.7091802,8.71237798 C10.9196899,7.08615503 12.4572745,7.57534452 12.1392996,6.20310265 C12.5553344,5.32186159 15.0194139,4 18.1961696,4 C20.4657938,4.06333384 22.4338368,5.14328611 24.1002986,7.23985683 C24.8976986,8.43212415 25.1577203,10.1600478 24.8803638,12.4236278 L24.3603204,14.4971361 L24.7503529,14.3675419 C25.0434747,14.2281996 25.2601595,14.3577939 25.4004072,14.7563247 C25.6107788,15.3541209 25.062379,18.2208116 23.9702878,19.0329357 L23.3202335,19.4217185 C23.305612,19.8171855 23.1653132,20.4126049 22.8993371,21.2079766 C22.6333609,22.0033483 22.4269641,22.4446832 22.2801466,22.5319811 C22.0681715,23.2540277 22.1288566,24.0623471 22.4622019,24.9569394 C22.9622198,26.2988278 23.6222399,27.9914894 28.4204307,28.4945079 C31.6192246,28.8298536 34.1457477,29.6868331 36,31.0654465 L36,37 Z" id="Path" fill="#C5CBD1" fill-rule="nonzero" mask="url(#mask-2)"></path>
    </g>
  </svg>
</ng-template>

<ng-template #copyLinkIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/content-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#8B96A0">
        <path d="M16,3 C17.1,3 18,3.9 18,5 L18,5 L18,19 C18,20.1 17.1,21 16,21 L16,21 L5,21 C3.9,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.9,3 5,3 L5,3 Z M16,5 L5,5 L5,19 L16,19 L16,5 Z M11.9359236,0 C12.4882083,0 12.9359236,0.44771525 12.9359236,1 C12.9359236,1.51283584 12.5498834,1.93550716 12.0525447,1.99327227 L11.9359236,2 L4.33333333,2 C3.09836327,2 2.08747935,2.95942684 2.00538306,4.17357901 L2,4.33333333 L2,16.0165979 C2,16.5688826 1.55228475,17.0165979 1,17.0165979 C0.487164161,17.0165979 0.0644928393,16.6305577 0.00672773133,16.133219 L0,16.0165979 L0,4.33333333 C0,2.01048865 1.82765421,0.11451548 4.12337942,0.00499702767 L4.33333333,0 L11.9359236,0 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
</ng-template>
<ng-template #openIcon>
  <svg width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m7 9 5 5 5-5z" fill="#000" fill-rule="evenodd"/>
  </svg>
</ng-template>
