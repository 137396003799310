import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { File } from "../../models/file.model";
import {Action} from "../actions";
import {FilesActionTypes} from "../actions/files";

export interface FilesState extends EntityState<File> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const filesAdapter: EntityAdapter<File> = createEntityAdapter<File>({
  selectId: (file: File) => file.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(c1: File, c2: File): number {
  return new Date(c2.created_on).getTime() - new Date(c1.created_on).getTime();
}

export const initialState: FilesState = filesAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function filesReducer(state: FilesState = initialState, action: Action): FilesState {
  switch (action.type) {
    case FilesActionTypes.FILES_ADD: {
      const newState = filesAdapter.addMany(action.payload, state);
      newState.isLoaded = true;
      const changes = action.payload.map((file: File) => ({ id: file.id, changes: file}));
      return filesAdapter.updateMany(changes, newState);
    }
    case FilesActionTypes.FILES_EDIT: {
      return filesAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }
    default: return state;
  }
}
