import {Injectable} from "@angular/core";
import {SmartObject} from "./smart-objects/smart-objects.service";

@Injectable()

export class KeywordService {

  constructKeywordData(innerText) {
    const data = {
      type: "keyword"
    };
    const element = document.createElement("s");
    element.classList.add("keyword-text");
    Object.entries(data).forEach(entry => {
      element.dataset[entry[0]] = entry[1];
    });
    element.textContent = innerText;
    element.contentEditable = "false";
    return element;
  }

  addKeywordToExistingSmartString(element, oldData) {
    const linkText = element.textContent;

    const newElement = document.createElement("s");
    newElement.dataset["type"] = SmartObject.SMART_OBJECTS;
    newElement.dataset[SmartObject.HIGHLIGHT.toLowerCase()] = "true";

    if (Object.keys(oldData)?.includes("smartLinkData")) {
      newElement.dataset[SmartObject.SMART_LINK.toLowerCase()] = "true";
    }

    if (Object.keys(oldData)?.includes("stickyNotesData")) {
      newElement.setAttribute("data-sticky-note", "true");
    }

    const newData = {
      ...oldData,
      highlightData: {
        name: linkText
      }
    };
    newElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(newData)));
    newElement.contentEditable = "false";
    newElement.textContent = linkText;

    return newElement;
  }

}
