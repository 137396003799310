    import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
    import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
    import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
    import { DefaultCover } from "app/channels/models/default-cover.model";
    import { Subject, take, takeUntil } from "rxjs";
    import { VNCImageCropperComponent, VncLibraryService } from "vnc-library";
    import { Broadcaster } from "../shared/providers";
    import { CommonUtil } from "../utils/common.util";
    import { environment } from "app/environments/environment";
    import { LoggerService } from "app/shared/services/logger.service";
    import { AppService } from "app/shared/services/app.service";
    import { RootState, getAppSettings, getChatBackgrounds } from "app/reducers";
    import { Store } from "@ngrx/store";
    import { TranslateService } from "@ngx-translate/core";
    import { DeleteChatBackgroundImage, SetChatBackgroundImages } from "app/actions/app";

    @Component({
        selector: "vp-chat-avatar-upload-dialog",
        templateUrl: "./chat-upload-avatar.component.html",
        styleUrls: ["./chat-upload-avatar.component.scss"],
        encapsulation: ViewEncapsulation.None
    })
    export class ChatUploadAvatarComponent implements OnInit, OnDestroy {
        @ViewChild(VNCImageCropperComponent) vncImageCropperComponent: VNCImageCropperComponent;
        imageChangedEvent: any = "";
        croppedImage: any = "";
        canvasRotation = 0;
        rotation = 0;
        scale = 0.9;
        containWithinAspectRatio = true;
        showDefaultAvatar: boolean = false;
        oldAvatarUrl: string = "";
        defaultCovers: DefaultCover[] = [];
        private isAlive$ = new Subject<boolean>();
        indexItem: number;
        defaultURL: string = "";
        hideCropper: boolean = false;
        hideRightBar: any;
        isMobileScreen: boolean;
        inputConfig = {
            width: 350,
            height: 350,
        };
        visible: boolean = false;
        externalCover: DefaultCover[] = [];
        externalIndex: number;
        disableButton: boolean = false;

        min: number = 0.5;
        max: number = 1;
        cropperEvent:any;

        constructor(
            private matDialogRef: MatDialogRef<ChatUploadAvatarComponent>,
            private broadcaster: Broadcaster,
            private breakpointObserver: BreakpointObserver,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private changeDetection: ChangeDetectorRef,
            private logger: LoggerService,
            private appService: AppService,
            private store: Store<RootState>,
            private translate: TranslateService,
            private vncLibraryService: VncLibraryService,
        ) {
            this.defaultURL = this.data.defaultURL;
            this.hideRightBar = this.data.hideRightBar;
            this.hideCropper = true;
            this.broadcaster.on("hideChatUploadAvatarComponent").pipe(take(1)).subscribe(() => {
                this.matDialogRef.close();
            });
            this.broadcaster.on("hideCropAvatar").pipe(take(1)).subscribe(() => {
                this.hideCropper = true;
                this.changeDetection.markForCheck();
            });
            this.changeDetection.markForCheck();
        }

        @HostListener("document:click", ["$event"])
        public onClick(event: Event): void {
            const target = event.target as HTMLElement;
            if(target.classList.contains("LyImageCropper-defaultContent-f")) this.selectPhoto();

        }

        @HostListener("window:resize", ["$event"])
        onWindowResize() {
          if (window.innerWidth < 768) {
            this.matDialogRef.close();
          };
        }

        ngOnInit() {
            for (let i = 1; i < 15; i++) {
                const text = i.toString().padStart(3, "0");
                this.defaultCovers.push({
                    name: `default-pattern-sq-${text}.png`,
                    url: CommonUtil.getFullUrl(`/assets/avatar/default-pattern-sq-${text}.png`),
                });
            }
            this.changeDetection.markForCheck();
            this.store.select(getChatBackgrounds).pipe(take(1)).subscribe(backgrounds => {
                this.logger.info("[AVATAR getChatBackgrounds]", backgrounds);
                if (backgrounds && backgrounds.length) {
                    for (const object of backgrounds) {
                        if (object.type === "avatar") {
                            let data = {
                                name: object.name,
                                url: object.src,
                                filename: object.name,
                                base64data: object.src,
                                type: object.type,
                                id: object.id
                            };
                            this.externalCover.push(data);
                        }
                    }
                    this.logger.info("[AVATAR getChatBackgrounds] bg", this.externalCover);
                    this.getProfileAvatar();

                } else {
                    setTimeout(() => {
                        this.visible = true;
                    }, 1000);
                }
            });


            this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
            this.breakpointObserver
            .observe(["(max-width: 767px)"])
            .pipe(takeUntil(this.isAlive$))
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                this.isMobileScreen = true;
                } else {
                    this.isMobileScreen = false;
                }

                setTimeout(() => {
                    this.vncImageCropperComponent.cropper.loadImage({ originalDataURL: this.defaultURL });
                }, 50);
                // setTimeout(() => {
                //     this.visible = true;
                // }, 1000);
            });
            if(environment.isCordova){
                StatusBar.backgroundColorByHexString("#000000");
                StatusBar.styleBlackTranslucent();
            }
        }

        getProfileAvatar() {
            let avatarData:any = {};
            this.store.select(getAppSettings)
            .pipe(takeUntil(this.isAlive$))
            .subscribe(options => {
                this.logger.info("[AppSettings][setupStore][getAppSettings]", options);
                avatarData = options.avtarData;
            });

            this.logger.info("[AppSettings][setupStore][getAppSettings] avatarData" , avatarData, this.data);
            if (this.data && this.data.selectedData && this.data.selectedData.selectedName){
                if (this.data.selectedData.isExternal) {
                    // Find the object with the specified name and its index
                    let nameToFind = this.data.selectedData.selectedName;
                    let foundIndex = this.externalCover.findIndex(obj => obj.name === nameToFind);
                    this.externalIndex = foundIndex;
                    this.selectIndexItem(this.externalIndex, true);
                } else {
                    let nameToFind = this.data.selectedData.selectedName;
                    let foundIndex = this.defaultCovers.findIndex(obj => obj.name === nameToFind);
                    this.indexItem = foundIndex;
                    if(this.indexItem == null || this.indexItem === undefined) return;
                    this.selectIndexItem(this.indexItem);
                }

                setTimeout(() => {
                    if(!this.data.selectedData.position && !this.data.selectedData.scale) return;
                    this.visible = false;
                    this.vncImageCropperComponent.cropper.updatePosition(this.data.selectedData.position.x, this.data.selectedData.position.y);
                    if (this.data.selectedData.scale > 1) this.scale = 1;
                    else this.scale = this.data.selectedData.scale;
                    setTimeout(() => {
                        this.visible = true;
                    }, 1500);
                }, 2500);
            } else {
                if (avatarData) {
                    if (avatarData.isExternal) {
                        let nameToFind = avatarData.selectedName;
                        let foundIndex = this.externalCover.findIndex(obj => obj.name === nameToFind);
                        this.externalIndex = foundIndex;
                        this.selectIndexItem(this.externalIndex, true);

                    } else {
                        let nameToFind = avatarData.selectedName;
                        let foundIndex = this.defaultCovers.findIndex(obj => obj.name === nameToFind);
                        this.indexItem = foundIndex;
                        if (this.indexItem == null || this.indexItem === undefined) return;
                        this.selectIndexItem(this.indexItem);
                    }

                    setTimeout(() => {
                        if (!avatarData.position && !avatarData.scale) return;
                        this.visible = false;
                        this.vncImageCropperComponent.cropper.updatePosition(avatarData.position.x, avatarData.position.y);
                        if (avatarData.scale > 1) this.scale = 1;
                        else this.scale = avatarData.scale;
                        setTimeout(() => {
                            this.visible = true;
                        }, 1500);
                    }, 2500);
                } else {
                    setTimeout(() => {
                        this.visible = true;
                    }, 1500);
                }
            }
        }

        ngOnDestroy() {
            this.isAlive$.next(false);
            this.isAlive$.complete();
            if (environment.isCordova) {
              StatusBar.backgroundColorByHexString("#317bbc");
            }
        }

        close(): void {
            this.matDialogRef.close();
        }


        selectPhoto() {
            if (!!document.getElementById("custom-input-select-avatar")) {
                (<HTMLElement>document.getElementById("custom-input-select-avatar")).click();
            }
        }

        fileChangeEvent(event: any): void {
            this.hideCropper = false;
            this.imageChangedEvent = event;
        }

        imageCropped(event: any) {
            this.cropperEvent = event;
            this.croppedImage = event.dataURL;
            this.updateAvatar();
        }

        selectNewImage($event) {
            // new Start
            if (!$event || !CommonUtil.isImage($event.srcElement.files[0].type)) {
                return;
            }

            let source = $event.srcElement || $event.currentTarget;

            if (source.files && source.files.length > 0) {
                const file = source.files[0];
                const filename = file.name;
                if (this.externalCover.find(background => background.name === filename)) {
                    this.translate.get("FILE_ALREADY_EXISTS", { fileName: filename })
                        .pipe(takeUntil(this.isAlive$))
                        .subscribe(res => {
                            this.vncLibraryService.openSnackBar(res, "close", "", "", 2000, "left", "left", "").subscribe();
                        });
                    return;
                }
            }


            // provide an abilityy to select the same file one more time
            this.logger.info("[Upload profile image source]", source);
            if (source.files && source.files.length > 0) {
            const file = source.files[0];
            const filename = file.name;
            const type = "avatar";

            const reader = new FileReader();

            reader.onload = function (evt) {
                this.logger.info("[SettingsComponent][onload]", evt);
                const base64data = evt.target.result;
                let uploadObj = {
                    filename: filename,
                    type: type,
                    base64data: base64data
                };
                this.logger.info("[SettingsComponent][onload]", uploadObj);
                this.uploadImage(uploadObj);
            }.bind(this);

            reader.readAsDataURL(file);
            };

        }

        uploadImage(uploadObj: any) {
            this.logger.info("[Upload profile image source] [SettingsComponent][uploadImage]", uploadObj);
            this.appService.uploadChatBackgroundImage(uploadObj).subscribe((res: any) => {
                this.logger.info("[Upload profile image source] [SettingsComponent][uploadChatBackgroundImage]", res);
                this.externalCover.push({
                    name: uploadObj.filename,
                    url: uploadObj.base64data,
                    filename: uploadObj.filename,
                    base64data: uploadObj.base64data,
                    type: uploadObj.type,
                    id: res.id
                });

                this.getAllList();
            });
        }

        deleteChatBackgroundImage(id: string, index: number) {
            this.logger.info("[deleteChatBackgroundImage] ", id, this.externalIndex, this.indexItem);
            this.appService.deleteChatBackgroundImage(id).subscribe((res: any) => {
                this.store.dispatch(new DeleteChatBackgroundImage(id));
                if (this.externalIndex >= 0) {
                    this.externalIndex = null;
                    this.hideCropper = true;
                    this.externalCover.splice(index, 1);
                    if (this.indexItem >= 0 ) {
                        return;
                    }
                    this.selectIndexItem(0);
                };
                const externalCovers = this.externalCover.filter(c => c.id !== id);
                this.externalCover = externalCovers;
                this.getAllList();
                this.changeDetection.markForCheck();
            });
        }

    removeExternalAvatar(index) {
        this.externalCover.splice(index, 1);
        this.vncImageCropperComponent.cropper.loadImage({ originalDataURL: null });
        this.hideCropper = true;
    }

    removeSelectedImage() {
        this.hideCropper = true;
        this.vncImageCropperComponent.cropper.loadImage({ originalDataURL: "" });
    }

    cropImage() {
        this.vncImageCropperComponent.cropImage();
    }

    loadedImageForCrop($event) {
        this.hideCropper = false;
        this.min = this.vncImageCropperComponent.cropper.scale;
        let center = 0;
        center = ((1 - this.vncImageCropperComponent.cropper.scale ) / 2 ) + this.vncImageCropperComponent.cropper.scale;
        this.scale = center;
        this.max = 1;
    }

    updateAvatar() {
        if (this.croppedImage) {
            const b64Data = this.croppedImage;
            const photo: any = {
                type: "image/png",
                data: b64Data
            };
            if (this.indexItem == null || this.indexItem === undefined) {
                this.matDialogRef.close({ photo: photo, cropperEvent: this.cropperEvent });
            } else {
                if (this.externalIndex !== null && this.externalIndex !== undefined) {
                    this.cropperEvent.isExternal = true;
                    this.cropperEvent.selectedName = this.externalCover[this.externalIndex].name;
                } else {
                    this.cropperEvent.isExternal = false;
                    this.cropperEvent.selectedName = this.defaultCovers[this.indexItem].name;
                }

                if (this.matDialogRef && this.matDialogRef.close) {
                    this.matDialogRef.close({ photo: photo , cropperEvent: this.cropperEvent });
                } else {
                    this.matDialogRef.close({ photo: photo , cropperEvent: this.cropperEvent});
                }
            }

        } else {
            if (this.matDialogRef && this.matDialogRef.close) {
                this.matDialogRef.close({ photo: {} });
            } else {
                this.matDialogRef.close({ photo: {} });
            }
        }
    }

    selectIndexItem(index: number, external:boolean = false): void {
        this.logger.info("[selectIndexItem] ", index, external, this.defaultCovers, this.externalCover);
        this.hideCropper = false;
        this.externalIndex = null;
        this.indexItem = null;
        this.disableButton = true;
        if (external) this.externalIndex = index;
        else this.indexItem = index;
        setTimeout(() => {
            if (index > -1) {
                if (external) this.defaultURL = CommonUtil.getAttachmentLocalAPIURL(this.externalCover[index].url);
                else this.defaultURL = CommonUtil.getAttachmentLocalAPIURL(this.defaultCovers[index].url);
                this.vncImageCropperComponent.cropper.loadImage({originalDataURL: this.defaultURL});
            }
            this.disableButton = false;
        }, 1000);
        this.changeDetection.markForCheck();
    }

    randomNumber(min, max): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    errorUploadedImage(): void {
        if (!this.isMobileScreen) {
            if (!!this.defaultCovers && this.defaultCovers.length > 0) {
                this.indexItem = this.randomNumber(1, 12);
                this.defaultURL = CommonUtil.getAttachmentLocalAPIURL(this.defaultCovers[this.indexItem].url);
                this.hideCropper = false;
                this.changeDetection.markForCheck();
            } else {
                this.defaultURL = null;
                this.changeDetection.markForCheck();
            }
        } else {
            this.defaultURL = null;
            this.changeDetection.markForCheck();
        }

    }

    getAllList() {
        this.appService.getChatBackgroundCustomImages().subscribe((res: any) => {
            if (res && res.length) {
                res.map(img => {
                    this.appService.getChatBackgroundCustomImageById(img.id).subscribe((res: any) => {
                        this.store.dispatch(new SetChatBackgroundImages({ id: img.id.toString(), name: img.filename, type: img.type, src: res[0].base64data }));
                    });
                });
            }
        });
    }
}
