<vp-topic-list-desktop *ngIf="!isMobileScreen"
                       [topics]="topics"
                       [bulkActionArray]="[]"
                       [unpublishedTopics]="unpublishedTopics"
                       [filteredTopics]="filteredTopics"
                       [topicsGroupedByAuthor]="topicsGroupedByAuthor"
                       [authorsGroup]="authorsGroup"
                       [currentView]="currentView"
                       [heroImageHeight]="heroImageHeight"
                       [wrapperWidth]="wrapperWidth"
                       [isTopicsLoading]="isTopicsLoading"
                       [selectedChannel]="selectedChannel"
                       [isFiltersApplied]="isFiltersApplied"
                       [filteredTopicsLoaded]="filteredTopicsLoaded"
                       [filteredTopicsLoading]="filteredTopicsLoading"
                       [isRightBarExpanded]="isRightBarExpanded"
                       [isLeftBarExpanded]="isLeftBarExpanded"
                       [emptyCards]="emptyCards"
                       [emptyStateBackground]="emptyStateBackground"
                       [emptyStateType]="emptyStateType"
                       [groupBy]="groupBy"
                       [sortBy]="sortBy"
                       (loadMoreTopics)="loadMoreTopics.next($event)"
                       (loadMoreFilteredTopics)="loadMoreFilteredTopics.next($event)"
                       (addFileToTopic)="addFileToTopic.next($event)"></vp-topic-list-desktop>
<vp-topic-list-mobile *ngIf="isMobileScreen"
                      [topics]="topics"
                      [unpublishedTopics]="unpublishedTopics"
                      [filteredTopics]="filteredTopics"
                      [searchedTopics]="searchedTopics"
                      [topicsGroupedByAuthor]="topicsGroupedByAuthor"
                      [authorsGroup]="authorsGroup"
                      [currentView]="currentView"
                      [heroImageHeight]="heroImageHeight"
                      [wrapperWidth]="wrapperWidth"
                      [isTopicsLoading]="isTopicsLoading"
                      [selectedChannel]="selectedChannel"
                      [isFiltersApplied]="isFiltersApplied"
                      [filteredTopicsLoaded]="filteredTopicsLoaded"
                      [filteredTopicsLoading]="filteredTopicsLoading"
                      [isRightBarExpanded]="isRightBarExpanded"
                      [isLeftBarExpanded]="isLeftBarExpanded"
                      [emptyStateType]="emptyStateType"
                      [isDisplayTopicSearch]="isDisplayTopicSearch"
                      [groupBy]="groupBy"
                      [noAccess]="noAccess"
                      [topicSearchControl]="topicSearchControl"
                      [emptyStateTypeNoAccess]="emptyStateTypeNoAccess"
                      (loadMoreTopics)="loadMoreTopics.next($event)"
                      (loadMoreFilteredTopics)="loadMoreFilteredTopics.next($event)"
                      (addFileToTopic)="addFileToTopic.next($event)"
                      (requestAccess)="requestAccess.next()"
                      (swipe)="swipe.next($event)"></vp-topic-list-mobile>
