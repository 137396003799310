import {Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RelatedTopicsPopupComponent} from "./related-topics-popup/related-topics-popup.component";
import {RelatedTopicPreviewComponent} from "./related-topic-preview/related-topic-preview.component";
import {ChannelRepository} from "../../../channels/repository/channel.repository";
import {take} from "rxjs/operators";
import {DefaultCover} from "../../../channels/models/default-cover.model";
import {CommonUtil} from "app/talk/utils/common.util";

@Injectable()

export class RelatedTopicsService {
  constructor(
    private _dialog: MatDialog,
    private _channelRepository: ChannelRepository
  ) {
  }

  getRelatedTopicsPopupRef(clientX, clientY, alreadySelectedTopics = [], topicId?: any): MatDialogRef<RelatedTopicsPopupComponent> {
    return this._dialog.open(RelatedTopicsPopupComponent, {
      width: "720px",
      height: "fit-content",
      backdropClass: "transparent-backdrop",
      panelClass: "related-topics-list-dialog-container",
      data: { alreadySelectedTopics, topicId: !!topicId ? Number(topicId) : null },
      position: this.getPopupPosition({ width: 720, height: 420 }, clientX, clientY)
    });
  }

  getRelatedTopicPreviewRef(clientX, clientY, topic, shouldNavigate = true): MatDialogRef<RelatedTopicPreviewComponent> {
    return this._dialog.open(RelatedTopicPreviewComponent, {
      width: "720px",
      height: "fit-content",
      backdropClass: "transparent-backdrop",
      panelClass: "related-topics-preview-dialog-container",
      data: { previewData: {topicData: this.getMappedRelatedTopicPreviewData(topic)}, shouldNavigate },
      autoFocus: false,
      position: this.getPopupPosition({ width: 760, height: 300 }, clientX, clientY)
    });
  }

  getPopupPosition(boxDimensions: { width: number, height: number}, left = 0, top = 0) {
    const totalWidth = document.body.offsetWidth;
    const totalHeight = document.body.offsetHeight;
    const right = totalWidth - left;
    const bottom = totalHeight - top;

    const position = {};
    if (left < totalWidth * .5) {
      const canFitHorizontally = left + boxDimensions.width < totalWidth;
      position["left"] = (canFitHorizontally ? left : left - (left + boxDimensions.width - totalWidth)) + "px";
    } else {
      const canFitHorizontally = right + boxDimensions.width < totalWidth;
      position["right"] = (canFitHorizontally ? right : right - (right + boxDimensions.width - totalWidth)) + "px";
    }
    if (top < totalHeight * .5) {
      const canFitVertically = top + boxDimensions.height < totalHeight;
      position["top"] = (canFitVertically ? top + 20 : top - (top + boxDimensions.height - totalHeight)) + "px";
    } else {
      const canFitVertically = bottom + boxDimensions.height < totalHeight;
      position["bottom"] = (canFitVertically ? bottom : bottom - (bottom + boxDimensions.height - totalHeight)) + "px";
    }
    return position;
  }

  getMappedRelatedTopicData(relatedTopics) {
    return relatedTopics?.map(topic => {
      return {
        id: topic?.id,
        is_private: topic?.is_private || "",
        author: topic?.author || {},
        channel_name: topic?.channel_name || "",
        channel_id: topic?.channel_id || "",
        topic_name: topic?.subject || topic?.topic_name || "",
        author_name: topic?.author?.name || "",
        default_cover: topic?.default_cover || "",
        thumbnail_url: CommonUtil.getAttachmentLocalAPIURL(topic?.attachments?.filter(a => a?.is_header || a?.is_default_thumbnail)?.[0]?.thumbnail_url) || topic?.default_cover_url || this.getDefaultCover(topic?.default_cover || null),
        default_cover_url: this.getDefaultCover(topic?.default_cover || null),
        full_data: topic,
        deleted: topic?.deleted,
      };
    });
  }

  getMappedRelatedTopicPreviewData(relatedTopic) {
    return {
      id: relatedTopic?.id,
      channel_id: relatedTopic?.channel_id,
      subject: relatedTopic?.subject,
      description: relatedTopic?.description || relatedTopic?.description_plain,
      author: relatedTopic?.author,
      unread_comments_count: relatedTopic?.unread_comments_count,
      heroAttachmentThumbnailUrl: relatedTopic?.thumbnail_url || CommonUtil.getAttachmentLocalAPIURL(relatedTopic?.attachments?.filter(a => a?.is_header || a?.is_default_thumbnail)?.[0]?.thumbnail_url) || relatedTopic?.default_cover_url || this.getDefaultCover(relatedTopic?.default_cover || null),
      default_cover_url: this.getDefaultCover(relatedTopic?.default_cover || null),
      full_data: {...relatedTopic}
    };
  }

  copyTopicLink(item: any) {
    this._channelRepository.copyLink("topic", item.channel_id, item.id);
  }

  private getDefaultCover(coverName?: string) {
    let defaultCover: DefaultCover;
    if (!!coverName) {
      this._channelRepository.getDefaultCoverByCoverName(coverName).pipe(take(1)).subscribe(res => defaultCover = res?.[0]);
    } else {
      this._channelRepository.getDefaultCovers().pipe(take(1)).subscribe(res => defaultCover = res?.[0]);
    }
    return defaultCover?.url;
  }

  async getTopic(topicId) {
    return await this._channelRepository.selectTopicDetail(topicId).pipe(take(1)).toPromise();
  }
}
