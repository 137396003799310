
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { ConfigService } from "./config.service";
import { UpdateNetworkInformation } from "./actions/app";
import { TalkRootState } from "./talk/reducers";
import { Store } from "@ngrx/store";
import { CommonUtil } from "./talk/utils/common.util";
import { ConstantsUtil } from "./talk/utils/constants.util";
import { environment } from "./environments/environment";
import { normalizeCommonJSImport } from "./talk/utils/normalize-common-js-import";
import { LoggerService } from "./shared/services/logger.service";

@Component({
  selector: "vp-app",
  template: `
    <router-outlet></router-outlet>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private config: ConfigService,
              private renderer: Renderer2,
              private store: Store<TalkRootState>,
              private logger: LoggerService) {
                this.logger.info("[AppComponent][constructor]", environment);
              if (navigator && navigator.splashscreen) {
                navigator.splashscreen.hide();
                this.logger.info("[AppComponent][constructor] hide splashscreen");
              }
  }


  async ngOnInit() {
    if (environment.isCordova) {

      document.addEventListener("deviceready", () => {
        if (navigator && navigator.splashscreen) {
          navigator.splashscreen.hide();
          this.logger.info("[AppComponent][constructor] hide splashscreen");
        }
        if (CommonUtil.isOnIOS()){
          try {
            StatusBar.show();
          } catch (error) {

          }
          const currentName = localStorage.getItem(ConstantsUtil.THEME) || environment.theme;
          try {
            if (currentName === "ekbo") {
              if (CommonUtil.isOnAndroid()) {
                StatusBar.backgroundColorByHexString("#6633cc");
                StatusBar.styleLightContent();
              }
            } else if (currentName === "airbus") {
              if (CommonUtil.isOnAndroid()) {
                StatusBar.backgroundColorByHexString("#00205b");
                StatusBar.styleLightContent();
              }
            } else if (currentName === "hin") {
              if (CommonUtil.isOnAndroid()) {
                StatusBar.backgroundColorByHexString("#f39900");
                StatusBar.styleBlackTranslucent();
              }
            } else {
              if (CommonUtil.isOnAndroid()) {
                StatusBar.backgroundColorByHexString("#317bbc");
                StatusBar.styleLightContent();
              }
            }
          } catch (error) {

          }

        }
      }, false);
    }
    const QuillNamespace = await normalizeCommonJSImport(import("quill"));
    const Quill: any = QuillNamespace;
    let Link = Quill.import("formats/link");
    let builtInFunc = Link.sanitize;
    Link.sanitize =  function customSanitizeLinkInput(linkValueInput) {
        let val = linkValueInput;
        // do nothing, since this implies user's already using a custom protocol
        if (/^\w+:/.test(val)) {

        } else if (!/^https?:/.test(val)) {
          val = "http://" + val;
        }
        return builtInFunc.call(this, val); // retain the built-in logic
    };
    this.renderer.listen("document", "deviceready", this.onDeviceReady);

    document.addEventListener("resume", () => {
      this.logger.info("[AppComponent][resume]", navigator.connection, navigator.onLine);
    });
    // network changes
    // Observable.merge(
    //   Observable.fromEvent(window, "online").mapTo(true),
    //   Observable.fromEvent(window, "offline").mapTo(false)
    // ).subscribe(this.notifyConnectionStatus.bind(this));

    document.addEventListener("cordovacallbackerror", event => {
      this.logger.sentryErrorLog("[AppComponent][cordovacallbackerror]", event);
    });

  }

  ngOnDestroy() {
    this.logger.info("[AppComponent][ngOnDestroy]");
  }

  private notifyConnectionStatus(isConnected: boolean) {
    let connectionType = "unknown";
    if (navigator.connection) {
      connectionType = navigator.connection.type ? navigator.connection.type : navigator.connection.effectiveType || "unknown";
    }
    this.logger.info("[AppComponent][notifyConnectionStatus] isOnline: ", isConnected, this.store);
    /**  */

    this.store.dispatch(new UpdateNetworkInformation({
      onlineState: isConnected,
      connectionType: connectionType
    }));
  }

  onDeviceReady() {
    if (window["cordova"].InAppBrowser) {
      window.open = window["cordova"].InAppBrowser.open;
    }
  }
}
