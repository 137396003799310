<div class="user-select-wrapper">
  <vnc-dialog [classes]="['mobile-editor-channel-dialog']" [headerTemplate]="headerTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="bodyTemplate">
  </vnc-dialog>

  <ng-template #headerTemplate>
    <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{headerLabel}}"
                       (darkSuffixButtonPress)="selectUsers()" [darkSuffixIcon]="'mdi-check'" (darkBackPress)="closeDialog()">
    </vnc-mobile-header>
  </ng-template>


  <ng-template #bodyTemplate>
    <div class="user-search">
      <vnc-mobile-input [placeholder]="'SEARCH' | translate"
                        [showSearchIcon]="true"
                        [searchForm]="smartLinkFiltersService.userSearchControl"></vnc-mobile-input>
    </div>
    <div *ngIf="data?.type.includes('AUTHOR') || data?.type.includes('USER')" class="search-results">
      <div *ngFor="let user of (smartLinkFiltersService.$userOptions | async)" class="user-wrapper">
        <div class="left-side">
          <vnc-avatar [imgSrc]="user?.imgSrc" [customSize]="'48px'"></vnc-avatar>
          <span class="user-name">{{user?.label}}</span>
        </div>
        <vnc-checkbox [isRoundCheckBox]="true"
                      (change)="handleCheckboxClick($event, user)"
                      [checked]="isUserChecked(user)"
        ></vnc-checkbox>
      </div>
    </div>
    <div *ngIf="data?.type.includes('TOPIC')" class="search-results">
      <div *ngFor="let topic of (smartLinkFiltersService.$topicOptions | async)" class="user-wrapper">
        <div class="left-side">
          <vnc-avatar [imgSrc]="topic?.imgSrc" [customSize]="'48px'"></vnc-avatar>
          <span class="user-name">{{topic?.label}}</span>
        </div>
        <vnc-checkbox [isRoundCheckBox]="true"
                      (change)="handleCheckboxClick($event, topic)"
                      [checked]="isUserChecked(topic)"
        ></vnc-checkbox>
      </div>
    </div>
    <div *ngIf="data?.type.includes('CHANNEL')" class="search-results">
      <div *ngFor="let channel of (smartLinkFiltersService.$channelOptions | async)" class="user-wrapper">
        <div class="left-side">
          <vnc-avatar [imgSrc]="channel?.imgSrc" [customSize]="'48px'"></vnc-avatar>
          <span class="user-name">{{channel?.label}}</span>
        </div>
        <vnc-checkbox [isRoundCheckBox]="true"
                      (change)="handleCheckboxClick($event, channel)"
                      [checked]="isUserChecked(channel)"
        ></vnc-checkbox>
      </div>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
  </ng-template>
</div>

