import { Action } from ".";
import { Topic } from "app/channels/models/topic.model";

export class SubscribeTopicActionTypes {
  static SUBSCRIBE_TOPIC_LOAD_REQUEST = "[SubscribeTopic] Load Request";
  static SUBSCRIBE_TOPICLOAD_SUCCESS = "[SubscribeTopic] Load Success";
  static SUBSCRIBE_TOPIC_ADD = "[SubscribeTopic] Add";
  static SUBSCRIBE_TOPIC_DELETE = "[SubscribeTopic] Delete";
  static SUBSCRIBE_ALL_TOPICS_INFO_UPDATE = "[SubscribeTopic] All topic info update";
}

export class SubsribeTopicLoadRequest implements Action {
  readonly type = SubscribeTopicActionTypes.SUBSCRIBE_TOPIC_LOAD_REQUEST;
}

export class SubsribeTopicLoadSuccess implements Action {
  readonly type = SubscribeTopicActionTypes.SUBSCRIBE_TOPICLOAD_SUCCESS;
  constructor(public payload: any[]) {
  }
}

export class SubsribeTopicAdd implements Action {
  readonly type = SubscribeTopicActionTypes.SUBSCRIBE_TOPIC_ADD;
  constructor(public payload: Topic) {
  }
}

export class SubsribeTopicDelete implements Action {
  readonly type = SubscribeTopicActionTypes.SUBSCRIBE_TOPIC_DELETE;
  constructor(public payload: string) {
  }
}

export class AllSubscribeTopicsInfoUpdate implements Action {
  readonly type = SubscribeTopicActionTypes.SUBSCRIBE_ALL_TOPICS_INFO_UPDATE;
  constructor(public payload: {offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean}) {
  }
}
