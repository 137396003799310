
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";

@Injectable()
export class OverlayService {

  public hideParam = {display: false, zIndex: 0};

  public visibiltySource = new BehaviorSubject<{display: boolean, zIndex?: number}>(this.hideParam);
  // Subscribe this to listen for click on overlay
  public overlayClickSource = new Subject<true>();

  public visibiltyChanged$ = this.visibiltySource.asObservable();

  show(zIndex: number = 1) {
    this.visibiltySource.next({display: true, zIndex: zIndex});
  }

  hide() {
    this.visibiltySource.next(this.hideParam);
  }

}
