
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { getUnreadNotificationCount } from "app/channels/store/reducers";
import { getIsAppBootstrapped } from "app/reducers";
import { TalkRootState } from "app/talk/reducers";
import { Broadcaster } from "app/talk/shared/providers";
import { CommonUtil } from "app/talk/utils/common.util";
import { Subject, takeUntil, distinctUntilChanged } from "rxjs";

@Component({
    selector: "vp-mobile-notification-center",
    templateUrl: "./mobile-notification-center.component.html",
    styleUrls: ["./mobile-notification-center.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VNCMobileNotificationCenterComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    notificationCount: any = "0";
    bootstrapped: boolean = false;
    constructor(
        private dialogRef: MatDialogRef<VNCMobileNotificationCenterComponent>,
        private store: Store<TalkRootState>,
        private changeDetectorRef: ChangeDetectorRef,
        private broadcasts: Broadcaster,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
      this.broadcasts.on<any>("hideNotificationCenter").pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.dialogRef.close();
      });
      this.store.select(getIsAppBootstrapped).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(v => {
        if (!this.bootstrapped) {
          if (!!v) {
            this.bootstrapped = true;
            this.store.select(getUnreadNotificationCount).pipe(takeUntil(this.isAlive$)).subscribe( count => {
              this.notificationCount = count?.toString();
              this.changeDetectorRef.markForCheck();
            });
          }
        }
      });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }

    async settingClick() {
        if (CommonUtil.isMobileSize()) {
          const { VNCSettingsComponent } = await import(
            "../settings");
            this.matDialog.open(VNCSettingsComponent, {
                width: "100vw",
                maxWidth: "100vw",
                height: "100vh",
                maxHeight: "100vh",
                backdropClass: "mobile-app-settings-backdrop",
                panelClass: "mobile-app-settings-dialog-panel",
                disableClose: true,
                autoFocus: true,
                data: {selectedTab: "notificationSettings", mobileSettings: true}
              });
        } else {
          const { VNCSettingsComponent } = await import(
            "../settings");
            this.matDialog.open(VNCSettingsComponent, {
                maxWidth: CommonUtil.isOnIpad() ? "95vw" : "80vw",
                width: "860px",
                height: "720px",
                maxHeight: "95%",
                backdropClass: "vnctalk-form-backdrop",
                panelClass: "vnctalk-form-panel",
                disableClose: true,
                autoFocus: true,
                data: {selectedTab: "notificationSettings"}
            });
        }

    }

    listClick(): void {
        this.broadcasts.broadcast("NOTIFICATION_PANEL_CLICK", "list");
    }
}
