/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import { Action } from "app/actions";
import { SocialProfile, USER_POST_TYPE } from "app/shared/models/social-profile.model";
import {ProfileActionTypes} from "../actions/profile";

export interface ProfileState extends EntityState<SocialProfile> {
  isLoading: boolean;
  isLoaded: boolean;
  selectedUserJid: string;
  allPosts: { [jid: string]: { ids: number[], offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  replyPosts: { [jid: string]: { ids: number[], offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  mediaPosts: { [jid: string]: { ids: number[], offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  likedPosts: { [jid: string]: { ids: number[], offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  followers: { [jid: string]: { ids: number[],  isLoading: boolean, isLoaded: boolean, total_count?: number, offset: number }},
  following: { [jid: string]: { ids: number[],  isLoading: boolean, isLoaded: boolean, total_count?: number, offset: number }},
  userFilteredPosts: {
    mediaPosts: {
      [filter: string]: {ids: number[],  isLoading: boolean, isLoaded: boolean, total_count?: number, offset?: number}
    },
    allPosts: {
      [filter: string]: {ids: number[],  isLoading: boolean, isLoaded: boolean, total_count?: number, offset?: number}
    },
    replyPosts: {
      [filter: string]: {ids: number[],  isLoading: boolean, isLoaded: boolean, total_count?: number, offset?: number}
    },
  }
}

export const profileAdapter: EntityAdapter<SocialProfile> = createEntityAdapter<SocialProfile>({
  selectId: (profile: SocialProfile) => profile.jid,
});

export const initialState: ProfileState = profileAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedUserJid: null,
  allPosts: null,
  replyPosts: null,
  mediaPosts: null,
  likedPosts: null,
  followers: null,
  following: null,
  userFilteredPosts: {
    mediaPosts: null,
    replyPosts: null,
    allPosts: null
  }
});

export function profileReducer(state: ProfileState = initialState, action: Action): ProfileState {
  switch (action.type) {

    case ProfileActionTypes.PROFILE_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProfileActionTypes.PROFILE_LOAD_SUCCESS: {
      const newState = profileAdapter.addOne(action.payload, {...state, isLoading: false});
      return profileAdapter.updateOne({ id: action.payload.jid, changes: action.payload }, newState);
    }

    case ProfileActionTypes.PROFILE_UPDATE: {
      return profileAdapter.updateOne({ id: action.payload.jid, changes: action.payload }, state);
    }

    case ProfileActionTypes.SELECTED_PROFILE: {
      return {
        ...state,
        selectedUserJid: action.payload
      };
    }

    case ProfileActionTypes.PROFILE_POSTS_LOAD_REQUEST: {
      const postType = action.payload.type;
      const userJID = action.payload.userJID;
      let newState = state;
      if (postType === USER_POST_TYPE.ALL) {
        const posts = {...state.allPosts};
        updatePostLoadingState(posts, userJID);
        newState = {...newState, allPosts: posts};
      } else if (postType === USER_POST_TYPE.REPLIES) {
        const posts = {...state.replyPosts};
        updatePostLoadingState(posts, userJID);
        newState = {...newState, replyPosts: posts};
      } else if (postType === USER_POST_TYPE.MEDIA) {
        const posts = {...state.mediaPosts};
        updatePostLoadingState(posts, userJID);
        newState = {...newState, mediaPosts: posts};
      } else if (postType === USER_POST_TYPE.LIKE) {
        const posts = {...state.likedPosts};
        if (posts[userJID]) {
          posts[userJID] = {...posts[userJID], isLoading: true};
        } else {
          posts[userJID] = {isLoading: true, isLoaded: false, ids: []};
        }
        newState = {...newState, likedPosts: posts};
      }
      return newState;
    }

    case ProfileActionTypes.PROFILE_POSTS_LOAD_SUCCESS: {
      const postType = action.payload.type;
      let newState = state;
      if (postType === USER_POST_TYPE.ALL) {
        const posts = {...state.allPosts};
        updatePostState(posts, action.payload);
        newState = {...newState, allPosts: posts};
      } else if (postType === USER_POST_TYPE.REPLIES) {
        const posts = {...state.replyPosts};
        updatePostState(posts, action.payload);
        newState = {...newState, replyPosts: posts};
      } else if (postType === USER_POST_TYPE.MEDIA) {
        const posts = {...state.mediaPosts};
        updatePostState(posts, action.payload);
        newState = {...newState, mediaPosts: posts};
      } else if (postType === USER_POST_TYPE.LIKE) {
        const posts = {...state.likedPosts};
        updatePostState(posts, action.payload);
        newState = {...newState, likedPosts: posts};
      }
      return newState;
    }

    case ProfileActionTypes.FILTERED_POSTS_LOAD_REQUEST: {
      const postType = action.payload.type;
      const filter = action.payload.filter;
      let newState = state;
      if (postType === USER_POST_TYPE.ALL) {
        const posts = {...state.userFilteredPosts.allPosts};
        updateFilteredPostLoadingState(posts, filter);
        newState = {...newState, userFilteredPosts: {...newState?.userFilteredPosts, allPosts: posts}};
      } else if (postType === USER_POST_TYPE.REPLIES) {
        const posts = {...state.userFilteredPosts.replyPosts};
        updateFilteredPostLoadingState(posts, filter);
        newState = {...newState, userFilteredPosts: {...newState?.userFilteredPosts, replyPosts: posts}};
      } else if (postType === USER_POST_TYPE.MEDIA) {
        const posts = {...state.userFilteredPosts.mediaPosts};
        updateFilteredPostLoadingState(posts, filter);
        newState = {...newState, userFilteredPosts: {...newState?.userFilteredPosts, mediaPosts: posts}};
      }
      return newState;
    }

    case ProfileActionTypes.FILTERED_POSTS_LOAD_SUCCESS: {
      const postType = action.payload.type;
      let newState = state;
      if (postType === USER_POST_TYPE.ALL) {
        const posts = {...state.userFilteredPosts.allPosts};
        updateFilteredPostState(posts, action.payload);
        newState = {...newState, userFilteredPosts: {...newState?.userFilteredPosts, allPosts: posts}};
      } else if (postType === USER_POST_TYPE.REPLIES) {
        const posts = {...state.userFilteredPosts.replyPosts};
        updateFilteredPostState(posts, action.payload);
        newState = {...newState, userFilteredPosts: {...newState?.userFilteredPosts, replyPosts: posts}};
      } else if (postType === USER_POST_TYPE.MEDIA) {
        const posts = {...state.userFilteredPosts.mediaPosts};
        updateFilteredPostState(posts, action.payload);
        newState = {...newState, userFilteredPosts: {...newState?.userFilteredPosts, mediaPosts: posts}};
      }
      return newState;
    }

    case ProfileActionTypes.PROFILE_POST_ADD: {
      const postType = action.payload.type;
      let newState = state;
      if (postType === USER_POST_TYPE.ALL) {
        const posts = {...state.allPosts};
        addPostIdInState(posts, action.payload);
        newState = {...newState, allPosts: posts};
      } else if (postType === USER_POST_TYPE.REPLIES) {
        const posts = {...state.replyPosts};
        addPostIdInState(posts, action.payload);
        newState = {...newState, replyPosts: posts};
      } else if (postType === USER_POST_TYPE.MEDIA) {
        const posts = {...state.mediaPosts};
        addPostIdInState(posts, action.payload);
        newState = {...newState, mediaPosts: posts};
      } else if (postType === USER_POST_TYPE.LIKE) {
        const posts = {...state.likedPosts};
        addPostIdInState(posts, action.payload);
        newState = {...newState, likedPosts: posts};
      }
      return newState;
    }

    case ProfileActionTypes.PROFILE_POST_REMOVE: {
      const postType = action.payload.type;
      let newState = state;
      if (postType === USER_POST_TYPE.ALL) {
        const posts = {...state.allPosts};
        removePostIdInState(posts, action.payload);
        newState = {...newState, allPosts: posts};
      } else if (postType === USER_POST_TYPE.REPLIES) {
        const posts = {...state.replyPosts};
        removePostIdInState(posts, action.payload);
        newState = {...newState, replyPosts: posts};
      } else if (postType === USER_POST_TYPE.MEDIA) {
        const posts = {...state.mediaPosts};
        removePostIdInState(posts, action.payload);
        newState = {...newState, mediaPosts: posts};
      } else if (postType === USER_POST_TYPE.LIKE) {
        const posts = {...state.likedPosts};
        removePostIdInState(posts, action.payload);
        newState = {...newState, likedPosts: posts};
      }
      return newState;
    }

    case ProfileActionTypes.PROFILE_FOLLOWERS_LOAD_REQUEST: {
      return {
        ...state,
        followers: { ...state.followers, [action.payload]: { ids: [], isLoading: true, isLoaded: false, offset: 0, totalCount: 0 }}
      };
    }

    case ProfileActionTypes.PROFILE_FOLLOWERS_LOAD_SUCCESS: {
      const followers = {...state.followers};
      addFollowersInState(followers, action.payload);
      return {...state, followers};
    }

    case ProfileActionTypes.PROFILE_FOLLOWERS_ADD_ONE: {
      const followers = {...state.followers};
      addOneFollowerInState(followers, action.payload);
      return {...state, followers};
    }

    case ProfileActionTypes.PROFILE_FOLLOWERS_REMOVE_ONE: {
      const followers = {...state.followers};
      removeOneFollowerInState(followers, action.payload);
      return {...state, followers};
    }

    case ProfileActionTypes.PROFILE_FOLLOWING_LOAD_REQUEST: {
      return {
        ...state,
        following: { ...state.following, [action.payload]: { ids: [], isLoading: true, isLoaded: false, offset: 0, totalCount: 0 }}
      };
    }

    case ProfileActionTypes.PROFILE_FOLLOWING_LOAD_SUCCESS: {
      const following = {...state.following};
      addFollowersInState(following, action.payload);
      return {...state, following: following};
    }

    case ProfileActionTypes.PROFILE_FOLLOWING_ADD_ONE: {
      const following = {...state.following};
      addOneFollowerInState(following, action.payload);
      return {...state, following};
    }

    case ProfileActionTypes.PROFILE_FOLLOWING_REMOVE_ONE: {
      const following = {...state.following};
      removeOneFollowerInState(following, action.payload);
      return {...state, following};
    }

    default: {
      return state;
    }

  }
}

function updatePostLoadingState(posts: any, userJID: string) {
  if (posts[userJID]) {
    posts[userJID] = {...posts[userJID], isLoading: true};
  } else {
    posts[userJID] = {isLoading: true, isLoaded: false, ids: []};
  }
}

function updateFilteredPostLoadingState(posts: any, filter: string) {
  if (posts[filter]) {
    posts[filter] = {...posts[filter], isLoading: true};
  } else {
    posts[filter] = {isLoading: true, isLoaded: false, ids: []};
  }
}

function updatePostState(posts: any, data: any) {
  const offset = data.offset;
  const total_count = data.total_count;
  const postIds = data.posts.map(v => v.id);
  const userJID = data.userJID;
  if (posts[userJID]) {
    const ids = [...posts[userJID].ids, ...postIds];
    posts[userJID] = {...posts[userJID], isLoading: false, isLoaded: true, offset, total_count, ids};
  } else {
    const ids = [...posts[userJID].ids, ...postIds];
    posts[userJID] = {isLoading: false, isLoaded: true, ids, offset, total_count,};
  }
}

function updateFilteredPostState(posts: any, data: any) {
  const offset = data.offset;
  const total_count = data.total_count;
  const postIds = data.posts.map(v => v.id);
  const filter = data.filter;
  if (posts?.[filter]) {
    const ids = [...posts?.[filter]?.ids, ...postIds];
    posts[filter] = {...posts[filter], isLoading: false, isLoaded: true, offset, total_count, ids};
  } else {
    const ids = [...posts?.[filter]?.ids, ...postIds];
    posts[filter] = {isLoading: false, isLoaded: true, ids, offset, total_count,};
  }
}

function addPostIdInState(posts: any, data: any) {
  const postId = data.postId;
  const userJID = data.userJID;
  if (posts[userJID]) {
    const postData = posts[userJID];
    const oldIds = [...posts[userJID].ids];
    if (!oldIds.includes(postId)) {
      const offset = postData.offset + 1;
      const total_count = postData.total_count + 1;
      const ids = [postId, ...oldIds];
      posts[userJID] = {...posts[userJID], isLoading: false, isLoaded: true, offset, total_count, ids};
    }
  }
}

function removePostIdInState(posts: any, data: any) {
  const postId = data.postId;
  const userJID = data.userJID;
  if (posts[userJID]) {
    const postData = posts[userJID];
    const oldIds = [...posts[userJID].ids];
    if (oldIds.includes(postId)) {
      const offset = postData.offset - 1;
      const total_count = postData.total_count - 1;
      const ids = [...oldIds].filter(v => v !== postId);
      posts[userJID] = {...posts[userJID], isLoading: false, isLoaded: true, offset, total_count, ids};
    }
  }
}

function addFollowersInState(followers: any, data: any) {
  const jid = data.jid;
  const offset = data.offset;
  const total_count = data.total_count;

  const oldFollowersIds: any[] = followers?.[jid]?.ids || [];
  const newFollowersIds: any[] = (data.users.map(v =>v.id) || [])
    .filter(m => !oldFollowersIds.includes(m));
  const ids = [...oldFollowersIds, ...newFollowersIds];

  if (followers[jid]) {
    followers[jid] = {...followers[jid], isLoading: false, isLoaded: true, offset, total_count, ids};
  } else {
    followers[jid] = {isLoading: false, isLoaded: true, ids, offset, total_count,};
  }
}

function addOneFollowerInState(followers: any, data: any) {
  const jid = data.jid;
  const id = data.id;
  if (followers[jid]) {
    const followersData = followers[jid];
    const oldIds = [...followers[jid].ids];
    if (!oldIds.includes(id)) {
      const offset = followersData.offset + 1;
      const total_count = followersData.total_count + 1;
      const ids = [...oldIds, id];
      followers[jid] = {...followers[jid], isLoading: false, isLoaded: true, offset, total_count, ids};
    }
  }
}

function removeOneFollowerInState(followers: any, data: any) {
  const followerId = data.id;
  const userJID = data.jid;
  if (followers[userJID]) {
    const followersData = followers[userJID];
    const oldIds = [...followers[userJID].ids];
    if (oldIds.includes(followerId)) {
      const offset = followersData.offset - 1;
      const total_count = followersData.total_count - 1;
      const ids = [...oldIds].filter(v => v !== followerId);
      followers[userJID] = {...followers[userJID], isLoading: false, isLoaded: true, offset, total_count, ids};
    }
  }
}

export const _getIsProfileLoading = (state: ProfileState) => state.isLoading;
export const _getIsProfileLoaded = (state: ProfileState) => state.isLoaded;
export const _getSelectedProfileId = (state: ProfileState) => state.selectedUserJid;
export const _getProfileAllPosts = (state: ProfileState) => state.allPosts;
export const _getProfileReplyPosts = (state: ProfileState) => state.replyPosts;
export const _getProfileMediaPosts = (state: ProfileState) => state.mediaPosts;
export const _getProfileLikedPosts = (state: ProfileState) => state.likedPosts;
export const _getProfileFilteredReplyPosts = (state: ProfileState) => state.userFilteredPosts.replyPosts;
export const _getProfileFilteredMediaPosts = (state: ProfileState) => state.userFilteredPosts.mediaPosts;
export const _getProfileFilteredAllPosts = (state: ProfileState) => state.userFilteredPosts.allPosts;
export const _getFollowers = (state: ProfileState) => state.followers;
export const _getFollowings = (state: ProfileState) => state.following;
