
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { catchError, Observable, Subject, take } from "rxjs";
import { ConversationRepository } from "../repositories/conversation.repository";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { CommonUtil } from "../utils/common.util";
import { NotificationService } from "./notification.service";
import { LoggerService } from "app/shared/services/logger.service";
import { Store } from "@ngrx/store";
import { SetMediaSharingStatus } from "app/actions/media";
import { TalkRootState } from "../reducers";

@Injectable()
export class SharingService {

  constructor(
    private conversationRepo: ConversationRepository,
    private notificationService: NotificationService,
    private logger: LoggerService,
    private store: Store<TalkRootState>,
    private http: HttpClient) {

  }

  public getWebDav(authCode: string): Observable<any> {
    const ownCloudURL = localStorage.getItem("ownCloudURL");
    const headers: HttpHeaders = new HttpHeaders({
      "Authorization":  `Basic ${authCode}`
    });
    this.logger.info("webdav: ", ownCloudURL);
    return this.http.request("PROPFIND", ownCloudURL + "remote.php/webdav/", { headers: headers , responseType: "text"}).pipe(catchError(error => error));
  }


  share(photoServerUrl) {
    const response = new Subject<any>();

    this.logger.info("[SharingService][share] photoUrl", photoServerUrl);

    // download file locally first, so on next share it will not be downloaded again & again
    this.conversationRepo.isFileDownloadedToExternalOrGallery(photoServerUrl).subscribe(localPhotoUrl => {
      this.logger.info("[SharingService][share] localPhotoUrl", photoServerUrl, localPhotoUrl);
      if (localPhotoUrl) {
        this.openShareDialog(localPhotoUrl).subscribe({
          next: (res) => {
            response.next(res);
          },
          error: (err) => {
            response.error(err);
          }
        });
      } else {
        this.conversationRepo.downloadFileToExternalOrGallery(photoServerUrl).subscribe(localFileUrl => {
          this.logger.info("[SharingService][share] downloadFileToExternalOrGallery", photoServerUrl, localPhotoUrl);
          this.openShareDialog(localFileUrl).subscribe({
            next: (res) => {
              response.next(res);
            },
            error: (err) => {
              response.error(err);
            }
          });
        }, err => {
          this.logger.error("[SharingService][downloadFileToDownloadsOrGallery] error", err);
          response.error(err);
        });
      }
    });

    return response.asObservable().pipe(take(1));
  }

  private openShareDialog(photoUrl): Observable<any>  {
    const response = new Subject<any>();

    this.logger.info("[SharingService][openShareDialog] photoUrl", photoUrl);

    let options = {
      files: [decodeURI(photoUrl)],
      chooserTitle: "Pick an app",
    };

    this.logger.info("[SharingService][openShareDialog] options", options);

    const onSuccess = (result) => {
      this.logger.info("[SharingService][openShareDialog] shared success", result);
      this.store.dispatch(new SetMediaSharingStatus(false));
      response.next(result);
    };

    const onError = (msg) => {
      this.logger.error("[SharingService][openShareDialog] shared failed", msg);
      this.store.dispatch(new SetMediaSharingStatus(false));
      response.error(msg);
    };

    window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);

    return response.asObservable().pipe(take(1));
  }

  getSnackbarOption() {
    let matbarOption = { duration: 2000, panelClass: "custom-snackbar-bottom-left" };
    if (CommonUtil.isMobileSize()) {
      matbarOption = { duration: 2000, panelClass: "custom-mobile-snackbar" };
    }
    return matbarOption;
  }

  underDevelopment() {
    this.notificationService.openSnackBarWithTranslation("UNDER_DEVELOPMENT");
  }
}
