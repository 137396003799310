
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { map } from "rxjs";
import { ConfigService } from "../../config.service";
import { environment } from "../../environments/environment";

@Injectable()
export class AuthService {
  user: any = {};
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private http: Http, private configService: ConfigService) {
  }

  getProfile() {
    let headers = new Headers({"Content-Type": "application/json"});
    if (this.isCordovaOrElectron) {
      headers = new Headers({"Content-Type": "application/json", "Authorization": localStorage.getItem("token")});
    }
    return this.http.get(this.configService.API_URL + "/api/profile", {headers: headers}).pipe(map(response => {
      return response.json();
    }));
  }

  logout() {
    const callLogout = environment.isElectron ? "./api/call-logout" : "/api/call-logout";
    return this.http.get(this.configService.API_URL + callLogout, {});
  }

  loggedIn() {
    return this.user && Object.keys(this.user).length > 0;
  }
}
