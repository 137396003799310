import { Action } from ".";
import { Notification } from "app/channels/models/notification.model";

export class NotificationActionTypes {
  static NOTIFICATION_LOAD_REQUEST = "[Notification] Load Request";
  static NOTIFICATION_LOAD_SUCCESS = "[Notification] Load Success";

  static NOTIFICATION_ADD = "[Notification] Add";
  static NOTIFICATION_DELETE = "[Notification] Delete";
  static NOTIFICATION_UPDATE = "[Notification] Update";
  static NOTIFICATION_BULK_ADD = "[Notification] Bulk Add";
  static NOTIFICATION_BULK_DELETE = "[Notification] Bulk Delete";
  static NOTIFICATION_INFO_UPDATE = "[Notification] All Notification Info Update";
  static UNREAD_NOTIFICATION_INFO_UPDATE = "[Notification] Unread Notification Info Update";
  static UNREAD_NOTIFICATION_COUNT_UPDATE = "[Notification] Unread Notification count Update";
}

export class NotificationLoadRequest implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_LOAD_REQUEST;
  }



  export class NotificationLoadSuccess implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_LOAD_SUCCESS;
    constructor(public payload: any[]) {
    }
  }



  export class NotificationAdd implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_ADD;
    constructor(public payload: Notification) {
    }
  }



  export class NotificationUpdate implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_UPDATE;
    constructor(public payload: any) {
    }
  }



  export class NotificationDelete implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_DELETE;
    constructor(public payload: string) {
    }
  }

  export class NotificationBulkDelete implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_BULK_DELETE;
    constructor(public payload: string[]) {
    }
  }

  export class NotificationBulkAdd implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_BULK_ADD;
    constructor(public payload: Notification[]) {
    }
  }

  export class NotificationInfoUpdate implements Action {
    readonly type = NotificationActionTypes.NOTIFICATION_INFO_UPDATE;
    constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
    }
  }
  export class UreadNotificationInfoUpdate implements Action {
    readonly type = NotificationActionTypes.UNREAD_NOTIFICATION_INFO_UPDATE;
    constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
    }
  }

  export class UnreadNotificationCountUpdate implements Action {
    readonly type = NotificationActionTypes.UNREAD_NOTIFICATION_COUNT_UPDATE;
    constructor(public payload: number) {
    }
  }
