
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions/index";
import { ConferenceActionTypes } from "../actions/conference";
import { JitsiOption } from "../models/jitsi-participant.model";
import { Recipient } from "../models/conversation.model";

export interface ConferenceState {
  mutedAllVideo?: boolean;
  isFloatingExpanded: boolean;
  uploadedBackground: any[];
  speakingParticipant: any;
  currentView: any;
  audioMuted: boolean;
  videoMuted: boolean;
  sharingScreen: boolean;
  hasWebcam: boolean;
  hasSpeaker: boolean;
  availableMediaDevices: any;
  hasMicrophone: boolean;
  fullScreenParticipantId: string | null;
  selectedParticipantId: string | null;
  participants: any[];
  // sct
  conferenceParticipants: any[];
  invitedParticipants: any[];
  conversationTarget: string;
  conferenceType: string;
  jitsiRoom: JitsiOption;
  conferenceId: string;
  participantEmail: string;
  jitsiOptions: any;
  isLoadingJitsiConfig: any;
  tracks: { [participantId: string]: any[] };
  frontCameraId: string;
  backCameraId: string;
  hasActiveCall: boolean;
  existingConfs: { [target: string]: any[] };
  activeWhiteboard: {
    conversationTarget: string
  };
  startFromInvitation: boolean;
  streamId: string;
  externalParticipants: Recipient[];
  mutedForMe: string[];
  isStartingScreenshare: boolean;
  requestedShare: boolean;
  presenterJid: string;
  presenterId: string;
  e2eeEnabled?: boolean;
  knocking?: boolean;
  knockingParticipants: any[];
  lobbyEnabled?: boolean;
  passwordJoinFailed?: boolean;
  backgroundEffectEnabled?: boolean;
  noiseSuppressionEnabled?: boolean;
  mutedEveryone?: boolean;
  virtualBackground?: any
}

const initialState: ConferenceState = {
  currentView: null,
  audioMuted: false,
  videoMuted: false,
  sharingScreen: false,
  isFloatingExpanded: true,
  hasMicrophone: false,
  hasSpeaker: false,
  hasWebcam: false,
  fullScreenParticipantId: null,
  selectedParticipantId: null,
  participants: [],
  availableMediaDevices: {},
  // sct
  conferenceParticipants: [],
  invitedParticipants: [],
  conversationTarget: null,
  jitsiRoom: null,
  conferenceType: null,
  conferenceId: null,
  jitsiOptions: null,
  isLoadingJitsiConfig: false,
  tracks: {},
  frontCameraId: null,
  backCameraId: null,
  hasActiveCall: false,
  existingConfs: {},
  activeWhiteboard: null,
  startFromInvitation: false,
  streamId: "",
  externalParticipants: [],
  participantEmail: "",
  speakingParticipant: null,
  mutedForMe: [],
  isStartingScreenshare: false,
  presenterJid: null,
  presenterId: null,
  requestedShare: false,
  e2eeEnabled: false,
  knocking: false,
  knockingParticipants: [],
  lobbyEnabled: false,
  passwordJoinFailed: false,
  noiseSuppressionEnabled: false,
  mutedEveryone: false,
  uploadedBackground: []
};

export function conferenceReducer(state: ConferenceState = initialState, action: Action): ConferenceState {
  switch (action.type) {

    case ConferenceActionTypes.MUTE_AUDIO: {
      return {
        ...state,
        audioMuted: true
      };
    }

    case ConferenceActionTypes.UPDATE_LOBBY_STATE: {
      return {
        ...state,
        lobbyEnabled: action.payload
      };
    }

    case ConferenceActionTypes.TOGGLE_MUTE_EVERYONE: {
      return {
        ...state,
        mutedEveryone: action.payload
      };
    }

    case ConferenceActionTypes.TOGGLE_MUTE_CAMERA: {
      return {
        ...state,
        mutedAllVideo: action.payload
      };
    }

    case ConferenceActionTypes.KNOCKING_PARTICIPANT_LEFT: {
      return {
        ...state,
        knockingParticipants: state.knockingParticipants.filter(p => p.id !== action.payload)
      };
    }

    case ConferenceActionTypes.KNOCKING_PARTICIPANT_ARRIVED_OR_UPDATED: {
      const participant = action.payload;
      let existingParticipant = state.knockingParticipants.find(p => p.id === participant.id);
      existingParticipant = {
        ...existingParticipant,
        ...participant
      };
      return {
        ...state,
        knockingParticipants: [
          ...state.knockingParticipants.filter(p => p.id !== participant.id),
          existingParticipant
        ]
      };
    }

    case ConferenceActionTypes.UNMUTE_AUDIO: {
      return {
        ...state,
        audioMuted: false
      };
    }

    case ConferenceActionTypes.TOGGLE_FLOATING_VIDEO: {
      return {
        ...state,
        isFloatingExpanded: action.payload
      };
    }

    case ConferenceActionTypes.TOGGLE_E2EE: {
      return {
        ...state,
        e2eeEnabled: action.payload
      };
    }

    case ConferenceActionTypes.MUTE_VIDEO: {
      return {
        ...state,
        videoMuted: true
      };
    }

    case ConferenceActionTypes.UNMUTE_VIDEO: {
      return {
        ...state,
        videoMuted: false
      };
    }

    case ConferenceActionTypes.SHARE_SCREEN: {
      return {
        ...state,
        sharingScreen: true
      };
    }

    case ConferenceActionTypes.UNSHARE_SCREEN: {
      return {
        ...state,
        sharingScreen: false
      };
    }

    // sct
    case ConferenceActionTypes.SET_AUDIO_STATUS: {
      return {
        ...state,
        conferenceParticipants: state.conferenceParticipants.map(participant => participant.id === action.payload.id ? { ...participant, audioStatus: action.payload.audioStatus } : participant )
      };
    }

    // sct
    case ConferenceActionTypes.SET_VIDEO_STATUS: {
      return {
        ...state,
        conferenceParticipants: state.conferenceParticipants.map(participant => participant.id === action.payload.id ? { ...participant, videoStatus: action.payload.videoStatus } : participant )
      };
    }

    case ConferenceActionTypes.SET_E2EE_IDKEY: {
      return {
        ...state,
        conferenceParticipants: state.conferenceParticipants.map(participant => participant.id === action.payload.id ? { ...participant, e2eeIndexKey: action.payload.idKey } : participant )
      };
    }

    case ConferenceActionTypes.SET_E2EE_STATUS: {
      return {
        ...state,
        conferenceParticipants: state.conferenceParticipants.map(participant => participant.id === action.payload.id ? { ...participant, e2eeEnabled: action.payload.status } : participant )
      };
    }


    case ConferenceActionTypes.SET_FULL_SCREEN_PARTICIPANT: {
      return {
        ...state,
        fullScreenParticipantId: action.payload
      };
    }


    case ConferenceActionTypes.CONFERENCE_START: {
      return {
        ...state,
        conversationTarget: action.payload.conversationTarget,
        startFromInvitation: action.payload.startFromInvitation,
        conferenceType: action.payload.conferenceType,
        invitedParticipants: action.payload.invitedParticipants,
        externalParticipants: action.payload.externalParticipants
      };
    }

    case ConferenceActionTypes.RESET_CONFERENCE: {
      return {
        ...state,
        conversationTarget: null,
        startFromInvitation: false,
        invitedParticipants: [],
        externalParticipants: []
      };
    }

    case ConferenceActionTypes.SET_CONFERENCE_TYPE: {
      return {
        ...state,
        conferenceType: action.payload
      };
    }
    case ConferenceActionTypes.CONFERENCE_SHOW_ACTIVE_CALL: {
      return {
        ...state,
        hasActiveCall: true
      };
    }

    case ConferenceActionTypes.CONFERENCE_HIDE_ACTIVE_CALL: {
      return {
        ...state,
        hasActiveCall: false
      };
    }

    case ConferenceActionTypes.CONFERENCE_SET_EXISTING_CALL: {
      return {
        ...state,
        existingConfs: {
          [action.payload.conversationTarget]: {type: action.payload.conferenceType},
          ...state.existingConfs
        }
      };
    }

    case ConferenceActionTypes.CONFERENCE_RESET_EXISTING_CALL: {
      delete state.existingConfs[action.payload.conversationTarget];
      return {
        ...state,
        existingConfs: {
          ...state.existingConfs
        }
      };
    }

    case ConferenceActionTypes.CONFERENCE_START_SUCCESS: {
      return {
        ...state,
        conferenceId: action.payload.conferenceId,
        participants: action.payload.participants,
        fullScreenParticipantId: action.payload.participants[0].id
      };
    }

    case ConferenceActionTypes.ANONYMOUS_CONFERENCE: {
      return {
        ...state,
        jitsiRoom: action.payload.jitsiRoom,
        conferenceType: "video",
        participantEmail: action.payload.participantEmail
      };
    }

    case ConferenceActionTypes.CONFERENCE_LEAVE_SUCCESS: {
      return {
        ...state,
        audioMuted: false,
        videoMuted: false,
        sharingScreen: false,
        conferenceId: null,
        participants: [],
        fullScreenParticipantId: null,
        conversationTarget: null,
        jitsiRoom: null,
        conferenceType: null
      };
    }

    case ConferenceActionTypes.CONFERENCE_ADD_PARTICIPANT: {
      const diff = state.participants.filter(p => p.id !== action.payload.id);

      return {
        ...state,
        participants: [
          ...diff,
          action.payload
        ]
      };
    }
    case ConferenceActionTypes.CONFERENCE_UNMUTE_PARTICIPANT: {
      const mutedForMe = state.mutedForMe.filter(v => v !== action.payload);
      return {
        ...state,
        mutedForMe: mutedForMe
      };
    }

    case ConferenceActionTypes.CONFERENCE_MUTE_PARTICIPANT: {
      const mutedForMe = state.mutedForMe;
      if (!mutedForMe.includes(action.payload)) {
        mutedForMe.push(action.payload);
      }
      return {
        ...state,
        mutedForMe: mutedForMe
      };
    }

    // sct
    case ConferenceActionTypes.JITSI_CONFERENCE_ADD_PARTICIPANT: {
      const diff = state.conferenceParticipants.filter(p => p.id !== action.payload.id);

      return {
        ...state,
        conferenceParticipants: [
          ...diff,
          action.payload
        ]
      };
    }

    case ConferenceActionTypes.CONFERENCE_REMOVE_PARTICIPANT: {
      const newParticipants = state.participants.filter(p => p.id !== action.payload);
      return {
        ...state,
        participants: newParticipants
      };
    }

    // sct
    case ConferenceActionTypes.JITSI_CONFERENCE_REMOVE_PARTICIPANT: {
      const newParticipants = state.conferenceParticipants.filter(p => p.id !== action.payload);
      return {
        ...state,
        conferenceParticipants: newParticipants
      };
    }

    case ConferenceActionTypes.SET_WEBCAM_STATUS: {
      return {
        ...state,
        hasWebcam: action.payload
      };
    }

    case ConferenceActionTypes.SET_SPEAKER_STATUS: {
      return {
        ...state,
        hasSpeaker: action.payload
      };
    }

    case ConferenceActionTypes.SET_AVAILABLE_MEDIA_DEVICES: {
      return {
        ...state,
        availableMediaDevices: action.payload
      };
    }

    case ConferenceActionTypes.SET_MICROPHONE_STATUS: {
      return {
        ...state,
        hasMicrophone: action.payload
      };
    }


    case ConferenceActionTypes.UPDATE_JITSI_CONFIG: {
      return {
        ...state,
        jitsiOptions: action.payload
      };
    }

    case ConferenceActionTypes.START_LOAD_JITSI_CONFIG: {
      return {
        ...state,
        isLoadingJitsiConfig: true
      };
    }

    case ConferenceActionTypes.STOP_LOAD_JITSI_CONFIG: {
      return {
        ...state,
        isLoadingJitsiConfig: false
      };
    }

    case ConferenceActionTypes.UPDATE_FRONT_CAMERA_ID: {
      return {
        ...state,
        frontCameraId: action.payload
      };
    }

    case ConferenceActionTypes.UPDATE_BACK_CAMERA_ID: {
      return {
        ...state,
        backCameraId: action.payload
      };
    }
    case ConferenceActionTypes.SET_JITSI_ROOM: {
      return {
        ...state,
        jitsiRoom: action.payload
      };
    }
    case ConferenceActionTypes.SET_STREAM_ID: {
      return {
        ...state,
        streamId: action.payload
      };
    }
    case ConferenceActionTypes.SET_CONVERSATION_TARGET: {
      return {
        ...state,
        conversationTarget: action.payload
      };
    }
    case ConferenceActionTypes.SET_CONFERENCE_ID: {
      return {
        ...state,
        conferenceId: action.payload
      };
    }
    case ConferenceActionTypes.SET_SELECTED_PARTICIPANT: {
      return {
        ...state,
        selectedParticipantId: action.payload
      };
    }
    case ConferenceActionTypes.CONFERENCE_CURRENT_VIEW: {
      return {
        ...state,
        currentView: action.payload
      };
    }
    case ConferenceActionTypes.WHITEBOARD_START: {
      return {
        ...state,
        activeWhiteboard: action.payload
      };
    }
    case ConferenceActionTypes.SET_SCREEN_SHARING_REQUEST: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ConferenceActionTypes.SET_SCREEN_SHARING_REQUEST_STATUS: {
      return {
        ...state,
        requestedShare: action.payload
      };
    }
    case ConferenceActionTypes.SET_SCREEN_SHARING_STARTED: {
      return {
        ...state,
        isStartingScreenshare: false
      };
    }
    case ConferenceActionTypes.RESET_SCREEN_SHARING: {
      return {
        ...state,
        isStartingScreenshare: false,
        presenterId: null,
        presenterJid: null
      };
    }
    case ConferenceActionTypes.SET_VIRTUAL_BACKGROUND: {
      return {
        ...state,
        virtualBackground: action.payload
      };
    }
    case ConferenceActionTypes.SET_UPLOADED_BACKGROUND: {
      return {
        ...state,
        uploadedBackground: action.payload
      };
    }
    case ConferenceActionTypes.BACKGROUND_ENABLED: {
      return {
        ...state,
        backgroundEffectEnabled: action.payload
      };
    }
    case ConferenceActionTypes.SUPPRESSION_ENABLED: {
      return {
        ...state,
        noiseSuppressionEnabled: action.payload
      };
    }
    default:
      return state;
  }
}

export const _getJitsiConfig = (state: ConferenceState) => state.jitsiOptions;
export const _getIsLoadingJitsiConfig = (state: ConferenceState) => state.isLoadingJitsiConfig;
export const _getIsConferenceAudioMuted = (state: ConferenceState) => state.audioMuted;
export const _getIsConferenceVideoMuted = (state: ConferenceState) => state.videoMuted;
export const _getIsConferenceScreenSharing = (state: ConferenceState) => state.sharingScreen;
export const _getFullScreenParticipantId = (state: ConferenceState) => state.fullScreenParticipantId;
export const _getSelectedParticipantId = (state: ConferenceState) => state.selectedParticipantId;
export const _getParticipants = (state: ConferenceState) => state.participants;
export const _getExistingConferences = (state: ConferenceState) => state.existingConfs;
// sct
export const _getConferenceParticipants = (state: ConferenceState) => state.conferenceParticipants;
export const _getConferenceId = (state: ConferenceState) => state.conferenceId;
export const _getConversationTarget = (state: ConferenceState) => state.conversationTarget;
export const _getConferenceType = (state: ConferenceState) => state.conferenceType;
export const _getJitsiRoom = (state: ConferenceState) => state.jitsiRoom;
export const _getParticipantEmail = (state: ConferenceState) => state.participantEmail;
export const _getHasMicrophone = (state: ConferenceState) => state.hasMicrophone;
export const _getAvailableMediaDevices = (state: ConferenceState) => state.availableMediaDevices;
export const _getHasSpeaker = (state: ConferenceState) => state.hasSpeaker;
export const _getHasWebcam = (state: ConferenceState) => state.hasWebcam;
export const _getFrontCameraId = (state: ConferenceState) => state.frontCameraId;
export const _getBackCameraId = (state: ConferenceState) => state.backCameraId;
export const _getStreamId = (state: ConferenceState) => state.streamId;
export const _getActiveConference = (state: ConferenceState) => state.conversationTarget;
export const _getInvitedParticipants = (state: ConferenceState) => state.invitedParticipants;
export const _getHasActiveCall = (state: ConferenceState) => state.hasActiveCall;
export const _getInvitationStatus = (state: ConferenceState) => state.startFromInvitation;
export const _getActiveWhiteboard = (state: ConferenceState) => state.activeWhiteboard;
export const _getExternalParticipants = (state: ConferenceState) => state.externalParticipants;
export const _getCurrentView = (state: ConferenceState) => state.currentView;
export const _getSpeakingParticipant = (state: ConferenceState) => state.speakingParticipant;
export const _getMutedForMe = (state: ConferenceState) => state.mutedForMe;
export const _getScreenSharingRequestStatus = (state: ConferenceState) => state.requestedShare;
export const _getE2EEStatus = (state: ConferenceState) => state.e2eeEnabled;
export const _getKnockingParticipants = (state: ConferenceState) => state.knockingParticipants;
export const _isBackgroundEffectEnabled = (state: ConferenceState) => state.backgroundEffectEnabled;
export const _isNoiseSuppressionEnabled = (state: ConferenceState) => state.noiseSuppressionEnabled;
export const _getVirtualBackground = (state: ConferenceState) => state.virtualBackground;
export const _getUploadedBackground = (state: ConferenceState) => state.uploadedBackground;
export const _isMutedEveryone = (state: ConferenceState) => state.mutedEveryone;
export const _isMutedAllVideo = (state: ConferenceState) => state.mutedAllVideo;
export const _isFloatingExpanded = (state: ConferenceState) => state.isFloatingExpanded;
export const _getScreenSharingRequest = (state: ConferenceState) => {
  const isStartingScreenshare = state.isStartingScreenshare;
  const presenterId = state.presenterId;
  const presenterJid = state.presenterJid;
  return {isStartingScreenshare, presenterId, presenterJid};
};
export const _getLobbyState = (state: ConferenceState) => {
  const knocking = state.knocking;
  const knockingParticipants = state.knockingParticipants;
  const lobbyEnabled = state.lobbyEnabled;
  const passwordJoinFailed = state.passwordJoinFailed;
  return {knocking, knockingParticipants, lobbyEnabled, passwordJoinFailed};
};
