import {Action} from "../../actions";
import { Follow } from "../models/follow.model";

export class FollowActionTypes {
  static FOLLOWERS_LOAD_REQUEST = "[FOLLOWERS] Follower Load Request";
  static FOLLOWERS_LOAD_SUCCESS = "[FOLLOWERS] Follower Load Success";
  static FOLLOWERS_ADD = "[FOLLOWERS] Follower Add";
  static FOLLOWERS_REMOVE = "[FOLLOWERS] Follower Remove";
}

export class FollowersLoadRequest implements Action {
  readonly type = FollowActionTypes.FOLLOWERS_LOAD_REQUEST;
}

export class FollowersLoadSuccess implements Action {
  readonly type = FollowActionTypes.FOLLOWERS_LOAD_SUCCESS;

  constructor(public payload: Follow[]) {
  }
}

export class FollowerAddOne implements Action {
  readonly type = FollowActionTypes.FOLLOWERS_ADD;
  constructor(public payload: Follow ) { }
}

export class FollowerRemoveOne implements Action {
  readonly type = FollowActionTypes.FOLLOWERS_REMOVE;
  constructor(public payload: Follow["id"]) { }
}
