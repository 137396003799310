
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { SearchMessage } from "../talk/models/search-message.model";
import { Message } from "../talk/models/message.model";

export class MediaActionType {
  static IMAGES_LOADING = "[Media] images loading";
  static IMAGES_LOADING_COMPLETED = "[Media] images loading completed";
  static IMAGES_NEXT_PAGE_LOADED = "[Media] images next page loaded";
  static IMAGES_FAILED = "[Media] images failed";

  static ATTACHMENTS_LOADING = "[Media] attachments loading";
  static ATTACHMENTS_LOADING_COMPLETED = "[Media] attachments loading completed";
  static ATTACHMENTS_NEXT_PAGE_LOADED = "[Media] attachments next page loaded";
  static ATTACHMENTS_FAILED = "[Media] attachments failed";

  static VOICE_MESSAGES_LOADING = "[Media] voice messages loading";
  static VOICE_MESSAGES_LOADING_COMPLETED = "[Media] voice messages loading completed";
  static VOICE_MESSAGES_NEXT_PAGE_LOADED = "[Media] voice messages next page loaded";
  static VOICE_MESSAGES_FAILED = "[Media] voice messages failed";

  static DOCUMENTS_LOADING = "[Media] documents loading";
  static DOCUMENTS_LOADING_COMPLETED = "[Media] documents loading completed";
  static DOCUMENTS_NEXT_PAGE_LOADED = "[Media] documents next page loaded";
  static DOCUMENTS_FAILED = "[Media] documents failed";

  static VIDEOS_LOADING = "[Media] video messages loading";
  static VIDEOS_LOADING_COMPLETED = "[Media] video messages loading completed";
  static VIDEOS_NEXT_PAGE_LOADED = "[Media] video messages next page loaded";
  static VIDEOS_FAILED = "[Media] video messages failed";

  static MEDIA_OPEN_TOGGLE = "[Media] Open Toggle";
  static MEDIA_SELECT_MESSAGE = "[Media] Select Messages";

  static MEDIA_TAB_SELECTED = "[Media] Tab Selected";

  static MEDIA_FILE_SHARING = "[Media] File sharing";

}

export class ImagesLoading implements Action {
  readonly type = MediaActionType.IMAGES_LOADING;

  constructor(public payload: string) {
  }
}

export class ImagesNextPageLoaded {
  readonly type = MediaActionType.IMAGES_NEXT_PAGE_LOADED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class ImagesLoadingCompleted implements Action {
  readonly type = MediaActionType.IMAGES_LOADING_COMPLETED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class ImagesFailed implements Action {
  readonly type = MediaActionType.IMAGES_FAILED;

  constructor(public payload: string) {
  }
}

export class AttachmentsLoading implements Action {
  readonly type = MediaActionType.ATTACHMENTS_LOADING;

  constructor(public payload: string) {
  }
}

export class AttachmentsNextPageLoaded {
  readonly type = MediaActionType.ATTACHMENTS_NEXT_PAGE_LOADED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class AttachmentsLoadingCompleted implements Action {
  readonly type = MediaActionType.ATTACHMENTS_LOADING_COMPLETED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean,
    numFound: number
  }) {
  }
}

export class AttachmentsFailed implements Action {
  readonly type = MediaActionType.ATTACHMENTS_FAILED;

  constructor(public payload: string) {
  }
}

export class VoiceMessagesLoading implements Action {
  readonly type = MediaActionType.VOICE_MESSAGES_LOADING;

  constructor(public payload: string) {
  }
}

export class VoiceMessagesNextPageLoaded {
  readonly type = MediaActionType.VOICE_MESSAGES_NEXT_PAGE_LOADED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class VoiceMessagesLoadingCompleted implements Action {
  readonly type = MediaActionType.VOICE_MESSAGES_LOADING_COMPLETED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class VoiceMessagesFailed implements Action {
  readonly type = MediaActionType.VOICE_MESSAGES_FAILED;

  constructor(public payload: string) {
  }
}

export class VideosLoading implements Action {
  readonly type = MediaActionType.VIDEOS_LOADING;

  constructor(public payload: string) {
  }
}

export class VideosNextPageLoaded {
  readonly type = MediaActionType.VIDEOS_NEXT_PAGE_LOADED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class VideosLoadingCompleted implements Action {
  readonly type = MediaActionType.VIDEOS_LOADING_COMPLETED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class VideosFailed implements Action {
  readonly type = MediaActionType.VIDEOS_FAILED;

  constructor(public payload: string) {
  }
}


export class DocumentsLoading implements Action {
  readonly type = MediaActionType.DOCUMENTS_LOADING;

  constructor(public payload: string) {
  }
}

export class DocumentsNextPageLoaded {
  readonly type = MediaActionType.DOCUMENTS_NEXT_PAGE_LOADED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class DocumentsLoadingCompleted implements Action {
  readonly type = MediaActionType.DOCUMENTS_LOADING_COMPLETED;

  constructor(public payload: {
    messages: SearchMessage[],
    convTarget: string,
    offset: number,
    isOnLastPage: boolean
  }) {
  }
}

export class DocumentsFailed implements Action {
  readonly type = MediaActionType.VIDEOS_FAILED;

  constructor(public payload: string) {
  }
}


export class MediaOpenToggle implements Action {
  readonly type = MediaActionType.MEDIA_OPEN_TOGGLE;

  constructor(public payload: boolean) {
  }
}

export class MediaSelectMessage implements Action {
  readonly type = MediaActionType.MEDIA_SELECT_MESSAGE;

  constructor(public payload: Message) {
  }
}

export class MediaTabSelected implements Action {
  readonly type = MediaActionType.MEDIA_TAB_SELECTED;

  constructor(public payload: number) {
  }
}

export class SetMediaSharingStatus implements Action {
  readonly type = MediaActionType.MEDIA_FILE_SHARING;

  constructor(public payload: boolean) {
  }
}

