import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {catchError, map, take, takeUntil} from "rxjs/operators";
import {SnackbarType} from "../models/snackbar.model";
import {SmartLinkData, SmartLinkService} from "../smart-link.service";
import {TranslateService} from "@ngx-translate/core";
import {ChannelSnackbarService} from "../channel-snackbar.service";
import {ChannelRepository} from "../repository/channel.repository";
import {Utils} from "../../common";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {ChannelService} from "../channel.service";
import {CommonUtil} from "../../talk/utils/common.util";
import {SmartObjectsService} from "../smart-objects/smart-objects.service";
import {StickyNoteActions, StickyNoteService} from "../sticky-note.service";
import {RemarkService} from "../remark.service";
import {Router} from "@angular/router";
import {CloseSmartObjectDialog} from "../../root.component";
import {Broadcaster} from "../../talk/shared/providers";
import { environment } from "app/environments/environment";

@Component({
  selector: "vp-sticky-note",
  templateUrl: "./sticky-note.component.html",
  styleUrls: ["./sticky-note.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StickyNoteComponent implements OnInit, OnDestroy {
  note = {};
  users: { id: string; value: string }[] = [];
  viewOnly = false;
  editor: any;
  isUploadRunning = false;
  observableUploadFilesBatch : any = [];
  uploadedInlineAttachments: any = [];
  thumbnailSize = 800;

  stickyNoteActions = StickyNoteActions;

  isAlive$ = new Subject();
  showActions = false;
  constructor(
    private _dialog: MatDialogRef<StickyNoteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedData: {
        preContent: string;
        selectedText: string;
        postContent: string;
      };
      note?: any;
      users?: any;
      viewOnly:boolean,
      bypassViewMode?: boolean,
      canAddMoreEntities?: boolean,
      isMobileView: boolean
    },
    private _smartLinkService: SmartLinkService,
    private translate: TranslateService,
    private channelSnackBarService: ChannelSnackbarService,
    private _channelRepository: ChannelRepository,
    private _channelService: ChannelService,
    private _smartObjectsService: SmartObjectsService,
    private _stickyNoteService: StickyNoteService,
    private _remarkService: RemarkService,
    private _router: Router,
    private _broadcaster: Broadcaster
  ) {
    this._broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_STICKY_NOTE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this._dialog.close();
    });
  }

  ngOnInit(): void {
    this.note = this.data.note;
    this.users = this.data.users;
    this.viewOnly = this.data.viewOnly;
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  onStickyNoteSaveOrEdit(event) {
    let noteData = this.data?.note?.uid ? {...event, uid: this.data.note.uid} : event;
    this._dialog.close({
      action: StickyNoteActions.SAVE,
      noteData: noteData,
      selectionData: { ...this.data.selectedData },
    });
  }

  saveStickyNote() {
    document.getElementById("save-sticky-note").click();
  }

  onCloseStickyNote() {
    this._dialog.close();
  }

  editNote() {
    document.getElementById("sticky-note-edit-button").click();
  }

  deleteNote(event) {
    this._dialog.close({
      action: StickyNoteActions.DELETE,
      noteData: event
    });
  }

  showSnackBar(message) {
    this.translate
      .get(message)
      .pipe(take(1))
      .subscribe((text) =>
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE)
      );
  }

  onEditorCreated(editor) {
    this.editor = editor;
    setTimeout(() => {
      this.editor.focus();
    }, 300);
  }

  getFileType(filename) {
    return Utils.getFileType({ name: filename });
  }

  uploadInlineAttachment(files: any): void {
    if (files?.name) {
      files = [files];
    }
    let filesArray: any = [];
    let imgExtArray: any = [];
    let notImageExt = false;
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        if (this.getFileType(files[i]?.name) == "Image") {
          imgExtArray.push(files[i]);
        } else {
          notImageExt = true;
        }
      }
    }
    if (notImageExt) {
      this.translate
        .get("ALLOW_UPLOAD_IMAGE_FILES")
        .pipe(take(1))
        .subscribe((text) => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
    }
    for (let i = 0; i < imgExtArray.length; i++) {
      if (imgExtArray[i]) {
        let isFileExceedLimit = this._channelRepository.isFileSizeExceedLimit(
          imgExtArray[i]
        );
        if (isFileExceedLimit) {
          this._channelRepository.showFileExceedNotification(imgExtArray[i]);
        } else {
          filesArray.push(imgExtArray[i]);
        }
      }
    }
    if (filesArray.length > 5) {
      this.translate
        .get("FILE_UPLOAD_LIMIT")
        .pipe(take(1))
        .subscribe((text) => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
      filesArray = filesArray.splice(0, 5);
    }
    if (this.isUploadRunning) {
      this.translate
        .get("FILE_UPLOAD_ALREADY_RUNNING_MSG")
        .pipe(take(1))
        .subscribe((text) => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
      return;
    }
    this.readFiles(filesArray).subscribe((fileUploads) => {
      this.isUploadRunning = true;
      this.uploadInlineFiles(fileUploads).subscribe(() => {
        this.isUploadRunning = false;
      });
    });
  }

  readFiles(files: any[]): Observable<any> {
    this.observableUploadFilesBatch = [];
    for (let i = 0; i < files.length; i++) {
      this.observableUploadFilesBatch.push(this.fileReaderObs(files[i]));
    }
    return forkJoin(this.observableUploadFilesBatch);
  }

  uploadInlineFiles(fileUploadsArray: any[]): Observable<any> {
    const fileUploads: any = [];
    for (let i = 0; i < fileUploadsArray.length; i++) {
      fileUploads.push(
        this._channelService
          .uploadFile(
            fileUploadsArray[i].content,
            fileUploadsArray[i].filename,
            fileUploadsArray[i].content_type
          )
          .pipe(
            map((res: any) => {
              if (res.upload) {
                this.uploadedInlineAttachments.push(res.upload);
                this.addInlineImages(res.upload);
              }
            }),
            catchError(() =>
              of(
                this.translate
                  .get("FILE_UPLOAD_ERROR", {
                    filename: fileUploadsArray[i].filename,
                  })
                  .pipe(take(1))
                  .subscribe((text) => {
                    this.channelSnackBarService.openSnackBar(
                      text,
                      SnackbarType.CLOSE
                    );
                  })
              )
            )
          )
      );
    }
    return forkJoin(fileUploads);
  }

  fileReaderObs(file) {
    let myReader: FileReader = new FileReader();
    let fileReaderObs = Observable.create((observer: any) => {
      myReader.onloadend = () => {
        let upload = {
          filename: file.name,
          content_type: file.type,
          content: myReader.result,
        };
        observer.next(upload);
        observer.complete();
      };
    });
    myReader.readAsArrayBuffer(file);
    return fileReaderObs;
  }

  addInlineImages(fileAttachment) {
    if (this.editor) {
      if (fileAttachment) {
        let url = CommonUtil.getAttachmentLocalAPIURL(
          fileAttachment.thumbnail_url
        );
        const img = "<img src=" + this.getThumbnailURL(url) + "><p><br></p>";
        this.editor.clipboard.dangerouslyPasteHTML(
          this.editor.selection.savedRange.index,
          img
        );
      }
    }
  }

  getThumbnailURL(url) {
    if (url.indexOf("?") !== -1) {
      return url + "&size=" + this.thumbnailSize;
    } else {
      return url + "?size=" + this.thumbnailSize;
    }
  }

  actionClick() {
    this._smartObjectsService.underDevelopment();
  }

  onHeaderAndFooterActionClick(event: { clickEvent: MouseEvent, actionType: StickyNoteActions }) {
    switch (event.actionType) {
      case StickyNoteActions.ADD_SMART_LINK: return this.onAddSmartLink(event, this.data.isMobileView);
      case StickyNoteActions.ADD_STICKY_NOTE: return this.onAddStickyNote(event, this.data.isMobileView);
      case StickyNoteActions.ADD_REFERENCE: return this.onAddReference(event, this.data.isMobileView);
      case StickyNoteActions.ADD_HIGHLIGHT: return this.onAddHighlight();
      case StickyNoteActions.DELETE: return this.actionClick();
    }
  }

  private getSmartLinkPopupData(selectedText: string, clientX, clientY, skipFirstStep = false, searchResult = null, selectedSmartLinkData = null, alreadyLinkedObjects = null, mobileMode = false): Observable<any> {
    const smartLinkPopupRef = this._smartLinkService.getSmartLinkPopupRef(selectedText, clientX, clientY, true, skipFirstStep, searchResult, selectedSmartLinkData, alreadyLinkedObjects, mobileMode);
    return smartLinkPopupRef.afterClosed().pipe(map(value => mobileMode ? value?.data?.previewData : !!skipFirstStep ? value : value?.data?.previewData[0]), take(1));
  }

  private onAddSmartLink(event, mobileMode) {
    const smartLinkName = event?.clickEvent?.target?.textContent;
    let alreadyLinkedObjects = {};
    this.getSmartLinkPopupData(smartLinkName, event.clickEvent.clientX, event.clickEvent.clientY, false, null, null, alreadyLinkedObjects, mobileMode).pipe(takeUntil(this.isAlive$)).subscribe((smartLinkData: SmartLinkData) => {
      if (!!smartLinkData) {
        const stickyNotesData = [this.data?.note?.uid];
        const mappedSmartLinkMetaData = this._smartLinkService.getMappedSmartLinkMetaData(mobileMode ? smartLinkData : [smartLinkData]);
        this._dialog.close({action: StickyNoteActions.ADD_SMART_LINK, stickyNotesData, smartLinkData: mappedSmartLinkMetaData});
      }
    });
  }

  private onAddStickyNote(event, mobileMode = false) {
    const stickyNotePopupRef = this._stickyNoteService.openStickyNotePopup(event.clickEvent, "", false, false, mobileMode);
    stickyNotePopupRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res?.action === StickyNoteActions.SAVE) {
          const {noteData} = res;
          const newNote = this._stickyNoteService.getMappedStickyNotesForReqPayload([noteData], true)?.[0];
          const stickyNotesData = [this.data?.note, newNote];
          this._dialog.close({action: StickyNoteActions.ADD_STICKY_NOTE, stickyNotesData, newNote});
        }});
  }

  private onAddHighlight() {
    const stickyNotesData = [this.data?.note?.uid];
    this._dialog.close({action: StickyNoteActions.ADD_HIGHLIGHT, stickyNotesData});
  }

  private onAddReference(event, mobileMode) {
    const remarkPopupRef = this._remarkService.openRemarkPopup(event.clickEvent, mobileMode);
    remarkPopupRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data?.action === "save") {
          const stickyNotesData = [this.data?.note?.uid];
          const referencesData = this._remarkService.getMappedReferencesForReqPayload([data], true);
          this._dialog.close({action: StickyNoteActions.ADD_REFERENCE, stickyNotesData, referencesData, newReference: referencesData[0]});
        }
      });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }


}
