import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {BottomSheetSmartLinkFilterComponent} from "./bottom-sheet-smart-link-filter/bottom-sheet-smart-link-filter.component";
import {take} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {UserSelectMobileComponent} from "./user-select-mobile/user-select-mobile.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {FilterType} from "../../smart-link.service";
import {SmartLinkPopupComponentService} from "../smart-link-popup.component.service";


@Injectable()
export class MobileSmartLinkFilterService {
  selectedFilters = new Subject<any>();
  appliedFiltersCount = 0;

  filterCategories = {
    CHANNEL_TYPE: "",
    CHANNEL_USER: [],
    CHANNEL_AUTHOR: [],

    TOPIC_TYPE : "",
    TOPIC_USER : [],
    TOPIC_AUTHOR : [],

    COMMENT_CHANNEL: [],
    COMMENT_AUTHOR: [],
    COMMENT_TOPIC: []
  };

  globalFilters = {
    [FilterType.GLOBAL_UNREAD]: false,
    [FilterType.GLOBAL_STARRED]: false,
    [FilterType.GLOBAL_ARCHIVED]: false
  };

  globalDateFilterApplied: boolean = false;
  globalDateFilter = {
    startDate: null,
    endDate: null
  };
  dateRangeToDisplay: string = "";

  constructor(private dialog: MatDialog,
              private bottomSheet: MatBottomSheet,
              private smartLinkPopupService: SmartLinkPopupComponentService) {};

  setFilterLocally(operation, fetchSearchResults) {
    const value = operation.selected;
    this.filterCategories[operation.event] = value;
    this.updateCounter();
    if (fetchSearchResults) {
      this.smartLinkPopupService.onFilterChange(value, operation.event);
    }
  }

  updateCounter() {
    let count = 0;
    Object.values(this.filterCategories).forEach((value) => {
      if ((typeof (value) === "string" && value !== "") || (value.length !== 0)) {
        count++;
      }
    });
    Object.values(this.globalFilters).forEach(value => {
      if (value) {
        count++;
      }
    });
    this.appliedFiltersCount = count;
  }

  setFilters() {
    let data = {
      ...this.filterCategories,
      ...this.globalFilters
    };
    if (this.globalDateFilterApplied) {
      data[FilterType.GLOBAL_DATE_RANGE] = this.globalDateFilter;
    }
    this.selectedFilters.next(data);
  }


  updateGlobalFilters(filterName: FilterType.GLOBAL_UNREAD | FilterType.GLOBAL_STARRED | FilterType.GLOBAL_ARCHIVED) {
    this.globalFilters[filterName] = !this.globalFilters[filterName];
    this.updateCounter();
  }

  resetFilters() {
    const resetFilter = {
      CHANNEL_TYPE: "",
      CHANNEL_USER: [],
      CHANNEL_AUTHOR: [],
      TOPIC_TYPE : "",
      TOPIC_USER : [],
      TOPIC_AUTHOR : [],
      COMMENT_CHANNEL: [],
      COMMENT_AUTHOR: [],
      COMMENT_TOPIC: []
    };
    this.filterCategories = resetFilter;
    this.appliedFiltersCount = 0;
    this.setFilters();
  }


  selectUsers(options: number[]|string[], type: FilterType, fetchSearchResults = false) {
    setTimeout(() => {
      this.dialog.open(UserSelectMobileComponent, {
        width: "100%",
        height: "100%",
        backdropClass: "transparent-backdrop",
        panelClass: "smartlink-popup-container-mobile",
        data: {options, type},
        position: {
          left: "0px",
          top: "0px"
        }
      })
      .afterClosed()
      .pipe(
        take(1)
      )
      .subscribe((data) => {
        if (data && data.type && data.type !== "") {
          this.filterCategories[data.type] = data.options;
          if (fetchSearchResults) {
            this.smartLinkPopupService.onFilterChange(data.options, data.type);
          }
        }
      });
    }, 200);
  }

  openMobileMenu(item: string, fetchSearchResults = false): void {
    setTimeout(() => {
      const bottomSheet = this.bottomSheet.open(BottomSheetSmartLinkFilterComponent, {
        panelClass: "vnc-mobile-bottm-sheet-channel-topic-panel",
        data: {event: item, selectedValue: this.filterCategories[item]}
      });
      bottomSheet.afterDismissed().pipe(take(1)).subscribe(res => {
        if (!!res) {
          if (res.operation) {
            const operation = res.operation;
            this.setFilterLocally(operation, fetchSearchResults);
          }
        }
      });
    }, 200);
  }

}

