import {Component, Inject} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {FilterType} from "../../../smart-link.service";

@Component({
  selector: "vp-bottom-sheet-smart-link-filter",
  templateUrl: "./bottom-sheet-smart-link-filter.component.html",
  styleUrls: ["./bottom-sheet-smart-link-filter.component.scss"]
})
export class BottomSheetSmartLinkFilterComponent {
  filterType = FilterType;
  channelTypeOptions = [
    { label: "All Channels", value: "" },
    { label: "Public only", value: "public" },
    { label: "Private only", value: "private" },
  ];
  topicTypeOptions = [
    { label: "All Topics", value: "" },
    { label: "Public only", value: "public" },
    { label: "Private only", value: "private" },
  ];
  item: any;
  selectedValue: string;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetSmartLinkFilterComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.selectedValue = this.data.selectedValue;
  }

  selectItem(item: any): void {
    this.item = item;
    this.selectedValue = item.selected;
  }

  operation(): void {
    this._bottomSheetRef.dismiss({ operation: this.item });
  }

}
