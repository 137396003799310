<div *ngIf="!isMobileScreen" class="archive-window-container">
  <div class="header">
    <div class="title">{{ 'ARCHIVE' | translate }}</div>
    <div class="close-btn">
      <vnc-icon-container (click)="exitArchiveWindow()">
          <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
      </vnc-icon-container>
    </div>
  </div>
  <div class="tabs">
    <mat-tab-group class="sidebar-container-channels-tab-group" animationDuration="0ms" [selectedIndex]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'CHANNELS' | translate }}
          <span class="channel-count">{{channelsCount}}</span>
        </ng-template>
        <vp-archive-window-channels (totalChannels)="channelsCount = $event"></vp-archive-window-channels>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'TOPICS' | translate }}
          <span class="topic-count">{{topicsCount}}</span>
        </ng-template>
        <vp-archive-window-topic (totalTopics)="topicsCount = $event"></vp-archive-window-topic>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>


<div *ngIf="isMobileScreen">
  <vnc-dialog [classes]="['archive-window-wrapper-mobile']" [headerTemplate]="mobileHeaderTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="mobileBodyTemplate">
  </vnc-dialog>

  <ng-template #mobileHeaderTemplate>
    <vnc-mobile-header headerType="dark-back-more"
                       darkBackMorePlaceHolder="{{'ARCHIVE' | translate}}"
                       (darkBackPress)="exitArchiveWindow()">
    </vnc-mobile-header>
  </ng-template>

  <ng-template #mobileBodyTemplate>
    <!-- <vp-archive-window-chats [isMobileScreen]="true" *ngIf="_archiveWindowService.currentActiveTab === 'chats'"></vp-archive-window-chats> -->
    <vp-archive-window-channels-mobile [isMobileScreen]="true" [hidden]="_archiveWindowService.currentActiveTab === 'topics'"></vp-archive-window-channels-mobile>
    <vp-archive-window-topic-mobile [isMobileScreen]="true" [hidden]="_archiveWindowService.currentActiveTab === 'channels'"></vp-archive-window-topic-mobile>
  </ng-template>
  <ng-template #footerTemplate>
    <span></span>
  </ng-template>
</div>
