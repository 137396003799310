<vnc-dialog [classes]="['send-message-to-chats-container', !!($selectedUsers | async)?.length ? 'has-comment-box': '']"
            headerText="{{ 'SEND_TO' | translate }}"
            [customStyles]="getContainerStyles()"
            [footerTemplate]="footerTemplate"
            [bodyTemplate]="bodyTemplate">
</vnc-dialog>


<ng-template #bodyTemplate>

  <div class="vnc-user-search-form">
    <vnc-input [form]="recipientSearchControl"
               [showClear]="true" [showLabel]="true"
               leadingIconName="mdi-magnify" placeholder="{{ 'SEARCH_LABEL' | translate}}">
    </vnc-input>
  </div>

  <div class="vnc-users-block vnc-scrollbar-verical-show-on-hover">
    <div class="vnc-selected-users-list" *ngIf="!!($selectedUsers | async)?.length">
      <vnc-chips *ngFor="let user of ($selectedUsers | async)" text="{{user.fullName}}" [item]="user"
        [avatarTemplate]="avatarTemplateRole" [imgSrc]="avatarTemplateRole" (removeIconClick)="removeUser(user)"
        [removable]=true size="m"></vnc-chips>
    </div>

    <div class="vnc-unselected-users-list" *ngIf="!!($filteredRecipients | async)?.length">
        <vnc-user-chip *ngFor="let recipient of ($filteredRecipients | async)"
                       (click)="selectUser(recipient)" [clickable]="true"
                       [noBackground]="true" size="md" [item]="recipient"
                       [avatarTemplate]="avatarTemplate">
        </vnc-user-chip>
    </div>
  </div>
</ng-template>

<ng-template #avatarTemplate let-item="item">
  <vp-avatar size="custom" [jid]="item.jid" [hidestatus]="true"></vp-avatar>
</ng-template>

<ng-template #avatarTemplateRole let-item="item">
  <vp-avatar size="custom" [jid]="item.jid" [hidestatus]="true"></vp-avatar>
  <div class="role-icon" [class.red]="isExternalUser(item)" >
      <mat-icon *ngIf="item?.role === 'moderator'" fontSet="mdi" fontIcon="mdi-star" class="moderator-icon"></mat-icon>
      <mat-icon *ngIf="item?.role !== 'moderator'" fontSet="mdi" fontIcon="mdi-account" class="participant-icon"></mat-icon>
  </div>

</ng-template>

<ng-template #footerTemplate>
<div *ngIf="!!($selectedUsers | async)?.length && isMobile()" class="quill-comment">
  <div class="quill-label">
    <label>{{'COMMENT_OPTIONAL' | translate}}</label>
  </div>
  <vnc-quill (click)="addComment()" [modules]="quillEditorModules" (editorCreated)="onEditorCreated($event)"
    placeholder="{{ 'TYPE_MESSAGE' | translate }}" class="send-to-comment"></vnc-quill>
</div>

<div *ngIf="!!($selectedUsers | async)?.length  && !isMobile()" class="quill-comment">
  <div class="quill-label">
    <label>{{'COMMENT_OPTIONAL' | translate}}</label>
  </div>
  <vnc-quill [modules]="quillEditorModules" (editorCreated)="onEditorCreated($event)"
    placeholder="{{ 'Your message' | translate }}" class="send-to-comment"></vnc-quill>
</div>

  <div class="footer-actions">
    <!-- <button (click)="closePopup()" class="vnc-default vnc-button">{{ 'CANCEL' | translate }}</button>
    <button (click)="sendTopicLink()" class="vnc-primary vnc-button">{{ 'SEND' | translate }}</button> -->
    <vnc-secondary-button
            [size]="'m'"
            label="{{ 'CANCEL' | translate }}"
            (onClick)="closePopup()"
    ></vnc-secondary-button>
    <vnc-primary-button
            [size]="'m'"
            (onClick)="validateSendingLink()"
        ><span>{{ 'SEND' | translate  }}</span>
    </vnc-primary-button>
  </div>
</ng-template>
