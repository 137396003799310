
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

// @flow

/**
 * SET_INTERVAL constant is used to set interval and it is set in
 * the id property of the request.data property. timeMs property must
 * also be set. request.data example:
 *
 * {
 *      id: SET_INTERVAL,
 *      timeMs: 33
 * }
 */
export const SET_INTERVAL = 1;

/**
 * CLEAR_INTERVAL constant is used to clear the interval and it is set in
 * the id property of the request.data property.
 *
 * {
 *      id: CLEAR_INTERVAL
 * }
 */
export const CLEAR_INTERVAL = 2;

/**
 * INTERVAL_TIMEOUT constant is used as response and it is set in the id
 * property.
 *
 * {
 *      id: INTERVAL_TIMEOUT
 * }
 */
export const INTERVAL_TIMEOUT = 3;

/**
 * The following code is needed as string to create a URL from a Blob.
 * The URL is then passed to a WebWorker. Reason for this is to enable
 * use of setInterval that is not throttled when tab is inactive.
 */
const code = `
    var timer;

    onmessage = function(request) {
        switch (request.data.id) {
        case ${SET_INTERVAL}: {
            timer = setInterval(() => {
                postMessage({ id: ${INTERVAL_TIMEOUT} });
            }, request.data.timeMs);
            break;
        }
        case ${CLEAR_INTERVAL}: {
            if (timer) {
                clearInterval(timer);
            }
            break;
        }
        }
    };
`;

export const timerWorkerScript
    = URL.createObjectURL(new Blob([ code ], { type: "application/javascript" }));
