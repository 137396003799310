
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-confirmation-wrapper">
  <h3 class="mdl-dialog__title">{{ 'PROMPT_SCREENSHARE_PERMISSIONS_TITLE'|translate }}</h3>
  <div class="mdl-dialog__content">
    <p *ngIf="!isHinTheme" [innerHTML]="'PROMPT_SCREENSHARE_PERMISSIONS_MESSAGE'|translate"></p>
    <p *ngIf="isHinTheme" [innerHTML]="'PROMPT_SCREENSHARE_PERMISSIONS_MESSAGE_HIN'|translate"></p>
  </div>
  <div class="mdl-dialog__actions">
    <button mat-button (click)="cancel()">{{ 'OK'|translate }}</button>
  </div>
</div>
