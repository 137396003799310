/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { Post } from "../models/post.model";

export class PostActionTypes {
  static POST_LOAD_REQUEST = "[POST] Load Request";
  static POST_LOAD_SUCCESS = "[POST] Load Success";

  static POST_ADD = "[POST] Add";
  static POST_DELETE = "[POST] Delete";
  static POST_UPDATE = "[POST] Update";
  static POST_BULK_ADD = "[POST] Bulk Add";
  static POST_BULK_UPDATE = "[POST] Bulk Update";
  static POST_BULK_DELETE = "[POST] Bulk Delete";
  static POST_INFO_UPDATE = "[POST] POST Info Update";
  static SELECTED_POST = "[POST] Selected POST";
  static POST_MEMBERS_LOAD_REQUEST = "[POST] POST Members Load Request";
  static POST_MEMBERS_LOAD_SUCCESS = "[POST] POST Members Load Success";
  static POST_MEMBERS_ADD = "[POST] POST Members Add";
  static POST_MEMBERS_REMOVE = "[POST] POST Members Remove";
  static POST_MEMBERS_OFFSET_UPDATE = "[POST] POST Members Offset Update";
  static POST_FILES_LOAD_REQUEST = "[POST] POST Files Load Request";
  static POST_FILES_LOAD_SUCCESS = "[POST] POST Files Load Success";
  static POST_FILES_ADD = "[POST] POST Files Add";
  static POST_DEFAULT_COVERS_ADD = "[POST] POST Default Covers Add";
  static POST_FILES_OFFSET_UPDATE = "[POST] POST Files Offset Update";
  static POST_SIDEBAR_TAB_CHANGE = "[POST] POST Sidebar Tab Change";
  static POST_FILE_REMOVE = "[POST] POST File Remove";
  static POST_COMMENTS_LOAD_REQUEST = "[POST] POST Comments Load Request";
  static POST_COMMENTS_LOAD_SUCCESS = "[POST] POST Comments Load Success";
  static POST_SCROLL_TO_ITEM = "[POST] Scroll To Item";
  static POST_REMOVE_SCROLL_TO_ITEM = "[POST] Remove Scroll To Item";
  static POST_REMOVE_ALL_SCROLL_TO_ITEM = "[POST] Remove All Scroll To Item";
  static ALL_POSTS_INFO_UPDATE = "[POST] ALL_POSTS_INFO_UPDATE";
  static MEDIA_POST_INFO_UPDATE = "[POST] Media POST Info Update";
  static POST_USER_JID_UPDATE = "[POST] Post User Jid Update";
  static BOOKMARKED_POSTS_INFO_UPDATE = "[POST] Bookmarked Posts Info Update";
  static POST_TIMER = "[POST] Post Timer";

  static POST_REPOST_REQUEST = "[POST] Post Repost Request";
  static POST_REPOST_SUCCESS = "[POST] Post Repost Success";

  static POST_REPLY_LOAD_REQUEST = "[POST] Post reply Load Request";
  static POST_REPLY_LOAD_SUCCESS = "[POST] Post reply Load Success";
  static POST_REPLY_ADD = "[POST] Post reply Add";
  static POST_REPLY_REMOVE = "[POST] Post reply Remove";
  static POST_REPLY_OFFSET_UPDATE = "[POST] Post reply Offset Update";
}

export class PostLoadRequest implements Action {
  readonly type = PostActionTypes.POST_LOAD_REQUEST;
}

export class PostLoadSuccess implements Action {
  readonly type = PostActionTypes.POST_LOAD_SUCCESS;
  constructor(public payload: any[]) { }
}

export class PostAdd implements Action {
  readonly type = PostActionTypes.POST_ADD;
  constructor(public payload: Post) { }
}

export class PostUpdate implements Action {
  readonly type = PostActionTypes.POST_UPDATE;
  constructor(public payload: any) { }
}

export class PostDelete implements Action {
  readonly type = PostActionTypes.POST_DELETE;


  constructor(public payload: Post["id"]) {
  }
}

export class PostBulkAdd implements Action {
  readonly type = PostActionTypes.POST_BULK_ADD;
  constructor(public payload: Post[]) { }
}

export class PostBulkUpdate implements Action {
  readonly type = PostActionTypes.POST_BULK_UPDATE;
  constructor(public payload: Post[]) { }
}

export class PostBulkDelete implements Action {
  readonly type = PostActionTypes.POST_BULK_DELETE;
  constructor(public payload: number[]) { }
}

export class SetSelectedPostId implements Action {
  readonly type = PostActionTypes.SELECTED_POST;
  constructor(public payload: Post["id"]) { }
}
export class SetPostTimer implements Action {
  readonly type = PostActionTypes.POST_TIMER;
  constructor(public payload: any) { }
}

export class PostInfoUpdate implements Action {
  readonly type = PostActionTypes.POST_INFO_UPDATE;
  constructor(public payload: { info: {totalCount: number, offset: number, isLoaded: boolean}}) {
  }
}

export class PostFilesLoadRequest implements Action {
  readonly type = PostActionTypes.POST_FILES_LOAD_REQUEST;
  constructor(public payload: Post["id"]) { }
}

export class PostFilesLoadSuccess implements Action {
  readonly type = PostActionTypes.POST_FILES_LOAD_SUCCESS;
  constructor(public payload: { PostId: Post["id"], files: File[], total_count: number }) {
  }
}

export class PostFilesAdd implements Action {
  readonly type = PostActionTypes.POST_FILES_ADD;
  constructor(public payload: { PostId: Post["id"], allAttachments: any[], extraFilesAdded: number }) {
  }
}

export class PostFilesOffsetUpdate implements Action {
  readonly type = PostActionTypes.POST_FILES_OFFSET_UPDATE;
  constructor(public payload: { PostId: Post["id"], offset: number }) {
  }
}

export class PostSideBarTabChange implements Action {
  readonly type = PostActionTypes.POST_SIDEBAR_TAB_CHANGE;
  constructor(public payload: string) {
  }
}

export class PostFileRemove implements Action {
  readonly type = PostActionTypes.POST_FILE_REMOVE;
  constructor(public payload: { fileId: number, PostId: string}) {
  }
}

export class PostCommentsLoadRequest implements Action {
  readonly type = PostActionTypes.POST_COMMENTS_LOAD_REQUEST;
  constructor(public payload: Post["id"]) { }
}

export class PostCommentsLoadSuccess implements Action {
  readonly type = PostActionTypes.POST_COMMENTS_LOAD_SUCCESS;
  constructor(public payload: { POSTId: Post["id"], offset: number, total_count: number }) { }
}

export class PostScrollToItemSuccess implements Action {
  readonly type = PostActionTypes.POST_SCROLL_TO_ITEM;
  constructor(public payload: { route: string, postId: number }) { }
}

export class PostRemoveScrollToItem implements Action {
  readonly type = PostActionTypes.POST_REMOVE_SCROLL_TO_ITEM;
  constructor(public payload: { route: string}) { }
}

export class PostRemoveAllScrollToItem implements Action {
  readonly type = PostActionTypes.POST_REMOVE_ALL_SCROLL_TO_ITEM;
}

export class AllPostsInfoUpdate implements Action {
  readonly type = PostActionTypes.ALL_POSTS_INFO_UPDATE;
  constructor(public payload: {offset?: number, total_count?: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}
export class BookmarkedPostsInfoUpdate implements Action {
  readonly type = PostActionTypes.BOOKMARKED_POSTS_INFO_UPDATE;
  constructor(public payload: {offset?: number, total_count?: number, ids: number[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class MediaPostInfoUpdate implements Action {
  readonly type = PostActionTypes.MEDIA_POST_INFO_UPDATE;
  constructor(public payload: {offset?: number, total_count?: number, isLoaded: boolean}) {
  }
}

export class PostUserJidUpdate implements Action {
  readonly type = PostActionTypes.POST_USER_JID_UPDATE;
  constructor(public payload: string) {
  }
}


export class PostRepostRequest implements Action {
  readonly type = PostActionTypes.POST_REPOST_REQUEST;
  constructor(public payload: Post["id"]) { }
}

export class PostRepostSuccess implements Action {
  readonly type = PostActionTypes.POST_REPOST_SUCCESS;
  constructor(public payload: Post["id"]) { }
}


export class PostReplysLoadRequest implements Action {
  readonly type = PostActionTypes.POST_REPLY_LOAD_REQUEST;
  constructor(public payload: Post["id"]) { }
}

export class PostReplysLoadSuccess implements Action {
  readonly type = PostActionTypes.POST_REPLY_LOAD_SUCCESS;
  constructor(public payload: { postId: Post["id"], children: any[], total_count?: number, offset?: number, isLoading: boolean, isLoaded: boolean }) {
  }
}

export class PostReplysAdd implements Action {
  readonly type = PostActionTypes.POST_REPLY_ADD;
  constructor(public payload: { postId: Post["id"], children: { id: string }[] }) { }
}

export class PostReplysRemove implements Action {
  readonly type = PostActionTypes.POST_REPLY_REMOVE;
  constructor(public payload: { postId: Post["id"], ids: string[] }) { }
}

export class PostReplysOffsetUpdate implements Action {
  readonly type = PostActionTypes.POST_REPLY_OFFSET_UPDATE;
  constructor(public payload: { postId: Post["id"], offset: number }) { }
}
