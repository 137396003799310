<span class="mobile-view" *ngIf="isMobileScreen">
  <div class="comments-total">
    <span *ngIf="!isSocial">{{ 'COMMENTS' | translate }}</span><span *ngIf="isSocial">{{ 'REPLY' | translate }}</span> {{topic?.comments_count}}
  </div>
  <vp-dropdown-item
    *ngIf="!isSocial"
    [itemType]="'add-comment'"
    [imgSrc]="avatarUrl"
    label="{{ 'ADD_A_COMMENT' | translate }}"
    (onClickEvent)="addMobileComment()"
  ></vp-dropdown-item>
  <div *ngFor="let comment of comments">
    <vnc-mobile-comment-item
      [comment]="comment"
      attachmentText="{{ 'ATTACHES' | translate }}"
      downloadText="{{'DOWNLOAD' | translate }}"
      copyLinkText="{{'COPY_LINK' | translate }}"
      copyFileToClipboardText="{{'COPY_FILE_TO_CLIPBOARD' | translate }}"
      [users]="usersForMentions"
      editCommentText="{{ 'EDIT_COMMENT' | translate }}"
      deleteCommentText="{{ 'DELETE_COMMENT' | translate }}"
      forwardCommentText="{{ 'FORWARD_COMMENT' | translate }}"
      cancelText="{{ 'CANCEL' | translate }}"
      (delete)="deleteMobileComment($event)"
      [isHideTextEditor]="isHideTextEditor"
      [isSocial]="isSocial"
    >

    </vnc-mobile-comment-item>
  </div>
</span>
<span class="desktop-view" *ngIf="!isMobileScreen">
  <section class="topic-comments-list">
    <ng-template #avatarTemplate>
      <vnc-avatar class="active-vnc-avatar" customSize="72px" [imgSrc]="avatarUrl" [defaultAvatar]="defaultAvatar"></vnc-avatar>
    </ng-template>

    <mat-menu #menu>
      <ng-container *ngIf="menuTemplate" [ngTemplateOutlet]="menuTemplate"></ng-container>
    </mat-menu>

    <ng-template #menuTemplate>
      <div class="item-drop-down">
        <vp-dropdown-item [itemType]="'icon-drop-default'"
                          [prefixIconTemplate]="deleteIconTemplate"
                          label="{{'DISABLE_COMMENTS' | translate }}"
                          (onClickEvent)="enableDisableComment($event)">
        </vp-dropdown-item>
      </div>
    </ng-template>
   <vnc-comments [language]="language | translate"
                 [avatarTemplate]="avatarTemplate"
                 [isSocial]="isSocial"
                 [commentLabelTranslations]="commentLabelTranslations"
                 [fileAttachments]="uploadedFiles"
                 [uploadedFilesCount]="uploadFilesCount"
                 [fileUploading]="fileUploading"
                 [users]="usersForMentions"
                 [attachmentText]="'ATTACHES' | translate"
                 [fileCountLabel]="(uploadedFiles.length>0? ('FILES' | translate):('FILE' | translate))"
                 downloadText="{{'DOWNLOAD' | translate }}"
                 copyLinkText="{{'COPY_LINK' | translate }}"
                 copyFileToClipboardText="{{'COPY_FILE_TO_CLIPBOARD' | translate }}"
                 (clearForm)="onCommentFormClear()"
                 (fileAttachmentRemove)="removeFileAttachment($event)"
                 (fileAttachmentRemoveAll)="removeAllFileAttachment()"
                 (removeFilesFromComment)="removeFilesFromComment($event)"
                 (fileUpload)="onFileUpload($event)"
                 [comments]="sortedComments"
                 [topicIsArchived]="topic?.archived"
                 [total_count]="comment_count"
                 (send)="addComment($event, this.uploadedFiles)"
                 [authorJid]="topic?.author?.jid"
                 *ngIf="topic?.author?.jid"
                 [focusOnCreation]="focusOnCreation"
                 [hasPrevious]="level1_offset"
                 [hasNext]="level1_offset + comments.length < level1_total_count"
                 [isHideTextEditor]="isHideTextEditor"
                 [canAddComments]="canAddComments()"
                 [canEnableDisableComments]="canEnableDisableComments()"
                 (click)="onCommentClick($event)"
                 [menuTemplate]="menuTemplate"
                 (cancelUpload)="cancelFileUpload()"
                 [filesLabel]="'FILES' | translate"
                 [fileLabel]="'FILE' | translate"
                 [cancelLabel]="'CANCEL_UPLOAD' | translate"
                 [loadingLabel]="'LOADING' | translate"
                 [addFilesLabel]="'ADD_FILES' | translate"
                 [removeAllLabel]="'REMOVE_ALL' | translate"
                 [removeLabel]="'REMOVE' | translate"
                 [attachedLabel]="'FILES_ATTACHED' | translate"

   ></vnc-comments>
  </section>
</span>

<ng-template #deleteIconTemplate>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M18,7 L18,18.2857143 C18,19.7746499 16.8086874,21 15.3333333,21 L15.3333333,21 L8.66666667,21 C7.19131256,21 6,19.7746499 6,18.2857143 L6,18.2857143 L6,7 L18,7 Z M16,9 L8,9 L8,18.2857143 C8,18.649428 8.25938319,18.9465317 8.57834889,18.9935231 L8.57834889,18.9935231 L8.66666667,19 L15.3333333,19 C15.6913126,19 16,18.6824929 16,18.2857143 L16,18.2857143 L16,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>

<ng-template #msgIconTemplate>
  <img src="img/icon-product-comment-new.svg"
     class="Iconproductcomment-new">
</ng-template>
