
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "app/environments/environment";

@Component({
    templateUrl: "./prompt-screenshare-permissions.html"
})
export class PromptScreensharePermissionsComponent implements OnDestroy {
    isHinTheme = environment.theme === "hin";
    constructor(private dialog: MatDialogRef<PromptScreensharePermissionsComponent>) {
    }

    ngOnDestroy() {
        if (document.querySelector(".dialog-backdrop") !== null) {
            (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
        }
    }

    @HostListener("document:keydown.esc")
    cancel(): void {
        this.dialog.close();
    }

}
