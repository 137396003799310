import {Directive, HostListener, Input, Output} from "@angular/core";
import { EventEmitter } from "@angular/core";

@Directive({
  selector: "[vpInfiniteScroll]"
})
export class InfiniteScrollDirective {

  constructor() { }

  @Output() onUserScroll = new EventEmitter<any>();
  @Input() scrollPercentage: string = (0).toFixed(1); // takes input as decimal number ( i.e if 0.8 is passed, it will trigger event if host element is scrolled more than 80% )
  @Input() scrollDebounceTime: number = 1000; // expressed in milliseconds (ms)

  private previousScrolledHeight = 0;
  private preventScrollTrigger = false;
  private timer;

  @HostListener("scroll", ["$event"])
  onScrollStart($event: any) {
    const totalScrollHeight = $event?.target?.scrollHeight || 0;
    const scrolledHeight = ($event?.target?.offsetHeight || 0) + ($event?.target?.scrollTop || 0);
    const scrolledPercentage = (scrolledHeight / totalScrollHeight).toFixed(1);
    if (scrolledPercentage > this.scrollPercentage && this.previousScrolledHeight < scrolledHeight && !this.preventScrollTrigger) {
      this.onUserScroll.emit();
      // console.log("onUserScroll");
      this.startTimer(this.scrollDebounceTime);
    }
    this.previousScrolledHeight = scrolledHeight;
  }

  private resetScrollTimer() {
    clearTimeout(this.timer);
    this.preventScrollTrigger = false;
  }

  private startTimer(timer: number) {
    this.preventScrollTrigger = true;
    this.timer = setTimeout(() => this.resetScrollTimer(), timer);
  }
}
