
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
export interface Notification {
    id: string;
    title?: string;
    read?: boolean;
    read_at?: string;
    created_at?: string;
    notification_type?: string;
    actor?: {
        avatar_url: string,
        id: number,
        name: string,
        fullName?: string,
        jid: string;
    },
    recepient?: {
        avatar_url: string,
        id: number,
        name: string,
        fullName?: string,
        jid: string;
    },
    data: any,
    defaultImageIndex?: any,
    period_type?: string
}


export interface NotificationCenterItem {
    type: string,
    object: any,
    description: string,
    timing: string,
    imgSrc?: string,
    authorAvatar: string,
    title: string,
    isPrivate?: boolean,
    totalUnread?: number,
    unread?: boolean,
    defaultImageIndex?: any,
    isSocial?: boolean
}

export enum NotificationCenterItemType {
    CHANNEL_NEW = "channel-new",
    TOPIC_NEW = "topic-new",
    TOPIC_INVITE_PRIVATE = "topic-invite-private",
    CHANNEL_INVITE_PRIVATE = "channel-invite-private",
    TOPIC_ACCESS_GRANTED = "topic-access-granted",
    TOPIC_ACCESS_REQUEST = "topic-access-request",
    CHANNEL_ACCESS_REQUEST = "channel-access-request",
    CHANNEL_ACCESS_GRANTED = "channel-access-granted",
    TOPIC_MENTION = "topic-mention",
    TOPIC_COMMENT_REPLY = "topic-comment-reply",
    TOPIC_NEW_COMMENT = "topic-new-comment"
}