
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MiddlewareService } from "./middleware.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { XmppService } from "app/talk/services/xmpp.service";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class RedmineApiService {
  lastEtagOfgetConversations = null;

  constructor(
    private middlewareService: MiddlewareService,
    private http: HttpClient,
 ) {
  }



  projectList(searchQuery :string = "", page = 1): Observable<any> {
    let api = `/api/redmine/project/list`;
    if(searchQuery) {
      api += `?search=${searchQuery}`;
    }
    if(page) {
      api += `?page=${page}`;
    }
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(api, {headers});
    }
    return this.middlewareService.get<any>(api, true);
  }


  projectAssignee(searchQuery :string = ""){
    let api = `/api/redmine/assignee`;
    if(searchQuery) {
      api += `?search=${searchQuery}`;
    }
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(api, {headers});
    }
    return this.middlewareService.get<any>(api, true);
  }

  projectVersions(projectId :number){
    let api = `/api/target-version`;
    api += `?projectId=${projectId}`;
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(api, {headers});
    }
    return this.middlewareService.get<any>(api, true);
  }

  projectAssigneeList(ProjectId:any){
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/projects/${ProjectId}/memberships`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/projects/${ProjectId}/memberships`, true);
  }

  projectAssignableUserList(ProjectId:any){
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/projects/${ProjectId}/assigee`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/projects/${ProjectId}/assigee`, true);
  }

  ticketStatus(): Observable<any> {
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/issue_statuses`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/issue_statuses`, true);
  }

  ticketPriorities(): Observable<any> {
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/enumerations/issue_priorities`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/enumerations/issue_priorities`, true);
  }

  ticketTypes(): Observable<any> {
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/trackers`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/trackers`, true);
  }

  createNewTicket(data:{}): Observable<any> {
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.post<any>(`/api/redmine/issues`, {headers}, data);
    }
    return this.middlewareService.post<any>(`/api/redmine/issues`, true, data );
  }

  uploadRedmineFile(body: any): Observable<any> {
    let uploadFileHeader = new HttpHeaders({
      "Content-Type": "application/octet-stream",
    });
    return this.middlewareService.post(`/api/redmine/uploads`, false, body, uploadFileHeader);
  }

  uploadRedmineFileV2(body: any, fileBody): Observable<any> {
    let uploadFileHeader = new HttpHeaders({
      "Content-Type": "application/octet-stream",
    });
    let api = `/api/uploadFiles` + "?filename=" + body.filename
    +  "&content_type=" + body.content_type;
    return this.middlewareService.post(api, false, fileBody, uploadFileHeader);
  }

  uploadAttachmentData(body) {
    let uploadFileHeader = new HttpHeaders({
      "Content-Type": "application/octet-stream",
    });
    const jwtToken = localStorage.getItem("token");
   return this.middlewareService.postWithoutBaseUrl(
      `https://vnctask.dev.vnc.de/api/task/uploads.json?&jwt=${jwtToken}`,
      false, body, uploadFileHeader, false);

  }


  projectTagsSuggestion(text: string = ""): Observable<any> {
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/project/tags?name=${text}`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/project/tags?name=${text}`, true);
  }


  getAllTickets(){
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/redmine/issues`, {headers});
    }
    return this.middlewareService.get<any>(`/api/redmine/issues`, true );
  }

  updateIssues(issueId: string, changes: any): Observable<any> {
    const data = {
      issue: changes
    };
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.put<any>(`/api/issues/${issueId}`, data, {headers});
    }
    return this.middlewareService.put<any>(`/api/issues/${issueId}`, true, data);
  }


}
