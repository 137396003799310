
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Inject, ChangeDetectorRef, HostListener, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { getUserJID, getUserProfile, RootState } from "app/reducers";
import { of, Subject } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { takeUntil, debounceTime, distinctUntilChanged, switchMap, filter, take } from "rxjs/operators";
import { ContactRepository } from "app/talk/repositories/contact.repository";
import { Recipient } from "app/talk/models/conversation.model";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { CommonUtil } from "app/talk/utils/common.util";
import { User } from "app/shared/models/user.model";
import { Broadcaster } from "app/talk/shared/providers";
import { SetActiveTab } from "app/actions/app";
import { ConfigService } from "app/config.service";
import { environment } from "app/environments/environment";
import { ConferenceRepository } from "app/talk/repositories/conference.repository";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ToastService } from "app/shared/services/toast.service";
import { UserInfoDialogComponent } from "app/talk/shared/components/dialogs/user-info/user-info.component";
import { RedmineApiService } from "app/talk/services/redmine-api.service";
@Component({
  selector: "vp-select-watcher",
  templateUrl: "./select-watcher-dialog.html",
  styleUrls: ["./select-watcher.component.scss"],
})
export class SelectWatcherComponent implements OnInit, OnDestroy {
  searchControl = new UntypedFormControl("", []);
  searchControlData = new UntypedFormControl("", []);
  private isAlive$ = new Subject<boolean>();
  searchedUsers = [];
  filteredRecipients: any;
  conversations: any = [];
  currentUser: User;
  userJID: any;
  allowExternalUsersAddByEmail: boolean;
  isHinEnv = environment.theme === "hin";
  showDialog: boolean;
  isMobileScreen: boolean;
  selectedParticipant: any;
  @ViewChild("profileDialog", { static: false }) profileDialog: ElementRef;
  hideTimer: NodeJS.Timeout;

  assigneeOptionsList = [];
  assigneeList = [];
  constructor(
    private matDialogRef: MatDialogRef<SelectWatcherComponent>,
    private store: Store<RootState>,
    private broadcaster: Broadcaster,
    private conversationRepo: ConversationRepository,
    private conferenceRepo: ConferenceRepository,
    private dialogService: MatDialog,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private contactRepo: ContactRepository,
    private toasterService: ToastService,
    private el: ElementRef,
    private redmineService: RedmineApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectionRef: ChangeDetectorRef) {
    this.allowExternalUsersAddByEmail = this.configService.get("allowExternalUsersAddByEmail");
  }

  ngOnInit(): void {
    if (!!document.getElementById("searchControl")) {
      document.getElementById("searchControl").focus();
    }
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
        this.changeDetectionRef.markForCheck();
      });
    this.conferenceRepo.toggleHideVideoIOS(true);
    this.conversationRepo.getConversations().pipe(take(1)).subscribe((conversations) => {
      this.conversations = conversations.filter(c => c.type === "chat" && c.Target.indexOf("@external.") === -1
        && c.Target.indexOf("@conference.") === -1 && c.Target.indexOf("broadcast-") === -1);
      this.getFilteredRecipients();
    });
    this.broadcaster.on<any>("hideStartConversationDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.cancel();
      });
    this.store.select(getUserProfile).pipe(filter(p => !!p), takeUntil(this.isAlive$)).subscribe(profile => {
      this.currentUser = profile.user;
    });
    this.store.select(getUserJID).pipe(takeUntil(this.isAlive$)).subscribe(jid => {
      this.userJID = jid;
    });
    this.searchControlData.valueChanges.subscribe((value) => {
      this.onSearchAssignee(value);
    });
    this.searchControl.valueChanges
      .pipe(takeUntil(this.isAlive$)
        , debounceTime(150)
        , distinctUntilChanged()
        , switchMap(keyword => {
          if (keyword.length > 1) {
            // return this.contactRepo.searchUsersOnServer(keyword);
            this.onSearchAssignee(keyword);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((users: any) => {
        if (!!users) {
          this.searchedUsers = users.map(u => {
            const recipient: Recipient = {
              target: u.jid || u.email, title: u.name, type: "user",
              ldapData: u.ldapData, isExternalUser: this.contactRepo.isExternalUser(u.jid || u.email)
            };
            if (u.ldapData && !!u.ldapData.company) {
              recipient.companyName = u.ldapData.company[0];
            }
            return recipient;
          });
        }

        this.getFilteredRecipients();
      });
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.conferenceRepo.toggleHideVideoIOS(false);
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.filteredRecipients = this.data.watcherList;
    this.filteredRecipients.map(data => {
      data.isSelected = false;
    });
    this.filteredRecipients.map(data => {
      this.alreadySelected.map(el => {
        if (el.id == data.id) data.isSelected = true;
      });
    });

    this.matDialogRef.close();
  }

  getTargetTitle(target: string): string {
    return this.contactRepo.getFullName(target);
  }

  addEmail(): void {
    if (this.isValidMail() && this.allowExternalUsersAddByEmail) {
      this.startTextChat(this.searchControl.value);
    }
  }

  isValidMail() {
    return this.searchControl.value && CommonUtil.validateEmail(this.searchControl.value);
  }

  getFilteredRecipients(): void {
    this.data.watcherList.map(el => {
      if (el.isSelected) this.alreadySelected.push(el);
    });
    this.filteredRecipients = [...this.data.watcherList];
    this.filteredRecipientsCopy = [...this.data.watcherList];
    this.assigneeList = this.data.watcherList;

  }
  alreadySelected: any = [];
  filteredRecipientsCopy: any = [];
  clearSearch() {
    this.searchControl.patchValue("");
    document.getElementById("searchControl").focus();
  }

  async showUserInfo(recipient: Recipient) {
    let dialogStyles: any = {
      "width": "416px",
      "height": "184px",
      "border-radius": "8px",
      "min-width": "416px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "90%",
        "height": "90%",
        "margin-top": "0",
        "border-radius": "6px",
        "min-width": "90%",
        "visibility": "visible"
      };
    }

    this.dialogService.open(UserInfoDialogComponent, Object.assign({
      data: { bare: recipient.target, name: recipient.title, ldapData: recipient.ldapData },
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      hasBackdrop: false,
      autoFocus: false
    }, dialogStyles));
  }

  closeUserInfoDialog() {
    if (!CommonUtil.isMobileSize()) {
    }
  }

  selectParticipant(participant) {
    let target = participant.jid || participant.target;
    if (participant.ldapData && participant.ldapData.mail && participant.ldapData.mail[0]) {
      target = participant.ldapData.mail[0];
    }
    this.startTextChat(target);
  }


  selectMiltipleParticipents(participant) {
    if (participant.isSelected) participant.isSelected = !participant.isSelected;
    else participant["isSelected"] = true;
  }

  addWatcher() {
    const filterParticipant = this.filteredRecipients.filter(v => v.isSelected);
    this.matDialogRef.close(filterParticipant);
  }

  startTextChat(target: string) {
    this.store.dispatch(new SetActiveTab("chat"));
    this.conversationRepo.navigateToConversation(target);
    this.matDialogRef.close(target);
  }

  async showUserInfoOnHover(recipient: Recipient) {
    // if (!CommonUtil.isMobileSize() && !this.showDialog) {
    //   this.closeUserInfoDialog();
    //   this.showDialog = true;
    //   let dialogStyles: any = {
    //     "width": "416px",
    //     "height": "186px",
    //     "border-radius": "8px",
    //     "minWidth": "416px",
    //     "right": "110px",
    //     "visibility": "visible"
    //   };
    // //   const { UserInfoDialogComponent } = await import(
    // //     "../user-info/user-info.component");
    //   this.dialogService.open(UserInfoDialogComponent, Object.assign({
    //     data: { bare: recipient.target, name: CommonUtil.html2text(recipient.title), ldapData: recipient.ldapData },
    //     backdropClass: "vnctalk-form-backdrop",
    //     panelClass: "vnctalk-form-panel",
    //     disableClose: true,
    //     hasBackdrop: false,
    //     autoFocus: false
    //   }, dialogStyles));
    // }
  }

  trackByFn(item) {
    return item.target;
  }



  showProfile() {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
  }


  copyEmail(email) {
    this.toasterService.show("COPIED_TO_CLIPBOARD");
    CommonUtil.copyToClipboard([email]);
  }

  onSearchAssignee(value: string) {
    this.filteredRecipients = this.assigneeList.filter(search => search.firstname.toLowerCase().indexOf(value.toLowerCase()) > -1);
    if (this.filteredRecipients.length == 0) {
      this.searchAssignee(value);
    }
  }

  searchAssignee(searchQuery: string) {
    this.redmineService.projectAssignee(searchQuery).subscribe(assignee => {
      this.assigneeList = this.mergeData(this.assigneeList, assignee.users);
      this.filteredRecipients = this.assigneeList.filter(search => search.firstname.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
    });
  }

  mergeData(data: any[], data2: any[]): any[] {
    const result = [...data]; // Create a copy of data
    for (const item of data2) {
      const existingItem = result.find((dataItem) => dataItem.id === item.id);
      if (!existingItem) {
        result.push(item);
      }
    }
    return result;
  }

}
