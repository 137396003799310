import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  SearchFilterChipOption,
  SmartLinkPopupFiltersService
} from "../../smart-link-popup-filters.service";
import {debounceTime, take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SmartLinkPopupComponentService} from "../../smart-link-popup.component.service";
import {FilterType} from "../../../smart-link.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {CloseSmartObjectDialog} from "../../../../root.component";
import {Broadcaster} from "../../../../talk/shared/providers";
import { environment } from "app/environments/environment";

@Component({
  selector: "vp-user-select-mobile",
  templateUrl: "./user-select-mobile.component.html",
  styleUrls: ["./user-select-mobile.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class UserSelectMobileComponent implements OnInit {
  private isAlive$ = new Subject<boolean>();
  headerLabel = "USERS";
  filterType: FilterType;

  constructor(private dialogRef: MatDialogRef<UserSelectMobileComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { options: any, type: FilterType },
              public smartLinkFiltersService: SmartLinkPopupFiltersService,
              private smartLinkPopupComponentService: SmartLinkPopupComponentService,
              private translate: TranslateService,
              private _router: Router,
              private _broadcaster: Broadcaster) {
    this._broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_USER_SELECT_MOBILE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngOnInit(): void {
    this.getHeaderLabel();
    this.smartLinkFiltersService.userSearchControl.valueChanges
      .pipe(takeUntil(this.isAlive$), debounceTime(100))
      .subscribe(value => {
        this.smartLinkPopupComponentService.onSmartLinkSearchQueryChange(value);
      });
    this.smartLinkFiltersService.fetchAllFilters();
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  ngOnDestroy() {
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

  getHeaderLabel() {
    if(this.data.type.includes("CHANNEL") && !this.data.type.includes("AUTHOR") && !this.data.type.includes("USER")) {
      this.headerLabel = "CHANNELS";
    } else if (this.data.type.includes("TOPIC") && !this.data.type.includes("AUTHOR") && !this.data.type.includes("USER")) {
      this.headerLabel = "TOPICS";
    } else if (this.data.type.includes("AUTHOR")) {
      this.headerLabel = "AUTHORS";
    } else {
      this.headerLabel = "USERS";
    }
    this.translate.get(this.headerLabel).pipe(take(1))
      .subscribe(text => {
        this.headerLabel = text;
      });
  }

  handleCheckboxClick(isChecked: any, option: SearchFilterChipOption) {
    if (isChecked) {
      this.data.options.push(option.value);
    } else {
      this.data.options = this.data.options.filter(value => value !== option.value);
    }
  }

  isUserChecked(option: SearchFilterChipOption): boolean {
    return this.data.options.includes(option.value);
  }

  selectUsers() {
    this.dialogRef.close({options: this.data.options, type: this.data.type});
  }

  closeDialog() {
    this.dialogRef.close({});
  }

}
