
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import {
  getAdvanceSearchRequest,
  getSearchKeyword,
  getSearchOffset,
  getSearchResults,
  getSearchSelectedMessageId,
  getSearchTotal,
  getSearchResultTimeRange,
  getUserJID
} from "../reducers";
import { Store } from "@ngrx/store";
import {
  SearchAppUpdate,
  SearchAttachmentUpdate,
  SearchBooleanUpdate,
  SearchClearAll,
  SearchContentTypeUpdate,
  SearchKeywordUpdate,
  SearchOffsetUpdate,
  SearchResponseLoad,
  SearchResponseNextPage,
  SearchStarredTypUpdate,
  SearchTimeRangeUpdate,
  SearchTotalUpdate,
  SearchUsersUpdate,
  SetSearchSelectedMessageId
} from "../actions/search";
import { Contact } from "../talk/models/contact.model";
import { Observable } from "rxjs";
import { AppType } from "../shared/models";
import { AdvanceSearchTime } from "../shared/models/advance-search-time.model";
import { AdvanceSearchContentType } from "../shared/models/advance-search-content-type.model";
import { AdvanceSearchRequest } from "../shared/models/advance-search-request.model";
import { AdvanceSearchRequestUtil } from "../shared/utils/advance-search-request.util";
import { MessageSearchResponse } from "../responses/message-search.response";
import { ConversationService } from "../talk/services/conversation.service";
import { SearchMessage } from "../talk/models/search-message.model";
import { getShowGlobalSearchResults, getShowGlobalSearchResultSelected, getSelectedSearchMessages, TalkRootState, getAllSearchMessage } from "../talk/reducers";
import { GlobalSearchResultDeSelected, GlobalSearchResultSelected } from "../talk/actions/layout";
import { ResetMessages, MessageAdd, MessageBulkAppend, MessageBulkUpdate } from "../talk/actions/search-message";
import { Message } from "../talk/models/message.model";
import { JID } from "../talk/models/jid.model";
import { CommonUtil } from "app/talk/utils/common.util";
import { filter, map, take } from "rxjs";
import { GroupChatsService } from "app/talk/services/groupchat.service";
import { OriginalMessage } from "app/talk/models/message.model";

@Injectable()
export class SearchRepository {
  private _inSearchMode: boolean;
  userJID: any;
  constructor(private store: Store<TalkRootState>,
              private groupChatsService: GroupChatsService,
              private conversationService: ConversationService) {
                this.store.select(getUserJID).pipe(filter(v => !!v), take(1)).subscribe(jid => {
                  this.userJID = jid;
                });
  }

  selectShowGlobalSearchResults(): Observable<boolean> {
    return this.store.select(getShowGlobalSearchResults);
  }

  saveSelectedMessageId(value: string | null) {
    this.store.dispatch(new SetSearchSelectedMessageId(value));
  }

  getSelectedMessageId(): Observable<string | null> {
    return this.store.select(getSearchSelectedMessageId);
  }

  saveSelectResult() {
    this.store.dispatch(new GlobalSearchResultSelected());
  }

  saveDeselectResult() {
    this.store.dispatch(new GlobalSearchResultDeSelected());
  }

  selectSearchResultSelected(): Observable<boolean> {
    return this.store.select(getShowGlobalSearchResultSelected);
  }

  getSearchKeyword(): Observable<string> {
    return this.store.select(getSearchKeyword);
  }

  getSearchOffset(): Observable<number> {
    return this.store.select(getSearchOffset);
  }

  getSearchTotalCount(): Observable<number> {
    return this.store.select(getSearchTotal);
  }

  getAllSearchResults(): Observable<SearchMessage[]> {
    return this.store.select(getSearchResults);
  }

  clearSearch() {
    this.store.dispatch(new SearchClearAll());
  }

  globalSearchForSelectedChat(bareId: string, keyword: string, include_advance: boolean, isMention?: boolean, isStarred?: boolean): Observable<MessageSearchResponse> {
    let params: {
      nb?: string,
      na?: string,
      tags?: string[],
      users?: string[],
      mention?: string
    } | null = null;

    if (include_advance) {
      let options: AdvanceSearchRequest;
      this.store.select(getAdvanceSearchRequest).pipe(take(1)).subscribe(a => options = a);

      params = AdvanceSearchRequestUtil.mapToServiceInput(options);
    }
    if (isMention) {
      params = params || {};
      params.mention = keyword;
    }
    if (isStarred) {
      params = params || {};
      params.tags = ["*"];
    }
    return this.conversationService.globalSearch(keyword, 0, params, bareId);
  }

  searchForSelectedChat(bareId: string, keyword: string, offset = 0, isMention?: boolean, selectedDateRange?: any, isStarred?: boolean): Observable<MessageSearchResponse> {
    let params: any = {
      excludeConference: true
    };
    if (isMention) {
      params.mention = keyword.replace(/\*/g, "");
      params.textMatch = "*:*";
    }
    if (isStarred) {
      params.isStarred = true;
    }
    if (CommonUtil.isGroupTarged(bareId)) {
      params.roomMatch = bareId;
    } else {
      params.peerMatch = bareId;
      params.excludeGroupchat = true;
    }
    if(selectedDateRange){
      params.dateRange = selectedDateRange;
    }
    return this.conversationService.solrSearch(keyword, offset, params);
  }

  recentSearch(body: any): Observable<any> {
    return this.conversationService.recentSearch(body);
  }

  searchMUC(keyword: string): Observable<any> {
    return this.groupChatsService.searchMUC(keyword);
  }

  searchContacts(body: any): Observable<any> {
    return this.conversationService.searchContacts(body);
  }

  globalSearchNextPageForSelectedChat(bareId: string, keyword: string, offset: number, include_advance: boolean, isMention?: boolean, isStarred?: boolean): Observable<MessageSearchResponse> {
    let params: {
      nb?: string,
      na?: string,
      tags?: string[],
      users?: string[],
      mention?: string
    } | null = null;

    if (include_advance) {
      let options: AdvanceSearchRequest;
      this.store.select(getAdvanceSearchRequest).pipe(take(1)).subscribe(a => options = a);

      params = AdvanceSearchRequestUtil.mapToServiceInput(options);
    }
    if (isMention) {
      params = params || {};
      params.mention = keyword;
    }
    if (isStarred) {
      params.tags = ["*"];
    }

    return this.conversationService.globalSearch(keyword, offset, params, bareId);
  }

  globalSearch(keyword: string): Observable<MessageSearchResponse> {
    let options: AdvanceSearchRequest;
    this.store.select(getAdvanceSearchRequest).pipe(take(1)).subscribe(a => options = a);

    return this.conversationService.globalSearch(keyword, 0, AdvanceSearchRequestUtil.mapToServiceInput(options)).pipe(map(res => {
      this.store.dispatch(new SearchResponseLoad(res.docs));
      this.store.dispatch(new SearchKeywordUpdate(keyword));
      this.store.dispatch(new SearchOffsetUpdate(res.docs.length));
      this.store.dispatch(new SearchTotalUpdate(res.numFound));

      return res;
    }));
  }

  globalSearchNextPage(): Observable<MessageSearchResponse> {
    let keyword: string;
    let offset: number;
    let options: AdvanceSearchRequest;

    this.store.select(getSearchKeyword).pipe(take(1)).subscribe(k => keyword = k);
    this.store.select(getSearchOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getAdvanceSearchRequest).pipe(take(1)).subscribe(a => options = a);

    return this.conversationService.globalSearch(keyword, offset, AdvanceSearchRequestUtil.mapToServiceInput(options)).pipe(map(res => {
      this.store.dispatch(new SearchResponseNextPage(res.docs));
      this.store.dispatch(new SearchOffsetUpdate(offset + res.docs.length));
      this.store.dispatch(new SearchTotalUpdate(res.numFound));

      return res;
    }));
  }

  selectAdvanceSearchRequest(): Observable<AdvanceSearchRequest> {
    return this.store.select(getAdvanceSearchRequest);
  }

  saveUsersList(users: Contact[]) {
    this.store.dispatch(new SearchUsersUpdate(users));
  }

  selectSavedUsers(): Observable<Contact[]> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.users));
  }

  saveAppList(apps: AppType[]) {
    this.store.dispatch(new SearchAppUpdate(apps));
  }

  selectSavedApps(): Observable<AppType[]> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.app_types));
  }

  saveTimeRange(data: AdvanceSearchTime) {
    this.store.dispatch(new SearchTimeRangeUpdate(data));
  }

  selectTimeRange(): Observable<AdvanceSearchTime> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.time));
  }

  selectSearchResultTimeRange(): Observable<AdvanceSearchTime> {
    return this.store.select(getSearchResultTimeRange);
  }

  saveContentType(data: AdvanceSearchContentType) {
    this.store.dispatch(new SearchContentTypeUpdate(data));
  }

  selectContentType(): Observable<AdvanceSearchContentType> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.content_type));
  }

  saveAttachment(data: boolean) {
    this.store.dispatch(new SearchAttachmentUpdate(data));
  }

  saveStarred(data: boolean) {
    this.store.dispatch(new SearchStarredTypUpdate(data));
  }

  selectBoolean(): Observable<boolean> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.boolean_operators));
  }

  saveBoolean(data: boolean) {
    this.store.dispatch(new SearchBooleanUpdate(data));
  }

  mapSearchMessage(message: SearchMessage): SearchMessage {
    message.isStarred = message.tags && message.tags.indexOf("*") !== -1 ? true : false;
    message.fromJid = message.from;
    if (message.mention && message.mention !== "[]")
    {
      const mention = JSON.parse(message.mention);
      message["references"] = mention.map(m => {
        m = m.replace(/xmpp:+/ig, "");
        const data = {
          type: "mention",
          uri: `xmpp:${m}`
        };
        return data;
      });
    }
    return message;
  }

  mapSolrMessage(data: any): SearchMessage {
    const message: any = {};
    const parser = new DOMParser();
    message.isStarred = false;
    message.fromJid = data.from_s;
    message.from = {
      bare: data.from_s
    };
    message.id = data.talk_id_s;
    message.owner = data.owner_s;
    message.sort_id = data.talk_internal_id_s;
    message.body = data.content_txt[0];
    message.html = data.raw_txt ? data.raw_txt[0].replace(/\\"/ig, "\"").replace(/\\'/ig, "\'") : "";
    if (data.talk_origmeasage_txt) {
      try {
        const xml: any =  CommonUtil.convertXmlToJson(data.talk_origmeasage_txt[0]);
        message.originalMessage = xml.originalMessage;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("cannot parse original message", error);
      }
    }
    if (data.talk_forwardmessage_s) {
      try {
        const xml: any =  CommonUtil.convertXmlToJson(data.talk_forwardmessage_s[0]);
        message.forwardMessage = xml.forwardMessage;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("cannot parse forward message", error);
      }
    }
    message.type = data.talk_type_s === "chat" ? "chat" : "groupchat";
    if (data.type === "chat") {
      message.to = data.talk_jid_s.replace(`-${data.owner_s}`, "").replace(`${data.owner_s}-`, "");
    } else {
      message.to = data.talk_jid_s;

      message.room = data.talk_jid_s;
    }
    if (data.talk_attachment_s) {
      const attachment = data.talk_attachment_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
      const attachmentDoc = parser.parseFromString(attachment, "text/html");
      if (!!attachmentDoc.querySelector("url")) {
        message.attachment = {
          url: (<HTMLElement>attachmentDoc.querySelector("url")).innerText,
          fileSize: !!attachmentDoc.querySelector("fileSize") ? (<HTMLElement>attachmentDoc.querySelector("fileSize")).innerText : "",
          fileName: !!attachmentDoc.querySelector("fileName") ? (<HTMLElement>attachmentDoc.querySelector("fileName")).innerText : "",
          fileType: !!attachmentDoc.querySelector("fileType") ? (<HTMLElement>attachmentDoc.querySelector("fileType")).innerText : "",
        };
      }

    }
    if (data.talk_mention_ss) {
      message.mention = data.talk_mention_ss;
    }
    if (data.starredby_txt && data.starredby_txt.indexOf(this.userJID?.bare) !== -1) {
      message.isStarred = true;
    }
    if (data.talk_location_p) {
      message.mention = {
        lat: data.talk_location_p.split(",")[0],
        long: data.talk_location_p.split(",")[1]
      };
    }
    if (data.talk_vncconference_s) {
      const vncTalkConference = data.talk_vncconference_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
      const vncTalkConferenceDoc = parser.parseFromString(vncTalkConference, "text/html");
      if (!!vncTalkConferenceDoc.querySelector("from")) {
        message.attachment = {
          from: (<HTMLElement>vncTalkConferenceDoc.querySelector("from")).innerText,
          to: (<HTMLElement>vncTalkConferenceDoc.querySelector("to")).innerText,
          conferenceId: (<HTMLElement>vncTalkConferenceDoc.querySelector("conferenceId")).innerText,
          jitsiRoom: (<HTMLElement>vncTalkConferenceDoc.querySelector("jitsiRoom")).innerText,
          jitsiURL: (<HTMLElement>vncTalkConferenceDoc.querySelector("jitsiURL")).innerText,
          eventType: (<HTMLElement>vncTalkConferenceDoc.querySelector("eventType")).innerText,
          timestamp: (<HTMLElement>vncTalkConferenceDoc.querySelector("timestamp")).innerText,
          conferenceType: (<HTMLElement>vncTalkConferenceDoc.querySelector("conferenceType")).innerText
        };
      }
    }
    message.timestamp = new Date(data.created_dt).getTime();
    return message;
  }

  convertSearchMessage(message: SearchMessage): Message {

    let _message: any = Object.assign(message, {
      id: message.id ? message.id.replace(/.*_/ig , "") : "",
      from: {bare: message.from} as JID
    });
    for (let key of Object.keys(_message)) {
      if (key.startsWith("x_") && _message[key] !== "null") {
        try {
          if (key === "x_origMessage") {
            _message.originalMessage = JSON.parse(_message[key]);
          } else if (key === "x_replaceMsgId") {
            _message.replace = JSON.parse(_message[key]);
          }
          delete _message[key];
        } catch (ex) {

        }

      }
    }

    return _message as Message;
  }

  public getSelectedMessages(): Observable<SearchMessage[]> {
    return this.store.select(getSelectedSearchMessages);
  }

  public getAllSearchMessages(): Observable<SearchMessage[]> {
    return this.store.select(getAllSearchMessage);
  }

  public addMessage(message: SearchMessage): void {
    return this.store.dispatch(new MessageAdd({message}));
  }

  public addMessages(messages: SearchMessage[]): void {
    return this.store.dispatch(new MessageBulkAppend({messages}));
  }

  public updateMessages(messages: SearchMessage[]): void {
    return this.store.dispatch(new MessageBulkUpdate({messages}));
  }

  public resetMessages(): void {
    return this.store.dispatch(new ResetMessages());
  }

  public setSearchMode(value: boolean): void {
    this._inSearchMode = value;
  }

  public isInSearchMode(): boolean {
    return this._inSearchMode;
  }
}
