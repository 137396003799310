
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { CommentItem } from "vnc-library/lib/components/comments/models/comment.model";
import { Action } from "../actions";
import { CommentActionTypes } from "../actions/comment";

export interface CommentState extends EntityState<CommentItem> {
  isLoading: boolean;
  isLoaded: boolean;
  replies: { [commentId: number]: { offset?: number, total_count?: number, isLoading: boolean, isLoaded?: boolean } };
}

export const commentAdapter: EntityAdapter<CommentItem> = createEntityAdapter<CommentItem>({
  selectId: (comment: CommentItem) => comment.id,
  // sortComparer: sortByTimestamp
});

export function sortByTimestamp(c1: CommentItem, c2: CommentItem): number {
  return new Date(c2.created_on).getTime() - new Date(c1.created_on).getTime();
}

export const initialState: CommentState = commentAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  replies: null,
});

export function commentReducer(state: CommentState = initialState, action: Action): CommentState {
  switch (action.type) {

    case CommentActionTypes.COMMENT_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CommentActionTypes.COMMENT_LOAD_SUCCESS: {
      const comments = action.payload.map(c => {
        let comment = state.entities[c.id];
        if (comment) {
          c = {...c, ...comment};
        }
        return c;
      });
      return commentAdapter.setAll(comments, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case CommentActionTypes.COMMENT_ADD: {
      const newState = commentAdapter.addOne(action.payload, state);
      return commentAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }

    case CommentActionTypes.COMMENT_UPDATE: {
      const updatedState = commentAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case CommentActionTypes.COMMENT_BULK_ADD: {
      const newState = commentAdapter.addMany(action.payload, state);

      const changes = action.payload.map(Comment => {
        return { id: Comment.id, changes: Comment };
      });

      return commentAdapter.updateMany(changes, newState);
    }

    case CommentActionTypes.COMMENT_DELETE: {
      return commentAdapter.removeOne(action.payload, state);
    }

    case CommentActionTypes.COMMENT_REPLIES_LOAD_REQUEST: {
      return {
        ...state,
        replies: { ...state.replies, [action.payload]: {...(state?.replies?.[action.payload] || {}), isLoading: true}}
      };
    }

    case CommentActionTypes.COMMENT_REPLIES_LOAD_SUCCESS: {
      const commentId = action.payload.commentId;
      return {
        ...state,
        replies: {
          ...state.replies,
          [commentId]: {
            ...(state?.replies?.[commentId] || {}),
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count,
            offset: action.payload.offset
          }
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoading = (state: CommentState) => state.isLoading;
export const _getIsLoaded = (state: CommentState) => state.isLoaded;
export const _getCommentReplies = (state: CommentState) => state.replies;
