
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { XmppService } from "./xmpp.service";
import { MiddlewareService } from "./middleware.service";
import { map, Observable, take } from "rxjs";
import { SearchUser, LDAPData } from "../models/jid.model";
import { getUserJID, getContactById } from "../../reducers/index";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../reducers/index";
import { ContactRest } from "app/talk/models/contact-rest.model";
import { ContactUpdate } from "app/actions/contact";
import { ConfigService } from "app/config.service";

@Injectable()
export class ContactsService {

  constructor(private xmppService: XmppService,
              private middlewareService: MiddlewareService,
              private configService: ConfigService,
              private store: Store<TalkRootState>) {
  }

  searchLDAPUsers(keyword: string): Observable<SearchUser[]> {
    return this.getLDAPUsers(keyword, null);
  }

  searchIOMUsers(keyword: string): Observable<SearchUser[]> {
    return this.getIOMUsers(keyword);
  }

  retrieveLDAPUsers(jids: string[]): Observable<SearchUser[]> {
    return this.getLDAPUsers(null, jids);
  }

  private getIOMUsers(keyword: string): Observable<SearchUser[]> {
    let loggedInuserBare = "";
    this.store.select(getUserJID).pipe(take(1)).subscribe(res => loggedInuserBare = res ? res.bare : "");
    return this.middlewareService.post<LDAPContact[]>("/api/search-iom", true, {keyword}).pipe(map(res => {
      if (res === null) {
        return [];
      }
      return res.filter(user => user.mail[0] !== loggedInuserBare).map(user => {
        return this.mapLDAPUser(user);
      });
    }));
  }

  private mapLDAPUser(user) {
    const jid = user.mail ? user.mail[0] : "";
    const email = user.email ? user.email[0] : jid;
    const displayName = this.getDisplayNameFromLDAPEntry(user);
    this.store.select(state => getContactById(state, jid)).pipe(take(1)).subscribe(contact => {
      if (contact && contact.name === jid.split("@")[0]) {
        this.store.dispatch(new ContactUpdate({id: jid, changes: {name: displayName}}));
      }
    });
    return {
      name: displayName,
      jid,
      email,
      ldapData: user as LDAPData
    };
  }

  getProfile(user) {
    const jid = !!user.mail ? user.mail[0] : user.jid;
    const email = user.email ? user.email[0] : jid;
    const displayName = this.getDisplayNameFromLDAPEntry(user);
    const full = displayName.split(" ");
    const first_name = full.pop();
    const last_name = full.join(" ");
    return {
      name: displayName,
      first_name,
      last_name,
      jid,
      email
    };
  }

  private getLDAPUsers(keyword: string, jids: string[]): Observable<SearchUser[]> {
    // console.trace("[getLDAPUsers]");

    let loggedInuserBare = "";
    this.store.select(getUserJID).pipe(take(1)).subscribe(res => loggedInuserBare = res ? res.bare : "");

    const queryParams: any = keyword ? {"keyword": keyword} : {"jids": jids};
    queryParams.searchIOM = true;
    return this.middlewareService.post<LDAPContact[]>("/api/search-ldap", true, queryParams).pipe(map(res => {
      if (res === null) {
        return [];
      }
      return res.filter(user => user.mail[0] !== loggedInuserBare).map(user => {
        return this.mapLDAPUser(user);
      });
    }));
  }


  createContact(contact: ContactRest): Observable<any> {
    const data = this.mapModelToMidlewareParams(contact);
    if (this.configService.get("vncdDisableUserToContactMapping")) {
      data.contact.is_iom = true;
    }
    return this.middlewareService.post("/api/contacts.json", true, data);
  }

  createGroup(name: string, contact_ids?: number[]): Observable<any> {
    const data = {
      contact_group: {
        name: name,
        contact_ids: contact_ids || []
      }
    };
    return this.middlewareService.post("/api/contact_groups.json", true, data);
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.middlewareService.delete(`/api/contact_groups/${groupId}`, true);
  }

  deleteAvatar(target: string): Observable<any> {
    return this.middlewareService.delete(`/api/delete_avatar/${target}`, true);
  }

  updateGroupContacts(groupId: number, contact_ids: number[]): Observable<any> {
    const data = {
      contact_group: {
        contact_ids: contact_ids || []
      }
    };
    return this.middlewareService.put(`/api/contact_groups/${groupId}`, true, data);
  }

  updateGroupName(groupId: number, groupName: string): Observable<any> {
    const data = {
      contact_group: {
        name: groupName
      }
    };
    return this.middlewareService.put(`/api/contact_groups/${groupId}`, true, data);
  }

  updateContact(contact: ContactRest): Observable<null> {
    const data = this.mapModelToMidlewareParams(contact);
    return this.middlewareService.put(`/api/contacts/${contact.id}`, true, data);
  }

  getContacts(queryParams: any): Observable<any> {
    let data = {};
    if (queryParams) {
      data = queryParams;
    }
    return this.middlewareService.get<ContactRest[]>(`/api/contacts.json`, true, data);
  }

  getGroups(queryParams: any): Observable<any> {
    let data = {};
    if (queryParams) {
      data = queryParams;
    }
    return this.middlewareService.get<ContactRest[]>(`/api/contact_groups.json`, true, data);
  }

  getContact(contactId: number): Observable<ContactRest> {
    return this.middlewareService.get<ContactRest>(`/api/contacts/${contactId}`, true);
  }

  deleteContact(contactId: number): Observable<null> {
    return this.middlewareService.delete(`/api/contacts/${contactId}`, true);
  }

  notifyOnAvatarUpdate(): Observable<null> {
    // it is dummy payload, as the actual user info is in auth info
    const data = { updated: "user@domain" };
    return this.middlewareService.post(`/api/onavatarupdate`, true, data);
  }

  private mapModelToMidlewareParams(contact: ContactRest): any {
    let contactParams = {...contact};
    if (contact.phones && contact.phones.length > 0) {
      let phones_attributes = {};
      for (let i = 0; i < contact.phones.length; ++i) {
        phones_attributes[i.toString()] = contact.phones[i];
      }
      contactParams["phones_attributes"] = phones_attributes;
    }
    if (contact.urls && contact.urls.length > 0) {
      let urls_attributes = {};
      for (let i = 0; i < contact.urls.length; ++i) {
        urls_attributes[i.toString()] = contact.urls[i];
      }
      contactParams["urls_attributes"] = urls_attributes;
    }
    if (contact.emails && contact.emails.length > 0) {
      let emails_attributes = {};
      for (let i = 0; i < contact.emails.length; ++i) {
        emails_attributes[i.toString()] = contact.emails[i];
      }
      contactParams["emails_attributes"] = emails_attributes;
      this.store.select(getUserJID).pipe(take(1)).subscribe(v => {
        if (!!contact.jid) {
          contact.bare = contact.jid;
        }
        const ownDomain = v.bare.split("@")[1];
        const contactDomain = contact.bare.split("@")[1];
        contactParams["is_iom"] = false;
        contactParams["jid"] = contact.bare;
        if (contactDomain !== ownDomain) {
          contactParams["is_iom"] = true;
        }
      });
    }
    if (contact.addresses && contact.addresses.length > 0) {
      let addresses_attributes = {};
      for (let i = 0; i < contact.addresses.length; ++i) {
        addresses_attributes[i.toString()] = contact.addresses[i];
      }
      contactParams["addresses_attributes"] = addresses_attributes;
    }
    return {"contact": contactParams};
  }

  private getDisplayNameFromLDAPEntry(user: LDAPContact) {
    if (user.displayName && user.displayName.length > 0) {
      return user.displayName[0];
    }

    if (user.givenName && user.givenName.length > 0) {
      if (user.sn && user.sn.length > 0) {
        return user.givenName[0] + " " + user.sn[0];
      }

      return user.givenName[0];
    }

    if (user.sn && user.sn.length > 0) {
      return user.sn[0];
    }

    return "";
  }
}

interface LDAPContact {
  givenName: string[];
  sn: string[];
  mail: string[]; // jid
  email?: string[]; // email
  uid: string[];
  displayName: string[];
}
