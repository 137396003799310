
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Conversation } from "../models/conversation.model";
import punycode from "punycode/punycode";

export class ConversationUtil {
  // PRASHANT_COMMENT all the methods that apply on conversation interface goes here.

  static createLocalConversation(target: string, subject: string, type: string = "groupchat", content: string = "", isE2EE: boolean = false, isTemporary: boolean = false): Conversation {
    const conv: Conversation = {
      Target: target,
      Timestamp: new Date().getTime(),
      // updated_at: Math.round(new Date().getTime() / 1000),
      type: type,
      content: content,
      archived: false,
      deleted: false,
      state: "active",
      unreadIds: [],
      unread_mentions: [],
      last_mention_stamp: Math.round(new Date().getTime() / 1000),
      total_mentions: 0,
      groupChatTitle: subject,
      encrypted: isE2EE,
      isTemporary
    };


    return conv;
  }

  static isGroupChat(conversation: Conversation) {
    return this.getType(conversation) === "groupchat";
  }

  static getType(conversation: Conversation) {
    return conversation.type;
  }

  static getGroupChatTitle(target: string) {
    if (!target) {
      return "";
    }

    let result = "";

    if (target.indexOf("talk") !== -1) {
      result = target.split("@")[0];
      return result;
    }

    try {
      if (target.startsWith("xn--")) {
        result = punycode.decode(target.split("@")[0].replace("xn--", "")).replace(/__/ig, " ");
      } else {
        result = target.split("@")[0].replace(/__/ig, " ");
      }
      return result;
    } catch (e) {
      result = target.split("@")[0]; // FIXME: same as above
      return result;
    }
  }

  static isConvsChanged(newConversations: Conversation[], existingConversations: Conversation[]){

    let changed = false;

    if (newConversations.length > 0 && existingConversations.length > 0) {
      if (newConversations.length !== existingConversations.length) {
        changed = true;
      } else {
        for (let i = 0; i < newConversations.length; ++i) {
          if (newConversations[i].Target !== existingConversations[i].Target ||
              newConversations[i].pad_read !== existingConversations[i].pad_read ||
              newConversations[i].encrypted !== existingConversations[i].encrypted ||
              newConversations[i].archived !== existingConversations[i].archived ||
              newConversations[i].content !== existingConversations[i].content || // last message text
              newConversations[i].groupChatTitle !== existingConversations[i].groupChatTitle ||
              newConversations[i].mute_notification !== existingConversations[i].mute_notification ||
              newConversations[i].mute_sound !== existingConversations[i].mute_sound ||
              newConversations[i].has_pads !== existingConversations[i].has_pads ||
              newConversations[i].Timestamp !== existingConversations[i].Timestamp ||
              newConversations[i].broadcast_title !== existingConversations[i].broadcast_title ||
              // newConversations[i].read !== existingConversations[i].read ||
              newConversations[i].has_active_call !== existingConversations[i].has_active_call ||
              newConversations[i].received_receipt !== existingConversations[i].received_receipt ||
              (newConversations[i].unreadIds && existingConversations[i].unreadIds && newConversations[i].unreadIds.length !== existingConversations[i].unreadIds.length) ||
              (newConversations[i].unread_mentions && existingConversations[i].unread_mentions && newConversations[i].unread_mentions.length !== existingConversations[i].unread_mentions.length)) {
            changed = true;
            break;
          }
        }
      }
    } else {
      changed = true;
    }
    return changed;
  }
}
