/* eslint no-console: 0 */

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { LoggerService } from "app/shared/services/logger.service";
import { Broadcaster } from "app/talk/shared/providers/broadcaster.service";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { ContactRepository } from "app/talk/repositories/contact.repository";
import { TalkRootState, getActiveConference } from "app/talk/reducers";
import { Observable, BehaviorSubject, Subject, bufferTime, combineLatestAll, distinctUntilChanged, filter, map, pairwise, sampleTime, skip, switchMap, take, tap} from "rxjs";
import { Store } from "@ngrx/store";
import { CommonUtil } from "app/talk/utils/common.util";
import { ConstantsUtil } from "app/talk/utils/constants.util";

@Injectable()
export class CallkitService {
    cordovaCall: any;
    calldata = new BehaviorSubject<any>({});
    lastCallDate = new BehaviorSubject<any>({});
    callName: string;
    activeCall: boolean;
    isOnIOS: boolean;

    constructor(private store: Store<TalkRootState>,
        private conversationRepo: ConversationRepository,
        private contactRepo: ContactRepository,
        private broadcaster: Broadcaster) {
        console.log("[CallkitService][constructor]");
    }

    init() {
        this.calldata.next({});
        this.lastCallDate.next({});
        this.isOnIOS = CommonUtil.isOnIOS();
        console.log("[CallkitService][init]");
        if (!CommonUtil.isOnNativeMobileDevice) {
          this.broadcaster.on<any>("onConferenceJoined").subscribe(() => {
            console.log("[ForeGroundMove] onConferenceJoined ... ");
            this.notifyCallConnected();
          });
          this.broadcaster.on<any>("notifyCallEnded").subscribe(() => {
            this.notifyCallEnded();
          });
        }

        document.addEventListener("deviceready", () => {
            console.log("[CallkitService][init] cordova.plugins.CordovaCall is now available");
            this.cordovaCall = cordova.plugins.CordovaCall;

            this.config();
            this.initCallbacks();
            this.notifyCallEnded();
        });
    }

    private config() {
        console.log("[CallkitService][config]");
        // this.cordovaCall.setRingtone("incoming-call-loop"); // TODO set ringtone
        this.cordovaCall.setIncludeInRecents(false);
        // this.cordovaCall.setVideo(true);
        this.cordovaCall.setIcon("CallIcons");
    }

    private initCallbacks() {
        console.log("[CallkitService][initCallbacks]");
        this.cordovaCall.on("answer", this.onCallAccepted.bind(this));
        this.cordovaCall.on("hangup", this.onCallHangup.bind(this));
        this.cordovaCall.on("reject", this.onCallRejected.bind(this));
        this.cordovaCall.on("receiveCall", this.onNewCallReceived.bind(this));
        this.cordovaCall.on("sendCall", this.onCallStartedFromRecents.bind(this));
        this.cordovaCall.on("mute", () => {
            this.onMicMuted(true);
        });
        this.cordovaCall.on("unmute", () => {
            this.onMicMuted(false);
        });
        this.cordovaCall.on("speakerOn", () => {
            this.onSpeakerEnabled(true);
        });
        this.cordovaCall.on("speakerOff", () => {
            this.onSpeakerEnabled(false);
        });

        this.broadcaster.on<any>("onConferenceJoined").subscribe(() => {
            console.log("[ForeGroundMove][initcallback] onConferenceJoined ... ");
            this.notifyCallConnected();
        });
        this.broadcaster.on<any>("notifyCallEnded").subscribe(() => {
            this.notifyCallEnded();
        });
        this.broadcaster.on<boolean>("notifyMicMuted").subscribe(isMuted => {
            this.notifyMicMuted(isMuted);
        });
        this.broadcaster.on<boolean>("notifySpeakerEnabled").subscribe(isEnabled => {
            this.notifySpeakerEnabled(isEnabled);
        });
        this.broadcaster.on<boolean>("notifySpeakerEnabledBluetooth").subscribe(isEnabled => {
          this.notifySpeakerEnabled(isEnabled, true);
        });
        this.broadcaster.on<string>("updateRingtone").subscribe(newRingtoneName => {
            this.updateRingtone(newRingtoneName);
        });
    }

    getCallData() {
      const data = {
        current: this.calldata.value,
        last: this.lastCallDate.value
      };
      return data;
    }

    setIncomingCall(data: any) {
      if (!this.cordovaCall) return;

      console.log("[callKitService][setIncomingCall] olddata:", this.calldata.value);

        console.log("[callKitService][setIncomingCall] data:", data);
        const oldData = this.calldata.value;
        this.lastCallDate.next(oldData);
        this.calldata.next(data);
        let displayName = "vnctalk call";
        if (!!data.target && !!data.groupName && (data.groupName !== "")) {
          displayName = data.groupName;
        } else {
          displayName = data.username;
        }

        this.cordovaCall.receiveCall(displayName, data.target, success => {
          console.log("callKitService should connect call");
        }, error => {
          console.error("callKitService setIncomingCall error: ", displayName, data.target, error);
        });

    }

    cancelIncomingCall(data:any){
      console.log("[callKitService][cancelIncomingCall] data:", this.activeCall, data, this.calldata.value, Object.keys(this.calldata.value).length);
      console.log("[callKitService][cancelIncomingCall1] data:", data, this.calldata.value, this.lastCallDate.value);
      if (!this.cordovaCall) {
        console.log("[callKitService][cancelIncomingCall1] no cordovaCall - bailing out");
        return;
      }
      try {
        if ((Object.keys(this.calldata.value).length === 0) && (Object.keys(this.lastCallDate.value).length === 0)) {
          console.log("[callKitService][cancelIncomingCall1] no calldata.values - bailing out");
          return;
        }

      } catch (error) {
        console.error("[callKitService][cancelIncomingCall] error: ", error);
      }


      this.notifyCallEnded();
      this.calldata.next({});

    }

    public iosEndCall(): Observable<any> {
      const response = new Subject<any>();
      if (!this.cordovaCall) {
        setTimeout(() => {
          response.next("no cordovacall");
        }, 1);
      }
      this.cordovaCall.endCall(() => {
        response.next("cordovacall endCall success");
      }, () => {
        response.next("cordovacall endCall error?");
      });

      return response.asObservable().pipe(take(1));
    }

    private onCallAccepted(data: any) {
        // [currentCallData setObject:callType forKey:@"call_type"];
        // [currentCallData setObject:chatType forKey:@"call_signal_type"];
        // [currentCallData setObject:initiatorName forKey:@"initiator_name"];
        // [currentCallData setObject:jitsiRoom forKey:@"jitsiRoom"];
        // [currentCallData setObject:jitsiURL forKey:@"jitsiURL"];
        // [currentCallData setObject:callId forKey:@"call_id"];
        // [currentCallData setObject:callerId forKey:@"from_jid"];
        // [currentCallData setObject:sound forKey:@"sound"];
        // [currentCallData setObject:conferenceId forKey:@"conferenceId"];

        console.log("[CallkitService][onCallAccepted]", data, this.calldata.value);

        if (CommonUtil.isOnIOS()) {
          const event = new CustomEvent("onCallAcceptedFromCallKit", {detail: data});
          document.dispatchEvent(event);
          this.broadcaster.broadcast("onCallAcceptedFromCallKit", data);
        } else {
          let event = {
            extra_call_action: "TalkCallAccept",
            extra_jitsi_room: this.calldata.value.jitsiRoom,
            extra_jitsi_url: this.calldata.value.jitsiURL,
            id: this.calldata.value.msgid,
            message: "audio",
            tap: true,
            vncEventType: "audio",
            vncInitiatorJid: this.calldata.value.target,
            vncPeerJid: this.calldata.value.target
          };
          localStorage.setItem("vncPeerJid", this.calldata.value.target);
          localStorage.setItem("vncEventType", "audio");
          localStorage.setItem("vncCallkitNotification", JSON.stringify(event));
          this.activeCall = true;
          if ((!!event.vncPeerJid) && (event.vncPeerJid !== "undefined")) {
            // maybe add force param here when
            console.log("[CallkitService][onCallAccepted] -> handlePushNotification ", event);
            localStorage.setItem("extraCallAction", "TalkCallAccept");
            this.broadcaster.broadcast("CALLKITACCEPTED", event);
            // this.conversationRepo.handlePushNotification(event);
          }

        }
    }

    private onCallHangup(data: any) {
        // [currentCallData setObject:callType forKey:@"call_type"];
        // [currentCallData setObject:chatType forKey:@"call_signal_type"];
        // [currentCallData setObject:initiatorName forKey:@"initiator_name"];
        // [currentCallData setObject:jitsiRoom forKey:@"jitsiRoom"];
        // [currentCallData setObject:jitsiURL forKey:@"jitsiURL"];
        // [currentCallData setObject:callId forKey:@"call_id"];
        // [currentCallData setObject:callerId forKey:@"from_jid"];
        // [currentCallData setObject:sound forKey:@"sound"];
        // [currentCallData setObject:conferenceId forKey:@"conferenceId"];
        console.log("[callKitService][onCallHangup] data:", data, this.calldata.value);
        this.calldata.next({});
        console.log("[CallkitService][onCallHangup]", data);
        this.broadcaster.broadcast("onCallHangup", data);
    }

    private onCallRejected(data: any) {
        // [currentCallData setObject:callType forKey:@"call_type"];
        // [currentCallData setObject:chatType forKey:@"call_signal_type"];
        // [currentCallData setObject:initiatorName forKey:@"initiator_name"];
        // [currentCallData setObject:jitsiRoom forKey:@"jitsiRoom"];
        // [currentCallData setObject:jitsiURL forKey:@"jitsiURL"];
        // [currentCallData setObject:callId forKey:@"call_id"];
        // [currentCallData setObject:callerId forKey:@"from_jid"];
        // [currentCallData setObject:sound forKey:@"sound"];
        // [currentCallData setObject:conferenceId forKey:@"conferenceId"];
        this.calldata.next({});
        console.log("[CallkitService][onCallRejected]", data);
        this.broadcaster.broadcast("onCallRejected", data);
    }

    private onNewCallReceived(data: any) {
        // [currentCallData setObject:callType forKey:@"call_type"];
        // [currentCallData setObject:chatType forKey:@"call_signal_type"];
        // [currentCallData setObject:initiatorName forKey:@"initiator_name"];
        // [currentCallData setObject:jitsiRoom forKey:@"jitsiRoom"];
        // [currentCallData setObject:jitsiURL forKey:@"jitsiURL"];
        // [currentCallData setObject:callId forKey:@"call_id"];
        // [currentCallData setObject:callerId forKey:@"from_jid"];
        // [currentCallData setObject:sound forKey:@"sound"];
        // [currentCallData setObject:conferenceId forKey:@"conferenceId"];
        console.log("[CallkitService][onNewCallReceived]", data);
        this.broadcaster.broadcast("onNewCallReceived", data);
    }

    private onCallStartedFromRecents(data: any) {
        // NSDictionary *callData = @{@"callName":action.contactIdentifier,
        //                             @"callId": action.handle.value,
        //                             @"isVideo": action.video?@YES:@NO,
        //                             @"message": @"sendCall event called successfully"};
        console.log("[CallkitService][onCallStartedFromRecents]", data);
        this.cordovaCall.connectCall(success => {
          try {
            setTimeout(() => {
              console.log("[CallkitService][onCallStartedFromRecents] tofront", data);
              console.log("[ForeGroundMove] moving to foreground onCallStartedFromRecents");
              cordova.plugins.backgroundMode.moveToForeground();
              // cordova.plugins.bringtofront();
            }, 500);
          } catch (error) {
            console.log("failed to move tor foreground");
            console.log("[ForeGroundMove] failed onCallStartedFromRecents");
          }
          console.log("callKitService connectcall success");
          if (!this.isOnIOS) {
            this.cordovaCall.unmute();
          }
        });

        this.broadcaster.broadcast("onCallStartedFromRecents", data);
    }

    private onMicMuted(isMuted: boolean) {
        console.log("[CallkitService][onMicMuted] isMuted", isMuted);
        this.broadcaster.broadcast("onMicMuted", isMuted);
    }

    private onSpeakerEnabled(isEnabled: boolean) {
        console.log("[CallkitService][onSpeakerEnabled] onSpeakerEnabled", isEnabled);
        this.broadcaster.broadcast("onSpeakerEnabled", isEnabled);
    }

    private setCallConnected(displayName: string, convTarget: string) {
      console.log("[CallkitService][notifyCallConnected] setCallConnected:", displayName, convTarget);
      console.log("[CallkitService][notifyCallConnected] setCallConnected calldata: ", JSON.stringify(this.calldata.value));
      if (!this.cordovaCall) return;

      const callid = !!convTarget ? "vnctalk-" + btoa(convTarget) : "vnctalk-" + Date.now();
      const callDisplayName = !! displayName ? displayName : "VNCtalk Call";
      if ((!this.activeCall) && (Object.keys(this.calldata.value).length === 0)) {
        console.log("[CallkitService][notifyCallConnected] setCallConnected sendCall: ", callDisplayName);
        this.cordovaCall.sendCall(callDisplayName, callid, success => {
        // this.cordovaCall.receiveCall(displayName, callid, success => {
          this.activeCall = true;
/*          setTimeout(() => {
            try {
              this.cordovaCall.connectCall(success => {
                console.log("callKitService connectcall success");
                this.cordovaCall.unmute();
              });
            } catch (error) {
              console.error("callKitService connectcall error: ", error);
            }
          }, 500); */
        }), error => {
          console.error("callKitService receiveCall error: ", error);
        };
      } else {
        console.log("[CallkitService][notifyCallConnected] setCallConnected connectCall: ", callDisplayName);
        this.cordovaCall.connectCall();
      }

    }

    private setSingleCallConnetced(target: string) {
      this.contactRepo.getContactById(target).pipe(take(1)).subscribe((contact) => {
        console.log("[CallkitService][notifyCallConnected] setSingleCallConnetced raw: ", contact);
        if (!!contact && !!contact.name) {
          console.log("[CallkitService][notifyCallConnected] setSingleCallConnetced name: ", contact.name);
          this.setCallConnected(contact.name, target);
        } else {
          if (!!target) {
            console.log("[CallkitService][notifyCallConnected] setSingleCallConnetced name: ", target.split("@")[0]);
            this.setCallConnected(target.split("@")[0], target);
          } else {
            this.setCallConnected(null, null);
          }
        }

        setTimeout(() => {
          try {
            console.log("[ForeGroundMove] setSingleCallConnetced moving to foreground");
            // cordova.plugins.bringtofront();
            cordova.plugins.backgroundMode.moveToForeground();
          } catch (error) {
            console.log("[ForeGroundMove] setSingleCallConnetced failed to move tor foreground");
          }
        }, 2500);

      });
    }

    private setGroupCallConnetced(target: string) {
      this.conversationRepo.getConversationById(target).pipe(take(1)).subscribe(v => {
        console.log("[CallkitService][setGroupCallConnetced] name: ", v.displayName);
        this.setCallConnected(v.displayName, target);
      });
    }


    // methods for updating native call UI
    private notifyCallConnected() {
        console.log("[CallkitService][notifyCallConnected1] ", this.activeCall);
        if (this.isOnIOS) {
          if (!this.cordovaCall) return;
          this.cordovaCall.setVideo(true, vsuccess => {
            this.cordovaCall.connectCall(success => {
              console.log("[CallkitService][notifyCallConnected] success");
              if (!this.isOnIOS) {
                this.cordovaCall.unmute();
              }
            });
          });
        } else {
          if (this.activeCall) {
            this.cordovaCall.connectCall(success => {
              console.log("callKitService notifyCallConnected connectcall success");
              setTimeout(() => {
                try {
                  console.log("[ForeGroundMove] moving to foreground");
                  cordova.plugins.backgroundMode.moveToForeground();
                  // cordova.plugins.bringtofront();
                } catch (error) {
                  console.log("[ForeGroundMove] failed to move tor foreground");
                }
              }, 2500);
              if (!this.isOnIOS) {
                this.cordovaCall.unmute();
              }
            });
            this.store.select(getActiveConference).pipe(take(1)).subscribe(convTarget => {
              console.log("callKitService notifyCallConnected1 activeConv: ", convTarget);
            });
          } else {
            this.store.select(getActiveConference).pipe(take(1)).subscribe(convTarget => {
              console.log("callKitService notifyCallConnected activeConv: ", convTarget);
              if (!!convTarget && (convTarget.indexOf("@conference") > -1)) {
                this.setGroupCallConnetced(convTarget);
              } else {
                this.setSingleCallConnetced(convTarget);
              }
            });
          }
        }
    }

    private notifyCallEnded() {
        console.log("[CallkitService][notifyCallEnded] ", !!this.cordovaCall);
        if (!this.cordovaCall) return;

        this.activeCall = false;
        this.cordovaCall.endCall();
    }

    private notifyMicMuted(isMuted: boolean) {
        console.log("[CallkitService][notifyMicMuted] isMuted", isMuted);
        if (!this.cordovaCall) return;
        if (this.isOnIOS) return;

        if (isMuted) {
            this.cordovaCall.mute();
        } else {
            this.cordovaCall.unmute();
        }
    }

    private notifySpeakerEnabled(isEnabled: boolean, isBT = false) {
        console.log("[CallkitService][notifySpeakerEnabled] isEnabled", isEnabled);
        if (!this.cordovaCall) return;
        if (this.isOnIOS) return;

        if (isEnabled) {
            this.cordovaCall.speakerOn(() => {
              console.log("[CallkitService][turnSpeakerOn] success");
            }, err => {
              console.error("[CallkitService][turnSpeakerOn] failed ", err);
            });
        } else {
          if (isBT) {
            this.cordovaCall.speakerOffBluetooth(() => {
              console.log("[CallkitService][turnSpeakerOff] success");
            }, err => {
              console.error("[CallkitService][turnSpeakerOff] failed ", err);
            });

          } else {
            this.cordovaCall.speakerOff(() => {
              console.log("[CallkitService][turnSpeakerOff] success");
            }, err => {
              console.error("[CallkitService][turnSpeakerOff] failed ", err);
            });
          }
        }
    }

    // TODO call it after changing ringtone in settings
    public updateRingtone(ringtoneName: string) {
        console.log("[CallkitService][updateRingtone] ringtoneName", ringtoneName);
        if (!this.cordovaCall) return;

        this.cordovaCall.setRingtone(ringtoneName);
    }


}
