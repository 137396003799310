
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { JitsiParticipant, JitsiOption } from "../models/jitsi-participant.model";
import { JID } from "../models/jid.model";
import { Recipient } from "../models/conversation.model";

export class ConferenceActionTypes {

  static UPDATE_JITSI_CONFIG = "[CONFERENCE] Update Jitsi Options";
  static START_LOAD_JITSI_CONFIG = "[CONFERENCE] Start Load Jitsi Config";
  static STOP_LOAD_JITSI_CONFIG = "[CONFERENCE] Stop Load Jitsi Config";

  static CONFERENCE_START = "[CONFERENCE] Start Request";
  static SET_CONFERENCE_TYPE = "[CONFERENCE] Set Conference Type";
  static RESET_CONFERENCE = "[CONFERENCE] Start Reset";
  static CONFERENCE_LEAVE = "[CONFERENCE] Leave Request";
  static CONFERENCE_START_SUCCESS = "[CONFERENCE] Start Request Success";
  static CONFERENCE_LEAVE_SUCCESS = "[CONFERENCE] Leave Request Success";

  static CONFERENCE_ADD_PARTICIPANT = "[CONFERENCE] Add Participant ";
  // sct
  static JITSI_CONFERENCE_ADD_PARTICIPANT = "[CONFERENCE] Jitsi Add Participant";
  static CONFERENCE_REMOVE_PARTICIPANT = "[CONFERENCE] Remove Participant ";
  // sct
  static JITSI_CONFERENCE_REMOVE_PARTICIPANT = "[CONFERENCE] Jitsi Remove Participant ";
  static WHITEBOARD_START = "[CONFERENCE] Whiteboard Start";

  static MUTE_AUDIO = "[CONFERENCE] Mute Audio ";
  static UNMUTE_AUDIO = "[CONFERENCE] Unmute Audio ";
  static MUTE_VIDEO = "[CONFERENCE] Mute Video ";
  static UNMUTE_VIDEO = "[CONFERENCE] Unmute Video ";
  static SHARE_SCREEN = "[CONFERENCE] Share Screen ";
  static UNSHARE_SCREEN = "[CONFERENCE] UnShare Screen ";
  static SET_FULL_SCREEN_PARTICIPANT = "[CONFERENCE] Set Full Screen participant ";
  static SET_SELECTED_PARTICIPANT = "[CONFERENCE] Select participant";
  // sct
  static SET_AUDIO_STATUS = "[CONFERENCE] set audio status";
  static SET_VIDEO_STATUS = "[CONFERENCE] set video status";

  static SET_MICROPHONE_STATUS = "[CONFERENCE] set microphone status";
  static SET_SPEAKER_STATUS = "[CONFERENCE] set speaker status ";
  static SET_WEBCAM_STATUS = "[CONFERENCE] set webcam ";
  static SET_AVAILABLE_MEDIA_DEVICES = "[CONFERENCE] SET_AVAILABLE_MEDIA_DEVICES";

  static UPDATE_FRONT_CAMERA_ID = "[CONFERENCE] update front camera id";
  static UPDATE_BACK_CAMERA_ID = "[CONFERENCE] update back camera id ";
  static SET_JITSI_ROOM = "[CONFERENCE] set jitsi room";
  static SET_CONFERENCE_ID = "[CONFERENCE] set conference id";
  static SET_STREAM_ID = "[CONFERENCE] set stream id";
  static SET_CONVERSATION_TARGET = "[CONFERENCE] set conversation target";
  static CONFERENCE_SHOW_ACTIVE_CALL = "[CONFERENCE] show active call";
  static CONFERENCE_HIDE_ACTIVE_CALL = "[CONFERENCE] hide active call";

  static CONFERENCE_SET_EXISTING_CALL = "[CONFERENCE] set active call";
  static CONFERENCE_RESET_EXISTING_CALL = "[CONFERENCE] reset active call";
  static ANONYMOUS_CONFERENCE = "[CONFERENCE] Anonymous conference";
  static CONFERENCE_CURRENT_VIEW = "[CONFERENCE] set Current View";
  static SET_SPEAKING_PARTICIPANT = "[CONFERENCE] Set speaking participant";
  static CONFERENCE_MUTE_PARTICIPANT = "[CONFERENCE] Mute for me";
  static CONFERENCE_UNMUTE_PARTICIPANT = "[CONFERENCE] Unmute for me";
  static SET_SCREEN_SHARING_REQUEST = "[CONFERENCE] Set Screen Sharing Request";
  static SET_SCREEN_SHARING_STARTED = "[CONFERENCE] Set Screen Sharing Started";
  static RESET_SCREEN_SHARING = "[CONFERENCE] Reset Screen Sharing";
  static SET_SCREEN_SHARING_REQUEST_STATUS = "[CONFERENCE] Set Screen Sharing Status";
  static TOGGLE_E2EE = "[CONFERENCE] TOGGLE_E2EE";
  static SET_E2EE_STATUS = "[CONFERENCE] SET_E2EE_STATUS";
  static SET_E2EE_IDKEY = "[CONFERENCE] SET_E2EE_IDKEY";
  static KNOCKING_PARTICIPANT_ARRIVED_OR_UPDATED = "[LOBBY] LOBBY KNOCKING_PARTICIPANT_ARRIVED_OR_UPDATED";
  static KNOCKING_PARTICIPANT_LEFT = "[LOBBY] KNOCKING_PARTICIPANT_LEFT";
  static SET_KNOCKING_STATE = "[LOBBY] SET_KNOCKING_STATE";
  static SET_LOBBY_MODE_ENABLED = "[LOBBY] SET_LOBBY_MODE_ENABLED";
  static SET_PASSWORD_JOIN_FAILED = "[LOBBY] SET_PASSWORD_JOIN_FAILED";
  static UPDATE_LOBBY_STATE = "[LOBBY] UPDATE_LOBBY_STATE";
  static SET_VIRTUAL_BACKGROUND = "[CONFERENCE] SET_VIRTUAL_BACKGROUND";
  static BACKGROUND_ENABLED = "[CONFERENCE] BACKGROUND_ENABLED";
  static SET_UPLOADED_BACKGROUND = "[CONFERENCE] SET_UPLOADED_BACKGROUND";
  static TOGGLE_MUTE_EVERYONE = "[CONFERENCE] TOGGLE_MUTE_EVERYONE";
  static TOGGLE_FLOATING_VIDEO = "[CONFERENCE] TOGGLE_FLOATING_VIDEO";
  static TOGGLE_MUTE_CAMERA = "[CONFERENCE] TOGGLE_MUTE_CAMERA";
  static SUPPRESSION_ENABLED = "[CONFERENCE] SUPPRESSION_ENABLED";
}

export class UpdateLobbyState implements Action {
  readonly type = ConferenceActionTypes.UPDATE_LOBBY_STATE;

  constructor(public payload: boolean) {

  }
}

export class BackgroundEffectEnabled implements Action {
  readonly type = ConferenceActionTypes.BACKGROUND_ENABLED;
  constructor(public payload: boolean) {
  }
}

export class SetNoiseSuppressionEnabled implements Action {
  readonly type = ConferenceActionTypes.SUPPRESSION_ENABLED;
  constructor(public payload: boolean) {
  }
}

export class ToggleFloatingVideo implements Action {
  readonly type = ConferenceActionTypes.TOGGLE_FLOATING_VIDEO;
  constructor(public payload: boolean) {
  }
}

export class SetVirtualBackground implements Action {
  readonly type = ConferenceActionTypes.SET_VIRTUAL_BACKGROUND;
  constructor(public payload: any) {
  }
}

export class SetUploadedBackground implements Action {
  readonly type = ConferenceActionTypes.SET_UPLOADED_BACKGROUND;
  constructor(public payload: any) {
  }
}

export class KnockingParticipantLeft implements Action {
  readonly type = ConferenceActionTypes.KNOCKING_PARTICIPANT_LEFT;
  constructor(public payload: string) {
  }
}

export class ParticipantIsKnockingOrUpdated implements Action {
  readonly type = ConferenceActionTypes.KNOCKING_PARTICIPANT_ARRIVED_OR_UPDATED;
  constructor(public payload: any) {

  }
}

export class UpdateJitsiConfig implements Action {
  readonly type = ConferenceActionTypes.UPDATE_JITSI_CONFIG;

  constructor(public payload: any) {

  }
}

export class StartLoadJitsiConfig implements Action {
  readonly type = ConferenceActionTypes.START_LOAD_JITSI_CONFIG;
}

export class StopLoadJitsiConfig implements Action {
  readonly type = ConferenceActionTypes.STOP_LOAD_JITSI_CONFIG;
}

export class ConferenceStartRequest implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_START;
  constructor(public payload: { conversationTarget: string, conferenceType: string, startFromInvitation?: boolean, invitedParticipants?: JID[], externalParticipants?: Recipient[]  }) {
  }
}

export class SetConferenceType implements Action {
  readonly type = ConferenceActionTypes.SET_CONFERENCE_TYPE;
  constructor(public payload: string) {
  }
}

export class ToggleE2EE implements Action {
  readonly type = ConferenceActionTypes.TOGGLE_E2EE;
  constructor(public payload: boolean) {
  }
}

export class SetParticipantE2EEIndexKey implements Action {
  readonly type = ConferenceActionTypes.SET_E2EE_STATUS;
  constructor(public payload: {id: string, idKey: string}) {
  }
}

export class SetParticipantE2EEStatus implements Action {
  readonly type = ConferenceActionTypes.SET_E2EE_STATUS;
  constructor(public payload: {id: string, status: boolean}) {
  }
}

export class AnonymousConference implements Action {
  readonly type = ConferenceActionTypes.ANONYMOUS_CONFERENCE;
  constructor(public payload: { participantEmail: string, jitsiRoom: any}) {
  }
}

export class ResetConferenceStartRequest implements Action {
  readonly type = ConferenceActionTypes.RESET_CONFERENCE;
  constructor() {
  }
}

export class ConferenceStartSuccess implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_START_SUCCESS;

  constructor(public payload: { conferenceId: string, participants: JitsiParticipant[] }) {

  }
}

export class SetCurrentView implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_CURRENT_VIEW;

  constructor(public payload: string) {

  }
}

export class ConferenceSetActiveWhiteboard implements Action {
  readonly type = ConferenceActionTypes.WHITEBOARD_START;

  constructor(public payload: { conversationTarget: string }) {

  }
}

export class ConferenceLeaveRequest implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_LEAVE;
}

export class ConferenceLeaveSuccess implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_LEAVE_SUCCESS;
}


export class ConferenceAddParticipant implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_ADD_PARTICIPANT;

  constructor(public payload: JitsiParticipant) {

  }
}

// sct
export class JitsiConferenceAddParticipant implements Action {
  readonly type = ConferenceActionTypes.JITSI_CONFERENCE_ADD_PARTICIPANT;

  constructor(public payload: JitsiParticipant) {

  }
}


export class ConferenceRemoveParticipant implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_REMOVE_PARTICIPANT;

  constructor(public payload: string) {

  }
}

// sct
export class JitsiConferenceRemoveParticipant implements Action {
  readonly type = ConferenceActionTypes.JITSI_CONFERENCE_REMOVE_PARTICIPANT;

  constructor(public payload: string) {

  }
}

export class ConferenceMuteAudio implements Action {
  readonly type = ConferenceActionTypes.MUTE_AUDIO;
}




export class ConferenceUnMuteAudio implements Action {
  readonly type = ConferenceActionTypes.UNMUTE_AUDIO;
}



export class ConferenceMuteVideo implements Action {
  readonly type = ConferenceActionTypes.MUTE_VIDEO;
}



export class ConferenceUnMuteVideo implements Action {
  readonly type = ConferenceActionTypes.UNMUTE_VIDEO;
}



export class ConferenceShareScreen implements Action {
  readonly type = ConferenceActionTypes.SHARE_SCREEN;
}


export class ConferenceUnShareScreen implements Action {
  readonly type = ConferenceActionTypes.UNSHARE_SCREEN;
}

export class ConferenceSetFullScreenParticipant implements Action {
  readonly type = ConferenceActionTypes.SET_FULL_SCREEN_PARTICIPANT;

  constructor(public payload: string) {

  }
}

export class ConferenceSelectParticipant implements Action {
  readonly type = ConferenceActionTypes.SET_SELECTED_PARTICIPANT;

  constructor(public payload: string) {

  }
}


export class SetWebcamStatus implements Action {
  readonly type = ConferenceActionTypes.SET_WEBCAM_STATUS;

  constructor(public payload: boolean) {

  }
}

export class SetMicrophoneStatus implements Action {
  readonly type = ConferenceActionTypes.SET_MICROPHONE_STATUS;

  constructor(public payload: boolean) {

  }
}

export class SetSpeakerStatus implements Action {
  readonly type = ConferenceActionTypes.SET_SPEAKER_STATUS;

  constructor(public payload: boolean) {

  }
}

export class SetAvailableMediaDevices implements Action {
  readonly type = ConferenceActionTypes.SET_AVAILABLE_MEDIA_DEVICES;

  constructor(public payload: any) {

  }
}

export class SetSpeakingParticipant implements Action {
  readonly type = ConferenceActionTypes.SET_SPEAKING_PARTICIPANT;
  constructor(public payload: string) {

  }
}

export class UpdateFrontCameraId implements Action {
  readonly type = ConferenceActionTypes.UPDATE_FRONT_CAMERA_ID;

  constructor(public payload: string) {

  }
}

export class UpdateBackCameraId implements Action {
  readonly type = ConferenceActionTypes.UPDATE_BACK_CAMERA_ID;

  constructor(public payload: string) {

  }
}

export class SetJitsiRoomId implements Action {
  readonly type = ConferenceActionTypes.SET_JITSI_ROOM;

  constructor(public payload: JitsiOption) {

  }
}

export class SetConferenceId implements Action {
  readonly type = ConferenceActionTypes.SET_CONFERENCE_ID;

  constructor(public payload: string) {

  }
}

export class SetStreamId implements Action {
  readonly type = ConferenceActionTypes.SET_STREAM_ID;

  constructor(public payload: string) {

  }
}

export class SetConversationTarget implements Action {
  readonly type = ConferenceActionTypes.SET_CONVERSATION_TARGET;

  constructor(public payload: string) {
  }
}

export class ShowActiveCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_SHOW_ACTIVE_CALL;
}

export class HideActiveCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_HIDE_ACTIVE_CALL;
}

export class SetExistingCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_SET_EXISTING_CALL;

  constructor(public payload: { conversationTarget: string, conferenceType: string}) {
  }
}

export class MuteForMe implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_MUTE_PARTICIPANT;

  constructor(public payload: string) {
  }
}

export class UnMuteForMe implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_UNMUTE_PARTICIPANT;

  constructor(public payload: string) {
  }
}

export class ResetExistingCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_RESET_EXISTING_CALL;

  constructor(public payload: { conversationTarget: string}) {
  }
}

export class SetScreenSharingRequest implements Action {
  readonly type = ConferenceActionTypes.SET_SCREEN_SHARING_REQUEST;

  constructor(public payload: { isStartingScreenshare: boolean, presenterJid?: string, presenterId?: string}) {
  }
}

export class SetScreenSharingStarted implements Action {
  readonly type = ConferenceActionTypes.SET_SCREEN_SHARING_STARTED;
  constructor() {
  }
}

export class SetScreenSharingRequestStatus implements Action {
  readonly type = ConferenceActionTypes.SET_SCREEN_SHARING_REQUEST_STATUS;
  constructor(public payload: boolean) {
  }
}

export class ResetScreenSharingData implements Action {
  readonly type = ConferenceActionTypes.RESET_SCREEN_SHARING;
  constructor() {
  }
}


// sct
export class SetAudioStatus implements Action {
  readonly type = ConferenceActionTypes.SET_AUDIO_STATUS;
  constructor(public payload: JitsiParticipant) {

  }
}

// sct
export class SetVideoStatus implements Action {
  readonly type = ConferenceActionTypes.SET_VIDEO_STATUS;
  constructor(public payload: JitsiParticipant) {

  }
}

export class ToggleMuteEveryone implements Action {
  readonly type = ConferenceActionTypes.TOGGLE_MUTE_EVERYONE;
  constructor(public payload: boolean) {

  }
}
export class ToggleMuteCamera implements Action {
  readonly type = ConferenceActionTypes.TOGGLE_MUTE_CAMERA;
  constructor(public payload: boolean) {

  }
}
