
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SearchMessage } from "../models/search-message.model";
import { MediaActionType } from "../../actions/media";
import { Action } from "../../actions";
import { Message } from "../models/message.model";
import { MessageActionTypes } from "../actions/message";

export interface MediaState {
  isOpen: boolean;
  isSharing: boolean;

  images: { [convTarget: string]: SearchMessage[] };
  imagesLoaded: { [convTarget: string]: boolean };
  imagesLoading: { [convTarget: string]: boolean };
  imagesOffset: { [convTarget: string]: number };
  isImagesOnLastPage: { [convTarget: string]: boolean };

  attachments: { [convTarget: string]: SearchMessage[] };
  attachmentsLoaded: { [convTarget: string]: boolean };
  attachmentsLoading: { [convTarget: string]: boolean };
  attachmentsOffset: { [convTarget: string]: number };
  attachmentsTotalFound: { [convTarget: string]: number };
  isAttachmentsOnLastPage: { [convTarget: string]: boolean };

  voiceMessages: { [convTarget: string]: SearchMessage[] };
  voiceMessagesLoaded: { [convTarget: string]: boolean };
  voiceMessagesLoading: { [convTarget: string]: boolean };
  voiceMessagesOffset: { [convTarget: string]: number };
  isVoiceMessagesOnLastPage: { [convTarget: string]: boolean };

  documents: { [convTarget: string]: SearchMessage[] };
  documentsLoaded: { [convTarget: string]: boolean };
  documentsLoading: { [convTarget: string]: boolean };
  documentsOffset: { [convTarget: string]: number };
  isDocumentsOnLastPage: { [convTarget: string]: boolean };

  videos: { [convTarget: string]: SearchMessage[] };
  videosLoaded: { [convTarget: string]: boolean };
  videosLoading: { [convTarget: string]: boolean };
  videosOffset: { [convTarget: string]: number };
  isvideosOnLastPage: { [convTarget: string]: boolean };

  selectedMessage: Message | null;
  selectedTabIndex: number;
}

export const initialState: MediaState = {
  isOpen: false,
  isSharing: false,

  images: {},
  imagesLoaded: {},
  imagesLoading: {},
  imagesOffset: {},
  isImagesOnLastPage: {},

  attachments: {},
  attachmentsLoaded: {},
  attachmentsLoading: {},
  attachmentsOffset: {},
  attachmentsTotalFound: {},
  isAttachmentsOnLastPage: {},

  voiceMessages: {},
  voiceMessagesLoaded: {},
  voiceMessagesLoading: {},
  voiceMessagesOffset: {},
  isVoiceMessagesOnLastPage: {},

  documents: {},
  documentsLoaded: {},
  documentsLoading: {},
  documentsOffset: {},
  isDocumentsOnLastPage: {},

  videos: {},
  videosLoaded: {},
  videosLoading: {},
  videosOffset: {},
  isvideosOnLastPage: {},

  selectedMessage: null,

  selectedTabIndex: 0,

};

export function mediaReducer(state: MediaState = initialState, action: Action): MediaState {
  switch (action.type) {

    case MediaActionType.IMAGES_LOADING: {
      const convTarget = action.payload;

      return {
        ...state,
        imagesLoading: {
          ...state.imagesLoading, [convTarget]: true
        }
      };
    }

    case MediaActionType.MEDIA_FILE_SHARING: {
      return {
        ...state,
        isSharing: action.payload
      };
    }

    case MediaActionType.IMAGES_LOADING_COMPLETED: {
      const messages = action.payload.messages;
      const convTarget = action.payload.convTarget;
      const offset = action.payload.offset;
      const isOnLastPage = action.payload.isOnLastPage;
      return {
        ...state,
        imagesLoaded: {
          ...state.imagesLoaded,
          [convTarget]: true
        },
        imagesLoading: {
          ...state.imagesLoading,
          [convTarget]: false
        },
        images: {
          ...state.images,
          [convTarget]: messages
        },
        isImagesOnLastPage: {
          ...state.isImagesOnLastPage,
          [convTarget]: isOnLastPage
        },
        imagesOffset: {
          ...state.imagesOffset,
          [convTarget]: offset
        },
      };
    }

    case MediaActionType.IMAGES_NEXT_PAGE_LOADED: {
      const payload = action.payload as {
        messages: SearchMessage[],
        convTarget: string,
        offset: number,
        isOnLastPage: boolean
      };

      return {
        ...state,
        images: {
          ...state.images,
          [payload.convTarget]: [
            ...state.images[payload.convTarget],
            ...payload.messages
          ]
        },
        imagesLoading: {
          ...state.imagesLoading,
          [payload.convTarget]: false
        },
        isImagesOnLastPage: {
          ...state.isImagesOnLastPage,
          [payload.convTarget]: payload.isOnLastPage
        },
        imagesOffset: {
          ...state.imagesOffset,
          [payload.convTarget]: payload.offset
        },

      };
    }

    case MediaActionType.IMAGES_FAILED: {
      return {
        ...state,
        imagesLoading: {
          [action.payload]: false
        }
      };
    }


    case MediaActionType.ATTACHMENTS_LOADING: {
      const convTarget = action.payload;

      return {
        ...state,
        attachmentsLoading: {
          ...state.attachmentsLoading, [convTarget]: true
        }
      };
    }

    case MediaActionType.ATTACHMENTS_LOADING_COMPLETED: {
      const messages = action.payload.messages;
      const convTarget = action.payload.convTarget;
      const offset = action.payload.offset;
      const isOnLastPage = action.payload.isOnLastPage;
      const numFound = action.payload.numFound;
      return {
        ...state,
        attachmentsLoaded: {
          ...state.attachmentsLoaded,
          [convTarget]: true
        },
        attachmentsLoading: {
          ...state.attachmentsLoading,
          [convTarget]: false
        },
        attachments: {
          ...state.attachments,
          [convTarget]: messages
        },
        isAttachmentsOnLastPage: {
          ...state.isAttachmentsOnLastPage,
          [convTarget]: isOnLastPage
        },
        attachmentsOffset: {
          ...state.attachmentsOffset,
          [convTarget]: offset
        },
        attachmentsTotalFound: {
          ...state.attachmentsTotalFound,
          [convTarget]: numFound
        }
      };
    }

    case MediaActionType.ATTACHMENTS_NEXT_PAGE_LOADED: {
      const payload = action.payload as {
        messages: SearchMessage[],
        convTarget: string,
        offset: number,
        isOnLastPage: boolean
      };

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [payload.convTarget]: [
            ...state.attachments[payload.convTarget],
            ...payload.messages
          ]
        },
        attachmentsLoading: {
          ...state.attachmentsLoading,
          [payload.convTarget]: false
        },
        isAttachmentsOnLastPage: {
          ...state.isAttachmentsOnLastPage,
          [payload.convTarget]: payload.isOnLastPage
        },
        attachmentsOffset: {
          ...state.attachmentsOffset,
          [payload.convTarget]: payload.offset
        },

      };
    }

    case MediaActionType.ATTACHMENTS_FAILED: {
      return {
        ...state,
        attachmentsLoading: {
          [action.payload]: false
        }
      };
    }

    case MediaActionType.DOCUMENTS_LOADING: {
      const convTarget = action.payload;

      return {
        ...state,
        documentsLoading: {
          ...state.documentsLoading, [convTarget]: true
        }
      };
    }

    case MediaActionType.DOCUMENTS_LOADING_COMPLETED: {
      const messages = action.payload.messages;
      const convTarget = action.payload.convTarget;
      const offset = action.payload.offset;
      const isOnLastPage = action.payload.isOnLastPage;
      return {
        ...state,
        documentsLoaded: {
          ...state.documentsLoaded,
          [convTarget]: true
        },
        documentsLoading: {
          ...state.documentsLoading,
          [convTarget]: false
        },
        documents: {
          ...state.documents,
          [convTarget]: messages
        },
        isDocumentsOnLastPage: {
          ...state.isDocumentsOnLastPage,
          [convTarget]: isOnLastPage
        },
        documentsOffset: {
          ...state.documentsOffset,
          [convTarget]: offset
        },
      };
    }

    case MediaActionType.DOCUMENTS_NEXT_PAGE_LOADED: {
      const payload = action.payload as {
        messages: SearchMessage[],
        convTarget: string,
        offset: number,
        isOnLastPage: boolean
      };

      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.convTarget]: [
            ...state.documents[payload.convTarget],
            ...payload.messages
          ]
        },
        documentsLoading: {
          ...state.documentsLoading,
          [payload.convTarget]: false
        },
        isDocumentsOnLastPage: {
          ...state.isDocumentsOnLastPage,
          [payload.convTarget]: payload.isOnLastPage
        },
        documentsOffset: {
          ...state.documentsOffset,
          [payload.convTarget]: payload.offset
        },

      };
    }

    case MediaActionType.DOCUMENTS_FAILED: {
      return {
        ...state,
        documentsLoading: {
          [action.payload]: false
        }
      };
    }


    case MediaActionType.VOICE_MESSAGES_LOADING: {
      const convTarget = action.payload;

      return {
        ...state,
        voiceMessagesLoading: {
          ...state.voiceMessagesLoading, [convTarget]: true
        }
      };
    }

    case MediaActionType.VOICE_MESSAGES_LOADING_COMPLETED: {
      const messages = action.payload.messages;
      const convTarget = action.payload.convTarget;
      const offset = action.payload.offset;
      const isOnLastPage = action.payload.isOnLastPage;
      return {
        ...state,
        voiceMessagesLoaded: {
          ...state.voiceMessagesLoaded,
          [convTarget]: true
        },
        voiceMessagesLoading: {
          ...state.voiceMessagesLoading,
          [convTarget]: false
        },
        voiceMessages: {
          ...state.voiceMessages,
          [convTarget]: messages
        },
        isVoiceMessagesOnLastPage: {
          ...state.isVoiceMessagesOnLastPage,
          [convTarget]: isOnLastPage
        },
        voiceMessagesOffset: {
          ...state.voiceMessagesOffset,
          [convTarget]: offset
        },
      };
    }

    case MediaActionType.VOICE_MESSAGES_NEXT_PAGE_LOADED: {
      const payload = action.payload as {
        messages: SearchMessage[],
        convTarget: string,
        offset: number,
        isOnLastPage: boolean
      };

      return {
        ...state,
        voiceMessages: {
          ...state.voiceMessages,
          [payload.convTarget]: [
            ...state.voiceMessages[payload.convTarget],
            ...payload.messages
          ]
        },
        voiceMessagesLoading: {
          ...state.voiceMessagesLoading,
          [payload.convTarget]: false
        },
        isVoiceMessagesOnLastPage: {
          ...state.isVoiceMessagesOnLastPage,
          [payload.convTarget]: payload.isOnLastPage
        },
        voiceMessagesOffset: {
          ...state.voiceMessagesOffset,
          [payload.convTarget]: payload.offset
        },

      };
    }

    case MediaActionType.VOICE_MESSAGES_FAILED: {
      return {
        ...state,
        voiceMessagesLoading: {
          [action.payload]: false
        }
      };
    }


    case MediaActionType.VIDEOS_LOADING: {
      const convTarget = action.payload;

      return {
        ...state,
        videosLoading: {
          ...state.videosLoading,
          [convTarget]: true
        }
      };
    }

    case MediaActionType.VIDEOS_LOADING_COMPLETED: {
      const messages = action.payload.messages;
      const convTarget = action.payload.convTarget;
      const offset = action.payload.offset;
      const isOnLastPage = action.payload.isOnLastPage;
      return {
        ...state,
        videosLoaded: {
          ...state.videosLoaded,
          [convTarget]: true
        },
        videosLoading: {
          ...state.videosLoading,
          [convTarget]: false
        },
        videos: {
          ...state.videos,
          [convTarget]: messages
        },
        isvideosOnLastPage: {
          ...state.isvideosOnLastPage,
          [convTarget]: isOnLastPage
        },
        videosOffset: {
          ...state.videosOffset,
          [convTarget]: offset
        },
      };
    }

    case MediaActionType.VIDEOS_NEXT_PAGE_LOADED: {
      const payload = action.payload as {
        messages: SearchMessage[],
        convTarget: string,
        offset: number,
        isOnLastPage: boolean
      };

      return {
        ...state,
        videos: {
          ...state.videos,
          [payload.convTarget]: [
            ...state.videos[payload.convTarget],
            ...payload.messages
          ]
        },
        videosLoading: {
          ...state.videosLoading,
          [payload.convTarget]: false
        },
        isvideosOnLastPage: {
          ...state.isvideosOnLastPage,
          [payload.convTarget]: payload.isOnLastPage
        },
        videosOffset: {
          ...state.videosOffset,
          [payload.convTarget]: payload.offset
        },

      };
    }

    case MediaActionType.VIDEOS_FAILED: {
      return {
        ...state,
        videosLoading: {
          [action.payload]: false
        }
      };
    }


    case MediaActionType.MEDIA_OPEN_TOGGLE: {
      return {
        ...state,
        isOpen: action.payload,
        // When open nothing needs to be selected
        // When closed, need to clear selection
        selectedMessage: null
      };
    }

    case MediaActionType.MEDIA_SELECT_MESSAGE: {
      return {
        ...state,
        selectedMessage: action.payload
      };
    }

    case MessageActionTypes.MESSAGE_DELETED_STATUS_UPDATE: {
      const payload = action.payload as { id: string, isDeleted: boolean, convTarget: string };

      let oldImages = state.images[payload.convTarget];
      if (!!oldImages) {
        const oldImagesIndex = oldImages.findIndex((image) => image.id === payload.id);
        if (oldImagesIndex !== -1) {
          oldImages = [
            ...oldImages.slice(0, oldImagesIndex),
            ...oldImages.slice(oldImagesIndex + 1, state.images[payload.convTarget].length)
          ];
        }
      }

      let oldAttachments = state.attachments[payload.convTarget];
      if (!!oldAttachments) {
        const oldAttachmentsIndex = oldAttachments.findIndex((attachment) => attachment.id === payload.id);
        if (oldAttachmentsIndex !== -1) {
          oldAttachments = [
            ...oldAttachments.slice(0, oldAttachmentsIndex),
            ...oldAttachments.slice(oldAttachmentsIndex + 1, state.attachments[payload.convTarget].length)
          ];
        }
      }

      let oldVoiceMessages = state.voiceMessages[payload.convTarget];
      if (!!oldVoiceMessages) {
        const oldVoiceMessagesIndex = oldVoiceMessages.findIndex((audio) => audio.id === payload.id);
        if (oldVoiceMessagesIndex !== -1) {
          oldVoiceMessages = [
            ...oldVoiceMessages.slice(0, oldVoiceMessagesIndex),
            ...oldVoiceMessages.slice(oldVoiceMessagesIndex + 1, state.voiceMessages[payload.convTarget].length)
          ];
        }
      }

      let oldVideos = state.videos[payload.convTarget];
      if (!!oldVideos) {
        const oldVideosIndex = oldVideos.findIndex((video) => video.id === payload.id);
        if (oldVideosIndex !== -1) {
          oldVideos = [
            ...oldVideos.slice(0, oldVideosIndex),
            ...oldVideos.slice(oldVideosIndex + 1, state.videos[payload.convTarget].length)
          ];
        }
      }

      return {
        ...state,
        images: {
          ...state.images,
          [payload.convTarget]: oldImages
        },
        attachments: {
          ...state.attachments,
          [payload.convTarget]: oldAttachments
        },
        voiceMessages: {
          ...state.voiceMessages,
          [payload.convTarget]: oldVoiceMessages
        },
        videos: {
          ...state.videos,
          [payload.convTarget]: oldVideos
        }
      };
    }

    case MediaActionType.MEDIA_TAB_SELECTED: {

      return {
        ...state,
        selectedTabIndex: action.payload
      };
    }


    default: {
      return state;
    }
  }

}

export const _getConversationImages = (state: MediaState, convTarget: string) => {
  return state.images[convTarget] || [];
};
export const _getIsConversationImagesLoaded = (state: MediaState, convTarget: string) => {
  return state.imagesLoaded[convTarget] || false;
};
export const _getIsConversationImagesLoading = (state: MediaState, convTarget: string) => {
  return state.imagesLoading[convTarget] || false;
};
export const _getConversationImagesOffset = (state: MediaState, convTarget: string) => {
  return state.imagesOffset[convTarget] || 0;
};
export const _getConversationImagesOnLastPage = (state: MediaState, convTarget: string) => {
  return state.isImagesOnLastPage[convTarget] || false;
};


export const _getConversationAttachments = (state: MediaState, convTarget: string) => {
  return state.attachments[convTarget] || [];
};
export const _getIsConversationAttachmentsLoaded = (state: MediaState, convTarget: string) => {
  return state.attachmentsLoaded[convTarget] || false;
};
export const _getIsConversationAttachmentsLoading = (state: MediaState, convTarget: string) => {
  return state.attachmentsLoading[convTarget] || false;
};
export const _getConversationAttachmentsOffset = (state: MediaState, convTarget: string) => {
  return state.attachmentsOffset[convTarget] || 0;
};
export const _getConversationAttachmentsTotalFound = (state: MediaState, convTarget: string) => {
  return state.attachmentsTotalFound[convTarget] || 0;
};
export const _getConversationAttachmentsOnLastPage = (state: MediaState, convTarget: string) => {
  return state.isAttachmentsOnLastPage[convTarget] || false;
};


export const _getConversationVoiceMessages = (state: MediaState, convTarget: string) => {
  return state.voiceMessages[convTarget] || [];
};
export const _getIsConversationVoiceMessagesLoaded = (state: MediaState, convTarget: string) => {
  return state.voiceMessagesLoaded[convTarget] || false;
};
export const _getIsConversationVoiceMessagesLoading = (state: MediaState, convTarget: string) => {
  return state.voiceMessagesLoading[convTarget] || false;
};
export const _getConversationVoiceMessagesOffset = (state: MediaState, convTarget: string) => {
  return state.voiceMessagesOffset[convTarget] || 0;
};
export const _getConversationVoiceMessagesOnLastPage = (state: MediaState, convTarget: string) => {
  return state.isVoiceMessagesOnLastPage[convTarget] || false;
};

export const _getConversationDocuments = (state: MediaState, convTarget: string) => {
  return state.documents[convTarget] || [];
};
export const _getIsConversationDocumentsLoaded = (state: MediaState, convTarget: string) => {
  return state.documentsLoaded[convTarget] || false;
};
export const _getIsConversationDocumentsLoading = (state: MediaState, convTarget: string) => {
  return state.documentsLoading[convTarget] || false;
};
export const _getConversationDocumentsOffset = (state: MediaState, convTarget: string) => {
  return state.documentsOffset[convTarget] || 0;
};
export const _getConversationDocumentsOnLastPage = (state: MediaState, convTarget: string) => {
  return state.isDocumentsOnLastPage[convTarget] || false;
};


export const _getConversationVideos = (state: MediaState, convTarget: string) => {
  return state.videos[convTarget] || [];
};
export const _getIsConversationVideosLoaded = (state: MediaState, convTarget: string) => {
  return state.videosLoaded[convTarget] || false;
};
export const _getIsConversationVideosLoading = (state: MediaState, convTarget: string) => {
  return state.videosLoading[convTarget] || false;
};
export const _getConversationVideosOffset = (state: MediaState, convTarget: string) => {
  return state.videosOffset[convTarget] || 0;
};
export const _getConversationVideosOnLastPage = (state: MediaState, convTarget: string) => {
  return state.isvideosOnLastPage[convTarget] || false;
};


export const _getIsMediaOpen = (state: MediaState) => state.isOpen;
export const _getMediaSelectedMessage = (state: MediaState) => state.selectedMessage;

export const _getSelectedTabIndex = (state: MediaState) => state.selectedTabIndex;

export const _getIsSharing = (state: MediaState) => state.isSharing;
