/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Channel} from "app/channels/models/channel.model";
import {Action} from "../actions";
import {ChannelActionTypes} from "../actions/channel";
import {File} from "../../models/file.model";
import {ChannelFiles} from "../../models/channel-files.model";


export interface ChannelState extends EntityState<Channel> {
  isLoading: boolean;
  isLoaded: boolean;
  selectedChannelId: string;
  archiveChannelsSortBy: string;
  members: { [channelId: string]: { members: { id: string, role: string }[], offset?: number, total_count?: number, isLoading: boolean, isLoaded: boolean } };
  files: ChannelFiles;
  channelSearchString: string;
  channelUserJid: string;
  channelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  subChannelsInfo: { totalCount: number, parent: { [key: string]: { subchannels: string[], allLoaded: boolean } | null }, isLoading: boolean, isLoaded: boolean};
  privateChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  publicChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  iomChannelsInfo: { totalCount: number, offset: number, ids: string[], subscribed: string[], isLoading: boolean, isLoaded: boolean};
  subscribedChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  favoriteChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  myChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  IntranetChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  ExtranetChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  searchedChannelsInfo: { keyword: string, ids: string[], totalCount: number, offset: number};
  trashedChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  archivedChannelsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  noAccess: boolean;
  notFound: boolean;
  channelSideBarTab: string;
  filteredTopicsInfo: { [channelId: string]: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean, filterApplied: boolean}}
  allAvailableTopicFilters: {filters: any[], total_count: number};
}



export const channelAdapter: EntityAdapter<Channel> = createEntityAdapter<Channel>({
  selectId: (channel: Channel) => channel.id,
  sortComparer: sortByTimestamp
});


export function sortByTimestamp(c1: Channel, c2: Channel): number {
  return new Date(c2.updated_on).getTime() - new Date(c1.updated_on).getTime();
}


export const initialState: ChannelState = channelAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedChannelId: null,
  archiveChannelsSortBy: null,
  members: null,
  files: null,
  channelSearchString: "",
  channelUserJid: "",
  channelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  subChannelsInfo: { totalCount: 0, parent: {}, isLoading: false, isLoaded: false},
  privateChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  publicChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  iomChannelsInfo: { totalCount: 0, offset: 0, ids: [], subscribed: [], isLoading: false, isLoaded: false},
  subscribedChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  favoriteChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  searchedChannelsInfo: { keyword: null, ids: [], totalCount: 0, offset: 0},
  myChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  IntranetChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  ExtranetChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  trashedChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  archivedChannelsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  noAccess: false,
  notFound: false,
  channelSideBarTab: null,
  filteredTopicsInfo: null,
  allAvailableTopicFilters: null
});


export function channelReducer(state: ChannelState = initialState, action: Action): ChannelState {
  switch (action.type) {

    case ChannelActionTypes.CHANNEL_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ChannelActionTypes.CHANNEL_LOAD_SUCCESS: {
      const contacts = action.payload.map(c => {
        let contact = state.entities[c.bare];
        if (contact) {
          c = {...c, ...contact};
        }
        return c;
      });
      return channelAdapter.setAll(contacts, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ChannelActionTypes.CHANNEL_ADD: {
      const newState = channelAdapter.addOne(action.payload, state);
      return channelAdapter.updateOne({id: action.payload.id, changes: action.payload}, newState);
    }

    case ChannelActionTypes.CHANNEL_UPDATE: {
      const updatedState = channelAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }


    case ChannelActionTypes.TRASHED_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        trashedChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.ARCHIVED_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        archivedChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.SUBCHANNEL_ADD: {
      const newState = channelAdapter.addOne(action.payload, state);
      return channelAdapter.updateOne({id: action.payload.id, changes: action.payload}, newState);
    }

    case ChannelActionTypes.SUBCHANNEL_UPDATE: {
      const updatedState = channelAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case ChannelActionTypes.CHANNEL_AVATAR_FAILED_TO_LOAD: {
      return channelAdapter.updateOne({
        id     : action.payload,
        changes: {avatarFailedToLoad: true}
      }, state);
    }

    case ChannelActionTypes.CHANNEL_AVATAR_FAILED_TO_LOAD_REMOVE: {
      return channelAdapter.updateOne({
        id     : action.payload,
        changes: {avatarFailedToLoad: false}
      }, state);
    }

    case ChannelActionTypes.CHANNEL_BULK_ADD: {
      const newState = channelAdapter.addMany(action.payload, state);

      const changes = action.payload.map(Channel => {
        return {id: Channel.id, changes: Channel};
      });

      return channelAdapter.updateMany(changes, newState);
    }

    case ChannelActionTypes.SUBCHANNEL_BULK_ADD: {
      const newState = channelAdapter.addMany(action.payload, state);

      const changes = action.payload.map(Channel => {
        return {id: Channel.id, changes: Channel};
      });

      return channelAdapter.updateMany(changes, newState);
    }

    case ChannelActionTypes.CHANNEL_SEARCH_STRING_UPDATE: {
      return {
        ...state,
        channelSearchString: action.payload
      };
    }

    case ChannelActionTypes.CHANNEL_USER_JID_UPDATE: {
      return {
        ...state,
        channelUserJid: action.payload
      };
    }

    case ChannelActionTypes.CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        channelsInfo: action.payload
      };
    }

    case ChannelActionTypes.SUBCHANNEL_INFO_UPDATE: {
      return {
        ...state,
        subChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.PRIVATE_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        privateChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.PUBLIC_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        publicChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.IOM_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        iomChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.SUBSCRIBED_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        subscribedChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.FAVORITE_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        favoriteChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.MY_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        myChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.INTRANET_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        IntranetChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.EXTRANET_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        ExtranetChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.FILTERED_TOPICS_INFO_UPDATE: {
      const channelId = action.payload.channelId;
      const info = action.payload;
      delete info.channelId;
      return {
        ...state,
        filteredTopicsInfo: {
          ...state.filteredTopicsInfo,
          [channelId]: info
        }
      };
    }

    case ChannelActionTypes.TOPIC_FILTERS_INFO_UPDATE: {
      return {
        ...state,
        allAvailableTopicFilters: action.payload
      };
    }

    case ChannelActionTypes.SEARCHED_CHANNEL_INFO_UPDATE: {
      return {
        ...state,
        searchedChannelsInfo: action.payload
      };
    }

    case ChannelActionTypes.CHANNEL_DELETE: {
      return channelAdapter.removeOne(action.payload, state);
    }

    case ChannelActionTypes.SELECTED_CHANNEL: {
      return {
        ...state,
        selectedChannelId: action.payload
      };
    }

    case ChannelActionTypes.ARCHIVE_CHANNELS_SORT_BY: {
      return  {
        ...state,
        archiveChannelsSortBy: action.payload
      };
    }

    case ChannelActionTypes.CHANNEL_MEMBERS_LOAD_REQUEST: {
      return {
        ...state,
        members: {...state.members, [action.payload]: {memberIds: [], offset: 0, total_count: 0, isLoading: true, isLoaded: false}}
      };
    }

    case ChannelActionTypes.CHANNEL_MEMBERS_LOAD_SUCCESS: {
      const oldMembers = [ ...(state?.members?.[action.payload.channelId]?.members || [])];
      const oldMemberIds = oldMembers.map(k => k.id);
      const newMembers = (action.payload.members || [])
        .filter(k => !oldMemberIds.includes(k.jid))
        .map(k => ({id: k.jid, role: k.role}));
      return {
        ...state,
        members: {
          ...state.members,
          [action.payload.channelId]: {
            ...(state.members[action?.payload?.channelId] || {}),
            members: [...oldMembers, ...newMembers],
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_MEMBERS_OFFSET_UPDATE: {
      return {
        ...state,
        members: {
          ...state.members,
          [action.payload.channelId]: {
            ...(state?.members?.[action.payload.channelId] || {}),
            offset: action.payload.offset
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_MEMBERS_ADD: {
      const channelId = action.payload.channelId;

      const oldMemberIds: any[] = state?.members?.[channelId]?.members?.map(x => x.id) || [];
      const newMembers: any[] = (action.payload.members?.map(k => ({id: k.jid, role: k.role})) || [])
        .filter(m => !oldMemberIds.includes(m.id));
      const oldMembers = [ ...(state?.members?.[channelId]?.members || [])];

      return {
        ...state,
        members: {
          ...state.members,
          [channelId]: {
            ...(state?.members?.[channelId] || {}),
            members: [...oldMembers, ...newMembers],
            isLoading: false,
            isLoaded: true
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_MEMBERS_UPDATE: {
      const channelId = action.payload.channelId;
      const updatedMembers = action.payload.members.map(k => ({id: k.jid, role: k.role}));
      const updatedMemberIds = updatedMembers.map(m => m?.id);

      const oldMembers = [ ...(state?.members?.[channelId]?.members || [])];
      let newMembers = oldMembers.filter(m => !updatedMemberIds.includes(m?.id));
      newMembers = [...newMembers, ...updatedMembers];

      return {
        ...state,
        members: {
          ...state.members,
          [channelId]: {
            ...(state?.members?.[channelId] || {}),
            members: newMembers,
            isLoading: false,
            isLoaded: true
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_MEMBERS_REMOVE: {
      const channelId = action.payload.channelId;

      const newMembers: any[] = (state?.members?.[channelId]?.members || []).filter(x => !action.payload.memberIds.includes(x.id));

      return {
        ...state,
        members: {
          ...state.members,
          [channelId]: {
            ...(state?.members?.[channelId] || {}),
            members: newMembers
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_FILES_LOAD_REQUEST: {
      return {
        ...state,
        files: { ...(state.files || {}), [action.payload]: { fileIds: [], isLoading: true, isLoaded: false, offset: 0, total_count: 0 } }
      };
    }

    case ChannelActionTypes.CHANNEL_FILES_LOAD_SUCCESS: {
      const channelId = action.payload.channelId;
      const files = action.payload.files || [];
      const fileIds = [...(state?.files?.[channelId]?.fileIds || [])];
      return {
        ...state,
        files: {
          ...state.files,
          [channelId]: {
            ...state.files[channelId],
            fileIds: [ ...fileIds , ...files.map((file: File) => file.id)],
            isLoading: false,
            isLoaded: true,
            total_count: action.payload.total_count
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_FILES_OFFSET_UPDATE: {
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.channelId]: {
            ...(state?.files?.[action?.payload?.channelId] || {}),
            offset: action.payload.offset
          }
        }
      };
    }

    case ChannelActionTypes.CHANNEL_FILES_DELETE: {
      const fileId = action.payload.fileId;
      const channelId = action.payload.channelId;
      const newFileIds = state?.files?.[channelId]?.fileIds.filter(x => x !== fileId);
      return {
        ...state,
        files: {
          ...state.files,
          [channelId]: {
            ...state.files[channelId],
            fileIds: [...newFileIds]
          }
        }
      };
    }
    case ChannelActionTypes.CHANNEL_NO_ACCESS: {
      return {
        ...state,
        noAccess: action.payload
      };
    }

    case ChannelActionTypes.CHANNEL_NOT_FOUND: {
      return {
        ...state,
        notFound: action.payload
      };
    }

    case ChannelActionTypes.CHANNEL_SIDEBAR_TAB_CHANGE: {
      return {
        ...state,
        channelSideBarTab: action.payload
      };
    }

    default: {
      return state;
    }
  }
}


export const _getIsLoading = (state: ChannelState) => state.isLoading;
export const _getIsLoaded = (state: ChannelState) => state.isLoaded;
export const _getChannelSearchString = (state: ChannelState) => state.channelSearchString;
export const _getChannelUserJid = (state: ChannelState) => state.channelUserJid;
export const _getChannelsInfo = (state: ChannelState) => state.channelsInfo;
export const _getSubChannelsInfo = (state: ChannelState) => state.subChannelsInfo;
export const _getPrivateChannelsInfo = (state: ChannelState) => state.privateChannelsInfo;
export const _getPublicChannelsInfo = (state: ChannelState) => state.publicChannelsInfo;
export const _getIOMChannelsInfo = (state: ChannelState) => state.iomChannelsInfo;
export const _getSubscribedChannelsInfo = (state: ChannelState) => state.subscribedChannelsInfo;
export const _getFavoriteChannelsInfo = (state: ChannelState) => state.favoriteChannelsInfo;
export const _getMyChannelsInfo = (state: ChannelState) => state.myChannelsInfo;
export const _getIntranetChannelsInfo = (state: ChannelState) => state.IntranetChannelsInfo;
export const _getExtranetChannelsInfo = (state: ChannelState) => state.ExtranetChannelsInfo;
export const _getSearchedChannelsInfo = (state: ChannelState) => state.searchedChannelsInfo;
export const _getSelectedChannelId = (state: ChannelState) => state.selectedChannelId;
export const _getArchiveChannelsSortBy = (state: ChannelState) => state.archiveChannelsSortBy;
export const _getChannelMembers = (state: ChannelState) => state.members;
export const _getChannelFiles = (state: ChannelState): ChannelFiles => state.files;
export const _getChannelNoAccess = (state: ChannelState) => state.noAccess;
export const _getChannelNotFound = (state: ChannelState) => state.notFound;
export const _getChannelSideBarTab = (state: ChannelState): string => state.channelSideBarTab;
export const _getFilteredTopicsInfo = (state: ChannelState) => state.filteredTopicsInfo;
export const _getAllAvailableTopicFiltersInfo = (state: ChannelState) => state.allAvailableTopicFilters;
export const _getTrashedChannelsInfo = (state: ChannelState) => state.trashedChannelsInfo;
export const _getArchivedChannelsInfo = (state: ChannelState) => state.archivedChannelsInfo;
