
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="user-profile">
  <div class="mdl-dialog__header">
    <button id="leaveEditUserProfile" class="hide-on-desktop" mat-icon-button (click)="onEsc()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <h3 class="mdl-dialog__title header-title">
      {{ 'EDIT_PROFILE'|translate }}
    </h3>
    <span></span>
    <button class="show-on-desktop close-dialog-btn" mat-icon-button (click)="onEsc()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()"
        [class.updated]="form.valid && form.dirty && isValidEmails">
    <div class="form-container">
      <div class="setting-group account-setting">
        <div class="form-control" formGroupName="name">
          <div class="form-icon">
            <mat-icon>person</mat-icon>
          </div>
          <input id="firstName" placeholder="{{ 'FIRST_NAME'|translate }} (*)" type="text" required
                         formControlName="given" />
          <input id="lastName" placeholder="{{ 'LAST_NAME'|translate }} (*)" type="text" formControlName="family" required />
        </div>
      </div>
      <div class="setting-group email-setting" formArrayName="emails">
        <div class="form-control" *ngFor="let emailForm of form['controls'].emails['controls']; let i=index">
          <div class="form-icon">
            <mat-icon *ngIf="i===0">email</mat-icon>
          </div>
          <div class="form-group" [formGroupName]="i">
            <input id="emailAddress" placeholder="{{ 'EMAIL'|translate }}" type="email" formControlName="email" />
            <mat-select id="emailType" formControlName="emailType">
              <mat-option *ngFor="let option of emailOptions" [value]="option.value">{{ option.label|translate }}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-icon">
            <mat-icon *ngIf="form['controls'].emails['controls'].length > 1" (click)="removeEmail(i)">close</mat-icon>
          </div>
        </div>
      </div>

      <div class="setting-group phone-setting" formArrayName="phoneNumbers">
        <div class="form-control" *ngFor="let phone of form['controls'].phoneNumbers['controls']; let j=index">
          <div class="form-icon">
            <mat-icon *ngIf="j===0">phone</mat-icon>
          </div>
          <div class="form-group" [formGroupName]="j">
            <input id="phoneNumber" label="{{ 'PHONE'|translate }}" type="text" formControlName="number" />
            <mat-select id="phoneType" formControlName="phoneType">
              <mat-option *ngFor="let option of phoneOptions" [value]="option.value">{{ option.label|translate }}
              </mat-option>
            </mat-select>

          </div>
          <div class="form-icon">
            <mat-icon *ngIf="form['controls'].phoneNumbers['controls'].length > 1" (click)="removePhone(j)">close
            </mat-icon>
          </div>
        </div>
      </div>
      <!-- list of addresses -->
      <div class="setting-group address-setting" formArrayName="addresses">
        <div class="form-control" *ngFor="let address of form['controls'].addresses['controls']; let k=index">
          <div class="form-icon">
            <mat-icon *ngIf="k===0">place</mat-icon>
          </div>
          <div class="form-group" [formGroupName]="k">
            <input *ngIf="isHin" id="street" label="{{ 'STREET_AND_NUMBER_HIN'|translate }}" type="text"
                           formControlName="street" />
            <input *ngIf="!isHin" id="street" label="{{ 'STREET_AND_NUMBER'|translate }}" type="text"
                           formControlName="street /">
            <div class="form-grid">
              <input id="zip" label="{{ 'ZIP'|translate }}" type="text" formControlName="postalCode" />
              <input id="city" label="{{ 'CITY'|translate }}" type="text" formControlName="city" />
            </div>
            <div class="form-grid">
              <textarea id="country" label="{{ 'COUNTRY'|translate }}" type="text" formControlName="country"></textarea>
              <mat-select id="addressType" formControlName="addressType">
                <mat-option *ngFor="let option of addressOptions" [value]="option.value">{{ option.label|translate }}
                </mat-option>
              </mat-select>
            </div>

          </div>
          <div class="form-icon">
            <mat-icon *ngIf="form['controls'].addresses['controls'].length > 1" (click)="removeAddress(k)">close
            </mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div id="editProfileSavebar" class="form-footer" *ngIf="form.valid && form.dirty && isValidEmails">
      {{ 'PROFILE_UPDATED'|translate }}
      <button id="editProfileSavebtn" class="save-profile-btn"mat-mini-fab color="primary"
              type="submit" [disabled]="!form.valid">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </form>
</div>
