<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<vnc-dialog [classes]="['mobile-notification-center']" [headerTemplate]="mobileHeaderTemplate"
  classes="fixed-size with-border with-border-radius mobile-notification-center" [bodyTemplate]="mobileBodyTemplate"></vnc-dialog>
<ng-template #mobileHeaderTemplate>
  <vnc-mobile-header
    headerType="media"
    [prefixIconTemplate]="prefixIcon"
    [sufixIconTemplate2]="settingsIcon"
    [sufixIconTemplate1]="listIcon"
    fileName="{{'NOTIFICATIONS' | translate }}"
    (onPrefixIconClick)="close()"
    (onSufixIcon1Click)="listClick()"
    (onSufixIcon2Click)="settingClick()"
    [totalUnread]="notificationCount">
  </vnc-mobile-header>
</ng-template>
<ng-template #mobileBodyTemplate>
  <div class="mobile-notification-dialog-component">
    <vp-notification-center (close)="close()"></vp-notification-center>
  </div>
</ng-template>
<ng-template #prefixIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g fill="#FFF">
            <g>
                <path d="M18.95 5.05c.39.39.39 1.024 0 1.414L13.414 12l5.536 5.536c.39.39.39 1.023 0 1.414-.39.39-1.024.39-1.414 0L12 13.414 6.464 18.95c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414L10.586 12 5.05 6.464c-.39-.39-.39-1.023 0-1.414.39-.39 1.024-.39 1.414 0L12 10.586l5.536-5.536c.39-.39 1.023-.39 1.414 0z" transform="translate(-12.000000, -33.000000) translate(12.000000, 33.000000)"/>
            </g>
        </g>
    </g>
  </svg>
</ng-template>
<ng-template #settingsIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g fill="#FFF">
            <g>
                <path d="M10.022 2c3.908 0 6.26 2.665 6.366 5.315l.003.248-.023.974c-.007.626.028 1.017.14 1.438.172.65.518 1.18 1.128 1.629l3.324 2.464 1.639 1.222c.442.33.533.957.202 1.4-.305.408-.862.517-1.294.27l-.157-.107-3.685-2.741-1.214-.898c-.989-.727-1.591-1.647-1.877-2.727-.164-.62-.214-1.146-.207-1.917l.024-1.07c0-1.67-1.553-3.5-4.37-3.5-.519 0-.985.013-1.401.036l-.341.023 1.048.528.754.395c2.03 1.088 1.93 3.047.814 5.05-1.07 1.919-2.515 2.925-4.562 2.073l-.964-.409-.922-.403.076.189c.08.187.17.372.268.554l.155.27c.32.525.71.99 1.177 1.384.835.704 2.113.992 4.312.992 1.137 0 2.266.354 3.351.937.284.152.538.305.754.446l.296.2.234.176.092.074 4.956 3.682c.443.33.536.956.206 1.4-.304.409-.86.519-1.293.274l-.106-.068-5-3.717-.13-.1-.146-.106c-.24-.17-.513-.34-.809-.5-.819-.439-1.645-.698-2.405-.698-2.63 0-4.317-.38-5.601-1.463-.64-.538-1.169-1.17-1.596-1.874-.847-1.393-1.18-2.773-1.236-3.742-.044-.753.731-1.282 1.417-.966l.839.382c.424.191.853.382 1.276.568l.941.407.627.265c.83.345 1.41-.06 2.046-1.2.642-1.152.682-1.942-.012-2.314l-.469-.247-.85-.434c-.402-.202-.824-.41-1.253-.619l-1.51-.721c-.492-.23-.71-.812-.49-1.31.172-.39.595-.718 1.301-.954.883-.294 2.228-.46 4.157-.46z" transform="translate(-324.000000, -33.000000) translate(324.000000, 33.000000)"/>
            </g>
        </g>
    </g>
</svg>
</ng-template>
<ng-template #listIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <filter id="7vj1whng7a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
        </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g filter="url(#7vj1whng7a)" transform="translate(-292.000000, -33.000000)">
            <g>
                <g fill="#FFF">
                    <path d="M0 6h2V4H0v2zm0 4h2V8H0v2zm0-8h2V0H0v2zm4 4h14V4H4v2zm0 4h14V8H4v2zM4 0v2h14V0H4z" transform="translate(292.000000, 33.000000) translate(3.000000, 7.000000)"/>
                </g>
            </g>
        </g>
    </g>
  </svg>
</ng-template>