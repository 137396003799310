<div *ngIf="!data.isMobileView" class="sticky-note-wrapper" [ngClass]="{'view_only': viewOnly}">
  <ng-container [ngTemplateOutlet]="stickyNote"></ng-container>
</div>

<div *ngIf="data.isMobileView" class="sticky-note-wrapper" [ngClass]="{'view_only': viewOnly}">
  <vnc-dialog [classes]="['mobile-editor-channel-dialog', 'mobile-sticky-note']" [headerTemplate]="mobileHeaderTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="stickyNote">
  </vnc-dialog>

  <ng-template #mobileHeaderTemplate>
    <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{ 'STICKY_NOTE' | translate }}"
                       [sufixIconTemplate1]="''"
                       (darkBackPress)="onCloseStickyNote()">
    </vnc-mobile-header>
  </ng-template>


  <ng-template #footerTemplate>
    <span></span>
  </ng-template>
</div>

<ng-template #stickyNote>
  <vnc-sticky-notes [noteData]="note"
                    [users]="users"
                    [cancelText]="'CANCEL' | translate | uppercase"
                    [updateText]="'UPDATE' | translate | uppercase"
                    [createText]="'CREATE' | translate | uppercase"
                    [menuTemplate]="menuTemplate"
                    [kebabMenuTemplate]="kebabMenuTemplate"
                    [bypassViewMode]="data?.bypassViewMode"
                    [allowEntityAddition]="data?.canAddMoreEntities"
                    [mobileMode]="data?.isMobileView"
                    (editStickyNote)="onStickyNoteSaveOrEdit($event)"
                    (closeStickyNote)="onCloseStickyNote()"
                    (deleteStickyNote)="deleteNote($event)"
                    (inlineImageUploaded)="uploadInlineAttachment($event.fileEvent)"
                    (editorCreated)="onEditorCreated($event)"
                    (showSnackbar)="showSnackBar($event)"></vnc-sticky-notes>
</ng-template>

<ng-template #menuTemplate>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="smartLinkIconTemplate"
                      label="{{'ADD_SMARTLINK' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: stickyNoteActions.ADD_SMART_LINK })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="stickyNoteIconTemplate"
                      label="{{'ADD_STICKY_NOTE' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: stickyNoteActions.ADD_STICKY_NOTE })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="highlightIconTemplate"
                      label="{{'ADD_HIGHLIGHT' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: stickyNoteActions.ADD_HIGHLIGHT })">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="referenceIconTemplate"
                      label="{{'ADD_REFERENCE' | translate}}"
                      (onClickEvent)="onHeaderAndFooterActionClick({ clickEvent: $event, actionType: stickyNoteActions.ADD_REFERENCE })">
    </vp-dropdown-item>
  </div>
</ng-template>
<ng-template #kebabMenuTemplate>
  <div class="item-drop-down">
<!--    TODO: GC changes to be incorporated here to switch view to edit mode manually-->
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="editIcon"
                      label="{{'EDIT' | translate}}"
                      (onClickEvent)="editNote()">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"
                      [prefixIconTemplate]="deleteIcon"
                      label="{{'DELETE' | translate}}"
                      (onClickEvent)="deleteNote(note)">
    </vp-dropdown-item>
  </div>
</ng-template>

<ng-template #smartLinkIconTemplate>
  <mat-icon svgIcon="smartlink"></mat-icon>
</ng-template>

<ng-template #stickyNoteIconTemplate>
  <mat-icon svgIcon="sticky-note"></mat-icon>
</ng-template>

<ng-template #highlightIconTemplate>
  <mat-icon svgIcon="highlight"></mat-icon>
</ng-template>

<ng-template #referenceIconTemplate>
  <mat-icon svgIcon="reference"></mat-icon>
</ng-template>


<ng-template #editIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="#8B96A0">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d="M0 10.688V13.5h2.813l8.294-8.295-2.812-2.813L0 10.688zM13.283 3.03c.292-.292.292-.765 0-1.057L11.527.216c-.293-.292-.766-.292-1.058 0L9.097 1.59l2.813 2.813 1.373-1.373z" transform="translate(-315 -433) translate(0 338) translate(12 72) translate(300 20) translate(3 3) translate(2.25 2.25)"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #deleteIcon>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19px" height="18px" viewBox="0 0 19 18" version="1.1">
    <title>A896B039-C170-4609-861C-E205211F44BC</title>
    <defs>
      <filter x="-21.6%" y="-12.8%" width="143.1%" height="125.6%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="0220.-VNCtalk--Channels--Topic-#013" transform="translate(-877.000000, -825.000000)" fill="#8B96A0">
        <g id="Group-13" transform="translate(862.500000, 520.000000)" filter="url(#filter-1)">
          <g id="Stacked-Group" transform="translate(0.000000, 8.000000)">
            <g id="Dropdown/item/@m/icon-default" transform="translate(0.000000, 288.000000)">
              <g id="Container/18px" transform="translate(12.000000, 6.000000)">
                <g id="Icon/product/delete-new" transform="translate(3.000000, 3.000000)">
                  <path d="M13.5,5.25 L13.5,13.7142857 C13.5,14.8309875 12.6065156,15.75 11.5,15.75 L11.5,15.75 L6.5,15.75 C5.39348442,15.75 4.5,14.8309875 4.5,13.7142857 L4.5,13.7142857 L4.5,5.25 L13.5,5.25 Z M12,6.75 L6,6.75 L6,13.7142857 C6,13.987071 6.1945374,14.2098987 6.43376166,14.2451424 L6.43376166,14.2451424 L6.5,14.25 L11.5,14.25 C11.7684844,14.25 12,14.0118697 12,13.7142857 L12,13.7142857 L12,6.75 Z M9.75,2.25 C10.3052314,2.25 10.790004,2.55167034 11.0493543,3.00004764 L13.5,3 C13.9142136,3 14.25,3.33578644 14.25,3.75 C14.25,4.16421356 13.9142136,4.5 13.5,4.5 L4.5,4.5 C4.08578644,4.5 3.75,4.16421356 3.75,3.75 C3.75,3.33578644 4.08578644,3 4.5,3 L6.950917,2.99957887 C7.21033395,2.55146009 7.69496209,2.25 8.25,2.25 L9.75,2.25 Z" id="Combined-Shape"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #checkIconTemplate>
  <span (click)="saveStickyNote()">
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-9.6%" y="-13.1%" width="119.2%" height="126.1%" filterUnits="objectBoundingBox" id="filter-1">
          <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
          <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter x="-16.5%" y="-86.2%" width="133.0%" height="272.5%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
          <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="0231.-Topic-type--Analytics-#04-17" transform="translate(-1499.000000, -2051.000000)" fill="#FFFFFF">
          <g id="Group-14" transform="translate(1125.000000, 1907.000000)">
            <g id="Group-9" filter="url(#filter-1)" transform="translate(0.000000, 24.000000)">
              <g id="Group-6" transform="translate(0.000000, 84.000000)">
                <g id="Group" transform="translate(64.000000, 8.000000)" filter="url(#filter-2)">
                  <g id="Group-2" transform="translate(16.000000, 8.000000)">
                    <g id="Button/primary-icon/@l/hover" transform="translate(282.000000, 8.000000)">
                      <g id="Group-2" transform="translate(12.000000, 12.000000)">
                        <g id="Icon/product/check" transform="translate(0.000000, 0.000000)">
                          <g id="Icon-24px" transform="translate(3.410000, 5.590000)">
                            <polygon id="Shape" points="5.59 10.58 1.42 6.41 0 7.82 5.59 13.41 17.59 1.41 16.18 0"></polygon>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</ng-template>

<ng-template #empty></ng-template>
<ng-template #plusIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-16 -16)" fill="#FFF" fill-rule="evenodd">
      <path d="M35 29h-6v6h-2v-6h-6v-2h6v-6h2v6h6z"/>
    </g>
  </svg>
</ng-template>

<ng-template #closeIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="rotate(90 28 12)" fill="#FFF" fill-rule="evenodd">
      <path d="M35 22.41 33.59 21 28 26.59 22.41 21 21 22.41 26.59 28 21 33.59 22.41 35 28 29.41 33.59 35 35 33.59 29.41 28z"/>
    </g>
  </svg>
</ng-template>

<ng-template #smartlinkMobileIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-26%" y="-26%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-12 -12)" fill="#FFF" fill-rule="evenodd">
      <path d="m25.016 14-2.017 2.016V16L16 16v17h14v-5.188l2-2V33a2 2 0 0 1-2 2H16a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h9.016zm3.497 5.094c.266 0 .492.11.68.328 1 1 1.5 2.172 1.5 3.515 0 1.344-.5 2.516-1.5 3.516l-3.516 3.563c-1.031.968-2.21 1.453-3.539 1.453-1.328 0-2.508-.485-3.539-1.453-.969-1.032-1.453-2.211-1.453-3.54 0-1.328.484-2.507 1.453-3.538l1.5-1.5a6.427 6.427 0 0 0 .375 2.437l-.469.469c-.593.625-.89 1.336-.89 2.133 0 .796.304 1.5.914 2.109.61.61 1.312.914 2.11.914.796 0 1.507-.297 2.132-.89l3.516-3.563c.593-.594.89-1.29.89-2.086 0-.797-.297-1.508-.89-2.133a1.09 1.09 0 0 1-.282-.726c0-.266.094-.493.282-.68.218-.219.46-.328.726-.328zM30.623 13c1.328 0 2.507.484 3.539 1.453.968 1.031 1.453 2.211 1.453 3.54 0 1.327-.485 2.507-1.453 3.538l-1.5 1.5a6.427 6.427 0 0 0-.375-2.437l.468-.469c.594-.625.891-1.336.891-2.133 0-.797-.305-1.5-.914-2.11-.61-.609-1.313-.913-2.11-.913-.796 0-1.507.297-2.132.89l-3.516 3.563c-.594.594-.89 1.289-.89 2.086 0 .797.296 1.508.89 2.133.188.218.281.46.281.726 0 .266-.101.5-.304.703a.966.966 0 0 1-.703.305.865.865 0 0 1-.68-.328c-1-1-1.5-2.172-1.5-3.516 0-1.343.5-2.515 1.5-3.515l3.515-3.563c1.032-.969 2.211-1.453 3.54-1.453z"/>
    </g>
  </svg>
</ng-template>

<ng-template #stickyNoteMobileIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-26%" y="-26%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2"/>
        <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
        <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <g transform="translate(-12 -12)" fill="#FFF" fill-rule="evenodd">
      <path d="M29 15a3 3 0 0 1 2.995 2.824L32 18v12a3 3 0 0 1-2.824 2.995L29 33h-6.362l-5.559-4.632a3 3 0 0 1-1.072-2.1L16 26.063V18a3 3 0 0 1 2.824-2.995L19 15h10zm-5.005 12.824L24 28v3h5a1 1 0 0 0 .974-.77l.02-.113L30 30V18a1 1 0 0 0-1-1H19a1 1 0 0 0-.993.883L18 18v7h3a3 3 0 0 1 2.995 2.824zM22 29.864V28a1 1 0 0 0-.883-.993L21 27h-2.438L22 29.864z"/>
    </g>
  </svg>
</ng-template>
