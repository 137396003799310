import {Component, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {RelatedTopicsService} from "../related-topics.service";
import {Subject} from "rxjs";
import { take, takeUntil, BehaviorSubject } from "rxjs";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-related-topics-list",
  templateUrl: "./related-topics-list.component.html",
  styleUrls: ["./related-topics-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RelatedTopicsListComponent implements OnInit, OnDestroy {

  isAlive$ = new Subject();
  viewType = ViewTypeEnum;
  open:boolean = false;

  @Input() relatedTopics: any[] = [];
  @Input() view: ViewTypeEnum  = ViewTypeEnum.LIST;
  @Input() editable = true;
  @Input() topicId: string;

  @Output() newTopicAdded = new Subject();
  @Output() removeTopics = new Subject();
  @Output() sendTopics = new Subject();

  popupAlreadyOpen = new BehaviorSubject<boolean>(false);
  isMobileScreen: boolean = false;
  constructor(
    private _relatedTopicsService: RelatedTopicsService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver.observe(["(max-width: 767px)"]).pipe(takeUntil(this.isAlive$)).subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
      });
  }

  ngOnInit(): void {
  }

  relatedTopicsPreview(topic) {
    return topic;
  }

  openRelatedTopicsDialog(event: MouseEvent) {
    if (this.isMobileScreen) {
      this.openMobileTopicRelatedDialog();
      return;
    }
    if (this.popupAlreadyOpen.getValue()) return;
    const {clientX, clientY} = event;
    const alreadySelectedTopics = this.relatedTopics?.map(topic => topic?.id);
    this.popupAlreadyOpen.next(true);
    this._relatedTopicsService.getRelatedTopicsPopupRef(clientX, clientY, alreadySelectedTopics, this.topicId)
      .afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        if (!!res?.selectedTopic) {
          this.newTopicAdded.next(res?.selectedTopic);
          this.relatedTopics.push(res?.selectedTopic);
        }
        this.popupAlreadyOpen.next(false);
      });
  }

  async openRelatedTopicPreview(event: MouseEvent, topic) {
    if (this.isMobileScreen) {
      return;
    }
    if ((event?.target?.["tagName"] === "svg" && !(event?.target?.["parentNode"]?.className === "profile-icon" || event?.target?.["parentNode"]?.className === "channel-icon"))
      || (event?.target?.["nearestViewportElement"]?.["tagName"] === "svg" && !(event?.target?.["nearestViewportElement"]?.["parentNode"]?.className === "profile-icon" || event?.target?.["nearestViewportElement"]?.["parentNode"]?.className === "channel-icon"))
      || event?.target?.["tagName"] === "button") return;
    const {clientX, clientY} = event;
    let selectedTopic = await this._relatedTopicsService.getTopic(topic?.id);
    this._relatedTopicsService.getRelatedTopicPreviewRef(clientX, clientY, selectedTopic,true)
      .afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe();
  }

  onRemoveTopic(topic) {
    this.relatedTopics = this.relatedTopics.filter(item => item.id !== topic.id);
    this.removeTopics.next([topic?.id]);
  }

  onRemoveAll() {
    const ids = this.relatedTopics?.map(topic => topic?.id);
    this.removeTopics.next(ids);
  }

  onSendTopic(topic) {
    this.sendTopics.next([topic]);
  }

  onSendAllTopics() {
    this.sendTopics.next(this.relatedTopics);
  }

  onCopyLink(topic) {
    this._relatedTopicsService.copyTopicLink(topic);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  async openMobileTopicRelatedDialog() {
    const options = {
      maxWidth: "100vw",
      maxHeight: "100vh",
      width: "100vw",
      height: "100vh",
    };
    const { TopicRelatedObjectDialogComponent } = await import(
      "app/channels/topic-realted-object-dialog/topic-related-object-dialog.component");
    const dialog = this.dialog.open(TopicRelatedObjectDialogComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: {
        addRelateTopics: [...this.relatedTopics],
        topicId: Number(this.topicId),
      },
      autoFocus: false
    }, options));
    dialog.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.newTopicAdded.next(res?.addRelateTopics.map(item => item.id));
      }
    });
  }

}

export enum ViewTypeEnum {
  GRID = "GRID",
  LIST = "LIST",
  LIST_DROPDOWN = "LIST_DROPDOWN"
}
