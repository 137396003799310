import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserConfigService} from "../../services/userConfig.service";
import {Store} from "@ngrx/store";
import {RootState} from "../../../reducers";
import {UpdateTrashConfig} from "../../../actions/app";

@Component({
  selector: "vp-trash-settings",
  templateUrl: "./trash-settings.component.html",
  styleUrls: ["./trash-settings.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TrashSettingsComponent implements OnInit {

  intervals = [
    {
      label: IntervalEnum.DAILY,
      value: 1
    },
    {
      label: IntervalEnum.WEEKLY,
      value: 7
    },
    {
      label: IntervalEnum.MONTHLY,
      value: 30
    },
    {
      label: IntervalEnum.BI_MONTHLY,
      value: 60
    },
    {
      label: IntervalEnum.HALF_YEARLY,
      value: 180
    }
  ];

  enableAutoClean = false;
  isLoading = false;

  intervalControl: UntypedFormControl;

  constructor(
    private _dialogRef: MatDialogRef<TrashSettingsComponent>,
    private _userConfigService: UserConfigService,
    private _store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: {auto_clean_trash: boolean, auto_clean_trash_period: number}
  ) {
    this.intervalControl = new UntypedFormControl(data?.auto_clean_trash_period ? data?.auto_clean_trash_period : IntervalEnum.MONTHLY, Validators.required);
    this.enableAutoClean = data?.auto_clean_trash;
  }

  ngOnInit(): void {
  }

  onSave() {
    this.isLoading = true;
    const data = {
      pref: {
        auto_clean_trash: this.enableAutoClean ? 1 : 0,
        auto_clean_trash_period: this.intervalControl.value
      }
    };
    this._userConfigService.updateUserConfig(data).subscribe(() => {
      this._store.dispatch(new UpdateTrashConfig({auto_clean_trash: this.enableAutoClean, auto_clean_trash_period: this.intervalControl.value}));
      this.isLoading = false;
      this._dialogRef.close();
    }, () => {
      this.isLoading = false;
    });
  }

  closeSettings() {
    this._dialogRef.close();
  }

}

const IntervalEnum = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  BI_MONTHLY: "Bi-Monthly",
  HALF_YEARLY: "Half Yearly"
};
