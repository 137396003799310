import {Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SmartObjectsPreviewComponent} from "./smart-objects-preview/smart-objects-preview.component";
import {PopupPositionService} from "../popup-position.service";
import {TranslateService} from "@ngx-translate/core";
import {ChannelSnackbarService} from "../channel-snackbar.service";
import {SnackbarType} from "../models/snackbar.model";
import {take} from "rxjs/operators";
import { CommonUtil } from "app/talk/utils/common.util";

@Injectable()

export class SmartObjectsService {

  editor: any;

  constructor(
    private _dialog: MatDialog,
    private _popup: PopupPositionService,
    private _translate: TranslateService,
    private _channelSnackbarService: ChannelSnackbarService
  ) {
  }

  getSmartObjectsPreviewRef(data, linkText, isEditable = true, shouldNavigate = true, clientX: number, clientY: number, isMobileView = false): MatDialogRef<SmartObjectsPreviewComponent> {
    return this._dialog.open(SmartObjectsPreviewComponent, {
      width: isMobileView ? "100%" : "fit-content",
      height: isMobileView ? "100%" : "fit-content",
      backdropClass: "transparent-backdrop",
      panelClass: isMobileView ? "smart-object-preview-container-mobile" : "smartlink-preview-container",
      data: {previewData: data, linkText, isEditable, shouldNavigate, isMobileView},
      position: isMobileView ? {
        left: "0px",
        top: "0px"
      } : this._popup.getPopupPosition({ width: 760, height: 520 }, clientX, clientY)
    });
  }

  checkForExistingObjects(metaData) {
    const availableTypes = ["smartlink", "keyword", "sticky-note", "remark"];
    const availability = {
      smartlink: true,
      keyword: true,
      sticky_note: true,
      remark: true
    };
    if (
      !metaData.hasOwnProperty("smartLinkData") ||
      metaData?.smartLinkData?.length === 0
    ) {
      availability.smartlink = false;
    }
    if (
      !metaData.hasOwnProperty("highlightData") ||
      !metaData?.highlightData?.name
    ) {
      availability.keyword = false;
    }
    if (
      !metaData.hasOwnProperty("stickyNotesData") ||
      metaData?.stickyNotesData?.length === 0
    ) {
      availability.sticky_note = false;
    }
    if (
      !metaData.hasOwnProperty("referencesData") ||
      metaData?.referencesData?.length === 0
    ) {
      availability.remark = false;
    }

    return {
      existingObjects: availableTypes.filter(t => !!availability[t.replace("-", "_")]),
      availability
    };
  }

  getSmartObjectsWrap() {
    const smartObjectWrap = document.createElement("s");
    smartObjectWrap.style.position = "relative";
    smartObjectWrap.setAttribute("data-type", "SMART_OBJECTS_WRAP");
    return smartObjectWrap;
  }

  getNewSmartObjectNode() {
    const element = document.createElement("s");
    element.dataset["type"] = SmartObject.SMART_OBJECTS;
    element.contentEditable = "false";

    return element;
  }


  getSmartObjectEntityIcon(name: SmartObject) {
    const imgEl = document.createElement("img");
    imgEl.onclick = () => imgEl.parentElement.click();
    imgEl.className = "smart-object-icon";
    switch (name) {
      case SmartObject.HIGHLIGHT:
        imgEl.src = HIGHLIGHT_MULTIPLE;
        return imgEl;
      case SmartObject.SMART_LINK:
        imgEl.src = SMART_LINK_MULTIPLE;
        return imgEl;
      case SmartObject.STICKY_NOTE:
        imgEl.src = STICKY_NOTE_MULTIPLE;
        return imgEl;
    }
  }

  updateSmartObjectIcons(selector = "editor_body") {
    setTimeout(() => {
      const smartObjectNodes = document.getElementById(selector)?.querySelectorAll("s[data-type=\"SMART_OBJECTS\"]");
      smartObjectNodes?.forEach(node => {
        if (this.removeIconsFromNode(node)) {
          this.addIconsToNode(node);
        }
      });
    }, 1000);
  }

  removeIconsFromNode(node: Element): boolean {
    node.querySelectorAll(".smart-object-icon").forEach(img => img.remove());
    return true;
  }

  addIconsToNode(node: Element) {
    const getAttribute = (element, attributeName) => element.getAttribute(attributeName);
    setTimeout(() => {
      if (getAttribute(node, "data-sticky-note") === "true") {
        node.prepend(this.getSmartObjectEntityIcon(SmartObject.STICKY_NOTE));
      }
      if (getAttribute(node, "data-smartlink") === "true") {
        node.prepend(this.getSmartObjectEntityIcon(SmartObject.SMART_LINK));
      }
      if (getAttribute(node, "data-keyword") === "true") {
        node.prepend(this.getSmartObjectEntityIcon(SmartObject.HIGHLIGHT));
      }
    });
  }

  async getConfirmationPopupRef(headerText = "SAVE_SMART_OBJECT", bodyText = "SAVE_SMART_OBJECT_MSG", okLabel = "SAVE") {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    return this._dialog.open(ConfirmationChannelComponent, Object.assign(
          {
            backdropClass: "vnctalk-form-backdrop",
            panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
            disableClose: true,
            data: {
              headerText,
              bodyText,
              okLabel,
              cancelLabel: "DISCARD",
            },
            autoFocus: true,
          },
          options
        )
      );
  }

  underDevelopment() {
    this._translate.get("UNDER_DEVELOPMENT").pipe(take(1)).subscribe(text => {
      this._channelSnackbarService.openSnackBar(text, SnackbarType.CLOSE);
    });
  }

  showSnackBar(message, type: SnackbarType = SnackbarType.CLOSE) {
    if (message === SmartLinkSnackBarMessage.SELECT_SHORTER_TEXT) {
      this._translate
        .get(message, { limit: SMART_LINK_MAX_TEXT_SELECTION_LIMIT })
        .pipe(take(1))
        .subscribe((text) =>
          this._channelSnackbarService.openSnackBar(text, type)
        );
      return;
    }
    this._translate
      .get(message)
      .pipe(take(1))
      .subscribe((text) =>
        this._channelSnackbarService.openSnackBar(text, type)
      );
  }

}

export enum SmartObject {
  SMART_LINK = "SMARTLINK",
  STICKY_NOTE = "STICKY_NOTE",
  HIGHLIGHT = "KEYWORD",
  REFERENCES = "REMARK",
  SMART_OBJECTS = "SMART_OBJECTS"
}

export enum SmartObjectActions {
  EDIT = "EDIT",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  CANCEL = "CANCEL",
  ADD_SMART_LINK = "ADD_SMART_LINK",
  ADD_REFERENCE = "ADD_REFERENCE",
  ADD_STICKY_NOTE = "ADD_STICKY_NOTE",
  ADD_HIGHLIGHT = "ADD_HIGHLIGHT"
}

const HIGHLIGHT_MULTIPLE = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTYuNSA4LjI1YS43NS43NSAwIDEgMSAwIDEuNWgtLjc1djEuNUEuNzUuNzUgMCAwIDEgMTUgMTJoLS43NVY5Ljc1SDguOTI1QTMuNzUxIDMuNzUxIDAgMCAxIDEuNSA5YTMuNzUgMy43NSAwIDAgMSA3LjQyNS0uNzVIMTYuNXpNNS4yNSA2Ljc1YTIuMjUgMi4yNSAwIDEgMCAwIDQuNSAyLjI1IDIuMjUgMCAwIDAgMC00LjV6IiBmaWxsPSIjMzg4RURBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==";
const STICKY_NOTE_MULTIPLE = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTIuNzUgMi4yNWEyLjI1IDIuMjUgMCAwIDEgMi4yNDUgMi4wOTZMMTUgNC41djlhMi4yNSAyLjI1IDAgMCAxLTIuMDk2IDIuMjQ1bC0uMTU0LjAwNUg3Ljk3OEwzLjgxIDEyLjI3NmEyLjI1IDIuMjUgMCAwIDEtLjgwMy0xLjU0N0wzIDEwLjU0N1Y0LjVhMi4yNSAyLjI1IDAgMCAxIDIuMDk2LTIuMjQ1bC4xNTQtLjAwNWg3LjV6bS0zLjc1NCA5LjYxOEw5IDEydjIuMjVoMy43NWEuNzUuNzUgMCAwIDAgLjcyNy0uNTYzbC4wMTgtLjEuMDA1LS4wODd2LTlhLjc1Ljc1IDAgMCAwLS43NS0uNzVoLTcuNWEuNzUuNzUgMCAwIDAtLjc0My42NDhMNC41IDQuNXY1LjI1aDIuMjVhMi4yNSAyLjI1IDAgMCAxIDIuMjQ2IDIuMTE4ek03LjUgMTMuMzk4VjEyYS43NS43NSAwIDAgMC0uNjYzLS43NDVsLS4wODctLjAwNUg0LjkyMUw3LjUgMTMuMzk4eiIgZmlsbD0iIzM4OEVEQSIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=";
const SMART_LINK_MULTIPLE = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNOS43NjIgMS41IDguMjQ5IDMuMDExVjNMMyAzdjEyLjc1aDEwLjV2LTMuODlsMS41LTEuNXY1LjM5YTEuNSAxLjUgMCAwIDEtMS41IDEuNUgzYTEuNSAxLjUgMCAwIDEtMS41LTEuNVYzQTEuNSAxLjUgMCAwIDEgMyAxLjVoNi43NjJ6bTIuNjIzIDMuODJjLjIgMCAuMzY5LjA4Mi41MS4yNDYuNzUuNzUgMS4xMjUgMS42MyAxLjEyNSAyLjYzNyAwIDEuMDA4LS4zNzUgMS44ODctMS4xMjUgMi42MzdsLTIuNjM3IDIuNjcyYy0uNzczLjcyNi0xLjY1OCAxLjA5LTIuNjU0IDEuMDlzLTEuODgxLS4zNjQtMi42NTUtMS4wOWMtLjcyNi0uNzc0LTEuMDktMS42NTgtMS4wOS0yLjY1NSAwLS45OTYuMzY0LTEuODggMS4wOS0yLjY1NGwxLjEyNS0xLjEyNWE0LjgyIDQuODIgMCAwIDAgLjI4MiAxLjgyOGwtLjM1Mi4zNTJjLS40NDUuNDY5LS42NjggMS4wMDItLjY2OCAxLjYgMCAuNTk3LjIyOSAxLjEyNC42ODYgMS41ODEuNDU3LjQ1Ny45ODQuNjg2IDEuNTgyLjY4Ni41OTcgMCAxLjEzLS4yMjMgMS42LS42NjhsMi42MzYtMi42NzJjLjQ0NS0uNDQ1LjY2OC0uOTY3LjY2OC0xLjU2NCAwLS41OTgtLjIyMy0xLjEzMS0uNjY4LTEuNmEuODE4LjgxOCAwIDAgMS0uMjExLS41NDVjMC0uMTk5LjA3LS4zNjkuMjExLS41MWEuNzU2Ljc1NiAwIDAgMSAuNTQ1LS4yNDZ6TTEzLjk2Ny43NWMuOTk2IDAgMS44OC4zNjMgMi42NTQgMS4wOS43MjcuNzczIDEuMDkgMS42NTggMS4wOSAyLjY1NHMtLjM2MyAxLjg4MS0xLjA5IDIuNjU0bC0xLjEyNSAxLjEyNWE0LjgyIDQuODIgMCAwIDAtLjI4MS0xLjgyOGwuMzUyLS4zNTFjLjQ0NS0uNDY5LjY2OC0xLjAwMi42NjgtMS42IDAtLjU5OC0uMjI5LTEuMTI1LS42ODYtMS41ODItLjQ1Ny0uNDU3LS45ODQtLjY4NS0xLjU4Mi0uNjg1LS41OTggMC0xLjEzLjIyMi0xLjYuNjY4TDkuNzMxIDUuNTY2Yy0uNDQ2LjQ0Ni0uNjY4Ljk2Ny0uNjY4IDEuNTY1cy4yMjIgMS4xMy42NjggMS42Yy4xNC4xNjQuMjEuMzQ1LjIxLjU0NCAwIC4yLS4wNzYuMzc1LS4yMjguNTI4YS43MjQuNzI0IDAgMCAxLS41MjcuMjI4Yy0uMiAwLS4zNy0uMDgyLS41MS0uMjQ2LS43NS0uNzUtMS4xMjUtMS42MjktMS4xMjUtMi42MzcgMC0xLjAwNy4zNzUtMS44ODYgMS4xMjUtMi42MzZsMi42MzctMi42NzJjLjc3My0uNzI3IDEuNjU4LTEuMDkgMi42NTQtMS4wOXoiIGZpbGw9IiMzODhFREEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K";
const SMART_LINK_MAX_TEXT_SELECTION_LIMIT = 100;

enum SmartLinkSnackBarMessage {
  SELECT_SOME_TEXT = "SELECT_SOME_TEXT",
  SELECT_SHORTER_TEXT = "SELECT_SHORTER_TEXT"
}
