
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { ContactRepository } from "../../repositories/contact.repository";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { Conversation } from "app/talk/models/conversation.model";
import { CommonUtil } from "app/talk/utils/common.util";
import { distinctUntilChanged, filter, take } from "rxjs";

@Pipe({
  name: "vpGetFullName",
  pure: false
})
export class GetFullNamePipe implements PipeTransform {
  constructor(private contactsRepo: ContactRepository, private conversationRepo: ConversationRepository) {
  }

  transform(value: any, args?: any[]): string {
    let fullName;
    if (!value) {
      return "";
    }
    if (value.bare) {
      value = value.bare;
    }
    if (value.startsWith("broadcast-") || value.indexOf("@conference.") !== -1) {
      let conv: Conversation;
      this.conversationRepo.getConversationById(value).pipe(take(1)).subscribe(c => conv = c);
      if (!!conv && conv.broadcast_title) {
        fullName = conv.broadcast_title;
      } else if (!!conv && conv.groupChatTitle) {
        fullName = conv.groupChatTitle;
      }
    }
    this.contactsRepo.getContactById(value).pipe(take(1))
    .subscribe(contact => {
      if (contact) {
        fullName = contact.name || contact.local;
        if (fullName === contact.local) {
          fullName = CommonUtil.beautifyName(fullName);
        }
      }
    });
    if (!fullName) {
      fullName = CommonUtil.beautifyName(value.split("@")[0]);
    }
    return fullName;
  }

}
