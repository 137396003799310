<div class="related-topics-component">
  <div class="header">
    <span class="title">{{'RELATED_TOPICS' | translate}}</span>
    <div (click)="onClosePopup()" class="close-button" matTooltip="{{ 'CLOSE' | translate }}">
      <ng-container [ngTemplateOutlet]="closeIconTemplate"></ng-container>
    </div>
  </div>

  <div class="search-box">
    <vnc-input label="Search" type="icon-filled"
               placeholder="Search"
               [leadingIconTemplate]="searchIconTemplate"
               [trailingIconTemplate]="(relatedTopicsSearchControl.value !== '' && relatedTopicsSearchControl.value !== null)  ? closeIconTemplate : ''"
               [showLabel]="true"
               [showClear]="true"
               [form]="relatedTopicsSearchControl"
               clearTooltip="{{ 'CLEAR' | translate }}"
               (trailingIconClicked)="resetInput()"></vnc-input>
  </div>

  <div class="filters-box">
<!--    GLOBAL CHIPS-->
    <ng-container>
      <vnc-chips [text]="'UNREAD' | translate | lowercase"
                 [active]="appliedFilters[filterType?.GLOBAL_UNREAD]"
                 (clickEvent)="updateGlobalFilters(filterType.GLOBAL_UNREAD)"
                 leftIcon="unread" type="filter"
                 size="s"></vnc-chips>
      <vnc-chips [text]="'ARCHIVED' | translate"
                 [active]="appliedFilters[filterType?.GLOBAL_ARCHIVED]"
                 (clickEvent)="updateGlobalFilters(filterType.GLOBAL_ARCHIVED)"
                 leftIcon="archive"
                 type="filter"
                 size="s"></vnc-chips>
      <vnc-smart-link-filter-chip [showAllAsDefault]="true"
                                  (dateFilterChange)="relatedTopicsPopupFilterService.onFilterChange($event, filterType.GLOBAL_DATE_RANGE)"
                                  [showAllTimeDate]="true" [linkCalendar]="false" [showDropdown]="true" [shortRange]="false" [applyLabel]="'APPLY' | translate" [cancelLabel]="'CANCEL' | translate" [clearLabel]="'ALL_TIMES' | translate" [customRangeLabel]="'CUSTOM_RANGE' | translate" [rangesLabel]="rangesLabel" [showCustomRangeLabel]="true"
                                  type="DATE_RANGE" [showCancelDate]="true" [lang]="defaultLang"></vnc-smart-link-filter-chip>

      <div class="vertical-divider"></div>
    </ng-container>

    <!-- TOPIC CHIPS -->
    <ng-container>
      <span class="chip-with-label resettable-chip">
        <span class="label">{{'TOPIC' | translate}}</span>
        <vnc-smart-link-filter-chip (apply)="relatedTopicsPopupFilterService.onFilterChange($event, filterType.TOPIC_PRIVACY)"
                                    [label]="'PRIVACY' | translate" [allTypeOptionValue]="'all'" type="SELECT"
                                    [showResetIcon]="true"
                                    (resetFilter)="relatedTopicsPopupFilterService.resetFilter(filterType.TOPIC_PRIVACY)"
                                    [options]="relatedTopicsPopupFilterService.topicPrivacyOptions"></vnc-smart-link-filter-chip>
<!--        <vnc-smart-link-filter-chip (apply)="relatedTopicsPopupFilterService.onFilterChange($event, filterType.TOPIC_TYPE)"-->
<!--                                    [label]="'TYPE' | translate" [allTypeOptionValue]="'any'" type="SELECT"-->
<!--                                    [multiple]="true"-->
<!--                                    [showResetIcon]="true"-->
<!--                                    (resetFilter)="relatedTopicsPopupFilterService.resetFilter(filterType.TOPIC_TYPE)"-->
<!--                                    [selectedValues]="appliedFilters[filterType.TOPIC_TYPE]"-->
<!--                                    [options]="relatedTopicsPopupFilterService.topicTypeOptions"></vnc-smart-link-filter-chip>-->
      </span>
      <span class="resettable-chip">
        <vnc-smart-link-filter-chip (apply)="relatedTopicsPopupFilterService.onFilterChange($event, filterType.TOPIC_USER)"
                                    [label]="'User' | translate" [multiple]="true" [allTypeOptionValue]="'all'" type="SELECT"
                                    [searchControl]="relatedTopicsPopupFilterService.userSearchControl"
                                    [disableSearchFilterDropdown]="true"
                                    [showSearchBox]="true"
                                    [showResetIcon]="true"
                                    (resetFilter)="relatedTopicsPopupFilterService.resetFilter(filterType.TOPIC_USER)"
                                    [selectedValues]="appliedFilters[filterType.TOPIC_USER]"
                                    [options]="relatedTopicsPopupFilterService.$userOptions | async"></vnc-smart-link-filter-chip>
      </span>
      <span class="resettable-chip">
        <vnc-smart-link-filter-chip (apply)="relatedTopicsPopupFilterService.onFilterChange($event, filterType.TOPIC_AUTHOR)"
                                    [label]="'Author' | translate" [multiple]="true" [allTypeOptionValue]="'all'" type="SELECT"
                                    [searchControl]="relatedTopicsPopupFilterService.userSearchControl"
                                    [disableSearchFilterDropdown]="true"
                                    [showSearchBox]="true"
                                    [showResetIcon]="true"
                                    (clearFilter)="relatedTopicsPopupFilterService.resetFilter(filterType.TOPIC_AUTHOR)"
                                    (resetFilter)="relatedTopicsPopupFilterService.resetFilter(filterType.TOPIC_AUTHOR)"
                                    [selectedValues]="appliedFilters[filterType.TOPIC_AUTHOR]"
                                    [options]="relatedTopicsPopupFilterService.$userOptions | async"></vnc-smart-link-filter-chip>
      </span>
    </ng-container>
  </div>

  <div class="related-topics-search-results"
       vpInfiniteScroll
       scrollPercentage="0.8"
       (onUserScroll)="relatedTopicsPopupFilterService.loadMoreSearchResults()">

    <ng-container *ngFor="let searchResult of ($searchResults | async)">
      <vnc-smart-link-search-result [avatarTemplate]="avatarTemplate" [resultData]="searchResult"
                                    [showActionIconsOnHover]="true"
                                    class="related-topics-popup-search-result"
                                    [actionType]="'preview'"
                                    [resultType]="searchResult?.resultType"
                                    [topicsLabel]="searchResult?.topics_count === 1 ? 'topic' : 'topics'"
                                    (actionClicked)="showTopicPreview($event, searchResult)"
                                    (click)="selectSearchResult(searchResult)"></vnc-smart-link-search-result>
      <ng-template #avatarTemplate>
        <vnc-avatar class="topic-avatar" *ngIf="searchResult?.resultType === 'TOPIC'"
                    [imgSrc]="searchResult?.heroAttachmentThumbnailUrl"
                    [defaultAvatar]="searchResult?.default_cover_url || searchResult?.default_cover"
                    [rightIconDefaultAvatarTemplate]="authorDefaultAvatar"
                    [rightIcon]="searchResult?.author?.avatar_url"
                    [leftIcon]="searchResult?.subscribed ? 'subscribe' : ''"
                    [totalUnread]="searchResult?.unread_comments_count"></vnc-avatar>
      </ng-template>
    </ng-container>

    <div class="load-spinner" *ngIf="relatedTopicsPopupFilterService.$loading | async">
      <vnc-loader-circular [color]="'blue'" [size]="'m'"></vnc-loader-circular>
      <span class="loading-text">{{'LOADING' | translate}}...</span>
    </div>

    <div class="error-message" *ngIf="(relatedTopicsPopupFilterService.$loadingError | async) && !(relatedTopicsPopupFilterService.$searchResults | async)?.length">
      {{'LOAD_FAILED' | translate}}
    </div>

    <div class="error-message" *ngIf="!($searchResults | async).length && (relatedTopicsPopupFilterService.$loading | async) === false && (relatedTopicsPopupFilterService.$loadingError | async) === false">
      {{ 'NOTHING_TO_DISPLAY' | translate }}
    </div>
  </div>
</div>

<ng-template #searchIconTemplate>
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icon-24px" transform="translate(3.000000, 3.000000)" fill="#8B96A0">
        <path d="M12.5,11 L11.71,11 L11.43,10.73 C12.41,9.59 13,8.11 13,6.5 C13,2.91 10.09,0 6.5,0 C2.91,0 0,2.91 0,6.5 C0,10.09 2.91,13 6.5,13 C8.11,13 9.59,12.41 10.73,11.43 L11,11.71 L11,12.5 L16,17.49 L17.49,16 L12.5,11 L12.5,11 Z M6.5,11 C4.01,11 2,8.99 2,6.5 C2,4.01 4.01,2 6.5,2 C8.99,2 11,4.01 11,6.5 C11,8.99 8.99,11 6.5,11 L6.5,11 Z" id="Shape"></path>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #closeIconTemplate>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Talk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="0220.-VNCtalk--Channels--Topic-#021" transform="translate(-1463.000000, -697.000000)" fill="#8B96A0">
        <g id="Popup/related-object" transform="translate(784.000000, 682.000000)">
          <g id="Group" transform="translate(672.000000, 8.000000)">
            <g id="Container/18px" transform="translate(4.000000, 4.000000)">
              <g id="Icon/product/close-new" transform="translate(3.000000, 3.000000)">
                <path d="M14.2123106,3.7876894 C14.5052038,4.08058262 14.5052038,4.55545635 14.2123106,4.84834957 L10.0606602,9 L14.2123106,13.1516504 C14.5052038,13.4445436 14.5052038,13.9194174 14.2123106,14.2123106 C13.9194174,14.5052038 13.4445436,14.5052038 13.1516504,14.2123106 L9,10.0606602 L4.84834957,14.2123106 C4.55545635,14.5052038 4.08058262,14.5052038 3.7876894,14.2123106 C3.49479618,13.9194174 3.49479618,13.4445436 3.7876894,13.1516504 L7.93933983,9 L3.7876894,4.84834957 C3.49479618,4.55545635 3.49479618,4.08058262 3.7876894,3.7876894 C4.08058262,3.49479618 4.55545635,3.49479618 4.84834957,3.7876894 L9,7.93933983 L13.1516504,3.7876894 C13.4445436,3.49479618 13.9194174,3.49479618 14.2123106,3.7876894 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #resetIconTemplate>
  <div class="reset-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <defs>
        <filter id="oe0d1hra7a" width="119.2%" height="133%" x="-9.6%" y="-16.5%" filterUnits="objectBoundingBox">
          <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"/>
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
          <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6"/>
          <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0"/>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="shadowMatrixOuter2"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g fill="#FFF">
          <g filter="url(#oe0d1hra7a)" transform="translate(-1016 -664) translate(645 560)">
            <g>
              <g>
                <g>
                  <path d="M8.475 3.525c.195.195.195.512 0 .707L6.707 6l1.768 1.768c.195.195.195.512 0 .707-.195.195-.512.195-.707 0L6 6.707 4.232 8.475c-.195.195-.512.195-.707 0-.195-.195-.195-.512 0-.707L5.293 6 3.525 4.232c-.195-.195-.195-.512 0-.707.195-.195.512-.195.707 0L6 5.293l1.768-1.768c.195-.195.512-.195.707 0z" transform="translate(12 96) translate(357 6) translate(2 2)"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</ng-template>

<ng-template #authorDefaultAvatar>
  <svg width="20px" height="20px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <circle id="path-1" cx="18" cy="18" r="18"></circle>
    </defs>
    <g id="Avatar/empty-user" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-1"></use>
      </mask>
      <use id="Oval" fill="#F3F5F6" xlink:href="#path-1"></use>
      <path d="M36,37 L0,37 L0,30.1780432 C3.2398217,28.5963644 5.99816262,27.805525 8.27502276,27.805525 C11.690313,27.805525 13.0748922,27.1374703 13.2178223,24.5298771 C13.3607523,21.9222839 12.822374,21.5330938 12.5966535,20.8356024 C12.4461732,20.370608 12.2937219,19.7697192 12.1392996,19.0329357 C11.4805779,19.1711696 11.2062395,18.7283463 10.7091802,18.0197875 C10.2121209,17.3112288 9.98978676,15.331644 10.0591259,14.7563247 C10.105352,14.3727785 10.3220367,14.2431842 10.7091802,14.3675419 L11.0992128,14.4971361 C10.4058215,12.8556087 10.3624846,11.3906596 10.7091802,8.71237798 C10.9196899,7.08615503 12.4572745,7.57534452 12.1392996,6.20310265 C12.5553344,5.32186159 15.0194139,4 18.1961696,4 C20.4657938,4.06333384 22.4338368,5.14328611 24.1002986,7.23985683 C24.8976986,8.43212415 25.1577203,10.1600478 24.8803638,12.4236278 L24.3603204,14.4971361 L24.7503529,14.3675419 C25.0434747,14.2281996 25.2601595,14.3577939 25.4004072,14.7563247 C25.6107788,15.3541209 25.062379,18.2208116 23.9702878,19.0329357 L23.3202335,19.4217185 C23.305612,19.8171855 23.1653132,20.4126049 22.8993371,21.2079766 C22.6333609,22.0033483 22.4269641,22.4446832 22.2801466,22.5319811 C22.0681715,23.2540277 22.1288566,24.0623471 22.4622019,24.9569394 C22.9622198,26.2988278 23.6222399,27.9914894 28.4204307,28.4945079 C31.6192246,28.8298536 34.1457477,29.6868331 36,31.0654465 L36,37 Z" id="Path" fill="#C5CBD1" fill-rule="nonzero" mask="url(#mask-2)"></path>
    </g>
  </svg>
</ng-template>
