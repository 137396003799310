
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import format from "date-fns/format";
import enUS from "date-fns/locale/en-US";
import de from "date-fns/locale/de";

@Pipe({
  name: "vpDisplayTime",
  pure: false
})
export class DisplayTimePipe implements PipeTransform {
constructor(private translateService: TranslateService) {
}
  transform(timestamp: any, formatStr: string = "p"): string {
    if (formatStr === "MMM dd" && this.translateService.currentLang === "de") {
      formatStr = "dd. MMM.";
    } else if (formatStr === "MMM dd, yyyy p" && this.translateService.currentLang === "de") {
      formatStr = "dd. MMM yyyy p";
    }
    let time = format(timestamp, formatStr, {locale: this.translateService.currentLang === "de" ? de : enUS});
    if (formatStr === "MMM dd") {
      time = time.replace("Juni", "Jun");
    } else if (formatStr === "MM/dd/yyyy") {
      if (this.translateService.currentLang === "de") {
        formatStr = "dd.MM.yyyy";
      }
      time = format(new Date(timestamp), formatStr, {locale: this.translateService.currentLang === "de" ? de : enUS});
    } else if (formatStr === "MM/dd/yyyy hh:mm") {
      if (this.translateService.currentLang === "de") {
        formatStr = "dd.MM.yyyy p";
      }
      time = format(new Date(timestamp), formatStr, {locale: this.translateService.currentLang === "de" ? de : enUS});
    }
    return time;
  }

}
