<vnc-dialog [classes]="['archive-trash-selection-window']" [headerTemplate]="mobileHeaderTemplate"
            classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
            [bodyTemplate]="mobileBodyTemplate">
</vnc-dialog>

<ng-template #mobileHeaderTemplate>
  <vnc-mobile-header headerType="dark-back-more"
                     darkBackMorePlaceHolder="{{ ('ARCHIVE' | translate) + ' & ' + ('TRASH' | translate) }}"
                     (darkBackPress)="closeAll()">
  </vnc-mobile-header>
</ng-template>

<ng-template #mobileBodyTemplate>
  <div class="selection-pane">
    <vp-dropdown-item
      [itemType]="'icon-drop-default'" [prefixIconTemplate]="archiveIconTemplate"
      [sufixIconTemplate]="dropIconTemplate"
      (onClickEvent)="openArchiveWindow()"
      [size]="'l'" [isMobile]="true"
      [label]="'ARCHIVE' | translate"></vp-dropdown-item>
    <vp-dropdown-item
      [itemType]="'icon-drop-default'" [prefixIconTemplate]="trashIconTemplate"
      [sufixIconTemplate]="dropIconTemplate"
      (onClickEvent)="openTrashWindow()"
      [size]="'l'" [isMobile]="true"
      [label]="'TRASH' | translate"></vp-dropdown-item>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <span></span>
</ng-template>

<ng-template #archiveIconTemplate>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.667 3c1.267 0 2.251 1.067 2.328 2.36l.005.164v12.843c0 1.358-.917 2.535-2.174 2.627l-.16.006H5.334c-1.285 0-2.253-1.123-2.328-2.464L3 18.367V5.524c0-1.312.933-2.431 2.176-2.519L5.333 3h13.334zM19 9.571H5v8.796c0 .303.12.518.237.598l.05.026.046.009h13.334c.12 0 .29-.193.326-.509l.007-.124V9.571zM14.25 11c.414 0 .75.346.75.773a.762.762 0 0 1-.75.772h-4.5a.762.762 0 0 1-.75-.772c0-.427.336-.773.75-.773h4.5zm4.417-6H5.333c-.135 0-.293.164-.327.422L5 5.524V7.57h14V5.524c0-.247-.11-.427-.23-.494l-.052-.022L18.667 5z" fill="#000" fill-rule="evenodd"/>
  </svg>
</ng-template>
<ng-template #trashIconTemplate>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 7v11.286C18 19.775 16.809 21 15.333 21H8.667C7.19 21 6 19.775 6 18.286V7h12zm-2 2H8v9.286c0 .363.26.66.578.708l.089.006h6.666c.358 0 .667-.318.667-.714V9zm-3-6a2 2 0 0 1 1.733 1H18a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2h3.268A2 2 0 0 1 11 3h2z" fill="#000" fill-rule="evenodd"/>
  </svg>
</ng-template>
<ng-template #dropIconTemplate>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.989 5.567a.7.7 0 0 0 0 1.018l2.87 2.757-2.87 2.756a.7.7 0 0 0 0 1.019.772.772 0 0 0 1.06 0l3.932-3.775L8.05 5.567a.772.772 0 0 0-1.06 0z" fill="#8B96A0" fill-rule="evenodd"/>
  </svg>
</ng-template>
