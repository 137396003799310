import { Injectable } from "@angular/core";
import { VncLibraryService } from "vnc-library";
import { Observable } from "rxjs";
import { SnackbarPositionType } from "./models/snackbar.model";


@Injectable()
export class ChannelSnackbarService {
  constructor(private vncLibaryService: VncLibraryService) {
  }

  openSnackBar(msg: string,
    snackbarType?: string,
    actionLabel?: string,
    link?: string,
    timeout?: number,
    snackbarVerticalPosition = SnackbarPositionType.BOTTOM,
    snackbarHorizontalPosition = SnackbarPositionType.LEFT
  ): Observable<any> {
    return this.vncLibaryService.openSnackBar(msg, snackbarType, actionLabel, link, timeout, snackbarVerticalPosition, snackbarHorizontalPosition);
  }
}
