
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import format from "date-fns/format";
import enUS from "date-fns/locale/en-US";
import de from "date-fns/locale/de";

export class DateUtil {

  static DAYS_TS = 1000 * 60 * 60 * 24;

  static isSameDay(timestamp1: number, timestamp2 = Date.now()): boolean {
    const days1 = Math.floor(timestamp1 / this.DAYS_TS);
    const days2 = Math.floor(timestamp2 / this.DAYS_TS);

    return days1 === days2;
  }

  static ddmmyyyyString(timestamp: number, dateFormat: any): string {
    return format(timestamp, dateFormat);
  }

  static dateStringToTs(dateString: string): number {
    return new Date(dateString).getTime();
  }

  static tsToISOString(timestamp: number): string {
    return new Date(timestamp).toISOString();
  }

  static getDisplayTime(timestamp, lang, datetimeService, translateService): string {
    const isTodayTimestamp = this.isSameDay(+timestamp);
    const displayTimestamp = datetimeService.getDisplayTime(+timestamp);

    let displayTime = format(displayTimestamp, "HH:mm", {locale: lang === "de" ? de : enUS});
    if (lang === "de") {
      displayTime = displayTime  + " Uhr";
    }

    let at = "at";
    let dateLabel;
    translateService.get("AT").subscribe(text => at = text.toLowerCase());
    if (isTodayTimestamp) {
      translateService.get("TODAY").subscribe(text => dateLabel = text);
    } else {
      const dateLabelFormatStr = lang === "de" ? "dd.MM.yyyy" : "dd.MM.yyyy";
      dateLabel = format(displayTimestamp, dateLabelFormatStr, {locale: lang === "de" ? de : enUS});
    }
    return `${dateLabel} ${at} ${displayTime}`;
  }
}
