
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EnvironmentTheme } from "./environment.interface";

export const theme: EnvironmentTheme = {
  title: "VNCtalk",
  icon: "./assets/icons/VNCtalk.svg",
  headerBranding: "<span>VNC</span><span class=\"product-name\">talk</span>",
  serviceDeskLink: "https://redmine.vnc.biz/helpdesk/incidents/new",
  logoPath: "/assets/img/logo.png",
  manualLink: "MANUAL_LINK_VNCTALK",
  helpLink: "HELP_LINK_VNCTALK",
  FAQLink: "FAQ_LINK_VNCTALK",
  termsLink: "TERMS_OF_USE_LINK",
  privacyLink: "DATA_PRIVACY_LINK",
  theme: "vnctalk",
  enableScreenSharing: true,
  enableSentry: false,
  enableChangeScreenName: true,
  enablePreviewURL: true,
  enableEditProfile: true,
  enableAddParticipantsSingleChat: true,
  meetingWaitingTime: 10, // mins
  defaultServerUrl: "https://talk.vnclagoon-live.com",
  isDebugMode: true,
  chatBackground: "vnc_pattern",
  chatBackgroundColor: "#FFFFFF"
};
