/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Channel } from "app/channels/models/channel.model";
import { Action } from ".";
import { Member } from "../../models/member.model";
import { File } from "../../models/file.model";



export class SubchannelActionTypes {
  static SUBCHANNEL_LOAD_REQUEST = "[Subchannel] Load Request";
  static SUBCHANNEL_LOAD_SUCCESS = "[Subchannel] Load Success";
  static SUBCHANNEL_ADD = "[Subchannel] Add";
  static SUBCHANNEL_DELETE = "[Subchannel] Delete";
  static SUBCHANNEL_UPDATE = "[Subchannel] Update";
  static SUBCHANNEL_BULK_ADD = "[Subchannel] Bulk Add";
  static SUBCHANNEL_SEARCH_STRING_UPDATE = "[Subchannel] Search String Update";
  static SUBCHANNEL_USER_JID_UPDATE = "[Subchannel] User Jid Update";
  static SUBCHANNEL_INFO_UPDATE = "[Subchannel] All Channel Info Update";
  static FILTERED_TOPICS_INFO_UPDATE = "[Subchannel] Filtered Topics Info Update";
  static TOPIC_FILTERS_INFO_UPDATE = "[Subchannel] Topic Filters Info Update";
  static SEARCHED_SUBCHANNEL_INFO_UPDATE = "[Subchannel] Searched Channel Info Update";
  static SELECTED_CHANNEL = "[Subchannel] Selected Channel";
  static SELECTED_SUBCHANNEL = "[Subchannel] Selected Subchannel";
  static SUBCHANNEL_MEMBERS_LOAD_REQUEST = "[Subchannel] Subchannel Members Load Request";
  static SUBCHANNEL_MEMBERS_LOAD_SUCCESS = "[Subchannel] Subchannel Members Load Success";
  static SUBCHANNEL_MEMBERS_OFFSET_UPDATE = "[Subchannel] Subchannel Members Offset Update";
  static SUBCHANNEL_MEMBERS_ADD = "[Subchannel] Subchannel Members Add";
  static SUBCHANNEL_MEMBERS_UPDATE = "[Subchannel] Subchannel Members UPDATE";
  static SUBCHANNEL_MEMBERS_REMOVE = "[Subchannel] Subchannel Members Remove";
  static SUBCHANNEL_FILES_LOAD_REQUEST = "[Subchannel] Subchannel Files Load Request";
  static SUBCHANNEL_FILES_LOAD_SUCCESS = "[Subchannel] Subchannel Files Load Success";
  static SUBCHANNEL_FILES_OFFSET_UPDATE = "[Subchannel] Subchannel Files Offset Update";
  static SUBCHANNEL_FILES_DELETE = "[Subchannel] Subchannel Files Delete";
  static SUBCHANNEL_NO_ACCESS = "[Subchannel] Subchannel No Access";
  static SUBCHANNEL_NOT_FOUND = "[Subchannel] Subchannel Not Found";
  static SUBCHANNEL_SIDEBAR_TAB_CHANGE = "[Subchannel] Subchannel Sidebar Tab Change";
  static SUBCHANNEL_AVATAR_FAILED_TO_LOAD = "[Subchannel] Subchannel Avatar Failed To Load";
  static SUBCHANNEL_AVATAR_FAILED_TO_LOAD_REMOVE = "[Subchannel] Subchannel Avatar Failed To Load Remove";
}



export class SubchannelLoadRequest implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_LOAD_REQUEST;
}



export class SubchannelLoadSuccess implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_LOAD_SUCCESS;
  constructor(public payload: any[]) {
  }
}

export class SubchannelAdd implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_ADD;
  constructor(public payload: Channel) {
  }
}

export class SubchannelUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_UPDATE;
  constructor(public payload: any) {
  }
}

export class SubchannelDelete implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_DELETE;
  constructor(public payload: string) {
  }
}

export class SubchannelBulkAdd implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_BULK_ADD;
  constructor(public payload: Channel[]) {
  }
}

export class SubchannelSearchStringUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_SEARCH_STRING_UPDATE;
  constructor(public payload: string) {
  }
}

export class SubchannelUserJidUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_USER_JID_UPDATE;
  constructor(public payload: string) {
  }
}

export class SubchannelInfoUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_INFO_UPDATE;
  constructor(public payload: {totalCount: number, parent: { [key: string]: { subchannels: string[], allLoaded: boolean } | null }, isLoading: boolean, isLoaded: boolean}) {
  }
}

export class FilteredTopicsInfoUpdate implements Action {
  readonly type = SubchannelActionTypes.FILTERED_TOPICS_INFO_UPDATE;
  constructor(public payload: {totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean, channelId: string, filterApplied: boolean}) {
  }
}

export class TopicFiltersInfoUpdate implements Action {
  readonly type = SubchannelActionTypes.TOPIC_FILTERS_INFO_UPDATE;
  constructor(public payload: {filters: any[], total_count: number}) {
  }
}


export class SearchedSubhannelInfoUpdate implements Action {
  readonly type = SubchannelActionTypes.SEARCHED_SUBCHANNEL_INFO_UPDATE;
  constructor(public payload: {keyword: string, ids: string[], totalCount: number, offset: number}) {
  }
}


export class SetSelectedChannelId implements Action {
  readonly type = SubchannelActionTypes.SELECTED_CHANNEL;
  constructor(public payload: string) {
  }
}

export class SetSelectedSubchannelId implements Action {
    readonly type = SubchannelActionTypes.SELECTED_SUBCHANNEL;
    constructor(public payload: string) {
    }
  }

export class SubchannelMembersLoadRequest implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_MEMBERS_LOAD_REQUEST;
  constructor(public payload: string) {
  }
}



export class SubchannelMembersLoadSuccess implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_MEMBERS_LOAD_SUCCESS;
  constructor(public payload: { channelId: string, members: Member[], total_count: number }) {
  }
}

export class SubchannelMemberOffsetUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_MEMBERS_OFFSET_UPDATE;
  constructor(public payload: { channelId: string, offset: number }) {
  }
}

export class SubchannelMembersAdd implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_MEMBERS_ADD;
  constructor(public payload: { channelId: string, members: { jid: string, role: string }[] }) {
  }
}

export class SubchannelMembersUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_MEMBERS_UPDATE;
  constructor(public payload: { channelId: string, members: { jid: string, role: string }[] }) {
  }
}

export class SubchannelMembersRemove implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_MEMBERS_REMOVE;
  constructor(public payload: { channelId: string, memberIds: string[] }) {
  }
}

export class SubchannelFilesLoadRequest implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_FILES_LOAD_REQUEST;
  constructor(public payload: Channel["id"]) {
  }
}

export class SubchannelFilesLoadSuccess implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_FILES_LOAD_SUCCESS;
  constructor(public payload: { channelId: Channel["id"], files: File[], total_count: number }) {
  }
}

export class SubchannelFilesOffsetUpdate implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_FILES_OFFSET_UPDATE;
  constructor(public payload: { channelId: string, offset: number }) {
  }
}

export class SubchannelFilesRemove implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_FILES_DELETE;
  constructor(public payload: { fileId: number, channelId: string}) {
  }
}

export class SubchannelNoAccess implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_NO_ACCESS;
  constructor(public payload: boolean) {
  }
}

export class SubchannelNotFound implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_NOT_FOUND;
  constructor(public payload: boolean) {
  }
}

export class SubchannelSideBarTabChange implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_SIDEBAR_TAB_CHANGE;
  constructor(public payload: string) {
  }
}

export class SubchannelAvatarFailedToLoad implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_AVATAR_FAILED_TO_LOAD;
  constructor(public payload: string) {
  }
}

export class SubchannelAvatarFailedToLoadRemove implements Action {
  readonly type = SubchannelActionTypes.SUBCHANNEL_AVATAR_FAILED_TO_LOAD_REMOVE;
  constructor(public payload: string) {
  }
}
