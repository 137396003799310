import {Injectable} from "@angular/core";
import {ChannelService} from "../../channel.service";
import {Observable} from "rxjs";
import {BehaviorSubject} from "rxjs";
import {ChannelState} from "../../store/reducers/channel";
import {Store} from "@ngrx/store";
import {getAllAvailableTopicFiltersInfo} from "../../store/reducers";
import {TopicFiltersInfoUpdate} from "../../store/actions/channel";
import {debounceTime, map, take} from "rxjs/operators";
import {noop} from "rxjs";
import {ChannelRepository} from "../../repository/channel.repository";
import {RootState} from "../../../reducers";
import {TalkRootState} from "../../../talk/reducers";
import {CommonUtil} from "../../../talk/utils/common.util";
import {AvatarRepository} from "../../../talk/repositories/avatar.repository";
import {ConfigService} from "../../../config.service";
import {DEFAULT_USER_AVATAR} from "../../smart-link-popup/smart-link-popup-filters.service";
import {ContactRepository} from "../../../talk/repositories/contact.repository";
import {UntypedFormControl} from "@angular/forms";
import {Contact} from "../../../talk/models/contact.model";
import {ConversationRepository} from "../../../talk/repositories/conversation.repository";
import {ChannelSnackbarService} from "../../channel-snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarType} from "../../models/snackbar.model";
import {Broadcaster} from "../../../talk/shared/providers";
import {ConstantsUtil} from "../../../talk/utils/constants.util";
import { format } from "date-fns";

@Injectable()
export class TopicFilterComponentService {
  defaultFilters = new BehaviorSubject<FilterModel[]>([]);
  channelId = new BehaviorSubject<string>("");
  fetchingFilters$ = new BehaviorSubject<boolean>(false);
  selectedFilters = new BehaviorSubject<string[]>([]);
  globalFilter = TopicGlobalFilter;
  typeOptions = [
    {
      label: "Public",
      value: "0"
    },
    {
      label: "Private",
      value: "1"
    }
  ];

  userSearchControl = new UntypedFormControl("");
  groupControl = new UntypedFormControl("asc");
  tagSearchControl = new UntypedFormControl("");
  $userOptions = new BehaviorSubject<Option[]>([]);
  $tagOptions = new BehaviorSubject<Option[]>([]);
  today = new Date();
  private _allContacts: any[] = [];
  private _allTags: any[] = [];
  filtersChanged$ = new BehaviorSubject<boolean>(false);

  selectedGlobalFilters = new BehaviorSubject<string[]>([TopicGlobalFilter.ALL_TOPICS]);
  queryString: string;
  oldChannelId: string;

  globalFilterValues = {
    [TopicGlobalFilter.UNREAD]: 0
  };

  constructor(private _channelService: ChannelService,
              private  channelRepository: ChannelRepository,
              private _channelRootStore: Store<ChannelState>,
              private _appRootStore: Store<RootState>,
              private _avatarRepository: AvatarRepository,
              private _configService: ConfigService,
              private _contactRepository: ContactRepository,
              private _conversationRepository: ConversationRepository,
              private _talkRootStore: Store<TalkRootState>,
              private _channelSnackBarService: ChannelSnackbarService,
              private _translate: TranslateService,
              private _broadcaster: Broadcaster,
              private channelService : ChannelService,
              private chatRepository: ContactRepository,
              ) {

  }

  filters: Observable<FilterModel[]> = this._channelRootStore.select(getAllAvailableTopicFiltersInfo).pipe(map((res: any) =>{
    return res?.filters;
  }));

  getAvailableFilters() {
    this.fetchingFilters$.next(true);
    this._channelRootStore.select(getAllAvailableTopicFiltersInfo).pipe(take(1)).subscribe( res => {
      if (!res) {
        this.fetchAvailableFilters();
      }
      else {
        this.fetchingFilters$.next(false);
      }
    });
  }

  private async getOldFilters() {
    return await this._channelRootStore.select(getAllAvailableTopicFiltersInfo).pipe(take(1), map(res => res?.filters)).toPromise();
  }

  private dispatchNewFilters(newFilters) {
    this._channelRootStore.dispatch(new TopicFiltersInfoUpdate({filters: newFilters, total_count: newFilters.length}));
  }

  private async fetchAvailableFilters() {
    this.fetchAllUsers();
    this.fetchAllTags();
    this.getTopicFilters();
  }

  async getTopicFilters() {
    const availableFiltersInfo = await this._channelService.getTopicFilters(TOPIC_QUERY).pipe(take(1)).toPromise() as any;
    let filtersToDisplay = this.refineFilters(availableFiltersInfo?.filters);
    filtersToDisplay = filtersToDisplay.map(f => {
      const operatorOptions = this.getOptions(f.operator_labels);
      const valueOptions = f.values?.length > 0 ? f.name === "is_private" ? this.typeOptions : this.getValuesOptions(f.values) : null;
      return {
        ...f,
        operatorOptions,
        selectedOperator: operatorOptions[0],
        selectedValue: f.type === "text" ? "" : !!valueOptions ? valueOptions[0] : noop(),
        valueOptions
      };
    });
    filtersToDisplay = [
      ...filtersToDisplay,
      {
        name: ViewFilter.SORT_BY,
        selectedValue: {label: "Last Updated (desc)", value: "updated_on:desc"}
      },
      {
        name: ViewFilter.GROUP_BY,
        selectedValue: {label: "None", value: "none"}
      }
    ];
    this._translate.get("TYPE").pipe(take(1)).subscribe((text: string) => {
      const typeFilter = filtersToDisplay.find(filter => filter.label === "Type");
      if (typeFilter) {
        typeFilter.label = text;
      }
    });
    this._translate.get("TAGS").pipe(take(1)).subscribe((text: string) => {
      const typeFilter = filtersToDisplay.find(filter => filter.label === "Tags");
      if (typeFilter) {
        typeFilter.label = text;
      }
    });
    this.defaultFilters.next(filtersToDisplay);
    this.dispatchNewFilters(filtersToDisplay);
    this.fetchingFilters$.next(false);
  }

  private refineFilters(filters: FilterModel[]): FilterModel[] {
    let refinedFilters = filters.filter(f => f.name !== "attachment" && f.name !== "q");
    const isPrivateFilter = refinedFilters.find(f => f.name === "is_private");
    if (!!isPrivateFilter) {
      const index = refinedFilters.indexOf(isPrivateFilter);
      refinedFilters = [...refinedFilters.slice(0, index), {...isPrivateFilter, label: "Type"}, ...refinedFilters.slice(index + 1)];
    }
    return refinedFilters;
  }

  private getOptions(operatorLabels): Option[] {
    const options = [];
    Object.keys(operatorLabels).forEach(op => {
      const option = {
        label: operatorLabels?.[op],
        value: op
      };
      options.push(option);
    });
    return options;
  }

  private getValuesOptions(values): Option[] {
    const options = [];
    values.forEach(value => {
      const option = {
        label: value[0],
        value: value[1]
      };
      options.push(option);
    });
    return options;
  }

  /* TAGS FILTERS */
  private async fetchAllTags() {
    const getMappedTags = (tags: any[]): Option[] =>
      tags.map(tag => ({
        label: tag.name,
        value: tag.id
      }));

    const tags = await this._conversationRepository.getTags().pipe(take(1)).toPromise() as any;
    this._allTags = tags;
    this.$tagOptions.next(getMappedTags(tags));

    this.tagSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(async () => {
      this.getFilteredTags(this.tagSearchControl.value);
    });
  }


  /* USERS FILTERS */
  fetchAllUsers() {
    let $contactsOb = this.channelService.getAuthorByChannel(this.channelId.getValue()).pipe(map((contacts: any) => {
      return contacts?.users.map(c => ({
        label: c?.name || c?.fullName,
        value: c?.id,
        imgSrc: c?.avatar_url,
        fallBackInfo: {
          bgColor: CommonUtil.getAvatarBackground(CommonUtil.getAvatarText(c.bare)),
          text: CommonUtil.getAvatarText(c.bare)
        }
      }));
    }));
    let currentContact = {
      label: this.chatRepository.getFullName(this.channelRepository?.userJID?.bare),
      value: this.channelRepository?.userJID?.bare,
      imgSrc: this.buildAvatarUrl(this.channelRepository?.userJID?.bare) || DEFAULT_USER_AVATAR,
      fallBackInfo: {
        bgColor: CommonUtil.getAvatarBackground(CommonUtil.getAvatarText(this.channelRepository?.userJID?.bare)),
        text: CommonUtil.getAvatarText(this.channelRepository?.userJID?.bare)
      }
    };

    $contactsOb.subscribe(res => {
      this.$userOptions.next([...res]);
    });


  }

  private search() {
    let $contactsOb = this.channelService.getAuthorByChannel(this.channelId.getValue()).pipe(map((contacts: any) => {
      return contacts.users.map(c => ({
        label: c?.name || c?.fullName,
        value: c?.id,
        imgSrc: c?.avatar_url,
        fallBackInfo: {
          bgColor: CommonUtil.getAvatarBackground(CommonUtil.getAvatarText(c?.bare)),
          text: CommonUtil.getAvatarText(c?.bare)
        }
      }));
    }));

    this.userSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(async () => {
      if (this.userSearchControl.touched) {
        if (this.userSearchControl.value) {
          let $searchOb = this.channelService.searchAuthorForAChannelTopic(this.channelId.getValue(), this.userSearchControl.value).pipe(map((contacts: any) => {
            return contacts.users.map(c => ({
              label: c?.name || c?.fullName,
              value: c?.id,
              imgSrc: c?.avatar_url,
              fallBackInfo: {
                bgColor: CommonUtil.getAvatarBackground(CommonUtil.getAvatarText(c.bare)),
                text: CommonUtil.getAvatarText(c.bare)
              }
            }));
          }));
          $searchOb.subscribe(res => {
            this.$userOptions.next([...res]);
          });
        }
        else {
          $contactsOb.subscribe(res => {
            this.$userOptions.next([...res]);
          });
        }
      }
    });
  }

  private buildAvatarUrl(jid) {
    const avatarVersion = "";
    let avatarName = this._avatarRepository.buildTargetHash(jid);
    const avUrl = this._configService.avatarServiceUrl + "/" + avatarName + ".jpg" + avatarVersion;
    return CommonUtil.translateHINFileURL(avUrl);
  }

  private getFilteredRecipients(searchValue: string): Contact[] {
    return this._allContacts.filter((contact: Contact) =>
      (contact.name || contact.fullName)?.toLowerCase()?.includes(searchValue?.toLowerCase()) &&
      (contact.bare)?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }

  public getFilteredTags(searchValue: string) {
    const getMappedTags = (tags: any[]): Option[] =>
      tags.map(tag => ({
        label: tag.name,
        value: tag.id
      }));

    if (!!searchValue) {
      let params: any = [];
      params["name"] = "~" + searchValue;
      this._conversationRepository.getTags(params).pipe(take(1)).subscribe(tags => {
        this.$tagOptions.next(getMappedTags(tags));
      });
    } else {
      this.$tagOptions.next(getMappedTags(this._allTags));
    }
  }
  private getQueryString(queries) {
    return queries.join("&").split("==").join("=");
  }

  private getDateFilter(date) {
    return format(date, "yyyy-MM-dd");
  }

  async filtersAreValid(): Promise<boolean> {
    let invalids = 0;
    let selectedFilters = await this.getSelectedFilters();
    const datePastFilters = selectedFilters.filter(f => f.type === "date_past" && !GLOBALLY_INDEPENDENT_FILTERS.includes(f?.selectedOperator?.value));
    if (datePastFilters.length > 0) {
      const dependentDatePastFilters = datePastFilters.filter(f => !INDEPENDENT_DATE_FILTERS.includes(f?.selectedOperator?.value));
      dependentDatePastFilters.filter((f,i)=>{
        if (!dependentDatePastFilters[i]?.selectedValue) {
          if (DATE_FILTER?.includes(f?.selectedOperator?.value)) {
            dependentDatePastFilters[i].selectedValue = { value: { "startDate": new Date() } };
          } else if (RANGE_FILTER.includes(f?.selectedOperator?.value)) {
            dependentDatePastFilters[i].selectedValue = { startDate: { value: { "startDate": new Date() } }, endDate: { value: { "startDate": new Date() } } };
          }
        }
      });
      dependentDatePastFilters.forEach(f => {
        if (RANGE_FILTER.includes(f?.selectedOperator?.value?.startDate)) {
          if (f?.selectedValue?.length < 2) {
            invalids += 1;
          }
        } else {
          if (!f?.selectedValue) {
            invalids += 1;
          }
        }
      });
    }
    const independentStatusFilters = selectedFilters.filter(f => f?.name === "status_id" && INDEPENDENT_STATUS_FILTERS.includes(f?.selectedOperator?.value));
    const filtersWithIndependentOperators = selectedFilters.filter(f => GLOBALLY_INDEPENDENT_FILTERS.includes(f?.selectedOperator?.value));
    const remainingValidFilters = selectedFilters.filter(f => f.type !== "date_past" && f.type !== "text" && !!f?.selectedValue);
    const containValidFilters = selectedFilters.filter(f => f.type === "text" && !!f?.selectedValue);
    if (selectedFilters?.length !== datePastFilters?.length + independentStatusFilters?.length + filtersWithIndependentOperators?.length + remainingValidFilters?.length + containValidFilters?.length) {
      invalids += 1;
    }
    return invalids === 0;
  }

  async getSelectedFilters(): Promise<any> {
    const selectedFilterNames = this.selectedFilters?.value;
    const allFilters = await this._channelRootStore.select(getAllAvailableTopicFiltersInfo).pipe(take(1), map(res => res?.filters)).toPromise();
    const selectedFilters = allFilters?.filter(f => selectedFilterNames?.includes(f.name) || f.name === ViewFilter.SORT_BY || f.name === ViewFilter.GROUP_BY);
    return selectedFilters?.length > 0 ? selectedFilters : [];
  }

  setFiltersChanged(state) {
    this.filtersChanged$.next(state);
  }

  // CAN BE USED IN FUTURE
  private getDefaultOperator(operatorLabels, filterType): Option {
    let operator = {label: "", value: ""};
    if (filterType === "text") {
      operator = {
        label: "contains",
        value: Object.keys(operatorLabels).find(op => operatorLabels?.[op] === "contains")
      };
    }
    if (filterType.includes("list") || filterType.includes("date_past")) {
      operator = {
        label: "is",
        value: Object.keys(operatorLabels).find(op => operatorLabels?.[op] === "is")
      };
    }
    return operator;
  }

  updateSelectedFilters(selected: boolean, filter: FilterModel) {
    let selectedFilters = this.selectedFilters?.value;
    if (selected) {
      selectedFilters.push(filter.name);
    } else {
      selectedFilters = selectedFilters.filter(f => f !== filter.name);
    }
    this.selectedFilters.next(selectedFilters);
  }

  async onFilterChange(event, changedProperty: "operator" | "filter", filter: FilterModel, dateType?: string, isMobile:boolean = false) {
    if (changedProperty === "operator") {
      const oldFilters = await this.getOldFilters();
      const newFilters = oldFilters.map(f => {
        if (f.name === filter.name) {
          return {
            ...f,
            selectedOperator: {
              label: event?.label,
              value: event?.value
            },
            selectedValue: GLOBALLY_INDEPENDENT_FILTERS.includes(event?.value) ? "" : f?.selectedValue
          };
        }
        return f;
      });
      this.dispatchNewFilters(newFilters);
    } else {
      const oldFilters = await this.getOldFilters();
      let newFilters = [];
      if (filter.type === "text") {
        newFilters = oldFilters.map(f => {
          if (f.name === filter.name) {
            return {
              ...f,
              selectedValue: event?.target?.value
            };
          }
          return f;
        });
      }

      if (filter?.type?.includes("list") && filter?.valueOptions?.length > 0) {
        newFilters = oldFilters.map(f => {
          if (f.name === filter.name) {
            return {
              ...f,
              selectedValue: {
                label: event?.label,
                value: event?.value,
                fallBackInfo: !!event?.fallBackInfo?.text ?  event?.fallBackInfo : null
              }
            };
          }
          return f;
        });
      }

      if (filter.name === "author_id") {
        const users = this.$userOptions.value;
        const selectedUsers = users.filter(user => event.includes(user.value));
        newFilters = oldFilters.map(f => {
          if (f.name === filter.name) {
            return {
              ...f,
              selectedValue: [...selectedUsers]
            };
          }
          return f;
        });
      }

      if (filter.name === "tags") {
        const tags = this.$tagOptions.value;
        const selectedTags = tags.filter(tag => event.includes(tag.value));
        newFilters = oldFilters.map(f => {
          if (f.name === filter.name) {
            return {
              ...f,
              selectedValue: isMobile ? [...event] : [...selectedTags]
            };
          }
          return f;
        });
      }

      if (filter.type === "date_past") {
        if (DAYS_DATE_FILTER.includes(filter.selectedOperator.value)) {
          newFilters = oldFilters.map(f => {
            if (f.name === filter.name) {
              return {
                ...f,
                selectedValue: event?.target?.value
              };
            }
            return f;
          });
        }

        if (DATE_FILTER.includes(filter?.selectedOperator?.value)) {
          newFilters = oldFilters.map(f => {
            if (f.name === filter.name) {
              return {
                ...f,
                selectedValue: {
                  label: event?.targetElement?.value ? event?.targetElement?.value : event?.$d,
                  value: event?.target?.value ? event?.target?.value : {"startDate": event?.$d}
                }
              };
            }
            return f;
          });
        }

        if (RANGE_FILTER.includes(filter?.selectedOperator?.value)) {
          newFilters = oldFilters.map(f => {
            if (f.name === filter.name) {
              return {
                ...f,
                selectedValue: {
                  ...f.selectedValue,
                  [dateType]: {
                    label: event?.targetElement?.value ? event?.targetElement?.value : event?.$d,
                    value: event?.target?.value ? event?.target?.value : {"startDate": event?.$d}
                  }
                }
              };
            }
            return f;
          });
        }
      }
      this.dispatchNewFilters(newFilters);
    }
  }

  getSelectedValues(filter: FilterModel, isObject?: boolean) {
    const valuesArray = [];
    const { selectedValue } = filter;
    if (selectedValue?.length > 0) {
      selectedValue?.forEach(val => {
        isObject ? valuesArray.push(val) : valuesArray.push(val?.value);
      });
    }
    return valuesArray;
  }

  async resetFilters() {
    this.dispatchNewFilters(this.defaultFilters.value);
    this.selectedFilters.next([]);
    this._broadcaster.broadcast(RESET_FILTERS, {channelId: this.channelId.value});
    this.updatedGlobalFilters(this.globalFilter.ALL_TOPICS);
    this.setFiltersChanged(false);
  }

  async fetchTopics() {
    const queries = [];
    if (await this.filtersAreValid()) {
      let selectedFilters = await this.getSelectedFilters();
      let groupByFilter = null;
      let sortFilter = null;
      selectedFilters.forEach(f => {
        if (f.type === "date_past") {
          const selectedOperator = f?.selectedOperator?.value;
          if (DAYS_DATE_FILTER.includes(selectedOperator)) {
            const q = `${f?.name}=${selectedOperator}${f?.selectedValue}`;
            queries.push(q);
          }
          if (DATE_FILTER.includes(selectedOperator)) {
            const q = `${f?.name}=${selectedOperator}${this.getDateFilter(f?.selectedValue?.value?.startDate)}`;
            queries.push(q);
          }
          if (RANGE_FILTER.includes(selectedOperator)) {
            const q = `${f?.name}=${selectedOperator}${this.getDateFilter(f?.selectedValue?.startDate?.value)}|${this.getDateFilter(f?.selectedValue?.endDate?.value)}`;
            queries.push(q);
          }
          if (INDEPENDENT_DATE_FILTERS.includes(selectedOperator) || GLOBALLY_INDEPENDENT_FILTERS.includes(selectedOperator)) {
            const q = `${f?.name}=${selectedOperator}`;
            queries.push(q);
          }
        }
        if (f?.type === "text") {
          const q = `${f?.name}=${f?.selectedOperator?.value}${f?.selectedValue}`;
          queries.push(q);
        }
        if (f?.name === "author_id" || f?.name === "tags") {
          const selectedIds = !GLOBALLY_INDEPENDENT_FILTERS.includes(f?.selectedOperator?.value) ? f?.selectedValue?.map(f => f.value) : [];
          const q = `${f?.name}=${f?.selectedOperator?.value}${selectedIds.join("|")}`;
          queries.push(q);
        }
        if (f?.type?.includes("list") && f?.valueOptions?.length > 0) {
          let q;
          if (f?.name === "status_id" && (INDEPENDENT_STATUS_FILTERS.includes(f?.selectedOperator?.value) || GLOBALLY_INDEPENDENT_FILTERS.includes(f?.selectedOperator?.value))) {
            q = `${f?.name}=${f?.selectedOperator?.value}`;
          } else {
            q = `${f?.name}=${f?.selectedOperator?.value}${f?.selectedValue?.value}`;
          }
          queries.push(q);
        }
        if (f?.name === ViewFilter.GROUP_BY && f?.selectedValue?.value === "author") {
          groupByFilter = "author:asc";
        }

        if (f?.name === ViewFilter.SORT_BY) {
          sortFilter = f?.selectedValue?.value;
        }
      });

      if (!this.selectedGlobalFilters.value.includes(TopicGlobalFilter.ALL_TOPICS)) {
        const selectedGlobalFilters = this.selectedGlobalFilters.value;
        const topicTypes: string[] = [TopicGlobalFilter.VIDEO, TopicGlobalFilter.REGULAR, TopicGlobalFilter.ANALYTICS];
        const topicTypeFilters = [...selectedGlobalFilters].filter(v => topicTypes.includes(v));
        if (topicTypeFilters.length > 0) {
          queries.push(`topic_type=${topicTypeFilters.join("|")}`);
        }
        if (selectedGlobalFilters.includes(TopicGlobalFilter.UNREAD)) {
          queries.push(`${TopicGlobalFilter.UNREAD}=0`);
        }
        this.setFiltersChanged(true);
      }
      if (!!groupByFilter && !!sortFilter) {
        queries.push(`${ViewFilter.SORT_BY}=${groupByFilter},${sortFilter}`);
      } else if (!!sortFilter) {
        queries.push(`${ViewFilter.SORT_BY}=${sortFilter}`);
      } else if (!!groupByFilter) {
        queries.push(`${ViewFilter.SORT_BY}=${groupByFilter}`);
      }
      this._broadcaster.broadcast(ConstantsUtil.APPLY_FILTERS);
      let queryStr = this.getQueryString(queries);
      // console.log("topic-filter pre-broadcast: ", this.queryString, queryStr);
      if ((queryStr !== this.queryString) || (this.channelId.value !== this.oldChannelId)) {
        this.queryString = queryStr;
        this.oldChannelId = this.channelId.value;
        this._broadcaster.broadcast(FETCH_FILTERED_TOPICS, {query: this.getQueryString(queries), channelId: this.channelId.value});
      }
    } else {
      this._translate.get("INVALID_FILTERS").pipe(take(1)).subscribe(text => {
        this._channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
    }
  }

  async removeChip(item, filter: FilterModel) {
    const oldFilters = await this.getOldFilters();
    const newFilters = oldFilters.map(f => {
      if (f.name === filter.name) {
        const selectedValue = filter?.selectedValue?.filter(v => v.value !== item.value);
        return {
          ...f,
          selectedValue: selectedValue.length > 0 ? selectedValue : null
        };
      }
      return f;
    });
    this.dispatchNewFilters(newFilters);
  }

  async onViewFilterChange(selection, type) {
    const oldFilters = await this.getOldFilters();
    const newFilters = oldFilters.map(f => {
      if (f?.name === type) {
        return {
          ...f,
          selectedValue: selection
        };
      }
      return f;
    });
    this.dispatchNewFilters(newFilters);
  }

  underDevelopment() {
    this._translate.get("UNDER_DEVELOPMENT").pipe(take(1)).subscribe(text => {
      this._channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
    });
  }

  updatedGlobalFilters(filterName: TopicGlobalFilter, filterEnableCheck: boolean = true) {
    let selectedGlobalFilters = this.selectedGlobalFilters.value;
    if (!!filterName && selectedGlobalFilters[0] !== filterName) {
      this.selectedGlobalFilters.next([filterName]);
    } else if (selectedGlobalFilters[0] === filterName) {
      return;
    } else {
      this.selectedGlobalFilters.next([TopicGlobalFilter.ALL_TOPICS]);
    }
    filterEnableCheck && this.fetchTopics();
  }
}

export interface FilterModel {
  name?: string;
  label?: string;
  type?: string;
  values?: any;
  operators?: any;
  operator_labels?: any;
  operatorOptions?: Option[];
  selectedOperator?: Option;
  selectedValue?: any;
  valueOptions?: Option[];
  sort?: Option;
  groupBy?: Option;
}

export interface Option {
  label: string;
  value: string;
  imgSrc?: string;
}

export enum ViewFilter {
  SORT_BY = "sort",
  GROUP_BY = "groupBy"
}

export const TOPIC_QUERY = "TopicQuery";

export const INDEPENDENT_STATUS_FILTERS = ["o", "c"];

export const GLOBALLY_INDEPENDENT_FILTERS = ["!*", "*"];

export const INDEPENDENT_DATE_FILTERS = ["t", "ld", "w", "lw", "l2w", "m", "lm", "y"];
export const DAYS_DATE_FILTER = ["><t-", "t-", ">t-", "<t-"];
export const DATE_FILTER = ["=", ">=", "<="];
export const RANGE_FILTER = ["><"];
export const FETCH_FILTERED_TOPICS = "[FETCH_FILTERED_TOPICS]";
export const RESET_FILTERS = "[RESET_FILTERS]";
export enum TopicGlobalFilter {
  ALL_TOPICS = "ALL_TOPICS",
  UNREAD = "read",
  REGULAR = "regular",
  VIDEO = "video",
  TICKET = "TICKET",
  ANALYTICS = "analytics",
}
