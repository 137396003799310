
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "../../../talk/shared/providers/broadcaster.service";
import { Subject, take, takeUntil } from "rxjs";
import { ProfileResponse } from "../../../responses/profile.response";
import { getUserProfile, RootState } from "../../../reducers";
import { Store } from "@ngrx/store";
import { CommonUtil } from "app/talk/utils/common.util";
import { ConferenceRepository } from "app/talk/repositories/conference.repository";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
  selector: "vp-help-dialog",
  templateUrl: "./help-dialog.html",
  styles: [
    `
    ::ng-deep .help-dialog-component .vnc-dialog_footer {
      display: none!important;
    }
    `
  ]
})
export class HelpDialogComponent implements OnDestroy, OnInit {
  manualLink;
  FAQLink;
  helpLink;
  private isAlive$ = new Subject<boolean>();
  content = "";
  profile: ProfileResponse;
  isMobileScreen: boolean;

  constructor(
    private broadcaster: Broadcaster,
    private dialog: MatDialogRef<HelpDialogComponent>,
    private conferenceRepo: ConferenceRepository,
    private breakpointObserver: BreakpointObserver,
    private store: Store<RootState>
  ) {
    this.broadcaster.on<any>("hideHelpDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.dialog.close();
      });
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
      });
  }

  ngOnInit() {
    this.store.select(getUserProfile).pipe(take(1)).subscribe(p => {
      const defaultLang = CommonUtil.getDefaultLang();

      if (defaultLang === "de") {
        this.manualLink = p.manualLinkDe;
        this.FAQLink = p.faqLinkDe;
        this.helpLink = p.helpLinkDe;
      }
      else {
        this.manualLink = p.manualLinkEn;
        this.FAQLink = p.faqLinkEn.replace("https://de.docs.vnc.biz", "https://en.docs.vnc.biz");
        this.helpLink = p.helpLinkEn;
      }

    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.conferenceRepo.toggleHideVideoIOS(false);
    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
  }

}
