<div id="vnc-commander-app" *ngIf="!isOnIpad" style="background-color: transparent;" [ngClass]="{'hin-theme': isHinTheme}">
    <div id="mainLayout">
        <div id="layoutContainer">
        </div>
        <div id="layoutHeader" class="header__blue" [class.is-hin-theme]="isHinTheme">
            <vnc-mobile-header [bgColor]="themeColor" [color]="color" [useAvatar]="true" [isShowSearchIcon]="false">
                <span vnc-mobile-header-title *ngIf="currentTheme === 'vnctalk'">
                    <ng-container>
                        <svg xmlns="http://www.w3.org/2000/svg" width="105" height="32" viewBox="0 0 105 32">
                            <g fill="none" fill-rule="evenodd">
                                <g [attr.fill]="logoColor">
                                    <path
                                        d="M60.865 9.386v2.442h3.399v1.275h-3.399v6.669l.007.216c.032.56.197.994.493 1.302.332.347.81.52 1.433.52.312 0 .613-.049.903-.148.232-.08.44-.186.622-.32l.132-.105.53 1.083-.146.123c-.255.195-.557.35-.905.461-.417.135-.853.202-1.306.202-1.048 0-1.855-.283-2.421-.85-.52-.519-.8-1.234-.844-2.146l-.006-.253v-6.754h-1.996v-1.275h1.996V9.386h1.508zm10.768 2.358c1.459 0 2.577.364 3.356 1.093.727.68 1.115 1.667 1.163 2.958l.005.281V23h-1.444v-1.742l-.12.19c-.333.49-.792.88-1.377 1.17-.659.325-1.441.488-2.347.488-1.246 0-2.237-.297-2.974-.892-.736-.595-1.104-1.38-1.104-2.357 0-.949.343-1.714 1.03-2.294.641-.542 1.637-.83 2.987-.867l.294-.004h3.547v-.68l-.005-.235c-.041-.848-.309-1.502-.802-1.963-.538-.502-1.324-.754-2.357-.754-.708 0-1.388.117-2.04.35-.557.201-1.048.466-1.471.797l-.206.17-.68-1.126.218-.175c.52-.397 1.127-.71 1.821-.94.793-.262 1.629-.392 2.506-.392zM81.764 7.24V23h-1.508V7.24h1.508zm5.713 0v10.683l6.67-6.096h1.868l-4.778 4.567L96.46 23h-1.847l-4.503-5.607-2.634 2.421V23H85.97V7.24h1.507zm-38.905.546c2.055 0 4.206.873 5.676 2.266l.206.202-1.96 2.383c-1.024-1.17-2.494-1.936-3.88-1.936-2.556 0-4.538 1.979-4.538 4.575 0 2.595 1.982 4.595 4.539 4.595 1.258 0 2.63-.598 3.674-1.55l.204-.194 1.982 2.149c-1.577 1.617-3.857 2.66-6.03 2.66-4.454 0-7.821-3.299-7.821-7.618 0-4.298 3.43-7.532 7.948-7.532zm-36.95.107l4.07 11.234 4.092-11.234h3.473l-5.924 14.893H13.86L8 7.894h3.623zm16.345 0l7.245 9.574V7.894h3.196v14.893h-3.069l-7.223-9.553v9.553h-3.218V7.894h3.069zm46.682 9.924h-3.504l-.225.003c-1.761.051-2.642.715-2.642 1.993 0 .652.248 1.165.743 1.54.496.375 1.19.563 2.081.563.85 0 1.58-.195 2.188-.584.541-.346.96-.83 1.254-1.45l.105-.239v-1.826z" />
                                </g>
                            </g>
                        </svg>
                    </ng-container>
                </span>
                <span vnc-mobile-header-title *ngIf="currentTheme === 'hin'">
                    <ng-container>
                        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="105" height="32"
                            viewBox="0 0 170.08 71.35">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #f39900;
                                    }

                                    .cls-2 {
                                        fill: #666 !important;
                                    }
                                </style>
                            </defs>
                            <title>hin-logo-talk-rgb</title>
                            <path class="cls-1"
                                d="M119.52,78.57a5.65,5.65,0,0,0,2.48-.49,11.58,11.58,0,0,0,2.64-1.59A16.54,16.54,0,0,0,129.06,71a11.82,11.82,0,0,0,1.07-3.06,6.36,6.36,0,0,0,0-2.5,3.11,3.11,0,0,0-1-1.71,2.93,2.93,0,0,0-1.87-.67,5.81,5.81,0,0,0-2.45.53,11,11,0,0,0-2.57,1.57,15.86,15.86,0,0,0-2.4,2.38,16.42,16.42,0,0,0-1.94,3,12,12,0,0,0-1.1,3,6.64,6.64,0,0,0-.08,2.53,3.26,3.26,0,0,0,.9,1.78,2.85,2.85,0,0,0,1.85.74"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-1"
                                d="M129.07,60.56a5.75,5.75,0,0,0,2.37-.52,10.94,10.94,0,0,0,2.5-1.52,15.21,15.21,0,0,0,2.31-2.3,15,15,0,0,0,1.83-2.82,10.29,10.29,0,0,0,1-2.82,5.59,5.59,0,0,0,0-2.28,2.78,2.78,0,0,0-.95-1.53,2.86,2.86,0,0,0-1.79-.57,6,6,0,0,0-2.33.55,11.35,11.35,0,0,0-2.43,1.5,15.42,15.42,0,0,0-2.26,2.24,14.9,14.9,0,0,0-1.82,2.75,11.18,11.18,0,0,0-1,2.78,5.84,5.84,0,0,0,0,2.31,2.91,2.91,0,0,0,.89,1.6,2.85,2.85,0,0,0,1.77.63"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-1"
                                d="M141.77,60.75a6.19,6.19,0,0,0,2.46-.52,11.06,11.06,0,0,0,2.56-1.56,15,15,0,0,0,2.36-2.35A14.51,14.51,0,0,0,151,53.44a10.7,10.7,0,0,0,.94-2.88,5.45,5.45,0,0,0-.08-2.34,2.94,2.94,0,0,0-1-1.57,3.12,3.12,0,0,0-1.89-.57,6.19,6.19,0,0,0-2.42.56,11.29,11.29,0,0,0-2.5,1.53,15,15,0,0,0-2.3,2.28,15.25,15.25,0,0,0-1.82,2.82,10.68,10.68,0,0,0-1,2.84,5.64,5.64,0,0,0,0,2.36,3,3,0,0,0,1,1.64,3,3,0,0,0,1.87.64"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-1"
                                d="M150,43.05a6,6,0,0,0,2.34-.54A10.85,10.85,0,0,0,154.78,41a13.57,13.57,0,0,0,2.22-2.2,13.24,13.24,0,0,0,1.71-2.68,9.15,9.15,0,0,0,.86-2.65,4.79,4.79,0,0,0-.11-2.13,2.67,2.67,0,0,0-1-1.4,3,3,0,0,0-1.81-.47,6,6,0,0,0-2.31.58A10.87,10.87,0,0,0,152,31.53a14.61,14.61,0,0,0-2.17,2.14,13.87,13.87,0,0,0-1.69,2.61,9.34,9.34,0,0,0-.89,2.62,4.94,4.94,0,0,0,.05,2.15,2.63,2.63,0,0,0,.95,1.46,2.89,2.89,0,0,0,1.79.54"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-1"
                                d="M169.53,20.06a6.21,6.21,0,0,0,2.32-.57A11.22,11.22,0,0,0,174.21,18a14.09,14.09,0,0,0,2.12-2.11,12.43,12.43,0,0,0,1.61-2.55,8.4,8.4,0,0,0,.75-2.49,4.3,4.3,0,0,0-.2-2,2.48,2.48,0,0,0-1.06-1.28,3.13,3.13,0,0,0-1.82-.39,6.45,6.45,0,0,0-2.27.6A10.75,10.75,0,0,0,171,9.26,14.39,14.39,0,0,0,169,11.32a12.74,12.74,0,0,0-1.59,2.48,8.37,8.37,0,0,0-.78,2.46,4.38,4.38,0,0,0,.13,2,2.52,2.52,0,0,0,1,1.33,3.07,3.07,0,0,0,1.8.46"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M37.36,8.3V26.08H24.12V8.3H8.64V9.36L10,9.5c4.62.49,4.62.49,4.62,4.36V51.28h9.53V31.86H37.36V51.28h9.52V8.3H37.36"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M55.29,8.3V9.36l1.29.17c4.66.63,4.66.63,4.66,4.35v37.4h9.52V8.3H55.29"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M115.4,8.31h-5.21v28.5L93.67,8.31H78.8V9.37L81,9.79c3.77.73,3.77.73,3.77,4.65V51.28h6.7v-30l17.41,30h8v-43H115.4"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M15.3,66h-1a.85.85,0,0,1-.92-1v-.37a.86.86,0,0,1,1-1h1V60.48a.86.86,0,0,1,1-1h.84a.85.85,0,0,1,.95,1V63.7h2.56a.86.86,0,0,1,.95,1V65a.85.85,0,0,1-.92,1H18.11v6.53c0,2.78,1.72,3.22,2.79,3.31.75,0,1,.29,1,1v.61c0,.67-.37,1-1.16,1-2.9,0-5.45-1.68-5.45-5.4Z"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M32.09,69.18h.76V68.8c0-2.4-1.4-3.22-3.19-3.22a6.92,6.92,0,0,0-3.31.93A.9.9,0,0,1,25,66.17l-.23-.41a.87.87,0,0,1,.32-1.31,9.67,9.67,0,0,1,4.76-1.24c3.65,0,5.77,2,5.77,5.74v8.33a.86.86,0,0,1-1,1H34a.86.86,0,0,1-1-1v-.87a3.87,3.87,0,0,1,.09-.9h-.06a5.44,5.44,0,0,1-4.79,3.07c-2.58,0-4.93-1.59-4.93-4.35C23.33,69.68,29.05,69.18,32.09,69.18ZM28.9,76.35c2.47,0,4-2.5,4-4.67V71h-.76c-2.06,0-5.94.17-5.94,3A2.46,2.46,0,0,0,28.9,76.35Z"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M39.51,58.71a.85.85,0,0,1,1-1h.9a.86.86,0,0,1,1,1V73.88c0,1.66.72,1.89,1.33,2a.77.77,0,0,1,.76.84v.69a.87.87,0,0,1-1,1c-1.57,0-3.92-.44-3.92-4.12Z"
                                transform="translate(-8.64 -7.23)" />
                            <path class="cls-2"
                                d="M47.14,58.71a.85.85,0,0,1,1-1H49a.84.84,0,0,1,.93,1V68.92H52l3.57-4.76a1.19,1.19,0,0,1,1.13-.61h1.08c.78,0,1,.53.58,1.16L54.34,70v.06l4.69,7c.41.67.15,1.16-.63,1.16H57.24a1.19,1.19,0,0,1-1.14-.66l-4-6.21H49.93v5.92a.84.84,0,0,1-.93,1h-.9a.85.85,0,0,1-1-1Z"
                                transform="translate(-8.64 -7.23)" />
                        </svg>
                    </ng-container>
                </span>
                <span vnc-mobile-header-title *ngIf="currentTheme === 'ekbo'">
                    <ng-container>
                        <span class="product-brand" [innerHTML]="headerBranding"> </span>
                    </ng-container>
                </span>
                <vnc-icon-container vnc-mobile-header-right-icon id="globalMuteBtnMobile"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19"
                        viewBox="0 0 18 19">
                        <g fill="none" fill-rule="evenodd">
                            <g fill="#FFF">
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M9.596 1.01C10.768.172 12 .808 12 2.245v9.008c0 1.436-1.235 2.071-2.404 1.237L5.758 9.749l-3.504.001c-1.206 0-2.182-.933-2.25-2.116L0 7.502V5.998C0 4.758 1.01 3.75 2.254 3.75h3.504zm.903 1.197L6.24 5.25H2.254c-.416 0-.754.337-.754.748v1.504c0 .416.332.748.754.748H6.24l4.26 3.042V2.207zm2.251 1.52c1.11.555 1.875 1.696 1.875 3.023 0 1.328-.765 2.467-1.875 3.015z"
                                                    transform="translate(-209 -36) translate(0 .202) translate(198 25) translate(11 11) translate(1.5 2.25)" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </vnc-icon-container>
                <div class="avatar-placeholder" vnc-mobile-header-right-avatar>

                </div>
            </vnc-mobile-header>
            <mat-progress-bar id="xmppLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div id="layoutContent">
            <div class="vnc-grid no-padding">
                <div class="recent-mobile-chat-tabs">
                    <vnc-mobile-tab
                      [bgColor]="themeColor"
                      talkLable="Talk"
                      [isHinTheme]="currentTheme === 'hin' ? true : false"
                    ></vnc-mobile-tab>
                  </div>
                  <div class="search-chips">
                    <div class="search-filter" >
                      <vnc-chips text="all chats" [active]="true" type="static" size="s"></vnc-chips>
                    </div>
                    <div class="search-filter" >
                      <vnc-chips text="unread" type="static" size="s"></vnc-chips>
                    </div>
                    <div class="search-filter" >
                      <vnc-chips text="favorites" type="static" size="s"></vnc-chips>
                    </div>
                    <div class="search-filter">
                      <vnc-chips  text="mentions" type="static" size="s"></vnc-chips>
                    </div>
                  </div>
                  <div class="conversationsList">
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                    <vnc-gs-dropdown-item
                    [type]="'chat-skeleton'"
                    [size]="'xl'">
                    </vnc-gs-dropdown-item>
                  </div>
            </div>
        </div>
    </div>
</div>


