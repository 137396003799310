import {Action} from "./index";
import { File } from "../../models/file.model";

export class FilesActionTypes {
  static FILES_ADD = "[Files] Add";
  static FILES_EDIT = "[Files] Edit";
}

export class FilesAdd implements Action {
  readonly type = FilesActionTypes.FILES_ADD;
  constructor(public payload: File[]) {
  }
}

export class FilesEdit implements Action {
  readonly type = FilesActionTypes.FILES_EDIT;
  constructor(public payload: File[]) {
  }
}
