
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action, createReducer, on } from "@ngrx/store";
import { ResponseCreatedPad } from "../interfaces/response-created-pad.interface";

import * as padActions from "./pad.actions";
import { Pad } from "../interfaces/pad.interface";

export interface PadState {
  pads: Pad[];
  isShowPadWindow: boolean;
  isLoading: boolean;
  responseCreatedPad: ResponseCreatedPad;
  currentTarget: string;
  plainTarget: string;
  currentPadName: string;
  currentPadId: string;
}

const initialState: PadState = {
  pads: [],
  isShowPadWindow: false,
  isLoading: false,
  responseCreatedPad: new ResponseCreatedPad(),
  currentTarget: "",
  plainTarget: "",
  currentPadName: "",
  currentPadId: ""
};

const padReducer = createReducer(
  initialState,
  on(padActions.loadPadsSuccess, (state: PadState, { pads }: any) => {
    return {
      ...state,
      pads: [...pads],
      isLoading: false
    };
  }),
  on(padActions.deletePad, (state: PadState, {padID }: any) => {
    if (padID === state.currentPadId) {
      const newPad = state.pads.find(p => p.padid !== padID);
      return {
        ...state,
        pads: state.pads.filter(p => p.padid !== padID),
        currentPadId: newPad ? newPad.padid : null,
        currentPadName: null,
        responseCreatedPad: new ResponseCreatedPad()
      };
    } else {
      return {
        ...state,
        pads: state.pads.filter(p => p.padid !== padID),
        isLoading: false
      };
    }
  }),
  on(padActions.renamePad, (state: PadState, {padid, padname }: any) => {
    const index = state.pads.findIndex(v => v.padid === padid);
    if (index !== -1) {
      state.pads[index].padname = padname;
    }
    return {
      ...state,
      pads: state.pads,
      isLoading: false
    };
  }),
  on(padActions.showPadWindow, (state: PadState) => {
    return {
      ...state,
      isShowPadWindow: true,
    };
  }),
  on(padActions.isLoadingPads, (state: PadState) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(padActions.resetPads, (state: PadState) => {
    return {
      ...state,
      pads: [],
      isLoading: false,
      currentPadId: "",
      currentPadName: ""
    };
  }),
  on(padActions.hidePadWindow, (state: PadState) => {
    return {
      ...state,
      isShowPadWindow: false,
      responseCreatedPad: new ResponseCreatedPad()
    };
  }),
  on(padActions.loadPadUrlSuccess, (state, { responseCreatedPad }: any) => {
    return {
      ...state,
      responseCreatedPad: { ...responseCreatedPad },
    };
  }),
  on(padActions.loadPadUrl, (state, data: any) => {
    return {
      ...state,
      currentPadId: data.padid,
      currentPadName: data.padname,
    };
  }),
  on(padActions.saveCurrentTarget, (state, { target, plainTarget }: any) => {
    return {
      ...state,
      currentTarget: target,
      plainTarget: plainTarget,
    };
  }),
  on(padActions.createNewPad, (state, { target, name, plainTarget }: any) => {
    return {
      ...state,
      currentTarget: target,
      plainTarget: plainTarget,
      currentPadName: name,
    };
  }),
  on(padActions.selectPad, (state, { padID }: any) => {
    return {
      ...state,
      currentPadId: padID
    };
  }),
);

export function reducer(state: PadState | undefined, action: Action) {
  return padReducer(state, action);
}
