export enum SnackbarType {
    CLOSE = "close",
    CHECKMARK = "checkmark"
}

export enum SnackbarPositionType {
    LEFT = "left",
    RIGHT = "right",
    BOTTOM = "bottom",
    TOP = "top"
}