
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { Whiteboard } from "../models/whiteboard.model";

export class WhiteboardActionTypes {
  static WHITEBOARD_ADD = "[WHITEBOARD] Add";
  static MULTI_CONVERSATION_WHITEBOARD_ADD = "[WHITEBOARD] Multi Conversation Add";
  static WHITEBOARD_STATUS_UPDATE = "[WHITEBOARD] Status Update";
  static WHITEBOARD_UPDATE = "[WHITEBOARD] Update";
  static WHITEBOARD_STATUS_UPDATE_DELIVERED = "[WHITEBOARD] Delivered Status Update";
  static WHITEBOARD_DELETED_STATUS_UPDATE = "[WHITEBOARD] Deleted Status Update";
  static MULTI_CONVERSATION_WHITEBOARD_DELETED_STATUS_UPDATE = "[WHITEBOARD] Multi Conversation Deleted Status Update";
  static WHITEBOARD_BULK_APPEND = "[WHITEBOARD] Bulk Append";
  static WHITEBOARD_BULK_APPEND_MULTI_CONVERSATION = "[WHITEBOARD] Bulk Append MultiConversation";
  static WHITEBOARD_BULK_LOADING = "[WHITEBOARD] Bulk Loading";
  static WHITEBOARD_BULK_LOADED = "[WHITEBOARD] Bulk Loaded";
  static WHITEBOARD_BULK_LOADING_FAILED = "[WHITEBOARD] Bulk Loading Failed";
  static WHITEBOARD_DELETE_ACTION = "[WHITEBOARD] Delete";
  static WHITEBOARD_LAST_PAGE_LOADED = "[WHITEBOARD] Last Page Loaded";
  static SELECT_WHITEBOARD = "[WHITEBOARD] Select WHITEBOARD";
  static UNSELECT_WHITEBOARD = "[WHITEBOARD] Unselect WHITEBOARD";
  static RESET_WHITEBOARDS = "[WHITEBOARD] Reset WHITEBOARDs";
  static WHITEBOARD_FAVORITE_UPDATE = "[WHITEBOARD] update favorite";
  static WHITEBOARD_BULK_DELETE = "[WHITEBOARD] delete bulk WHITEBOARDs";
  static WHITEBOARDS_BULK_UPDATE = "[WHITEBOARD] bulk update WHITEBOARDs";
  static RESET_UNREAD_COUNT = "[WHITEBOARD] Reset unread count";
  static SET_SELECTED_WHITEBOARD_ID = "[WHITEBOARD] Set selected whiteboard id (uid)";

}

export class WhiteboardAdd implements Action {
  readonly type = WhiteboardActionTypes.WHITEBOARD_ADD;

  constructor(public payload: { conversationTarget: string, whiteboard: Whiteboard }) {
  }
}

export class MultiConversationWhiteboardAdd implements Action {
  readonly type = WhiteboardActionTypes.MULTI_CONVERSATION_WHITEBOARD_ADD;

  constructor(public payload: { conversationTarget: string, whiteboard: Whiteboard }[]) {
  }
}

export class WhiteboardBulkAppendMultiConversation implements Action {
  readonly type = WhiteboardActionTypes.WHITEBOARD_BULK_APPEND_MULTI_CONVERSATION;

  constructor(public payload: {
    conversationTarget: string,
    whiteboards: Whiteboard[]
  }[]) {
  }
}

export class WhiteboardBulkAppend implements Action {
  readonly type = WhiteboardActionTypes.WHITEBOARD_BULK_APPEND;

  constructor(public payload: {
    conversationTarget: string,
    whiteboards: Whiteboard[]
  }) {
  }
}

export class WhiteboardDeleteAction implements Action {
  readonly type = WhiteboardActionTypes.WHITEBOARD_DELETE_ACTION;

  constructor(public payload: string) {
  }
}

export class WhiteboardsBulkUpdate implements Action {
  readonly type = WhiteboardActionTypes.WHITEBOARDS_BULK_UPDATE;

  constructor(public payload: any[]) {
  }
}

export class WhiteboardUpdateAction implements Action {
  readonly type = WhiteboardActionTypes.WHITEBOARD_UPDATE;

  constructor(public payload: { id: number, changes: any }) {
  }
}

export class SetSelectedWhiteboardId implements Action {
  readonly type = WhiteboardActionTypes.SET_SELECTED_WHITEBOARD_ID;

  constructor(public payload: string) {
  }
}
