<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<vnc-dialog [classes]="['help-dialog-component']" headerText="{{ 'MANUAL_DOCUMENT' | translate }}" closeText="{{ 'CLOSE' | translate }}"
  [headerTemplate]="isMobileScreen ? mobileHeader : null" classes="fixed-size {{ !isMobileScreen ? 'with-border with-border-radius' : '' }}"
  [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<ng-template #mobileHeader>
  <vnc-mobile-header style="width: 100%;" customClass="white-back-more" (backButtonClick)="onEsc()"
    headerType="back-more" mobileHeading="{{ 'USER_MANUAL' | translate }}"></vnc-mobile-header>
</ng-template>
<ng-template #bodyTemplate>
  <div class="link-option" *ngIf="helpLink">
    <a *ngIf="helpLink" target="_blank" class="open-new-window" href="{{ helpLink }}">{{ 'HELP' | translate }} <svg
        width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z"
            id="Shape" fill="#8B96A0"></path>
        </g>
      </svg></a>
  </div>
  <div class="link-option" *ngIf="manualLink">
    <a target="_blank" class="open-new-window" href="{{ manualLink }}">{{ 'MANUAL' | translate }}
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z"
            id="Shape" fill="#8B96A0"></path>
        </g>
      </svg>
    </a>
  </div>

  <div *ngIf="FAQLink" class="link-option">
    <a *ngIf="FAQLink" target="_blank" class="open-new-window" href="{{ FAQLink }}">FAQ <svg width="24px" height="24px"
        viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z"
            id="Shape" fill="#8B96A0"></path>
        </g>
      </svg></a>
  </div>
</ng-template>
