/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit,Renderer2, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonService } from "app/shared/providers";
import { Broadcaster } from "app/talk/shared/providers";
import addYears from "date-fns/addYears";
import { Subject, take, takeUntil } from "rxjs";
import { NotificationService } from "app/talk/services/notification.service";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { environment } from "app/environments/environment";
import { parse } from "date-fns";

@Component({
    selector: "vp-mobile-schedule-dialog",
    templateUrl: "./mobile-schedule-dialog.component.html",
    styleUrls: ["./mobile-schedule-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class MobileScheduleDialogComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    isSchedule: boolean = true;
    public scheduleDateControl = new UntypedFormControl();
    public expireDateControl = new UntypedFormControl();
    minDate: Date = new Date();
    isScheduledMeeting: any;
    isScheduledPost = false;
    classes = ["mobile-schedule-dialog"];
    lang: string;
    headerText = "SCHEDULE";
    datePickerMode: boolean = false;
    maxDate = addYears(new Date(), 3);
    focused = false;
    durations = [
      {
        id: "15min",
        value: "15",
        inMinute: 15,
        label: "MINUTES"
      },
      {
        id: "30min",
        value: "30",
        inMinute: 30,
        label: "MINUTES"
      },
      {
        id: "45min",
        value: "45",
        inMinute: 45,
        label: "MINUTES"
      }
    ];
    duration = "15min";
    constructor(
        private dialogRef: MatDialogRef<MobileScheduleDialogComponent>,
        private changeDetection: ChangeDetectorRef,
        private broadcaster: Broadcaster,
        private newNotificationService: NotificationService,
        private commonService: CommonService,
        private renderer : Renderer2,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        for (let i = 1; i < 7; i++) {
          if (i < 6) {
            this.durations = [...this.durations, ...[
              {
                id: `${i}hour`,
                value: i + "",
                inMinute: i * 60,
                label: i === 1 ? "HOUR" : "HOURS"
              },
              {
                id: `${i }15hour`,
                value: `${i}.15`,
                inMinute: i * 60 + 15,
                label: "HOURS"
              },
              {
                id: `${i}30hour`,
                value: `${i}.30`,
                inMinute: i * 60 + 30,
                label: "HOURS"
              },
              {
                id: `${i}45hour`,
                value: `${i}.45`,
                inMinute: i * 60 + 45,
                label: "HOURS"
              }
            ]];
          } else {
            this.durations = [...this.durations, ...[
              {
                id: `${i}hour`,
                value: i + "",
                inMinute: i * 60,
                label: "HOURS"
              }
            ]];
          }

        }
        this.commonService.currentLanguage.subscribe((lang: string) => {
            this.lang = lang;
            this.changeDetection.markForCheck();
        });
        if (this.data) {
            if (this.data.isScheduledTopic) {
                this.isSchedule = this.data.isScheduledTopic;
            }
            if (this.data.isScheduled) {
                this.isSchedule = this.data.isScheduled;
            }
            this.isScheduledMeeting = this.data.isScheduledMeeting;
            this.isScheduledPost = this.data.isScheduledPost;
            if (this.isScheduledMeeting) {
                this.classes.push("schedule-meeting-dialog");
                this.scheduleDateControl.setValue(this.data.startTimeField);
                this.expireDateControl.setValue(this.data.endTimeField);
                const difference = differenceInMinutes(new Date(this.data.endTimeField), new Date(this.data.startTimeField));
                if (difference < 60) {
                  this.duration = "15min";
                  if (difference > 15 && difference <= 30) {
                    this.duration = "30min";
                  } else if (difference > 30) {
                    this.duration = "45min";
                  }
                } else {
                  const minutes = Math.abs(Math.floor(difference) % 60);
                  const hours = Math.abs(Math.floor(difference / 60));
                  this.duration = `${hours}hour`;
                  if (minutes > 0 && minutes <= 15) {
                    this.duration = `${hours}15hour`;
                  } if (minutes > 15 && minutes <= 30) {
                    this.duration = `${hours}30hour`;
                  } else if (minutes > 30) {
                    this.duration = `${hours}45hour`;
                  }
                  // console.log("differenceInMinutes", minutes, hours);
                }
            }
            if (this.data.publishDate) {
              this.scheduleDateControl.patchValue(this.data.publishDate);
            }
            if (this.data.expiryDate) {
              this.expireDateControl.patchValue(this.data.expiryDate);
            }
            if (this.data.datePickerMode) {
              this.headerText = "DATE";
              this.datePickerMode = true;
            }
            this.changeDetection.markForCheck();
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.broadcaster.on("closeScheduleDialog").pipe(take(1)).subscribe(() => {
            if (this.dialogRef) {
                this.dialogRef.close();
            }
        });
        this.commonService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
            this.lang = lang;
        });
        if (environment.isCordova) {
          StatusBar.backgroundColorByHexString("#000000");
          StatusBar.styleBlackTranslucent();
      }
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        if(environment.isCordova){
          StatusBar.backgroundColorByHexString("#317bbc");
        }
    }

    changeSchedule() {
        this.isSchedule = !this.isSchedule;
        this.changeDetection.markForCheck();
    }

    calculateDuration(): Date {
      const duration = this.durations.find(v => v.id === this.duration);
      let time = 15 * 60000; // default duration
      if (duration && duration.label === "MINUTES") {
        time = duration.inMinute * 60 * 1000;
      } else if (duration && (duration.label === "HOUR" || duration.label === "HOURS")) {
        time = duration.inMinute * 60 * 1000;
      }
      return new Date(this.scheduleDateControl?.value?.startDate.getTime() + time);
    }

    submit(): void {
        if (this.isScheduledMeeting && this.scheduleDateControl.value) {
            const now = new Date().getTime();
            const startDateTime = new Date(this.scheduleDateControl?.value?.startDate).getTime();
            const endDateTime = this.calculateDuration().getTime();
            this.expireDateControl.patchValue(this.calculateDuration());
            if (startDateTime <= now || endDateTime <= now) {
                this.newNotificationService.openSnackBarWithTranslation("START_MEETING_ERROR");
                return;
            } else if (startDateTime > endDateTime) {
                this.newNotificationService.openSnackBarWithTranslation("START_TIME_ERROR");
                return;
            }
        } else if (this.scheduleDateControl.value && this.expireDateControl.value) {
            const now = new Date().getTime();
            const startDateTime = new Date(this.scheduleDateControl?.value?.startDate).getTime();
            const endDateTime = new Date(this.expireDateControl?.value?.startDate).getTime();
            if (startDateTime <= now || endDateTime <= now) {
                this.newNotificationService.openSnackBarWithTranslation("START_MEETING_ERROR");
                return;
            } else if (startDateTime > endDateTime) {
                this.newNotificationService.openSnackBarWithTranslation("START_TIME_ERROR");
                return;
            }
        }
        this.dialogRef.close({
            isSchedule: this.isSchedule,
            scheduleDateControl: this.scheduleDateControl,
            duration: this.duration,
            expireDateControl: this.expireDateControl
        });
    }

  dateChange(event, start: boolean) {
    if((!this.data?.publishDate && event === null) || !this.data?.expiryDate && event === null){
      if (start && !this.data?.publishDate) {
        this.scheduleDateControl.setValue(event?.$d ? {"startDate": event?.$d} : {"startDate": new Date()});
      } else {
        this.expireDateControl.setValue(null);
      }
    }else if(event !== null){
      if (start) {
        this.scheduleDateControl.setValue({"startDate": event?.$d});
      } else {
        this.expireDateControl.setValue({"startDate": event?.$d});
      }
    }else{
      return;
    }
  }
}
