/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";
import { Member } from "../../models/member.model";
import { MemberActionTypes } from "../actions/members";



export interface MembersState extends EntityState<Member> {
  isLoading: boolean;
  isLoaded: boolean;
}



export const membersAdapter: EntityAdapter<Member> = createEntityAdapter<Member>({
  selectId: (member: Member) => member.jid
});

export const initialState: MembersState = membersAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
});


export function membersReducer(state: MembersState = initialState, action: Action): MembersState {
  switch (action.type) {
    case MemberActionTypes.MEMBERS_ADD: {
      const newState = membersAdapter.addMany(action.payload, state);

      const changes = action.payload.map((m: Member) => {
        return {id: m.jid, changes: m};
      });

      return membersAdapter.updateMany(changes, newState);
    }

    case MemberActionTypes.MEMBERS_REMOVE: {
      const deletedIds = action.payload;
      return membersAdapter.removeMany(deletedIds, state);
    }

    default: {
      return state;
    }
  }
}
