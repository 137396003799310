import {
  Component, Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import {FilterType, SmartLinkService} from "../../../../channels/smart-link.service";
import {RelatedTopicsPopupFilterService} from "./related-topics-popup.filter.service";
import {Subject} from "rxjs";
import {debounceTime, map, skip, take, takeUntil} from "rxjs/operators";
import {UntypedFormControl} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RelatedTopicsService} from "../related-topics.service";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";
import { SnackbarType } from "app/channels/models/snackbar.model";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtil } from "app/talk/utils/common.util";

@Component({
  selector: "vp-related-topics-popup",
  templateUrl: "./related-topics-popup.component.html",
  styleUrls: ["./related-topics-popup.component.scss"],
  providers: [RelatedTopicsPopupFilterService],
  encapsulation: ViewEncapsulation.None
})
export class RelatedTopicsPopupComponent implements OnInit, OnDestroy {

  isAlive$ = new Subject();
  relatedTopicsSearchControl = new UntypedFormControl("");
  filterType = FilterType;
  globalFilters = { [FilterType.GLOBAL_UNREAD]: false, [FilterType.GLOBAL_STARRED]: false,  [FilterType.GLOBAL_ARCHIVED]: false };
  appliedFilters: any = null;
  $searchResults = new BehaviorSubject<any[]>([]);
  $previewAlreadyOpen = new BehaviorSubject<boolean>(false);
  defaultLang: any;
  rangesLabel: any;

  constructor(
    public relatedTopicsPopupFilterService: RelatedTopicsPopupFilterService,
    private _smartLinkService: SmartLinkService,
    private _relatedTopicsService: RelatedTopicsService,
    private translate: TranslateService,
    private _channelSnackBarService: ChannelSnackbarService,
    private _dialogRef: MatDialogRef<RelatedTopicsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {alreadySelectedTopics: any[], topicId: any}
  ) {
    (async () => {
      this.rangesLabel = {
        today: await this.translate.get("TODAY").pipe(take(1)).toPromise(),
        yesterday: await this.translate.get("YESTERDAY").pipe(take(1)).toPromise(),
        thisWeek: await this.translate.get("THIS_WEEK").pipe(take(1)).toPromise(),
        thisMonth: await this.translate.get("THIS_MONTH").pipe(take(1)).toPromise(),
        lastMonth: await this.translate.get("LAST_MONTH").pipe(take(1)).toPromise(),
        last3Month: await this.translate.get("LAST_THREE_MONTH").pipe(take(1)).toPromise()
      };
    })();
   }

  ngOnInit(): void {
    this.defaultLang = CommonUtil.getDefaultLang();
    this.relatedTopicsPopupFilterService.fetchAllFilters();

    this.relatedTopicsPopupFilterService.appliedFilters$
      .pipe(takeUntil(this.isAlive$))
      .subscribe(filters => {
        this.appliedFilters = filters;
      });

    this.relatedTopicsSearchControl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.isAlive$))
      .subscribe(text => this.relatedTopicsPopupFilterService.onFilterChange(text, FilterType.TOPIC_SUBJECT));

    this.relatedTopicsPopupFilterService.$searchResults
      .pipe(takeUntil(this.isAlive$), skip(1), map((results: any) => {
        return results.filter(r => !this.data.alreadySelectedTopics?.includes(r?.id));
      }))
      .subscribe(searchResults => {
        this.$searchResults.next(this._smartLinkService.getMappedSmartLinkSearchResults(searchResults));
      });
  }

  selectSearchResult(topic) {
    if(this.data?.topicId === topic?.id){
      // add translations
      this.translate.get("SAME_TOPIC_CANNOT_ADDED_AS_RELATED").pipe(take(1)).subscribe(txt =>{
        this._channelSnackBarService.openSnackBar(txt, SnackbarType.CLOSE);
      });
    }else{
      this._dialogRef.close({selectedTopic: topic});
    }
  }

  updateGlobalFilters(filterName: FilterType.GLOBAL_UNREAD | FilterType.GLOBAL_STARRED | FilterType.GLOBAL_ARCHIVED) {
    this.globalFilters[filterName] = !this.globalFilters[filterName];
    this.relatedTopicsPopupFilterService.onFilterChange(this.globalFilters[filterName], filterName);
  }

  resetInput(): void {
    this.relatedTopicsSearchControl.reset();
  }

  onClosePopup() {
    this._dialogRef.close();
  }

  showTopicPreview(event: { clickEvent: MouseEvent }, topic) {
    const {clientX, clientY} = event?.clickEvent;
    if (this.$previewAlreadyOpen.value) return;
    this.$previewAlreadyOpen.next(true);
    setTimeout(() => {
      this._relatedTopicsService.getRelatedTopicPreviewRef(clientX, clientY, topic, false)
        .afterClosed()
        .pipe(takeUntil(this.isAlive$))
        .subscribe(()=> {
          this.$previewAlreadyOpen.next(false);
        });
    }, 100);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}
