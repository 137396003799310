import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {combineLatest} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {ChannelRepository} from "../../../../channels/repository/channel.repository";
import {Subject} from "rxjs";
import {environment} from "../../../../environments/environment";
import {SnackbarType} from "../../../../channels/models/snackbar.model";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ChannelSnackbarService} from "../../../../channels/channel-snackbar.service";

@Component({
  selector: "vp-related-topic-preview",
  templateUrl: "./related-topic-preview.component.html",
  styleUrls: ["./related-topic-preview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RelatedTopicPreviewComponent implements OnInit, OnDestroy {

  isAlive$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {previewData: any, shouldNavigate: boolean},
    private _dialogRef: MatDialogRef<RelatedTopicPreviewComponent>,
    private _channelRepository: ChannelRepository,
    private _router: Router,
    private _translate: TranslateService,
    private _channelSnackBarService: ChannelSnackbarService
  ) { }

  ngOnInit(): void {
  }

  openSmartLinkEntity() {
    let path = window.location.origin;
    const $channelIdOb = this._channelRepository.getSelectedChannelId();
    const $topicIdOb = this._channelRepository.getSelectedTopicId();
    combineLatest([$channelIdOb, $topicIdOb])
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        const channelId = res[0];
        const topicId = res[1];
        const { topicData } = this.data?.previewData;
        if (!this.data.shouldNavigate) {
          path += `/talk/channels/${topicData?.channel_id}/topics/${topicData.id}`;
          this.openPreviewInNewTab(path);
          return;
        }
        if (topicId && topicId != topicData.id) {
          this._router.navigate([`talk/channels/${topicData?.channel_id}/topics/${topicData.id}`], {
            queryParams: {
              parentChannelId: channelId,
              parentTopicId: topicId
            },
            queryParamsHandling: "merge"
          }).then(() => this._dialogRef.close());
        }
      });
  }

  private openPreviewInNewTab(path) {
    if (environment.isElectron || environment.isCordova) {
      this._translate.get("NAVIGATION_NOT_ALLOWED").pipe(take(1)).subscribe(text => {
        this._channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
    } else {
      const newWindow = window.open(path, "_blank");
      newWindow.focus();
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

}
