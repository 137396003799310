
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { VNCStandardObj } from "./vnc-standard-obj.model";

export * from "./user";

export enum UserStatus {
  ONLINE = 1,
  OFFLINE,
}

export function getAllUserStatusTypes(): UserStatus[] {
  return [
    UserStatus.ONLINE,
    UserStatus.OFFLINE
  ];
}

export function getUserStatusType(type: UserStatus): VNCStandardObj {
  let slug = "";
  let title = "";
  let key = "";
  switch (type) {
    case UserStatus.ONLINE: {
      slug = "online";
      title = "Online";
      key = "HEADER_ONLINE";
      break;
    }

    case UserStatus.OFFLINE:
    default : {
      slug = "offline";
      title = "Invisible";
      key = "HEADER_OFFLINE";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key
  };
}

export enum AppType {
  VNCTALK = 1,
  VNCMAIL,
  VNCCAST,
  VNCCOMMANDER,
  VNCCONTACTS,
  VNCTASK,
}

export function getAllAppTypes(): AppType[] {
  return [
    AppType.VNCTALK,
    AppType.VNCMAIL,
    AppType.VNCCAST,
    AppType.VNCCOMMANDER,
    AppType.VNCCONTACTS,
    AppType.VNCTASK,
  ];
}

export function getAppType(type: AppType): VNCStandardObj {
  let slug = "";
  let title = "";
  let image_key = "";

  switch (type) {
    case AppType.VNCTALK: {
      slug = "vnctalk";
      title = "VNCtalk";
      image_key = "VNCtalk";
      break;
    }

    case AppType.VNCMAIL: {
      slug = "vncmail";
      title = "VNCmail";
      image_key = "VNCmail";
      break;
    }

    case AppType.VNCCAST: {
      slug = "vnccast";
      title = "VNCcast";
      image_key = "VNCcast";
      break;
    }

    case AppType.VNCCOMMANDER: {
      slug = "vnccommander";
      title = "VNCcommander";
      image_key = "VNCcommander";
      break;
    }

    case AppType.VNCCONTACTS: {
      slug = "vnccontacts";
      title = "VNCcontacts";
      image_key = "VNCcontacts";
      break;
    }

    default : {
      slug = "vnctask";
      title = "VNCtask";
      image_key = "VNCtask";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    image_key: "assets/icon/apps/" + image_key + ".svg"
    // No need for translation as the app names remain same in both languages
  };
}

export function appTypeFromString(str: string): AppType {
  switch (str) {
    case "vnctalk":
      return AppType.VNCTALK;
    case "vncmail":
      return AppType.VNCMAIL;
    case "vnccast":
      return AppType.VNCCAST;
    case "vnccommander":
      return AppType.VNCCOMMANDER;
    case "vnccontacts":
      return AppType.VNCCONTACTS;
    default:
      return AppType.VNCTASK;
  }
}

export enum TimeRangeType {
  LAST_HOUR = 1,
  LAST_24_HOURS,
  LAST_WEEK,
  LAST_MONTH,
  CUSTOM,
}

export function getAllTimeRangeTypes(): TimeRangeType[] {
  return [
    TimeRangeType.LAST_HOUR,
    TimeRangeType.LAST_24_HOURS,
    TimeRangeType.LAST_WEEK,
    TimeRangeType.LAST_MONTH,
  ];
}

export function getTimeRangeType(type: TimeRangeType): VNCStandardObj {
  let slug = "";
  let title = "";
  let key = "";

  switch (type) {
    case TimeRangeType.LAST_HOUR: {
      slug = "last_hour";
      key = "TIME_LAST_HOUR";
      break;
    }

    case TimeRangeType.LAST_24_HOURS: {
      slug = "last_24_hours";
      key = "TIME_LAST_24_HOURS";
      break;
    }

    case TimeRangeType.LAST_WEEK: {
      slug = "last_week";
      key = "TIME_LAST_WEEK";
      break;
    }

    case TimeRangeType.LAST_MONTH: {
      slug = "last_month";
      key = "TIME_LAST_MONTH";
      break;
    }

    default: {
      slug = "custom";
      key = "TIME_CUSTOM";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key,
  };
}

export function timeRangeTypeFromString(str: string): TimeRangeType {
  switch (str) {
    case "last_hour":
      return TimeRangeType.LAST_HOUR;
    case "last_24_hours":
      return TimeRangeType.LAST_24_HOURS;
    case "last_week":
      return TimeRangeType.LAST_WEEK;
    case "last_month":
      return TimeRangeType.LAST_MONTH;
    default:
      return TimeRangeType.CUSTOM;
  }
}
