<div *ngIf="!data.isMobileView" class="add-remark-popup-container">
  <div class="text-area-wrapper">
    <vnc-textarea
      [type]="'simple'"
      [control]="remarkText"
      [rows]="rows"
      [focused]="false"></vnc-textarea>
  </div>
  <div class="remark-footer">
    <vnc-footer [secondaryLabel]="'CANCEL' | translate | uppercase"
                [primaryLabel]="data?.selectedData?.uid? 'UPDATE' : 'CREATE' | translate | uppercase"
                [type]="'secondary-primary'"
                [color]="'white'"
                (onSecondaryClick)="closeDialog()"
                (onPrimaryClick)="createRemark()"></vnc-footer>
  </div>
</div>

<div *ngIf="data.isMobileView">
  <vnc-dialog [classes]="['mobile-editor-channel-dialog', 'footer-display']" [headerTemplate]="mobileHeaderTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
              [bodyTemplate]="mobileBodyTemplate">
  </vnc-dialog>
  <ng-template #mobileHeaderTemplate>
    <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{headerLabel | translate}}"
                       (darkSuffixButtonPress)="createRemark()" [darkSuffixIcon]="'mdi-check'" (darkBackPress)="closeDialog()">
    </vnc-mobile-header>
  </ng-template>
  <ng-template #mobileBodyTemplate>
    <vnc-textarea
      [type]="'simple'"
      [control]="remarkText"
      [rows]="rows"
      [focused]="true"></vnc-textarea>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="right">
      <button mat-mini-fab (click)="createRemark()">
        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-send"></mat-icon>
      </button>
    </div>
  </ng-template>
</div>

