import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "app/talk/shared/providers";
import { PopupPositionService } from "./popup-position.service";
import { StickyNoteComponent } from "./sticky-note/sticky-note.component";
import {Utils} from "../common";
import {SmartObject} from "./smart-objects/smart-objects.service";
import format from "date-fns/format";

@Injectable()
export class StickyNoteService {
  colorSchemes = [
    {
      name: "default",
      primary: "#a8b0b9",
      secondary: "#f5f8fa",
      border: "#eaeef2",
    },
    {
      name: "blue",
      primary: "#388eda",
      secondary: "#ecf6fe",
      border: "#d3eafd",
    },
    {
      name: "green",
      primary: "#27ab7f",
      secondary: "#e1f5ee",
      border: "#c0e8da",
    },
    {
      name: "yellow",
      primary: "#fdb611",
      secondary: "#fefcf6",
      border: "#faf0d9",
    },
    {
      name: "orange",
      primary: "#fa864c",
      secondary: "#fff4ee",
      border: "#ffdbc8",
    },
    {
      name: "pink",
      primary: "#ef417b",
      secondary: "#fce4ec",
      border: "#fcd0df",
    },
  ];
  openedNote = null;

  constructor(
    private _dialog: MatDialog,
    private _popup: PopupPositionService,
    private _broadcaster: Broadcaster
  ) { }

  openStickyNotePopup(popupConfig, note = "", viewOnly = false, bypassViewMode = false, isMobileView = false) {
    const data = {
      preContent: popupConfig?.preContent,
      selectedText: popupConfig?.selectedText,
      postContent: popupConfig?.postContent,
    };

    return this.getStickyNotePopupRef(
      data,
      popupConfig.pageX,
      popupConfig.pageY,
      note,
      viewOnly,
      bypassViewMode,
      true,
      isMobileView
    );
  }

  getStickyNotePopupRef(
    data,
    clientX,
    clientY,
    note: any = {},
    viewOnly = false,
    bypassViewMode = false,
    canAddMoreEntities = true,
    isMobileView = false
  ): MatDialogRef<StickyNoteComponent> {
    if (note?.uid) { // limit consecutive opening due to broadcasting;
      if (note?.uid === this.openedNote) {
        return;
      }
      this.openedNote = note?.uid;
      setTimeout(() => {
        this.openedNote = null;
      }, 1000);
    }
    return this._dialog.open(StickyNoteComponent, {
      width: isMobileView ? "100%" : "430px",
      height: isMobileView ? "100%" : "fit-content",
      backdropClass: "transparent-backdrop",
      panelClass: isMobileView ? "smartlink-popup-container-mobile" : "sticky-note-popup-container",
      data: { selectedData: data, note, viewOnly, bypassViewMode, canAddMoreEntities, isMobileView },
      position: isMobileView ? {
        left: "0px",
        top: "0px"
      } : this._popup.getPopupPosition(
        { width: 430, height: 520 },
        clientX,
        clientY
      ),
    });
  }

  constructStickyNoteData(innerText, uid, bg_color) {
    const colorScheme = this.colorSchemes.find((cs) =>
      cs?.secondary.includes(bg_color)
    );
    const data = {
      type: "sticky-note",
      stickyNoteBgColor: colorScheme?.name,
      uid,
    };
    const element = document.createElement("s");
    Object.entries(data).forEach((entry) => {
      element.dataset[entry[0]] = entry[1];
    });

    element.textContent = innerText;
    element.contentEditable = "false";
    return element;
  }

  updateDescriptions() {
  }

  changeStickyNoteDataInHTML(newNote: any) {
    const colorScheme = this.colorSchemes.find((cs) =>
      cs?.secondary.includes(newNote.bg_color)
    );
    const elem = document
      .querySelectorAll(`s[data-uid="${newNote.uid}"]`)
      .item(0);
    // @ts-ignore
    elem.dataset["stickyNoteBgColor"] = colorScheme?.name;
  }

  deleteStickyNote(noteUid:string) {
    const elem = document
    .querySelectorAll(`s[data-uid="${noteUid}"]`)
    .item(0);
    elem.replaceWith(elem.innerHTML);
  }

  transformStickyNoteData(sn: any): any {
    let created_on = "";
    if (!!sn.updated_at) {
      created_on = format(Date.parse(sn?.updated_at), "dd/MM/yy HH:mm").split(" ").join(" at ");
    }
    return {
      ...sn,
      note_description: sn.notes,
      created_on,
      bg_color_hex: sn.bg_color,
    };
  }

  getMappedStickyNoteDropdownData(stickyNotes, userConfig) {

    return stickyNotes.map(note => ({
      uid: note?.uid,
      description: note?.notes,
      author: {
        name: note?.author?.name || userConfig?.name,
        avatar_url: note?.author?.avatar_url || userConfig?.avatar_url
      },
      date: !!note?.updated_at ? format(Date.parse(note?.updated_at), "dd.MM.yyyy") : "",
      color_scheme: this.getColorSchemeByBgColor(note?.bg_color),
      fullData: {...note}
    }));
  }

  getMappedStickyNoteDataForEditMode(stickyNote) {
    let created_on = "";
    if (!!stickyNote?.fullData?.created_at) {
      created_on = format(Date.parse(stickyNote?.fullData?.created_at), "dd/MM/yy HH:mm").split(" ").join(" at ");
    }

    return {
      ...stickyNote?.fullData,
      note_description: stickyNote?.description,
      bg_color_hex: stickyNote?.fullData?.bg_color,
      created_on: created_on,
      author: {...stickyNote?.author},
      uid: stickyNote.uid
    };
  }

  getColorSchemeByBgColor(color) {
    const colorScheme = this.colorSchemes.find(scheme => scheme.secondary.includes(color));
    return {
      name: colorScheme?.name,
      primary_color: colorScheme.primary,
      bg_color: colorScheme.secondary,
      border_color: colorScheme.border
    };
  }

  getMappedStickyNotesForReqPayload(notes, mapFromRawData = false) {
    return (notes || []).map(note => {
      return mapFromRawData ? {
        uid: Utils.makeid(15),
        notes: note?.note_description,
        bg_color: note?.bg_color_hex.substring(1)
      } : {
        uid: note?.uid,
        notes: note?.description,
        bg_color: note?.color_scheme?.bg_color?.substring(1)
      };
    });
  }

  addStickyNoteToExistingSmartString(element, data: {smartLinkData?: any[], stickyNotesData?: any[]}) {
    const linkText = element.textContent;

    const newElement = document.createElement("s");
    newElement.dataset["type"] = SmartObject.SMART_OBJECTS;
    newElement.setAttribute("data-sticky-note", "true");

    if (Object.keys(data)?.includes("smartLinkData")) {
      newElement.dataset[SmartObject.SMART_LINK.toLowerCase()] = "true";
    }

    const newData = {
      ...data,
      stickyNotesData: [
        ...(data?.stickyNotesData.map(note => note?.uid))
      ]
    };
    newElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(newData)));
    newElement.contentEditable = "false";
    newElement.textContent = linkText;

    return newElement;
  }
}

export enum StickyNoteActions {
  DELETE = "DELETE",
  SAVE = "SAVE",
  ADD_SMART_LINK = "ADD_SMART_LINK",
  ADD_REFERENCE = "ADD_REFERENCE",
  ADD_STICKY_NOTE = "ADD_STICKY_NOTE",
  ADD_HIGHLIGHT = "ADD_HIGHLIGHT"
}
