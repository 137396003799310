/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Notification } from "app/channels/models/notification.model";
import { Action } from "../actions";
import { NotificationActionTypes } from "../actions/notification";




export interface NotificationState extends EntityState<Notification> {
  isLoading: boolean;
  isLoaded: boolean;
  notificationsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  unreadNotificationsInfo: { totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean};
  unreadCount: number;
}



export const notificationAdapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: (notification: Notification) => notification.id,
  sortComparer: sortByTimestamp
});


export function sortByTimestamp(c1: Notification, c2: Notification): number {
  return new Date(c2.created_at).getTime() - new Date(c1.created_at).getTime();
}


export const initialState: NotificationState = notificationAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  notificationsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  unreadNotificationsInfo: { totalCount: 0, offset: 0, ids: [], isLoading: false, isLoaded: false},
  unreadCount: 0
});


export function notificationReducer(state: NotificationState = initialState, action: Action): NotificationState {
  switch (action.type) {

    case NotificationActionTypes.NOTIFICATION_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case NotificationActionTypes.NOTIFICATION_LOAD_SUCCESS: {
        const notifications = action.payload.map(t => {
            let notification = state.entities[t.id];
            if (notification) {
              t = {...t, ...notification};
            }
            return t;
          });
          return notificationAdapter.setAll(notifications, {
            ...state,
            isLoading: false,
            isLoaded: true,
          });
    }

    case NotificationActionTypes.NOTIFICATION_ADD: {
      const newState = notificationAdapter.addOne(action.payload, state);
      return notificationAdapter.updateOne({id: action.payload.id, changes: action.payload}, newState);
    }

    case NotificationActionTypes.NOTIFICATION_UPDATE: {
      const updatedState = notificationAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case NotificationActionTypes.NOTIFICATION_BULK_ADD: {
      const newState = notificationAdapter.addMany(action.payload, state);

      const changes = action.payload.map(notification => {
        return {id: notification.id, changes: notification};
      });

      return notificationAdapter.updateMany(changes, newState);
    }

    case NotificationActionTypes.NOTIFICATION_INFO_UPDATE: {
      return {
        ...state,
        notificationsInfo: action.payload
      };
    }

    case NotificationActionTypes.UNREAD_NOTIFICATION_INFO_UPDATE: {
      return {
        ...state,
        unreadNotificationsInfo: action.payload
      };
    }

    case NotificationActionTypes.NOTIFICATION_DELETE: {
      return notificationAdapter.removeOne(action.payload, state);
    }

    case NotificationActionTypes.NOTIFICATION_BULK_DELETE: {
      return notificationAdapter.removeMany(action.payload, state);
    }

    case NotificationActionTypes.UNREAD_NOTIFICATION_COUNT_UPDATE: {
      return {
        ...state,
        unreadCount: action.payload
      };
    }

    default: {
      return state;
    }
  }
}


export const _getNotificationIsLoading = (state: NotificationState) => state.isLoading;
export const _getNotificationIsLoaded = (state: NotificationState) => state.isLoaded;
export const _getNotificationInfo = (state: NotificationState) => state.notificationsInfo;
export const _getUnreadNotificationInfo = (state: NotificationState) => state.unreadNotificationsInfo;
export const _getUnreadNotificationCount = (state: NotificationState) => state.unreadCount;
