
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2, HostListener } from "@angular/core";

@Directive({ selector: "[vpAutoFocusScrollPos]" })
export class AutoFocusScrollPosDirective implements AfterViewInit, OnDestroy {
  @Input() parentContainer: Element;
  @Input() offset: number = 0;

  input: Element;
  isInuptFocussed: boolean = false;

  private elementClickHandler = this.onElementClick.bind(this);
  private elementBlurHandler = this.onElementBlur.bind(this);
  private elementFocusHandler = this.onElementFocus.bind(this);
  private elementFocusings: any[] = [];

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.input = this.el.nativeElement.querySelector("input");
    this.input.addEventListener("click", this.elementClickHandler);
    this.input.addEventListener("blur", this.elementBlurHandler);
    this.input.addEventListener("focus", this.elementFocusHandler);
  }

  onElementClick() {
    this.isInuptFocussed = false;
    this.elementFocusings = [];
    this.elementFocusings.push(this.input);
    this.parentContainer.scrollTop = this.el.nativeElement.offsetTop + this.input.clientHeight - this.offset;
    this.renderer.selectRootElement(this.input, true).focus();
  }

  onElementBlur() {
    this.isInuptFocussed = false;
    this.elementFocusings = [];
  }

  onElementFocus() {
    this.isInuptFocussed = true;
  }

  reFocusElementOnChanges() {
    if (this.elementFocusings.length > 0) {
      this.renderer.selectRootElement(this.input, true).click();
    }
  }

  ngOnDestroy() {
    this.input.removeEventListener("click", this.elementClickHandler);
    this.input.removeEventListener("blur", this.elementBlurHandler);
    this.input.removeEventListener("focus", this.elementFocusHandler);
  }

  @HostListener("window:resize", ["$event"])
  detectBrowser() {
    this.reFocusElementOnChanges();
  }
}
