<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
  <div class="file-preview-dialog-main">
      <div class="header-item">
        <!--
          <div class="lbl">
            <span *ngIf="!!attachment"> {{ attachment?.filename}}</span>
          </div>
        -->
          
        <div class="back_button">
          <mat-icon (click)="close()" id="hidePreviewMobile">arrow_back</mat-icon>
        </div>
        <div class="document-actions">
          <div class="document-actions-item" *ngFor="let item of headerActions" (click)="triggerEvent.next(item.action)">
            <img class="vnctask-icon" [src]="'assets/icon/preview-header/' + item.vncIconName + '.svg'" alt="{{item.label}}">
          </div>
        </div>
        <div>  
          <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-close" (click)="close()"></mat-icon>
        </div>

      </div>

      <div *ngIf="!isAPIUrlAvailable || !isRenderableFile" class="not-render-file-text">
        {{ 'CANNOT_GENERATE_PREVIEW' | translate }}
    </div>
    <div *ngIf="isAPIUrlAvailable && isRenderableFile" class="office-viewer-div">
      <onlyoffice [config]="officeConfig"></onlyoffice>
    </div>
  </div>
