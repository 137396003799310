
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, HostListener, Input, HostBinding } from "@angular/core";

@Directive({ selector: `[vpDelayDragLift]` })
export class DelayDragLiftDirective {
  @Input() delayDrag: number;

  private touchTimeout: any;

  @HostBinding("class.delay-drag-lifted")
  private draggable: boolean = false;

  get delay() {
    // if it"s a string, that"s because we didn"t pass a value (<div delayDragLift></div>)
    return (typeof this.delayDrag === "number")
      ? this.delayDrag
      : 200;
  }

  @HostListener("touchstart", ["$event"])
  private onTouchStart(): void {
    this.touchTimeout = setTimeout(() => {
      this.draggable = true;
    }, this.delay);
  }

  @HostListener("touchmove", ["$event"])
  private onTouchMove(evt: Event): void {
    if (!this.draggable) {
      evt.stopPropagation();
      clearTimeout(this.touchTimeout);
    }
  }

  @HostListener("touchend", ["$event"])
  private onTouchEnd(): void {
    clearTimeout(this.touchTimeout);
    this.draggable = false;
  }
}
