/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { RedmineApiService } from "app/talk/services/redmine-api.service";
import { CommonUtil } from "app/talk/utils/common.util";
import { SelectWatcherComponent } from "../select-watcher/select.watcher.component";
import { newTicketData } from "./new-ticket.data";
import { DatePipe } from "@angular/common";
import { Observable, take, forkJoin, takeUntil, Subject, distinctUntilChanged, debounceTime, BehaviorSubject } from "rxjs";
import { ChannelService } from "app/channels/channel.service";
import { ToastService } from "app/shared/services/toast.service";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { Store } from "@ngrx/store";
import { TalkRootState } from "app/talk/reducers";
import { getUserJID } from "app/reducers";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "app/environments/environment.dev";


@Component({
  selector: "vp-create-new-ticket",
  templateUrl: "./create-new-ticket.component.html",
  styleUrls: ["./create-new-ticket.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CreateNewTicketComponent implements OnInit , AfterViewInit{
  @ViewChild("chatDragDropShade", {static: false}) chatDragDropShade: ElementRef;
  ticketTitle: FormControl;
  ticketEstimate: FormControl;
  ticketDone: FormControl;
  ticketProject: FormControl;
  ticketStatus: FormControl;
  ticketAssignee: FormControl;
  ticketStartDate: FormControl;
  ticketDueDate: FormControl;
  ticketTargetVersion: FormControl;
  ticketType: FormControl;
  ticketPriority: FormControl;
  ticketAnalyticsAccount: FormControl;
  ticketStoryPoints: FormControl;
  ticketOTA: FormControl;

  ticketProjectSearchText: FormControl;
  ticketAssigneeSearchText: FormControl;
  ticketTargetVersionSearchText: FormControl;


  ticketDescriptionContent: any = "";
  ticketUserStoryContent: any = "";
  ticketTechSpecsContent: any = "";
  ticketMockupsContent: any;
  newTagText: FormControl;
  currentFilter: string = "description";
  showTags: boolean = true;
  showWatchers: boolean = true;
  showAttachments: boolean = true;
  showTagInput: boolean = false;
  tagsList: string[] = [];

  watcherList: any = [];

  statusOptions: ISelectOptions[] = [];
  typeOptions: ISelectOptions[] = [];
  priorityOptions: ISelectOptions[] = [];
  analyticAccountOptions: ISelectOptions[] = newTicketData.analyticOptions;
  ticketDoneOptions: ISelectOptions[] = newTicketData.ticketDone;
  projectList = newTicketData.projectList;
  projectVersionList = newTicketData.projectVersionsList;
  assigneeOptionsList = [];

  attachmentsList: any[] = [];
  appProgressBarColor: any;
  styleEl: HTMLStyleElement = document.createElement("style");
  //generate unique attribute which we will use to minimize the scope of our dynamic style
  uniqueAttr = `app-progress-bar-color-1`;

  projectListShow: boolean = false;
  assigneeListShow: boolean = false;
  ticketTargetVersionShow: boolean = false;

  tagsListShow: boolean = false;


  newTicketData: INewTicketData = {
    project_id: null,
    tracker_id: null,
    status_id: null,
    priority_id: 2,
    subject: "",
    description: "",
    assigned_to_id: "",
    estimated_hours: null,
  };
  projectWatcherList: any[] = [];

  tagsListSuggestion: any[] = [];
  tagsListSuggestionCopy: any[] = [];

  targetVersionId: any;
  projectListPage = 1;
  showDragAndDrop$ = new BehaviorSubject<boolean>(false);
  loggedInJid: string = "";
  prefilledUser: string = "";
  private isAlive$ = new Subject<boolean>();

  constructor(
    private matDialogRef: MatDialogRef<CreateNewTicketComponent>,
    public matDialog: MatDialog,
    private el: ElementRef,
    private redmineService: RedmineApiService,
    public datepipe: DatePipe,
    private channelService: ChannelService,
    private toastService: ToastService,
    private conversationRepo: ConversationRepository,
    private store: Store<TalkRootState>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.store.select(getUserJID).pipe(take(1)).subscribe(res => {
      this.loggedInJid = res.bare;
    });
    this.ticketTitle = new FormControl("", [Validators.required, Validators.maxLength(100)]);
    this.ticketEstimate = new FormControl("", [Validators.required, Validators.maxLength(100)]);
    this.ticketDone = new FormControl("", [Validators.maxLength(100)]);
    this.ticketProject = new FormControl("", [Validators.required, Validators.maxLength(100)]);

    this.ticketStatus = new FormControl([1], [Validators.maxLength(100)]);
    this.ticketAssignee = new FormControl("", [Validators.maxLength(100)]);
    this.ticketStartDate = new FormControl(new Date(), [Validators.maxLength(100)]);
    this.ticketDueDate = new FormControl("", [Validators.maxLength(100)]);
    this.ticketTargetVersion = new FormControl("", [Validators.maxLength(100)]);
    this.ticketPriority = new FormControl([2], [Validators.required, Validators.maxLength(100)]);
    this.ticketType = new FormControl("", [Validators.required, Validators.maxLength(100)]);
    this.ticketAnalyticsAccount = new FormControl("", [Validators.maxLength(100)]);
    this.ticketStoryPoints = new FormControl("", [Validators.maxLength(100)]);
    this.ticketOTA = new FormControl("", [Validators.maxLength(100)]);
    this.newTagText = new FormControl("", [Validators.maxLength(100)]);

    this.ticketProjectSearchText = new FormControl("", [Validators.maxLength(100)]);
    this.ticketAssigneeSearchText = new FormControl("", [Validators.maxLength(100)]);
    this.ticketTargetVersionSearchText = new FormControl("", [Validators.maxLength(100)]);

    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.setAttribute(this.uniqueAttr, "");
    nativeEl.appendChild(this.styleEl);
    this.styleEl.innerText = `[${this.uniqueAttr}] .mat-progress-bar-fill::after {background-color: #2CAB7F;}`;

    this.ticketProjectSearchText.valueChanges.subscribe(value => {
      this.onSearchProject(value);
    });

    this.ticketAssigneeSearchText.valueChanges.subscribe(value => {
      this.onSearchAssignee(value);
    });

    this.showDragAndDrop$.asObservable().pipe(
      debounceTime(200),
      distinctUntilChanged(),
      takeUntil(this.isAlive$)).subscribe(v => {
        if (this.chatDragDropShade && this.chatDragDropShade.nativeElement) {
          if (v) {
            this.chatDragDropShade.nativeElement.classList.add("drag-n-drop-shade-visible");
          } else {
            this.chatDragDropShade.nativeElement.classList.remove("drag-n-drop-shade-visible");
          }
        }
    });

    this.ticketTargetVersionSearchText.valueChanges.subscribe(value => {
      this.onSearchProjectVersion(value);
    });

    this.redmineService.projectTagsSuggestion("").subscribe(res => {
      const mergedTags = [...new Set([...this.tagsListSuggestion, ...res.tags])];
      this.tagsListSuggestion = res.tags;
      this.tagsListSuggestionCopy = mergedTags;
    });

    this.newTagText.valueChanges.subscribe(value => {
      if (value === "") {
        this.tagsListSuggestion = this.tagsListSuggestionCopy;
      }
      this.redmineService.projectTagsSuggestion(value).subscribe(res => {
        const mergedTags = [...new Set([...this.tagsListSuggestion, ...res.tags])];
        this.tagsListSuggestion = res.tags;
        this.tagsListSuggestionCopy = mergedTags;
      });
    });

    this.ticketType.valueChanges.subscribe(value => {
      this.newTicketData.tracker_id = value[0];
    });

    this.ticketStatus.valueChanges.subscribe(value => {
      this.newTicketData.status_id = value[0];
    });

    this.ticketPriority.valueChanges.subscribe(value => {
      this.newTicketData.priority_id = value[0];
    });


    this.redMineData();

    this.ticketEstimate.valueChanges.pipe(takeUntil(this.isAlive$), distinctUntilChanged(), debounceTime(100))
    .subscribe((val) => {
      let cleanedString = this.removeNonNumeric(val);
      if (cleanedString.length > 4) {
        cleanedString = cleanedString.slice(0, 4);
      }
      this.ticketEstimate.patchValue(cleanedString);

    });

    if (data.prefilledUser) {
      this.getPrefilledUser(data.prefilledUser);
      this.prefilledUser = data.prefilledUser;
    }
  }


  removeNonNumeric(str) {
    let cleanedStr = str.replace(/[^\d.]/g, "");
    // Allow only one dot in the string
    let dotIndex = cleanedStr.indexOf(".");
    if (dotIndex !== -1) {
        // Keep the first dot and remove subsequent dots
        cleanedStr = cleanedStr.substring(0, dotIndex + 1) + cleanedStr.substring(dotIndex + 1).replace(/\./g, "");
    }

    return cleanedStr;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (!environment.isCordova) {
      ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
        this.el.nativeElement.addEventListener(eventName, (e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }, false);
      });

      ["dragenter", "dragover"].forEach(eventName => {
        this.el.nativeElement.addEventListener(eventName, (e: any) => {
          if (!this.showDragAndDrop$.value) {
            this.showDragAndDrop$.next(true);
          }
        }, false);
      });
      ["dragleave", "drop"].forEach(eventName => {
        this.el.nativeElement.addEventListener(eventName, (e: any) => {
          if (this.showDragAndDrop$.value) {
            this.showDragAndDrop$.next(false);
          }
        }, false);
      });
      this.el.nativeElement.addEventListener("drop", (e: any) => {
        const dt = e?.dataTransfer;
        const files = dt?.files;

        let chatDragDropShadeVisible = this.chatDragDropShade.nativeElement.className.includes("drag-n-drop-shade-visible");
        if (chatDragDropShadeVisible && files?.length) {
          this.uploadDropFile(files);
        }
      }, false);
    }
  }

  @HostListener("document:keydown.esc")
  close(): void {
    this.matDialogRef.close();

    // Select elements by class name
      const elements:any = document.getElementsByClassName("chat-sidebar-header");
      // Loop through the elements and add the style
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.position = "absolute";
      }
      setTimeout(() => {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.position = "";
        }
      }, 150);

  }

  getNextPageProject(){
    this.projectListPage = this.projectListPage + 1;
    this.redmineService.projectList("", this.projectListPage).subscribe(projectList => {
      projectList.results.map(project => {
        this.projectList.push(project);
      });
      if (projectList.pagination.more) {
        this.getNextPageProject();
      };
    });
  }

  redMineData() {
    this.redmineService.projectList().subscribe(projectList => {
      this.projectList = projectList.results;
      newTicketData.projectList = projectList.results;
      if (projectList.pagination.more) {
        this.getNextPageProject();
      };
      this.selectProject(this.projectList[0]);
    });

    this.redmineService.projectAssignee().subscribe(assignee => {
      this.projectWatcherList = assignee.users;
    });


    this.redmineService.getAllTickets().subscribe(allTickets => {
    });

    this.redmineService.ticketStatus().subscribe(status => {
      if (status?.issue_statuses && status?.issue_statuses?.length > 0) {
        this.statusOptions = [{
          value: status.issue_statuses[0]?.id,
          viewValue: status.issue_statuses[0]?.name
        }];
      }
    });

    this.redmineService.ticketPriorities().subscribe(priorities => {
      const prioritiesOptions = [];
      if (priorities?.issue_priorities && priorities?.issue_priorities?.length > 0) {
        priorities?.issue_priorities.map(pr => {
          prioritiesOptions.push({ viewValue: pr.name, value: pr.id });
        });
      }
      this.priorityOptions = prioritiesOptions;
    });

  }

  filterConversations(name) {
    this.currentFilter = name;
  }

  async addWatcher() {
    let options: any = {
      width: "440px",
      borderRadius: "8px",
      height: "680px",
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh",
      };
    }

    const dialogRef = this.matDialog.open(SelectWatcherComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: {
        watcherList: this.projectWatcherList,
      },
      autoFocus: true
    }, options)
    );
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.watcherList = result;
        this.watcherMerger(result);
      }

    });
  }

  watcherMerger(result) {
      this.projectWatcherList = this.mergeData(this.projectWatcherList, result);
  }


  addNewTag(e, isEvent: boolean = true) {
    if (isEvent) this.tagsList.push(e.target.value);
    else this.tagsList.push(e);
    this.tagsListShow = false;
    this.newTagText.patchValue("");
  }

  removeTag(index) {
    this.tagsList.splice(index, 1);
  }



  attachFile(event) {
    this.uploadVideoFile(event);
  }


  onSearchProject(value: string) {
    this.projectList = newTicketData.projectList.filter(search => search.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
    if (this.projectList.length == 0) {
      this.searchProject(value);
    }
    if (value === "") this.projectList = newTicketData.projectList;
  }

  onSearchProjectVersion(value: string) {
    this.projectVersionList = newTicketData.projectVersionsList.filter(search => search.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
  }

  onSearchAssignee(value: string) {
    this.assigneeOptionsList = newTicketData.assigneeList.filter(search => search.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
  }

  // assigneeOptionsList
  selectProject(item) {
    this.projectVersionList = [];
    newTicketData.projectVersionsList = [];
    this.ticketProject.patchValue(item.name);
    this.projectListShow = false;
    this.ticketProjectSearchText.patchValue("");
    this.newTicketData.project_id = item.id;
    this.getAssignableAssignee();
  }

  getAssignableAssignee() {
    this.redmineService.projectAssignableUserList(this.newTicketData.project_id).subscribe(res => {
      this.setProjectVersion(res.issue.assignable_versions);
      this.setAssignee(res.issue.assignable_users);
      this.setPrefilledAssignee(res.issue.assignable_users);
      this.setTrackerType(res.issue.allowed_trackers);
    });
  }

  setPrefilledAssignee(usersList) {
    usersList.map(user => {
      if (user.jid === this.prefilledUser) {
        this.selectAssignee(user);
      };
    });
  }

  setTrackerType(trackers) {
    const typeOptions = [];
    trackers.map(pr => {
      typeOptions.push({ viewValue: pr.name, value: pr.id });
    });
    this.typeOptions = typeOptions;
  }

  setAssignee(assigneeList) {
    newTicketData.assigneeList = assigneeList;
    this.assigneeOptionsList = assigneeList;
  }
  setProjectVersion(assignable_versions) {
    newTicketData.projectVersionsList = [];
    this.projectVersionList = [];
    newTicketData.projectVersionsList = assignable_versions;
        newTicketData.projectVersionsList.map(version => {
          this.projectVersionList.push(version);
    });
  }

  getProjectVersion() {
    this.targetVersionId = null;
    this.redmineService.projectVersions(this.newTicketData.project_id).subscribe(targetVersions => {
      if (targetVersions && targetVersions.versions && targetVersions.versions.length > 0) {
        newTicketData.projectVersionsList = targetVersions.versions;
        newTicketData.projectVersionsList.map(version => {
          this.projectVersionList.push(version);
        });
      }

    });
  }


  selectAssignee(item) {
    this.ticketAssignee.patchValue(item.name);
    if (item.jid === this.loggedInJid) {
      this.translate.get("ME_PROJECT_LBL").pipe(take(1)).subscribe(text => {
        this.ticketAssignee.patchValue(text);
      });
    }
    this.assigneeListShow = false;
    this.ticketProjectSearchText.patchValue("");
    this.newTicketData.assigned_to_id = item.id;
  }

  selectTargetVersion(item) {
    this.targetVersionId = item.id;
    this.ticketTargetVersion.patchValue(item.name);
    this.ticketTargetVersionShow = false;
    this.ticketTargetVersionSearchText.patchValue("");
  }

  closeAlOverlayList() {
    this.projectListShow = false;
    this.assigneeListShow = false;
    this.ticketTargetVersionShow = false;
    this.tagsListShow = false;
  }

  checkFileIsImage(file): boolean {
    if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif" || file.type == "image/webp") return true;
    else return false;
  }

  hitCreateButton:boolean = false;
  createNewTicket() {
    // this.hitCreateButton = true;
    // if (!this.ticketTitle.valid || !this.ticketEstimate.valid || !this.ticketProject.valid || !this.ticketType.valid || !this.ticketPriority.valid) {
    //   return;
    // }
    let watcherIds = [];

    this.watcherList.map(w => {
      watcherIds.push(w.id);
    });
    let data = {
      issue: {
        project_id: this.newTicketData.project_id,
        tracker_id: this.newTicketData.tracker_id,
        status_id: this.newTicketData.status_id,
        priority_id: this.newTicketData.priority_id,
        subject: this.ticketTitle.value,
        description: this.manipulateString(this.ticketDescriptionContent),
        assigned_to_id: this.newTicketData.assigned_to_id,
        watcher_user_ids: watcherIds,
      }
    };

    if (this.ticketUserStoryContent && this.ticketUserStoryContent !== "") {
      data.issue["user_story"] = this.manipulateString(this.ticketUserStoryContent);
    }
    if (this.ticketTechSpecsContent && this.ticketTechSpecsContent !== "") {
      data.issue["tech_specs"] = this.manipulateString(this.ticketTechSpecsContent);
    }
    if (this.ticketMockupsContent && this.ticketMockupsContent !== "") {
      data.issue["mockups"] = this.manipulateString(this.ticketMockupsContent);
    }
    if(this.targetVersionId){
      data.issue["fixed_version_id"] = this.targetVersionId;
    }
    if (this.ticketStartDate.value) {
      data.issue["start_date"] = this.datepipe.transform(this.ticketStartDate.value, "yyyy-MM-dd");
    }
    if (this.ticketStartDate.value) {
      data.issue["due_date"] = this.datepipe.transform(this.ticketDueDate.value, "yyyy-MM-dd");
    }

    if (this.ticketEstimate.value) {
      data.issue["estimated_hours"] = this.ticketEstimate.value;
    }
    if (this.ticketDone.value) {
      data.issue["done_ratio"] = this.ticketDone.value;
    }
    if (this.attachmentsList.length) {
      data.issue["uploads"] = this.attachmentsList;
    }
    if (this.ticketOTA.value) {
      data.issue["ota"] = this.ticketOTA.value;
    }
    if (this.tagsList.length) {
      // data.issue["tags_value"] = this.tagsList
      data.issue["tags"] = this.tagsList.join(",");
    }

    this.redmineService.createNewTicket(data).subscribe(ticket => {
      if (ticket.issue) {
        this.toastService.show("TICKET_CREATED");
        this.matDialog.closeAll();
      } else {
        this.toastService.showSnackbar(ticket.error[0]);
      }
    });
  }


  // File Upload Data

  readFiles(files: any[]): Observable<any> {
    let observableUploadFilesBatch = [];
    for (let i = 0; i < files.length; i++) {
      observableUploadFilesBatch.push(this.fileReaderObs(files[i]));
    }
    return forkJoin(observableUploadFilesBatch);
  }

  fileReaderObs(file) {
    let myReader: FileReader = new FileReader();
    let fileReaderObs = Observable.create((observer: any) => {
      myReader.onloadend = () => {
        let upload = {
          filename: file.name,
          content_type: file.type,
          content: myReader.result,
        };
        observer.next(upload);
        observer.complete();
      };
    });
    myReader.readAsArrayBuffer(file);
    return fileReaderObs;
  }

  uploadVideoFile(fill: any) {
    for (let i = 0; i < fill.target.files.length; i++) {
      this.readFiles([fill.target.files[i]]).pipe(take(1)).subscribe(fileUploads => {
        this.uploadFileWithProgress(fileUploads, fill.target.files[i], fill.target.files[i]);
      });
    }
  }

  uploadDropFile(fill: any) {
    for (let i = 0; i < fill?.length; i++) {
      this.readFiles([fill[i]]).pipe(take(1)).subscribe(fileUploads => {
        this.uploadFileWithProgress(fileUploads, fill[i], fill[i]);
      });
    }
  }

  uploadFileWithProgress(files: any[], file, filesEvent) {
    this.redmineService.uploadRedmineFileV2(files[0], files[0].content).pipe(takeUntil(this.isAlive$)).subscribe(
      (res: any) => {
        const prepareAttachmentData = {
          "token": res.upload.token,
          "filename": files[0].filename,
          "content_type": files[0].content_type,
          iconType: this.checkVideoType(files[0].filename),
        };
        this.prepareFilePreview(filesEvent, prepareAttachmentData);
        if (CommonUtil.isHttpResponse(res)) {
          this.attachmentsList.push(res.body["upload"]);
        }

      }
    );

  }

  prepareFilePreview(event, dataToSave) {
    let imageUrl: string | ArrayBuffer | null;
    const file = event;

    if (file) {
      // Use FileReader to read the file content
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the result to the imageUrl, which can be bound to the <img> tag
        imageUrl = e.target?.result;
        dataToSave["thumbnail_url"] = imageUrl;
        dataToSave["filesize"] = event.size;
        this.attachmentsList.push(dataToSave);
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    }

    return imageUrl;
  }

  openInApp() {
    this.conversationRepo.createTicket(this.data.profileJid);
  }


  searchAssignee(searchQuery: string) {
    this.redmineService.projectAssignee(searchQuery).subscribe(assignee => {
      newTicketData.assigneeList = this.mergeData(newTicketData.assigneeList, assignee.users);
      this.assigneeOptionsList = newTicketData.assigneeList.filter(search => search.firstname.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
    });
  }

  getPrefilledUser(searchUser: string) {
    // this.redmineService.projectAssignee(searchUser).subscribe(assignee => {
    //   assignee.users.map(user => {
    //     if (user.jid === searchUser) {
    //       this.selectAssignee(user);
    //     };
    //   });
    // });
  }
  searchProject(searchQuery: string) {
    this.redmineService.projectList(searchQuery).subscribe(project => {
      newTicketData.projectList = this.mergeData(newTicketData["projectList"], project.results);
      this.projectList = newTicketData.projectList.filter(search => search.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
    });

    if (searchQuery === "") this.projectList = newTicketData.projectList;

  }

  mergeData(data: any[], data2: any[]): any[] {
    const result = [...data]; // Create a copy of data

    for (const item of data2) {
      // Check if an object with the same 'id' exists in the 'result' array
      const existingItem = result.find((dataItem) => dataItem.id === item.id);

      // If it doesn't exist, push it into the 'result' array
      if (!existingItem) {
        result.push(item);
      }
    }

    return result;
  }

  addTokenToAttachment(url: string) {
    return url = CommonUtil.addTokenToURL(CommonUtil.getAttachmentLocalAPIURL(url));
  }

  checkImageType(fileType: string) {
    return CommonUtil.isImage(fileType);
  }

  checkVideoType(fileType: string) {
    let extension = this.getFileExtension(fileType);
    if (extension === "pdf") return "pdfIcon";
    if (CommonUtil.isVideo(extension)) return "videoIcon";
    if (CommonUtil.isDocument(extension)) return "docIcon";
    if (CommonUtil.isAudio(extension)) return "audioIcon";
  }

  getFileExtension(filename: string): string | null {
    const parts = filename.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return null;
  }

  manipulateString(inputString) {
    inputString =  inputString
      .replace(/<\/?p>/g, "")
      .replace(/<strong>/g, " *").replace(/<\/strong>/g, "*")
      .replace(/<em>/g, " _").replace(/<\/em>/g, "_")
      .replace(/<u>/g, " +").replace(/<\/u>/g, "+")
      .replace(/<s>/g, " -").replace(/<\/s>/g, "-")
      .replace(/<p>/g, "<h1>").replace(/<\/p>/g, "</h1>")
      .replace(/<h1>/g, "<h2>").replace(/<\/h1>/g, "</h2>")
      .replace(/<h2>/g, "<h3>").replace(/<\/h2>/g, "</h3>");
      return inputString;
  }

  showTagInputData(){
   this.tagsListShow = true;
  }

  onChangeDropdownMenu(dropDownType, value) {
    if (value) {
      switch (dropDownType) {
        case "priority":
          this.ticketPriority.patchValue([value.value]);
          break;
        case "ticketType":
          this.ticketType.patchValue([value.value]);
          break;
        case "ticketDone":
          this.ticketDone.patchValue([value.value]);
          break;
        case "status":
          this.ticketStatus.patchValue([value.value]);
          break;
        default:
          break;
      }
    }
  }
}


interface ISelectOptions {
  value: any,
  viewValue: string
}

interface INewTicketData {
  project_id: number,
  tracker_id: number,
  status_id: number,
  priority_id: number,
  subject: string,
  description: string,
  start_date?: string,
  due_date?: string,
  assigned_to_id?: string,
  estimated_hours?: number,
}


