
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfigService } from "app/config.service";
import { Broadcaster } from "app/talk/shared/providers";
import { CommonUtil } from "app/talk/utils/common.util";
import { Subject, take } from "rxjs";


const TEXT_DOCUMENT_TYPES = ["doc", "docx", "doct", "odt", "gdoc", "txt", "rtf", "pdf", "mht", "htm", "html", "epub", "djvu", "xps", "oxps", "docm", "dot", "dotm", "dotx", "fodt", "ott", "fb2", "xml"];
const PRESENTATION_TYPES = ["pps", "ppsx", "ppt", "pptx", "odp", "pptt", "ppty", "gslides", "pot", "potm", "potx", "ppsm", "pptm", "fodp", "otp"];
const SPREADSHEET_TYPES = ["xls", "xlsx", "ods", "csv", "xlst", "xlsy", "gsheet", "xlsm", "xlt", "xltm", "xltx", "fods", "ots"];

@Component({
  selector: "vp-file-preview-dialog",
  templateUrl: "./file-preview-dialog.component.html",
  styleUrls: ["./file-preview-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewDialogComponent {

  attachment: any;
  isRenderableFile: boolean = false;
  isAPIUrlAvailable: boolean = false;
  onlyOfficeApiUrl: any;
  officeConfig = {
    editorConfig: {
      editorConfig: {
        customization: {
          anonymous: {
            request: false,
            label: "Guest"
          },
        },
      },
      document: {
        fileType: "",
        info: {
          author: "",
          created: "",
        },
        key: "",
        permissions: {
          reader: true,
          download: true,
          edit: false
        },
        title: "",
        url: "",
      },
      documentType: "text",
      anonymous: true,
      autosave: false,
      lang: localStorage.getItem("portalLanguage") || "en",
      mode: "view",
      embedded: {
        anonymous: true,
        autosave: false
      },
      height: "100%",
      type: "desktop",
      width: "100%",
    },
    script: "",
  };

  triggerEvent: Subject<any>;
  headerActions: { label: string, vncIconName: string, action: string }[];

  constructor(
    private dialogRef: MatDialogRef<FilePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { attachment: any, triggerEvent: Subject<any>, headerActions: any },
    private changeDetectionRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private config: ConfigService,
  ) {
    this.triggerEvent = data.triggerEvent;
    this.headerActions = data.headerActions;

    this.broadcaster.on<string>("closePreviewDialog").pipe(take(1)).subscribe(() => {
      this.dialogRef.close();
    });

    this.onlyOfficeApiUrl = this.config.get("onlyOfficeApiUrl");
    this.isAPIUrlAvailable = !!this.onlyOfficeApiUrl?.length;

    if (this.isAPIUrlAvailable && this.data?.attachment) {
      this.officeConfig = Object.assign({}, this.officeConfig, { script: this.onlyOfficeApiUrl });
      this.attachment = this.data.attachment;
      let documentType = this.getDocumentType(this.attachment.fileType);
      this.isRenderableFile = CommonUtil.isPreviewAvailableForType(this.attachment.fileType) && !!documentType;
      if (this.isRenderableFile) {
        this.officeConfig = CommonUtil.rmap(this.officeConfig, {
          editorConfig: {
            documentType: documentType,
            type: CommonUtil.isOnMobileDevice() ? "mobile" : "desktop",
            document: {
              fileType: this.attachment.fileType,
              title: this.attachment.fileName,
              url: this.attachment.url,
              key: new Date().getTime().toString()
            }
          }
        });
        // this.officeConfig["user"] = { group: "none", id: "1234", name: "vnctalkuser" };
      }

      // console.log("[FilePreviewDialogComponent] constructor: ", this.officeConfig, this.attachment, this.isRenderableFile);
      this.changeDetectionRef.markForCheck();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  getDocumentType(fileType: string): string | undefined {
    fileType = fileType.toLowerCase();
    if (TEXT_DOCUMENT_TYPES.includes(fileType)) {
      return "word";
    } else if (SPREADSHEET_TYPES.includes(fileType)) {
      return "cell";
    } else if (PRESENTATION_TYPES.includes(fileType)) {
      return "slide";
    } else {
      return;
    }
  }

}
