
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Broadcaster } from "../../shared/providers";
import { ContactsService } from "../../shared/providers/contacts.service";
import { Contact } from "../../shared/models/Contact";
import { JitsiService } from "../../services/jitsi.service";
import { ConferenceRepository } from "../../repositories/conference.repository";
import { CommonUtil } from "../../utils/common.util";
import { ConstantsUtil } from "app/talk/utils/constants.util";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { ConfigService } from "app/config.service";
import { NotificationService } from "app/talk/services/notification.service";
import { distinctUntilChanged, Subject, take, takeUntil } from "rxjs";
import { ContactRepository } from "app/talk/repositories/contact.repository";
import { LoggerService } from "app/shared/services/logger.service";

@Component({
  selector: "vp-participant",
  templateUrl: "./participant.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContactsService],
  host: {
    "(document:click)": "disableContextMenu()",
  }
})

export class VNCTalkParticipantComponent implements OnInit, OnDestroy, OnChanges {
  @Input() participant: any;
  @Input() size: string;
  @Input() isFullScreen: boolean;
  @Input() enableVideo: boolean;
  @Input() audioOnly: boolean;
  @Input() isFloating: boolean;
  @Input() videoOnly: boolean;
  currentVideoTrack: any;
  userJID: any;
  showHand: any;
  isPinned: boolean;
  isFlipped: boolean;
  hideAllVideo: any;
  @Input()
  set contextMenuParticipant(contextMenuParticipant: string) {
    this.participant.id !== contextMenuParticipant ? this.showContextMenu = false : null;
  }
  @ViewChild("videoArea", { static: false }) videoArea;
  @ViewChild("audioArea", { static: false }) audioArea;
  @ViewChild("speakingIcon", { static: false }) speakingIcon;
  @ViewChild("participantAvatar", { static: false }) participantAvatar;
  @Output() notifyContextMenu = new EventEmitter<string>();
  viewer: any = {};
  avatarData: any;
  onUpdateAvatar: any;
  onClientWebRtcPeer: any;
  contact: any = {};
  context: CanvasRenderingContext2D;
  participantId = "";
  displayName = "";
  isScreenSharingVideo: boolean;
  private isAlive$ = new Subject<boolean>();
  myUserId: any;
  hasVideo: boolean;
  hasAudio: boolean;
  showContextMenu: boolean = false;
  muteParticipantAudio: boolean = false;
  muteParticipantVideo: boolean = false;
  conferenceType: string;
  contextMenuX: string = 0 + "px";
  contextMenuY: string = 0 + "px";
  hideControls: boolean = false;
  result: string = "No";
  categoryColor: string = "#000000";
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };
  private tracksForParticipant$;
  isAnonymous = false;
  muteForMe = false;
  hover = false;
  isOnMobile = CommonUtil.isOnMobileDevice();

  constructor(private sanitizer: DomSanitizer,
    private broadcaster: Broadcaster,
    private contactsService: ContactsService,
    private contactRepo: ContactRepository,
    private configService: ConfigService,
    private notificationService: NotificationService,
    private jitsiService: JitsiService,
    private conferenceRepo: ConferenceRepository,
    private changeDetectionRef: ChangeDetectorRef,
    private logger: LoggerService,
    public dialog: MatDialog) {
    this.isAnonymous = this.configService.isAnonymous;
    this.size = "small";
  }

  ngOnInit() {
    this.logger.info("[VNCTalkParticipantComponent][ngOnInit]", this.participant);
    this.getDisplayName();
    this.setMediaStatus();
    this.myUserId = this.jitsiService.myUserId();
    this.userJID = this.conferenceRepo.userJID;
    this.categoryColor = ConstantsUtil.USER_BANDWIDTH_QUALITY.good_background;

    let confType = this.jitsiService.getConferenceTypeForParticipant(this.participantId);
    if (!confType) {
      this.conferenceType = ConstantsUtil.CONFERENCE_MEDIA_TYPE.none;
    } else {
      this.conferenceType = confType;
    }

    this.conferenceRepo.getRaisedHandList().pipe(takeUntil(this.isAlive$)).subscribe(participants => {
      this.showHand = participants[this.participantId];
      this.changeDetectionRef.markForCheck();
    });

    this.conferenceRepo.hideAllVideo$.pipe(takeUntil(this.isAlive$)).subscribe(isHidden => {
      this.logger.info("[hideAllVideo]", isHidden);
      if (this.hideAllVideo && !isHidden && this.hasVideo && !this.audioOnly) {
        this.jitsiService.getTracksForParticipant(this.participantId).pipe(take(1)).subscribe(tracks => {
          for (let track of tracks.filter(t => t.type === "video")) {
            this.jitsiService.attachTrack(track, this.participantId);
            this.currentVideoTrack = track;
          }
        });
      }
      this.hideAllVideo = isHidden;
      this.changeDetectionRef.markForCheck();
    });

    this.conferenceRepo.getMutedForMe().pipe(takeUntil(this.isAlive$)).subscribe(participants => {
      this.logger.info("[getMutedForMe]", participants);
      if (participants.includes(this.participantId)) {
        this.muteForMe = true;
      } else {
        this.muteForMe = false;
      }
      this.changeDetectionRef.markForCheck();
    });

    this.broadcaster.on<any>("onTrackMuteUnmute").pipe(takeUntil(this.isAlive$))
      .subscribe(track => {
        this.logger.info("[VNCTalkParticipantComponent][onTrackMuteUnmute] ", track, track.getParticipantId(), this.participantId, this.participant, track.type, track.isMuted());

        if (track.getParticipantId() === this.participantId) {
          if (track.type === ConstantsUtil.CONFERENCE_MEDIA_TYPE.audio) {
            this.muteParticipantAudio = track.isMuted();
          } else if (track.type === ConstantsUtil.CONFERENCE_MEDIA_TYPE.video) {
            this.muteParticipantVideo = track.isMuted();
          }
          if (track.type === "video" && !this.muteParticipantVideo) {
            this.logger.info("[VNCTalkParticipantComponent][onTrackMuteUnmute] attaching track ", track.getParticipantId(), track.type, track);
            this.jitsiService.attachTrack(track, this.participantId);
          }
          this.changeDetectionRef.markForCheck();
          this.logger.info("[VNCTalkParticipantComponent][onTrackMuteUnmute] ", this.muteParticipantAudio, this.muteParticipantVideo);

        }
      });

    this.broadcaster.on<any>("fakeParticipantInactive").pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.logger.info("[VNCTalkParticipantComponent][fakeParticipantInactive] ", this.participantId);
        this.isScreenSharingVideo = false;
        this.changeDetectionRef.markForCheck();
      });


    this.setParticipantsSubscriptions();

    this.conferenceRepo.getMutedForMe().pipe(takeUntil(this.isAlive$)).subscribe(participants => {
      this.logger.info("[getMutedForMe]", participants);
      let audioElement = <HTMLAudioElement>document.getElementById(`participantAudio${this.participantId}`);
      if (audioElement) {
        if (participants.includes(this.participantId)) {
          audioElement.muted = true;
        } else {
          audioElement.muted = false;
        }
        audioElement = null;
      }
    });

    this.conferenceRepo.getFullScreenParticipantId().pipe(takeUntil(this.isAlive$)).subscribe(participantId => {
      // this.logger.info("[VNCTalkParticipantComponent][getFullScreenParticipantId]", participantId);
      const isFullScreen = participantId === this.participantId;
      if (isFullScreen !== this.isFullScreen) {
        this.isFullScreen = isFullScreen;
        this.jitsiService.getTracksForParticipant(participantId).pipe(take(1)).subscribe(tracks => {
          for (let track of tracks.filter(t => t.type === "video")) {
            this.logger.info("[VNCTalkParticipantComponent][getFullScreenParticipantId]", participantId, track);
            if (track.videoType === "desktop" || track.videoType === "screen") {
              this.isScreenSharingVideo = true;
            } else {
              this.isScreenSharingVideo = false;
            }
          }
        });
        this.changeDetectionRef.markForCheck();
      }

    });
    this.jitsiService.getFlipStatus().pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        if (res && this.participantId === this.myUserId) {
          this.isFlipped = true;
          this.changeDetectionRef.markForCheck();
        } else {
          this.isFlipped = false;
          this.changeDetectionRef.markForCheck();
        }
      });
    this.broadcaster.on<any>("onVideoSourceChange").pipe(takeUntil(this.isAlive$))
      .subscribe(participantId => {
        if (this.participantId === participantId) {
          this.logger.info("onVideoSourceChange", participantId);
          this.jitsiService.getTracksForParticipant(participantId).pipe(take(1)).subscribe(tracks => {
            for (let track of tracks.filter(t => t.type === "video")) {
              this.logger.info("[VNCTalkParticipantComponent][onVideoSourceChange]", participantId, track);
              if (track.videoType === "desktop" || track.videoType === "screen") {
                this.isScreenSharingVideo = true;
              } else {
                this.isScreenSharingVideo = false;
              }
              if (!this.audioOnly) {
                // this.logger.info("onVideoSourceChange", this.currentVideoTrack, track, participantId);
                if (!this.currentVideoTrack || this.currentVideoTrack.track.id !== track.track.id) {
                  this.jitsiService.attachTrack(track, this.participantId);
                  this.currentVideoTrack = track;
                }
              }
            }
          });
          this.setMediaStatus();
          this.changeDetectionRef.markForCheck();
        }
      });
    this.jitsiService.pinnedParticipant$.asObservable().pipe(takeUntil(this.isAlive$))
      .subscribe(participantId => {
        this.isPinned = participantId === this.participantId;
        this.changeDetectionRef.markForCheck();
      });
    this.renderVideo();

    this.onUpdateAvatar = this.broadcaster.on<any>("updateAvatar")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(jid => {
        if (this.participant.name === jid.split("@")[0]) {
          this.getAvatar();
        }
      });

    this.broadcaster.on<any>(`onStatsUpdate${this.participantId}`).pipe(distinctUntilChanged(), takeUntil(this.isAlive$))
      .subscribe(res => {
        this.logger.info("[VNCTalkParticipantComponent] onStatsUpdate", this.participantId, res);

        const stats = res.stats;

        const connectionQuality = stats.connectionQuality;
        this.setColor(connectionQuality);

        this.changeDetectionRef.markForCheck();
      });

    this.broadcaster.on<any>("onConferenceTypeChangeForParticipant").pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        if (res.id === this.participantId) {
          // this.logger.info("[VNCTalkParticipantComponent][onConferenceTypeChangeForParticipant]", this.participantId, res);

          this.conferenceType = res.type;

          this.setMediaStatus();

          this.changeDetectionRef.markForCheck();
        }
      });

    this.broadcaster.on<any>("onScreenStarted").pipe(takeUntil(this.isAlive$))
      .subscribe(participantId => {
        if (participantId === this.participantId) {
          this.isScreenSharingVideo = true;
          this.changeDetectionRef.markForCheck();
        }
      });
  }

  setColor(connectionQuality) {
    if (connectionQuality === ConstantsUtil.USER_BANDWIDTH_QUALITY.good) {
      this.categoryColor = ConstantsUtil.USER_BANDWIDTH_QUALITY.good_background;
    } else if (connectionQuality === ConstantsUtil.USER_BANDWIDTH_QUALITY.average) {
      this.categoryColor = ConstantsUtil.USER_BANDWIDTH_QUALITY.average_background;
    } else {
      this.categoryColor = ConstantsUtil.USER_BANDWIDTH_QUALITY.poor_background;
    }
  }

  private setParticipantsSubscriptions() {
    if (this.tracksForParticipant$) {
      this.tracksForParticipant$.unsubscribe();
    }

    this.tracksForParticipant$ = this.jitsiService.getTracksForParticipant(this.participantId).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(tracks => {
      // this.logger.info("[VNCTalkParticipantComponent][getTracksForParticipantScreenTest]", this.participantId, this.size, this.jitsiService.isLocalId(this.participantId) ? "local" : "remote", tracks, this.audioOnly, this.videoOnly);
      this.setMediaStatus();
      tracks.forEach(track => {
        if (track.getType() === "video") {
          if (!this.audioOnly && (!this.currentVideoTrack || this.currentVideoTrack.id !== track.id)) {
            this.currentVideoTrack = track;
            this.logger.info("[setParticipantsSubscriptions] attachTrack", this.participantId);
            this.jitsiService.attachTrack(track, this.participantId);
          }
          if (track.videoType === "desktop" || track.videoType === "screen") {
            this.isScreenSharingVideo = true;
          } else {
            this.isScreenSharingVideo = false;
          }
        } else {
          if (!this.videoOnly) {
            this.jitsiService.attachTrack(track, this.participantId);
          }
        }
      });
      this.changeDetectionRef.markForCheck();
      const hasVideo = tracks.filter(track => track.getType() === "video").length > 0;
      const hasAudio = tracks.filter(track => track.getType() === "audio").length > 0;
      if (hasVideo !== this.hasVideo || this.hasAudio !== hasAudio) {
        this.hasVideo = hasVideo;
        this.hasAudio = hasAudio;
        this.changeDetectionRef.markForCheck();
      }
      // this.logger.info("[VNCTalkParticipantComponent][getTracksForParticipantScreenTest] hasVideo", this.participantId, this.size, hasVideo, this.hasVideo);
    });
  }

  private setMediaStatus() {
    let mediaStatus = this.jitsiService.getMediaStatus(this.participantId);
    // this.logger.info("[VNCTalkParticipantComponent][setMediaStatus] ", this.participantId, mediaStatus);

    if (mediaStatus === null) {
      this.muteParticipantAudio = false;
      this.muteParticipantVideo = false;
    } else {
      this.muteParticipantAudio = mediaStatus.audioStatus;
      this.muteParticipantVideo = mediaStatus.videoStatus;
    }
  }

  repositionVideoElements() {
    let largeVideoElement = <HTMLElement>document.querySelector("#largeVideo");
    this.logger.info("[VideoConferenceComponent][repositionVideoElements] largeVideoElement?", largeVideoElement, this.hideControls);
    if (!CommonUtil.isOnIpad()) {
      if (largeVideoElement) {
        largeVideoElement.style.height = this.hideControls ? "80%" : (CommonUtil.isOnIOSXFamily() ? "63%" : "58%");
        largeVideoElement.style.marginBottom = this.hideControls ? "80px" : (CommonUtil.isOnIOSXFamily() ? "110px" : "125px");
      }
    }

    const smallVideos = Array.from(document.querySelectorAll("#small_videos video"));
    this.logger.info("[VideoConferenceComponent][repositionVideoElements] smallVideos", smallVideos);

    for (let smallVideoElement of smallVideos) {
      (<HTMLElement>smallVideoElement).style.marginTop = CommonUtil.isOnIOSXFamily() ? "45px" : "20px";
    }

    setTimeout(() => {
      cordova.plugins.iosrtc.refreshVideos();
      this.logger.info("[VideoConferenceComponent][repositionVideoElements] iosrtc.refreshVideos");
    }, 50);
  }

  @HostListener("document:click")
  onDocumentClick(): void {
    this.disableContextMenu();
    return;
  }
  @HostListener("document:keyup", ["$event"]) handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.disableContextMenu();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.logger.info("[VNCTalkParticipantComponent][ngOnChanges] participant changed", this.size, changes);

    if (changes.participant && changes.participant.previousValue
      && changes.participant.previousValue !== changes.participant.currentValue) {
      this.currentVideoTrack = null;
      this.userJID = this.conferenceRepo.userJID;
      this.getAvatar();
      this.getDisplayName();

      this.setParticipantsSubscriptions();

      this.setMediaStatus();
    }
    this.conferenceRepo.getRaisedHandList().pipe(take(1)).subscribe(participants => {
      this.showHand = participants[this.participantId];
      this.changeDetectionRef.markForCheck();
    });
  }

  hideVideo() {
    let canvasSmall = document.getElementById("participant" + this.participantId);
    let canvasBig = document.getElementById("fullParticipant" + this.participantId);
    if (canvasSmall && canvasSmall !== null) {
      canvasSmall.style.visibility = "hidden";
    }
    if (canvasBig && canvasBig !== null) {
      canvasBig.style.visibility = "hidden";
    }
    canvasSmall = null;
    canvasBig = null;
  }

  renderVideo() {
    this.getAvatar();
  }

  ngOnDestroy() {
    this.logger.info("[VNCTalkParticipantComponent][ngOnDestroy]", this.participant);

    this.isAlive$.next(false);
    this.isAlive$.complete();

    if (this.onUpdateAvatar) {
      this.onUpdateAvatar.unsubscribe();
    }
    if (this.onClientWebRtcPeer) {
      this.onClientWebRtcPeer.unsubscribe();
    }
  }

  trustURL(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private getAvatar() {
    this.jitsiService.getTracksForParticipant(this.participantId).pipe(take(1)).subscribe(() => {
      if (this.participant && this.participant.name) {
        let local = this.participant.name.toLowerCase();
        let contact: Contact = this.contactsService.getContactByLocal(local);
        this.avatarData = "url(assets/img/avatar2.png)";
        if (contact && contact.avatarData) {
          this.avatarData = "url(data:image/png;base64," + contact.avatarData + ")";
        }
      }
    });
    if (this.contactsService.getMyAvatar()) {
      this.avatarData = "url(data:image/png;base64," + this.contactsService.getMyAvatar() + ")";
    }

    if (this.participantAvatar && this.participantAvatar.nativeElement) {
      this.participantAvatar.nativeElement.style.backgroundImage = this.avatarData;
    } else if (document.getElementById("participantAvatar" + this.participantId) != null) {
      let avatar = document.getElementById("participantAvatar" + this.participantId);
      avatar.style.backgroundImage = this.avatarData;
      avatar = null;
    } else if (document.getElementById("participantBigAvatar" + this.participantId) != null) {
      let avatar = document.getElementById("participantBigAvatar" + this.participantId);
      avatar.style.backgroundImage = this.avatarData;
      avatar = null;
    }
    // this.logger.info("[participant.getAvatar(" + this.participantId + ", " + this.participant.name + ")] = ", this.avatarData);
    return this.avatarData;
  }

  private getDisplayName() {
    if (this.participant) {
      this.participantId = this.participant.id;
      this.displayName = CommonUtil.convertDisplayNameToEmail(this.participant.name);
      if (this.displayName === "me") {
        this.displayName = this.conferenceRepo.userJID.bare;
      }
    }
  }

  toggleRemoteMediaAudio(shouldMute?: boolean) {
    this.logger.info("[toggleRemoteMediaAudio]:", this.participantId, shouldMute);
    this.jitsiService.toggleRemoteMedia("audio", this.participantId, shouldMute);
  }

  toggleRemoteMediaVideo(shouldMute?: boolean) {
    this.logger.info("[toggleRemoteMediaVideo]:", this.participantId, shouldMute);
    this.jitsiService.toggleRemoteMedia("video", this.participantId, shouldMute);
  }

  onContextMenu(event: MouseEvent): void {
    this.notifyContextMenu.emit(this.participantId);
    if (this.jitsiService.isModerator(this.jitsiService.myUserId()) === false) {
      this.showContextMenu = false;
    } else if (this.jitsiService.isModerator(this.participantId) === true) {
      this.showContextMenu = false;
    } else {
      this.showContextMenu = true;
      event.preventDefault();
      this.contextMenuX = event.clientX + "px";
      this.contextMenuY = event.clientY + "px";
    }
  }

  onContextMenuParticipant(event: MouseEvent) {
    this.logger.info("[onContextMenuParticipant");
    if (!this.isFloating && this.jitsiService.myUserId() !== this.participantId) {
      event.preventDefault();
      event.stopPropagation();
      this.contextMenuPosition.x = event.clientX + "px";
      this.contextMenuPosition.y = event.clientY + "px";
      this.contextMenu.menuData = { "item": this.participant };
      this.contextMenu.menu.focusFirstItem("mouse");
      this.contextMenu.openMenu();
    }
  }

  disableContextMenu() {
    this.notifyContextMenu.emit(this.participantId);
    this.showContextMenu = false;
  }

  async evictParticipant() {
    let name: string = this.participant.name;
    const { EvictConfirmationComponent } = await import(
      "../participant/evict-confirmation/evict-confirmation.component");
    const dialogRef = this.dialog.open(EvictConfirmationComponent, {
      width: "250px",
      data: name,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.result = result;
      if (result === "Yes") {
        this.conferenceRepo.kickParticipant(this.participantId, name);
      }
    });
  }
  isOwner() {
    let target;
    this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(v => {
      target = v;
    });
    return this.jitsiService.isOwner(target);
  }

  kickOut() {
    this.jitsiService.kickParticipant(this.participantId);
  }

  async removeFromMeeting() {
    if (this.jitsiService.isModerator(this.jitsiService.myUserId())) {
      const displayName = this.contactRepo.getFullName(this.displayName);
      const { ConferenceDialogComponent } = await import(
        "app/talk/shared/components/dialogs/conference-confirmation/conference-confirmation.component");
      this.dialog.open(ConferenceDialogComponent, {
        width: "390px",
        maxWidth: "95%",
        height: "200px",
        backdropClass: "delete-conference-backdrop",
        panelClass: "delete-conference-panel",
        disableClose: true,
        data: {
          action: "remove_from_meeting",
          displayName: displayName
        },
        autoFocus: true
      }).afterClosed().pipe(take(1)).subscribe(data => {
        if (!!data) {
          this.logger.info(" ConferenceDialogComponent", data);
          this.notificationService.openSnackBarWithTranslation("REMOVED_FROM_MEETING_MSG", { displayName: displayName });
          this.conferenceRepo.removeFromMeeting(this.displayName);
          this.jitsiService.kickParticipant(this.participantId);
        }
      });
    }
  }

  async muteRemoteMediaAudio() {
    if (this.jitsiService.isModerator(this.jitsiService.myUserId())) {
      const displayName = this.contactRepo.getFullName(this.participant.name);
      const { ConferenceDialogComponent } = await import(
        "app/talk/shared/components/dialogs/conference-confirmation/conference-confirmation.component");
      this.dialog.open(ConferenceDialogComponent, {
        width: "420px",
        maxWidth: "95%",
        height: "130px",
        backdropClass: "delete-conference-backdrop",
        panelClass: "delete-conference-panel",
        disableClose: true,
        data: {
          action: "mute_for_everyone",
          type: "mute",
          displayName: displayName
        },
        autoFocus: true
      }).afterClosed().pipe(take(1)).subscribe(data => {
        if (!!data) {
          this.jitsiService.toggleRemoteMedia("audio", this.participantId);
          this.notificationService.openSnackBarWithTranslation("MUTED_FOR_EVERYONE_MSG", { displayName: displayName });
        }
      });
    }

  }
  muteAudioForMe() {
    this.conferenceRepo.muteForMe(this.participantId);
  }

  unMuteAudioForMe() {
    this.conferenceRepo.unMuteForMe(this.participantId);
  }

  unMuteRemoteMediaAudio() {
    this.jitsiService.toggleRemoteMedia("audio", this.participantId);
  }

  sendMessage() {
    this.broadcaster.broadcast("sendMessageParticipant", this.participant.name);
  }

  allowToSendXMPP() {
    return this.conferenceRepo.shouldSendCallSignal(this.participant.name);
  }

  pinParticipant() {
    this.conferenceRepo.togglePinParticipant(true, this.participantId, "thumbnail");
  }

  unpinParticipant() {
    this.conferenceRepo.togglePinParticipant(false, this.participantId, "thumbnail");
  }

  wakeUp() {
    this.conferenceRepo.wakeUp(this.participantId);
  }
  changeRole() {
    this.broadcaster.broadcast("changeRole", this.participantId);
  }
}
