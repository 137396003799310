/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Topic } from "app/channels/models/topic.model";
import { Action } from "../actions";
import { SubscribeTopicActionTypes } from "../actions/subscribe_topic";

export interface SubscribeTopicState extends EntityState<Topic> {
  isLoading: boolean;
  isLoaded: boolean;
  allSubscribeTopicsInfo: { totalCount: number, offset: number, isLoading: boolean, isLoaded: boolean};
}

export const subscribeTopicAdapter: EntityAdapter<Topic> = createEntityAdapter<Topic>({
  selectId: (topic: Topic) => topic.id
});


export const initialState: SubscribeTopicState = subscribeTopicAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  allSubscribeTopicsInfo: {offset: 0, totalCount: 0, isLoading: false, isLoaded: false}
});


export function subscribeTopicReducer(state: SubscribeTopicState = initialState, action: Action): SubscribeTopicState {
  switch (action.type) {

    case SubscribeTopicActionTypes.SUBSCRIBE_TOPIC_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case SubscribeTopicActionTypes.SUBSCRIBE_TOPICLOAD_SUCCESS: {
      const topics = action.payload.map(t => {
        let topic = state.entities[t.id];
        if (topic) {
          t = { ...t, ...topic };
        }
        return t;
      });
      return subscribeTopicAdapter.setAll(topics, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case SubscribeTopicActionTypes.SUBSCRIBE_TOPIC_ADD: {
      const newState = subscribeTopicAdapter.addOne(action.payload, state);
      return subscribeTopicAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }

    case SubscribeTopicActionTypes.SUBSCRIBE_TOPIC_DELETE: {
      return subscribeTopicAdapter.removeOne(action.payload, state);
    }

    case SubscribeTopicActionTypes.SUBSCRIBE_ALL_TOPICS_INFO_UPDATE: {
      return {
        ...state,
        allSubscribeTopicsInfo: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getSubscribeTopicIsLoading = (state: SubscribeTopicState) => state.isLoading;
export const _getSubscribeTopicIsLoaded = (state: SubscribeTopicState) => state.isLoaded;
export const _getAllSubscribeTopicsInfo = (state: SubscribeTopicState) => state.allSubscribeTopicsInfo;
