<vnc-dialog [classes]="[' send-message-to-chats-container delete-channel confirmation-channel-item-component ']" headerText="{{ headerText | translate }}"
    classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>

<ng-template #bodyTemplate>

  <div [innerHTML]="bodyText | translate">
  </div>

<div class="vnc-users-block vnc-scrollbar-verical-show-on-hover">
  <div class="vnc-selected-users-list" *ngIf="data?.externalUsers$?.length > 0">
    <vnc-chips *ngFor="let user of data?.externalUsers$" text="{{user.fullName}}" [item]="user"
      [avatarTemplate]="avatarTemplateRole" [imgSrc]="avatarTemplateRole" (removeIconClick)="removeUser(user)"
      [removable]=true size="m"></vnc-chips>
  </div>
</div>

</ng-template>

<ng-template #footerTemplate>
    <div class="footer-actions" [class.is-mobile-footer]="isMobileScreen && data?.leftAction !== confirmationPopupActions.SAVE_AS_DRAFT">
      <vnc-action-link *ngIf="!isMobileScreen && data?.leftAction === confirmationPopupActions.SAVE_AS_DRAFT"
                       [leadingIconTemplate]="saveIconTemplate"
                       [label]="'SAVE_AS_DRAFT' | translate"
                       (actionClicked)="close(confirmationPopupActions.SAVE_AS_DRAFT)"></vnc-action-link>
      <vnc-secondary-button *ngIf="!isMobileScreen"
          [size]="'m'"
          label="{{ cancelLabel | translate }}"
          (onClick)="close('no')"
      ></vnc-secondary-button>
      <vnc-link-button *ngIf="isMobileScreen && data?.leftAction === confirmationPopupActions.SAVE_AS_DRAFT" [size]="'xs'" [label]="getLabel() | translate" (onClick)="close(confirmationPopupActions.SAVE_AS_DRAFT)"></vnc-link-button>
      <vnc-link-button *ngIf="isMobileScreen" [size]="'xs'" [label]="cancelLabel | translate" (onClick)="close('no')"></vnc-link-button>
      <vnc-primary-button
          [size]="!isMobileScreen ? 'm' : 'xs'"
          (onClick)="close('yes')"
          [isDisabled]="disabled"
      ><span>{{ okLabel | translate }}</span>
      </vnc-primary-button>
    </div>
</ng-template>


<ng-template #saveIconTemplate>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/save-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(3.000000, 3.000000)" fill="#8B96A0">
            <path d="M12.4409503,0 C13.1996067,0 14.1221565,0.356502196 14.7133941,0.865144645 L14.8540146,0.995356581 L17.0046434,3.14598541 C17.5356921,3.67703413 17.9344577,4.5865786 17.992664,5.36694348 L18,5.55904966 L18,15.2222222 C18,16.6956158 16.8429966,17.9071893 15.3910182,17.9949144 L15.2222222,18 L2.77777778,18 C1.29803014,18 0.0923399857,16.845133 0.00505943634,15.3912556 L0,15.2222222 L0,2.77777778 C0,1.3021656 1.15088468,0.0926463164 2.60830194,0.00507645471 L2.77777778,0 L12.4409503,0 Z M12.4409503,2 L2.77777778,2 C2.38311423,2 2.05845114,2.29278406 2.00708134,2.67228295 L2,2.77777778 L2,15.2222222 C2,15.6157844 2.29177446,15.9413653 2.67203055,15.9928957 L2.77777778,16 L15.2222222,16 C15.6122575,16 15.9407775,15.703983 15.9928222,15.3269088 L16,15.2222222 L16,5.55904966 C16,5.30603161 15.8389894,4.8836166 15.665241,4.64847056 L15.5904299,4.56019897 L13.439801,2.40957014 C13.2637917,2.23356084 12.8549817,2.05199068 12.5595923,2.00934828 L12.4409503,2 Z M9,7 C11.2122847,7 13,8.78771525 13,11 C13,13.2122847 11.2122847,15 9,15 C6.78771525,15 5,13.2122847 5,11 C5,8.78771525 6.78771525,7 9,7 Z M9,9 C7.89228475,9 7,9.89228475 7,11 C7,12.1077153 7.89228475,13 9,13 C10.1077153,13 11,12.1077153 11,11 C11,9.89228475 10.1077153,9 9,9 Z M11,3 C11.5522847,3 12,3.44771525 12,4 C12,4.55228475 11.5522847,5 11,5 L4,5 C3.44771525,5 3,4.55228475 3,4 C3,3.44771525 3.44771525,3 4,3 L11,3 Z" id="Combined-Shape"></path>
        </g>
    </g>
  </svg>
</ng-template>

<ng-template #avatarTemplateRole let-item="item">
  <vp-avatar size="custom" [jid]="item.jid" [hidestatus]="true"></vp-avatar>
  <div class="role-icon" [class.red]="true">
      <mat-icon *ngIf="item?.role === 'moderator'" fontSet="mdi" fontIcon="mdi-star" class="moderator-icon"></mat-icon>
      <mat-icon *ngIf="item?.role !== 'moderator'" fontSet="mdi" fontIcon="mdi-account" class="participant-icon"></mat-icon>
  </div>

</ng-template>
