/* eslint no-console: 0 */
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { CanActivate, CanActivateChild } from "@angular/router";
import { ConfigService } from "../../../config.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class TalkAuthGuard implements CanActivate, CanActivateChild {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private http: Http, private configService: ConfigService) {
    console.trace("[auth-guard.ctor]"); // FIXME: is this ever used?
  }

  canActivate() {

    console.trace("[auth-guard.canActivate]"); // FIXME: is this ever called
    if (this.getSecret() !== null) {
      let headers = new Headers({ "Content-Type": "application/json" });
      if (this.isCordovaOrElectron) {
        headers = new Headers({ "Content-Type": "application/json", "Authorization": localStorage.getItem("token") });
      }
      this.http.get(this.configService.API_URL + "/api/profile", { headers: headers }).subscribe(data => {
        if (data === null) {
          this.configService.redirectToLoginScreen();
        }
      });
      return true;
    }
    return false;
  }

  canActivateChild() {
    return this.canActivate();
  }

  getSecret() {
    console.trace("[auth-guard.getSecret]");
    return localStorage.getItem("token");
  }
}
