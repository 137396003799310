
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, InjectionToken, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs";

@Component({
  selector: "vp-electron-screen-sharing-dialog",
  styles: [`
      vnc-dialog_body {
        padding: 16px;
      }
  `],
  templateUrl: "./electron-screen-sharing-dialog.html"
})
export class ElectronScreenSharingComponent {
  static DESKTOP_SOURSES = new InjectionToken("desktopSources");
  desktopSources: any[];
  selectedOption = "";

  constructor(
    private dialog: MatDialogRef<ElectronScreenSharingComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) desktopSources
  ) {
    desktopSources.forEach(source => {
      if (source.id.startsWith("screen")) {
        if (source.name === "Entire Screen") {
          this.translate.get("SCREEN_SHARE_ENTIRESCREEN").pipe(take(1)).subscribe(text => {
            source.name = text;
          });
        }
        if (source.name.startsWith("Screen")) {
          const num = source.name.split(" ")[1];
          this.translate.get("SCREEN_SHARE_SCREEN").pipe(take(1)).subscribe(text => {
            source.name = text + " " + num;
          });
        }
      }
    });
    this.desktopSources = desktopSources;
  }

  onSelectScreen(event: Event, sourceId: string) {
    event.preventDefault();
    this.dialog.close(sourceId);
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
    // this.conferenceRepo.unshareScreenAndStartNewStream();
  }

}
