import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {CommonUtil} from "../utils/common.util";
import {scan, take} from "rxjs/operators";
import {ToastService} from "../../shared/services/toast.service";
import {ElectronService} from "../../shared/providers/electron.service";
import {VNCTalkNotificationsService} from "../notifications";
import {File} from "../../channels/models/file.model";
import {TranslateService} from "@ngx-translate/core";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {HttpClient, HttpEvent} from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";
import { LoggerService } from "app/shared/services/logger.service";


@Injectable()
export class FileActionsService {
  private currentDownloadPromise;
  constructor(private _toastService: ToastService,
              private logger: LoggerService,
              private _electronService: ElectronService,
              private _notificationsService: VNCTalkNotificationsService,
              private _httpClient: HttpClient,
              private _translate: TranslateService,
              private channelSnackbarService: ChannelSnackbarService) {
  }


  downloadFile(file: File) {
    try {
      if (environment.isCordova) {
        let headers = [{
            Key: "Authorization",
            Value: localStorage.getItem("token")
          }];
        this.downloadAndSaveFileInBackground(file.content_url, headers, CommonUtil.isOnIOS()).subscribe((localFileUrl) => {
          this.logger.info("[MessageComponent][downloadInBackgroundFileAsBlob] success, localFileUrl: ", localFileUrl);
          if (CommonUtil.isOnAndroid()) {
            this._translate.get("FILE_DOWNLOADED").pipe(take(1)).subscribe(text => {
              alert(text);
            });
          } else {
            this.openLocalFile(localFileUrl);
          }
        }, err => {
          this.logger.error("[MessageComponent][downloadInBackgroundFileAsBlob] err", err);
          this._toastService.show("SOME_UNKNOWN_ERROR");
        });
        this._toastService.show("FILE_STARTED_DOWNLOADING");
      } else if (environment.isElectron) {
        this._translate.get("DOWNLOADING_FILES").pipe(take(1)).subscribe((text: string) => {
          this.channelSnackbarService.openSnackBar(text, "loader");
        });
        this._electronService.fileDownloader(file.content_url).subscribe(() => {
          this._translate.get("FILES_DOWNLOAD_SUCCESSFULLY").pipe(take(1)).subscribe((text: string) => {
            this.channelSnackbarService.openSnackBar(text, "checkmark");
          });
          // this._translate.get("FILE_DOWNLOADED")
          //   .subscribe(text => this._notificationsService.html("", text, "notify", {timeOut: 2000}));
        });
      } else {
        // Utils.downloadFileViaUrl(file.content_url);
        this.download(file.content_url).subscribe(() => { });
      }
    } catch (e) {
      this._toastService.show("SOME_UNKNOWN_ERROR");
    }
  }

  downloadFilesAsZIP(files: File[], title: string) {
    this._translate.get("DOWNLOADING_FILES").pipe(take(1)).subscribe((text: string) => {
      this.channelSnackbarService.openSnackBar(text, "loader");
    });
    const getFile = async (url: string) => {
      const httpOptions = { responseType: "blob" as "json" };
      return await this._httpClient.get(url, httpOptions).toPromise().catch(err => err.error);
    };

    const zip = new JSZip();
    for (const file of files) {
      const data = getFile(file?.is_iom ? file.content_url : CommonUtil.getAttachmentLocalAPIURL(file.content_url));
      zip.file(file.filename, data);
    }
    zip.generateAsync({ type: "blob" }).then((blob) => {
      FileSaver.saveAs(blob, title);
      this._translate.get("FILES_DOWNLOAD_SUCCESSFULLY").pipe(take(1)).subscribe((text: string) => {
        this.channelSnackbarService.openSnackBar(text, "checkmark");
      });
    });
  }

  copyFileLink(file: File) {
    const contentUrl = file?.is_iom ? file?.content_url : CommonUtil.getPrivateOriginAttachmentLocalAPIURL(file?.content_url);
    CommonUtil.copyToClipboard([encodeURI(contentUrl)]);
    this._toastService.showMatSnackBar("LINK_COPIED_TO_THE_CLIPBOARD");
  }

  getFileLink(file: File) {
    const contentUrl = file?.is_iom ? file?.content_url : CommonUtil.getPrivateOriginAttachmentLocalAPIURL(file?.content_url);
    return contentUrl;
  }

  private openLocalFile(localFileUrl: string) {
    let mimeType = CommonUtil.getMediaType(localFileUrl);
    this.logger.info("[MessageComponent][openLocalFile] localFileUrl: " + localFileUrl + ", mimeType: " + mimeType);

    if (CommonUtil.isOnAndroid()) {
      // this plugin is only for Android
      cordova.plugins.FileOpener.canOpenFile(localFileUrl, () => {
        cordova.plugins.FileOpener.openFile(localFileUrl, () => {
          this.logger.info("[MessageComponent][openLocalFile] success");
        }, (error) => {
          this.logger.error("[MessageComponent][openLocalFile] openFile error", error);
          this._toastService.show("SOME_UNKNOWN_ERROR");
        });
      }, (error) => {
        this.logger.error("[MessageComponent][downloadFileAttachment] canOpenFile error", error);
        this._toastService.show("FILE_DOWNLOADED");
      });

    // iOS
    } else {
      this.logger.info("[MessageComponent][downloadFileAttachment] showOpenWithDialog", localFileUrl, mimeType);
      if (CommonUtil.isOnIpad()) {
        mimeType = CommonUtil.getMimeType(localFileUrl);
        this.logger.info("[MessageComponent][downloadFileAttachment] showOpenWithDialog", localFileUrl, mimeType);
        cordova.plugins.fileOpener2.open(
          localFileUrl,
          mimeType,
          {
              error : (e)  => {
                  this.logger.error("[MessageComponent][downloadFileAttachment] Error status: " + e.status + " - Error message: " + e.message);
              },
              success : () => {
                  this.logger.info("[MessageComponent][downloadFileAttachment] file opened successfully");
              }
          }
      );
      } else {
        cordova.plugins.fileOpener2.showOpenWithDialog(
          localFileUrl,
          mimeType,
          {
              error : (e)  => {
                  this.logger.error("[MessageComponent][downloadFileAttachment] Error status: " + e.status + " - Error message: " + e.message);
              },
              success : () => {
                  this.logger.info("[MessageComponent][downloadFileAttachment] file opened successfully");
              }
          }
        );
      }

    }
  }

  downloadAndSaveFileInBackground(fileServerUrl, headers = [], isIOS = false): Observable<string> {
    const response = new Subject<string>();
    let localFileName = fileServerUrl.substring(fileServerUrl.lastIndexOf("/") + 1);
    if (localFileName.includes("?token=")) {
      localFileName = localFileName.substr(0, localFileName.lastIndexOf("?token="));
    }
    this.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob]", fileServerUrl, localFileName, isIOS);
    if (isIOS) {
      window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (dir) => {

         // https://github.com/pwlin/cordova-plugin-file-opener2/issues/14
        this.logger.info("[FilesStorageService][downloadAndSaveFileInBackground] localFileName origin: ", localFileName);
        localFileName = decodeURIComponent(localFileName);
        localFileName = localFileName.replace(/[^a-zA-Z0-9-_.]/g, "_");
        localFileName = encodeURIComponent(localFileName);
        this.logger.info("[FilesStorageService][downloadAndSaveFileInBackground] localFileName processed: ", localFileName);

        dir.getFile(localFileName, { create: true, exclusive: false},  (targetFile) => {
          this.logger.info("[FilesStorageService][downloadAndSaveFileInBackground] getFile, targetFile: ", JSON.stringify(targetFile));

          this.backgroundFileDownload(fileServerUrl, targetFile, headers).subscribe(() => {
            const localFileUrl = this.privateFilePath(localFileName);
            response.next(localFileUrl);
          }, err => {
            response.error(err);
          });
        });
      }, (err) => {
        this.logger.error("[FilesStorageService][downloadAndSaveFileInBackground] resolveLocalFileSystemURL error", err);
        response.error(err);
      });
    } else {
      window.resolveLocalFileSystemURL(cordova.file.externalRootDirectory, (directoryEntry) => {
        this.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] directoryEntry", directoryEntry);
        directoryEntry.getDirectory("Download", {create: true, exclusive: false}, () => {
          this.logger.info("Download folder create/exists");
          const localFileUrl = this.androidDownloadFolderFilePath(localFileName);
          directoryEntry.getFile(localFileUrl, { create: true, exclusive: false }, (fileEntry) => {
          this.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] getFile", fileEntry, fileEntry.toURL());
          const fileTransfer = new FileTransfer();
          const fileURL = fileEntry.toURL();
            fileTransfer.download(
              fileServerUrl,
              fileURL,
              function (entry) {
                this.logger.info("Successful download...");
                this.logger.info("download complete: " + entry.toURL());
                response.next(entry.toURL());
              },
              function (error) {
                this.logger.info("download error source " + error.source);
                this.logger.info("download error target " + error.target);
                this.logger.info("upload error code" + error.code);
                response.error(error);
              },
              null, // or, pass false
              {
                headers: headers
              }
            );
          }, (err) => {
            this.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] getFile err", err);
            response.error(err);
          });
        }, () => {
          this.logger.info("Error on Download Folder");
        } );
      }, (err) => {
        this.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] resolveLocalFileSystemURL error", err);
        response.error(err);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  private backgroundFileDownload(fileServerUrl, targetFile, headers = []) {
    this.logger.info("[FilesStorageService][backgroundFileDownload]", fileServerUrl);

    const response = new Subject<any>();

    const onSuccess = () => {
      this.logger.info("[FilesStorageService][backgroundFileDownload] onSuccess");
      response.next(true);
    };
    const onError = (err) => {
      this.logger.error("[FilesStorageService][backgroundFileDownload] onError", err);
      response.error(err);
    };
    const onProgress = (progress) => {
      this.logger.info("[FilesStorageService][backgroundFileDownload] onProgress", progress);
    };

    const downloader = new BackgroundTransfer.BackgroundDownloader();
    const download = downloader.createDownload(fileServerUrl, targetFile, null, headers);

    // Start the download and persist the promise to be able to cancel the download.
    this.currentDownloadPromise = download.startAsync().then(onSuccess, onError, onProgress);

    return response.asObservable().pipe(take(1));
  }

  privateFilePath(fileName: string){
    return cordova.file.dataDirectory + fileName;
  }

  androidDownloadFolderFilePath(fileName: string){
    return `Download/${fileName}`;
  }

  androidExternalDataDirectoryFilePath(fileName: string){
    // return cordova.file.externalDataDirectory + "vnctalk_downloads/" + fileName;
    return cordova.file.externalDataDirectory + fileName;
  }

  download(url: string, filename?: string): Observable<any> {
    this._translate.get("DOWNLOADING_FILES").pipe(take(1)).subscribe((text: string) => {
      this.channelSnackbarService.openSnackBar(text, "loader");
    });
    return this._httpClient.get(url, {
      reportProgress: true,
      observe: "events",
      responseType: "blob"
    }).pipe(this.downloadFromBlob(blob => {
      const fname  = url.substring( url.lastIndexOf("/") + 1, url.length);
      const fileNameWithoutExtn = fname.substring(0, fname .lastIndexOf("."));
      this.logger.info("[downloadFromBlob][fileNameWithoutExtn]", filename, fileNameWithoutExtn);
      this._translate.get("FILES_DOWNLOAD_SUCCESSFULLY").pipe(take(1)).subscribe((text: string) => {
        this.channelSnackbarService.openSnackBar(text, "checkmark");
      });
      FileSaver.saveAs(blob, fileNameWithoutExtn);
    }));
  }

  downloadFromBlob(saver?: (b: Blob) => void ): (source: Observable<HttpEvent<Blob>>) => Observable<any> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan((previous: any, event: HttpEvent<Blob>): any => {
            if (CommonUtil.isHttpProgressEvent(event)) {
              return {
                state: "IN_PROGRESS",
                content: null
              };
            }
            if (CommonUtil.isHttpResponse(event)) {
              if (saver && event.body) {
                saver(event.body);
              }
              return {
                progress: 100,
                state: "DONE",
                content: event.body
              };
            }
            return previous;
          },
          {state: "PENDING", progress: 0, content: null}
        )
      );
  }
}
