
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="user-profile"
     [ngClass]="{'upload-avatar': showUploadForm, 'participant': jidBare !== loggedInUserJID.bare}">
    <vnc-header *ngIf="!isOnMobile() && !showUploadForm" style="width:100%;"
     title="{{ 'PROFILE' | translate }}"
     type="title-close"
     size="l"
     (onCloseClick)="onEsc()"
     [showCloseIcon]="true">
 </vnc-header>
  <div class="mdl-dialog__header" *ngIf="isOnMobile()  && !showUploadForm">
    <button id="retPrevMenuBtn" class="hide-on-desktop" mat-icon-button (click)="onEsc()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <h3 id="profileScreenTitle" class="mdl-dialog__title header-title">
      {{ 'PROFILE'|translate }}
    </h3>
    <span></span>
    <button class="show-on-desktop close-dialog-btn"
            id="retPrevMenuBtn"
            mat-icon-button (click)="onEsc()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="profile-container">
    <div class="user-profile__information" *ngIf="!showUploadForm">
      <div class="user-profile__avatar">
        <vp-avatar [atComponent]="'profile'" [hidestatus]="true" [size]="'incomingCall'" [jid]="jidBare"></vp-avatar>

        <button class="change-avatar-button" id="changeAvatarBtn" *ngIf="loggedInUserJID && jidBare === loggedInUserJID.bare" mat-icon-button
              (click)="changeAvatar()"><mat-icon>photo_camera</mat-icon></button>
      </div>
      <div id="userName" class="user-profile__name">
          {{ vCard && vCard.fullName ? vCard.fullName : fullName }}
      </div>
    </div>


    <div class="user-profile__actions top-actions" *ngIf="!showUploadForm"
         [ngClass]="{'me': loggedInUserJID && jidBare === loggedInUserJID.bare}">
      <div class="actions" *ngIf="loggedInUserJID && jidBare !== loggedInUserJID.bare">
        <button id="profileScreenAudioCall" mat-button (click)="startCall('audio')">
          <mat-icon>call</mat-icon> <br>
          <span>{{ 'AUDIO_CALL' | translate }}</span>
        </button>
        <button id="profileScreenVideoCall" mat-button (click)="startCall('video')"
          [disabled]="isOnSafari"
          [ngStyle]="{'opacity': isOnSafari ? 0.5 : 1}">
          <mat-icon>videocam</mat-icon> <br>
          <span>{{ 'VIDEO_CALL' | translate }}</span>
        </button>
        <button id="profileScreenChat"  mat-button (click)="sendMessage()">
          <mat-icon>chat_bubble</mat-icon> <br>
          <span>{{ 'CHAT_TEXT' | translate }}</span>
        </button>
        <button class="meeting-invite" [disabled]="true" mat-button (click)="meetingInvite()">
          <mat-icon>event</mat-icon> <br>
          <span [innerHTML]="'MEETING_INVITE' | translate"></span>
        </button>
        <button class="more-actions" [disabled]="true" mat-button>
            <mat-icon>more_horiz</mat-icon><br>
            <span></span>
          </button>
      </div>
      <button id="editUserProfileBtn" *ngIf="enableEditProfile && loggedInUserJID && jidBare === loggedInUserJID.bare" class="edit-profile-btn"  mat-icon-button (click)="editProfile()">  <mat-icon>edit</mat-icon>  </button>
    </div>

    <div class="contact-information" *ngIf="vCard && !showUploadForm">
      <h4>{{ 'CONTACT_DETAIL'|translate }}</h4>
      <div class="setting-group email-setting" id="emails" *ngIf="!vCard.emails">
          <div class="form-control">
              <div class="form-icon">
                  <mat-icon>email</mat-icon>
              </div>
              <div class="form-group">
                  <span id="userEmail" class="email">{{ jidBare }}</span>
              </div>

          </div>
      </div>
      <div class="setting-group email-setting" id="emails" *ngIf="!!vCard.emails">
          <div class="form-control" *ngFor="let emailForm of vCard.emails; let i=index">
              <div class="form-icon">
                  <mat-icon *ngIf="i===0">email</mat-icon>
              </div>
              <div class="form-group">
                  <span class="email">{{ emailForm.email }}</span>
                  <span id="userEmail" class="form-type" *ngIf="emailForm.emailType"> <span class="dot-separator"><mat-icon>lens</mat-icon></span>
                      {{ emailForm.emailType | translate }}
                  </span>
              </div>

          </div>
      </div>

      <div class="setting-group phone-setting" id="phoneNumbers" *ngIf="!!vCard.phoneNumbers">
          <div class="form-control" *ngFor="let phone of vCard.phoneNumbers; let j=index">
              <ng-container *ngIf="!!phone.number">
                  <div class="form-icon">
                      <mat-icon *ngIf="j===0">phone</mat-icon>
                  </div>
                  <div class="form-group">
                      <span label="Phone">{{ phone.number }}</span>
                      <span class="form-type" *ngIf="phone.phoneType"> <span class="dot-separator"><mat-icon>lens</mat-icon></span>
                          {{ phone.phoneType | translate }}
                      </span>
                  </div>
              </ng-container>


          </div>
      </div>
      <!-- list of addresses -->
      <div class="setting-group address-setting" id="addresses" *ngIf="!!vCard.addresses">
          <div class="form-control" *ngFor="let address of vCard.addresses; let k=index">
              <ng-container *ngIf="!!address.street || !!address.postalCode || !!address.city || !!address.country">
                  <div class="form-icon">
                      <mat-icon *ngIf="k===0">place</mat-icon>
                  </div>
                  <div class="form-group">
                      <span label="Street and number" *ngIf="address.street" class="street">{{ address.street }}</span>
                      <div class="form-grid" *ngIf="address.postalCode || address.city">
                          <span label="Zip" class="postalCode" *ngIf="address.postalCode">{{ address.postalCode }}</span>
                          <span label="City" class="city"  *ngIf="address.city">{{ address.city }}</span>
                      </div>
                      <div class="form-grid">
                          <span label="Country" class="country" *ngIf="address.country">{{ address.country }}</span>
                          <span class="form-type" *ngIf="address.country"> <span class="dot-separator"><mat-icon>lens</mat-icon></span>
                              {{ address.addressType | translate }}
                          </span>
                      </div>

                  </div>
              </ng-container>
          </div>
      </div>
    </div>
    <div class="contact-information" *ngIf="!vCard && !showUploadForm">
      {{ 'LOADING_CONTACT' | translate}}
    </div>

  </div>
  <vnc-header *ngIf="!isOnMobile() && showUploadForm" style="width:100%;"
     title="{{ 'PROFILE_PHOTO' | translate }}"
     type="title-close"
     size="l"
     (onCloseClick)="onEsc()"
     [showCloseIcon]="true">
 </vnc-header>
  <div class="mdl-dialog__header" *ngIf="isOnMobile() &&  showUploadForm">
    <button id="leaveProfilePhoto" class="hide-on-desktop" mat-icon-button (click)="onEsc()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <h3 class="mdl-dialog__title header-title">
      <mat-icon class="show-on-desktop">photo_camera</mat-icon>
      {{ 'PROFILE_PHOTO'|translate }}
    </h3>
    <span></span>
    <button class="show-on-desktop close-dialog-btn" mat-icon-button (click)="showUploadForm = false">
      <mat-icon>close</mat-icon>
    </button>
    <button id="saveProfilePhoto" class="hide-on-desktop close-dialog-btn" mat-icon-button (click)="updateAvatar()">
      <mat-icon>check</mat-icon>
    </button>
  </div>

  <div class="user-profile__upload-avatar" *ngIf="showUploadForm">
    <vp-update-avatar hideHeader="true" (changedAvatar)="changedAvatar($event)" [userProfile]="{email: loggedInUserJID}"></vp-update-avatar>
  </div>
</div>
