
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, HostListener, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Broadcaster } from "app/talk/shared/providers";
import { Subject, takeUntil } from "rxjs";
import { Member } from "../models/member.model";

@Component({
  selector: "vp-confirmation-channel",
  templateUrl: "./confirmation-channel.component.html",
  styleUrls: ["./confirmation-channel.component.scss"]
})
export class ConfirmationChannelComponent implements OnInit, OnDestroy {
  okLabel = "DELETE";
  cancelLabel = "CANCEL";
  bodyText = "";
  headerText = "";
  author_name: string;
  channel_name: string;
  disabled: boolean;
  isMobileScreen: boolean = false;
  private isAlive$ = new Subject<boolean>();

  confirmationPopupActions = ConfirmationPopupActions;

  constructor(
    private translate: TranslateService,
    private matDialogRef: MatDialogRef<ConfirmationChannelComponent>,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private broadcaster: Broadcaster) {
    if (!!this.data.author_name) {
      this.author_name = this?.data?.author_name;
      this.translate.get(this.data.bodyText, { author_name: this.author_name })
        .subscribe(res => {
          this.bodyText = res;
        });
    } else if (!!this.data.channel_name) {
      this.channel_name = this?.data?.channel_name;
      this.translate.get(this.data.bodyText, { channelName: this.channel_name })
        .subscribe(res => {
          this.bodyText = res;
        });
    }
    else {
      this.bodyText = this.data.bodyText;
    }
    this.headerText = this.data.headerText;
       if (this.data.okLabel) {
        this.okLabel = this.data.okLabel;
       }
       if (this.data.cancelLabel) {
        this.cancelLabel = this.data.cancelLabel;
       }
     }

   removeUser(user: Member) {
    let updatedExternal =  this.data.externalUsers$.filter((eachUser: any) =>{
        return user.jid !== eachUser.jid;
      });
      this.data.externalUsers$ = updatedExternal;
    }

  ngOnInit(): void {
    this.broadcaster.on<any>("CLOSE_DELETE_CONFIRM_DIALOG").pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.cancel();
    });

    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
      });
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.matDialogRef.close();
  }

  close(value?: string): void {
    if (this.data?.externalUsers$) {
      this.matDialogRef.close({ confirmation: value, invitedUsers: this.data?.externalUsers$ });
    }
    else {
      this.matDialogRef.close({ confirmation: value });
    }
  }

  getLabel() {
    if (window.innerWidth < 471)
      return "DRAFT";
    return "SAVE_AS_DRAFT";
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}

export enum ConfirmationPopupActions {
  SAVE_AS_DRAFT = "SAVE_AS_DRAFT"
}
