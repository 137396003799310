<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div class="mat-dialog vnctalk-form-dialog select-participants start-chat-component select-watcher-block">
    <ng-container *ngIf="!isMobileScreen">
      <vnc-header style="width:100%;"
          title="{{ 'ADD_WATCHER' | translate }}"
          closeLbl="{{ 'CLOSE' | translate }}"
          type="title-close"
          size="l"
          (onCloseClick)="cancel()"
          [showCloseIcon]="true">
      </vnc-header>
    </ng-container>
      <div class="mat-dialog__body" [class.hide-footer]="isMobileScreen">
          <div class="form-group">
              <div class="search-control">
                <vnc-input [form]="searchControlData" placeholder="{{ 'SEARCH'| translate}}" [showClear]="true">
                </vnc-input>

                  <!-- <input id="searchControl" (keydown.enter)="addEmail()" [formControl]="searchControl" type="text"
                      placeholder="{{ 'SEARCH' | translate }}" maxlength="200"> -->
                  <!-- <mat-icon fontSet="mdi" fontIcon="mdi-magnify" class="mr-24"></mat-icon>
                  <mat-icon *ngIf="searchControl.value" class="clear-icon" (click)="clearSearch()"
                      fontSet="mdi" fontIcon="mdi-close"></mat-icon> -->

              </div>
          </div>

          <!-- list of suggestedd users -->
          <cdk-virtual-scroll-viewport class="all-participants" minBufferPx="250" maxBufferPx="500" [itemSize]="50">
              <div (click)="selectMiltipleParticipents(participant)" class="participant-item"
                  *cdkVirtualFor="let participant of filteredRecipients;trackBy: trackByFn">
                  <div class="participant-avatar">
                      <vp-avatar [jid]="participant.jid" size="conference" hidestatus="true"></vp-avatar>
                  </div>
                  <div class="participant-name">
                      {{ participant.firstname }} {{ participant.lastname }}
                  </div>

                  <vnc-icon-container (click)="selectMiltipleParticipents(participant)" class="arrow-icon select-icon" *ngIf="participant.isSelected">
                    <mat-icon>check</mat-icon>
                  </vnc-icon-container>

              </div>
          </cdk-virtual-scroll-viewport>
          <div #profileDialog class="profile-info-box" (mouseleave)="hideProfile(true)" (mouseover)="showProfile()" *ngIf="selectedParticipant">
            <div class="profile-info-box__top">
              <div class="profile-avatar">
                <vp-avatar [hidestatus]="true" [jid]="selectedParticipant.target" size="profile-info"></vp-avatar>
              </div>
              <div class="profile-info">
                <div class="profile-name">{{ selectedParticipant.target | vpGetFullName }}</div>
                <div class="profile-job-title">{{ selectedParticipant.job_title }}</div>
              </div>
            </div>
            <div class="profile-info-box__bottom">
              <label>{{ 'EMAIL' | translate }}</label>
              <div class="email-info">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" class="ng-star-inserted"><g  fill="none" fill-rule="evenodd"><g  fill="#8b96a0"><g ><path  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" transform="translate(-6 -6) translate(6 6)"></path></g></g></g></svg>
                <div class="email-address">{{ selectedParticipant.target }}</div>
                <vnc-icon-container matTooltip="{{ 'COPY_TO_CLIPBOARD' | translate }}" id=""copyEmailBtn (click)="copyEmail(selectedParticipant.target)">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="ng-star-inserted"><path d="M19 5c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2zm0 2H8v14h11V7zm-4.064-5a1 1 0 0 1 .117 1.993L14.936 4H7.333c-1.235 0-2.246.96-2.328 2.174L5 6.334v11.683a1 1 0 0 1-1.993.116L3 18.017V6.333a4.333 4.333 0 0 1 4.123-4.328L7.333 2h7.603z" fill="#8B96A0" fill-rule="nonzero"></path></svg>
                </vnc-icon-container>
              </div>

            </div>
          </div>
      </div>

        <div class="footer-actions">
            <button class="vnc-default vnc-button" (click)="cancel()">{{ 'CANCEL' | translate }}</button>
            <button class="vnc-primary vnc-button" (click)="addWatcher()">{{ 'ADD' | translate }}</button>
        </div>
  </div>
