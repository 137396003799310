/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserConfigService } from "../services/userConfig.service";
import { Store } from "@ngrx/store";
import { getNetworkInformation, getIsAppBootstrapped, RootState } from "app/reducers";
import { ConfigService } from "app/config.service";
import { Broadcaster } from "../shared/providers";
import { TOPIC_VIEW, UserConfig } from "app/shared/models/user-config.model";
import { SetInvitationConfig, SetUserConfig, UpdateTopicView } from "app/actions/app";
import { CommonUtil } from "app/talk/utils/common.util";
import {AvatarRepository} from "app/talk/repositories/avatar.repository";
import { distinctUntilChanged, filter, take, tap } from "rxjs/operators";
import { LoggerService } from "app/shared/services/logger.service";



@Injectable()
export class UserConfigRepository {
  config: UserConfig;
  private bootstrapped = false;
  private isNetOnline = false;
  private isInitDataLoaded = false;


  constructor(
    private userConfig: UserConfigService,
    private store: Store<RootState>,
    private configService: ConfigService,
    private avatarRepo: AvatarRepository,
    private logger: LoggerService,
    private broadcaster: Broadcaster) {
      this.logger.info("[UserConfigRepository][constructor]");

        if (!this.configService.isAnonymous) {
            this.configService.getLoadedConfig().subscribe(() => {
                this.logger.info("[UserConfigRepository][constructor] getLoadedConfig");
                if (!this.isInitDataLoaded) {
                    this.initData();
                }
            });
        } else {
          this.logger.info("userconfigservice anonymous");
          this.renewJitsiAuth();
        }

        this.broadcaster.on<any>("vncdirectoryuserconfigupdate").subscribe( () => {
            this.logger.info("[UserConfigRepository][vncdirectoryuserconfigupdate] on broadcast");
            this.loadUserConfig().pipe(take(1)).subscribe();
        });

        this.broadcaster.on<any>("maybeRenewJitsiAuth").subscribe(() => {
          this.maybeRenewJitsiAuth();
          setTimeout(() => {
            this.avatarRepo.checkRefreshAvatars();
          }, 4000);
        });

        this.broadcaster.on<any>("recheck2FA").subscribe(() => {
          this.recheckConfigs();
        });

        this.store.select(getIsAppBootstrapped).pipe(distinctUntilChanged()).subscribe(v => {
          if (!this.bootstrapped) {
            if (!!v) {
              this.bootstrapped = true;
              this.logger.info("[UserConfigRepository][getIsAppBootstrapped]", v);
              setTimeout(() => {
                this.logger.info("[UserConfigRepository][getIsAppBootstrapped] recheckConfigs");
                this.recheckConfigs();
              }, 1000);
            }
          }
        });
    }

    initData() {
        this.logger.info("[UserConfigRepository][initData]");

        this.isInitDataLoaded = true;

        this.store.select(getNetworkInformation).pipe(filter(res => res?.onlineState), take(1)).subscribe(information => {
            if (information && this.isNetOnline !== information.onlineState) {
              this.isNetOnline = information.onlineState;
              this.logger.info("[UserConfigRepository][getNetworkInformation]", this.isNetOnline);
              if (this.isNetOnline) {
                this.loadUserConfig().pipe(distinctUntilChanged()).pipe(take(1)).subscribe();
              }
            }
        });
    }


  public loadUserConfig(): Observable<any> {
    this.logger.info("userconfig.repo getUserConfig");
    return this.userConfig.getUserConfig()
      .pipe(tap((user: any) => {
        if (user) {
          let userConfig: UserConfig = {
            id: user.id,
            avatar_url: user.avatar_url,
            firstname: user.firstname,
            lastname: user.lastname,
            mail: user.mail,
            totp_secret: user.totp_secret,
            rfc_enabled: user.rfc_enabled === "true",
            rfc_limit: user.rfc_limit,
            tfa_enabled: user.tfa_enabled === "true",
            video_bridge: user.video_bridge === "true",
            can_invite_users: user.can_invite_users === "true",
            has_invitation_rights: user.has_invitation_rights === "true",
            can_manage_mcbs: user.can_manage_mcbs === "true",
            can_upgrade_to_org: user.can_upgrade_to_org === "true",
            organizationId: user.organization ? user.organization.id : null,
            omemo: user.omemo === "true",
            topics_view: user.topics_view || TOPIC_VIEW.LIST,
            vnc_features: user.vnc_features,
            attachment_max_size: user.attachment_max_size,
            auto_clean_trash: user?.auto_clean_trash,
            auto_clean_trash_period: user?.auto_clean_trash_period
          };
          this.config = userConfig;
          this.store.dispatch(new SetUserConfig(userConfig));
          if (userConfig && userConfig.has_invitation_rights) {
            this.loadInvitationConfig();
          }
        }
      }));
  }

  public updateUserTopicView(view:TOPIC_VIEW): Observable<any> {
    return this.userConfig.updateUserConfig({pref: {topics_view: view}})
      .pipe(tap(() => {
        this.logger.info("[UserConfigRepository][updateUserTopicView]", view);
        this.store.dispatch(new UpdateTopicView(view));
    }));
  }

  isOMEMOEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("omemo");
  }

  isBroadcastEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("broadcast");
  }

  isMCBEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("mcb");
  }

  isWhiteboardEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("whiteboard");
  }

  isRFCEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("rfc");
  }

  isThemesEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("themes");
  }

  is2FAEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("twofa");
  }

  isPadEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("vncpad");
  }

  isStartVideoEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("start_video_meeting_enabled");
  }

  isCopyURLinCallEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("copy_url_in_call_enabled");
  }

  isInviteUserEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("invite_user_enabled");
  }

  isRTFEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("rtf");
  }

  isChannelsEnabled() {
    return this.config?.vnc_features && (this.config?.vnc_features.includes("channels") || this.config?.vnc_features.includes("vncchannels"));
  }

  isVideoBridgeEnabled() {
    return this.config?.vnc_features && this.config?.vnc_features.includes("video_bridge");
  }

  public loadInvitationConfig() {
        this.userConfig.getInvitationConfig().subscribe( (res: any) => {
            if ( res && res.organization && res.departments) {
                let invitationConfig = {
                    organization: res.organization,
                    departments: res.departments
                };
                this.store.dispatch(new SetInvitationConfig(invitationConfig));
            }
        });
  }

  public maybeRenewJitsiAuth() {
    const currJitsiAuth = this.configService.get("jitsiauth");
    if (!!currJitsiAuth) {
      try {
        const currJitsiJWT = CommonUtil.decodeJWT(currJitsiAuth);
        let remainJitsiValid = (1000 * currJitsiJWT.exp) - new Date().getTime();
        if (remainJitsiValid < 604800000) {
          this.renewJitsiAuth();
        }
      } catch (ex) {
        this.logger.error("[UserConfigRepository][maybeRenewJitsiAuth] error: ", ex);
      }
    } else {
      this.renewJitsiAuth();
    }
  }

  private renewJitsiAuth() {
    this.logger.info("[userconfig.repo] renewJitsiAuth");
    this.userConfig.getJitsiAuth().subscribe((res: any) => {
      if (!!res.jitsiAuth) {
        // store in localstorage for debug visibilit
        this.logger.info("[userconfig.repo] renewJitsiAuth res: ", res);
        localStorage.setItem("jitsiauth", res.jitsiAuth);
        this.configService.set("jitsiauth", res.jitsiAuth);
      }
    });
  }

  private recheckConfigs() {
    this.userConfig.getUserConfigFromServer().subscribe(data => {
      this.logger.info("[UserConfigRepository][recheckConfigs] dataFromServer: ", data);
      const cachedConfigString = localStorage.getItem("userConfigFromServer");
      let cachedConfig;
      try {
        if (!!cachedConfigString && (cachedConfigString !== "")) {
          cachedConfig = JSON.parse(cachedConfigString);
        }
      } catch (e) {
        this.logger.error("[UserConfigRepository][recheckConfigs] error restoring cachedConfig: ", e);
      }

      let configEqual = CommonUtil.compareObjects(data, cachedConfig);
      this.logger.info("[UserConfigRepository][recheckConfigs] configEqual: ", configEqual);
      if (!configEqual) {
        try {
          const jsonData = JSON.stringify(data);
          localStorage.setItem("userConfigFromServer", jsonData);
        } catch (e) {
          this.logger.error("[UserConfigRepository][recheckConfigs] error serializing config data: ", e);
        }
      }
      this.loadUserConfig().pipe(take(1)).subscribe();
      setTimeout(() => {
        this.renewJitsiAuth();
        this.logger.info("[userconfig.repo] userconfigdata: ", data);
        if (!!data.created_on) {
          let hardNag = false;
          const createdDate = Date.parse(data.created_on);
          if (Date.now() - createdDate > 2592000000) {
            hardNag = true;
          }
          if (!!data.tfa_enabled) {
            this.logger.info("[userconfig.repo] userconfigdata: ", hardNag, data.tfa_enabled, data);
            localStorage.setItem("remindTFA", hardNag.toString());
            if (data.tfa_enabled !== "true") {
              this.broadcaster.broadcast("remindTFA", hardNag);
            }
          }
        }
      }, 500);
    });
  }

}
