
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtil } from "app/talk/utils/common.util";


@Pipe({ name: "vpProcessText" })
export class ProcessTextPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) { }
  transform(value: any): string {
    if (!value) {
      return "";
    }
    let sanitizeContent = this.escapedAndSanitizedHtmlProperty(value.replace(/\r?\n/g, " "));
    let content: any = sanitizeContent;
    if (content.trim().startsWith("http") || content.trim().startsWith("https")) {
      const link = content.trim().toLocaleLowerCase();
      const extension = link.split(".").pop();
      const photoFormat = ["jpg", "jpeg", "png", "gif", "bmp"];
      const audioFormat = ["wav", "mp3", "wma", "ogg"];
      if (extension && photoFormat.indexOf(extension) !== -1) {
        this.translate.get(["PHOTO"])
          .subscribe(res => {
            // content = `
            //   <div class="message-notification-image">
            //     <div class='message-icon'><mat-icon class='material-icons'>photo</mat-icon> ${res.PHOTO}</div>
            //     <img src="${content}" style="width:100%" />
            //   </div>
            // `
            content = "<div class='message-icon'><mat-icon class='material-icons'>photo</mat-icon> " + res.PHOTO + " </div>";
          });
        // content = "<div class='message-icon'><mat-icon class='material-icons'>photo</mat-icon> Foto</div>";
      } else if (extension && audioFormat.indexOf(extension) !== -1) {
        const fileName = link.split("/").pop();
        if (fileName.startsWith("audio_recording_")) {
          this.translate.get(["VOICE_MESSAGE"])
            .subscribe(res => {
              content = "<div class='message-icon'><mat-icon class='material-icons'>keyboard_voice</mat-icon> " + res.VOICE_MESSAGE + " </div>";
            });
        } else {
          if (fileName.startsWith("audio_recording_")) {
            this.translate.get(["AUDIO"])
              .subscribe(res => {
                content = "<div class='message-icon'><mat-icon class='material-icons'>audiotrack</mat-icon> " + res.AUDIO + " </div>";
              });
          }
        }
      } else {
        content = "<div class='message-icon'><mat-icon class='material-icons'>link</mat-icon> Link</div>";
      }
    }
    if(content.indexOf("t;") > -1){
      content = document.getElementById("notificationText").innerHTML = value;
    } else{
      content = this.sanitizer.bypassSecurityTrustHtml(wdtEmojiBundle.render(content));
    }
    return content;
  }

  escapedAndSanitizedHtmlProperty(content) {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.escapeHtml(content));
  }

  escapeHtml(unsafe) {
    const escaped = CommonUtil.escapeHTMLString(unsafe);
    return escaped;
  }
}
