
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { getAppSettings } from "app/reducers";
import { LoggerService } from "app/shared/services/logger.service";
import { AppSettings } from "../models/app-settings.model";
import { TalkRootState } from "../reducers";
import { CommonUtil } from "../utils/common.util";

@Injectable()
export class DatetimeService {

  static SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY = "SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY";

  static MIN_ALLOWED_TIME_DIFF = 5 * 1000; // 5 seconds

  localAndServerTimeDiffInterval = 0;
  settings: AppSettings = {};

  constructor( private store: Store<TalkRootState>, private logger: LoggerService) {
    this.logger.info("[DatetimeService][constructor]");
    this.localAndServerTimeDiffInterval = this.getServerAndLocalTimeDiffIntervalFromStorage();
    this.store.select(getAppSettings).subscribe(options => {
      this.settings = options;
    });
  }

  getFormatString(lang?: string, timeOnly?: boolean, dateOnly?: boolean) {
    let timeFormat = "HH:mm";
    let dateFormat = "MM/dd/yyyy";
    lang = lang || localStorage.getItem("portalLanguage") || "en";
    if (lang === "de") {
      dateFormat = "dd.MM.yyyy";
    }
    const defaultDateFormat = CommonUtil.getDatePattern(navigator.language || "en-US");
    if (!!this.settings.timeFormat) {
      timeFormat = this.settings.timeFormat;
    }
    if (defaultDateFormat === "dd/MM/yyyy" || defaultDateFormat === "dd.MM.yyyy" || defaultDateFormat === "MM/dd/yyyy") {
      dateFormat = defaultDateFormat;
    }
    if (!!this.settings.dateFormat) {
      dateFormat = this.settings.dateFormat;
    }
    let formatStr = `${dateFormat} ${timeFormat}`;
    if (timeOnly) {
      formatStr = timeFormat;
    }

    if (dateOnly) {
      formatStr = dateFormat;
    }

    // this.logger.info("formatStr", {formatStr, settings: this.settings.timeFormat, defaultDateFormat, timeOnly, dateOnly});

    return formatStr;
  }

  processTimeDiff(diff: number){
    // if server Time diff > 5 s -> show an alert
    if (Math.abs(diff) > DatetimeService.MIN_ALLOWED_TIME_DIFF) {
      this.setServerAndLocalTimeDiffInterval(diff);
      this.logger.info("[DatetimeService][processTimeDiff] local time is invalid (diff > 5s), so adjusting it. diff: ", diff);
    } else {
      this.clearServerAndLocalTimeDiffInterval();
      this.logger.info("[DatetimeService][processTimeDiff] local time is valid");
    }
  }

  setServerAndLocalTimeDiffInterval(interval: number) {
    this.logger.info("[DatetimeService][setServerAndLocalTimeDiffInterval]", interval);
    this.storeServerAndLocalTimeDiffInterval(interval);
    this.localAndServerTimeDiffInterval = interval;
  }

  clearServerAndLocalTimeDiffInterval() {
    this.logger.info("[DatetimeService][clearServerAndLocalTimeDiffInterval]");
    this.clearServerAndLocalTimeDiffIntervalInStorage();
    this.localAndServerTimeDiffInterval = 0;
  }

  getCorrectedLocalTime(): number {
    const correctedLocalTime = new Date().getTime() - this.localAndServerTimeDiffInterval;
    // this.logger.info("[DatetimeService][getCorrectedLocalTime]", correctedLocalTime, this.localAndServerTimeDiffInterval);
    return correctedLocalTime;
  }

  getDisplayTime(timestamp: number): number {
    const displayTime = timestamp + this.localAndServerTimeDiffInterval;
    // this.logger.info("[DatetimeService][getDisplayTime]", timestamp, this.localAndServerTimeDiffInterval, displayTime);
    return displayTime;
  }

  private storeServerAndLocalTimeDiffInterval(interval: number) {
    localStorage.setItem(DatetimeService.SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY, interval + "");
  }

  private getServerAndLocalTimeDiffIntervalFromStorage(): number {
    return parseInt(localStorage.getItem(DatetimeService.SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY) || "0");
  }

  private clearServerAndLocalTimeDiffIntervalInStorage() {
    localStorage.removeItem(DatetimeService.SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY);
  }

}
