
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, HostListener, Output, EventEmitter } from "@angular/core";

@Directive({ selector: "[vpTxtHighlightPos]" })
export class TxtHighlightPosDirective {

  @Output() onSelectionChange = new EventEmitter<{pos: ClientRect; text: string; element: Node}>();

  constructor() { }

  @HostListener("document:mousedown", ["$event"]) onMouseDown(e) {
    this.onSelectionChange.emit({ pos: null, text: null, element: e.target });
  }

  @HostListener("mouseup")
  onMouseUp() {
    let selection = this.getSelection();
    let text = (selection?.toString() || "").trim();

    if (text && selection?.anchorNode === selection?.focusNode) {
      this.onSelectionChange.emit({
        pos: selection.getRangeAt(0).getBoundingClientRect(),
        text: text,
        element: selection.anchorNode,
      });
    }
  }

  getSelection() {
    return window.getSelection();
  }
}
