
<div>
    <color-chrome [color] = "existingColor" (onChange)="handleColorChange($event)"></color-chrome>
    <div class = "color-action">
        <!-- <button (click)="close()" class="brand-color mat-button" (click) = "changeColor()">
            <mat-icon class="disable-select">check</mat-icon>
        </button>
        <button (click)="close()" class="brand-color mat-button" (click) = "close()">
            <mat-icon class="disable-select">close</mat-icon>
        </button> -->
  
        <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL' | translate }}" (click)="close()"></vnc-button>
  
        <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" (click)="close(); changeColor()">
            <span>{{'ADD' | translate }}</span>
        </vnc-button>
    </div>
  </div>