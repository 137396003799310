
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, Optional, SkipSelf } from "@angular/core";
import { Subject } from "rxjs";

export interface ProfileCardInfo {
    target: string;
    type: string;
    status: string;
}

@Injectable()
export class ProfileCardService {
    private profileCardInfoSubject = new Subject<ProfileCardInfo>();
    profileCardState = this.profileCardInfoSubject.asObservable();
    profileChatEvents: Array<any> = [];

    constructor( @Optional() @SkipSelf() prior: ProfileCardService) {
        if (prior) {
            return prior;
        }
    }

    activate(target?: string, type?: string, status?: string) {
        this.profileCardInfoSubject.next(<ProfileCardInfo>{ target, type, status });
    }
}
