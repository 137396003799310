
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../actions";
import { SearchMessage } from "../models/search-message.model";

export class SearchMessageActionTypes {
  static MESSAGE_ADD = "[SEARCH MESSAGE] Add";
  static MESSAGE_BULK_APPEND = "[SEARCH MESSAGE] Bulk Append";
  static MESSAGE_BULK_UPDATE = "[SEARCH MESSAGE] Bulk Update";
  static MESSAGE_BULK_LOAD = "[SEARCH MESSAGE] Bulk Load";
  static RESET_MESSAGES = "[SEARCH MESSAGE] Reset Messages";
}

export class MessageAdd implements Action {
  readonly type = SearchMessageActionTypes.MESSAGE_ADD;

  constructor(public payload: { message: SearchMessage }) {
  }
}

export class MessageBulkAppend implements Action {
  readonly type = SearchMessageActionTypes.MESSAGE_BULK_APPEND;

  constructor(public payload: {
    messages: SearchMessage[]
  }) {
  }
}

export class MessageBulkUpdate implements Action {
  readonly type = SearchMessageActionTypes.MESSAGE_BULK_UPDATE;

  constructor(public payload: {
    messages: SearchMessage[]
  }) {
  }
}

export class MessageBulkLoad implements Action {
  readonly type = SearchMessageActionTypes.MESSAGE_BULK_LOAD;

  constructor(public payload: {
    messages: SearchMessage[]
  }) {
  }
}

export class ResetMessages implements Action {
  readonly type = SearchMessageActionTypes.RESET_MESSAGES;
}