import {Injectable} from "@angular/core";
import {FilterType} from "../../../../channels/smart-link.service";
import {DEFAULT_USER_AVATAR} from "../../../../channels/smart-link-popup/smart-link-popup-filters.service";
import {CommonUtil} from "../../../../talk/utils/common.util";
import {catchError, debounceTime, map, take} from "rxjs/operators";
import {Option} from "../../../../channels/channel-detail/topic-filter/topic-filter.component.service";
import {Contact} from "../../../../talk/models/contact.model";
import {UntypedFormControl} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {ContactRepository} from "../../../../talk/repositories/contact.repository";
import {AvatarRepository} from "../../../../talk/repositories/avatar.repository";
import {ConfigService} from "../../../../config.service";
import {Observable} from "rxjs";
import {ChannelService} from "../../../../channels/channel.service";
import {combineLatest, noop, of} from "rxjs";
import {SnackbarType} from "../../../../channels/models/snackbar.model";
import {TranslateService} from "@ngx-translate/core";
import {ChannelSnackbarService} from "../../../../channels/channel-snackbar.service";
import {getBare, getUserConfig} from "../../../../reducers";
import {Store} from "@ngrx/store";
import {TalkRootState} from "../../../../talk/reducers";

@Injectable()

export class RelatedTopicsPopupFilterService {

  userSearchControl = new UntypedFormControl("");
  $userOptions = new BehaviorSubject<Option[]>([]);
  $totalCount = new BehaviorSubject<number>(0);
  $loading = new BehaviorSubject<boolean>(false);
  $loadingError = new BehaviorSubject<boolean>(false);
  $searchResults = new BehaviorSubject<any[]>([]);

  topicTypeOptions = [
    { label: "Any", value: "any" },
    { label: "Regular", value: "regular" },
    { label: "VNCSocial", value: "vnc-social" },
    { label: "Youtube", value: "youtube" },
  ];

  topicPrivacyOptions = [
    { label: "Public only", value: "public" },
    { label: "Private only", value: "private" },
  ];

  queryKeys = {
    [FilterType.TOPIC_TYPE]: "type",
    [FilterType.TOPIC_USER]: "subscriber_id",
    [FilterType.TOPIC_AUTHOR]: "author_id",
    [FilterType.TOPIC_PRIVACY]: "is_private",
    [FilterType.GLOBAL_UNREAD]: "read",
    [FilterType.GLOBAL_ARCHIVED]: "status_id",
    [FilterType.TOPIC_SUBJECT]: "q",
    [FilterType.GLOBAL_DATE_RANGE]: "created_on"
  };

  defaultFilters = {
    [FilterType.TOPIC_TYPE]: [],
    [FilterType.TOPIC_USER]: [],
    [FilterType.TOPIC_AUTHOR]: [],
    [FilterType.TOPIC_PRIVACY]: "all",
    [FilterType.GLOBAL_UNREAD]: false,
    [FilterType.GLOBAL_ARCHIVED]: false,
    [FilterType.GLOBAL_DATE_RANGE]: null
  };

  appliedFilters$ = new BehaviorSubject<any>({...this.defaultFilters});

  private _allContacts: any[] = [];
  private _data = { q: "", topics: 1, offset: 0, limit: 25 };
  private _entityQueries = { };
  private searchSubscription: any = null;
  private _isInitialSearch = true;

  constructor(
    private _contactRepository: ContactRepository,
    private _avatarRepository: AvatarRepository,
    private _configService: ConfigService,
    private _channelService: ChannelService,
    private _translate: TranslateService,
    private _channelSnackBarService: ChannelSnackbarService,
    private _talkRootStore: Store<TalkRootState>
  ) {
  }

  fetchAllFilters() {
    this.fetchAllUsers();
  }

  resetFilter(filter: FilterType): void {
    this.resetSearchResults(false);
    let appliedFilters = this.appliedFilters$.value;
    appliedFilters[filter] = this.defaultFilters[filter];
    this.appliedFilters$.next(appliedFilters);
    this.fetchTopics();
  }

  onFilterChange(event, filter: FilterType) {
    if (event?.startDate != null &&  event?.endDate != null){
      let sub = this._data?.q;
      if(!(event?.endDate?.$d instanceof Date) || isNaN(event.endDate.$d)) {
        this.resetSearchResults();
        this._data["q"] = sub;
        return;
      }
      if(!(event?.startDate?.$d instanceof Date) || isNaN(event.startDate.$d)) {
        this.resetSearchResults();
        this._data["q"] = sub;
        return;
      }
    }
    if (filter === FilterType.GLOBAL_DATE_RANGE && this._isInitialSearch) {
      this._isInitialSearch = false;
      return;
    }
    if (filter === FilterType.TOPIC_SUBJECT) {
      this.resetSearchResults();
      this._data["q"] = event;
    } else if (filter === FilterType.GLOBAL_DATE_RANGE && event?.startDate === null && event?.endDate === null) {
      this.resetSearchResults(false);
      let appliedFilters = this.appliedFilters$.value;
      appliedFilters[filter] = this.defaultFilters?.[filter];
      this.appliedFilters$.next(appliedFilters);
      delete this._entityQueries?.["topics_query"]?.created_on;
    } else {
      this.resetSearchResults(false);
      let appliedFilters = this.appliedFilters$.value;
      appliedFilters[filter] = event;
      this.appliedFilters$.next(appliedFilters);
    }
    this.fetchTopics();
  }

  loadMoreSearchResults() {
    const loadedResults = this.$searchResults.getValue().length;
    const totalResults = this.$totalCount.getValue();
    if (loadedResults < totalResults) {
      this.fetchTopics();
    }
  }

  /* USERS FILTERS */
  private fetchAllUsers() {
    const getMappedUsers = (contacts: any[]): Option[] =>
      contacts.map(contact => ({
        label: contact?.name || contact?.fullName,
        value: contact?.user_id,
        imgSrc: contact?.avatar_url || this.buildAvatarUrl(contact?.bare) || DEFAULT_USER_AVATAR,
        fallBackInfo: {
          bgColor: CommonUtil.getAvatarBackground(CommonUtil.getAvatarText(contact.bare)),
          text: CommonUtil.getAvatarText(contact.bare)
        }
      }));

    const getSortedUsers = (contacts: Option[]): Option[] => {
      return contacts.sort((a, b) => {
        if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      });
    };

    const $loggedInUserOb = this._talkRootStore.select(getUserConfig);
    const $loggedInUserBareOb = this._talkRootStore.select(getBare);
    const $contactsOb = this._contactRepository.getAllContacts().pipe(map(contacts => {
      return contacts.filter(c => c?.user_id);
    }));
    combineLatest([$loggedInUserOb, $loggedInUserBareOb, $contactsOb]).subscribe(res => {
      const loggedInUser = {
        fullName: `${res[0]?.firstname} ${res[0]?.lastname}`,
        user_id: res[0]?.id,
        bare: res[1],
        avatar_url: res[0]?.avatar_url
      };
      this._allContacts = [...res[2], loggedInUser];
      this.$userOptions.next(getSortedUsers(getMappedUsers([...res[2], loggedInUser])));
    });

    this.userSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(async () => {
      const mappedContacts = this.getFilteredRecipients(this.userSearchControl.value);
      this.$userOptions.next(getSortedUsers(getMappedUsers(mappedContacts)));
    });
  }

  private buildAvatarUrl(jid) {
    const avatarVersion = "";
    let avatarName = this._avatarRepository.buildTargetHash(jid);
    const avUrl = this._configService.avatarServiceUrl + "/" + avatarName + ".jpg" + avatarVersion;
    return CommonUtil.translateHINFileURL(avUrl);
  }

  private getFilteredRecipients(searchValue: string): Contact[] {
    return this._allContacts.filter((contact: Contact) =>
      (contact.name || contact.fullName)?.toLowerCase()?.includes(searchValue?.toLowerCase()) &&
      (contact.bare)?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }

  private fetchTopics() {
    if (this.$loading.value) {
      return;
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.$loading.next(true);
    const data = this.getQuery();
    this.searchSubscription = this.getTopics(data).subscribe(res => this.setTopics(res));
  }

  private getQuery(): any {
    const query = {
      topics_query: { ...( this._entityQueries?.["topics_query"] || {} ) }
    };
    const filters = this.appliedFilters$.value;
    const filtersWithValidValue = Object.keys(filters).filter(f => filters?.[f] !== this.defaultFilters?.[f]);
    if (filtersWithValidValue.includes(FilterType.GLOBAL_UNREAD)) {
      query.topics_query[this.queryKeys[FilterType.GLOBAL_UNREAD]] = 0;
    } else {
      delete query.topics_query?.[this.queryKeys?.[FilterType.GLOBAL_UNREAD]];
    }
    if (filtersWithValidValue.includes(FilterType.GLOBAL_ARCHIVED)) {
      query.topics_query[this.queryKeys[FilterType.GLOBAL_ARCHIVED]] = "c";
    } else {
      delete query?.topics_query?.[this.queryKeys?.[FilterType?.GLOBAL_ARCHIVED]];
    }
    if (filtersWithValidValue.includes(FilterType.TOPIC_USER)) {
      query.topics_query[this.queryKeys[FilterType.TOPIC_USER]] = filters?.[FilterType.TOPIC_USER].join("|");
    } else {
      delete query?.topics_query?.[this.queryKeys?.[FilterType.TOPIC_USER]];
    }
    if (filtersWithValidValue.includes(FilterType.TOPIC_AUTHOR)) {
      query.topics_query[this.queryKeys[FilterType.TOPIC_AUTHOR]] = filters?.[FilterType.TOPIC_AUTHOR].join("|");
    } else {
      delete query?.topics_query?.[this.queryKeys?.[FilterType.TOPIC_AUTHOR]];
    }
    if (filtersWithValidValue.includes(FilterType.TOPIC_PRIVACY)) {
      query.topics_query[this.queryKeys[FilterType.TOPIC_PRIVACY]] = filters?.[FilterType.TOPIC_PRIVACY] === "private" ? 1 : filters?.[FilterType.TOPIC_PRIVACY] === "public" ? 0 : "all";
      filters?.[FilterType.TOPIC_PRIVACY] === "all" ? delete query.topics_query[this.queryKeys[FilterType.TOPIC_PRIVACY]] : noop();
    } else {
      delete query?.topics_query?.[this.queryKeys?.[FilterType.TOPIC_PRIVACY]];
    }
    if (filtersWithValidValue.includes(FilterType.GLOBAL_DATE_RANGE)) {
      query.topics_query[this.queryKeys[FilterType.GLOBAL_DATE_RANGE]] = this.getCreatedOnFilter(filters?.[FilterType.GLOBAL_DATE_RANGE]);
    } else if (query?.topics_query?.[this.queryKeys?.[FilterType.GLOBAL_DATE_RANGE]] === null) {
      delete query?.topics_query?.[this.queryKeys?.[FilterType.GLOBAL_DATE_RANGE]];
    }
    this._entityQueries = {...query};
    this._data = {...this._data, ...this._entityQueries};
    return this._data;
  }

  private getTopics(data): Observable<any> {
    return this._channelService.globalSearch(data)
      .pipe(map(value => {
        let newOffset = (value?.offset || 0) + this._data.limit;
        const total_count = value?.total_count || 0;
        newOffset = newOffset > total_count ? total_count : newOffset;
        this.$totalCount.next(total_count);
        this._data.offset = newOffset;
        this.$loading.next(false);
        this.$loadingError.next(false);
        return (value?.results || []);
      }), catchError(() => {
        this._translate.get("LOAD_FAILED").pipe(take(1)).subscribe(text => this._channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE));
        this.$loading.next(false);
        this.$loadingError.next(true);
        return of([]);
      }));
  }

  private setTopics(searchResults: any[]) {
    const filterAndConcat = (oldSearchResults, newSearchResults) => {
      const resultantSearchResults = [...oldSearchResults];
      newSearchResults.forEach(newSearchResult => {
        const index = oldSearchResults.findIndex(oldSearchResult => oldSearchResult?.["object_type"] === newSearchResult?.["object_type"] && oldSearchResult?.["id"] === newSearchResult?.["id"]);
        if (index === -1 ) { resultantSearchResults.push(newSearchResult); }
      });
      return resultantSearchResults;
    };

    const getTopicsSortedByChannelName = (topics: any[]): any[] => {
      return topics.sort((a, b) => {
        if(a.channel_name.toUpperCase() < b.channel_name.toUpperCase()) { return -1; }
        if(a.channel_name.toUpperCase() > b.channel_name.toUpperCase()) { return 1; }
        return 0;
      });
    };

    this.$searchResults.next(getTopicsSortedByChannelName(filterAndConcat(this.$searchResults.getValue(), searchResults)));
  }

  private resetSearchResults(resetSearchTerm = true) {
    const searchTerm = resetSearchTerm ? "" : this._data.q;
    this.$searchResults.next([]);
    this.$loading.next(false);
    this.$loadingError.next(false);
    this.$totalCount.next(0);
    this._data = { ...this._data, q : searchTerm, offset: 0 };
  }

  private getCreatedOnFilter(event) {
    const startDate = event?.startDate?.format("YYYY-MM-DDTHH:mm:ss");
    const endDate = event?.endDate?.format("YYYY-MM-DDTHH:mm:ss");
    return `><${startDate}Z|${endDate}Z`;
  }
}
