
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState, getNetworkInformation } from "app/reducers";
import { ContactUpdatePhotoLastUpdate } from "app/actions/contact";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { CommonUtil } from "../utils/common.util";
import { debounceTime, distinctUntilChanged, filter, Observable, Subject, take } from "rxjs";
import { LoggerService } from "app/shared/services/logger.service";
import { DatabaseService } from "app/talk/services/db/database.service";
import { BehaviorSubject } from "rxjs";
import { ContactInformation } from "../models/vcard.model";
import { ContactRepository } from "app/talk/repositories/contact.repository";
import { MatDialog } from "@angular/material/dialog";
import { Photo } from "../models/photo.model";
import { ChatUploadAvatarComponent } from "../chat-upload-avatar/chat-upload-avatar.component";
import differenceInMinutes from "date-fns/differenceInMinutes";

@Injectable()
export class AvatarRepository {

  private isNetOnline = false;
  avatars = {};
  avatarsLastUpdated$ = new BehaviorSubject<any>({});
  avatarInDB$ = new BehaviorSubject<any>({});
  noAvatar$ = new BehaviorSubject<any>({});
  updateStoredAvatarsTrigger = new Subject<any>();
  recheckAvatars: string[] = [];
  connectionType: string = "";
  ownAvatarUpdated: number;
  avatarsLoaded: boolean = false;

  constructor(private store: Store<RootState>,
    private electronService: ElectronService,
    private logger: LoggerService,
    private contactRepo: ContactRepository,
    private matDialog: MatDialog,
    private databaseService: DatabaseService,
    private configService: ConfigService) {
      if (!!localStorage.getItem("lastTimeNoAvatar")) {
        this.noAvatar$.next(new Date(localStorage.getItem("lastTimeNoAvatar")));
      }
    try {
      const restoredRecheckAvatars = JSON.parse(localStorage.getItem("recheckAvatars"));
      if (!!restoredRecheckAvatars) {
        this.recheckAvatars = JSON.parse(localStorage.getItem("recheckAvatars"));
      }
    } catch (error) {
      this.logger.info("[avatar.repository][constructor] can not restore recheckAvatars");
    }

    this.store.select(getNetworkInformation).pipe(distinctUntilChanged()).subscribe(information => {
      if (information) {
        if ((!this.isNetOnline && !!information.onlineState || information.connectionType !== this.connectionType) && !window.appInBackground) {
          if (!information.inBackground) {
            this.logger.info("[avatar.repository][getNetworkInformation] -> checkRefreshAvatars", this.isNetOnline);
            this.checkRefreshAvatars();
          }
        }
        this.isNetOnline = information.onlineState;
        this.connectionType = information.connectionType;
      }
    });

    this.databaseService.fetchAllAvatarFromDatabase().subscribe(avatars => {
      // this.logger.info("fetchAllAvatarFromDatabase", avatars);
      const avatar = this.avatarInDB$.value;
      const avatarsLastUpdated = this.avatarsLastUpdated$.value;
      avatars.forEach(v => {
        // this.logger.info("fetchAllAvatarFromDatabase v", v);
        if (!!v.updated) {
          avatar[v.id] = v.data;
          avatarsLastUpdated[v.id] = {
            avatarid: this.buildTargetHash(v.id),
            updated: v.updated
          };
        }
      });
      this.avatarInDB$.next(avatar);
      this.avatarsLastUpdated$.next(avatarsLastUpdated);
      if (!this.avatarsLoaded) {  // run only first time on startup!
        avatars.forEach(v => {
          this.store.dispatch(new ContactUpdatePhotoLastUpdate({
            bare: v.id,
            photoLastUpdate: new Date().getTime()
          }));
        });
      }
      this.avatarsLoaded = true;
    });

    this.updateStoredAvatarsTrigger.pipe(filter(v => !!v), debounceTime(2000)).subscribe(() => {
      this.updateStoredAvatars();
    });
  }

  setNoAvatar() {
    if (!localStorage.getItem("NoAvatarCounter")) {
      localStorage.setItem("NoAvatarCounter", "0");
    }
    const lastTimeNoAvatar = new Date().getTime();
    if (!!localStorage.getItem("lastTimeNoAvatar")) {
      const difference = differenceInMinutes(new Date(), new Date(localStorage.getItem("lastTimeNoAvatar")));
      if (Math.abs(difference) > 60) {
        localStorage.setItem("NoAvatarCounter", "1");
      } else {
        localStorage.setItem("NoAvatarCounter", (+localStorage.getItem("NoAvatarCounter") + 1).toString());
      }
    }
    localStorage.setItem("lastTimeNoAvatar", lastTimeNoAvatar.toString());
    this.noAvatar$.next(lastTimeNoAvatar);
  }

  setOwnAvatarUpdated(ts: number) {
    this.ownAvatarUpdated = ts;
  }

  clearNoAvatar() {
    this.noAvatar$.next(null);
    localStorage.removeItem("NoAvatarCounter");
    localStorage.removeItem("lastTimeNoAvatar");
  }

  addAvatarToDB(data, bare) {
    let avatar = this.avatarInDB$.value;
    avatar[bare] = data;
    this.avatarInDB$.next(avatar);
  }

  get avatarInDB() {
    return this.avatarInDB$.value;
  }

  buildTargetHash(target) {
    // this.logger.info("[buildTargetHash]", target);
    if (this.electronService.isElectron) {
      return this.electronService.md5(target);
    }
    return md5(target);
  }

  isNetworkOnline() {
    return this.isNetOnline;
  }

  removeAvatar(bareTarget: string){
    // this.logger.info("[AvatarRepository][removeAvatar] bareTarget", bareTarget);

    this.store.dispatch(new ContactUpdatePhotoLastUpdate({
      bare: bareTarget,
      photoLastUpdate: -1
    }));
  }

  upgradeAvatar(bareTarget: string){
    // this.logger.info("[AvatarRepository][upgradeAvatar] bareTarget", bareTarget);
    const r = this.avatarInDB[bareTarget];
    if (!!r) {
      this.databaseService.deleteAvatar(bareTarget).subscribe(() => {
        const avatar = this.avatarInDB$.value;
        let updatedAvatar = {};
        const existingInDB = Object.keys(avatar);
        existingInDB.forEach(v => {
          if (v !== bareTarget) {
            updatedAvatar[v] = avatar[v];
          }
        });
        this.avatarInDB$.next(updatedAvatar);
        this.store.dispatch(new ContactUpdatePhotoLastUpdate({
          bare: bareTarget,
          photoLastUpdate: new Date().getTime()
        }));

      });
    } else {
      this.store.dispatch(new ContactUpdatePhotoLastUpdate({
        bare: bareTarget,
        photoLastUpdate: new Date().getTime()
      }));
    }
    const nts = Date.now();
    this.updateStoredAvatarsTrigger.next(nts);
  }

  addTargetForCheck(bareTarget: string) {
    // this.logger.info("[avatarRepo] pre recheckAvatars: ", this.recheckAvatars);
    if (this.recheckAvatars.indexOf(bareTarget) > -1) {
      this.recheckAvatars.splice(this.recheckAvatars.indexOf(bareTarget), 1);
    } else {
      this.recheckAvatars.push(bareTarget);
    }
    localStorage.setItem("recheckAvatars", JSON.stringify(this.recheckAvatars));
    //this.logger.info("[avatarRepo] recheckAvatars: ", this.recheckAvatars);
  }

  removeTargetForCheck(bareTarget: string) {
    if (this.recheckAvatars.indexOf(bareTarget) > -1) {
      this.recheckAvatars.splice(this.recheckAvatars.indexOf(bareTarget), 1);
    }
  }

  checkRefreshAvatars() {
    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {

    }

    if ((!!this.recheckAvatars) && (this.recheckAvatars.length > 0)) {
      this.logger.info("[avatarRepo] checkRefreshAvatars: ", this.recheckAvatars);
      const avatarsToRefresh = this.recheckAvatars.filter(v => (deletedConvs.indexOf(v) === -1));
      this.processRefreshAvatars(avatarsToRefresh);
    }
  }

  processRefreshAvatars(targets: string[]) {
    if (!!targets && targets.length > 0) {
      this.upgradeAvatar(targets[0]);
      const remainingTargets = targets.slice(1);
      setTimeout(() => {
        this.processRefreshAvatars(remainingTargets);
      }, 1000);
    }
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
  }

  getBase64AvatarFromUrl(url) {
    const subject = new Subject();

    const image: HTMLImageElement = new Image();
    image.src = url;
    image.crossOrigin = "Anonymous";

    image.onload = () => {
      const canvasElementName = "canvas-" + url;
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      const b64url = canvas.toDataURL();
      subject.next(b64url);
    };
    image.onerror = (error) => {
      subject.error(error);
    };


    return subject.asObservable().pipe(take(1));;
  }

  storeNewAvatar(avatarUrl: string, jid: string): Observable<string> {
    const subject = new Subject<string>();
    if (avatarUrl.startsWith("http")) {
      // store to idb
      this.getBase64AvatarFromUrl(avatarUrl).subscribe((res: string) => {
        this.addAvatarToDB(res, jid);
        this.storeAvatarInDatabase(res, jid).subscribe();
        subject.next(res);
      }, () => {
        subject.next("");
      });
    }
    return subject.asObservable().pipe(take(1));
  }

  storeAvatarInDatabase(data, bare){
    return this.databaseService.storeAvatar(data, bare);
  }

  fetchAvatarFromDatabase(bare) {
    return this.databaseService.getAvatarByBare(bare);
  }

  fetchAllAvatarFromDatabase() {
    return this.databaseService.fetchAllAvatarFromDatabase();
  }

  getAvatarUrlWithCache(bare: string) {
    const r = this.avatarInDB[bare];
    return !!r ? r : this.buildAvatarUrl(bare);
  }

  buildAvatarUrl(bare: string) {
    let avatarName = this.buildTargetHash(bare);
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg" + `?ver=${Math.abs(new Date().getTime())}`;
    return CommonUtil.translateHINFileURL(avUrl);
  }

  buildAvatarCustomSize(bare: string, size = 420, lastUpdateTimeStamp = undefined) {
    let avatarName = this.buildTargetHash(bare);
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + `-${size}.jpg?ver=${!!lastUpdateTimeStamp ? lastUpdateTimeStamp : Math.abs(new Date().getTime())}`;
    return CommonUtil.translateHINFileURL(avUrl);
  }

  buildAvatarUrlWithTimestamp(bare: string, timestamp: string) {
    let avatarName = this.buildTargetHash(bare);
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg" + "?ver=" + timestamp;
    return CommonUtil.translateHINFileURL(avUrl);
  }

  uploadAvatar(target): void {
    let options: any = {
      width: "640px",
      height: "485px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh",
      };
    }
    const dialog = this.matDialog.open(ChatUploadAvatarComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "vnctalk-channel-avatar-upload-panel"],
      disableClose: true,
      data: {
        jid: target,
        hideRightBar: CommonUtil.isMobileSize(),
        defaultURL: this.buildAvatarCustomSize(target),
      },
      autoFocus: false
    }, options)
    );
    dialog.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        const base64Img = res.photo.data;
        if (!!base64Img) {
            let photo: Photo = {
              type: "image/png" ,
              data: base64Img.split(",")[1]
            };
            let originalData: ContactInformation = {};
            this.contactRepo.getContactVCard(target).pipe(take(1)).subscribe(data => {
              originalData = data;
            });
            const newData = {
              ...originalData,
              ...{photo: photo}
            };
            this.contactRepo.publishVCards(newData).pipe(take(1)).subscribe(() => {
              setTimeout(() => {
                this.upgradeAvatar(target);
                // broadcast avatar change to all contacts
                this.contactRepo.notifyOnAvatarUpdate().pipe(take(1)).subscribe(res => {
                  this.logger.info("[ProfileComponent][updateAvatar] broadcast, res: ", res);
                });
              }, 1000);
            });
        }
      }
    });
  }

  removeAvatarFromDB(bare: string): void {
    const r = this.avatarInDB[bare];
    if (!!r) {
      this.databaseService.deleteAvatar(bare).subscribe(() => {
        const avatar = this.avatarInDB$.value;
        let updatedAvatar = {};
        const existingInDB = Object.keys(avatar);
        existingInDB.forEach(v => {
          if (v !== bare) {
            updatedAvatar[v] = avatar[v];
          }
        });
        this.avatarInDB$.next(updatedAvatar);
      });
    }
  }

  updateStoredAvatars() {
    this.databaseService.fetchAllAvatarFromDatabase().subscribe(avatars => {
      // this.logger.info("fetchAllAvatarFromDatabase", avatars);
      const avatar = this.avatarInDB$.value;
      const avatarsLastUpdated = this.avatarsLastUpdated$.value;
      avatars.forEach(v => {
        // this.logger.info("fetchAllAvatarFromDatabase v", v);
        if (!!v.updated) {
          avatar[v.id] = v.data;
          avatarsLastUpdated[v.id] = {
            avatarid: this.buildTargetHash(v.id),
            updated: v.updated
          };
        }
      });
      this.avatarInDB$.next(avatar);
      this.avatarsLastUpdated$.next(avatarsLastUpdated);
      if (!this.avatarsLoaded) {  // run only first time on startup!
        avatars.forEach(v => {
          this.store.dispatch(new ContactUpdatePhotoLastUpdate({
            bare: v.id,
            photoLastUpdate: new Date().getTime()
          }));
        });
      }
      this.avatarsLoaded = true;
    });
  }
}
