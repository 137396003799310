
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="select-app-dialog">
  <div class="select-app__header">
    <span></span>
    <h4>{{'SELECT_THE_APP' | translate}}</h4>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="app-dialog-body">
    <div class="select-app__body">
      <div class="app-item" (click)="showTextPad()">
        <div class="app-icon pad-icon">
          <mat-icon fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
        </div>
        <span class="app-name">{{'VNC_PAD' | translate}}</span>
      </div>
      <div class="app-item" (click)="showSpreadsheet()">
        <div class="app-icon spreadsheet-icon">
          <mat-icon fontSet="mdi" fontIcon="mdi-table"></mat-icon>
        </div>
        <span class="app-name">{{'VNC_SHEET' | translate}}</span>
      </div>
    </div>
  </div>