import {Injectable} from "@angular/core";

@Injectable()
export class ArchiveWindowService {
  currentActiveTab = "channels";
  tabs = [
    // {
    //   label: "CHATS",
    //   active: false,
    //   value: "chats"
    // },
    {
      label: "CHANNELS",
      active: true,
      value: "channels"
    },
    {
      label: "TOPICS",
      active: false,
      value: "topics"
    }
  ];
  constructor() {
  }


  changeTab(val: string) {
    this.tabs.forEach(tab => {
      tab.active = tab.value === val;
    });
    this.currentActiveTab = val;
  }
}
