
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, OnDestroy, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject, take } from "rxjs";
import { CommonUtil } from "app/talk/utils/common.util";
import { Broadcaster } from "app/talk/shared/providers";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "vp-conference-confirmation",
    templateUrl: "./conference-confirmation.html"
})
export class ConferenceDialogComponent implements OnInit , OnDestroy {
    messageKey = "DO_YOU_WANT_TO_SAVE_THIS_ROOM";
    private isAlive$ = new Subject<boolean>();
    activeKey: string;
    params = {};
    requestTimer: any;
    password = "";
    message = "";
    isCancelMeeting: boolean;
    constructor(private broadcaster: Broadcaster,
        private dialog: MatDialogRef<ConferenceDialogComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            if (this.data.action === "remove_from_meeting") {
                this.messageKey = "REMOVE_FROM_GROUP_MSG";
                this.params = {displayName: this.data.displayName};
                this.activeKey = "REMOVE";
            } else if (this.data.action === "mute_for_everyone") {
                this.messageKey = "MUTE_FOR_EVERYONE_CONFIRMED";
                this.params = {displayName: this.data.displayName};
                this.activeKey = "MUTE";
            } else if (this.data.action === "stop_live_stream") {
                this.messageKey = "STOP_LIVE_STREAM_MSG";
                this.params = {displayName: this.data.displayName};
                this.activeKey = "STOP_LIVE_STREAM";
            } else if (this.data.action === "close_whiteboard") {
                this.messageKey = "DELETE_WHITEBOARD_MSG";
                this.params = {};
                this.activeKey = "DELETE";
            } else if (this.data.action === "request_share_screen") {
                this.messageKey = "SCREEN_SHARE_REQUEST";
                this.params = {fullname: data.fullname};
                this.activeKey = "ALLOW";
                this.requestTimer = setTimeout(() => {
                    this.dialog.close({allowed: false});
                }, 30000);
            } else if (this.data.action === "start_screenshare") {
                this.messageKey = "SHARESCREEN_MSG";
                this.params = {fullname: data.fullname};
                this.activeKey = "SHARE_SCREEN_NOW";
            } else if (this.data.action === "password_required") {
                this.messageKey = "CALL_PASSWORD_MSG";
                this.params = {};
                this.activeKey = "OK";
            } else if (this.data.action === "access_denied") {
                this.messageKey = "WRONG_CALL_PASSWORD";
                this.params = {};
                this.activeKey = "TRY_AGAIN";
            } else if (this.data.action === "enable_blur") {
                this.messageKey = "BLUR_FEATURE_MSG";
                this.params = {};
                this.activeKey = "CONTINUE";
            } else if (this.data.action === "cancel_meeting") {
                this.messageKey = "CANCEL_SCHEDULED_VIDEO_MEETING";
                this.params = {};
                this.activeKey = "YES";
            }
    }
    ngOnInit(): void {
        if (CommonUtil.isOnIpad) {
            this.broadcaster.broadcast("toggleBigVideo", true);
        }
    }

    ngOnDestroy() {
        if (CommonUtil.isOnIpad) {
            this.broadcaster.broadcast("toggleBigVideo", false);
        }
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    @HostListener("document:keydown.esc")
    cancel(): void {
        if (this.data.action === "request_share_screen") {
            this.dialog.close({allowed: false});
        } else {
            this.dialog.close();
        }
    }

    @HostListener("document:keydown.enter")
    action(): void {
        if (this.data.action === "remove_from_meeting") {
            this.dialog.close({isRemoved: true});
        } else if (this.data.action === "mute_for_everyone") {
            this.dialog.close({isMuted: true});
        } else if (this.data.action === "stop_live_stream") {
            this.dialog.close({stopped: true});
        } else if (this.data.action === "close_whiteboard") {
            this.dialog.close({closed: true});
        } else if (this.data.action === "request_share_screen") {
            if (this.requestTimer) {
                clearTimeout(this.requestTimer);
            }
            this.dialog.close({allowed: true});
        } else if (this.data.action === "start_screenshare") {
            this.dialog.close({sharescreen: true});
        } else if (this.data.action === "password_required") {
            if (this.password) {
                this.dialog.close({password: this.password});
            }
        }  else if (this.data.action === "enable_blur") {
            this.dialog.close({enableBlur: true});
        } else {
            this.dialog.close();
        }
    }

    goToChats() {
        this.dialog.close({goToChat: true});
    }

    cancelMeeting() {
        this.isCancelMeeting = true;
        this.messageKey = "";
        setTimeout(() => {
            if (!!document.getElementById("cancelMessage")) {
                document.getElementById("cancelMessage").focus();
            }
        }, 1000);
        this.translate.get("CANCEL_SCHEDULED_MEETING_MSG").pipe(take(1)).subscribe(v => {
            this.message = v;
        });
    }

    send() {
        this.dialog.close({cancelMeeting: true, message: this.message});
    }

    tryAgain() {
        this.data.action = "password_required";
        this.messageKey = "CALL_PASSWORD_MSG";
    }

}
