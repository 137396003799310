import {Injectable} from "@angular/core";
import {TopicCommentScroll} from "./channel-detail.component";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ChannelRepository} from "../repository/channel.repository";
import {Topic} from "../models/topic.model";
import {CommonUtil} from "../../talk/utils/common.util";
import {take} from "rxjs/operators";
import {TopicFilterComponentService, TopicGlobalFilter} from "./topic-filter/topic-filter.component.service";
import {SnackbarType} from "../models/snackbar.model";
import {ConversationRepository} from "../../talk/repositories/conversation.repository";
import {ChannelSnackbarService} from "../channel-snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {Broadcaster} from "../../talk/shared/providers";
import { ChannelService } from "../channel.service";

@Injectable()

export class ChannelDetailComponentService {
  constructor(
    private _router: Router,
    private _matDialog: MatDialog,
    private _channelRepository: ChannelRepository,
    private _topicFilterComponentService: TopicFilterComponentService,
    private _conversationRepo: ConversationRepository,
    private _channelSnackBarService: ChannelSnackbarService,
    private _translate: TranslateService,
    private _broadcaster: Broadcaster,
    private channelService: ChannelService,
  ) {
  }

  openTopic(topic, scrollToCommentSection: TopicCommentScroll) {
    this._router.navigateByUrl(`/talk/channels/${topic.channel_id}/topics/${topic.id}?comment=${scrollToCommentSection}`);
  }

  // async createTopic() {
  //   const options = {
  //     maxWidth: "100%",
  //     maxHeight: "100%",
  //     width: "100vw",
  //     height: "100vh"
  //   };
  //   const { CreateTopicComponent } = await import(
  //     /* webpackPrefetch: true */
  //     "../create-topic/create-topic.component");
  //   this._matDialog.open(CreateTopicComponent, Object.assign({
  //     backdropClass: "vnctalk-form-backdrop",
  //     panelClass: [ "vnctalk-form-panel", "vnctalk-create-topic-panel"],
  //     disableClose: true,
  //     data: {
  //       type: "regularTopic"
  //     },
  //     autoFocus: true
  //   }, options));
  // }

  async createTopic(type?: any) {
    const options = {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100vw",
      height: "100vh",
    };
    const dataItem: any = {};
    if (type) {
      dataItem.type =  type;
    }
    const { CreateTopicComponent } = await import(
      /* webpackPrefetch: true */
      "app/channels/create-topic/create-topic.component");
    this._matDialog.open(CreateTopicComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: [ "vnctalk-form-panel", "vnctalk-create-topic-panel"],
        disableClose: true,
        data: dataItem,
        autoFocus: true
       }, options)).afterClosed().subscribe((res) => {
        if (res) {
          if (!this._router.url.includes(res?.channel_id) && this._router.url.includes("/topics/")) {
            this._channelRepository.setSelectedChannelId(res.channel_id);
            this._router.navigateByUrl(`/talk/channels/${res?.channel_id}/topics/${res?.id}`);
          } else if (!this._router.url.includes(res?.channel_id)) {
            this._channelRepository.setSelectedChannelId(res.channel_id);
            this._router.navigateByUrl(`/talk/channels/${res?.channel_id}`);
          }
        }
      });
  }

  async editTopic(item: Topic) {
    item.description = item.description_plain;
    const options = {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100vw",
      height: "100vh"
    };
    const { CreateTopicComponent } = await import(
      /* webpackPrefetch: true */
      "../create-topic/create-topic.component");
    this._matDialog.open(CreateTopicComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: [ "vnctalk-form-panel", "vnctalk-create-topic-panel"],
      disableClose: true,
      data: item,
      autoFocus: true
    }, options)).afterClosed().subscribe((res) => {
      if (res) {
        if (!this._router.url.includes(res?.channel_id) && this._router.url.includes("/topics/")) {
          this._channelRepository.setSelectedChannelId(res.channel_id);
          this._router.navigateByUrl(`/talk/channels/${res?.channel_id}/topics/${res?.id}`);
        } else if (!this._router.url.includes(res?.channel_id)) {
          this._channelRepository.setSelectedChannelId(res.channel_id);
          this._router.navigateByUrl(`/talk/channels/${res?.channel_id}`);
        }
      }
    });
  }

  async cloneTheTopicNew(topic: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this._matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "CLONE_TOPIC_HEADER",
        bodyText: "CLONE_TOPIC_DESCRIPTION",
        okLabel: "CLONE",
        cancelLabel: "CANCEL"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.cloneTheTopic(topic);
          if (!!topic?.is_draft) {
            this._translate.get("TOPIC_UNPUBLISHED_CLONED").pipe(take(1)).subscribe(text => {
              this._channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
            });
          } else {

          }
        }
      }
    });
  }

  async cloneTheTopic(item) {
    this.channelService.cloneTopic(item.id).subscribe((resp: any) => {
      const options = {
        maxWidth: "100%",
        maxHeight: "100%",
        width: "100vw",
        height: "100vh"
      };
      item.clone = true;
      // resp.topic.clone = true;
      ;
      (async () => {
        const { CreateTopicComponent } = await import(
          /* webpackPrefetch: true */
          "../create-topic/create-topic.component");
        this._matDialog.open(CreateTopicComponent, Object.assign({
          backdropClass: "vnctalk-form-backdrop",
          panelClass: ["vnctalk-form-panel", "vnctalk-create-topic-panel"],
          disableClose: true,
          data: { topic: resp.topic, clone: true },
          autoFocus: true
        }, options)).afterClosed().subscribe((res) => {
          if (res) {
            this._channelRepository.addTopicIntoStore(res);
          } else {
            this._channelRepository.deleteTopic(resp.topic.id, item.channel_id, item.read, false, false, false);
          }
        });
      }
      )();
    });
  }

  likeTopic(item) {
    if (item?.archived) { return; }
    if (item && item.liked) {
      item.liked = false;
      item.likes_count = (item.likes_count || 0) - 1;
      this._channelRepository.unlikesTopic(item.id, item.is_iom ? item.channel_id : null)
        .pipe(take(1))
        .subscribe((topic: Topic) => {
          if (topic) {
            item = { ...item, likes_count: topic.likes_count, liked: topic.liked };
          }
        });
    } else {
      item.liked = true;
      item.likes_count = (item.likes_count || 0) + 1;
      this._channelRepository.likesTopic(item.id, item.is_iom ? item.channel_id : null)
        .pipe(take(1))
        .subscribe((topic: Topic) => {
          if (topic) {
            item = { ...item, likes_count: topic.likes_count, liked: topic.liked };
          }
        });
    }
  }


  mouseMoveAddTile() {
    const toolTip = <HTMLElement>document.querySelector(".create-topic-tooltip");
    const tileElement = <HTMLElement>document.querySelector(".add-default-view");
    if (toolTip) {
      if (tileElement) {
        /*if ((($event.offsetY + 50) > tileElement.clientHeight) || (($event.offsetX + 120) > tileElement.clientWidth)) {
          if (($event.offsetY + 50) > tileElement.clientHeight) {
            toolTip.style.top = ($event.offsetY - 50) + 'px';
          } else {
            toolTip.style.top = ($event.offsetY + 15) + 'px';
          }
          if (($event.offsetX + 120) > tileElement.clientWidth) {
            toolTip.style.left = ($event.offsetX - 120) + 'px';
          } else {
            toolTip.style.left = ($event.offsetX + 15) + 'px';
          }
        }
        else {
          toolTip.style.top = ($event.offsetY + 15) + 'px';
          toolTip.style.left = ($event.offsetX + 15) + 'px';
        }*/
        let top = ((tileElement.clientHeight / 2) - 45);
        toolTip.style.top = (top > 0 ? top : 0) + "px";
        toolTip.style.left = ((tileElement.clientWidth / 2) - 55) + "px";
      }
    }
  }

  mouseEnterAddTile() {
    const toolTip = <HTMLElement>document.querySelector(".create-topic-tooltip");
    if (toolTip) {
      toolTip.style.visibility = "visible";
    }
  }

  mouseLeaveAddTile() {
    const toolTip = <HTMLElement>document.querySelector(".create-topic-tooltip");
    if (toolTip) {
      toolTip.style.visibility = "hidden";
    }
  }

  showTopicInfo(item: Topic) {
    this._channelRepository.openSideBar("topic", item.id);
  }

  copyTopicLink(item: Topic) {
    this._channelRepository.copyLink("topic", item.channel_id, item.id);
  }

  subscribeTopic(item: Topic) {
    this._channelRepository.subscribeTopic(item.id);
  }

  async unsubscribeTopic(item: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }

    const bodyText = this._channelRepository.getTranslatedText("UNSUBSCRIBE_FROM_TOPIC_MSG", {key: "topicName", value: item?.subject});
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this._matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "UNSUBSCRIBE_FROM_TOPIC",
        bodyText,
        okLabel: "UNSUBSCRIBE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this._channelRepository.unsubscribeTopic(item.id).pipe(take(1)).subscribe();
        }
      }
    });
  }

  readTopic(item: Topic) {
    this._topicFilterComponentService.selectedGlobalFilters
      .pipe(take(1))
      .subscribe(filters => {
        if (!!filters && filters?.includes(TopicGlobalFilter.UNREAD)) {
          this._channelRepository.readTopic(item.id, true, true);
          return;
        }
        this._channelRepository.readTopic(item.id);
      });
  }

  unreadTopic(item: Topic) {
    this._channelRepository.unreadTopic(item.id);
  }

  async openSendTopicLinkPopup(item: Topic) {
    const { SendTopicLinkComponent } = await import(
      "../send-topic-link/send-topic-link.component");
    const sendTopicLinkRef = this._matDialog.open(SendTopicLinkComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "open-send-topic-link-popup"],
      width: "480px",
      height: "572px",
      autoFocus: true,
      data: {topic: item}
    });
    sendTopicLinkRef.afterClosed()
      .pipe(take(1))
      .subscribe(val => {
        if (val?.selectedUserIds?.length > 0) {
          let comment = `Hey, checkout this topic`;
          this._translate.get("HEY_CHECKOUT_THIS_TOPIC").pipe(take(1)).subscribe((text: string) => {
            comment = text + " :";
          });
          if (!!val?.comment) {
            comment = val.comment + " :";
          }
          const currentUrl = CommonUtil.getBaseOriginUrl();
          val?.selectedUserIds?.forEach((jId) => {
            const type = jId.includes("@conference") ? "groupchat" : "chat";
            this._conversationRepo.sendMessage({
              body: `${comment} ${currentUrl}/talk/channels/${item.channel_id}/topics/${item?.id}`,
              type: type
            }, jId, type);
          });
          this._translate.get("LINK_SENT").pipe(take(1)).subscribe((text: string) => {
            this._channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
        }
      });
  }

  async openMoveToChannelDialog(item: Topic) {
    const { MoveToChannelComponent } = await import(
      "../move-to-channel/move-to-channel.component");
    const moveToChannelRef = this._matDialog.open(MoveToChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      width: "480px",
      height: "572px",
      autoFocus: true,
      data: {topic: item}
    });
    moveToChannelRef.afterClosed()
      .pipe(take(1))
      .subscribe( (val: any)=> {
        this._channelRepository.updateTopic(item.id, {channel_id: val.channel.id}).pipe(take(1)).subscribe(
          () => {
            this._translate.get("TOPIC_MOVED_TO_SELECTED_CHANNEL", {channelName: "\"" + val.channel.name + "\""}).pipe(take(1)).subscribe(
              res => {
                this._channelRepository.moveTopic(item, val.channel, item?.channel_id);
                this._channelSnackBarService.openSnackBar(res, SnackbarType.CHECKMARK);
                this._channelRepository.setSelectedChannelId(val.channel.id);
                this._router.navigateByUrl(this._router.url.replace(item.channel_id, val.channel.id));
              }
            );
          }
        );
      });
  }

  async bulkMoveToChannel() {
    const { MoveToChannelComponent } = await import(
      "../move-to-channel/move-to-channel.component");
    const moveToChannelRef = this._matDialog.open(MoveToChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      width: "480px",
      height: "572px",
      autoFocus: true,
    });
     return moveToChannelRef.afterClosed();
  }

  async openCloneToChannelDialog(item: Topic) {
    let width = "480px";
    let height = "572px";
    let maxWidth = "80vw";
    if (CommonUtil.isMobileSize()) {
      width = "100vw";
      height = "100vh";
      maxWidth = "100vw";
    }
    const { MoveToChannelComponent } = await import(
      "../move-to-channel/move-to-channel.component");
    const moveToChannelRef = this._matDialog.open(MoveToChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass   : "vnctalk-form-panel",
      width        : width,
      height       : height,
      maxWidth     : maxWidth,
      autoFocus    : true,
      data         : {topic: item, clone: true}
    });
    moveToChannelRef.afterClosed()
    .subscribe((val: any) => {
      this._channelRepository.cloneTopicToChannel(item.id, val.channel.id);
    });
  }

  async archiveTopic(item: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this._matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "ARCHIVE_TOPIC",
        bodyText: "ARCHIVE_TOPIC_MSG",
        okLabel: "ARCHIVE"
      },
      autoFocus: true
    }, options))
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this._channelRepository.archiveTopic(item.id, item.channel_id, item.read);
          }
        }
      });
  }

  async deleteTopic(item: Topic, moveToTrash = false) {
    if (moveToTrash) {
      this._channelRepository.deleteTopic(item.id, item.channel_id, item.read, true, true);
      return;
    }
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this._matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "DELETE_TOPIC",
        bodyText: "DELETE_TOPIC_MSG",
        okLabel: "DELETE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this._channelRepository.
          deleteTopic(item.id, item.channel_id, item.read, true, true);
        }
      }
    });
  }


  async cloneTopic(topic: Topic) {
    this._channelRepository.cloneTopic(topic.id);
  }

  openMobileTopic(topic): void {
    this._router.navigateByUrl(`/talk/channels/${topic.channel_id}/topics/${topic.id}?comment=none`);
  }

  addComment(topic): void {
    this._router.navigateByUrl(`/talk/channels/${topic.channel_id}/topics/${topic.id}`);
    setTimeout(() => {
      this._broadcaster.broadcast("SCROLL_TO_COMMENT");
    }, 1000);
  }

  shareChannel(item: Topic, isTopicLink: boolean = false): void {
    let currentUrl = CommonUtil.getBaseOriginUrl();
    if (isTopicLink) {
      currentUrl = `${currentUrl}/talk/channels/${item.channel_id}/topics/${item.id}`;
    } else {
      currentUrl = `${currentUrl}/talk/channels/${item.id}`;
    }
    this._channelRepository.shareLink(currentUrl);
  }
}
