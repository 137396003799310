import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import { Action } from "app/actions";
import {FollowActionTypes} from "../actions/follow";
import { Follow } from "../models/follow.model";


export interface FollowState extends EntityState<Follow> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const followAdapter: EntityAdapter<Follow> = createEntityAdapter<Follow>({
  selectId: (follow: Follow) => follow.id,
});

export const initialState: FollowState = followAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
});

export function followReducer(state: FollowState = initialState, action: Action): FollowState {
  switch (action.type) {
    case FollowActionTypes.FOLLOWERS_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FollowActionTypes.FOLLOWERS_LOAD_SUCCESS: {
      const newState = followAdapter.addMany(action.payload, state);
      const changes = action.payload.map(follower => {
        return { id: follower.id, changes: follower };
      });
      return followAdapter.updateMany(changes, {...newState, isLoading: false});
    }
    case FollowActionTypes.FOLLOWERS_ADD: {
      const newState = followAdapter.addOne(action.payload, state);
      return followAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }
    case FollowActionTypes.FOLLOWERS_REMOVE: {
      return followAdapter.removeOne(action.payload, state);
    }
    default: {
      return state;
    }
  }
}

export const _getIsFollowLoading = (state: FollowState) => state.isLoading;
export const _getIsFollowLoaded = (state: FollowState) => state.isLoaded;
