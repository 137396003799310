import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvatarComponent } from "./talk/shared/components";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
@NgModule({
    declarations: [AvatarComponent],
    imports: [ CommonModule, MatIconModule, MatTooltipModule, TranslateModule ],
    exports: [AvatarComponent],
    providers: [],
})
export class GlobalSharedModule {}