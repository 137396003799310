
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Conversation, ConversationConfig } from "../models/conversation.model";
import { Action } from "../../actions";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ConversationActionTypes } from "../actions/conversation";
import { JID } from "../models/jid.model";
import { Message } from "../models/message.model";
import { MessageActionTypes } from "../actions/message";
import { AppActionTypes } from "../../actions/app";
import { JitsiOption } from "../models/jitsi-participant.model";
import { CommonUtil } from "../utils/common.util";

export interface ConversationState extends EntityState<Conversation> {
  isLoading: boolean;
  isLoaded: boolean;

  selectedChatTab:any;

  configLoaded: { [conversationTarget: string]: JID[] };

  blockedBareIds: string[];

  // Target of Conversations which have show details enabled
  detailsVisibleBareIds: string[];

  // Conversations which have already been opened for the first time
  activatedConversations: string[];

  // Selected Conversation for which messages will be shown.
  selectConversationId: string | null;
  selectMiniConversationId: string | null;
  whiteboard: any;

  members: { [conversationTarget: string]: string[] };
  meta_conference_boards: { [conversationTarget: string]: any[] };
  owner: { [conversationTarget: string]: string };
  admins: { [conversationTarget: string]: string[] };
  audiences: { [conversationTarget: string]: string[] };
  favorites: { [conversationTarget: string]: string[] };
  roomIds: { [conversationTarget: string]: JitsiOption };
  lastActivity: { [conversationTarget: string]: string };
  lastText: { [conversationTarget: string]: string };
  messageToCopy: any;
  configs: { [conversationTarget: string]: ConversationConfig };
  notificationConfig: { [conversationTarget: string]: {muteIncomingCall?: boolean, muteEverything?: boolean, muteSound?: boolean, muteNotification?: boolean} };
  fileInProgress: { [messageId: string]: {loaded: number, total: number} };
  selectedMessageIds: string[];
  padNotifications: string[];
  processingE2EMessages: string[];
  whiteboardNotifications: string[];
  activeConversation: Conversation;
  pinConversationsList: Conversation[];
  downloadInProgress: { [messageId: string]: {loaded: number, total: number, status: string, convoTarget?: string, convoType?: string, url?: string, fileName?: string} };
}

export const conversationAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
  selectId: (conversation: Conversation) => conversation.Target,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(conv1: Conversation, conv2: Conversation): number {
  if (conv2.ispinned && !conv1.ispinned) {
    return conv2.Timestamp * 9 - conv1.Timestamp || conv2.Target.localeCompare(conv1.Target);
  } else if (conv1.ispinned && !conv2.ispinned) {
    return conv2.Timestamp - conv1.Timestamp * 9 || conv2.Target.localeCompare(conv1.Target);
  }
  return  conv2.Timestamp - conv1.Timestamp || conv2.Target.localeCompare(conv1.Target);
}

export const initialState: ConversationState = conversationAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedChatTab: null,
  configLoaded: {},

  blockedBareIds: [],
  mutedBareIds: [],
  soundOffBareIds: [],
  pinConversationsList:[],
  detailsVisibleBareIds: [],

  activatedConversations: [],

  selectConversationId: null,
  processingE2EMessages: [],
  selectMiniConversationId: null,
  whiteboard: {},
  lastText: {},
  lastActivity: {},
  members: {},
  owner: {},
  admins: {},
  audiences: {},
  meta_conference_boards: {},
  favorites: {},
  roomIds: {},
  background: {},
  configs: {},
  notificationConfig: {},
  selectedMessageIds: [],
  fileInProgress: {},
  notificationSettings: {},
  activeConversation: null,
  padNotifications: [],
  whiteboardNotifications: [],
  messageToCopy: {},
  downloadInProgress: {},
});

export function conversationReducer(state: ConversationState = initialState, action: Action): ConversationState {
  switch (action.type) {

    case MessageActionTypes.MESSAGE_ADD: {
      const payload = action.payload;
      const conversationTarget = payload.conversationTarget;
      const message = payload.message as Message;

      if (message.type === "CHAT.JOIN" || message.type === "CHAT.LEAVE" || message.type === "CHAT.KICK" || message.mucInvite) {
        return state;
      }

      const conversation = state.entities[conversationTarget];

      if (!conversation) {
        return state;
      }

      const skipUpdate = conversation.Timestamp - 1 > message.timestamp; // because we increased this value in different place

      if (skipUpdate) {
        return state;
      }

      const newContent = CommonUtil.processMessageBody(message);
      // console.log("MESSAGE_ADD conversationReducerUpdate-" + conversationTarget + ":", conversation.content, conversation, newContent);
      if ((message.id === conversation.last_message_id) && (conversation.content !== "Encrypted message") && (newContent === "Encrypted message")) {
        return state;
      }

      let changes: any = {
        Timestamp: message.timestamp,
        last_message_id: message.id,
        content: newContent,
        archived: false
      };
      if (message.vncTalkConference) {
        changes.conferenceType = message.vncTalkConference.conferenceType;
      }
      if (action.payload.incoming)
      {
        changes["incoming"] = action.payload.incoming;
      }
      changes["ended_call_time"] = conversation.ended_call_time;
      return {
        ...conversationAdapter.updateOne({
          id: conversationTarget, changes: changes
        }, state),
      };
    }


    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD:
    case MessageActionTypes.MULTI_CONVERSATION_UPDATE_LAST_MESSAGE: {
      const data = action.payload;
      let localState = {...state};
      const conversations = [];
      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const message = item.message as Message;
        const conversation = localState.entities[conversationTarget];
        // console.log("MULTI_CONVERSATION conversationReducerUpdate-" + conversationTarget + ":", conversation.content, conversation.last_message_id, message.id, conversation, message);
        if (!(message.type === "CHAT.JOIN" || message.type === "CHAT.LEAVE" || message.type === "CHAT.KICK" || message.mucInvite)
            && conversation
            && ((message.timestamp + 2000 > conversation.Timestamp) || (message.id === conversation.last_message_id && message.body !== conversation.content && message.body !== "Encrypted message"))) {

            let archiveChanges = false;
            if(message?.type == "groupchat") {
              if(message?.group_action?.type === "archived") {
                archiveChanges = true;
              };
              if(message?.group_action?.type === "unarchived") {
                archiveChanges = false;
              };
            };
          const changes = {
            Timestamp: message.timestamp,
            last_message_id: message.id,
            content: ((conversation.last_message_id === message.id) && conversation.content !== "Encrypted message") ? conversation.content : CommonUtil.processMessageBody(message),
            historystate: "NONE",
            archived: archiveChanges
          };


          if (!message.vncTalkConference) {
            changes["conferenceType"] = "chat";
          } else {
            changes["conferenceType"] = message.vncTalkConference.conferenceType;
            if (!!message.vncTalkConference.eventType) {
              switch(message.vncTalkConference.eventType) {
                case "leave" : changes.historystate = "ENDED_CALL"; break;
                case "join": changes.historystate = "JOINED_CALL"; break;
                case "invite": changes.historystate = "STARTED_CALL"; break;
              }
            }
          }
          if (!!message.body && (message.body.startsWith("http://") || message.body.startsWith("https://") || message.body.startsWith("ftp://"))) {
            changes.historystate = "LINK";
          }
          if (!!message.attachment && !!message.attachment.fileType) {
            if (CommonUtil.isImage(message.attachment.fileType)) {
              changes.historystate = "PHOTO";
            } else if (CommonUtil.isSupportedVideo(message.attachment.fileType)) {
              changes.historystate = "VIDEO";
            } else if (CommonUtil.isAudio(message.attachment.fileType)) {
              changes.historystate = "VOICE_MESSAGE";
            } else if (CommonUtil.isVCFfile(message.attachment.fileType)) {
              changes.historystate = "VCF";
            } else {
              changes.historystate = "DOCUMENT";
            }
          }


          if (!!message.group_action && !!message.group_action.type) {
            try {
              const group_action = JSON.parse(message.group_action.type);
              if (!!group_action.reason && group_action.reason === "MISSED_CALL") {
                changes.historystate = "MISSED_CALL";
              }
            } catch (error) {

            }
          }

          changes["ended_call_time"] = conversation.ended_call_time;

          if (!item.incoming) {
            changes["incoming"] = item.incoming;
          } else {
            changes["incoming"] = true;
            changes["received_receipt"] = true;
          }
          conversations.push({...conversation, ...changes});
        }

      });

      return conversationAdapter.upsertMany(conversations, localState);
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let localState = {...state};

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const messages = item.messages || [];
        const conversation = localState.entities[conversationTarget];


        if (messages.length > 0 )
        {
          messages.sort((msg1, msg2) => msg1.timestamp -  msg2.timestamp);
          const lastMessage = messages[messages.length - 1];

          if (!(lastMessage.type === "CHAT.JOIN" || lastMessage.type === "CHAT.LEAVE" || lastMessage.type === "CHAT.KICK" || lastMessage.mucInvite)
          && conversation && (lastMessage.timestamp > conversation.Timestamp || (lastMessage.id === conversation.last_message_id && conversation.content !== lastMessage.body  && lastMessage.body !== "Encrypted message") )) {

            localState = {
              ...conversationAdapter.updateOne({
                id: conversationTarget, changes: {
                  Timestamp: lastMessage.timestamp,
                  last_message_id: lastMessage.id,
                  content: CommonUtil.processMessageBody(lastMessage),
                  archived: false
                }
              }, localState),
            };
          }
        }

      });
      return {...localState};
    }

    case MessageActionTypes.UPDATE_PROCESSING_MESSAGES: {
      return {
        ...state,
        processingE2EMessages: action.payload
      };
    }

    case MessageActionTypes.ADD_PROCESSING_MESSAGES: {
      return {
        ...state,
        processingE2EMessages: [...state.processingE2EMessages, ...action.payload]
      };
    }

    case MessageActionTypes.REMOVE_PROCESSING_MESSAGE: {
      return {
        ...state,
        processingE2EMessages: state.processingE2EMessages.filter(v => v !== action.payload)
      };
    }


    case MessageActionTypes.SELECT_MESSAGE: {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        selectedMessageIds: CommonUtil.uniq([
          ...state.selectedMessageIds,
          action.payload
        ])
      };
    }

    case MessageActionTypes.UNSELECT_MESSAGE: {
      const messageId = action.payload;
      let selectedMessageIds = state.selectedMessageIds.filter(id => messageId !== id);
      return {
        ...state,
        selectedMessageIds: selectedMessageIds
      };
    }

    case MessageActionTypes.RESET_MESSAGES: {
      return {
        ...state,
        selectedMessageIds: []
      };
    }

    case ConversationActionTypes.CONVERSATION_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ConversationActionTypes.CONVERSATION_LOAD_SUCCESS: {

      const newState = {
        ...state,
        isLoaded: true
      };

      return conversationAdapter.addMany(action.payload, newState);
    }

    case ConversationActionTypes.SELECTED_CHAT_TAB: {
      if (action.payload?.request) {
        return conversationAdapter.updateOne({
          id: action.payload?.target,
          changes: { selectedChatTab: action.payload?.flag }
        }, state);
      }
      else return;
    }

    case ConversationActionTypes.CONVERSATION_NEXT_LOAD_SUCCESS: {
      const newState = conversationAdapter.addMany(action.payload.conversations, {
        ...state,
        isLoaded: true
      });
      const changes = action.payload.conversations.map(conv => {
        return { id: conv.Target, changes: conv };
      });

      return conversationAdapter.updateMany(changes, newState);
    }

    case ConversationActionTypes.CONVERSATION_CREATE: {
      return conversationAdapter.addOne(action.payload, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_SUBJECT: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { groupChatTitle: action.payload.subject }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_DATA_UPDATE: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { data: action.payload.data }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_CONTENT_UPDATE: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { content: action.payload.content }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_CALL_FLAG: {
      if (!action.payload.flag) {
        return conversationAdapter.updateOne({
          id: action.payload.target,
          changes: { has_active_call: action.payload.flag, ended_call_time: new Date().getTime() }
        }, state);
      } else {
        return conversationAdapter.updateOne({
          id: action.payload.target,
          changes: { has_active_call: action.payload.flag }
        }, state);
      }
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_FAV_FLAG: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { isfav: action.payload.flag }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_PIN_FLAG: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { ispinned: action.payload.flag }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_PIN_ORDER: {
      const newState = conversationAdapter.addMany(action.payload.conversations, {
        ...state,
        isLoaded: true
      });
      const changes = action.payload.conversations.map(conv => {
        return { id: conv.Target, changes: conv };
      });
      return conversationAdapter.updateMany(changes, newState);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_AUDIENCES: {
      const conversationTarget = action.payload.target;
      const exitingMembers = [];
      const newMembers = action.payload.audiences;
      const audiences = [...exitingMembers, ...newMembers];
      return {
        ...state,
        audiences: {
          ...state.audiences,
          [conversationTarget]: CommonUtil.uniq(audiences)
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_MCB: {
      const conversationTarget = action.payload.target;
      const exitingMembers = [];
      const newMembers = action.payload.meta_conference_boards;
      const meta_conference_boards = [...exitingMembers, ...newMembers];
      return {
        ...state,
        meta_conference_boards: {
          ...state.meta_conference_boards,
          [conversationTarget]: CommonUtil.uniq(meta_conference_boards)
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_TITLE: {
      return conversationAdapter.updateOne({
        id: action.payload.conversationTarget,
        changes: { "broadcast_title": action.payload.title }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_AUDIENCE: {
      return conversationAdapter.updateOne({
        id: action.payload.conversationTarget,
        changes: { "audience": action.payload.audience }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_DATA: {
      return conversationAdapter.updateOne({
        id: action.payload.conversationTarget,
        changes: action.payload.changes
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_INACTIVE: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { "state": "inactive" }
      }, state);
    }


    case MessageActionTypes.MESSAGE_DELETED_STATUS_UPDATE: {
      return conversationAdapter.updateOne({
        id: action.payload.convTarget,
        changes: { content: ""}
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_INITIAL_ACTIVE: {
      return {
        ...state,
        activatedConversations: CommonUtil.uniq([
          ...state.activatedConversations,
          action.payload
        ])
      };
    }

    case ConversationActionTypes.CONVERSATION_INITIAL_DEACTIVATE: {
      return {
        ...state,
        activatedConversations: state.activatedConversations.filter(c => c !== action.payload)
      };
    }

    case ConversationActionTypes.CONVERSATION_RESET_ALL_ACTIVATED: {
      return {
        ...state,
        activatedConversations: []
      };
    }

    case ConversationActionTypes.CONVERSATION_SELECT: {
      return {
        ...state,
        selectConversationId: action.payload
      };
    }
    case ConversationActionTypes.MINI_CONVERSATION_SELECT: {
      return {
        ...state,
        selectMiniConversationId: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_LAST_ACTIVITY: {
      return {
        ...state,
        lastActivity: {
          ...state.lastActivity,
          [action.payload.target] : action.payload.seconds
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_LAST_ACTIVITY_BULK: {
      const data = action.payload;

      let localState = { ...state };
      data.forEach(conv => {
        localState = {
          ...localState,
          lastActivity: {
            ...localState.lastActivity,
            [conv.target]: conv.seconds
          }
        };
      });

      return localState;
    }


    case ConversationActionTypes.CONVERSATION_ADD_PAD_NOTIFICATIONS: {
      const padNotifications = state.padNotifications;
      if (padNotifications.indexOf(action.payload) === -1) {
        padNotifications.push(action.payload);
      }
      return {
        ...state,
        padNotifications: padNotifications
      };
    }

    case ConversationActionTypes.CONVERSATION_ADD_WB_NOTIFICATIONS: {
      const whiteboardNotifications = state.whiteboardNotifications;
      if (whiteboardNotifications.indexOf(action.payload) === -1) {
        whiteboardNotifications.push(action.payload);
      }
      return {
        ...state,
        whiteboardNotifications: whiteboardNotifications
      };
    }

    case ConversationActionTypes.CONVERSATION_REMOVE_PAD_NOTIFICATIONS: {
      const padNotifications = state.padNotifications.filter(v => v !== action.payload);
      return {
        ...state,
        padNotifications: padNotifications
      };
    }

    case ConversationActionTypes.CONVERSATION_REMOVE_WB_NOTIFICATIONS: {
      const whiteboardNotifications = state.whiteboardNotifications.filter(v => v !== action.payload);
      return {
        ...state,
        whiteboardNotifications: whiteboardNotifications
      };
    }

    case ConversationActionTypes.CONVERSATION_DE_SELECT: {
      return {
        ...state,
        selectConversationId: null
      };
    }

    case ConversationActionTypes.CONVERSATION_DELETE: {
      return conversationAdapter.removeOne(action.payload, state);
    }


    case ConversationActionTypes.CONVERSATION_MULTIPLE_DELETE: {
      return conversationAdapter.removeMany(action.payload, state);
    }


    case ConversationActionTypes.CONVERSATION_UPDATE_NOTIFICATION_SETTING: {
      const convTarget = action.payload.conversationTarget as string;
      const type = action.payload.type as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          mute_notification: type
        }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_RETENTION_TIME: {
      const convTarget = action.payload.conversationTarget as string;
      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          retention_time: action.payload.retention_time
        }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_SOUND_SETTING: {
      const convTarget = action.payload.conversationTarget as string;
      const type = action.payload.type as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          mute_sound: type
        }
      }, state);
    }


    case ConversationActionTypes.CONVERSATION_BLOCK_LIST_INDEX: {
      return {
        ...state,
        blockedBareIds: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_BLOCK_LIST_ADD: {
      const blockedBareIds = [
        ...state.blockedBareIds,
        action.payload
      ];
      return {
        ...state,
        blockedBareIds: CommonUtil.uniq(blockedBareIds)
      };
    }

    case ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_ADD: {
      const blockedBareIds = [
        ...state.blockedBareIds,
        ...action.payload
      ];
      return {
        ...state,
        blockedBareIds: CommonUtil.uniq(blockedBareIds)
      };
    }

    case ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_REMOVE: {

      let newBlockedBareIds = state.blockedBareIds;

      if (newBlockedBareIds) {
        newBlockedBareIds = newBlockedBareIds.filter(item => action.payload.indexOf(item) === -1);
      }

      return {
        ...state,
        blockedBareIds: newBlockedBareIds
      };
    }

    case ConversationActionTypes.CONVERSATION_BLOCK_LIST_REMOVE: {

      let newBlockedBareIds = state.blockedBareIds;

      if (newBlockedBareIds) {
        newBlockedBareIds = newBlockedBareIds.filter(item => item !== action.payload);
      }

      return {
        ...state,
        blockedBareIds: CommonUtil.uniq(newBlockedBareIds)
      };
    }

    case ConversationActionTypes.CONVERSATION_SHOW_DETAILS: {
      return {
        ...state,
        detailsVisibleBareIds: [
          ...state.detailsVisibleBareIds,
          action.payload
        ]
      };
    }

    case ConversationActionTypes.CONVERSATION_HIDE_DETAILS: {
      return {
        ...state,
        detailsVisibleBareIds: state.detailsVisibleBareIds.filter(v => v === action.payload)
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_MEMBERS: {
      // console.trace("[CONVERSATION_UPDATE_MEMBERS]");

      const conversationTarget = action.payload.conversationTarget;
      // const exitingMembers = state.members[conversationTarget] || [];
      const exitingMembers = [];
      const newMembers = action.payload.members;
      const members = [...exitingMembers, ...newMembers];
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: CommonUtil.uniq(members)
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_OWNER: {
      const conversationTarget = action.payload.conversationTarget;
      const newOwner = action.payload.owner;
      return {
        ...state,
        owner: {
          ...state.owner,
          [conversationTarget]: newOwner
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_ADMINS: {
      const conversationTarget = action.payload.conversationTarget;
      const newAdmins = action.payload.admins;
      return {
        ...state,
        admins: {
          ...state.admins,
          [conversationTarget]:  CommonUtil.uniq(newAdmins)
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_JITSI_ROOM: {
      const conversationTarget = action.payload.conversationTarget;
      return {
        ...state,
        roomIds: {
          ...state.roomIds,
          [conversationTarget]: action.payload.option
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_ROOM_IDS: {
      return {
        ...state,
        roomIds: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_UPLOADING_FILE: {
      const messageId = action.payload.messageId;
      const progress = action.payload.progress;
      return {
        ...state,
        fileInProgress: {
          ...state.fileInProgress,
          [messageId]: progress
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_FILE_UPLOADED: {
      const messageId = action.payload.messageId;
      let fileInProgress = state.fileInProgress;
      delete fileInProgress[messageId];
      return {
        ...state,
        fileInProgress: fileInProgress
      };
    }


    case ConversationActionTypes.MULTI_CONVERSATION_UPDATE_MEMBERS: {
      const data = action.payload;
      let localState = {...state};

      localState = {
        ...localState,
        members: {
          ...localState.members,
          ...data
        }
      };

      return localState;

    }

    case ConversationActionTypes.MULTI_CONVERSATION_UPDATE_OWNER: {
      const data = action.payload;
      let localState = {...state};

      localState = {
        ...localState,
        owner: {
          ...localState.owner,
          ...data
        }
      };

      return localState;

    }

    case ConversationActionTypes.MULTI_CONVERSATION_UPDATE_ADMINS: {
      const data = action.payload;
      let localState = {...state};

      localState = {
        ...localState,
        admins: {
          ...localState.admins,
          ...data
        }
      };

      return localState;
    }

    case ConversationActionTypes.MULTI_CONVERSATION_UPDATE_AUDIENCE: {
      const data = action.payload;
      let localState = { ...state };

      localState = {
        ...localState,
        audiences: {
          ...localState.audiences,
          ...data
        }
      };

      return localState;
    }


    case ConversationActionTypes.CONVERSATION_REMOVE_MEMBER: {
      // console.trace("[CONVERSATION_REMOVE_MEMBER]");

      const conversationTarget = action.payload.conversationTarget;
      const exitingMembers = state.members[conversationTarget] || [];
      const exitingAdmins = state.admins[conversationTarget] || [];
      const exitingAudiences = state.audiences[conversationTarget] || [];
      const removedMember = action.payload.member;
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: CommonUtil.uniq(exitingMembers.filter(m => m !== removedMember ))
        },
        admins: {
          ...state.admins,
          [conversationTarget]: CommonUtil.uniq(exitingAdmins.filter(m => m !== removedMember ))
        },
        audiences: {
          ...state.audiences,
          [conversationTarget]: CommonUtil.uniq(exitingAudiences.filter(m => m !== removedMember ))
        },
        owner: {
          ...state.owner,
          [conversationTarget]: state.owner[conversationTarget] === removedMember ? null : state.owner[conversationTarget]
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_REMOVE_MEMBERS: {
      // console.trace("[CONVERSATION_REMOVE_MEMBERS]");

      const conversationTarget = action.payload.conversationTarget;
      const exitingMembers = state.members[conversationTarget] || [];
      const exitingAdmins = state.admins[conversationTarget] || [];
      const members = action.payload.members;
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: CommonUtil.uniq(exitingMembers.filter(m => members.indexOf(m) === -1 ))
        },
        admins: {
          ...state.admins,
          [conversationTarget]: CommonUtil.uniq(exitingAdmins.filter(m => members.indexOf(m) === -1 ))
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_ADD_MEMBERS: {
      // console.trace("[CONVERSATION_ADD_MEMBERS]");

      const conversationTarget = action.payload.conversationTarget;
      const exitingMembers = state.members[conversationTarget] || [];
      const members = action.payload.members;
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: CommonUtil.uniq([...exitingMembers, ...members])
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_TEXT: {
      const conversationTarget = action.payload.conversationTarget;
      return {
        ...state,
        lastText: {
          ...state.lastText,
          [conversationTarget]: {text: action.payload.text, originalMessage: action.payload.originalMessage}
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_MESSAGE_TO_DELETE: {
      let messageToCopy = null;
      if (action.payload) {
        const conversationTarget = action.payload?.conversationTarget;
        const text = action.payload?.text;
        const messageId = action.payload?.messageId;
        messageToCopy = {
          conversationTarget: conversationTarget,
          text: text,
          messageId: messageId
        };
      }
      return {
        ...state,
        messageToCopy: messageToCopy
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_CONFIG: {
      const conversationTarget = action.payload.conversationTarget;
      const config = action.payload.config;

      return {
        ...state,
        configs: {
          ...state.configs,
          [conversationTarget]: config
        }
      };
    }
    case ConversationActionTypes.CONVERSATION_SET_NOTIFICATION_CONFIG: {
      const conversationTarget = action.payload.conversationTarget;
      const config = action.payload.config;
      const oldConfig = state.notificationConfig[conversationTarget] || {};
      const newConfig = {...oldConfig, ...config};
      return {
        ...state,
        notificationConfig: {
          ...state.notificationConfig,
          [conversationTarget]: newConfig
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_ALL_NOTIFICATION_CONFIG: {
      return {
        ...state,
        notificationConfig: action.payload || {}
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.conversationState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case ConversationActionTypes.ARCHIVE_CONVERSATION: {
      const convTarget = action.payload.target as string;
      const timestamp = action.payload.timestamp as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          archived: true,
          Timestamp: timestamp
        }
      }, state);
    }

    case ConversationActionTypes.UPDATE_CONVERSATION: {
      const convTarget = action.payload.target as string;
      const changes = action.payload.changes;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: changes
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_RESET_UNREAD: {
      const conversationTarget = action.payload;

      return conversationAdapter.updateOne({
        id: conversationTarget,
        changes: {
          unreadIds: [],
          unread_mentions: []
        }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_SET_UNREAD: {
      const conversationTarget = action.payload;

      return conversationAdapter.updateOne({
        id: conversationTarget,
        changes: {
          unreadIds: action.payload.unreadIds,
          last_mention_stamp: action.payload.last_mention_stamp,
          unread_mentions: action.payload.unread_mentions,
          total_mentions: action.payload.total_mentions
        }
      }, state);
    }

    case ConversationActionTypes.UNARCHIVE_CONVERSATION: {
      const convTarget = action.payload.target as string;
      const timestamp = action.payload.timestamp as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          archived: false,
          Timestamp: timestamp
        }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_LAST_ACTIVITY: {
      const lastActivity = state.lastActivity;
      return {
        ...state,
        lastActivity: {...lastActivity, ...action.payload}
      };
    }

    case ConversationActionTypes.CONVERSATION_LAST_TEXT: {
      return {
        ...state,
        lastText: action.payload
      };
    }

    case ConversationActionTypes.SET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        activeConversation: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_DOWNLOADING_FILE: {
      const messageId = action.payload.messageId;
      let progress = action.payload.progress;
      let downloadInProgress = state.downloadInProgress[messageId];
      if(downloadInProgress){
        progress.convoTarget = downloadInProgress.convoTarget;
        progress.convoType = downloadInProgress.convoType;
        progress.url = downloadInProgress.url;
        progress.fileName = downloadInProgress.fileName;
      }
      if(downloadInProgress && progress.status == "done"){
        progress.loaded = downloadInProgress.loaded;
        progress.total = downloadInProgress.total;
      }
      return {
        ...state,
        downloadInProgress: {
          ...state.downloadInProgress,
          [messageId]: progress
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_RESET_DOWNLOAD: {
      return {
        ...state,
        downloadInProgress: {}
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoading = (state: ConversationState) => state.isLoading;
export const _getIsLoaded = (state: ConversationState) => state.isLoaded;

export const _getSelectedConversationId = (state: ConversationState) => state.selectConversationId;
export const _getSelectedMiniConversationId = (state: ConversationState) => state.selectMiniConversationId;

export const _getIsActivatedConversation = (state: ConversationState, conversationTarget: string) => {
  return state.activatedConversations.indexOf(conversationTarget) !== -1;
};

export const _getSelectedMessageIds = (state: ConversationState) => state.selectedMessageIds || [];
export const _getBlockedBareIds = (state: ConversationState) => state.blockedBareIds;
export const _getIsConversationBlocked = (state: ConversationState, bareId: string) => state.blockedBareIds.indexOf(bareId) !== -1;

export const _getIsConversationDetailsVisible = (state: ConversationState, bareId: string) => state.detailsVisibleBareIds.indexOf(bareId) !== -1;

export const _getConversationMembers = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.members[conversationTarget] ? state.members[conversationTarget].filter(v => !!v) : [];
};

export const _getConversationAllMembers = (state: ConversationState, conversationTarget: string) => {
  if (!conversationTarget) {
    return [];
  }
  const members = state.members[conversationTarget] || [];
  const admins = state.admins[conversationTarget] || [];
  const owner = state.owner[conversationTarget];
  const audiences = state.audiences[conversationTarget] || [];

  const allMembers = [...members, ...admins, ...audiences];
  allMembers.push(owner);
  return CommonUtil.uniq(allMembers.filter(v => !!v));
};

export const _getConversationOwner = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.owner[conversationTarget] ? state.owner[conversationTarget] : null;
};

export const _getConversationAdmins = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.admins[conversationTarget] ? state.admins[conversationTarget].filter(v => !!v) : [];
};

export const _getConversationAudiences = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.audiences[conversationTarget] ? state.audiences[conversationTarget].filter(v => !!v) : [];
};

export const _getConversationMCBs = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.meta_conference_boards[conversationTarget] ? state.meta_conference_boards[conversationTarget].filter(v => !!v) : [];
};

export const _getConversationText = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.lastText[conversationTarget] ? state.lastText[conversationTarget] : {};
};

export const _getConversationConfig = (state: ConversationState, conversationTarget: string) => state.configs[conversationTarget];
export const _getConversationNotificationConfig = (state: ConversationState, conversationTarget: string) => state.notificationConfig[conversationTarget];
export const _getAllNotificationConfig = (state: ConversationState) => state.notificationConfig;

export const _getConversationConfigLoaded = (state: ConversationState, conversationTarget: string) => state.configLoaded[conversationTarget];
export const _getFileInProgressByMessageId = (state: ConversationState, messageId: string) => state.fileInProgress[messageId];
export const _getLastActivityByTarget = (state: ConversationState, target: string) => state.lastActivity[target];

export const _getLastActivity = (state: ConversationState) => state.lastActivity;
export const _getLastText = (state: ConversationState) => state.lastText;
export const _getMessageToCopy = (state: ConversationState) => state.messageToCopy;
export const _getActiveConversation = (state: ConversationState) => state.activeConversation;

export const _getConversationRoomIds = (state: ConversationState) => state.roomIds;
export const _getPadNotifications = (state: ConversationState) => state.padNotifications;
export const _getWBNotifications = (state: ConversationState) => state.whiteboardNotifications;
export const _getProcessingE2EMessages = (state: ConversationState) => state.processingE2EMessages;

export const _getConversationRoomId = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.roomIds[conversationTarget];
};

export const _hasNotification = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.padNotifications.indexOf(conversationTarget) !== -1;
};
export const _hasWBNotification = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.whiteboardNotifications.indexOf(conversationTarget) !== -1;
};
export const _getDownloadInProgress = (state: ConversationState) => state.downloadInProgress;
// export const _getConversationPin = (state: ConversationState) => state.pinConversationsList;
