
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->


  <div class="mat-dialog vnctalk-form-dialog update-avatar">
    <div class="mat-dialog__header" *ngIf="!hideHeader">
        <div class="divider"></div>
        <h2>{{ 'SELECT_AVATAR' | translate }}</h2>
        <mat-icon (click)="cancel()" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </div>
    <div class="mat-dialog__body" [class.hide-header]="hideHeader">
        <div class="avatar-wrapper"[class.hide-cropper]="!croppedImage">
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [aspectRatio]="1/1"
                [resizeToWidth]="292"
                [cropperMinWidth]="200"
                [cropperMinHeight]="200"
                [onlyScaleDown]="false"
                format="png"
                [autoCrop]="true"
                [roundCropper]="true"
                [transform]="transform"
                [alignImage]="'center'"
                [canvasRotation]="canvasRotation"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
            <div class="no-avatar" *ngIf="!showDefaultAvatar && !croppedImage">
                <mat-icon (click)="selectPhoto()" fontSet="mdi" fontIcon="mdi-camera"></mat-icon>
            </div>
            <img class="default-avatar" *ngIf="showDefaultAvatar && !croppedImage" [src]="oldAvatarUrl" (error)="imgLoadOnError()">

        </div>
    </div>
    <div class="mat-dialog__footer upload-avatar-footer">
        <div class="rotate-icons">
            <mat-icon fontSet="mdi" fontIcon="mdi-rotate-left" (click)="rotateLeft()"></mat-icon>
            <mat-icon fontSet="mdi" fontIcon="mdi-rotate-right" (click)="rotateRight()"></mat-icon>
        </div>
        <span></span>
        <div class="footer-actions">
            <button id="selectPhotoButton" mat-button><input title=" " id="custom-input" type="file" (change)="fileChangeEvent($event)"
                accept="image/*">{{ 'SELECT_AN_IMAGE' | translate }}</button>
        <button mat-button (click)="updateAvatar()">{{ 'USE' | translate }}</button>
        </div>
    </div>
</div>