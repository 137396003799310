import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "vp-channel-detail-icon",
  templateUrl: "./channel-detail-icon.component.html",
  styleUrls: ["./channel-detail-icon.component.css"]
})
export class ChannelDetailIconComponent implements OnInit {

  @Input() iconName: ChannelDetailIconName;

  channelDetailIconName = ChannelDetailIconName;

  constructor() { }

  ngOnInit(): void {
  }

}

export enum ChannelDetailIconName {
  ADD_SUBCHANNEL,
  OPEN_IN_BROWSER,
  ADD_TO_FAVORITES_ICON,
  ARCHIVE_ICON,
  ATTACH_ICON,
  CLONE_ICON,
  COPY_LINK_ICON,
  DELETE_ICON,
  EDIT_ICON,
  EDIT_UNPUBLISHED_ICON,
  EMPTY_STATE_ICON,
  EYE_OFF_ICON,
  GRID_ICON,
  INFO_ICON,
  LIST_ICON,
  MOVE_ICON,
  MUTE_ICON,
  PIN_ICON,
  READ_ICON,
  REMOVE_FROM_FAVORITES_ICON,
  SELECTED_ICON,
  SEND_TO_ICON,
  SHARE_ICON,
  SUBSCRIBE_ICON,
  TABLE_ICON,
  UN_ARCHIVE_ICON,
  UNMUTE_ICON,
  UNPIN_ICON,
  SCHEDULE_ICON,
  RESTORE_ICON
}
