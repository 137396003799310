<div class="smart-link-filter-wrapper">
    <vnc-dialog [classes]="['mobile-editor-channel-dialog']" [headerTemplate]="mobileHeaderTemplate"
                classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate"
                [bodyTemplate]="currentCategoryFilterTemplate">
    </vnc-dialog>

    <ng-template #mobileHeaderTemplate>
      <div class="header-wrapper">
        <vnc-mobile-header headerType="dark-back-more" darkBackMorePlaceHolder="{{headerLabel}}" [sufixIconTemplate1]="closeIconTemplate"
                           (onSufixIcon1Click)="mobileFilterService.resetFilters()"
                           (darkBackPress)="closeFilter()">
        </vnc-mobile-header>
        <div *ngIf="mobileFilterService.appliedFiltersCount" class="filter-count">{{mobileFilterService.appliedFiltersCount}}</div>
      </div>
    </ng-template>


    <ng-template #footerTemplate>
    </ng-template>

    <ng-template #currentCategoryFilterTemplate>
      <div class="category-body">
        <div class="filter-categories">
          <vnc-chips [text]="'CHATS' | translate"
                     [active]="filterCategories.CHATS"
                     (clickEvent)="changeFilterCategory(categoryFilters.CHATS)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips [text]="'CHANNELS' | translate"
                     [active]="filterCategories.CHANNELS"
                     (clickEvent)="changeFilterCategory(categoryFilters.CHANNELS)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips [text]="'TOPIC' | translate"
                     [active]="filterCategories.TOPIC"
                     (clickEvent)="changeFilterCategory(categoryFilters.TOPIC)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips [text]="'BROADCASTS' | translate"
                     [active]="filterCategories.BROADCASTS"
                     (clickEvent)="changeFilterCategory(categoryFilters.BROADCASTS)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips [text]="'FILES' | translate"
                     [active]="filterCategories.FILES"
                     (clickEvent)="changeFilterCategory(categoryFilters.FILES)"
                     type="filter"
                     size="m"></vnc-chips>
          <vnc-chips [text]="'COMMENTS' | translate"
                     [active]="filterCategories.COMMENTS"
                     (clickEvent)="changeFilterCategory(categoryFilters.COMMENTS)"
                     type="filter"
                     size="m"></vnc-chips>
        </div>

        <div class="global-filter">
          <div class="global-filter_label">{{'TYPE' | translate | uppercase}}</div>
          <div class="global-filter_chips">
            <vnc-chips [text]="'UNREAD' | translate"
                       [active]="mobileFilterService.globalFilters[filterType.GLOBAL_UNREAD]"
                       (clickEvent)="mobileFilterService.updateGlobalFilters(filterType.GLOBAL_UNREAD)"
                       leftIcon="unread" type="filter"
                       size="m"></vnc-chips>
            <vnc-chips [text]="'STARRED' | translate"
                       [active]="mobileFilterService.globalFilters[filterType.GLOBAL_STARRED]"
                       (clickEvent)="mobileFilterService.updateGlobalFilters(filterType.GLOBAL_STARRED)"
                       leftIcon="star"
                       type="filter"
                       size="m"></vnc-chips>
            <vnc-chips [text]="'ARCHIVED' | translate"
                       [active]="mobileFilterService.globalFilters[filterType.GLOBAL_ARCHIVED]"
                       (clickEvent)="mobileFilterService.updateGlobalFilters(filterType.GLOBAL_ARCHIVED)"
                       leftIcon="archive"
                       type="filter"
                       size="m"></vnc-chips>
          </div>
        </div>
        <div class="filters">
          <!--        DATEPICKER-->
          <div class="category"
               (click)="openScheduleDialog()">
            <div class="category-label">{{'DATE' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.globalDateFilterApplied ? mobileFilterService.dateRangeToDisplay : 'All Time'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <!--        CHAT section-->
          <div *ngIf="filterCategories.CHATS" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'TYPE' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="filterCategories.CHATS" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'User' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <!--        channel section-->
          <div *ngIf="filterCategories.CHANNELS" class="category"
               (click)="mobileFilterService.openMobileMenu(filterType.CHANNEL_TYPE)">
            <div class="category-label">{{'TYPE' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.CHANNEL_TYPE !== '' ? mobileFilterService.filterCategories.CHANNEL_TYPE : 'Any'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="filterCategories.CHANNELS" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.CHANNEL_USER, filterType.CHANNEL_USER)">
            <div class="category-label">{{'User' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.CHANNEL_USER.length === 0 ? 'Any' : mobileFilterService.filterCategories.CHANNEL_USER.length}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="filterCategories.CHANNELS" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.CHANNEL_AUTHOR, filterType.CHANNEL_AUTHOR)">
            <div class="category-label">{{'Author' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.CHANNEL_AUTHOR.length === 0 ? 'Any' : mobileFilterService.filterCategories.CHANNEL_AUTHOR.length}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>
          <!--        topic section-->
          <div *ngIf="filterCategories.TOPIC" class="category"
               (click)="mobileFilterService.openMobileMenu(filterType.TOPIC_TYPE)">
            <div class="category-label">{{'TYPE' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.TOPIC_TYPE !== '' ? mobileFilterService.filterCategories.TOPIC_TYPE : 'Any'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="filterCategories.TOPIC" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.TOPIC_USER, filterType.TOPIC_USER)">
            <div class="category-label">{{'User' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.TOPIC_USER.length === 0 ? 'Any' : mobileFilterService.filterCategories.TOPIC_USER.length + ' Users'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="filterCategories.TOPIC" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.TOPIC_AUTHOR, filterType.TOPIC_AUTHOR)">
            <div class="category-label">{{'Author' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.TOPIC_AUTHOR.length === 0 ? 'Any' : mobileFilterService.filterCategories.TOPIC_AUTHOR.length + ' Users'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <!--        broadcast type-->
          <div *ngIf="filterCategories.BROADCASTS" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'Author' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="filterCategories.BROADCASTS" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'User' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <!--        files type-->
          <div *ngIf="filterCategories.FILES" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'Author' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="filterCategories.FILES" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'SIZE' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="filterCategories.FILES" class="category"
               (click)="underDevelopment()">
            <div class="category-label">{{'LOCATION' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection"></div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <!--        comment type-->
          <div *ngIf="filterCategories.COMMENTS" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.COMMENT_CHANNEL, filterType.COMMENT_CHANNEL)">
            <div class="category-label">{{'Channel' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.COMMENT_CHANNEL.length === 0 ? 'Any' : mobileFilterService.filterCategories.COMMENT_CHANNEL.length + ' Channels'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="filterCategories.COMMENTS" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.COMMENT_AUTHOR, filterType.COMMENT_AUTHOR)">
            <div class="category-label">{{'Author' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.COMMENT_AUTHOR.length === 0 ? 'Any' : mobileFilterService.filterCategories.COMMENT_AUTHOR.length + ' Authors'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="filterCategories.COMMENTS" class="category"
               (click)="mobileFilterService.selectUsers(mobileFilterService.filterCategories.COMMENT_TOPIC, filterType.COMMENT_TOPIC)">
            <div class="category-label">{{'TOPIC' | translate}}</div>
            <div class="category-select-wrapper">
              <div class="current-selection">
                {{mobileFilterService.filterCategories.COMMENT_TOPIC.length === 0 ? 'Any' : mobileFilterService.filterCategories.COMMENT_TOPIC.length + ' Topics'}}
              </div>
              <div class="arrow-icon">
                <ng-container [ngTemplateOutlet]="arrowIconTemplate"></ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="set-filter">
        <vnc-primary-button [size]="'l'"
                            [label]="'SHOW_RESULTS' | translate | uppercase"
                            (onClick)="applyFilters()"></vnc-primary-button>
      </div>
    </ng-template>
</div>

<ng-template #arrowIconTemplate>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.989 5.567a.7.7 0 0 0 0 1.018l2.87 2.757-2.87 2.756a.7.7 0 0 0 0 1.019.772.772 0 0 0 1.06 0l3.932-3.775L8.05 5.567a.772.772 0 0 0-1.06 0z" fill="#8B96A0" fill-rule="evenodd"/>
  </svg>
</ng-template>

<ng-template #closeIconTemplate>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.95 5.05a1 1 0 0 1 0 1.414L13.414 12l5.536 5.536a1 1 0 0 1-1.414 1.414L12 13.414 6.464 18.95a1 1 0 1 1-1.414-1.414L10.586 12 5.05 6.464A1 1 0 0 1 6.464 5.05L12 10.586l5.536-5.536a1 1 0 0 1 1.414 0z" fill="#FFF" fill-rule="evenodd"/>
  </svg>
</ng-template>
