
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { Observable } from "rxjs";

@Injectable()
export class MeetingsService {

  constructor(private middlewareService: MiddlewareService) {
  }

  scheduleMeeting(attributes: any, attendees: any): Observable<any> {
   //  {"meeting" : {
   //    "attributes":
   //        {
   //          "subject" : "Test Meeting subject",
   //          "description" : "Sample body",
   //          "start_time" : "20200905T095500",
   //          "end_time" : "20200925T095500",
   //         },
   //   "attendees" :  {"0": {"jid": "ramiz.raja@vnc.biz", "invite_url": "https://vnctalk.vnc.biz/vncmeet/join/aWhvci5raG9tZW5rb0B2bmMuYml6"} , "1": {"jid": "umesh.sarvaiya@vnc.biz", "invite_url":
  // "https://vnctalk.vnc.biz/vncmeet/join/aWhvci5raG9tZW5rb0B2bmMuYml6"} } }
  // }
   // }
   //
   // Start time and end time are considered as UTC offset(so, convert the time to UTC before sending the request)

    const data = {"meeting": {attributes, attendees }};
    return this.middlewareService.post(`/api/meetings/schedule_meeting`, true, data);
  }

  cancelScheduledMeeting(jid: any, message?: any): Observable<any> {
    const data = {
      "meeting": {
          "attributes": {
              "jid": jid,
          }
      },
      "message": message
    };
    return this.middlewareService.post(`/api/meetings/cancel_meeting`, true, data);
  }

  updateScheduledMeeting(body): Observable<any> {
    return this.middlewareService.post(`/api/meetings/update_meeting`, true, body);
  }

  deleteScheduledMeeting(jid: any): Observable<any> {
    const data = {
      "meeting": {
          "attributes": {
              "jid": jid,
          }
      }
    };
    return this.middlewareService.post(`/api/meetings/delete_meeting`, true, data);
  }
}
