/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Topic } from "app/channels/models/topic.model";
import { Action } from ".";
import { Member } from "../../models/member.model";
import { File } from "../../models/file.model";
import {DefaultCover} from "../../models/default-cover.model";



export class TopicActionTypes {
  static TOPIC_LOAD_REQUEST = "[Topic] Load Request";
  static TOPIC_LOAD_SUCCESS = "[Topic] Load Success";

  static TOPIC_ADD = "[Topic] Add";
  static TOPIC_DELETE = "[Topic] Delete";
  static TOPICS_DELETE = "[Topic] Delete";
  static TOPIC_UPDATE = "[Topic] Update";
  static TOPIC_BULK_ADD = "[Topic] Bulk Add";
  static TOPIC_CHANNEL_ADD = "[Topic] Channel Add";
  static TOPIC_INFO_UPDATE = "[Topic] Topic Info Update";
  static SELECTED_TOPIC = "[Topic] Selected Topic";
  static ARCHIVE_TOPICS_SORT_BY = "[Topic] Archive Sort By";
  static TOPIC_MEMBERS_LOAD_REQUEST = "[Topic] Topic Members Load Request";
  static TOPIC_MEMBERS_LOAD_SUCCESS = "[Topic] Topic Members Load Success";
  static TOPIC_MEMBERS_ADD = "[Topic] Topic Members Add";
  static TOPIC_MEMBERS_REMOVE = "[Topic] Topic Members Remove";
  static TOPIC_MEMBERS_OFFSET_UPDATE = "[Topic] Topic Members Offset Update";
  static TOPIC_FILES_LOAD_REQUEST = "[Topic] Topic Files Load Request";
  static TOPIC_FILES_LOAD_SUCCESS = "[Topic] Topic Files Load Success";
  static TOPIC_FILES_ADD = "[Topic] Topic Files Add";
  static TOPIC_DEFAULT_COVERS_ADD = "[Topic] Topic Default Covers Add";
  static TOPIC_FILES_OFFSET_UPDATE = "[Topic] Topic Files Offset Update";
  static TOPIC_SIDEBAR_TAB_CHANGE = "[Topic] Topic Sidebar Tab Change";
  static TOPIC_FILE_REMOVE = "[Topic] Topic File Remove";
  static TOPIC_COMMENTS_LOAD_REQUEST = "[Topic] Topic Comments Load Request";
  static TOPIC_COMMENTS_LOAD_SUCCESS = "[Topic] Topic Comments Load Success";
  static ALL_TOPICS_INFO_UPDATE = "[Topic] ALL_TOPICS_INFO_UPDATE";
  static MEDIA_TOPIC_INFO_UPDATE = "[Topic] Media Topic Info Update";
  static ARCHIVED_TOPICS_INFO_UPDATE = "[Topic] ARCHIVED_TOPICS_INFO_UPDATE";
}

export class TopicLoadRequest implements Action {
  readonly type = TopicActionTypes.TOPIC_LOAD_REQUEST;
}
export class TopicLoadSuccess implements Action {
  readonly type = TopicActionTypes.TOPIC_LOAD_SUCCESS;


  constructor(public payload: any[]) {
  }
}
export class TopicAdd implements Action {
  readonly type = TopicActionTypes.TOPIC_ADD;


  constructor(public payload: Topic) {
  }
}

export class ArchivedTopicsInfoUpdate implements Action {
  readonly type = TopicActionTypes.ARCHIVED_TOPICS_INFO_UPDATE;

  constructor(public payload: {offset?: number, total_count?: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}



export class TopicUpdate implements Action {
  readonly type = TopicActionTypes.TOPIC_UPDATE;


  constructor(public payload: any) {
  }
}



export class TopicDelete implements Action {
  readonly type = TopicActionTypes.TOPIC_DELETE;


  constructor(public payload: Topic["id"]) {
  }
}


export class TopicsDelete implements Action {
  readonly type = TopicActionTypes.TOPICS_DELETE;


  constructor(public payload: Topic["id"][]) {
  }
}


export class TopicBulkAdd implements Action {
  readonly type = TopicActionTypes.TOPIC_BULK_ADD;


  constructor(public payload: Topic[]) {
  }
}



export class TopicChannelAdd implements Action {
  readonly type = TopicActionTypes.TOPIC_CHANNEL_ADD;


  constructor(public payload: Topic["id"]) {
  }
}



export class SetSelectedTopicId implements Action {
  readonly type = TopicActionTypes.SELECTED_TOPIC;


  constructor(public payload: Topic["id"]) {
  }
}

export class SetArchiveTopicsSortBy implements Action {
  readonly type = TopicActionTypes.ARCHIVE_TOPICS_SORT_BY;


  constructor(public payload: string) {
  }
}



export class TopicMembersLoadRequest implements Action {
  readonly type = TopicActionTypes.TOPIC_MEMBERS_LOAD_REQUEST;


  constructor(public payload: Topic["id"]) {
  }
}

export class TopicInfoUpdate implements Action {
  readonly type = TopicActionTypes.TOPIC_INFO_UPDATE;
  constructor(public payload: { channelId: string, info: {totalCount: number, offset: number, isLoaded: boolean}}) {
  }
}

export class TopicMembersLoadSuccess implements Action {
  readonly type = TopicActionTypes.TOPIC_MEMBERS_LOAD_SUCCESS;


  constructor(public payload: { topicId: Topic["id"], members: Member[], total_count: number }) {
  }
}



export class TopicMembersAdd implements Action {
  readonly type = TopicActionTypes.TOPIC_MEMBERS_ADD;


  constructor(public payload: { topicId: Topic["id"], members: { jid: string, role: string }[] }) {
  }
}



export class TopicMembersRemove implements Action {
  readonly type = TopicActionTypes.TOPIC_MEMBERS_REMOVE;


  constructor(public payload: { topicId: Topic["id"], memberIds: string[] }) {
  }
}

export class TopicMembersOffsetUpdate implements Action {
  readonly type = TopicActionTypes.TOPIC_MEMBERS_OFFSET_UPDATE;

  constructor(public payload: { topicId: Topic["id"], offset: number }) {
  }
}


export class TopicFilesLoadRequest implements Action {
  readonly type = TopicActionTypes.TOPIC_FILES_LOAD_REQUEST;


  constructor(public payload: Topic["id"]) {
  }
}

export class TopicFilesLoadSuccess implements Action {
  readonly type = TopicActionTypes.TOPIC_FILES_LOAD_SUCCESS;


  constructor(public payload: { topicId: Topic["id"], files: File[], total_count: number }) {
  }
}

export class TopicFilesAdd implements Action {
  readonly type = TopicActionTypes.TOPIC_FILES_ADD;


  constructor(public payload: { topicId: Topic["id"], allAttachments: any[], extraFilesAdded: number }) {
  }
}

export class TopicDefaultCoversAdd implements Action {
  readonly type = TopicActionTypes.TOPIC_DEFAULT_COVERS_ADD;
  constructor(public payload: DefaultCover[]) {
  }
}

export class TopicFilesOffsetUpdate implements Action {
  readonly type = TopicActionTypes.TOPIC_FILES_OFFSET_UPDATE;

  constructor(public payload: { topicId: Topic["id"], offset: number }) {
  }
}

export class TopicSideBarTabChange implements Action {
  readonly type = TopicActionTypes.TOPIC_SIDEBAR_TAB_CHANGE;
  constructor(public payload: string) {
  }
}

export class TopicFileRemove implements Action {
  readonly type = TopicActionTypes.TOPIC_FILE_REMOVE;
  constructor(public payload: { fileId: number, topicId: string}) {
  }
}

export class TopicCommentsLoadRequest implements Action {
  readonly type = TopicActionTypes.TOPIC_COMMENTS_LOAD_REQUEST;

  constructor(public payload: Topic["id"]) {
  }
}

export class TopicCommentsLoadSuccess implements Action {
  readonly type = TopicActionTypes.TOPIC_COMMENTS_LOAD_SUCCESS;

  constructor(public payload: { topicId: Topic["id"], offset: number, total_count: number }) {
  }
}

export class AllTopicsInfoUpdate implements Action {
  readonly type = TopicActionTypes.ALL_TOPICS_INFO_UPDATE;

  constructor(public payload: {offset?: number, total_count?: number, ids: string[], isLoading: boolean, isLoaded: boolean}) {
  }
}

export class MediaTopicInfoUpdate implements Action {
  readonly type = TopicActionTypes.MEDIA_TOPIC_INFO_UPDATE;
  constructor(public payload: { channelId: string, info: {totalCount: number, offset: number, isLoaded: boolean}}) {
  }
}
