import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";
import {RemarkService} from "../remark.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { Broadcaster } from "app/talk/shared/providers";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {CloseSmartObjectDialog} from "../../root.component";
import {Subject} from "rxjs";
import { take, takeUntil } from "rxjs";
import { environment } from "app/environments/environment";

@Component({
  selector: "vp-add-remark-popup",
  templateUrl: "./add-remark-popup.component.html",
  styleUrls: ["./add-remark-popup.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AddRemarkPopupComponent implements OnInit, OnDestroy {

  isAlive$ = new Subject();

  constructor(private remarkService: RemarkService,
              private _broadcaster: Broadcaster,
              private  translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: { selectedData: {preContent?: string, selectedText?: string, postContent?: string, uid?:string, remarkText?:string, remarkIndex?: string}, isMobileView: boolean },
              private _dialogRef: MatDialogRef<AddRemarkPopupComponent>,
              private _router: Router) {
    this._broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_ADD_REFERENCE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this._dialogRef.close();
    });
  }

  ngOnInit(): void {
    this.remarkText.patchValue(this.data.selectedData.remarkText || "");
    this.translate.get(this.data.selectedData.remarkIndex ? "REFERENCE" : this.headerLabel)
      .pipe(take(1)).subscribe(text => {
      const referenceText =  text.charAt(0) + text.substring(1);
      this.headerLabel = this.data.selectedData.remarkIndex ?
        `${referenceText} [${this.data.selectedData.remarkIndex}]` : text;
    });
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
  }
  }

  remarkText = new UntypedFormControl("");
  rows = 7;
  headerLabel = "NEW_REFERENCE";

  createRemark() {
    // this._broadcaster.broadcast(BroadcastKeys.REMARK_ADDED,{remarkText : this.remarkText.value,data:this.data});
    this._dialogRef.close({action: "save", remarkText : this.remarkText.value, data:this.data});
  }

  closeDialog() {
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

}
