
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { TalkRootState } from "../../../talk/reducers";
import { Store } from "@ngrx/store";
import { getIsAppOnline } from "../../../reducers";
import { ToastService } from "../../services/toast.service";
import { PadService } from "app/talk/chat-window/pad/services/pad.service";
import { Broadcaster } from "app/talk/shared/providers";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-pad-dialog",
  templateUrl: "./pad-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PadMenuDialogComponent implements OnDestroy {
  isAlive$ = new Subject<boolean>();
  padName: UntypedFormControl;
  isAppOnline = false;

  constructor(private dialog: MatDialogRef<PadMenuDialogComponent>,
    private store: Store<TalkRootState>,
    private toastService: ToastService,
    private padService: PadService,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef) {
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
    this.broadcaster.on<string>("closePadMenu").pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.closeDialog();
    });
    this.changeDetectorRef.markForCheck();
  }

  closeDialog() {
    this.dialog.close();
  }

  createSpreadsheet() {
    if (!this.isAppOnline) {
      this.toastService.show("OFFLINE_MODE");
      return;
    }
    this.padService.showPadWindow();
    this.dialog.close();
  }

  createDocument() {
    this.toastService.show("UNDER_DEVELOPMENT");
    this.dialog.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();

  }
}
