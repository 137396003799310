
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, OnDestroy } from "@angular/core";
import { ConfigService } from "../../../config.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "../../../talk/shared/providers/broadcaster.service";
import { Subject, take, takeUntil } from "rxjs";
import { environment } from "app/environments/environment";
import { RootState } from "app/reducers";
import { Store } from "@ngrx/store";
import { getUserProfile } from "../../../reducers";
import { CommonUtil } from "app/talk/utils/common.util";
import { ConversationService } from "app/talk/services/conversation.service";

@Component({
  selector: "vp-all-settings",
  templateUrl: "./all-settings.html"
})
export class VNCAllSettingsComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  serviceDeskImage: String = CommonUtil.getFullUrl("/assets/img/vnc-service-desk.jpg");
  currentTheme = environment.theme;
  hideHelpMenu = environment.hideHelpMenu;
  HEADER_ABOUT_KEY = environment.theme === "hin" ? "HEADER_ABOUT_HIN" : "HEADER_ABOUT";
  VERSION_AND_CHANGELOG_KEY = environment.theme === "hin" ? "VERSION_AND_CHANGELOG_HIN" : "VERSION_AND_CHANGELOG";
  selectedPanel: String = "NONE";
  manualLink: String;
  FAQLink: String;
  helpLink: String;
  serviceDeskLink: String;
  privacyLink = environment.privacyLink;
  termsLink = environment.termsLink;

  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<VNCAllSettingsComponent>,
    private broadcaster: Broadcaster,
    private store: Store<RootState>,
    private conversationService: ConversationService
  ) {
    this.broadcaster.on<any>("hideAllSettings")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        if (this.selectedPanel === "NONE") {
          this.dialogRef.close();
        } else {
          this.selectedPanel = "NONE";
        }
      });
    this.broadcaster.on<any>("closeDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnInit() {
    // Help Dialog
    this.store.select(getUserProfile).pipe(take(1)).subscribe(p => {
      const defaultLang = CommonUtil.getDefaultLang();

      if (defaultLang === "de") {
        this.manualLink = p.manualLinkDe;
        this.FAQLink = p.faqLinkDe;
        this.helpLink = p.helpLinkDe;
      }
      else {
        this.manualLink = p.manualLinkEn;
        this.FAQLink = p.faqLinkEn;
        this.helpLink = p.helpLinkEn;
      }
    });

    // Service desk
    this.conversationService.getSupportUrl().subscribe(url => {
      this.serviceDeskLink = url;
    });
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  async aboutDialog() {
    const { AboutDialogComponent } = await import(
      "../about-dialog/about-dialog.component");
    this.dialog.open(AboutDialogComponent, {
      backdropClass: "about-dialog-backdrop",
      panelClass: "about-dialog-mobile-panel",
      width: "100%",
      height: "100%",
      maxWidth: "100vw",
      disableClose: true,
      autoFocus: true
    });
  }

  async serviceDialog() {
    const { ServiceDeskComponent } = await import(
      "../service-desk-dialog/service-desk-dialog.component");
    this.dialog.open(ServiceDeskComponent, {
      backdropClass: "service-dialog-backdrop",
      panelClass: "service-dialog-panel",
      width: "100%",
      height: "100%",
      maxWidth: "100vw",
      disableClose: true,
      autoFocus: false
    });
  }

  async helpDialog() {
    const { HelpDialogComponent } = await import(
      "../help-dialog/help-dialog.component");
    this.dialog.open(HelpDialogComponent, {
      backdropClass: "help-dialog-backdrop",
      panelClass: "help-dialog-panel",
      width: "100%",
      height: "100%",
      maxWidth: "100vw",
      disableClose: true,
      autoFocus: true
    });
  }

  displaySelectedPanel(panel) {
    this.selectedPanel = panel;
  }

}
