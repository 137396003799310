
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { BehaviorSubject, Observable, take } from "rxjs";
import { debounceTime } from "rxjs";
import { TalkRootState } from "../reducers";
import { getIsAppOnline } from "app/reducers";
import { Store } from "@ngrx/store";
import { DatabaseService } from "./db/database.service";
import { MessageUpdateAction } from "../actions/message";
import { getUserJID } from "../../reducers/index";
import { ConversationRepository } from "../repositories/conversation.repository";

@Injectable()
export class EmojiReactionService {

  apiPath = "/api/ereact";
  pendingReactionMessages$: any = new BehaviorSubject<any>({});
  isOnline: any;
  processPending$: any;
  userJID: any;
  constructor(
    private store: Store<TalkRootState>,
    private conversationRepo: ConversationRepository,
    private middlewareService: MiddlewareService,
    private databaseService: DatabaseService,) {
    const pendingReactionMessages = localStorage.getItem("pendingReactionMessages");
    if (pendingReactionMessages) {
      this.pendingReactionMessages$.next(JSON.parse(pendingReactionMessages));
    }
    this.store.select(getUserJID).subscribe(jid => {
      this.userJID = jid;
    });
    this.store.select(getIsAppOnline).subscribe(isOnline => {
      this.isOnline = isOnline;
      if (this.isOnline) {
        if (this.processPending$) {
          this.processPending$.unsubscribe();
        }
        this.processPending$ = this.pendingReactionMessages$.asObservable().pipe(debounceTime(500)).subscribe(messages => {
          for (let messageId of Object.keys(messages)) {
            const payload = messages[messageId];
            if (payload && this.isOnline) {
              if (payload.reaction === "") {
                this.removeReaction(payload.msgid, payload.target).subscribe(() => {
                  this.removePendingReaction(payload.msgid);
                });
              } else {
                this.updateReaction(payload.msgid, payload.target, payload.reaction).subscribe(() => {
                  this.removePendingReaction(payload.msgid);
                });
              }
            }
          }
        });
      } else if (this.processPending$) {
        this.processPending$.unsubscribe();
      }
    });


  }

  addPendingReaction(data) {
    const messages = this.pendingReactionMessages$.value;
    messages[data.msgid] = data;
    // console.log("[addPendingReaction]", data);
    localStorage.setItem("pendingReactionMessages", JSON.stringify(messages));
    this.pendingReactionMessages$.next(messages);
    let getMessageById$ = this.conversationRepo.getMessageById(data.msgid).pipe(take(1));
    if (this.databaseService.initWorker) {
      getMessageById$ = this.databaseService.getMessageById(data.msgid);
    }
    getMessageById$.subscribe(message => {
      if (message) {
        let newReactions = [];
        if (!!data.reaction) {
          newReactions.push({[this.userJID?.bare]: data.reaction});
        }
        for (let reaction of (message.reactions || [])) {
          if (!Object.keys(reaction).includes(this.userJID?.bare)) {
            newReactions.push(reaction);
          }
        }

        message.reactions = newReactions;
        this.store.dispatch(new MessageUpdateAction({ id: message.id, changes: { reactions: newReactions } }));
        if (this.databaseService.initWorker) {
          this.databaseService.createOrUpdateMessages([message], message.convTarget);
        }
      }
    });
  }

  removePendingReaction(msgId: string) {
    const messages = this.pendingReactionMessages$.value;
    delete messages[msgId];
    localStorage.setItem("pendingReactionMessages", JSON.stringify(messages));
    this.pendingReactionMessages$.next(messages);
    // console.log("removePendingReaction", msgId, messages);
  }

  updateReaction(messageId:string, target:string, reaction:string):Observable<any>{
    const data = {
      msgid: messageId,
      target: target,
      reaction: reaction
    };
    return this.middlewareService.post(`${this.apiPath}`, true, data);
  }

  removeReaction(messageId:string, target:string):Observable<any>{
    return this.middlewareService.delete(`${this.apiPath}/${target}/${messageId}`, true);
  }
}
