/* eslint no-console: 0 */

import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import * as ElectronSentry from "@sentry/electron";
import { environment } from "app/environments/environment";


if (environment.enableSentry) {
  Sentry.init({
    dsn: "https://9b5d0e89bf1e4273a4b3af3acdf903c8@sentry-web.dev-k8s.vnc.de/2",
    maxBreadcrumbs: 150,
    debug: true,
  });
}

@Injectable()
export class ElectronErrorHandler extends ErrorHandler {
  async handleError(error) {
    console.log("[SentryErrorHandler] handleError", error);
    super.handleError(error);
    try {
      console.log("[SentryErrorHandler] captureException", error);
      ElectronSentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}


@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  async handleError(error) {
    super.handleError(error);
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}
