/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { getUserJID, RootState } from "../../reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  _getBlockedBareIds,
  _getConversationConfig,
  _getConversationConfigLoaded,
  _getConversationMembers,
  _getConversationOwner,
  _getConversationText,
  _getFileInProgressByMessageId,
  _getIsConversationBlocked,
  _getIsConversationDetailsVisible,
  _getIsLoaded,
  _getIsLoading,
  _getSelectedConversationId,
  _getIsActivatedConversation,
  _getSelectedMessageIds,
  conversationAdapter,
  conversationReducer,
  ConversationState,
  _getLastActivityByTarget, _getLastText, _getLastActivity, _getActiveConversation, _getConversationRoomId, _getConversationRoomIds, _hasNotification, _getPadNotifications, _getConversationAdmins, _getConversationAllMembers, _hasWBNotification, _getWBNotifications, _getSelectedMiniConversationId,
  _getConversationAudiences, _getConversationMCBs, _getConversationNotificationConfig, _getAllNotificationConfig, _getMessageToCopy, _getProcessingE2EMessages, _getDownloadInProgress,
} from "./conversation";
import {
  _getIsConversationListExpanded, _getIsRightSideBarExpanded,
  _getIsSideRostersExpanded,
  _getSelectedSideRostersTab,
  _getShowGlobalSearchResults,
  _getShowGlobalSearchResultSelected,
  layoutReducer,
  LayoutState,
  _getIsChatRightSideBarExpanded,
  _getIsChatFilesSideBarExpanded,
  _getIsChatInfoSideBarExpanded,
  _getProfileJid,
  _getSidebarInfo,
  _getFileTabSidebar,
  _getProfileTab,
  _getDownloadFileIds,
} from "./layout";
import { messageAdapter, messageReducer, MessageState } from "./message";
import {
  _getConversationMessageIds,
  _getIsConversationMessagesLoaded,
  _getIsConversationMessagesLoading,
  _getIsConversationMessagesOnLastPage,
  conversationMessageReducer,
  ConversationMessageState
} from "./conversation-message";
import { Conversation } from "../models/conversation.model";
import {
  _getActiveConference,
  _getActiveWhiteboard,
  _getBackCameraId,
  _getConferenceId,
  _getConferenceType,
  _getConversationTarget,
  _getFrontCameraId,
  _getFullScreenParticipantId,
  _getHasActiveCall,
  _getHasMicrophone,
  _getHasSpeaker,
  _getHasWebcam,
  _getInvitationStatus,
  _getInvitedParticipants,
  _getIsConferenceAudioMuted,
  _getIsConferenceScreenSharing,
  _getIsConferenceVideoMuted,
  _getJitsiConfig,
  _getIsLoadingJitsiConfig,
  _getJitsiRoom,
  _getParticipants,
  // sct
  _getConferenceParticipants,
  _getE2EEStatus,
  _getSelectedParticipantId,
  _getStreamId,
  conferenceReducer,
  ConferenceState,
  _getExternalParticipants,
  _getExistingConferences,
  _getParticipantEmail,
  _getCurrentView,
  _getSpeakingParticipant,
  _getMutedForMe,
  _getAvailableMediaDevices, _getScreenSharingRequest, _getScreenSharingRequestStatus,
  _getLobbyState, _getKnockingParticipants, _getVirtualBackground, _isBackgroundEffectEnabled,
  _getUploadedBackground,
  _isMutedEveryone,
  _isFloatingExpanded,
  _isMutedAllVideo,
  _isNoiseSuppressionEnabled
} from "./conference";
import { Message, MessageStatus } from "../models/message.model";
import {
  _getConversationAttachments,
  _getConversationAttachmentsTotalFound,
  _getConversationAttachmentsOffset,
  _getConversationAttachmentsOnLastPage,
  _getConversationImages,
  _getConversationImagesOffset,
  _getConversationImagesOnLastPage,
  _getConversationVideos,
  _getConversationVideosOffset,
  _getConversationVideosOnLastPage,
  _getConversationVoiceMessages,
  _getConversationVoiceMessagesOffset,
  _getConversationVoiceMessagesOnLastPage,
  _getIsConversationAttachmentsLoaded,
  _getIsConversationAttachmentsLoading,
  _getIsConversationImagesLoaded,
  _getIsConversationImagesLoading,
  _getIsConversationVideosLoaded,
  _getIsConversationVideosLoading,
  _getIsConversationVoiceMessagesLoaded,
  _getIsConversationVoiceMessagesLoading,
  _getIsMediaOpen,
  _getMediaSelectedMessage, _getSelectedTabIndex,
  mediaReducer,
  MediaState,
  _getIsSharing, _getConversationDocuments, _getConversationDocumentsOffset, _getConversationDocumentsOnLastPage, _getIsConversationDocumentsLoaded, _getIsConversationDocumentsLoading
} from "./media";

import {
  _getAllPostsInfo,
  _getBookmarkedPostsInfo,
  _getIsPostLoaded,
  _getIsPostLoading,
  _getMediaPostsInfo,
  _getPostComments,
  _getPostReplys,
  _getPostReposting,
  _getPostSideBarTab,
  _getPostTimer,
  _getPostUserJid,
  _getScrollToItem,
  _getSelectedPostId,
  postAdapter,
  postReducer,
  PostState
} from "./post";
import {
  _getFollowers,
  _getFollowings,
  _getIsProfileLoaded,
  _getIsProfileLoading,
  _getProfileAllPosts,
  _getProfileFilteredAllPosts,
  _getProfileFilteredMediaPosts,
  _getProfileFilteredReplyPosts,
  _getProfileLikedPosts,
  _getProfileMediaPosts,
  _getProfileReplyPosts,
  _getSelectedProfileId,
  profileAdapter,
  profileReducer,
  ProfileState
} from "./profile";
import {_getIsFollowLoaded, _getIsFollowLoading, followAdapter, followReducer, FollowState} from "./follow";




import { searchMessageAdapter, searchMessageReducer, SearchMessageState } from "./search-message";
import { SearchMessage } from "../models/search-message.model";

import * as fromPad from "../chat-window/pad/@store/pad.reducer";
import { ConferenceUtil } from "../utils/conference.util";
import { WhiteboardState, whiteboardReducer, whiteboardAdapter, _getSelectedWhiteboardId } from "./whiteboard";
import { Whiteboard } from "../models/whiteboard.model";
import { Dictionary } from "@ngrx/entity";

export interface TalkRootState extends RootState {
  talk: State;
}

export const getTalkRootState = createFeatureSelector<State>("talk");

export interface State {
  conversation: ConversationState;
  conversationMessage: ConversationMessageState;
  message: MessageState;
  searchMessage: SearchMessageState;
  layout: LayoutState;
  conference: ConferenceState;
  media: MediaState;
  whiteboard: WhiteboardState;
  padReducer: fromPad.PadState;
  post: PostState;
  profile: ProfileState;
  follow: FollowState;
}

export const talkRootReducer = {
  conversation: conversationReducer,
  conversationMessage: conversationMessageReducer,
  message: messageReducer,
  searchMessage: searchMessageReducer,
  layout: layoutReducer,
  conference: conferenceReducer,
  media: mediaReducer,
  whiteboard: whiteboardReducer,
  padReducer: fromPad.reducer,
};

/**
 * Sub-Reducer
 * Conversation
 */
export const getConversationState = createSelector(
  getTalkRootState,
  state => state.conversation
);

export const {
  selectIds: getConversationIds,
  selectEntities: getConversationEntities,
  selectAll: getAllConversation,
  selectTotal: getTotalConversation, // returns total count of conversation
} = conversationAdapter.getSelectors(getConversationState);

// PRASHANT_COMMENT: Doing it this way keeping in mind that if in future we have to filter conversations,
// we will have to make change in this function only rather than in components
export const getConversations = getAllConversation;
export const getTotalConversations = getTotalConversation;


export const getIsConversationsLoading = createSelector(
  getConversationState,
  _getIsLoading
);


export const getIsConversationsLoaded = createSelector(
  getConversationState,
  _getIsLoaded
);

export const getArchivedConversations = createSelector(getAllConversation, conversations => {
  return conversations.filter((conv: Conversation) => conv.archived && !conv.deleted);
});

export const getUnArchivedConversations = createSelector(getAllConversation, conversations => {
    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {

    }

  return conversations.filter((conv: Conversation) => !conv.archived && !conv.deleted && (deletedConvs.indexOf(conv.Target) === -1) );
});

export const getPinConverstaions = createSelector(getAllConversation, conversations => {
  let deletedConvs = [];
  try {
    deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
  } catch (error) {

  }
  let pinConverstaion =  conversations.filter((conv: Conversation) => !conv.archived && !conv.deleted && (deletedConvs.indexOf(conv.Target) === -1) && conv.ispinned);
  return pinConverstaion.sort((a, b) => a.pinorder - b.pinorder);
});

export const getLastActivityState = createSelector(
  getConversationState,
  _getLastActivity
);

export const getLastText = createSelector(
  getConversationState,
  _getLastText
);

export const getMessageToCopy = createSelector(
  getConversationState,
  _getMessageToCopy
);


export const getSelectedConversationId = createSelector(
  getConversationState,
  _getSelectedConversationId
);

export const getSelectedMiniConversationId = createSelector(
  getConversationState,
  _getSelectedMiniConversationId
);

export const getIsActivatedConversation = (state: TalkRootState, target: string) => {
  return _getIsActivatedConversation(getConversationState(state), target);
};

export const getSelectedConversation = createSelector(
  getSelectedConversationId,
  getConversationEntities,
  (id, entities) => entities[id]
);

export const getSelectedMiniConversation = createSelector(
  getSelectedMiniConversationId,
  getConversationEntities,
  (id, entities) => entities[id]
);

export const getConversationById = (state: RootState, bareId: string) => {
  return getConversationEntities(state)[bareId];
};

export const getBlockedBareIds = createSelector(
  getConversationState,
  _getBlockedBareIds
);

export const getActiveConversation = createSelector(
  getConversationState,
  _getActiveConversation
);

export const getIsConversationBlocked = (state: TalkRootState, conversation: Conversation) => {
  return conversation && _getIsConversationBlocked(getConversationState(state), conversation.Target);
};


export const getSelectedMessageIds = createSelector(
  getConversationState,
  _getSelectedMessageIds
);

export const getIsSelctedConversationDetailsVisible = createSelector(
  getConversationState,
  getSelectedConversationId,
  _getIsConversationDetailsVisible
);

export const getSelectedConversationMembers = createSelector(
  getConversationState,
  getSelectedConversationId,
  _getConversationMembers
);

export const getAllMembersOfSelectedConversation = createSelector(
  getConversationState,
  getSelectedConversationId,
  _getConversationAllMembers
);

export const getAllMembersOfSelectedMiniConversation = createSelector(
  getConversationState,
  getSelectedMiniConversationId,
  _getConversationAllMembers
);

export const getConversationMembers = (state: TalkRootState, target: string) => {
  return _getConversationMembers(getConversationState(state), target);
};

export const getAllConversationMembers = (state: TalkRootState, target: string) => {
  return _getConversationAllMembers(getConversationState(state), target);
};

export const getConversationOwner = (state: TalkRootState, target: string) => {
  return _getConversationOwner(getConversationState(state), target);
};

export const getConversationAdmins = (state: TalkRootState, target: string) => {
  return _getConversationAdmins(getConversationState(state), target);
};

export const getConversationAudiences = (state: TalkRootState, target: string) => {
  return _getConversationAudiences(getConversationState(state), target);
};

export const getConversationMCBs = (state: TalkRootState, target: string) => {
  return _getConversationMCBs(getConversationState(state), target);
};

export const getSelectedConversationText = createSelector(
  getConversationState,
  getSelectedConversationId,
  _getConversationText
);

export const getConversationTextById = (conversationId: string) => createSelector(
  getConversationState,
  (state) => _getConversationText(state, conversationId)
);


export const getPadNotifications = createSelector(
  getConversationState,
  _getPadNotifications
);

export const getWBNotifications = createSelector(
  getConversationState,
  _getWBNotifications
);

export const hasNotification = (state: TalkRootState, target: string) => {
  return _hasNotification(getConversationState(state), target);
};

export const hasWBNotification = (state: TalkRootState, target: string) => {
  return _hasWBNotification(getConversationState(state), target);
};

export const getConversationConfig = (state: TalkRootState, target: string) => {
  return _getConversationConfig(getConversationState(state), target);
};

export const getConversationNotificationConfig = (state: TalkRootState, target: string) => {
  return _getConversationNotificationConfig(getConversationState(state), target);
};

export const getAllNotificationConfig = (state: TalkRootState) => {
  return _getAllNotificationConfig(getConversationState(state));
};

export const getIsConversationConfigLoaded = (state: TalkRootState, target: string) => {
  return _getConversationConfigLoaded(getConversationState(state), target);
};

/**
 * Message Reducers
 */
export const getMessageState = createSelector(
  getTalkRootState,
  state => state.message
);

export const getConversationMessageState = createSelector(
  getTalkRootState,
  state => state.conversationMessage
);

export const {
  selectIds: getMessageIds,
  selectEntities: getMessageEntities,
  selectAll: getAllMessage,
  selectTotal: getTotalMessage, // returns total count of conversation
} = messageAdapter.getSelectors(getMessageState);

export const getPendingMessages = createSelector(
  getAllMessage,
  messages => messages.filter(msg => msg.status === MessageStatus.PENDING)
);

export const getJoinLeaveMessages = createSelector(
  getAllMessage,
  messages => messages.filter(msg => msg.type === "CHAT.JOIN" || msg.type === "CHAT.LEAVE" || (msg.attachment && msg.status === MessageStatus.PENDING)) || []
);

export const getSelectedConversationMessageIds = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getConversationMessageIds
);
export const getSelectedMiniConversationMessageIds = createSelector(
  getConversationMessageState,
  getSelectedMiniConversation,
  _getConversationMessageIds
);

export const getSelectedConversationMessages = createSelector(
  getSelectedConversationMessageIds,
  getMessageEntities,
  (ids, entities) => ids.map(id => entities[id]).
      filter(msg => !!msg && (!!msg.body || msg.isDeleted)).
      sort((msg1, msg2) => {
    return msg2.timestamp - msg1.timestamp;
  })
);

export const getSelectedMiniConversationMessages = createSelector(
  getSelectedMiniConversationMessageIds,
  getMessageEntities,
  (ids, entities) => ids.map(id => entities[id]).
      filter(msg => !!msg && (!!msg.body || msg.isDeleted)).
      sort((msg1, msg2) => {
    return msg2.timestamp - msg1.timestamp;
  })
);

export const getMessagesByConversationTarget = (state: TalkRootState, conv: any) => {
  const ids = getMessageIdsByConversationTarget(state, conv);
  const entities = getMessageEntities(state);
  return ids.map(id => entities[id]).
    filter(msg => !!msg && (!!msg.body || msg.isDeleted)).
    sort((msg1, msg2) => {
      return msg2.timestamp - msg1.timestamp;
    });
};

export const getMessageByIdWithContent = (state: TalkRootState, msgId: any) => {
  const id = msgId;
  const entities = getMessageEntities(state);
  return entities[id];
};

export const getSelectedConversationMessagesWithContent = createSelector(
  getSelectedConversation,
  getSelectedConversationMessageIds,
  getUserJID,
  getMessageEntities,
  (conv, ids, jid, entities) => ids.map(id => entities[id])
    .filter(msg => !!msg && (!!msg.body || msg.isDeleted) && !msg.startFile && !msg.vncTalkMuc && ["inactive", "paused", "composing"].indexOf(msg?.chatState) === -1)
    .filter(m => {
      if (!!m && !!m.group_action && !!m.group_action.type && typeof m.group_action.type === "string") {
        if (!!m && m.group_action && m.group_action?.type?.indexOf("MISSED_CALL") !== -1) {
          try {
            const data = JSON.parse(m.group_action?.type);
            if (data.jids && !data.jids.includes(jid?.bare)) {
              return false;
            }
          } catch (ex) {

          }
        }
      }
      return (conv.type === "chat" && (!m?.vncTalkConference || ConferenceUtil.isPrivateCallSignal(m))
      || (conv.type === "groupchat" && !(m.id?.length >= 36 && m.body?.trim() === ""))
      || conv.type === "broadcast");
    })
    .sort((msg1, msg2) => {
      return msg2.timestamp - msg1.timestamp;
      // return msg1.timestamp - msg2.timestamp;
    }
  )
);

export const getSelectedMiniConversationMessagesWithContent = createSelector(
  getSelectedMiniConversation,
  getSelectedMiniConversationMessageIds,
  getUserJID,
  getMessageEntities,
  (conv, ids, jid, entities) => ids.map(id => entities[id])
    .filter(msg => !!msg && (!!msg.body || msg.isDeleted) && !msg.startFile && !msg.vncTalkMuc && ["inactive", "paused", "composing"].indexOf(msg.chatState) === -1)
    .filter(m => {
      if (!!m && !!m.group_action && !!m.group_action.type && typeof m.group_action.type === "string") {
        if (!!m && m.group_action && m.group_action?.type?.indexOf("MISSED_CALL") !== -1) {
          try {
            const data = JSON.parse(m.group_action?.type);
            if (data.jids && !data.jids.includes(jid?.bare)) {
              return false;
            }
          } catch (ex) {

          }
        }
      }
      return (conv.type === "chat" && (!m.vncTalkConference || ConferenceUtil.isPrivateCallSignal(m))
      || conv.type === "groupchat"
      || conv.type === "broadcast");
    }).sort((msg1, msg2) => {
      return msg2.timestamp - msg1.timestamp;
    }
  )
);

export const getMessageIdsByConversationTarget = (state: TalkRootState, conv: any) => {
  return _getConversationMessageIds(getConversationMessageState(state), conv);
};

export const getLastMessageByConversationTarget = (state: TalkRootState, conv: any) => {
  const ids = getMessageIdsByConversationTarget(state, conv);
  const entities = getMessageEntities(state);
  return ids.map(id => entities[id]).filter(msg => !!msg && !msg.startFile && !msg.vncTalkConference && !msg.vncTalkMuc && !msg.isDeleted && ["inactive", "paused", "composing"].indexOf(msg.chatState) === -1).sort((msg1, msg2) => {
    return msg2.timestamp - msg1.timestamp;
  })[0];
};

export const getIsLoadedByConversationTarget = (state: TalkRootState, conv: Conversation) => {
  return _getIsConversationMessagesLoaded(getConversationMessageState(state), conv);
};

export const getIsLoadingByConversationTarget = (state: TalkRootState, conv: Conversation) => {
  return _getIsConversationMessagesLoading(getConversationMessageState(state), conv);
};

export const getIsSelectedConversationMessagesLoaded = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getIsConversationMessagesLoaded
);

export const getIsSelectedConversationMessagesLoading = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getIsConversationMessagesLoading
);

export const getIsSelectedConversationMessagesOnLastPage = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getIsConversationMessagesOnLastPage
);


// UNREADS

// all convs
export const getAllConversationsUnreadCount = createSelector(
  getUnArchivedConversations, (allConversations) => {
    const unreadCount = {};
    for (let conv of allConversations) {
      unreadCount[conv.Target] = conv.unreadIds?.length || 0;
    }
    return unreadCount;
  }
);

// all but not broadcasts
export const getTotalUnreadCount = createSelector(
  getUnArchivedConversations, (allConversations) => {
    const totalUnreadCount = allConversations.filter(c => !c.Target.startsWith("broadcast-")).reduce((sum, conv) => sum + (conv.unreadIds?.length || 0), 0);
    return totalUnreadCount;
  }
);

// broadcasts only
export const getTotalBroadcastUnreadCount = createSelector(
  getUnArchivedConversations, (allConversations) => {
    const totalUnreadCount = allConversations.filter(c => c.Target.startsWith("broadcast-")).reduce((sum, conv) => sum + (conv.unreadIds?.length || 0), 0);
    return totalUnreadCount;
  }
);

// per conv
export const getConversationUnreadCount = (state: TalkRootState, conversation: Conversation) => {
  return conversation.unreadIds?.length || 0;
};

/////


export const getMessageById = (state: RootState, messageId: string) => {
  return getMessageEntities(state)[messageId];
};

export const getSelectedMessages = createSelector(
  getMessageEntities,
  getSelectedMessageIds,
  (entities: Dictionary<Message>, messageIds: string[]) => {
    return messageIds.map(messageId => {
      return entities[messageId];
    });
  }
);

/**
 * Sub-Reducer
 * Layout
 */
export const getLayoutState = createSelector(
  getTalkRootState,
  state => state.layout
);

export const getIsConversationListExpanded = createSelector(
  getLayoutState,
  _getIsConversationListExpanded
);

export const getIsRightSideBarExpanded = createSelector(
  getLayoutState,
  _getIsRightSideBarExpanded
);

export const getFileTabSidebar = createSelector(
  getLayoutState,
  _getFileTabSidebar
);

export const getIsChatRightSideBarExpanded = createSelector(
  getLayoutState,
  _getIsChatRightSideBarExpanded
);

export const getSidebarInfo = createSelector(
  getLayoutState,
  _getSidebarInfo
);

export const getIsChatFilesSideBarExpanded = createSelector(
  getLayoutState,
  _getIsChatFilesSideBarExpanded
);

export const getIsChatInfoSideBarExpanded = createSelector(
  getLayoutState,
  _getIsChatInfoSideBarExpanded
);

export const getProfileJid = createSelector(
  getLayoutState,
  _getProfileJid
);

export const getProfileTab = createSelector(
  getLayoutState,
  _getProfileTab
);

export const getIsSideRostersExpanded = createSelector(
  getLayoutState,
  _getIsSideRostersExpanded
);

export const getSelectedSideRostersTab = createSelector(
  getLayoutState,
  _getSelectedSideRostersTab
);

export const getShowGlobalSearchResults = createSelector(
  getLayoutState,
  _getShowGlobalSearchResults
);

export const getShowGlobalSearchResultSelected = createSelector(
  getLayoutState,
  _getShowGlobalSearchResultSelected
);

// Sub-Reducer Conference

export const getConferenceState = createSelector(
  getTalkRootState,
  state => state.conference
);

export const getConferenceId = createSelector(
  getConferenceState,
  _getConferenceId
);

export const getConferenceParticipants = createSelector(
  getConferenceState,
  _getParticipants
);

// sct
export const getJitsiConferenceParticipants = createSelector(
  getConferenceState,
  _getConferenceParticipants,
);

export const getE2EEStatus = createSelector(
  getConferenceState,
  _getE2EEStatus,
);

export const getLobbyState = createSelector(
  getConferenceState,
  _getLobbyState,
);

export const getKnockingParticipants = createSelector(
  getConferenceState,
  _getKnockingParticipants,
);

export const getVirtualBackground = createSelector(
  getConferenceState,
  _getVirtualBackground,
);

export const getUploadedBackground = createSelector(
  getConferenceState,
  _getUploadedBackground,
);

export const isBackgroundEffectEnabled = createSelector(
  getConferenceState,
  _isBackgroundEffectEnabled,
);

export const isNoiseSuppressionEnabled = createSelector(
  getConferenceState,
  _isNoiseSuppressionEnabled,
);

export const getFullScreenParticipantId = createSelector(
  getConferenceState,
  _getFullScreenParticipantId
);

export const getSelectedParticipantId = createSelector(
  getConferenceState,
  _getSelectedParticipantId
);

export const getCurrentView = createSelector(
  getConferenceState,
  _getCurrentView
);

export const getSpeakingParticipant = createSelector(
  getConferenceState,
  _getSpeakingParticipant
);

export const getMutedForMe = createSelector(
  getConferenceState,
  _getMutedForMe
);

export const getIsConferenceAudioMuted = createSelector(
  getConferenceState,
  _getIsConferenceAudioMuted
);

export const getIsConferenceVideoMuted = createSelector(
  getConferenceState,
  _getIsConferenceVideoMuted
);

export const getIsConferenceScreenSharingOn = createSelector(
  getConferenceState,
  _getIsConferenceScreenSharing
);


export const getJitsiConfig = createSelector(
  getConferenceState,
  _getJitsiConfig
);

export const getIsLoadingJitsiConfig = createSelector(
  getConferenceState,
  _getIsLoadingJitsiConfig
);

export const getHasMicrophone = createSelector(
  getConferenceState,
  _getHasMicrophone
);

export const getAvailableMediaDevices = createSelector(
  getConferenceState,
  _getAvailableMediaDevices
);

export const getExistingConferences = createSelector(
  getConferenceState,
  _getExistingConferences
);

export const getHasSpeaker = createSelector(
  getConferenceState,
  _getHasSpeaker
);

export const getHasWebcam = createSelector(
  getConferenceState,
  _getHasWebcam
);

export const getFrontCameraId = createSelector(
  getConferenceState,
  _getFrontCameraId
);


export const getBackCameraId = createSelector(
  getConferenceState,
  _getBackCameraId
);

export const getActiveConference = createSelector(
  getConferenceState,
  _getActiveConference
);

export const getActiveWhiteboard = createSelector(
  getConferenceState,
  _getActiveWhiteboard
);

export const getConversationRoomIds = createSelector(
  getConversationState,
  _getConversationRoomIds
);

export const getConversationTarget = createSelector(
  getConferenceState,
  _getConversationTarget
);

export const getJitsiRoom = createSelector(
  getConferenceState,
  _getJitsiRoom
);

export const getParticipantEmail = createSelector(
  getConferenceState,
  _getParticipantEmail
);

export const getConferenceType = createSelector(
  getConferenceState,
  _getConferenceType
);

export const getInvitationStatus = createSelector(
  getConferenceState,
  _getInvitationStatus
);

export const getStreamId = createSelector(
  getConferenceState,
  _getStreamId
);

export const getHasActiveCall = createSelector(
  getConferenceState,
  _getHasActiveCall
);

export const getInvitedParticipants = createSelector(
  getConferenceState,
  _getInvitedParticipants
);

export const getExternalParticipants = createSelector(
  getConferenceState,
  _getExternalParticipants
);

export const getScreenSharingRequest = createSelector(
  getConferenceState,
  _getScreenSharingRequest
);

export const getScreenSharingRequestStatus = createSelector(
  getConferenceState,
  _getScreenSharingRequestStatus
);

export const isMutedEveryone = createSelector(
  getConferenceState,
  _isMutedEveryone
);

export const isMutedAllVideo = createSelector(
  getConferenceState,
  _isMutedAllVideo
);

export const isFloatingExpanded = createSelector(
  getConferenceState,
  _isFloatingExpanded
);

// Search message selector

export const getSearchMessageState = createSelector(
  getTalkRootState,
  state => state.searchMessage
);

export const {
  selectIds: getSearchMessageIds,
  selectEntities: getSearchMessageEntities,
  selectAll: getAllSearchMessage,
  selectTotal: getTotalSearchMessage, // returns total count of conversation
} = searchMessageAdapter.getSelectors(getSearchMessageState);

export const getSearchMessageById = (state: RootState, messageId: string) => {
  return getSearchMessageEntities(state)[messageId];
};

export const getSelectedSearchMessages = createSelector(
  getSearchMessageEntities,
  getSelectedMessageIds,
  (entities: Dictionary<SearchMessage>, messageIds: string[]) => {
    return messageIds.map(messageId => {
      return entities[messageId];
    }).filter(m => !!m);
  }
);


// Media selectors

export const getMediaState = createSelector(
  getTalkRootState,
  state => state.media
);

export const getIsMediaOpen = createSelector(
  getMediaState,
  _getIsMediaOpen
);

export const getMediaSelectedMessage = createSelector(
  getMediaState,
  _getMediaSelectedMessage
);

export const getAllConversationImages = (state: RootState, convTarget: string) => {
  return _getConversationImages(getMediaState(state), convTarget);
};
export const getIsConversationImagesLoading = (state: RootState, convTarget: string) => {
  return _getIsConversationImagesLoading(getMediaState(state), convTarget);
};
export const getIsConversationImagesLoaded = (state: RootState, convTarget: string) => {
  return _getIsConversationImagesLoaded(getMediaState(state), convTarget);
};
export const getConversationImageOffset = (state: RootState, convTarget: string) => {
  return _getConversationImagesOffset(getMediaState(state), convTarget);
};
export const getConversationImageOnLastPage = (state: RootState, convTarget: string) => {
  return _getConversationImagesOnLastPage(getMediaState(state), convTarget);
};


export const getAllConversationAttachments = (state: RootState, convTarget: string) => {
  return _getConversationAttachments(getMediaState(state), convTarget);
};
export const getIsConversationAttachmentsLoading = (state: RootState, convTarget: string) => {
  return _getIsConversationAttachmentsLoading(getMediaState(state), convTarget);
};
export const getIsConversationAttachmentsLoaded = (state: RootState, convTarget: string) => {
  return _getIsConversationAttachmentsLoaded(getMediaState(state), convTarget);
};
export const getConversationAttachmentsOffset = (state: RootState, convTarget: string) => {
  return _getConversationAttachmentsOffset(getMediaState(state), convTarget);
};
export const getAllConversationAttachmentsTotalFound = (state: RootState, convTarget: string) => {
  return _getConversationAttachmentsTotalFound(getMediaState(state), convTarget);
};
export const getConversationAttachmentsOnLastPage = (state: RootState, convTarget: string) => {
  return _getConversationAttachmentsOnLastPage(getMediaState(state), convTarget);
};

export const getFileInProgressByMessageId = (state: RootState, messageId: string) => {
  return _getFileInProgressByMessageId(getConversationState(state), messageId);
};

export const getLastActivityByTarget = (state: RootState, target: string) => {
  return _getLastActivityByTarget(getConversationState(state), target);
};

export const getConversationRoomId = (state: RootState, target: string) => {
  return _getConversationRoomId(getConversationState(state), target);
};


export const getProcessingE2EMessages = (state: RootState) => {
  return _getProcessingE2EMessages(getConversationState(state));
};

export const getAllConversationVoiceMessages = (state: RootState, convTarget: string) => {
  return _getConversationVoiceMessages(getMediaState(state), convTarget);
};
export const getIsConversationVoiceMessagesLoading = (state: RootState, convTarget: string) => {
  return _getIsConversationVoiceMessagesLoading(getMediaState(state), convTarget);
};
export const getIsConversationVoiceMessagesLoaded = (state: RootState, convTarget: string) => {
  return _getIsConversationVoiceMessagesLoaded(getMediaState(state), convTarget);
};
export const getConversationVoiceMessagesOffset = (state: RootState, convTarget: string) => {
  return _getConversationVoiceMessagesOffset(getMediaState(state), convTarget);
};
export const getConversationVoiceMessagesOnLastPage = (state: RootState, convTarget: string) => {
  return _getConversationVoiceMessagesOnLastPage(getMediaState(state), convTarget);
};

export const getAllConversationVideos = (state: RootState, convTarget: string) => {
  return _getConversationVideos(getMediaState(state), convTarget);
};
export const getIsConversationVideosLoading = (state: RootState, convTarget: string) => {
  return _getIsConversationVideosLoading(getMediaState(state), convTarget);
};
export const getIsConversationVideosLoaded = (state: RootState, convTarget: string) => {
  return _getIsConversationVideosLoaded(getMediaState(state), convTarget);
};
export const getConversationVideosOffset = (state: RootState, convTarget: string) => {
  return _getConversationVideosOffset(getMediaState(state), convTarget);
};
export const getConversationVideosOnLastPage = (state: RootState, convTarget: string) => {
  return _getConversationVideosOnLastPage(getMediaState(state), convTarget);
};
export const getAllConversationDocuments = (state: RootState, convTarget: string) => {
  return _getConversationDocuments(getMediaState(state), convTarget);
};
export const getIsConversationDocumentsLoading = (state: RootState, convTarget: string) => {
  return _getIsConversationDocumentsLoading(getMediaState(state), convTarget);
};
export const getIsConversationDocumentsLoaded = (state: RootState, convTarget: string) => {
  return _getIsConversationDocumentsLoaded(getMediaState(state), convTarget);
};
export const getConversationDocumentsOffset = (state: RootState, convTarget: string) => {
  return _getConversationDocumentsOffset(getMediaState(state), convTarget);
};
export const getConversationDocumentsOnLastPage = (state: RootState, convTarget: string) => {
  return _getConversationDocumentsOnLastPage(getMediaState(state), convTarget);
};

export const getSelectedTabIndex = createSelector(
  getMediaState,
  _getSelectedTabIndex
);

export const getIsSharing = createSelector(
  getMediaState,
  _getIsSharing
);

/**
 * Whiteboard Reducers
 */
export const getWhiteboardState = createSelector(
  getTalkRootState,
  state => state.whiteboard
);

export const {
  selectIds: getWhiteboardIds,
  selectEntities: getWhiteboardEntities,
  selectAll: getAllWhiteboard,
  selectTotal: getTotalWhiteboard, // returns total count of conversation
} = whiteboardAdapter.getSelectors(getWhiteboardState);

export const getSelectedWhiteboardId = createSelector(
  getWhiteboardState,
  _getSelectedWhiteboardId
);

export const getSelectedConversationWhiteboards = createSelector(
  getAllWhiteboard,
  getSelectedConversation,
  (whiteboards: Whiteboard[],  conv: Conversation) => {
    return whiteboards.filter(wb => wb.target === conv.Target);
  }
);

export const getWhiteboardsByTarget = (state: TalkRootState, target: string) => {
  const whiteboards = getAllWhiteboard(state);
  return whiteboards.filter(wb => wb.target === target);
};

export const getWhiteboardById = (state: RootState, id: string) => {
  return getWhiteboardEntities(state)[id];
};

export const getSelectedWhiteboard = createSelector(
  getAllWhiteboard,
  getSelectedWhiteboardId,
  (whiteboards: Whiteboard[],  whiteboardId: string) => {
    return whiteboards.filter(wb => wb.uid === whiteboardId);
  }
);

export const getWhiteboardByWhiteboardId = (state: RootState, whiteboardId: string) => {
  return getWhiteboardEntities(state)[whiteboardId];
};



///



export interface PostRootState {
  posts: State
}

export const getPostRootState = createFeatureSelector<State>("posts");

export const postReducers = {
  post: postReducer,
  profile: profileReducer,
  follow: followReducer,
};

export const getPostState = createSelector(
  getPostRootState,
  state => state.post
);

export const {
  selectIds: getPostIds,
  selectEntities: getPostEntities,
  selectAll: getAllPosts,
  selectTotal: getTotalPost,
} = postAdapter.getSelectors(getPostState);

export const getIsPostLoading = createSelector(getPostState, _getIsPostLoading);
export const getIsPostLoaded = createSelector(getPostState, _getIsPostLoaded);


export const getSelectedPostId = createSelector(getPostState, _getSelectedPostId);
export const getPostTimer = createSelector(getPostState, _getPostTimer);
export const getPostSideBarTab = createSelector(getPostState, _getPostSideBarTab);
export const getPostComments = createSelector(getPostState, _getPostComments);
export const getScrollToItem = createSelector(getPostState, _getScrollToItem);
export const getAllPostsInfo = createSelector(getPostState, _getAllPostsInfo);
export const getBookmarkedPostsInfo = createSelector(getPostState, _getBookmarkedPostsInfo);

export const getPostById = (state: PostRootState, id: string) => {
  return getPostEntities(state)[id];
};
export const getRepostsByParentId = (state: PostRootState, id: string) => {
  const allPosts = getPostEntities(state);
  return Object.keys(allPosts).map(key => allPosts?.[key]?.original_post?.id === id ? key : null).filter(v => !!v);
};

export const getPostByIds = (state: PostRootState, ids: string[]) => {
  return ids.map(id => getPostEntities(state)[id])
    .filter(v => !!v);
};

export const getCompleteTopics = (state: PostState) => {
  return getAllPosts(state);
};

export const getMediaPostsInfo = createSelector(getPostState, _getMediaPostsInfo);

export const getCommentInfoByPostId = (state: PostRootState , postId: string) => {
  if (getPostComments(state)) {
    return getPostComments(state)[postId];
  } else {
    return null;
  }
};

export const getPostUserJid = createSelector(getPostState, _getPostUserJid);
export const getPostReplys = createSelector(getPostState, _getPostReplys);
export const getPostReposting = createSelector(getPostState, _getPostReposting);

export const getProfileState = createSelector(
  getPostRootState,
  state => state.profile
);

export const {
               selectIds: getProfileIds,
               selectEntities: getProfileEntities,
               selectAll: getAllProfiles,
               selectTotal: getTotalProfiles,
             } = profileAdapter.getSelectors(getProfileState);

export const getIsProfileLoading = createSelector(getProfileState, _getIsProfileLoading);
export const getIsProfileLoaded = createSelector(getProfileState, _getIsProfileLoaded);
export const getSelectedProfileId = createSelector(getProfileState, _getSelectedProfileId);
export const getProfileAllPosts = createSelector(getProfileState, _getProfileAllPosts);
export const getProfileReplyPosts = createSelector(getProfileState, _getProfileReplyPosts);
export const getProfileMediaPosts = createSelector(getProfileState, _getProfileMediaPosts);
export const getProfileLikedPosts = createSelector(getProfileState, _getProfileLikedPosts);
export const getProfileFilteredAllPosts = createSelector(getProfileState, _getProfileFilteredAllPosts);
export const getProfileFilteredReplyPosts = createSelector(getProfileState, _getProfileFilteredReplyPosts);
export const getProfileFilteredMediaPosts = createSelector(getProfileState, _getProfileFilteredMediaPosts);
export const getFollowers = createSelector(getProfileState, _getFollowers);
export const getFollowings = createSelector(getProfileState, _getFollowings);

export const getProfileById = (state: PostRootState, id: string) => {
  return getProfileEntities(state)[id];
};


//follower following store

export const getFollowState = createSelector(
  getPostRootState,
  state => state.follow
);

export const {
  selectIds: getFollowIds,
  selectEntities: getFollowEntities,
  selectAll: getAllFollow,
  selectTotal: getTotalFollow,
} = followAdapter.getSelectors(getFollowState);

export const getIsFollowLoading = createSelector(getFollowState, _getIsFollowLoading);
export const getIsFollowLoaded = createSelector(getFollowState, _getIsFollowLoaded);

export const getFollowerById = (state: PostRootState, id: string) => {
  return getFollowEntities(state)[id];
};

export const getFollowersByIds = createSelector([getFollowEntities],
  (followers, ids)  => {
  return ids.map(id => followers[id]).filter(v => !!v);
});

export const getDownloadInProgress = createSelector(
  getConversationState,
  _getDownloadInProgress
);

export const getDownloadFileIds = createSelector(
  getLayoutState,
  _getDownloadFileIds
);
