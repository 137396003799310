
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { CommonUtil } from "app/talk/utils/common.util";
import { Contact } from "../models/Contact";

@Injectable()
export class ContactsService {
    _localJID: string = "";
    _ldapSearchResults: any = {};
    contactsByJID: any = {};           // map (bare jid => contact object)
    contactsByLocalPart: any = {};     // map (local => contact object) - maybe obsolete after refactoring
    contactsSearchCache: any = {};     // map ((search params) => contacts array)
    _contactsAvatar: any = {};
    constructor(
    ) {
    }

    updateLDAPResults(keyword, results): void {
        this._ldapSearchResults[keyword] = results;
    }

    createOrUpdateAvatar(contact: Contact, avatarData: string): void {
        contact.setAvatar(avatarData);
        /* FIXME. we need to rescale the images to small size or use another storage. these are too big :-\
        localStorage.setItem(`avatar_${this._localJID}_${contact.bare}`, avatarData);
        */
        localStorage.removeItem(`avatar_${this._localJID}_${contact.bare}`); // for now remove previously stored avatars
        this._contactsAvatar[`avatar_${this._localJID}_${contact.bare}`] = avatarData;
    }

    getAvatarByJID(jid: string): string {
        /* if (localStorage.getItem(`avatar_${this._localJID}_${jid}`) !== null) {
            defaultAvatar = localStorage.getItem(`avatar_${this._localJID}_${jid}`);
        } */
        return /* defaultAvatar || */ this._contactsAvatar[`avatar_${this._localJID}_${jid}`] || "";
    }

    getMyAvatar(): string {
        return this.getAvatarByJID(this._localJID);
    }

    setDefaultAvatarFromStorage(contact: Contact): void {
        let avatar = this.getAvatarByJID(contact.bare);
        if (avatar) {
            contact.setAvatar(avatar);
        }
    }

    getLDAPResultsByKeyword(keyword): Array<any> {
        return this._ldapSearchResults[keyword] || [];
    }

    getContactByJID(jid): Contact {

        // FIXME: add strict check if valid bare jid here because this function is in every code path
        // FIXME: make bare jid if full jid. what about case?
        let result = jid && this.contactsByJID[jid];
        /* if (!result)
          console.error("[ContactsService.getContactByJID(" + jid + ")] NOT FOUND"); */
        return result;
    }


    getOrCreateContactByJID(jid): Contact {

        let c: Contact = this.getContactByJID(jid);
        if (!c) {
            c = this.addContact({
                bare: jid
            });
        }
        return c;
    }

    getContactByLocal(local): Contact { // FIXME: obsolete
        return local && this.contactsByLocalPart[local];
    }

    addOrUpdateContact(contact): void {

        if ((typeof contact !== "object") || !contact.bare)
            throw "[ContactsService.addOrUpdateContact] invalid data: '" + JSON.stringify(contact) + "'";

        let c: Contact = this.getContactByJID(contact.bare);
        if (!c)
            this.addContact(contact);
        else {
            // copy all unset fields
            this._fillContactInfo(c, contact);
        }
    }

    setJID(jid: string): void {
        this._localJID = jid;
    }

    private _fillContactInfo(_contact, contact): void {
        if (contact.state) {
            _contact.setStatus(contact.state);
        }
        if (contact.name) {
            _contact.setName(contact.name);
        }
        if (contact.domain) {
            _contact.setDomain(contact.domain);
        }
        if (contact.full) {
            _contact.setFullJID(contact.full);
        }
        if (!contact.avatarData) {
            _contact.setAvatar(this.getAvatarByJID(contact.bare));
        } else {
            this.createOrUpdateAvatar(contact.bare, contact.avatarData);
        }
        contact.resource = contact.resource;
        if (contact.subscription) {
            _contact.setSubscription(contact.subscription);
        }

        if (contact.groups) {
            if (!Array.isArray(contact.groups)) {
                _contact.groups = [contact.groups];
            } else {
                _contact.groups = contact.groups;
            }
        } else {
            _contact.groups = [];
        }
    }

    addContact(contact): Contact {

        let _contact: Contact = new Contact(contact.bare);
        this.setDefaultAvatarFromStorage(_contact);
        if ((typeof contact !== "object") || !contact.bare)
            throw "[xmpp.addContact] invalid data: '" + JSON.stringify(contact) + "'";
        this._fillContactInfo(_contact, contact);

        if (this.contactsByJID[contact.bare]) {
            throw "[ContactsService.addContact] '" + contact.bare + "' already present";
        }
        this.contactsByJID[contact.bare] = _contact;

        this.contactsByLocalPart[_contact.local] = _contact;

        this.contactsSearchCache = {};
        // console.debug("[ContactsService.addContact] ADDED " + contact.bare + (contact.avatarData ? " (with avatar)" : ""), _contact);
        return _contact;
    }

    addContactToGroup(bare, group): void {
        let contact: Contact = this.getContactByJID(bare);

        if (!contact)
            return;

        if (contact.groups.indexOf(group) === -1)
            contact.groups.push(group);

        this.contactsSearchCache = {};
    }

    removeContactFromGroup(bare, group): void {
        let contact: Contact = this.getContactByJID(bare);

        if (!contact)
            return;

        contact.groups = contact.groups.filter(v => v !== group);

        this.contactsSearchCache = {};
    }

    getContactsGroup(groupName, onlyAvailable?): Array<Contact> {
        return this.searchContacts(groupName, "", onlyAvailable);
    }

    getAvailableContacts(): Array<Contact> {
        let result = [];
        let filter = (c) => this.contactIsAvailable(c) && c.bare !== this._localJID;
        for (let jid in this.contactsByJID) {
            let c = this.contactsByJID[jid];
            if (filter(c)) {
                result.push(c);
            }
        }
        return result;
    }

    searchContacts(groupName, textMatch, onlyAvailable): Array<Contact> {
        if (textMatch)
            textMatch = textMatch.toLowerCase();

        let chacheKey = (groupName || "") + (onlyAvailable ? "+" : "-") + (textMatch || "");

        let result = this.contactsSearchCache[chacheKey];

        if (!result) {

            result = [];

            let filter = (c) => ((!onlyAvailable || this.contactIsAvailable(c))
                && (!textMatch || (c.name && (c.name.toLowerCase().indexOf(textMatch) > -1)))
                && (!groupName || (c.groups && (c.groups.indexOf(groupName) > -1))));

            for (let jid in this.contactsByJID) {

                let c = this.contactsByJID[jid];
                if (filter(c) && c.bare !== this._localJID)
                    result.push(c);
            }

            result = this.contactsSearchCache[chacheKey] = (result || []).sort(CommonUtil.sortBy("name"));

            // console.debug("[ContactsService.searchContacts] '" + chacheKey + "' => " + result.length);
        }

        return result;
    }

    getContactGroupNames(): Array<string> {

        /* NOTE: all cache keys besides not containing '+' or '-'' do not collide with
          search results from searchContacts() and can be used for other caches */
        return this.contactsSearchCache["g"]
            || (this.contactsSearchCache["g"] = CommonUtil.uniq(this.contactsByJID.flatMap(c => c.groups)).sort());
    }

    contactIsAvailable(contact): boolean {
        return contact.name && contact.local && contact.state && contact.state !== "unavailable"
            && contact.state !== "offline" && contact.state !== "error";
    }

    getFullName(target): string {
        let contact = this.getContactByJID(target);
        if (contact) {
            return contact.name || contact.local;
        }
        return target.split("@")[0];
    }
}
