
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { Group } from "app/talk/models/group.model";
import { GroupActionTypes } from "app/actions/group";

export interface GroupState extends EntityState<Group> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const groupAdapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (contact: Group) => contact.id,
  sortComparer: null
});

export const initialState: GroupState = groupAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function groupReducer(state: GroupState = initialState, action: Action): GroupState {
  switch (action.type) {

    case GroupActionTypes.GROUP_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case GroupActionTypes.GROUP_LOAD_SUCCESS: {
      const contacts = action.payload.map(c => {
        let contact = state.entities[c.bare];
        if (contact) {
          c = {...c, ...contact};
        }
        return c;
      });
      return groupAdapter.addAll(contacts, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case GroupActionTypes.GROUP_ADD: {
      const newState = groupAdapter.addOne(action.payload, state);
      return groupAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }

    case GroupActionTypes.GROUP_UPDATE: {
      const updatedState = groupAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case GroupActionTypes.GROUP_BULK_ADD: {
      const newState = groupAdapter.addMany(action.payload, state);

      const changes = action.payload.map(group => {
        return { id: group.id, changes: group };
      });

      return groupAdapter.updateMany(changes, newState);
    }

    case GroupActionTypes.GROUP_DELETE: {
      return groupAdapter.removeOne(action.payload, state);
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.groupState;
      return savedState ? { ...state, ...savedState } : state;
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoading = (state: GroupState) => state.isLoading;
export const _getIsLoaded = (state: GroupState) => state.isLoaded;
