
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
  <div class="vnc-screenshare-component">
    <vnc-dialog [classes]="['vnc-screenshare']" [hideFooter]="true" closeText="{{ 'CLOSE' | translate }}" headerText="{{ 'SHARE_SCREEN_NAME' | translate }}"
      classes="fixed-size with-border with-border-radius" [bodyTemplate]="bodyTemplate">
    </vnc-dialog>
    <ng-template #bodyTemplate>
      <mat-form-field>
        <mat-label class="option-label"> {{ 'SCREEN_SHARING' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedOption" id="selectScreenSharing"  [style.width]="'100%'">
          <mat-option value="">{{ 'CHOOSE_SCREEN_YOU_WANT_TO_SHARE' | translate }}</mat-option>
          <mat-option *ngFor="let source of desktopSources" [attr.id]="source.id" [value]="source.name" (click)="onSelectScreen($event, source.id)">{{source.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </div>
  
