import { Component, Inject, OnDestroy } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { environment } from "app/environments/environment";
import { Broadcaster } from "app/talk/shared/providers/broadcaster.service";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: "vp-mobile-bottom-sheet-add-actions-menu",
    templateUrl: "./bottom-sheet-add-action-menu.component.html",
    styleUrls: ["./bottom-sheet-add-action-menu.component.scss"],
})
export class VNCBottomSheetAddActionsMenu implements OnDestroy{
    private isAlive$ = new Subject<boolean>();
    menuType: string = "chat-actions";
    isEncrypted: boolean = false;
    showSurvey: boolean = false;
    allowEncryption: boolean = false;
    showFullEditor: boolean = false;
    isCordovaApp: boolean = environment.isCordova;
  hideAddParticipant: any;
    constructor(
        private broadcaster: Broadcaster,
        private _bottomSheetRef: MatBottomSheetRef<VNCBottomSheetAddActionsMenu>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.broadcaster.on<any>("hideAddActionsBottomMenu")
        .pipe(takeUntil(this.isAlive$))
        .subscribe(() => {
            this._bottomSheetRef.dismiss({ operation: "hide-menu-action" });
          });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    operation(operationItem: string): void {
        this._bottomSheetRef.dismiss({ operation: operationItem });
    }

}
