import { Injectable } from "@angular/core";

@Injectable()
export class PopupPositionService {
  constructor() {  }

  getPopupPosition(boxDimensions: { width: number, height: number}, left = 0, top = 0) {
    const totalWidth = document.body.offsetWidth;
    const totalHeight = document.body.offsetHeight;
    const right = totalWidth - left;
    const bottom = totalHeight - top;

    const position = {};
    if (left < totalWidth * .5) {
      const canFitHorizontally = left + boxDimensions.width < totalWidth;
      position["left"] = (canFitHorizontally ? left : left - (left + boxDimensions.width - totalWidth)) + "px";
    } else {
      const canFitHorizontally = right + boxDimensions.width < totalWidth;
      position["right"] = (canFitHorizontally ? right : right - (right + boxDimensions.width - totalWidth)) + "px";
    }
    if (top < totalHeight * .5) {
      const canFitVertically = top + boxDimensions.height < totalHeight;
      position["top"] = (canFitVertically ? top + 20 : top - (top + boxDimensions.height - totalHeight)) + "px";
    } else {
      const canFitVertically = bottom + boxDimensions.height < totalHeight;
      position["bottom"] = (canFitVertically ? bottom : bottom - (bottom + boxDimensions.height - totalHeight)) + "px";
    }
    return position;
  }
}
