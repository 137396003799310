
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { LoggerService } from "app/shared/services/logger.service";

const CHUNK_MAC_LENGTH = 32000; // max 65K for DC message, and we have UTF-16 for single char

@Injectable()
export class P2PDataChannelService {

  private peerConnection: RTCPeerConnection;
  private dataChannel: RTCDataChannel;
  private iceCandidates = [];

  private sendSignalingMessageFunction: any;

  private onMessage: any;
  private onOpen: any;

  private dcChunksReceived: string[];
  private dcChunksExpected = 0;

  constructor(private logger: LoggerService) {
  }

  // called on initiator's side
  createConnection(sendSignalingMessageFunction: any, onDataChannelOpen: any, onDataChannelMessage: any) {
    this.logger.info("[P2PDataChannelService][createConnection]");

    this.sendSignalingMessageFunction = sendSignalingMessageFunction;
    this.onMessage = onDataChannelMessage;
    this.onOpen = onDataChannelOpen;

    this.createPeerConnection();

    this.dataChannel = this.peerConnection.createDataChannel("P2PDataChannelServiceChannel");
    this.setDataChannelHandlers();

    this.peerConnection.createOffer().then((description: any) => {
      this.peerConnection.setLocalDescription(description).then(() => {
        this.logger.info("[P2PDataChannelService][setLocalDescription]");
        this.sendSignalingMessageFunction({"offer": {"sdp": this.peerConnection.localDescription}});
      }).catch((error: any) => {
         this.logger.error("[P2PDataChannelService][setLocalDescription]", error);
      });
    }).catch((error: any) => {
       this.logger.error("[P2PDataChannelService][createOffer]", error);
    });

    return this.peerConnection;
  }

  acceptConnection(sdp: any, sendSignalingMessageFunction: any, onDataChannelOpen: any, onDataChannelMessage: any) {
    this.logger.info("[P2PDataChannelService][acceptConnection]");

    this.sendSignalingMessageFunction = sendSignalingMessageFunction;
    this.onMessage = onDataChannelMessage;
    this.onOpen = onDataChannelOpen;

    this.createPeerConnection();

    this.peerConnection.ondatachannel = (event: any) => {
      this.logger.info("[P2PDataChannelService] opened");

      this.dataChannel = event.channel;
      this.setDataChannelHandlers();
    };

    this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
      this.peerConnection.createAnswer().then((description: any) => {
        this.peerConnection.setLocalDescription(description).then(() => {
          this.logger.info("[P2PDataChannelService][setLocalDescription]");
          this.sendSignalingMessageFunction({"answer": {"sdp": this.peerConnection.localDescription}});
        }).catch((error: any) => {
           this.logger.error("[P2PDataChannelService][setLocalDescription]", error);
        });
      }).catch((error: any) => {
         this.logger.error("[P2PDataChannelService][createOffer]", error);
      });
    }).catch((error: any) => {
       this.logger.error("[P2PDataChannelService][createOffer]", error);
    });

    return this.peerConnection;
  }

  gotIceCandidate(ice: any) {
    this.logger.info("[P2PDataChannelService][gotIceCandidate]", this.peerConnection);

    this.peerConnection.addIceCandidate(new RTCIceCandidate(ice)).then(() => {
      this.logger.info("[P2PDataChannelService][addIceCandidate] ok");
    }).catch((error: any) => {
       this.logger.error("[P2PDataChannelService][addIceCandidate]", error);
    });
  }

  setRemoteDescription(sdp: any) {
    this.logger.info("[P2PDataChannelService][setRemoteDescription]");

    this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
      this.logger.info("[P2PDataChannelService][setRemoteDescription] ok");
    }).catch((error: any) => {
       this.logger.error("[P2PDataChannelService][createOffer]", error);
    });
  }

  private createPeerConnection() {
    this.logger.info("[P2PDataChannelService] createPeerConnection");
    const peerConnectionConfig = {
      "iceServers": [
        {"urls": "stun:stun.l.google.com:19302"},
      ]
    };
    this.peerConnection = new RTCPeerConnection(peerConnectionConfig);

    this.peerConnection.onicecandidate = (event: any) => {
      this.logger.info("[P2PDataChannelService] onicecandidate");
      if (event.candidate) {
        this.sendSignalingMessageFunction({"ice": event.candidate});
      }
    };

    this.peerConnection.onicegatheringstatechange = () => {
      this.logger.info("[P2PDataChannelService] onicegatheringstatechange", this.peerConnection.iceGatheringState);
    };

    this.peerConnection.oniceconnectionstatechange = () => {
      this.logger.info("[P2PDataChannelService] oniceconnectionstatechange", this.peerConnection.iceConnectionState);
    };

    this.peerConnection.onconnectionstatechange = () => {
      this.logger.info("[P2PDataChannelService] onconnectionstatechange", this.peerConnection.connectionState);
    };

    this.peerConnection.onsignalingstatechange = () => {
      this.logger.info("[P2PDataChannelService] onsignalingstatechange", this.peerConnection.signalingState);
    };
  }

  private setDataChannelHandlers() {
    this.dataChannel.onmessage = (event: any) => {
      if (this.onMessage) {
        const parsedMessage = JSON.parse(event.data);
        if (parsedMessage.chunks_count) {
          this.logger.info("[P2PDataChannelService] onmessage, chunks_count: ", parsedMessage.chunks_count);
          this.dcChunksExpected = parsedMessage.chunks_count;

          this.dcChunksReceived = new Array(this.dcChunksExpected);

        } else if (parsedMessage.chunk_num !== undefined) {
          this.logger.info("[P2PDataChannelService] onmessage, chunk_num: ", parsedMessage.chunk_num);

          this.dcChunksReceived[parsedMessage.chunk_num] = parsedMessage.data; // TODO: can DC messages come out of order?

          if (parsedMessage.chunk_num === (this.dcChunksExpected - 1)) {
            const jointMessageString = this.dcChunksReceived.join("");
            this.logger.info("[P2PDataChannelService] dataChannel.onmessage, dcChunksReceived: ", this.dcChunksReceived);
            this.onMessage(JSON.parse(jointMessageString));
          }
        }
      }
    };
    this.dataChannel.onopen = () => {
      this.logger.info("[P2PDataChannelService] dataChannel.onopen: ", this.dataChannel.readyState);

      if (this.onOpen) {
        this.onOpen();
      }
    };
    this.dataChannel.onclose = (event: any) => {
      this.logger.info("[P2PDataChannelService] dataChannel.onclose: ", event.data, this.dataChannel.readyState);
    };
  }

  sendMessage(data: any) {
    const dataString = JSON.stringify(data);

    // send chunks count first
    const chunks_count = Math.ceil(dataString.length / CHUNK_MAC_LENGTH);
    this.logger.info("[P2PDataChannelService][sendMessage] message length", dataString.length, chunks_count);

    this.dataChannel.send(JSON.stringify({ chunks_count }));

    // send data in chunks
    for (let i = 0; i < chunks_count; ++i) {
      const chunk = dataString.substring(i * CHUNK_MAC_LENGTH, (i + 1) * CHUNK_MAC_LENGTH);

      this.logger.info("[P2PDataChannelService][sendMessage] chunk", i, chunk.length, i * CHUNK_MAC_LENGTH, (i + 1) * CHUNK_MAC_LENGTH);

      this.dataChannel.send(JSON.stringify({chunk_num: i, data: chunk}));
    }
  }

  closeConnection() {
    this.dataChannel.close();
    this.peerConnection.close();

    this.dataChannel = null;
    this.peerConnection = null;
  }
}
