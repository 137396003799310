
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { UserStatus } from "../shared/models/index";
import { Contact } from "../talk/models/contact.model";
import { VCard } from "../talk/models/jid.model";
import { ContactInformation } from "../talk/models/vcard.model";
import { Group } from "app/talk/models/group.model";

export class ContactActionTypes {
  static CONTACT_LOAD_REQUEST = "[CONTACT] Load Request";
  static CONTACT_LOAD_SUCCESS = "[CONTACT] Load Success";

  static CONTACT_ADD = "[CONTACT] Add";
  static CONTACT_UPDATE = "[CONTACT] Update";
  static CONTACT_STATUS_UPDATE = "[CONTACT] Status Update";
  static CONTACT_STATUS_UPDATE_BULK = "[CONTACT] Bulk Status Update ";
  static CONTACT_AVATAR_UPDATE_BULK = "[CONTACT] Bulk Avatar Update ";
  static CONTACT_APP_STATUS_UPDATE_BULK = "[CONTACT] Bulk App User Update ";
  static CONTACT_NICKNAME_UPDATE_BULK = "[CONTACT] Bulk Nickname Update ";
  static CONTACT_BULK_ADD = "[CONTACT] Bulk Add";
  static CONTACT_DELETE = "[CONTACT] Delete contact";

  static CONTACT_ADD_VCARD = "[CONTACT] Add VCard";
  static CONTACT_ADD_BULK_VCARD = "[CONTACT] Add Bulk VCard";
  static CONTACT_UPDATE_PHOTO_LAST_UPDATE = "[CONTACT] Update Photo Last Update";
  static CONTACT_UPDATE_BULK = "[CONTACT] Bulk Update";
  static CONTACT_BULK_UPDATE_PHOTO_LAST_UPDATE = "[CONTACT] Update Bulk Photo Last Update";

  static CONTACT_ADD_TO_LIST = "[CONTACT GROUP] Add User";
  static CONTACT_REMOVED_FROM_LIST = "[CONTACT GROUP] Remove User";
  static CONTACT_REMOVED_GROUP = "[CONTACT GROUP] Remove Group";
  static CONTACT_REMOVE_GROUP_FROM_CONTACTS = "[CONTACT GROUP] Remove Group From Contacts";
  static CONTACT_ADD_GROUP_TO_CONTACTS = "[CONTACT GROUP] Add Group To Contacts";
  static RENAME_LIST = "[CONTACT GROUP] Rename";
  static UPDATE_LISTS = "[CONTACT] Update Lists";
  static UPDATE_ALL_MEMBERS = "[CONTACT] Update All List Members";
}

export class ContactLoadRequest implements Action {
  readonly type = ContactActionTypes.CONTACT_LOAD_REQUEST;
}

export class ContactLoadSuccess implements Action {
  readonly type = ContactActionTypes.CONTACT_LOAD_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class ContactAdd implements Action {
  readonly type = ContactActionTypes.CONTACT_ADD;

  constructor(public payload: Contact) {
  }
}


export class ContactStatusUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_STATUS_UPDATE;

  constructor(public payload: { jid: string, status: UserStatus }) {
  }
}

export class ContactBulkStatusUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_STATUS_UPDATE_BULK;

  constructor(public payload: { jid: string, status: UserStatus } []) {
  }
}

export class ContactBulkUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_UPDATE_BULK;

  constructor(public payload: { bare: string, changes: any } []) {
  }
}

export class ContactBulkAppStatusUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_APP_STATUS_UPDATE_BULK;

  constructor(public payload: { jid: Contact, vncAppUser: string } []) {
  }
}

export class ContactBulkNicknameUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_NICKNAME_UPDATE_BULK;

  constructor(public payload: { jid: Contact, nickname: string } []) {
  }
}

export class ContactUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_UPDATE;

  constructor(public payload: { id: string, changes: any }) {
  }
}

export class ContactBulkAdd implements Action {
  readonly type = ContactActionTypes.CONTACT_BULK_ADD;

  constructor(public payload: any[]) {
  }
}

export class ContactDelete implements Action {
  readonly type = ContactActionTypes.CONTACT_DELETE;

  constructor(public payload: any[]) {
  }
}

export class ContactUpdatePhotoLastUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_UPDATE_PHOTO_LAST_UPDATE;

  constructor(public payload: { bare: string, photoLastUpdate: number }) {
  }
}

export class ContactBulkUpdatePhotoLastUpdate implements Action {
  readonly type = ContactActionTypes.CONTACT_BULK_UPDATE_PHOTO_LAST_UPDATE;

  constructor(public payload) {
  }
}



export class ContactAddVCard implements Action {
  readonly type = ContactActionTypes.CONTACT_ADD_VCARD;

  constructor(public payload: VCard) {
  }
}

export class ContactAddBulkVCard implements Action {
  readonly type = ContactActionTypes.CONTACT_ADD_BULK_VCARD;

  constructor(public payload: { vCard: ContactInformation, bare: string } []) {
  }
}

export class AddContactToList implements Action {
  readonly type = ContactActionTypes.CONTACT_ADD_TO_LIST;

  constructor(public payload: { listName: string, members: Contact[] }) {
  }
}

export class RemoveContactFromList implements Action {
  readonly type = ContactActionTypes.CONTACT_REMOVED_FROM_LIST;

  constructor(public payload: { listName: string, members: Contact[] }) {
  }
}

export class RemoveGroupFromContact implements Action {
  readonly type = ContactActionTypes.CONTACT_REMOVED_GROUP;

  constructor(public payload: { contact: Contact, groupId: number }) {
  }
}

export class RemoveGroupFromContacts implements Action {
  readonly type = ContactActionTypes.CONTACT_REMOVE_GROUP_FROM_CONTACTS;

  constructor(public payload: { contacts: Contact[], groupId: number }) {
  }
}

export class AddGroupToContacts implements Action {
  readonly type = ContactActionTypes.CONTACT_ADD_GROUP_TO_CONTACTS;

  constructor(public payload: { contacts: Contact[], group: Group }) {
  }
}

// export class AddContactsToGroup implements Action {
//   readonly type = ContactActionTypes.CONTACT_ADD_CONTACTS_TO_GROUP;

//   constructor(public payload: { contacts: Contact[], groupId: number }) {
//   }
// }

export class UpdateAllMembersOfList implements Action {
  readonly type = ContactActionTypes.UPDATE_ALL_MEMBERS;

  constructor(public payload: { listName: string, members: Contact[] } []) {
  }
}

export class RenameList implements Action {
  readonly type = ContactActionTypes.RENAME_LIST;

  constructor(public payload: { oldName: string, newName: string }) {
  }
}

export class UpdateLists implements Action {
  readonly type = ContactActionTypes.UPDATE_LISTS;

  constructor(public payload: string[]) {
  }
}
