
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output
} from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Subject, Subscription, takeUntil } from "rxjs";

import { Notification, Options } from "./notifications.model";
import { VNCTalkNotificationsService } from "./notifications.service";
import { NotificationService } from "../services/notification.service";

@Component({
    selector: "vp-notifications",
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="vt-notification-wrapper" [ngClass]="position" [class.auto-width]="notificationType === 'withIcon'" [class.custom]="notificationType === 'custom' || notificationType.startsWith('wakeup')" [class.show-right-sidebar]="showSidebar && !isMobileScreen" [class.show-align-right]="alignRight">
            <vp-notification
                *ngFor="let a of notifications; let i = index"
                [item]="a"
                [timeOut]="timeOut"
                [clickToClose]="clickToClose"
                [maxLength]="maxLength"
                [pauseOnHover]="pauseOnHover"
                [theClass]="theClass"
                [rtl]="rtl"
                [animate]="animate"
                [position]="i"
                >
            </vp-notification>
        </div>
    `,
    styles: [`
        .vt-notification-wrapper {
            position: fixed;
            width: 360px;
            z-index: 900000;
        }
        .vt-notification-wrapper.left { left: 10px; }
        .vt-notification-wrapper.top { top: 50px; }
        .vt-notification-wrapper.top.custom { top: 55px; }
        .vt-notification-wrapper.right { right: 0px; }
        .vt-notification-wrapper.show-right-sidebar { right: 255px; }
        .vt-notification-wrapper.show-align-right { right: 0px; }
        .vt-notification-wrapper.bottom { bottom: 10px; }
        .vt-notification-wrapper.auto-width {
            width: auto;
            top: 70px;
        }
        @media (max-width: 340px) {
            .vt-notification-wrapper {
                width: auto;
                left: 10px;
                right: 10px;
            }
            .vt-notification-wrapper.show-right-sidebar { right: 10px; }
        }
    `]
})

export class VNCTalkNotificationsComponent implements OnInit, OnDestroy {
    notificationType: string = "";
    isMobileScreen: boolean;

    @Input() set options(opt: Options) {
        this.attachChanges(opt);
    }
    @Input() showSidebar: boolean;

    @Output() onCreate = new EventEmitter();
    @Output() onDestroy = new EventEmitter();

    notifications: Notification[] = [];
    position: ["top" | "bottom", "right" | "left"] = ["bottom", "right"];

    lastNotificationCreated: Notification;
    listener: Subscription;

    // Received values
    lastOnBottom = true;
    maxStack = 8;
    preventLastDuplicates: any;
    preventDuplicates = false;

    // Sent values
    timeOut = 0;
    maxLength = 0;
    clickToClose = true;
    pauseOnHover = true;
    theClass = "";
    rtl = false;
    animate: "fromRight" | "fromLeft" | "rotate" | "scale" = "fromRight";
    alignRight = false;

    private isAlive$ = new Subject<boolean>();

    constructor(
        private notificationsService: VNCTalkNotificationsService,
        private notificationService: NotificationService,
        private breakpointObserver: BreakpointObserver,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
        this.breakpointObserver.observe(["(max-width: 767px)"])
        .pipe(takeUntil(this.isAlive$))
        .subscribe((state: BreakpointState) => {
          this.isMobileScreen = !!state.matches;
          this.changeDetectorRef.markForCheck();
        });
        this.listener = this.notificationsService.getChangeEmitter()
            .subscribe(item => {
                if (item.notification) {
                    this.notificationType = item.notification.type;
                    if (this.notificationType === "wakeupme") {
                        this.notificationService.playWakeUp();
                    }
                }
                switch (item.command) {
                    case "cleanAll":
                        this.notifications = [];
                        break;

                    case "clean":
                        if (item.id) {
                          this.cleanSingle(item.id);
                        } else if (item.type) {
                          this.cleanSingleByType(item.type);
                        }
                        break;

                    case "set":
                         // active call notification - do not shpw if arleady displayed
                        if (item.notification && item.notification.type === "active") {
                          if (this.isExistsWithType("active")) {
                            break;
                          }
                        }
                        if (item.notification && item.notification.type == "channels") {
                            this.alignRight = true;
                        }

                        if (item.add) {
                          this.add(item.notification!);
                        } else {
                          this.defaultBehavior(item);
                        }
                        this.changeDetectorRef.markForCheck();
                        break;

                    default:
                        this.defaultBehavior(item);
                        break;
                }
            });
    }

    private defaultBehavior(value: any) {
        this.notifications.splice(this.notifications.indexOf(value.notification), 1);
        this.onDestroy.emit(this.buildEmit(value.notification, false));
    }


    // Add the new notification to the notification array
    add(item: Notification) {
        item.createdOn = new Date();

        let toBlock: boolean = this.preventLastDuplicates || this.preventDuplicates ? this.block(item) : false;

        // Save this as the last created notification
        this.lastNotificationCreated = item;

        if (!toBlock) {
            // Check if the notification should be added at the start or the end of the array
            if (this.lastOnBottom) {
                if (this.notifications.length >= this.maxStack) {
                  this.notifications.splice(0, 1);
                }
                this.notifications.push(item);
            } else {
                if (this.notifications.length >= this.maxStack) {
                  this.notifications.splice(this.notifications.length - 1, 1);
                }
                this.notifications.splice(0, 0, item);
            }

            this.onCreate.emit(this.buildEmit(item, true));
        }
    }

    // Check if notifications should be prevented
    block(item: Notification): boolean {

        let toCheck = item.html ? this.checkHtml : this.checkStandard;

        if (this.preventDuplicates && this.notifications.length > 0) {
            for (let i = 0; i < this.notifications.length; i++) {
                if (toCheck(this.notifications[i], item)) {
                    return true;
                }
            }
        }

        if (this.preventLastDuplicates) {

            let comp: Notification;

            if (this.preventLastDuplicates === "visible" && this.notifications.length > 0) {
                if (this.lastOnBottom) {
                    comp = this.notifications[this.notifications.length - 1];
                } else {
                    comp = this.notifications[0];
                }
            } else if (this.preventLastDuplicates === "all" && this.lastNotificationCreated) {
                comp = this.lastNotificationCreated;
            } else {
                return false;
            }
            return toCheck(comp, item);
        }

        return false;
    }

    checkStandard(checker: Notification, item: Notification): boolean {
        return checker.type === item.type && checker.title === item.title && checker.content === item.content;
    }

    checkHtml(checker: Notification, item: Notification): boolean {
        return checker.html ? checker.type === item.type && checker.title === item.title
        && checker.content === item.content && checker.html === item.html : false;
    }

    // Attach all the changes received in the options object
    attachChanges(options: any) {
        Object.keys(options).forEach(a => {
            if (this.hasOwnProperty(a)) {
                (<any>this)[a] = options[a];
            }
        });
    }

    buildEmit(notification: Notification, to: boolean) {
        let toEmit: Notification = {
            createdOn: notification.createdOn,
            type: notification.type,
            id: notification.id
        };

        if (notification.html) {
            toEmit.html = notification.html;
        } else {
            toEmit.title = notification.title;
            toEmit.content = notification.content;
        }

        if (!to) {
            toEmit.destroyedOn = new Date();
        }

        return toEmit;
    }

    cleanSingle(id) {
        // console.trace("[NotificationComponent][cleanSingle]", id);
        let indexOfDelete = 0;
        let doDelete = false;

        this.notifications.forEach((notification, idx) => {
            if (notification.id === id) {
                indexOfDelete = idx;
                doDelete = true;
            }
        });

        if (doDelete) {
            this.notifications.splice(indexOfDelete, 1);
        }
    }

    private cleanSingleByType(type) {
        // console.trace("[NotificationComponent][cleanSingleByType]", type);
        let indexOfDelete = 0;
        let doDelete = false;

        this.notifications.forEach((notification, idx) => {
            if (notification.type === type) {
                indexOfDelete = idx;
                doDelete = true;
            }
        });

        if (doDelete) {
            this.notifications.splice(indexOfDelete, 1);
        }
    }

    private isExistsWithType(type) {
        let exists = false;

        this.notifications.forEach((notification) => {
            const equals = notification.type === type;
            if (equals) {
              exists = true;
            }
        });

        return exists;
    }

    ngOnDestroy() {
        if (this.listener) {
            this.listener.unsubscribe();
        }
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }
}
