
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { MiddlewareService } from "./middleware.service";
import { filter, Observable, Subject } from "rxjs";
import { CommonUtil } from "app/talk/utils/common.util";
import { AudioOutputService } from "./audio-output.service";
import { Store } from "@ngrx/store";

import {
  getAppSettings,
  RootState
} from "../../reducers";
import { AppSettings } from "../models/app-settings.model";
import { VncLibraryService } from "vnc-library";
import { TranslateService } from "@ngx-translate/core";
import { take, takeUntil } from "rxjs/operators";
import { LoggerService } from "app/shared/services/logger.service";

@Injectable()
export class NotificationService {
  private __incomingCall: HTMLAudioElement;
  private __receiveMessage: HTMLAudioElement;
  private __calling: HTMLAudioElement;
  private __endingCall: HTMLAudioElement;

  private isCallingPlaying = false;
  private __wakeUp: HTMLAudioElement;
  private ringtoneVolume = 0.5;

  private incomingCallCordovaLoaded = false;

  private currentCallRingtone = "incoming-call";

  private cordovaDeviceReady = false;
  options: AppSettings;
  private sinkId: string;

  constructor(
    private store: Store<RootState>,
    private middlewareService: MiddlewareService,
    private translate: TranslateService,
    private logger: LoggerService,
    private vncLibraryService: VncLibraryService,
    private audioOutputService: AudioOutputService) {
      this.store.select(getAppSettings)
      .subscribe(options => {
        this.options = options;
      });
    if (environment.isCordova){
      if (navigator && navigator.splashscreen) {
        navigator.splashscreen.hide();
      }
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    } else {
      this.initAudioFilesForBrowser();
      this.initCallRingtoneForBrowser();
    }

    this.store.select(getAppSettings).pipe(filter(options => !!options.callRingtone)).subscribe(options => {
      this.logger.info("[NotificationService] callRingtone", options.callRingtone);
      if (!options.callRingtone && environment.theme === "hin") {
        options.callRingtone = "retroMovieRingtone";
      }
      if (options.callRingtone !== this.currentCallRingtone) {
        this.currentCallRingtone = options.callRingtone;

        this.logger.info("[NotificationService] callRingtone CUSTOM", options.callRingtone);

        if (environment.isCordova){
          if (this.cordovaDeviceReady) {
            this.initCallRingtoneForCordova();
          } else {
            //
          }
        } else {
          this.initCallRingtoneForBrowser();
        }
      }

      if(options.notificationSound){
        const notificationTone = options.notificationSound;
        if(notificationTone == "incomming_echo" || notificationTone == "incomming_echo_alt" || notificationTone == "knock_alt"){
          this.__receiveMessage = this.loadAudio(`assets/sounds/${notificationTone}.wav`);
        } else{
          this.__receiveMessage =  this.loadAudio("assets/sounds/receive-message.mp3");
        }
      } else{
        this.__receiveMessage =  this.loadAudio("assets/sounds/receive-message.mp3");
      }
    });
  }

  setSinkId(audioOutputDeviceId) {
    this.sinkId = audioOutputDeviceId;
  }

  openSnackBar(msg: string,
    snackbarType?: string,
    actionLabel?: string,
    link?: string,
    timeout?: number,
    snackbarVerticalPosition = "bottom",
    snackbarHorizontalPosition = "left"
  ) {
    return this.vncLibraryService.openSnackBar(msg, snackbarType, actionLabel, link, timeout, snackbarVerticalPosition, snackbarHorizontalPosition);
  }

  openSnackBarWithTranslation(key: string, param?: any, timeout = 2000) {
    this.translate.get(key, param || {}).pipe(take(1)).subscribe(text => {
      this.vncLibraryService.openSnackBar(text, "checkmark","", "", timeout, "bottom", "left").subscribe(() => {});
    });
  }

  openSnackBarWithTranslationCenter(key: string, param?: any, timeout = 2000) {
    this.translate.get(key, param || {}).pipe(take(1)).subscribe(text => {
      this.vncLibraryService.openSnackBar(text, "checkmark", "", "", timeout, "bottom", "center").subscribe(() => { });
    });
  }

  updateVolume() {
    if (CommonUtil.isOnNativeMobileDevice && window.androidVolume) {
      window.androidVolume.getRinger((volume) => {
        this.logger.info("[getRinger] success", volume);
        if (!!volume) {
          this.ringtoneVolume = volume / 100;
        }
      }, (error) => {
        this.logger.info("[getRinger] err", error);
      });
    }
  }

  deviceReady() {
    this.cordovaDeviceReady = true;

    // for Cordova only env
    if (cordova.plugins && cordova.plugins.NativeAudio) {
      this.initAudioFilesForCordova();
      this.initCallRingtoneForCordova();
      this.updateVolume();
    } else {
      this.logger.info("NativeAudio plugin API is not available");
      this.initAudioFilesForBrowser();
      this.initCallRingtoneForBrowser();
    }
    window.addEventListener("volume", (ev) => {
        this.logger.info("[getRinger][on volume change]", ev);
        this.updateVolume();
    }, false);
    if (!!window.HeadsetDetection) {
      window.HeadsetDetection.registerRemoteEvents(status => {
        this.logger.info("[headSetStatus][change]", status);
          this.audioOutputService.onMobileDeviceChanges(status);
      });
    }
    if (!!navigator.proximity) {
      navigator.proximity.registerRemoteEvents(status => {
        this.logger.info("[proximitystatus][change]", status);
        const proximityState = (status === "proximitySensorFar") ? 0 : 1;
        this.audioOutputService.setProximityState(proximityState);
      });
    }
  }

  storeFCMToken(token: string, device: string, os: string): Observable<null> {
    return this.middlewareService.post("/api/storefcm", true, {
      "token": token,
      "device": device,
      "os": os
    });
  }

  deleteFCMToken(device: string): Observable<null> {
    this.logger.info("[NotificationService][deleteFCMToken] device:", device);

    return this.middlewareService.post("/api/deletefcm", true, {
      "device": device,
    });
  }

  public playCalling() {
    this.logger.info("[NotificationService][playCalling] - options: ", this.options);
    if (!this.options.enabledSound && !environment.isCordova) {
      this.logger.info("[NotificationService][playCalling] - sound disabled, bailing out");
      return;
    }
    if (environment.isCordova) {
      if (!this.options.enabledSound && !this.options.enableSound) {
        this.logger.info("[NotificationService][playCalling] cordova - sound disabled, bailing out");
        return;
      }
      this.playAudioCordova("startcalling");
      setTimeout(() => {
        this.playAudioCordovaInLoop("calling");
      }, 1100);
    } else {
      this.playAudioBrowserLoop(this.__calling);
    }
  }

  public stopPlayCalling() {
    this.logger.info("[NotificationService][stopPlayCalling]");

    if (environment.isCordova) {
      this.stopPlayAudioCordova("calling");
    } else {
      this.stopPlayAudioBrowser(this.__calling);
    }
  }

  public playIncomingCall() {
    if (!this.options.enabledSound) {
      return;
    }
    this.logger.info("[NotificationService][playIncomingCall]");
    if (environment.isCordova) {
      if (!window.appInBackground) {

        if (CommonUtil.isOnAndroid()) {
          window.plugins.ringerMode.getRingerMode(res => {
            this.logger.info("[NotificationService][playIncomingCall] ringerMode result: " + res);

            if (res === "RINGER_MODE_NORMAL") { // RINGER_MODE_SILENT or RINGER_MODE_VIBRATE
              this.playAudioCordovaInLoop("incoming-call");
            }
          }, error => {
            this.logger.info("[NotificationService][playIncomingCall] ringerMode error: " + error);
          });
        } else {
          this.playAudioCordovaInLoop("incoming-call");
        }
      }
    } else {
      this.playAudioBrowserLoop(this.__incomingCall);
    }
  }

  public playEndingCall() {
    this.logger.info("[NotificationService][playEndingCall]");
    if (environment.isCordova) {
      this.playAudioCordova("ending");
    } else {
      this.playAudioBrowser(this.__endingCall);
    }
  }

  public stopPlayEndingCall() {
    this.logger.info("[NotificationService][stopPlayEndingCall]");

    if (environment.isCordova) {
      this.stopPlayAudioCordova("ending");
    } else {
      this.stopPlayAudioBrowser(this.__endingCall);
    }
  }

  public playWakeUp() {
    this.logger.info("[NotificationService][playWakeUp] ", this.__wakeUp, this.__wakeUp.currentTime);
    if (!this.options.enabledSound) {
      return;
    }
    if (environment.isCordova) {
      if (!window.appInBackground) {
        const self = this;
        if (CommonUtil.isOnAndroid()) {
          window.plugins.ringerMode.getRingerMode(res => {
            self.logger.info("[NotificationService][playWakeUp] ringerMode result: " + res);

            if (res === "RINGER_MODE_NORMAL") { // RINGER_MODE_SILENT or RINGER_MODE_VIBRATE
              self.playAudioCordovaInLoop("wake_up");
            }
          }, error => {
            self.logger.info("[NotificationService][playIncomingCall] ringerMode error: " + error);
          });
        } else {
          this.playAudioCordovaInLoop("wake_up");
        }
      }
    } else {
      this.playAudioBrowser(this.__wakeUp, 0.2);
    }
  }

  public stopPlayIncomingCall() {
    this.logger.info("[NotificationService][stopPlayIncomingCall]");

    if (environment.isCordova) {
      this.stopPlayAudioCordova("incoming-call");
    } else {
      this.stopPlayAudioBrowser(this.__incomingCall);
    }
  }

  public stopWakeUp() {
    this.logger.info("[NotificationService][stopWakeUp]", this.__wakeUp, this.__wakeUp.loop, this.__wakeUp.ended);

    if (environment.isCordova) {
      this.stopPlayAudioCordova("wake_up");
    } else {
      this.__wakeUp.loop = false;
      this.stopPlayAudioBrowser(this.__wakeUp);
    }
  }

  public playReceiveMessage() {
    this.logger.info("[NotificationService][playReceiveMessage]");
    if (!this.options.enabledSound) {
      return;
    }
    if (environment.isCordova) {
      if (!window.appInBackground) {
        this.playAudioCordova("receive-message");
      }
    } else {
      this.playAudioBrowser(this.__receiveMessage);
    }
  }

  private initCallRingtoneForBrowser(){
    this.logger.info("[NotificationService][initCallRingtoneForBrowser]");

    if (this.__incomingCall) {
      this.__incomingCall.remove();
      this.__incomingCall = null;
    }
    if(this.currentCallRingtone == "polite_alert" || this.currentCallRingtone == "aliens_alarm" || this.currentCallRingtone == "sea_queen"){
      this.__incomingCall = this.loadAudio(`assets/sounds/ringtones/${this.currentCallRingtone}.wav`);
    } else{
      this.__incomingCall = this.loadAudio(`assets/sounds/ringtones/${this.currentCallRingtone}.mp3`);
    }
  }

  private initAudioFilesForBrowser(){
    this.logger.info("[NotificationService][initAudioFilesForBrowser]");

    this.__wakeUp = this.loadAudio("assets/sounds/wake_up.mp3");
    this.__calling = this.loadAudio("assets/sounds/calling.mp3");
    this.__endingCall = this.loadAudio("assets/sounds/bone_toggle.mp3");
  }

  private initCallRingtoneForCordova(){
    this.logger.info("[NotificationService][initCallRingtoneForCordova]", this.incomingCallCordovaLoaded);
    const self = this;
    if (this.incomingCallCordovaLoaded) {
      cordova.plugins.NativeAudio.unload("incoming-call", res => {
        self.logger.info("[NotificationService][initCallRingtoneForCordova] unload result: " + res);

        self.incomingCallCordovaLoaded = false;

        self.loadIncomingCallCordova();
      }, error => {
        self.logger.error("[NotificationService][initCallRingtoneForCordova] unload error: " + error);
      });
    } else {
      this.loadIncomingCallCordova();
    }
  }

  private loadIncomingCallCordova() {
    this.logger.info("[NotificationService][loadIncomingCallCordova]", this.currentCallRingtone);
    const self = this;
    cordova.plugins.NativeAudio.preloadComplex("incoming-call", `assets/sounds/ringtones/${this.currentCallRingtone}.mp3`, 1.0, 1, 0, res => {
        self.logger.info("[NotificationService][loadIncomingCallCordova] NativeAudio preloadComplex incoming-call result: " + res);
    }, error => {
        self.logger.error("[NotificationService][loadIncomingCallCordova] NativeAudio preloadComplex incoming-call error: " + error);
    });

    this.incomingCallCordovaLoaded = true;
  }

  private initAudioFilesForCordova(){
    this.logger.info("[NotificationService][initAudioFilesForCordova]");
    const self = this;
    cordova.plugins.NativeAudio.preloadSimple("receive-message", "assets/sounds/receive-message.mp3", res => {
        self.logger.info("[NotificationService][initAudioFilesForCordova] NativeAudio preloadSimple receive-message result: " + res);
    }, error => {
        self.logger.error("[NotificationService][initAudioFilesForCordova] NativeAudio preloadSimple receive-message error: " + error);
    });
    cordova.plugins.NativeAudio.preloadComplex("wake_up", "assets/sounds/wake_up.mp3", 1.0, 1, 0, res => {
        self.logger.info("[NotificationService][initAudioFilesForCordova] NativeAudio preloadComplex wake_up.mp3 result: " + res);
    }, error => {
        self.logger.error("[NotificationService][initAudioFilesForCordova] NativeAudio preloadComplex wake_up.mp3 error: " + error);
    });
    cordova.plugins.NativeAudio.preloadComplex("calling", "assets/sounds/calling.mp3", 0.1, 1, 0, res => {
        self.logger.info("[NotificationService][initAudioFilesForCordova] NativeAudio preloadSimple incoming-call result: " + res);
    }, error => {
        self.logger.error("[NotificationService][initAudioFilesForCordova] NativeAudio preloadSimple incoming-call error: " + error);
    });
    cordova.plugins.NativeAudio.preloadComplex("ending", "assets/sounds/bone_toggle.mp3", 0.1, 1, 0, res => {
        self.logger.info("[NotificationService][initAudioFilesForCordova] NativeAudio preloadSimple ending-call result: " + res);
    }, error => {
        self.logger.error("[NotificationService][initAudioFilesForCordova] NativeAudio preloadSimple ending-call error: " + error);
    });
  }

  private loadAudio(path: string): HTMLAudioElement {
    this.logger.info("[NotificationService][loadAudio]", path);
    const audio = new Audio(path);
    audio.preload = "auto";
    audio.load();
    return audio;
  }

  private playAudioBrowser(audio: HTMLAudioElement, volume = 0.5) {
    this.logger.info("[NotificationService][playAudioBrowser]");
    if (!audio) {
      return;
    }
    audio.volume = volume;
    audio.addEventListener("ended",  () => {
      audio.pause();
      audio.currentTime = 0;
      audio.remove();
    }, false);
    audio.play();
  }

  private async playAudioBrowserLoop(audio: HTMLAudioElement) {
    this.logger.info("[NotificationService][playAudioBrowserLoop] ");
    if (!!this.sinkId) {
      await audio.setSinkId(this.sinkId);
    }
    audio.loop = true;
    audio.volume = 0.5;
    audio.play();
  }

  private stopPlayAudioBrowser(audio: HTMLAudioElement) {
    this.logger.info("[NotificationService][stopPlayAudioBrowser]" , audio, audio.ended, audio.loop);
    audio.loop = false;
    audio.pause();
    audio.currentTime = 0;
  }


  ///


  private playAudioCordova(type){
    cordova.plugins.NativeAudio.play(type);
  }

  private playAudioCordovaInLoop(type){
    if (type === "calling" && this.isCallingPlaying) {
      return;
    }

    this.logger.info("[NotificationService][playAudioCordovaInLoop]", type);

    if (type === "calling" && CommonUtil.isOnAndroid()) {
      this.setVolumeAudioCordova("calling", 0.0);
    }
    let self = this;
    cordova.plugins.NativeAudio.loop(type, () => {
      self.logger.info("[NotificationService][playAudioCordovaInLoop] success");
      self = null;
      if (type === "calling") {
        this.isCallingPlaying = true;
      }

      if (CommonUtil.isOnAndroid()) {
        // Now this is a dirty hack
        // by some reason, when start audio call, a 'calling' ringtone outputs to SPEAKER,
        // even if we set EARPIECE
        // so here we have a quick switch back and forth to fix it :-|
        //
        setTimeout(() => {
          this.audioOutputService.correctAudioOutput();
          this.setVolumeAudioCordova(type, this.ringtoneVolume);
        }, 200);
      } else {
        this.setVolumeAudioCordova(type, 0.5);
      }
    }, error => {
      self.logger.error("[NotificationService][playAudioCordovaInLoop] error", error);
      self = null;
    });
  }

  private stopPlayAudioCordova(type){
    this.logger.info("[NotificationService][stopPlayAudioCordova]", type);

    cordova.plugins.NativeAudio.stop(type);

    if (type === "calling") {
      this.isCallingPlaying = false;
    }
  }

  private setVolumeAudioCordova(type, volume) {
    this.logger.info("[NotificationService][setVolumeAudioCordova] ", type, volume);
    let self = this;
    cordova.plugins.NativeAudio.setVolumeForComplexAsset(type, volume, function(res){
      self.logger.info("NativeAudio setVolumeForComplexAsset result: " + res);
      self = null;
    }, function(error){
      self.logger.error("NativeAudio setVolumeForComplexAsset error: " + error);
      self = null;
    });
  }
}
