
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="user-profile user-info">
  <div class="user-info__header">
    <div class="user-info__avatar" [ngClass]="{'is-external-user': isExternalUser}">
      <vp-avatar [atComponent]="'user-info'" [jid]="jidBare" [size]="'chip-size'" [hidestatus]="'true'"></vp-avatar>
    </div>
    <div class="user-info__name" *ngIf="ldapData">
      <div>{{ ldapData.displayName && ldapData.displayName[0] ? ldapData.displayName[0] : fullName }}</div>
      <span class="contact-company" *ngIf="companyName" title="{{ companyName }}">{{ companyName }}</span>
    </div>
    <div class="user-info__name" *ngIf="!ldapData">
      <div>{{ vCard && vCard.fullName ? vCard.fullName : fullName }}</div>
      <span class="contact-company" *ngIf="companyName" title="{{ companyName }}">{{ companyName }}</span>

    </div>
    <button class="close-dialog-btn" mat-icon-button (click)="close()"  matTooltip="{{ 'CLOSE' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="contact-information" *ngIf="!ldapData && vCard">
    <h4>{{ 'CONTACT_DETAILS'|translate }}</h4>
    <div class="setting-group email-setting" id="companyName" *ngIf="companyName">
      <div class="form-control">
        <div class="form-icon">
          <mat-icon>business</mat-icon>
        </div>
        <div class="form-group">
          <span class="email">{{ companyName }}</span>
        </div>
      </div>
    </div>
    <div class="setting-group email-setting" id="emails" *ngIf="!vCard?.emails">
      <div class="form-control">
        <div class="form-icon">
          <mat-icon>email</mat-icon>
        </div>
        <div class="form-group">
          <span class="email">{{ jidBare }}</span>
        </div>

      </div>
    </div>
    <div class="setting-group email-setting" id="emails" *ngIf="vCard?.emails">
      <div class="form-control" *ngFor="let emailForm of vCard.emails; let i=index">
        <div class="form-icon">
          <mat-icon *ngIf="i===0">email</mat-icon>
        </div>
        <div class="form-group">
          <span class="email">{{ emailForm.email }}</span>
          <span class="form-type" *ngIf="emailForm.emailType"> <span class="dot-separator">
              <mat-icon>lens</mat-icon>
            </span>
            {{ emailForm.emailType | translate }}
          </span>
        </div>

      </div>
    </div>

    <div class="setting-group phone-setting" id="phoneNumbers" *ngIf="vCard?.phoneNumbers">
      <div class="form-control" *ngFor="let phone of vCard.phoneNumbers; let j=index">
        <div class="form-icon" *ngIf="phone.number">
          <mat-icon *ngIf="j===0">phone</mat-icon>
        </div>
        <div class="form-group" *ngIf="phone.number">
          <span label="Phone">{{ phone.number }}</span>
          <span class="form-type"> <span class="dot-separator">
              <mat-icon>lens</mat-icon>
            </span>
            {{ phone.phoneType | translate }}
          </span>
        </div>

      </div>
    </div>
    <!-- list of addresses -->
    <div class="setting-group address-setting" id="addresses" *ngIf="vCard?.addresses">
      <div class="form-control" *ngFor="let address of vCard.addresses; let k=index">
        <div class="form-icon" *ngIf="address.street || address.postalCode || address.city || address.country">
          <mat-icon *ngIf="k===0">place</mat-icon>
        </div>
        <div class="form-group">
          <span label="Street and number" *ngIf="address.street" class="street">{{ address.street }}</span>
          <div class="form-grid" *ngIf="address.postalCode || address.city">
            <span label="Zip" class="postalCode">{{ address.postalCode }}</span>
            <span label="City" class="city">{{ address.city }}</span>
          </div>
          <div class="form-grid">
            <span label="Country" class="country" *ngIf="address.country">{{ address.country }}</span>
            <span class="form-type"> <span class="dot-separator" *ngIf="address.country">
                <mat-icon>lens</mat-icon>
              </span>
              {{ address.addressType | translate }}
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="contact-information" *ngIf="ldapData">
    <h4>{{ 'CONTACT_DETAILS'|translate }}</h4>
    <div class="setting-group email-setting" id="companyName" *ngIf="companyName">
      <div class="form-control">
        <div class="form-icon">
          <mat-icon>business</mat-icon>
        </div>
        <div class="form-group">
          <span class="email">{{ companyName }}</span>
        </div>
      </div>
    </div>
    <div class="setting-group email-setting" id="emails" *ngIf="ldapData.mail">
      <div class="form-control" *ngFor="let mail of ldapData.mail; let i=index">
        <div class="form-icon">
          <mat-icon *ngIf="i===0">email</mat-icon>
        </div>
        <div class="form-group">
          <span class="email">{{ mail }}</span>
        </div>

      </div>
    </div>

    <div class="setting-group phone-setting" id="phoneNumbers" *ngIf="ldapData.telephoneNumber">
      <div class="form-control" *ngFor="let phone of ldapData.telephoneNumber; let j=index">
        <div class="form-icon">
          <mat-icon *ngIf="j===0">phone</mat-icon>
        </div>
        <div class="form-group">
          <span label="Phone">{{ phone }}</span>
        </div>

      </div>
    </div>
    <!-- list of addresses -->
    <div class="setting-group address-setting" id="addresses" *ngIf="ldapData.street">
      <div class="form-control" *ngFor="let address of ldapData.street; let k=index">
        <div class="form-icon">
          <mat-icon *ngIf="k===0">place</mat-icon>
        </div>
        <div class="form-group">
          <div *ngIf="ldapData.company && ldapData.company[k]" class="street">{{ ldapData.company[k] }}</div>
          <div *ngIf="address" class="street">{{ address }}</div>
          <div label="City" class="city" *ngIf="ldapData.l && ldapData.l[k]">{{ ldapData.l[k] }}</div>
          <div label="Zip" class="postalCode" *ngIf="ldapData.postalCode && ldapData.postalCode[k]">{{
            ldapData.postalCode[k] }}</div>
          <div label="Country" class="city" *ngIf="ldapData.co && ldapData.co[k]">{{ ldapData.co[k] }}</div>
        </div>
      </div>
    </div>
    <div class="setting-group address-setting" id="addresses" *ngIf="!ldapData.street && ldapData.l">
      <div class="form-control" *ngFor="let address of ldapData.l; let k=index">
        <div class="form-icon">
          <mat-icon *ngIf="k===0">place</mat-icon>
        </div>
        <div class="form-group">
          <div label="City" class="city">{{ address }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
