
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "app/environments/environment";
import { getAppSettings, getBaseApiUrl, getChatBackgrounds, getGlobalMute, getIsAppOnline, getUserConfig, RootState, selectedChatBackgroundPreview } from "app/reducers";
import { CommonService } from "app/shared/providers";
import { AppService } from "app/shared/services/app.service";
import { AppSettings } from "app/talk/models/app-settings.model";
import { ConferenceRepository } from "app/talk/repositories/conference.repository";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { TFARepository } from "app/talk/repositories/tfa.repository";
import { DatabaseService } from "app/talk/services/db/database.service";
import { JitsiService } from "app/talk/services/jitsi.service";
import { VNCDirectoryService } from "app/talk/services/vncdirectory.service";
import { XmppService } from "app/talk/services/xmpp.service";
import { CommonUtil } from "app/talk/utils/common.util";
import { ConstantsUtil } from "app/talk/utils/constants.util";
import { debounceTime, Subject, take, takeUntil } from "rxjs";
import { VNCVerticalTabsComponent, VncLibraryService } from "vnc-library";
import { ConfigService } from "../../../config.service";
import { Broadcaster } from "../../../talk/shared/providers/broadcaster.service";
import { DateTimeAdapter } from "ng-pick-datetime-ex";
import TIMEZONE from "app/shared/utils/timezone";
import { MatMenuTrigger } from "@angular/material/menu";
import { LoggerService } from "app/shared/services/logger.service";
import { MatSliderChange } from "@angular/material/slider";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UserConfigRepository } from "app/talk/repositories/userconfig.repository";
import { DeleteChatBackgroundImage, SetChatBackgroundColorPreview, SetChatBackgroundImages, SetSelectedChatBackgroundPreview } from "app/actions/app";
import { ColorControlComponent } from "../color-control/color-control.component";
import { VNCBottomSheetAddActionsMenu } from "../bottom-sheet-add-action-menu/bottom-sheet-add-action-menu.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

const DEFAULT_SEND_KEY = "Enter";

@Component({
  selector: "vp-settings",
  styleUrls: ["./settings.scss"],
  templateUrl: "./settings.html"
})
export class VNCSettingsComponent implements OnDestroy, AfterViewInit {
  private isAlive$ = new Subject<boolean>();
  @ViewChild(VNCVerticalTabsComponent, {static: false}) tabs: VNCVerticalTabsComponent;
  @ViewChild("leftMenuTrigger") leftMenuTrigger: MatMenuTrigger;
  @ViewChild("rightMenuTrigger") rightMenuTrigger: MatMenuTrigger;
  @ViewChild("addActionMenuTrigger") addActionMenuTrigger: MatMenuTrigger;
  isCordova = environment.isCordova;
  availableMediaDevices: any = {
    audioInput: [],
    videoInput: [],
    audioOutput: []
  };
  itemsLength = null;
  hinIcon = CommonUtil.getFullUrl("/assets/vnc-theme-svg/theme-preview-orange.svg");
  ekboIcon = CommonUtil.getFullUrl("/assets/vnc-theme-svg/theme-preview-purple.svg");
  airbusIcon = CommonUtil.getFullUrl("/assets/vnc-theme-svg/theme-preview-airbus.svg");
  vnctalkIcon = CommonUtil.getFullUrl("/assets/vnc-theme-svg/theme-preview-default.svg");
  fruitBackgroundImage: SafeResourceUrl;
  planePatternImage: SafeResourceUrl;
  vncPatternImage: SafeResourceUrl;
  defaultChatBackgrounds = ConstantsUtil.DEFAULT_CHAT_BACKGROUND;
  customBackgroundImages: any [];
  messageAvatar: SafeResourceUrl;
  hinIconMobile: SafeResourceUrl;
  ekboIconMobile: SafeResourceUrl;
  airbusIconMobile: SafeResourceUrl;
  vnctalkIconMobile: SafeResourceUrl;
  currentAudioInputDeviceLabel: UntypedFormControl;
  currentVideoInputDeviceLabel: UntypedFormControl;
  currentAudioOutputDeviceLabel: UntypedFormControl;
  leftAction = "VOICE_CALL";
  rightAction = "DELETE";
  timezones = Object.keys(TIMEZONE).map(key => {
    return { key: key, value: TIMEZONE[key] };
  });
  stream: any;

  camDeviceAvailable: boolean = false;
  micDeviceAvailable: boolean = false;
  audioOutputDeviceAvailable: boolean = false;

  @ViewChild("videoElem" , {static: false}) videoElem: ElementRef;

  levels = Array.from(Array(40), () => "");
  volumeLevel: any = 0;
  hideVideoPreview: boolean;

  isAccessingPermissionsForFirstTime = false;
  settingsForm: UntypedFormGroup;
  lang: UntypedFormControl;
  serverURL: UntypedFormControl;
  notification: UntypedFormControl;
  desktopNotification: UntypedFormControl;
  selectedTheme: UntypedFormControl;
  selectedFontSize: UntypedFormControl;
  badgeColor: UntypedFormControl;
  isAppOnline: boolean;
  dateFormat: UntypedFormControl;
  timeFormat: UntypedFormControl;
  timezone: UntypedFormControl;
  serverUrlRegex = "(http|https)://[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?";

  isCurrentTabSecurity = false;

  themes = [
    { title: "Airbus Theme", value: "airbus" },
    { title: "Purple Theme", value: "ekbo" },
    { title: "Yellow Theme", value: "hin" },
    { title: "VNCtalk", value: "vnctalk" }
  ];

  fontSizes = [
    { title: "10px", value: "10" },
    { title: "12px", value: "12" },
    { title: "14px", value: "14" },
    { title: "16px", value: "16" },
    { title: "18px", value: "18" },
    { title: "20px", value: "20" },
  ];

  receipts: UntypedFormControl;
  informTyping: UntypedFormControl;
  publicLastActivity: UntypedFormControl;
  addTimestamp: UntypedFormControl;
  enableOperators: UntypedFormControl;
  enableOmemoEncryption: UntypedFormControl;
  useEncryption: UntypedFormControl;
  vibration: UntypedFormControl;
  ledNotification: UntypedFormControl;
  enabledSound: UntypedFormControl;
  notificationSound: UntypedFormControl;
  callRingtone: UntypedFormControl;
  silentHours: UntypedFormControl;
  daysOptions: UntypedFormControl;
  startTime: UntypedFormControl;
  endTime: UntypedFormControl;
  sendKey: UntypedFormControl;
  hideInsecureContent: UntypedFormControl;
  globalMute: UntypedFormControl;
  enableAutofocus: UntypedFormControl;
  publishProfile: UntypedFormControl;
  emailNotification: UntypedFormControl;
  emailNotificationRepeat: UntypedFormControl;
  allowScreensharePreviewNew: UntypedFormControl;
  autoDeviceChange: UntypedFormControl;
  enableRTF: UntypedFormControl;
  leftSwipe: UntypedFormControl;
  rightSwipe: UntypedFormControl;
  enableChannels: UntypedFormControl;
  enableSocials: UntypedFormControl;
  enableBroadcasts: UntypedFormControl;
  enableMCB: UntypedFormControl;
  enablePad: UntypedFormControl;
  enableWhiteboard: UntypedFormControl;

  isGlobalMuteEnabled = false;
  enableHoursSetting = false;
  enableFontSetting = false;
  use2FA: UntypedFormControl;
  is2FAEnabled: boolean = false;
  isSupport2FA: any;
  playingNotification = false;
  playingRingtone = false;
  supportedLanguages = [
    {
      value: "en",
      display: "ENGLISH",
      elementId: "setLanguageEnglish"
    },
    {
      value: "de",
      display: "GERMAN",
      elementId: "setLanguageGerman"
    },
    {
      value: "fr",
      display: "FRENCH",
      elementId: "setLanguageFrench"
    }
  ];
  notifyOptions = [
    {
      value: "0",
      display: environment.theme === "hin" ? "NO_NOTIFICATIONS_MOBILE_HIN" : "NO_NOTIFICATIONS_MOBILE",
      elementId: "disableNotifications"
    },
    {
      value: "1",
      display: environment.theme === "hin" ? "NOTIFICATIONS_WITHOUT_TEXT_HIN" : "NOTIFICATIONS_WITHOUT_TEXT",
      elementId: "enableChatNotifications"
    },
    {
      value: "2",
      display: environment.theme === "hin" ? "NOTIFICATIONS_WITH_TEXT_HIN" : "NOTIFICATIONS_WITH_TEXT",
      elementId: "setNoChatNotifications"
    }
  ];
  desktopNotifyOptions = [
    {
      value: "0",
      display: "NO_NOTIFICATIONS",
      elementId: "disableNotifications"
    },
    {
      value: "1",
      display: "NOTIFICATION_NON_STICKY",
      elementId: "showNoneStickyNotifications"
    },
    {
      value: "2",
      display: "NOTIFICATION_STICKY",
      elementId: "showStickyNotifications"
    }
  ];
  emailNotificationOptions = [
    {
      value: "0",
      display: "OFF",
      elementId: "offNotifications"
    },
    {
      value: "1",
      display: "AFTER_4_HOURS",
      elementId: "fourHoursNotifications"
    },
    {
      value: "2",
      display: "AFTER_1_DAY",
      elementId: "oneDayNotifications"
    },
    {
      value: "3",
      display: "AFTER_2_DAYS",
      elementId: "TwoDaysNotifications"
    },
    {
      value: "4",
      display: "AFTER_1_WEEK",
      elementId: "oneWeekNotifications"
    }
  ];
  emailNotificationRepeatOptions = [
    {
      value: "0",
      display: "ONLY_ONCE",
      elementId: "onlyOnceNotifications"
    },
    {
      value: "1",
      display: "EVERY_DAY",
      elementId: "everydayNotifications"
    }
  ];
  soundOptions = [
    {
      value: "0",
      display: "DEFAULT_SOUND",
      elementId: "defaultSound"
    },
    {
      value: "incomming_echo",
      display: "Incomming echo",
      elementId: "incomming_echo"
    },
    {
      value: "incomming_echo_alt",
      display: "Incomming echo alt",
      elementId: "incomming_echo_alt"
    },
    {
      value: "knock_alt",
      display: "Knock alt",
      elementId: "knock_alt"
    }
  ];
  ringtoneOptions = [
    {
      value: "onHoldRingtone",
      display: "On hold ringtone",
      elementId: "onHoldRingtone"
    },
    {
      value: "europeanPhoneConnecting",
      display: "European phone connecting",
      elementId: "europeanPhoneConnecting"
    },
    {
      value: "retroMovieRingtone",
      display: "Retro movie ringtone",
      elementId: "retroMovieRingtone"
    },
    {
      value: "technologyRingtone",
      display: "Technology ringtone",
      elementId: "technologyRingtone"
    },
    {
      value: "ringtoneLoop3",
      display: "Ringtone loop 3",
      elementId: "ringtoneLoop3"
    },
    {
      value: "technologyRingtone5",
      display: "Technology ringtone 5",
      elementId: "technologyRingtone5"
    },
    {
      value: "technologyRingtone4",
      display: "Technology ringtone 4",
      elementId: "technologyRingtone4"
    },
    {
      value: "polite_alert",
      display: "Polite alert",
      elementId: "polite_alert"
    },
    {
      value: "aliens_alarm",
      display: "Aliens alarm",
      elementId: "aliens_alarm"
    },
    {
      value: "sea_queen",
      display: "Sea queen",
      elementId: "sea_queen"
    }
  ];
  dayOptions = [
    {
      value: "MONDAY",
      display: "MONDAY",
      elementId: "MONDAY"
    },
    {
      value: "TUESDAY",
      display: "TUESDAY",
      elementId: "TUESDAY"
    },
    {
      value: "WEDNESDAY",
      display: "WEDNESDAY",
      elementId: "WEDNESDAY"
    },
    {
      value: "THURSDAY",
      display: "THURSDAY",
      elementId: "THURSDAY"
    },
    {
      value: "FRIDAY",
      display: "FRIDAY",
      elementId: "FRIDAY"
    },
    {
      value: "SATURDAY",
      display: "SATURDAY",
      elementId: "SATURDAY"
    },
    {
      value: "SUNDAY",
      display: "SUNDAY",
      elementId: "SUNDAY"
    },
    {
      value: "EVERY_DAY",
      display: "EVERY_DAY",
      elementId: "EVERY_DAY"
    }
  ];
  keyOptions = [
    {
      value: "Enter",
      display: "ENTER",
      elementId: "EnterKey"
    },
    {
      value: "Ctrl_Enter",
      display: "CTRL_ENTER",
      elementId: "CtrlEnterKey"
    }
  ];

  omemoDevicesList = [];
  omemoDevicesListUpdated = null;
  omemoCurrentDeviceId: number;
  isOmemoMaxDeviceReached = false;
  isOmemoDeviceRetrievalInProgress = false;

  timeOptions = [];
  disableThemeOption = environment.disableThemeOption;
  disableSoundSetting = environment.disableSoundSetting;
  disableSecuritySetting = environment.disableSecuritySetting;
  disableBooleanOperatorSetting = environment.disableBooleanOperatorSetting;
  disableServerUrl = environment.disableServerUrl;
  disablePublishUserData = environment.disablePublishUserData;
  isHin = environment.theme === "hin";
  EMAIL_NOTIFICATION = this.isHin ? "EMAIL_NOTIFICATION_HIN" : "EMAIL_NOTIFICATION";
  EMAIL_NOTIFICATION_REPEATS = this.isHin ? "EMAIL_NOTIFICATION_REPEATS_HIN" : "EMAIL_NOTIFICATION_REPEATS";
  isSupportedRTF: any;
  showScreenshareSettings: any;
  notificationSettings = {
    broadcastEnableNotification: true,
    broadcastShowBadge: true,
    mentionEnableNotification: true,
    mentionShowBadge: true,
    channelNewTopic: true,
    channelInvite: true,
    channelNewComment: true,
    channelReplyComment: true,
    chatMention: true,
    chatConferenceReminder: true,
    chatNotifyMissedCall: true,
    sendMessageWithEnter: true,
    addTimestamp: false
  };
  private __incomingCall: HTMLAudioElement;
  private __notificationSound: HTMLAudioElement;
  defaultTheme: string;
  mobileSettings: any;
  selectedTab: any;
  firstTime: boolean;
  defaultDateFormat = CommonUtil.getDatePattern(navigator.language || "en-US");
  canManageMCBs: boolean;
  themeTooltipPosition : string = "above";
  showDateTimeFormat: boolean = true;
  changeLanguageSave: boolean = true;
  selectedChatBackground: UntypedFormControl;
  uploadImageType: string = "";
  chatBackgroundColor: string = "#FFFFFF";
  imageExtensions = ["jpeg", "jpg", "gif", "png", "bmp"];
  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private jitsiService: JitsiService,
    private conferenceRepo: ConferenceRepository,
    private dialogRef: MatDialogRef<VNCSettingsComponent>,
    private broadcaster: Broadcaster,
    private xmppService: XmppService,
    private tfaRepo: TFARepository,
    private appService: AppService,
    private vncDirectoryService: VNCDirectoryService,
    private matDialog: MatDialog,
    private store: Store<RootState>,
    private translate: TranslateService,
    private commonService: CommonService,
    private snackbar: MatSnackBar,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private databaseService: DatabaseService,
    private domSanitizer: DomSanitizer,
    private convRepository: ConversationRepository,
    private changeDetectorRef: ChangeDetectorRef,
    public userConfigRepo: UserConfigRepository,
    private logger: LoggerService,
    private _bottomSheet: MatBottomSheet,
    private vncLibraryService: VncLibraryService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.logger.info("[VNCSettingsComponent]", this.tabs, this.data);
    this.fruitBackgroundImage = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/chat-backgrounds/Background-fruits.png"));
    this.planePatternImage = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/chat-backgrounds/Pattern--Planes.png"));
    this.vncPatternImage = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/chat-backgrounds/Pattern--VNC.png"));
    this.messageAvatar = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/message-avatar.png"));
    this.hinIconMobile = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/theme-preview-orange.svg"));
    this.ekboIconMobile = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/theme-preview-purple.svg"));
    this.airbusIconMobile = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/theme-preview-airbus.svg"));
    this.vnctalkIconMobile = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/theme-preview-default.svg"));
    const defaultLang = CommonUtil.getDefaultLang();
    if (this.defaultDateFormat !== "dd/MM/yyyy" && this.defaultDateFormat !== "dd.MM.yyyy" && this.defaultDateFormat !== "MM/dd/yyyy" && this.defaultDateFormat !== "yyyy-MM-dd") {
      this.defaultDateFormat = "MM/dd/yyyy";
    }
    this.selectedTab = this.data?.selectedTab;
    this.mobileSettings = this.data?.mobileSettings;
    this.setupForm(defaultLang);
    this.selectedChatBackground.setValue(this.convRepository.changeChatBackgroundImageId);
    for (let i = 0; i < 24; i++) {
      let value = i.toString();
      if (i < 10) {
        value = "0" + i;
      }
      this.timeOptions.push({ value: `${value}:00`, display: `${value}:00` });
      this.timeOptions.push({ value: `${value}:30`, display: `${value}:30` });
    }
    this.broadcaster.on<any>("hideMobileSettings").pipe(takeUntil(this.isAlive$)).subscribe(() => {
      if (this.selectedTab === "ALL") {
        this.cancel();
      } else {
        this.backToSettings();
      }
    });
    this.broadcaster.on<any>("CLOSE_ACTION_MENU").pipe(takeUntil(this.isAlive$)).subscribe(() => {
      if (this.leftMenuTrigger) {
        this.leftMenuTrigger.closeMenu();
      }
      if (this.rightMenuTrigger) {
        this.rightMenuTrigger.closeMenu();
      }
    });

    // resync if updated on other active client
    this.broadcaster.on<any>("omemo-devices-updated").pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.retrieveOMEMODevices();
    });

    this.selectedChatBackground.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(res => {
      this.changeChatBackground(true);
    });
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    if (window.innerWidth < 766) {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.store.select(getChatBackgrounds).pipe(takeUntil(this.isAlive$)).subscribe(backgrounds => {
      this.logger.info("[getChatBackgrounds]", backgrounds);
      if (backgrounds && backgrounds.length) {
        this.customBackgroundImages = backgrounds;
        this.changeDetectorRef.markForCheck();
      }
    });
    this.retrieveOMEMODevices();
    this.conferenceRepo.toggleHideVideoIOS(true);
    this.store.select(getUserConfig).pipe(take(1)).subscribe(userConfig => {
      this.logger.info("[getUserConfig]", userConfig);
      if (!!userConfig) {
        this.canManageMCBs = userConfig.can_manage_mcbs;
      }
    });
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!this.timezones.find(v => v.key === localTimezone)) {
      this.timezones.push({
        key: localTimezone,
        value: new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]
      });
    }
    this.lang.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe((val) => {
      if (val === "de")
        this.timeFormat.setValue("HH:mm");

      this.showDateTimeFormat = false;
      setTimeout(() => {
        this.showDateTimeFormat = true;
      }, 10);
    });
    this.setVerticalClass();
  }

  setVerticalClass() {
    setTimeout(() => {
      const tabElement = document.getElementsByTagName("vnc-vertical-tabs")[0];
      if (tabElement) {
        const listElement = tabElement.getElementsByTagName("ul")[0];
        if (listElement) listElement.classList.add("vnc-scroll-bar--small--vertical");
      }
    }, 500);
  }

  private setupForm(defaultLang: string) {
    this.currentAudioInputDeviceLabel = new UntypedFormControl("", []);
    this.currentVideoInputDeviceLabel = new UntypedFormControl("", []);
    this.currentAudioOutputDeviceLabel = new UntypedFormControl("", []);
    this.lang = new UntypedFormControl(defaultLang, []);
    this.notification = new UntypedFormControl("2", []);
    this.desktopNotification = new UntypedFormControl("2", []);
    this.receipts = new UntypedFormControl("1", []);
    this.hideInsecureContent = new UntypedFormControl("1", []);
    this.enableOmemoEncryption = new UntypedFormControl("0", []);
    this.badgeColor = new UntypedFormControl("red", []);
    this.informTyping = new UntypedFormControl("1", []);
    this.publicLastActivity = new UntypedFormControl("1", []);
    this.addTimestamp = new UntypedFormControl("0", []);
    this.enableOperators = new UntypedFormControl("1", []);
    this.useEncryption = new UntypedFormControl("0", []);
    this.vibration = new UntypedFormControl("1", []);
    this.ledNotification = new UntypedFormControl("1", []);
    this.enabledSound = new UntypedFormControl("1", []);
    this.publishProfile = new UntypedFormControl("1", []);
    this.notificationSound = new UntypedFormControl("0", []);
    this.allowScreensharePreviewNew = new UntypedFormControl("1", []);
    this.autoDeviceChange = new UntypedFormControl("0", []);
    this.silentHours = new UntypedFormControl("0", []);
    this.daysOptions = new UntypedFormControl("EVERY_DAY", []);
    this.startTime = new UntypedFormControl("00:00", []);
    this.endTime = new UntypedFormControl("23:30", []);
    const selectedTheme = localStorage.getItem(ConstantsUtil.THEME) || environment.theme;
    this.selectedTheme = new UntypedFormControl(selectedTheme, []);
    const selectedChatBackground = localStorage.getItem(ConstantsUtil.CHAT_BACKGROUND) || environment.chatBackground;
    this.selectedChatBackground = new UntypedFormControl(selectedChatBackground, []);
    const chatBackgroundColor = localStorage.getItem(ConstantsUtil.CHAT_BACKGROUND_COLOR) || environment.chatBackgroundColor;
    this.chatBackgroundColor = chatBackgroundColor;
    const selectedFontSize = localStorage.getItem(ConstantsUtil.FONT_SIZE) || "16";
    this.selectedFontSize = new UntypedFormControl(selectedFontSize, []);
    this.timezone = new UntypedFormControl("", []);
    this.timeFormat = new UntypedFormControl("hh:mm a", []);
    this.dateFormat = new UntypedFormControl("dd/MM/yyyy", []);
    this.sendKey = new UntypedFormControl(DEFAULT_SEND_KEY, []);
    this.globalMute = new UntypedFormControl(this.isGlobalMuteEnabled, []);
    this.enableAutofocus = new UntypedFormControl("0", []);
    this.emailNotification = new UntypedFormControl("0", []);
    this.emailNotificationRepeat = new UntypedFormControl("0", []);
    this.enableRTF = new UntypedFormControl("0");
    this.leftSwipe = new UntypedFormControl("0");
    this.rightSwipe = new UntypedFormControl("0");

    this.enableChannels = new UntypedFormControl("1");
    this.enableSocials = new UntypedFormControl("1");
    this.enableBroadcasts = new UntypedFormControl("1");
    this.enableMCB = new UntypedFormControl("1");
    this.enablePad = new UntypedFormControl("1");
    this.enableWhiteboard = new UntypedFormControl("1");

    this.use2FA = new UntypedFormControl("0");
    this.callRingtone = new UntypedFormControl("0", []);
    if (environment.isCordova && this.data.selectedTab === "notificationSettings") {
      this.callRingtone = new UntypedFormControl("0", []);
    }
    const defaultGroup = {
      "lang": this.lang,
      "currentAudioInputDeviceLabel": this.currentAudioInputDeviceLabel,
      "currentVideoInputDeviceLabel": this.currentVideoInputDeviceLabel,
      "currentAudioOutputDeviceLabel": this.currentAudioOutputDeviceLabel,
      "notification": this.notification,
      "desktopNotification": this.desktopNotification,
      "badgeColor": this.badgeColor,
      "dateFormat": this.dateFormat,
      "timeFormat": this.timeFormat,
      "timezone": this.timezone,
      "receipts": this.receipts,
      "informTyping": this.informTyping,
      "publicLastActivity": this.publicLastActivity,
      "addTimestamp": this.addTimestamp,
      "enableOperators": this.enableOperators,
      "vibration": this.vibration,
      "ledNotification": this.ledNotification,
      "enabledSound": this.enabledSound,
      "notificationSound": this.notificationSound,
      "callRingtone": this.callRingtone,
      "silentHours": this.silentHours,
      "daysOptions": this.daysOptions,
      "hideInsecureContent": this.hideInsecureContent,
      "startTime": this.startTime,
      "endTime": this.endTime,
      "selectedTheme": this.selectedTheme,
      "selectedFontSize": this.selectedFontSize,
      "sendKey": this.sendKey,
      "globalMute": this.globalMute,
      "enableAutofocus": this.enableAutofocus,
      "allowScreensharePreviewNew": this.allowScreensharePreviewNew,
      "autoDeviceChange": this.autoDeviceChange,
      "publishProfile": this.publishProfile,
      "emailNotification": this.emailNotification,
      "emailNotificationRepeat": this.emailNotificationRepeat,
      "enableRTF": this.enableRTF,
      "leftSwipe": this.leftSwipe,
      "rightSwipe": this.rightSwipe,
      "use2FA": this.use2FA,
      "enableChannels": this.enableChannels,
      "enableSocials": this.enableSocials,
      "enableBroadcasts": this.enableBroadcasts,
      "enableMCB": this.enableMCB,
      "enablePad": this.enablePad,
      "enableWhiteboard": this.enableWhiteboard,
      "enableOmemoEncryption": this.enableOmemoEncryption,
      "selectedChatBackground": this.selectedChatBackground,
    };
    let serverUrl = "";
    this.serverURL = new UntypedFormControl("", []);
    if (environment.isCordova) {
      this.store.select(getBaseApiUrl).pipe(takeUntil(this.isAlive$)).subscribe(u => serverUrl = u);
      if (CommonUtil.isOnIpad()) {
        this.serverURL = new UntypedFormControl("", []);
      } else {
        this.serverURL = new UntypedFormControl("", [Validators.pattern(this.serverUrlRegex)]);
      }
      defaultGroup["server_url"] = this.serverURL;
    }
    this.settingsForm = new UntypedFormGroup(defaultGroup);
    if (environment.isCordova) {
      setTimeout(() => {
        if (document.querySelector("#serverUrl") !== null) {
          document.querySelector("#serverUrl").classList.add("is-focused");
        }
        this.serverURL.setValue(serverUrl);
        this.changeDetectorRef.markForCheck();
      }, 100);
    }
    this.setupStore();
    this.get2FAStatus();
  }

  get settingLabel() {
    let key = "SETTINGS";
    switch(this.selectedTab) {
      case "globalSettings": key = "GLOBAL_SETTINGS"; break;
      case "notificationSettings": key = "NOTIFICATIONS_SOUND"; break;
      case "videoSettings": key = "AUDIO_VIDEO"; break;
      case "securitySettings": key = "SECURITY_PRIVACY"; break;
      case "featuresSettings": key = "FEATURES"; break;
      case "appearanceSettings": key = "APPEARANCE"; break;
    }
    return key;
  }

  sanitizeSrc(s) {
    let src = this.domSanitizer.bypassSecurityTrustResourceUrl(s);
    return src;
  }

  private retrieveOMEMODevices() {
    this.logger.info("[VNCSettingsComponent][retrieveOMEMODevices]");
    this.broadcaster.broadcast("closeOMEMODialog");
    this.isOmemoDeviceRetrievalInProgress = true;
    this.changeDetectorRef.markForCheck();

    this.xmppService.getOMEMODevices().then((devices: any[]) => {
      this.logger.info("[VNCSettingsComponent][retrieveOMEMODevices] devices", devices);
      this.omemoDevicesList = Array.from(devices).filter(d => !!d && !!d.id); // just to remove any trash
      this.omemoDevicesList.reverse();
      this.omemoDevicesListUpdated = [...this.omemoDevicesList];

      const isOmemoMaxDeviceReached = this.omemoDevicesList.length >= CommonUtil.OMEMO_MAX_DEVICES;
      if (this.isOmemoMaxDeviceReached && !isOmemoMaxDeviceReached) {
        this.logger.info("[VNCSettingsComponent][retrieveOMEMODevices] now register new device");
        // init omemo with new device
        this.broadcaster.broadcast("forceInitOmemo");

        this.isOmemoDeviceRetrievalInProgress = true;
      } else {
        this.isOmemoDeviceRetrievalInProgress = false;
      }

      this.isOmemoMaxDeviceReached = isOmemoMaxDeviceReached;

      this.changeDetectorRef.markForCheck();
    }).catch(err => {
      this.logger.sentryErrorLog("[VNCSettingsComponent][retrieveOMEMODevices]", err);
      this.logger.error("[VNCSettingsComponent][retrieveOMEMODevices]", err);
    });

    this.databaseService.getLocalDevice().subscribe(localDevice => {
      this.logger.info("[VNCSettingsComponent][retrieveOMEMODevices] getLocalDevice", localDevice);
      this.omemoCurrentDeviceId = localDevice?.id;
      this.logger.info("[VNCSettingsComponent][retrieveOMEMODevices] omemoCurrentDeviceId", this.omemoCurrentDeviceId);
    });
  }

  public get2FAStatus() {
    this.isSupport2FA = this.configService.get("twoFactorAuthentication");
    if (this.isSupport2FA) {
      this.tfaRepo.get2FAStatus().pipe(take(1)).subscribe( value => {
        if (value && value.tfa_enable) {
          this.is2FAEnabled = value.tfa_enable !== "false" ? true : false;
          this.use2FA.setValue(this.is2FAEnabled);
          this.changeDetectorRef.markForCheck();
        }
      });
    }
  }

  onInputChange(event: MatSliderChange, control: UntypedFormControl) {
    control.patchValue(event.value);
  }

  private setupStore() {
    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
    this.configService.getLoadedConfig().pipe(takeUntil(this.isAlive$)).subscribe(() => {
      if (this.isSupportedRTF !== this.configService.get("isSupportedRTF")) {
        this.isSupportedRTF = this.configService.get("isSupportedRTF");
        this.changeDetectorRef.markForCheck();
      }
      if (this.isSupport2FA !== this.configService.get("twoFactorAuthentication")) {
        this.isSupport2FA = this.configService.get("twoFactorAuthentication");
        this.changeDetectorRef.markForCheck();
      }
    });

    this.store.select(getGlobalMute)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(v => {
        this.isGlobalMuteEnabled = v;
        this.logger.info("app-settings getGlobalMute v: ", v);
        this.globalMute.setValue(this.isGlobalMuteEnabled);
        this.changeDetectorRef.markForCheck();
      });

    this.store.select(getAppSettings)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(options => {
        this.logger.info("[AppSettings][setupStore][getAppSettings]", options);
        this.lang.setValue(this.lang.value);
        this.receipts.setValue(options.receipts);
        this.informTyping.setValue(options.informTyping);
        this.publicLastActivity.setValue(options.publicLastActivity);
        this.addTimestamp.setValue(options.addTimestamp);
        this.enableOperators.setValue(options.enableOperators);
        this.vibration.setValue(options.vibration);
        this.ledNotification.setValue(options.ledNotification);
        if (typeof options.enabledSound === "undefined") {
          this.enabledSound.setValue("1");
        } else {
          this.enabledSound.setValue(options.enabledSound);
        }

        this.notificationSound.setValue(options.notificationSound);
        this.callRingtone.setValue(options.callRingtone || "ringtoneLoop3");
        this.silentHours.setValue(options.silentHours);
        this.daysOptions.setValue(options.daysOptions);
        this.badgeColor.setValue(options.badgeColor || "red");
        this.timezone.setValue(options.timezone || Intl.DateTimeFormat().resolvedOptions()?.timeZone);

        this.dateFormat.setValue(options.dateFormat || this.defaultDateFormat);
        let defaultTimeFormat = this.defaultDateFormat.indexOf(".") !== -1 ? "HH:mm" : "hh:mm a";
        this.timeFormat.setValue(options.timeFormat || defaultTimeFormat);
        this.logger.info("default date time settings", this.defaultDateFormat, defaultTimeFormat);
        this.hideInsecureContent.setValue(options.hideInsecureContent);
        this.startTime.setValue(options.startTime);
        this.endTime.setValue(options.endTime);
        this.allowScreensharePreviewNew.setValue(options.allowScreensharePreviewNew);
        this.autoDeviceChange.setValue(options.autoDeviceChange);
        this.selectedTheme.setValue(options.selectedTheme || this.selectedTheme.value);
        this.defaultTheme = options.selectedTheme;
        this.selectedChatBackground.setValue(options.selectedChatBackground || this.selectedChatBackground.value);
        this.selectedFontSize.setValue(options.selectedFontSize || "16");
        this.sendKey.setValue(options.sendKey || DEFAULT_SEND_KEY);
        this.enableAutofocus.setValue((!!(options.enableAutofocus)) ? "0" : options.enableAutofocus);
        this.publishProfile.setValue((!!(options.publishProfile)) ? "0" : options.publishProfile);
        this.use2FA.setValue((!!(options.use2FA)) ? "0" : options.use2FA);
        this.emailNotification.setValue(options.emailNotification || "0");
        this.emailNotificationRepeat.setValue(options.emailNotificationRepeat || "0");
        this.enableRTF.setValue((!!(options.enableRTF)) ? "0" : options.enableRTF);
        this.leftSwipe.setValue((!!(options.leftSwipe)) ? "0" : options.leftSwipe);
        this.rightSwipe.setValue((!!(options.rightSwipe)) ? "0" : options.rightSwipe);
        this.leftAction = !!options.leftAction ? options.leftAction : "VOICE_CALL";
        this.rightAction = !!options.rightAction ? options.rightAction : "DELETE";

        // New Features settings
        this.enableOmemoEncryption.setValue(!!options.enableOmemoEncryption || typeof options.enableOmemoEncryption === "undefined" ? "0" : options.enableOmemoEncryption);
        this.enableChannels.setValue(!!options.enableChannels || typeof options.enableChannels === "undefined" ? "1" : options.enableChannels);
        this.enableSocials.setValue(!!options.enableSocials || typeof options.enableSocials === "undefined" ? "1" : options.enableSocials);
        this.enableBroadcasts.setValue(!!options.enableBroadcasts || typeof options.enableBroadcasts === "undefined" ? "1" : options.enableBroadcasts);
        this.enableWhiteboard.setValue(!!options.enableWhiteboard || typeof options.enableWhiteboard === "undefined" ? "1" : options.enableWhiteboard);
        this.enableMCB.setValue(!!options.enableMCB || typeof options.enableMCB === "undefined" ? "1" : options.enableMCB);
        this.enablePad.setValue(!!options.enablePad || typeof options.enablePad === "undefined" ? "1" : options.enablePad);
        if (this.isCordova) {
          this.notification.setValue(options.notification || "1");
        } else {
          this.desktopNotification.setValue(options.desktopNotification || "2");
        }
        if (!!options.globalMute) {
          this.isGlobalMuteEnabled = true;
        } else {
          this.isGlobalMuteEnabled = false;
        }
        Object.keys(this.notificationSettings).forEach(key => {
          this.logger.info("[notificationSettings]", key, options[key]);
          this.notificationSettings[key] = typeof options[key] !== "undefined" ? !!options[key] : true;
        });
        this.globalMute.setValue(this.isGlobalMuteEnabled);
        this.setGlobalMute();
        this.changeDetectorRef.markForCheck();
      });
  }

  submitForm() {
    if (!this.isAppOnline) {
      this.translate.get(["OFFLINE_MODE"])
        .pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.snackbar.open(res.OFFLINE_MODE);
        });
      return;
    }

    this.translate.use(this.lang.value);
    this.translate.reloadLang(this.lang.value);
    this.commonService.language = this.lang.value;
    this.changeLanguageSave = false;

    if (this.lang.value !== "en") {
      this.dateTimeAdapter.setLocale("de-DE");
    } else {
      this.dateTimeAdapter.setLocale("en-EN");
    }
    setTimeout(() => {
      this.changeLanguageSave = true;
    }, 10);
    this.updateSettings();
  }

  private changeTheme() {
    this.appService.changeTheme(this.selectedTheme.value);
  }

  private changeChatBackground (previewOnly: boolean = false){
    if (!previewOnly) {
      localStorage.setItem(ConstantsUtil.CHAT_BACKGROUND_COLOR, this.chatBackgroundColor);
    }
    this.appService.changeChatBackground(this.selectedChatBackground.value, previewOnly);
  }

  resetChatBackground () {
    const previousChatBackground = localStorage.getItem(ConstantsUtil.CHAT_BACKGROUND) || environment.chatBackground;
    if (previousChatBackground !== "background_color") {
      this.store.dispatch(new SetSelectedChatBackgroundPreview(previousChatBackground));
    } else {
      this.chatBackgroundColor = localStorage.getItem(ConstantsUtil.CHAT_BACKGROUND_COLOR) || environment.chatBackgroundColor;
      this.store.dispatch(new SetChatBackgroundColorPreview(this.chatBackgroundColor));
    }
  }

  get isOnMobile(): boolean {
    return CommonUtil.isOnMobileDevice();
  }

  get isOnIOS(): boolean {
    return CommonUtil.isOnIOS();
  }


  onOMEMODDeviceSelect(event) {
    const checked = event.checked;
    const deviceId = +event.source.value;

    if (checked) {
      const device = this.omemoDevicesList.filter(d => d?.id === deviceId);
      this.omemoDevicesListUpdated.push(device);
      this.logger.info("[VNCSettingsComponent][onOMEMODDeviceSelect] add", deviceId);
    } else {
      this.omemoDevicesListUpdated = this.omemoDevicesListUpdated.filter(d => d.id !== deviceId);
      this.logger.info("[VNCSettingsComponent][onOMEMODDeviceSelect] remove", deviceId);
    }
  }

  save() {
    this.logger.info("[VNCSettingsComponent][updateSettings]", this.isSupport2FA, this.notificationSettings);
    this.submitForm();
  }

  toggleChange(key, data) {
    this.notificationSettings[key] = data.checked;
    this.logger.info("[toggleChange]", key, data.checked);
  }

  private postUpdatedOMEMODevices() {
    this.xmppService.announceDevices(this.omemoDevicesListUpdated).then(() => {
      this.logger.info("[VNCSettingsComponent][postUpdatedOMEMODevices] announceDevices", "Ok", this.omemoDevicesListUpdated);

      // update all other active clients
      const signal = {
        type: "omemo-devices-updated",
        target: "app"
      };
      this.xmppService.sendSignalToMyself(signal);
    }).catch(err => {
      this.logger.error("[VNCSettingsComponent][postUpdatedOMEMODevices] announceDevices", err);
      this.logger.sentryErrorLog("[VNCSettingsComponent][postUpdatedOMEMODevices] announceDevices", err);
    });
  }

  private updateSettings() {
    this.logger.info("[VNCSettingsComponent][updateSettings]", this.isSupport2FA, this.notificationSettings, this.data.selectedTab);

    // update omemo devices only
    if (this.isCurrentTabSecurity) {
      this.postUpdatedOMEMODevices();
    }

    let appOptions: AppSettings = {
      "lang": this.lang.value,
      "receipts": this.receipts.value,
      "informTyping": this.informTyping.value,
      "publicLastActivity": this.publicLastActivity.value,
      "addTimestamp": this.addTimestamp.value,
      "enableOperators": this.enableOperators.value,
      "globalMute": this.globalMute.value,
      "vibration": this.vibration.value,
      "ledNotification": this.ledNotification.value,
      "enabledSound": this.enabledSound.value,
      "notificationSound": this.notificationSound.value,
      "notification": this.notification.value,
      "desktopNotification": this.desktopNotification.value,
      "callRingtone": this.callRingtone.value,
      "silentHours": this.silentHours.value,
      "daysOptions": this.daysOptions.value,
      "startTime": this.startTime.value,
      "endTime": this.endTime.value,
      "sendKey": this.sendKey.value,
      "badgeColor": this.badgeColor.value,
      "enableAutofocus": this.enableAutofocus.value,
      "selectedTheme": this.selectedTheme.value,
      "selectedFontSize": this.selectedFontSize.value,
      "publishProfile": this.publishProfile.value,
      "emailNotification": this.emailNotification.value,
      "hideInsecureContent": this.hideInsecureContent.value,
      "emailNotificationRepeat": this.emailNotificationRepeat.value,
      "enableChannels": this.enableChannels.value,
      "enableSocials": this.enableSocials.value,
      "enableBroadcasts": this.enableBroadcasts.value,
      "enableWhiteboard": this.enableWhiteboard.value,
      "enableMCB": this.enableMCB.value,
      "enablePad": this.enablePad.value,
      "use2FA": this.use2FA.value,
      "enableOmemoEncryption": this.enableOmemoEncryption.value,
      "allowScreensharePreviewNew": this.allowScreensharePreviewNew.value,
      "autoDeviceChange": this.autoDeviceChange.value,
      "enableRTF": this.enableRTF.value,
      "leftSwipe": this.leftSwipe.value,
      "rightSwipe": this.leftSwipe.value,
      "leftAction": this.leftAction,
      "rightAction": this.rightAction,
      "dateFormat": this.dateFormat.value,
      "timeFormat": this.timeFormat.value,
      "timezone": this.timezone.value,
      "selectedChatBackground": this.selectedChatBackground.value,
      "chatBackgroundColor": this.chatBackgroundColor,
    };
    this.logger.info("app-settings updateLang", this.selectedChatBackground.value);

    const signal = {
      type: "changeBackgroundImage",
      target: this.selectedChatBackground.value,
    };
    this.xmppService.sendSignalToMyself(signal);

    if (this.autoDeviceChange.value) {
      localStorage.setItem("autoDeviceChange", "true");
    } else {
      localStorage.setItem("autoDeviceChange", "false");
    }

    this.vncDirectoryService.updateLang(this.lang.value).subscribe(res => {
      this.logger.info("app-settings updateLang res: ", res);
      this.broadcaster.broadcast("LangUpdated");
      this.changeDetectorRef.markForCheck();
    });
    const notifyOptionType = this.isCordova ? this.notification.value : this.desktopNotification.value;

    // Hide the settings modal
    appOptions = {...appOptions, ...this.notificationSettings};
    this.logger.info("[updateSettings] appOptions", appOptions);
    this.appService.updateSettings(notifyOptionType, appOptions)
      .subscribe(() => {
        this.setGlobalMute();
        this.set2FASettings();
        this.updateAudioSettings();
        if (environment.isCordova || environment.isElectron) {
          this.changeUrl();
        } else {
          this.changeTheme();
          this.changeChatBackground();
        }

      }, () => {
        this.setGlobalMute();
        this.set2FASettings();
        this.updateAudioSettings();
        if (environment.isCordova || environment.isElectron) {
          this.changeUrl();
        } else {
          this.changeTheme();
          this.changeChatBackground();
        }
      });
    this.dialogRef.close();
  }

  private set2FASettings() {
    if (this.isSupport2FA) {
      if (this.is2FAEnabled && !this.use2FA.value ) {
        this.tfaRepo.disableTFA().subscribe( () => {
          this.is2FAEnabled = false;
          this.changeDetectorRef.markForCheck();
        });
      }
    }
  }

  private setGlobalMute() {
    this.isGlobalMuteEnabled = this.globalMute.value;
    if (this.globalMute.value) {
      this.convRepository.enableGlobalMute().subscribe(() => this.processUpdateSettings(), () => this.processUpdateSettings());
    } else {
      this.convRepository.disableGlobalMute().subscribe(() => this.processUpdateSettings(), () => this.processUpdateSettings());
    }
  }

  private processUpdateSettings() {
    this.changeFontSize();
  }

  private changeUrl() {
    if (!this.isAppOnline) {
      this.translate.get(["OFFLINE_MODE"])
        .pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.snackbar.open(res.OFFLINE_MODE);
        });
      return;
    }

    if (this.isCordova && this.configService.API_URL !== this.serverURL.value.trim()) {
      const selectedURL = this.serverURL.value.trim();
      localStorage.removeItem("unreadCounts");
      this.appService.changeServerUrl(selectedURL).pipe(takeUntil(this.isAlive$)).subscribe(success => {
        if (success) {
          this.translate.get(["URL_CHANGED"])
            .pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
              this.databaseService.deleteDatabase().subscribe();
              this.snackbar.open(res.URL_CHANGED, null, {duration: 5000});
            });
          this.configService.setAPIURL(selectedURL);
          this.changeTheme();
          this.changeChatBackground();
          localStorage.removeItem("user");
          localStorage.removeItem(ConstantsUtil.KEY_USERNAME);
          this.appService.logout();
        } else {
          this.translate.get(["WRONG_URL"])
            .pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
              this.snackbar.open(res.WRONG_URL, null, {duration: 5000});
            });
        }
      }, () => {
        this.translate.get(["WRONG_URL"])
          .pipe(takeUntil(this.isAlive$))
          .subscribe(res => {
            this.snackbar.open(res.WRONG_URL, null, {duration: 5000});
          });
      });
    } else {
      this.changeTheme();
      this.changeChatBackground();
    }
  }

  async setAuthenticationApp() {
    let dialogStyles: any = {
      "width": "600px",
      "height": "630px",
      "maxHeight": "90%"
    };
    if (CommonUtil.isOnMobileDevice()) {
      dialogStyles = {
        "width": "100%",
        "height": "100%",
        "maxHeight": "100%"
      };
    }
    const { TFASettingsComponent } = await import(
      "../tfa-settings/tfa-settings.component");
    let dialogRef = this.matDialog.open(TFASettingsComponent, Object.assign({
      maxWidth: "100%",
      autoFocus: false,
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
    }, dialogStyles));
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.is2FAEnabled = true;
        localStorage.removeItem("token");
        this.appService.logout();
      }
    });
  }

  isMobileNotIpad() {
    return CommonUtil.isOnMobileDevice() || CommonUtil.isOnIpad();
  }

  ngAfterViewInit() {
    this.logger.info("[VNCSettingsComponent] ngAfterViewInit", this.tabs, this.data);
    if (this.data && this.data.selectedTab) {
      setTimeout(() => {
        this.tabs.selectTabById(this.data.selectedTab);
      }, 200);
    }
  }

  backToSettings() {
    this.tabs.selectTabById("ALL");
    this.selectedTab = "ALL";
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialogRef.close();
  }

  cancel() {
    this.resetChatBackground();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.stopStream();
    this.broadcaster.broadcast("stopAllPreviewMediaStreams");
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.conferenceRepo.toggleHideVideoIOS(false);
  }

  private changeFontSize() {
    this.appService.changeFontSize(this.selectedFontSize.value);
  }

  get chatSettings() {
    let translation: any = {};
    this.translate.get(["MENTIONS", "CONFERENCE_REMINDERS", "MISSED_CALLS", "ALL_MUTED"]).pipe(take(1)).subscribe(v => {
      translation = v;
    });
    let text = [];
    if (this.notificationSettings.chatMention) {
      text.push(translation.MENTIONS);
    }
    if (this.notificationSettings.chatConferenceReminder) {
      text.push(translation.CONFERENCE_REMINDERS);
    }
    if (this.notificationSettings.chatNotifyMissedCall) {
      text.push(translation.MISSED_CALLS);
    }
    if (text.length > 0) {
      return text.join(", ");
    }
    return translation.ALL_MUTED;
  }

  get channelSettings() {
    let translation: any = {};
    this.translate.get(["NEW_TOPIC_TEXT", "INVITE_TO_A_CHANNEL", "NEW_COMMENT", "ALL_MUTED", "NEW_REPLY"]).pipe(take(1)).subscribe(v => {
      translation = v;
    });
    let text = [];
    if (this.notificationSettings.channelNewTopic) {
      text.push(translation.NEW_TOPIC_TEXT);
    }
    if (this.notificationSettings.channelInvite) {
      text.push(translation.INVITE_TO_A_CHANNEL);
    }
    if (this.notificationSettings.channelNewComment) {
      text.push(translation.NEW_COMMENT);
    }
    if (this.notificationSettings.channelReplyComment) {
      text.push(translation.NEW_REPLY);
    }
    if (text.length > 0) {
      return text.join(", ");
    }
    return translation.ALL_MUTED;
  }

  get mentionSettings() {
    let translation: any = {};
    this.translate.get(["ENABLE_NOTIFICATIONS", "SHOW_BADGE", "ALL_MUTED"]).pipe(take(1)).subscribe(v => {
      translation = v;
    });
    let text = [];
    if (this.notificationSettings.mentionEnableNotification) {
      text.push(translation.ENABLE_NOTIFICATIONS);
    }
    if (this.notificationSettings.mentionShowBadge) {
      text.push(translation.SHOW_BADGE);
    }
    if (text.length > 0) {
      return text.join(", ");
    }
    return translation.ALL_MUTED;
  }

  get broadcastSettings() {
    let translation: any = {};
    this.translate.get(["ENABLE_NOTIFICATIONS", "SHOW_BADGE", "ALL_MUTED"]).pipe(take(1)).subscribe(v => {
      translation = v;
    });
    let text = [];
    if (this.notificationSettings.broadcastEnableNotification) {
      text.push(translation.ENABLE_NOTIFICATIONS);
    }
    if (this.notificationSettings.broadcastShowBadge) {
      text.push(translation.SHOW_BADGE);
    }
    if (text.length > 0) {
      return text.join(", ");
    }
    return translation.ALL_MUTED;
  }

  playRingtone() {
    this.playingRingtone = true;
    this.changeDetectorRef.markForCheck();
    if (this.__incomingCall) {
      this.__incomingCall.remove();
      this.__incomingCall = null;
    }
    if(this.callRingtone.value == "polite_alert" || this.callRingtone.value == "aliens_alarm" || this.callRingtone.value == "sea_queen"){
      this.__incomingCall = this.loadAudio(`assets/sounds/ringtones/${this.callRingtone.value}.wav`);
    } else{
      this.__incomingCall = this.loadAudio(`assets/sounds/ringtones/${this.callRingtone.value}.mp3`);
    }
    this.playAudioBrowser(this.__incomingCall);
  }

  private loadAudio(path: string): HTMLAudioElement {
    const audio = new Audio(path);
    audio.preload = "auto";
    audio.load();
    return audio;
  }

  private playAudioBrowser(audio: HTMLAudioElement) {
    if (!audio) {
      return;
    }
    audio.volume = 0.5;
    audio.addEventListener("ended",  () => {
        audio.pause();
        this.playingRingtone = false;
        this.playingNotification = false;
        audio.currentTime = 0;
        audio.remove();
    }, false);
    audio.play();
  }

  private stopPlayAudioBrowser(audio: HTMLAudioElement) {
    this.logger.info("[NotificationService][stopPlayAudioBrowser]");
    audio.pause();
    audio.currentTime = 0;
  }

  stopRingtone() {
    this.playingRingtone = false;
    this.changeDetectorRef.markForCheck();
    this.stopPlayAudioBrowser(this.__incomingCall);
  }

  playNotification() {
    this.playingNotification = true;
    this.changeDetectorRef.markForCheck();
    if (this.__notificationSound) {
      this.__notificationSound.remove();
      this.__notificationSound = null;
    }
    let fileName = this.notificationSound.value;
    if (fileName.indexOf("receive-message") === -1) {
      fileName = "receive-message";
    }
    if (this.notificationSound.value == "incomming_echo" || this.notificationSound.value == "incomming_echo_alt" || this.notificationSound.value == "knock_alt") {
      this.__notificationSound = this.loadAudio(`assets/sounds/${this.notificationSound.value}.wav`);
    } else {
      this.__notificationSound = this.loadAudio(`assets/sounds/${fileName}.mp3`);
    }
    this.playAudioBrowser(this.__notificationSound);
  }

  stopNotification() {
    this.playingNotification = false;
    this.changeDetectorRef.markForCheck();
    this.stopPlayAudioBrowser(this.__notificationSound);
  }

  getAvailableMediaDevices(skipChangeInput?: boolean) {
    this.logger.info("[VNCSettingsComponent][getAvailableMediaDevices]");

    if (!JitsiMeetJS.mediaDevices || !JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("input")) {
      return;
    }

    let videoInputDevices = [];
    let audioInputDevices = [];
    let audioOutputDevices = [];

    this.conferenceRepo.getMediaDevices().pipe(take(1)).subscribe(devices => {
      this.logger.info("[VNCSettingsComponent][getAvailableMediaDevices] devices", devices);

      if (devices) {
        devices.forEach(device => {
          switch (device.kind) {
            case ConstantsUtil.MEDIA_DEVICE_KIND.videoinput: {
              this.camDeviceAvailable = true;
              videoInputDevices.push({
                deviceId: device.deviceId,
                deviceLabel: device.label
              });
              break;
            }
            case ConstantsUtil.MEDIA_DEVICE_KIND.audioinput: {
              this.micDeviceAvailable = true;
              audioInputDevices.push({
                deviceId: device.deviceId,
                deviceLabel: device.label
              });
              break;
            }
            case ConstantsUtil.MEDIA_DEVICE_KIND.audiooutput: {
              this.audioOutputDeviceAvailable = true;
              audioOutputDevices.push({
                deviceId: device.deviceId,
                deviceLabel: device.label
              });
              break;
            }
          }
        });
      }

      this.isAccessingPermissionsForFirstTime = videoInputDevices.every(d => !d.deviceLabel);
      if(!videoInputDevices.length) {
        const defaultVideo  = {
          deviceId : "noCameraInput",
          deviceLabel : "none"
        };
        videoInputDevices.push(defaultVideo);
      }
      if(!audioInputDevices.length) {
        const defaultInput  = {
          deviceId : "noInput",
          deviceLabel : "none"
        };
        audioInputDevices.push(defaultInput);
      }
      if (!audioOutputDevices.length) {
        const defaultOutput  = {
          deviceId : "noOutput",
          deviceLabel : "none"
        };
        audioOutputDevices.push(defaultOutput);
      }
      if (audioInputDevices?.length == 1 && audioInputDevices[0]?.deviceId === "noInput" ) {
        this.currentAudioInputDeviceLabel.setValue("none");
      }
      else {
        if(audioInputDevices[0]?.deviceId === "" && audioInputDevices[0]?.deviceLabel === "") {
          audioInputDevices = audioInputDevices.filter(d => d.deviceId !== "" && d.deviceLabel !== "");
          const defaultInput  = {
            deviceId : "noInput",
            deviceLabel : "none"
          };
          audioInputDevices.push(defaultInput);
          this.currentAudioInputDeviceLabel.setValue("none");
        }
        else this.currentAudioInputDeviceLabel.setValue(this.jitsiService.getPreferableMicLabel());
      }

      if (videoInputDevices?.length == 1 && videoInputDevices[0]?.deviceId === "noCameraInput") {
        this.currentVideoInputDeviceLabel.setValue("none");
      }
      else {
        if(videoInputDevices[0]?.deviceId === "" && videoInputDevices[0]?.deviceLabel === "") {
          videoInputDevices = videoInputDevices.filter(d => d.deviceId !== "" && d.deviceLabel !== "");
          const defaultVideo  = {
            deviceId : "noCameraInput",
            deviceLabel : "none"
          };
          videoInputDevices.push(defaultVideo);
          this.currentVideoInputDeviceLabel.setValue("none");
        }
        else this.currentVideoInputDeviceLabel.setValue(this.jitsiService.getPreferableCameraLabel());
      }

      if(audioOutputDevices?.length == 1 && audioOutputDevices[0]?.deviceId === "noOutput" ) {
        this.currentAudioOutputDeviceLabel.setValue("none");
      }
      else {
        if (audioOutputDevices[0]?.deviceId === "" && audioOutputDevices[0]?.deviceLabel === "") {
          audioOutputDevices = audioOutputDevices.filter(d => d.deviceId !== "" && d.deviceLabel !== "");
          const defaultOutput  = {
            deviceId : "noOutput",
            deviceLabel : "none"
          };
          audioOutputDevices.push(defaultOutput);
          this.currentAudioOutputDeviceLabel.setValue("none");
        }
        else this.currentAudioOutputDeviceLabel.setValue(this.jitsiService.getPreferableAudioOutputLabel());
      }

      this.availableMediaDevices = {
        audioInput: audioInputDevices,
        videoInput: videoInputDevices,
        audioOutput: audioOutputDevices
      };
      this.logger.info("[VNCSettingsComponent][setDevices] availableMediaDevices", this.availableMediaDevices, this.isAccessingPermissionsForFirstTime);
      this.logger.info("[VNCSettingsComponent] getPreferableMicLabel", this.currentAudioInputDeviceLabel);
      this.logger.info("[VNCSettingsComponent] getPreferableCameraLabel", this.currentVideoInputDeviceLabel);
      this.logger.info("[VNCSettingsComponent] getPreferableAudioOutpuLabel", this.currentAudioOutputDeviceLabel);

      if (!skipChangeInput) {
        this.changeInput();
      }
    });
  }

  updateAudioSettings(): void {
    this.logger.info("[VNCSettingsComponent][updateAudioSettings]", CommonUtil.getDeviceId(), this.currentAudioInputDeviceLabel.value, this.currentVideoInputDeviceLabel.value, this.currentAudioOutputDeviceLabel.value);
    this.conferenceRepo.saveAVPreferencesAndChangeMediaDevices(this.currentVideoInputDeviceLabel.value, this.currentAudioInputDeviceLabel.value, this.currentAudioOutputDeviceLabel.value, this.availableMediaDevices);
    // this.conferenceRepo.updateDevicesList();
    this.dialogRef.close();
  }

  changeInput() {
    this.stopStream();
    this.logger.info("[VNCSettingsComponent][changeInput]", this.currentAudioInputDeviceLabel, this.currentVideoInputDeviceLabel);
    let constraints: any = {
      audio: this.availableMediaDevices.audioInput && this.availableMediaDevices.audioInput.length > 0,
      video: this.availableMediaDevices.videoInput && this.availableMediaDevices.videoInput.length > 0
    };
    if (this.currentAudioInputDeviceLabel && this.currentAudioInputDeviceLabel.value !== "none") {
      const audioInputDeviceId = this.jitsiService.getAudioInputDeviceIdByLabel(this.availableMediaDevices, this.currentAudioInputDeviceLabel.value);
      constraints.audio = { deviceId: audioInputDeviceId };
    }
    if (this.currentVideoInputDeviceLabel && this.currentVideoInputDeviceLabel.value !== "none") {
      const videoInputDeviceId = this.jitsiService.getVideoInputDeviceIdByLabel(this.availableMediaDevices, this.currentVideoInputDeviceLabel.value);
      constraints.video = { deviceId: videoInputDeviceId };
    }

    if (this.currentAudioInputDeviceLabel.value === "none") {
      constraints.audio = false;
    };
    if (this.currentVideoInputDeviceLabel.value === "none") {
      constraints.video = false;
    };

    this.logger.info("[VNCSettingsComponent][changeInput] getUserMedia constraints", constraints);
    this.jitsiService.getPreviewMediaStream(constraints).pipe(take(1)).subscribe(stream => {
      this.stream = stream;
      this.logger.info("[VNCSettingsComponent][changeInput] getUserMedia stream", stream);
      if (constraints.video) {
        this.setVideoPreview(stream);
      }
      if (constraints.audio) {
        this.setAudioLevel(stream);
      }

      if (this.isAccessingPermissionsForFirstTime || !this.currentVideoInputDeviceLabel) {
        this.isAccessingPermissionsForFirstTime = false;

        this.broadcaster.broadcast("forceGetMediaDevices");
        // re-get media devices to get proper labels
        this.getAvailableMediaDevices(true);
      }

    });
  }

  setAudioLevel(stream) {
    this.logger.info("[VNCSettingsComponent][setAudioLevel]");
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = () => {
      const tracks = stream.getAudioTracks();
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;

      const length = array.length;
      for (let index = 0; index < length; index++) {
        values += (array[index]);
      }
      const average = values / length;
      if (!!tracks[0] && !!tracks[0].readyState && (tracks[0].readyState === "ended")) {
        this.volumeLevel = 0;
        tracks[0].stop();
        javascriptNode.disconnect();
        analyser.disconnect();
        microphone.disconnect();
      } else {
        this.volumeLevel = Math.round((Math.round(average)) / 10) * 4;
        this.changeDetectorRef.markForCheck();
      }
    };
  }

  setVideoPreview(stream) {
    this.videoElem.nativeElement.srcObject = stream;
    this.videoElem.nativeElement.muted = true;
    this.videoElem.nativeElement.width = 266;
    this.videoElem.nativeElement.height = 200;
    this.videoElem.nativeElement.load();
    this.changeDetectorRef.markForCheck();
  }

  private stopStream(){
    this.logger.info("[VNCSettingsComponent][stopStream]");
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
      this.videoElem.nativeElement.srcObject = null;
    }
  }

  openedChange() {
    this.logger.info("[openedChange]");
  }

  onTabChange(event) {
    this.logger.info("[onTabChange]", event);
    this.selectedTab = event.id;
    if (event.id === "videoSettings") {
      this.getAvailableMediaDevices();
    }

    this.isCurrentTabSecurity = event.id === "securitySettings";
  }

  deleteOmemoDevice(deviceId: number) {
    let translation: any = {};
    this.translate.get(["REMOVE_OMEMO_DEVICE"]).pipe(take(1)).subscribe(v => {
      translation = v;
    });

    if (window.confirm(translation.REMOVE_OMEMO_DEVICE)) {
      this.omemoDevicesListUpdated = this.omemoDevicesListUpdated.filter(d => d.id !== deviceId);
      this.postUpdatedOMEMODevices();

      setTimeout(() => {
        this.retrieveOMEMODevices();
      }, 1000);
    }
  }

  async omemoTransferChats() {
    const dialogStyles  = CommonUtil.isMobileSize() ? {
      "width": "100%",
      "maxWidth": "100%",
      "height": "100%",
      "maxHeight": "100%",
      "visibility": "visible"
    } : {
      "width": "440px",
      "maxWidth": "80vw",
      "height": "none",
      "maxHeight": "600px",
      "visibility": "visible"
    };
    const { OMEMOForwardHistoryComponent } = await import(
      "app/talk/shared/components/dialogs");

    this.dialog.open(OMEMOForwardHistoryComponent, {
      backdropClass: "forward-dialog-backdrop",
      panelClass: ["forward-dialog-panel" ,"transfer-omemo-panel"],
      disableClose: true,
      autoFocus: true,
      width: dialogStyles.width,
      maxHeight: dialogStyles.maxHeight,
      maxWidth: dialogStyles.maxWidth,
      height: dialogStyles.height
    });
  }

  swipeAction(position, action) {
    if (position === "swipeLeft") {
      this.leftAction = action;
    } else {
      this.rightAction = action;
    }
    this.changeDetectorRef.markForCheck();
  }

  isActive(position, action) {
    if (position === "swipeLeft") {
      return this.leftAction === action;
    } else {
      return this.rightAction === action;
    }
  }

  menuItemClicked(type: string) {
    this.logger.info("[SettingsComponent][menuItemClicked]", type);
    this.uploadImageType = type;
    try {
      document.getElementById("attachImage").click();
    } catch (error) {
      this.logger.info("error clicking attach: ",error);
    }
  }

  attachImage(event:any, name?: string, isRecordedFile?: boolean, diffTarget?: string) {
    this.logger.info("[SettingsComponent][attachImage]", event, name);
    if (!event || !CommonUtil.isImage(event.srcElement.files[0].type)) {
      return;
    }
    let source = event.srcElement || event.currentTarget;
    // provide an abilityy to select the same file one more time
    this.logger.info("[SettingsComponent][source]", source);
    if (source.files && source.files.length > 0) {
      const file = source.files[0];
      const filename = file.name;
      const type = this.uploadImageType;
      if (this.customBackgroundImages && this.customBackgroundImages.length) {
        if (this.customBackgroundImages.find(background => background.name === filename)) {
          this.translate.get("FILE_ALREADY_EXISTS", { fileName: filename })
            .pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
              this.vncLibraryService.openSnackBar(res, "close", "", "", 2000, "left", "left", "").subscribe();
            });
          return;
        }
      }
      const reader = new FileReader();

      reader.onload = function (evt) {
        this.logger.info("[SettingsComponent][onload]", evt);
        const base64data = evt.target.result;
        let uploadObj = {
          filename: filename,
          type: type,
          base64data: base64data
        };
        this.logger.info("[SettingsComponent][onload]", uploadObj);
        this.uploadImage(uploadObj);
      }.bind(this);

      reader.readAsDataURL(file);

    }
    if (event.target) {
      let attachFile = <HTMLInputElement>event.target;
      attachFile.value = "";
      attachFile = null;
    }
  }

  uploadImage(uploadObj: any) {
    this.logger.info("[SettingsComponent][uploadImage]", uploadObj);
    this.appService.uploadChatBackgroundImage(uploadObj).subscribe((res: any) => {
    this.logger.info("[SettingsComponent][uploadChatBackgroundImage]", res);
      if (res && res.id) {
        this.store.dispatch(new SetChatBackgroundImages({id: res.id.toString(), name: res.filename, type: uploadObj.type, src: uploadObj.base64data}));
      }
    });
  }

  deleteChatBackgroundImage(id: string) {
    this.appService.deleteChatBackgroundImage(id).subscribe((res: any) => {
      if (this.selectedChatBackground.value === id) {
        this.selectedChatBackground.setValue(localStorage.getItem(ConstantsUtil.CHAT_BACKGROUND) || environment.chatBackground);
      }
        this.store.dispatch(new DeleteChatBackgroundImage(id));
    });
  }

  get appliedTheme () {
    return localStorage.getItem(ConstantsUtil.CHAT_BACKGROUND) || environment.chatBackground;
  }


  openColorDialog() {
    const changeColorDialogRef = this.matDialog.open(ColorControlComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "258px",
      autoFocus: false,
      panelClass: "vp-color-control-dialog",
      data: { folderColor : this.chatBackgroundColor }
    });
    changeColorDialogRef.afterClosed().subscribe(operation => {
      if (!!operation && operation !== null && operation.selectedColor) {
        this.chatBackgroundColor = operation.selectedColor;
        this.store.dispatch(new SetChatBackgroundColorPreview(this.chatBackgroundColor));
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  showAddActionsMenu() {
    if (!this.isOnMobile) return;
    const bottomSheet = this._bottomSheet.open(VNCBottomSheetAddActionsMenu, {
      panelClass: "vnc-mobile-add-actions-bottom-sheet-panel"
    });
    bottomSheet.afterDismissed().pipe(take(1)).subscribe(res => {
      if(!res?.operation) return;
      const operation = res.operation;
      switch (operation) {
        case "background":
          this.menuItemClicked(operation);
          break;
        case "avatar":
          this.menuItemClicked(operation);
          break;
        default:
          break;
        }
    });
  }

  openActionMenu () {
    if (this.isOnMobile) {
      this.showAddActionsMenu();
    } else {
      this.addActionMenuTrigger.openMenu();
    }
  }


}
