import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {PopupPositionService} from "./popup-position.service";
import {ContactRepository} from "../talk/repositories/contact.repository";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ConversationRepository} from "../talk/repositories/conversation.repository";

@Injectable()
export class MentionService {
  constructor(
    private _dialog: MatDialog,
    private _popupPositionService: PopupPositionService,
    private _contactRepository: ContactRepository,
    private _conversationRepository: ConversationRepository
  ) {
  }

  getContactByBare(bare): Observable<any> {
    return this._contactRepository.getAllContacts().pipe(map(contacts => {
      return contacts.filter(c => c?.bare === bare);
    }));
  }

  async getContactPreviewDialogRef(data, clientX, clientY, canPerformActions = true) {
    const { ContactPreviewComponent } = await import(
      "./contact-preview/contact-preview.component");
    return this._dialog.open(ContactPreviewComponent, {
      width: "720px",
      height: "440px",
      backdropClass: "transparent-backdrop",
      panelClass: "contact-preview-dialog-container",
      position: this._popupPositionService.getPopupPosition({ width: 760, height: 440 }, clientX, clientY),
      data: {contactData: data, canPerformActions}
    });
  }

  async getTagPreviewDialogRef(data, clientX, clientY) {
    const { TagsPreviewComponent } = await import(
      "./tags-preview/tags-preview.component");
    return this._dialog.open(TagsPreviewComponent, {
      width: "220px",
      maxHeight: "330px",
      backdropClass: "transparent-backdrop",
      panelClass: "tags-preview-dialog-container",
      position: this._popupPositionService.getPopupPosition({ width: 760, height: 440 }, clientX, clientY),
      data
    });
  }

  getTagsByQuery(query: string): Observable<any> {
    let params: any = [];
    params["name"] = "~" + query;
    return this._conversationRepository.getTags(params);
  }

  getMappedContactForPreview(contact) {
    return {
      first_name: contact?.first_name || "",
      middle_name: contact?.middle_name || "",
      last_name: contact?.last_name || "",
      email: contact?.bare || "",
      role: contact?.job_title || "",
      company: contact?.company || "",
      dob: contact?.dob || "",
      tags: contact?.tags?.map(t => t?.name) || [],
      avatar_url:  contact?.avatar_url || "https://www.defaulturl.com",
      timezone:  contact?.timezone || "",
      language:  contact?.language || "",
      projects:  contact?.products?.map(p => p?.name) || []
    };
  }

}
