import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "app/environments/environment";
import { CommonUtil } from "app/talk/utils/common.util";

@Component({
  selector: "vp-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  isHinTheme = localStorage.getItem("theme") === "hin";
  currentTheme = environment.theme || "vnctalk";
  themeColor: string = "#317bbc";
  logoColor = "#FFFFFF";
  color: string = "#FFFFFF";
  screen: string;
  isOnIpad = CommonUtil.isOnIpad();
  constructor(private router: Router) { }

  async ngOnInit() {
    this.getThemeColor();
    if (!!document.getElementById("non-cordova-only")) {
      document.getElementById("non-cordova-only").outerHTML = "";
    }
    await import("../root.module").then(m => m.RootModule).finally(() => {
      setTimeout(() => {
        this.router.navigateByUrl("/talk");
      }, 500);
    }).catch(() => {
    });
  }

  getThemeColor(): void {
    const theme = localStorage.getItem("theme");
    if (theme !== null && theme === "hin") {
      this.themeColor = "#f39900";
      if (this.currentTheme === "hin") {
        this.themeColor = "#FFFFFF";
        this.color = "#8f8f8f";
      }
    } else if (theme !== null && theme === "ekbo") {
      this.themeColor = "#6633cc";
    } else if (theme !== null && theme === "airbus") {
      this.themeColor = "#00205b";
    } else {
      this.themeColor = "#317bbc";
    }
  }

}
