
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { catchError, map, Observable, of, Subject, BehaviorSubject, take } from "rxjs";
import { Router } from "@angular/router";

import { ConfigService } from "../config.service";
import { environment } from "../environments/environment";
import { LoggerService } from "app/shared/services/logger.service";


@Injectable()
export class GlobalAuthService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private AUTH_TOKEN_KEY: string;
  user: any = null;
  ldapUser: any = null;
  userRequest: any = null;
  authObserable: Observable<any>;
  redirectTo: string = null;
  isProfileUpdated$: Subject<boolean> = new Subject<boolean>();
  authUserChanged$ = new Subject<boolean>();

  constructor(private config: ConfigService,
              private router: Router,
              private logger: LoggerService,
              private http: Http) {
    this.AUTH_TOKEN_KEY = this.config.get("AUTH_TOKEN_KEY");
  }

  public getProfile(refresh: boolean = false) {
    let headers = new Headers({"Content-Type": "application/json"});
    if (this.isCordovaOrElectron) {
      headers = new Headers({"Content-Type": "application/json", "Authorization": localStorage.getItem("token")});
    }
    const localTime = new Date().getTime();

    let storedProfileString = localStorage.getItem("profile");
    let storedProfile;
    if (!!storedProfileString && (storedProfileString !== "")) {
      try {
        storedProfile = JSON.parse(storedProfileString);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("[auth.service] error parsing stored profile", error);
      }
    }

    if (!refresh && !!storedProfile  && !!storedProfile.profile && !!storedProfile.onlyOfficeApiUrl && (storedProfile.onlyOfficeApiUrl !== "")) {
      const storedResponse = new BehaviorSubject<any[]>([]);
      this.logger.info("[plone.auth.service] getApiProfile - stored2: ", storedProfile);
      storedResponse.next(storedProfile);
      return storedResponse.asObservable().pipe(take(1));
    } else {
      return this.http.get(this.config.API_URL + "/api/profile", {headers: headers}).pipe(map(response => {
        const serverDate = response.headers.get("Date");
        let jsonResponse = response.json();
        if (serverDate) {
          const diff = localTime - new Date(serverDate).getTime();
          jsonResponse.serverTimeDiff = diff;
        }
        localStorage.setItem("profile", JSON.stringify(jsonResponse));
        return jsonResponse;
      }));
    }

  }



  public getPrivateDocuments(): Observable<any> {
    this.logger.info("[plone.auth.service] getprivatexml");
    let headers = new Headers({"Content-Type": "application/json"});
    if (this.isCordovaOrElectron) {
      headers = new Headers({"Content-Type": "application/json", "Authorization": localStorage.getItem("token")});
    }
    return this.http.get(this.config.API_URL + "/api/privatexmldoc", {headers: headers}).pipe(map(response => response.json()), catchError((err) => {
      this.logger.info("getPrivateDocuments err", err);
      return of(true);
    }));
  }

  loginPlone() {
    let headers = new Headers({"Content-Type": "application/json"});
    if (this.isCordovaOrElectron) {
      headers = new Headers({"Content-Type": "application/json", "Authorization": localStorage.getItem("token")});
    }

    return this.http.get(this.config.API_URL + "/api/login-plone", {headers: headers}).pipe(map(response => response.json()));
  }

  logout() {
    const callLogout = environment.isElectron ? "./api/call-logout" : "/api/call-logout";
    return this.http.get(this.config.API_URL + callLogout, {});
  }

  addAuthHeader(headers: Headers): Headers {
    // Appends an authentication header to headers
    let authorization = localStorage.getItem(this.AUTH_TOKEN_KEY);
    if (authorization) {
      headers.append("Authorization", "Bearer " + authorization);
    }
    return headers;
  }

  setAuthToken(token: string) {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  getAuthToken(): string {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  clearRedirect(): void {
    this.redirectTo = null;
  }

  getRedirect(): string {
    return this.redirectTo;
  }

  delAuthToken() {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.user = null;
  }

  private fetchAuthUser() {
    let headers = new Headers({
      "Content-Type": "application/json",
      "Accept": "application/json"
    });
    this.addAuthHeader(headers);
    let options = new RequestOptions({headers: headers});
    if (this.userRequest === null) {
      this.userRequest = this.http.get(`${this.config.get("PORTAL_URL")}/@me`, options)
      // .share()
        .pipe(map(res => {
          let user = res.json();
          this.user = user;
          this.authUserChanged$.next(this.user);
          return user;
        }));
    }
    return this.userRequest;
  }

  getAuthUser() {
    let token = this.getAuthToken();
    if (token && this.user) {
      return of(this.user);
    } else {
      /* SSA: don' use fetchAuthUSer from portal
      return this.fetchAuthUser();
      */
      return null;
    }
  }

  removeLogin(redirect?: boolean): void {
    this.delAuthToken();
    this.userRequest = null;
    this.user = null;
    this.authObserable = null;
    if (redirect) {
      this.redirectTo = window.location.pathname.replace("/uxf", "");
    }
  }

  isLogin(): boolean {
    return this.ldapUser !== null;
  }

  redirectToLogin(expired?: boolean): void {
    this.removeLogin(true);
    this.router.navigate(["login"], expired ? {queryParams: {session: "expired"}} : {});
  }
}
