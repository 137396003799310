
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="app-all-settings-component">
  <div class="mat-dialog__header">
    <button id="backBtn" *ngIf="selectedPanel === 'NONE'" class="hide-on-desktop icon-button" mat-icon-button (click)="onEsc()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button id="backBtn" *ngIf="selectedPanel !== 'NONE'" class="hide-on-desktop icon-button" mat-icon-button (click)="selectedPanel = 'NONE'">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <h3 id="helpHeader" class="mat-dialog__title header-title" *ngIf="selectedPanel === 'NONE'">
      {{ 'HELP' | translate }}
    </h3>
    <h3 class="mat-dialog__title header-title" *ngIf="selectedPanel === 'APP_INFO'">
      {{ 'APP_INFO' | translate }}
    </h3>
    <h3 class="mat-dialog__title header-title" *ngIf="selectedPanel === 'USER_MANUAL'">
      {{ 'HEADER_MANUAL_FAQ' | translate }}
    </h3>
    <h3 id="service_header" class="mat-dialog__title header-title" *ngIf="selectedPanel === 'SERVICE_DESK'">
      <span *ngIf="currentTheme === 'hin'">{{ 'HIN_SERVICE_DESK' | translate }}</span>
      <span *ngIf="currentTheme !== 'hin'">{{ 'HEADER_SERVICE_DESK' | translate }}</span>
    </h3>
    <h3 class="mat-dialog__title header-title" *ngIf="selectedPanel === 'LEGAL_NOTICE'">
      {{ 'LEGAL_NOTICE' | translate }}
    </h3>
  </div>

  <div class="mat-dialog__body">
    <div class="setting-options" *ngIf="selectedPanel === 'NONE'">
      <div class="setting-option">
        <vp-dropdown-item id="appInfo" size="xl" itemType="icon-drop-default"  (onClickEvent)="aboutDialog('APP_INFO')"
        [prefixIconTemplate]="appInfoIcon" label="{{'APP_INFO' | translate }}">
        </vp-dropdown-item>
      </div>
      <div class="setting-option">
        <vp-dropdown-item id="UserManual" size="xl" itemType="icon-drop-default"  (onClickEvent)="helpDialog()"
        [prefixIconTemplate]="manualIcon" label="{{ (currentTheme === 'hin' ? 'INSTRUCTIONS_FAQ' : 'USER_MANUAL') | translate }}">
        </vp-dropdown-item>
      </div>
      <div class="setting-option">
        <vp-dropdown-item id="serviceDesk" size="xl" itemType="icon-drop-default"  (onClickEvent)="serviceDialog()"
        [prefixIconTemplate]="helpIcon" label="{{ (currentTheme === 'hin' ? 'HIN_SERVICE_DESK' : 'HEADER_SERVICE_DESK') | translate }}">
        </vp-dropdown-item>
      </div>
      <div class="setting-option">
        <vp-dropdown-item id="legalnotice" size="xl" itemType="icon-drop-default"  (onClickEvent)="displaySelectedPanel('LEGAL_NOTICE')"
        [prefixIconTemplate]="manualIcon" label="{{ 'LEGAL_NOTICE' | translate }}">
        </vp-dropdown-item>
      </div>

      <ng-template #appInfoIcon>
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" fill="#000" fill-rule="evenodd"/>
        </svg>
      </ng-template>
      <ng-template #manualIcon>
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.586 2a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h7.586zm-2.587 1.999L6 4v16h12V10h-4a3 3 0 0 1-2.995-2.824L11 7l-.001-3.001zM17.172 8l-4.001-4-.172-.001L13 7a1 1 0 0 0 .883.993L14 8h3.172z" fill="#000" fill-rule="evenodd"/>
        </svg>
      </ng-template>
      <ng-template #helpIcon>
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2c.19 0 .378.006.565.018 4.603.283 8.296 4.005 8.431 8.643l.004.264V14.3c0 4.8-4.05 8.7-9 8.7-.412 0-.699-.252-.744-.64l-.006-.11v-1.5c0-.413.252-.699.64-.744L12 20h2.25c.308 0 .546.14.665.374a6.998 6.998 0 0 0 3.083-2.71 3.576 3.576 0 0 1-.748.086c-.413 0-.699-.252-.744-.64L16.5 17v-6c0-.45.3-.75.75-.75.61 0 1.22.164 1.739.431C18.788 6.951 15.729 4 12 4c-3.73 0-6.788 2.951-6.99 6.682a3.848 3.848 0 0 1 1.74-.432c.412 0 .699.252.744.64L7.5 11v6c0 .45-.3.75-.75.75a3.77 3.77 0 0 1-2.08-.614c-.708-.603-1.152-1.12-1.33-1.551a3.782 3.782 0 0 1-.333-1.343L3 14v-3.075c0-4.76 3.746-8.619 8.435-8.908A9.26 9.26 0 0 1 12 2z" fill="#000" fill-rule="evenodd"/>
          </svg>
      </ng-template>   
    </div>

    <div class="options-content" *ngIf="selectedPanel !== 'NONE'">
      <ng-container *ngIf="selectedPanel == 'APP_INFO'">
        <div class="app-info-icon-container">
          <div class="image">
            <img [src]="iconUrl">
            <div class="app-version-name">
              <h6 [innerHTML]="headerBranding"></h6>
            </div>
            <span class="app-version">{{ 'VERSION' | translate }} {{ app_version }}  </span>
          </div>
          <h3 *ngIf="!hideChangelogMenu">
            <a target="_blank" id="changeLog" class="open-new-window" href="{{ 'CHANGELOG_URL'|translate }}">{{ 'CHANGELOG'|translate }}</a>
          </h3>
        </div>
      </ng-container>  

      <ng-container *ngIf="selectedPanel == 'USER_MANUAL' && currentTheme !== 'hin'">
        <div class="link-option">
          <h6 id="Help_lbl" class="no-margin">{{ 'HELP' | translate }}</h6>
          <span *ngIf="!helpLink"> {{ 'COMING_SOON' | translate }} </span>
          <a *ngIf="helpLink" id="Help_Link" target="_blank" class="open-new-window" href="{{ helpLink }}">{{ helpLink }}</a>
        </div>
        <div class="link-option">
          <h6 id="Manual_lbl">{{ 'MANUAL' | translate }}</h6>
          <span *ngIf="!manualLink"> {{ 'COMING_SOON' | translate }} </span>
          <a *ngIf="manualLink" id="Manual_link" target="_blank" class="open-new-window" href="{{ manualLink }}">{{ manualLink }}</a>
        </div>
    
        <div class="link-option">
          <h6 id="faq_lbl">FAQ</h6>
          <span *ngIf="!FAQLink"> {{ 'COMING_SOON' | translate }} </span>
          <a *ngIf="FAQLink" id="faq_link" target="_blank" class="open-new-window" href="{{ FAQLink }}">{{ FAQLink }}</a>
        </div>
      </ng-container> 
      <ng-container *ngIf="selectedPanel == 'USER_MANUAL' && currentTheme === 'hin'">
        <div class="link-option">
          <a target="_blank" class="open-new-window" href="{{ 'HIN_QUICK_GUIDE_LINK' | translate }}">{{ 'HIN_QUICK_GUIDE' | translate }}</a>
        </div>
        <div class="link-option">
          <a target="_blank" class="open-new-window" href="{{ 'HIN_FAQ_LINK' | translate }}">{{ 'HIN_FAQ' | translate }}</a>
        </div>
      </ng-container> 

      <ng-container *ngIf="selectedPanel === 'SERVICE_DESK'">
        <span *ngIf="currentTheme !== 'hin'"> {{ 'SERVICE_CONTENT' | translate }}
        </span>
        <span *ngIf="currentTheme === 'hin'"> {{ 'HIN_SERVICE_CONTENT' | translate }}
        </span>
        <br>
        <br>
        <span *ngIf="currentTheme === 'hin'" [innerHTML]="'HIN_SUPPORT_ADDRESS' | translate"></span>
        <div class="container" *ngIf="currentTheme !== 'hin'">
          <img [src]="serviceDeskImage">
    
          <a id="Service_visit" class="open-new-window vp-button" target="_blank" [attr.href]="serviceDeskLink" *ngIf="currentTheme !== 'hin'">
            {{'VISIT_VNC' | translate }}</a>
          <a id="Service_visit" class="open-new-window vp-button" target="_blank" [attr.href]="serviceDeskLink" *ngIf="currentTheme === 'hin'">
            {{'VISIT_HIN' | translate }}</a>
        </div> 
      </ng-container>

      <ng-container *ngIf="selectedPanel === 'LEGAL_NOTICE'">
        <mat-list class="legal-notice-content">
         <ng-container *ngIf="currentTheme !== 'hin'">
          <span id="term_of_use" mat-list-item class="mat-list-item">
            <span class="link-container">
              <mat-icon class="setting-option-icon">assignment</mat-icon>
              <a target="_blank" class="options custom-link-style open-new-window" href="{{ termsLink | translate }}">
                {{ 'TERMS_OF_USE' | translate }}
              </a>
            </span>
          </span>
          <span id="data_privacy" mat-list-item class="mat-list-item">
            <span class="link-container">
              <mat-icon class="setting-option-icon">lock</mat-icon>
              <a target="_blank" class="options custom-link-style open-new-window"
                href="{{ privacyLink | translate }}">
                {{ 'DATA_PRIVACY' | translate }}
              </a>
            </span>  
          </span>
         </ng-container>
          <ng-container *ngIf="currentTheme === 'hin'">
            <span mat-menu-item>
              <span layout="row" class="drop-menu-nav__item">
                <mat-icon>assignment</mat-icon>
                <a target="_blank" class="open-new-window legal-notice-link" href="{{ 'GENERAL_TERMS_AND_CONDITIONS_LINK' | translate }}">{{ 'GENERAL_TERMS_AND_CONDITIONS' | translate }}</a>
              </span>
            </span>
            <span mat-menu-item>
              <span layout="row" class="drop-menu-nav__item">
                <mat-icon>https</mat-icon>
                <a target="_blank" class="open-new-window legal-notice-link" href="{{ 'FRAMEWORK_CONDITIONS_LINK' | translate }}">{{ 'FRAMEWORK_CONDITIONS' | translate }}</a>
              </span>
            </span>
        </ng-container>
        </mat-list>
      </ng-container>
    </div>  
  </div>
</div>
<ng-template #information>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" fill="#000" fill-rule="evenodd"/>
</svg>
</ng-template>

<ng-template #fileIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path d="M13.586 2a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h7.586zm-2.587 1.999L6 4v16h12V10h-4a3 3 0 0 1-2.995-2.824L11 7l-.001-3.001zM17.172 8l-4.001-4-.172-.001L13 7a1 1 0 0 0 .883.993L14 8h3.172z" fill="#000" fill-rule="evenodd"/>
  </svg>
</ng-template>

<ng-template #helpDesk>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path d="M12 2c.19 0 .378.006.565.018 4.603.283 8.296 4.005 8.431 8.643l.004.264V14.3c0 4.8-4.05 8.7-9 8.7-.412 0-.699-.252-.744-.64l-.006-.11v-1.5c0-.413.252-.699.64-.744L12 20h2.25c.308 0 .546.14.665.374a6.998 6.998 0 0 0 3.083-2.71 3.576 3.576 0 0 1-.748.086c-.413 0-.699-.252-.744-.64L16.5 17v-6c0-.45.3-.75.75-.75.61 0 1.22.164 1.739.431C18.788 6.951 15.729 4 12 4c-3.73 0-6.788 2.951-6.99 6.682a3.848 3.848 0 0 1 1.74-.432c.412 0 .699.252.744.64L7.5 11v6c0 .45-.3.75-.75.75a3.77 3.77 0 0 1-2.08-.614c-.708-.603-1.152-1.12-1.33-1.551a3.782 3.782 0 0 1-.333-1.343L3 14v-3.075c0-4.76 3.746-8.619 8.435-8.908A9.26 9.26 0 0 1 12 2z" fill="#000" fill-rule="evenodd"/>
  </svg>
</ng-template>